import React from 'react';
import { HealthCareIcon, InjectionIcon, HandPuzzleIcon } from 'icons/';
import { Wrap, Title, ItemsWrap, Item, Icon, Text } from './styles';

export const Features: React.FC = props => {
  return (
    <Wrap {...props}>
      <Title>Подпишись и будь всегда в курсе</Title>
      <ItemsWrap>
        <Item>
          <Icon>{HealthCareIcon}</Icon>
          <Text>Самые эффективные методы оздоровления</Text>
        </Item>
        <Item>
          <Icon>{InjectionIcon}</Icon>
          <Text>Самые прогрессивные методы омоложения</Text>
        </Item>
        <Item>
          <Icon>{HandPuzzleIcon}</Icon>
          <Text>Действенные техники решения проблем</Text>
        </Item>
      </ItemsWrap>
    </Wrap>
  );
};
