import React, { useEffect } from 'react';
import { useCatalogQuery } from 'queries/catalog/useCatalogQuery';
import { $catalogData, clearCatalog } from 'models/catalog/model';
import { useStore } from 'effector-react';
import { StyledPreloader } from '../styles';
import { CatalogItem } from 'models/catalog/types';
import { CatalogItemSet } from '../../common/CatalogItemSet';
import { SmallCatalogItem } from '../../common/SmallCatalogItem';
import styled from 'styled-components';

const NoItemsText = styled.p`
  ${({ theme }) => theme.typography.headline3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const Books: React.FC = () => {
  const { isLoading, isFetching, isError } = useCatalogQuery('books');
  const data: CatalogItem[] = useStore($catalogData);
  const showPreloader = isLoading || isFetching;
  useEffect(() => {
    return () => {
      clearCatalog();
    };
  }, []);

  return (
    <>
      {showPreloader ? (
        <StyledPreloader />
      ) : data.length ? (
        data.map(item =>
          item.is_set ? (
            <CatalogItemSet
              goodsType={'books'}
              buttonText={!item.available ? 'Товар недоступен' : 'В корзину'}
              disabled={!item.available}
              key={item.id}
              {...item}
            />
          ) : (
            <SmallCatalogItem
              goodsType={'books'}
              key={item.id}
              buttonText={!item.available ? 'Товар недоступен' : 'В корзину'}
              disabled={!item.available}
              {...item}
            />
          )
        )
      ) : (
        <NoItemsText>
          {isError ? 'Не удалось загрузить товары' : 'Нет товаров'}
        </NoItemsText>
      )}
    </>
  );
};
