import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';
import { Radio } from 'ui/Radio';

export const List = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-radius: 14px;
  padding: 24px;
`;

export const Option = styled.div`
  display: flex;
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  width: 100%;
  height: 1px;
  margin: 24px 0;
`;

export const Inner = styled.div`
  max-width: 489px;
  padding: 6px 0 0 0;
`;

export const OptionRadio = styled(Radio)`
  align-self: flex-start;
  margin: 3px;
  & + ${Inner} {
    margin-left: 40px;
  }
`;

export const OptionTitle = styled.p`
  ${({ theme }) => theme.typography.bannerDescription};
  margin: 0;
`;

export const OptionText = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 12px 0 0;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 24px auto 0;
`;
