import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
  text-align: center;
`;

export const ButtonWrap = styled.div`
  text-align: right;
`;

export const StyledButtonPreloader = styled(Preloader)`
  margin: 0 63px 0 87px;
`;
