export const WebIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.99992 2.50001C5.85778 2.50001 2.49992 5.85787 2.49992 10C2.49992 14.1421 5.85778 17.5 9.99992 17.5C14.1421 17.5 17.4999 14.1421 17.4999 10C17.4999 5.85787 14.1421 2.50001 9.99992 2.50001ZM0.833252 10C0.833252 4.9374 4.93731 0.833344 9.99992 0.833344C15.0625 0.833344 19.1666 4.9374 19.1666 10C19.1666 15.0626 15.0625 19.1667 9.99992 19.1667C4.93731 19.1667 0.833252 15.0626 0.833252 10Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.833252 9.99999C0.833252 9.53975 1.20635 9.16666 1.66659 9.16666H18.3333C18.7935 9.16666 19.1666 9.53975 19.1666 9.99999C19.1666 10.4602 18.7935 10.8333 18.3333 10.8333H1.66659C1.20635 10.8333 0.833252 10.4602 0.833252 9.99999Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.50011 10C7.55722 12.5624 8.43846 15.0293 9.99992 17.041C11.5614 15.0293 12.4426 12.5624 12.4997 10C12.4426 7.43763 11.5614 4.9707 9.99992 2.95899C8.43846 4.9707 7.55722 7.43763 7.50011 10ZM9.99992 1.66666L9.38463 1.10464C7.164 3.53575 5.90201 6.69071 5.83343 9.98264C5.83319 9.99421 5.83319 10.0058 5.83343 10.0174C5.90201 13.3093 7.164 16.4642 9.38463 18.8953C9.54251 19.0682 9.76582 19.1667 9.99992 19.1667C10.234 19.1667 10.4573 19.0682 10.6152 18.8953C12.8358 16.4642 14.0978 13.3093 14.1664 10.0174C14.1666 10.0058 14.1666 9.99421 14.1664 9.98264C14.0978 6.69071 12.8358 3.53575 10.6152 1.10464L9.99992 1.66666Z'
    />
  </svg>
);
