import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { PromoItem } from 'models/sales/types';
import { getPromoItemFx, setPromoItem } from 'models/sales/model';

export const usePromoItemQuery: (
  id: string
) => UseQueryResult<
  AxiosResponse<{ data: PromoItem }>,
  AxiosError<{ error: string }>
> = id =>
  useQuery('promoItem', () => getPromoItemFx(id), {
    onSuccess: res => {
      setPromoItem(res.data.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
