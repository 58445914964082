import React from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import {
  CatalogItemWrap,
  ImageWrap,
  Image,
  TagsWrap,
  TagItem,
  CatalogItemTitle,
  EventDateWrap,
  EventBeginning,
  EventDate,
  EventDuration,
} from './styles';
import { baseUrl } from 'api/apiClient';
import { MiddleCatalogItemProps } from './types';
import { NoImage } from 'ui/NoImage';
import { DateFormats } from 'constants/dateFormats';

//TODO: Вынести компонент в сторибук

export const MiddleCatalogItem: React.FC<MiddleCatalogItemProps> = ({
  id,
  image,
  title,
  datetime,
  duration,
  is_marathon,
  purchased,
  bg_color,
  url,
  ...rest
}) => {
  return (
    <CatalogItemWrap
      key={id}
      to={{
        pathname: url,
      }}
      {...rest}
    >
      <ImageWrap bg_color={bg_color} image={image}>
        {image ? <Image src={baseUrl + image} alt={title} /> : <NoImage />}
        {/*TODO: Заказчик пока отказался от возрастных ограничений*/}
        {/*{restriction ? <Age age={restriction} /> : null}*/}
      </ImageWrap>
      <TagsWrap>
        {is_marathon ? (
          <TagItem status={'progress'} size={'medium'} text={'Марафон'} ghost />
        ) : null}
        {purchased ? (
          <TagItem status={'progress'} size={'medium'} text={'Получен'} />
        ) : null}
      </TagsWrap>
      <CatalogItemTitle>{title}</CatalogItemTitle>
      <EventDateWrap>
        {datetime ? (
          <EventBeginning>
            Начало:{' '}
            <EventDate>
              {format(datetime, DateFormats['StartEventDate'], {
                locale: ru,
              })}
            </EventDate>
          </EventBeginning>
        ) : null}
        {duration ? <EventDuration>{duration}</EventDuration> : null}
      </EventDateWrap>
      {/*TODO: Прогресс надо подключить уже на страницах курсов Inner Insight*/}
      {/*{progress ? (*/}
      {/*  <ProgressBar>*/}
      {/*    <ProgressBarPercent>*/}
      {/*      {progress.done && progress.done > 0*/}
      {/*        ? Math.round((progress.done / progress.total) * 100)*/}
      {/*        : 0}*/}
      {/*      %*/}
      {/*    </ProgressBarPercent>*/}
      {/*    <ProgressBarDot started={!!progress.done && progress.done > 0} />*/}
      {/*    <ProgressBarLine>*/}
      {/*      <ProgressBarDone progress={progress} />*/}
      {/*    </ProgressBarLine>*/}
      {/*    <ProgressBarFinish progress={progress}>*/}
      {/*      {ProgressBarStarIcon}*/}
      {/*    </ProgressBarFinish>*/}
      {/*    <ProgressBarCount>*/}
      {/*      {progress.done}/{progress.total} лекций*/}
      {/*    </ProgressBarCount>*/}
      {/*  </ProgressBar>*/}
      {/*) : null}*/}
    </CatalogItemWrap>
  );
};
