import React from 'react';
import { useStore } from 'effector-react';
import Ruble from 'components/common/Ruble';
import {
  Inner,
  Wrap,
  Text,
  Title,
  Action,
  Price,
  BannerButton,
  StyledPreloader,
} from './styles';
import { formatPriceToString } from 'utilities/formatPriceToString';
import { VIPChatListItemType } from 'models/catalog/types';
import { usePurchaseVIPChatQuery } from 'queries/catalog/usePurchaseVIPChatQuery';
import { useHistory } from 'react-router-dom';
import { Path } from 'constants/paths';
import { $user} from 'models/auth';
import { showAuthModal } from 'models/auth/model';

export type VIPChatBannerProps = { data: VIPChatListItemType };

export const Banner: React.FC<VIPChatBannerProps> = ({ data, ...rest }) => {
  const { id, price, name, description, picture } = data;
  const { mutate, isLoading } = usePurchaseVIPChatQuery();
  const history = useHistory();
  const isAuthed = useStore($user);

  const handlePurchase = () => isAuthed ? (
      mutate(id, {
        onSuccess: res => {
          history.push(
            `${Path.directBuy.replace(':key', 'chat')}/${res.data.order_id}`
          );
        },
      })
    ) : showAuthModal();

  return (
    <Wrap bg={picture} {...rest}>
      <Inner>
        <Title>{name}</Title>
        <Text>{description}</Text>
        <Action>
          <Price>
            {formatPriceToString(price)} {Ruble}
          </Price>
          {isLoading ? (
            <StyledPreloader />
          ) : (
            <BannerButton size='large' onClick={handlePurchase}>
              Приобрести
            </BannerButton>
          )}
        </Action>
      </Inner>
    </Wrap>
  );
};
