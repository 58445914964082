import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 120px;
  &:focus {
    ${({ theme }) => theme.additional.noOutline};
  }
`;
export const LogoImage = styled.div`
  line-height: 0;
`;
export const LogoText = styled.span`
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.mainBgColor};
`;

export const FooterWrap = styled.div`
  margin-top: auto;
  background-color: ${({ theme }) => theme.colors.mainColor};
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1236px;
  padding: 0 12px;
  display: flex;
`;

export const Top = styled.div`
  border-bottom: 1px solid #313131;
  padding: 50px 0 65px 0;
`;
export const Bottom = styled.div`
  padding: 20px 0;
`;

export const InstagramText = styled.p`
  color: ${({ theme }) => theme.colors.mainBgColor};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
`;

export const Instagram = styled.a`
  display: block;
  margin-top: 66px;
`;

export const InstagramSvg = styled.a`
  padding: 9px;
  margin: 0 0 0 auto;
  border-radius: 14px;
  font-size: 0;
  background: ${({ theme }) => theme.colors.socialMediaNotActiveColor};
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-top: 12px;
  &:focus {
    ${({ theme }) => theme.additional.noOutline};
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      152.53deg,
      #feda75 13.16%,
      #fa7e1e 34.27%,
      #d62976 56.36%,
      #962fbf 74.37%,
      #4f5bd5 91.51%
    );
    transition: all 0.3s;
    opacity: 0;
  }

  &:hover {
    & > svg {
      transition: all 0.3s;
      fill: ${({ theme }) => theme.colors.mainColor};
    }

    &:after {
      transition: all 0.3s;
      opacity: 1;
    }
  }
  & > svg {
    position: relative;
    transition: all 0.3s;
    width: 22px;
    height: 22px;
    z-index: 2;
    fill: ${({ theme }) => theme.colors.mainBgColor};
  }
`;

export const FooterColumn = styled.div`
  width: 25%;
  padding: 0 12px;
`;
export const ListTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.mainBgColor};
`;

export const ListItemLink = styled(Link)`
  color: ${({ theme }) => theme.colors.disabledColor};
  &:focus {
    ${({ theme }) => theme.additional.noOutline};
  }

  &:hover {
    transition: all 0.3s;
    color: ${({ theme }) => theme.colors.mainBgColor};
  }
`;

export const ListItemWrap = styled.p`
  ${({ theme }) => theme.typography.bodyText1};
  margin: 0;
  line-height: 1.6;
  letter-spacing: 0;
  transition: all 0.3s;
`;

export const List = styled.div`
  & ${ListTitle} + ${ListItemWrap} {
    margin-top: 17px;
  }

  & ${ListItemWrap} + ${ListItemWrap} {
    margin-top: 14px;
  }
`;

export const Contacts = styled.div`
  text-align: right;
`;

export const Phone = styled.a`
  ${({ theme }) => theme.typography.headline3};
  color: ${({ theme }) => theme.colors.mainBgColor};
`;

export const Address = styled.div`
  ${({ theme }) => theme.typography.bodyText1};
  color: ${({ theme }) => theme.colors.disabledColor};
  margin-top: 20px;
  line-height: 1.6;
`;

export const Email = styled.a`
  ${({ theme }) => theme.typography.bodyText1};
  color: ${({ theme }) => theme.colors.disabledColor};
  line-height: 1.6;
  display: block;
  margin: 20px 0 0;
  &:focus {
    ${({ theme }) => theme.additional.noOutline};
  }
`;

export const Copyright = styled.span`
  ${({ theme }) => theme.typography.bodyText1};
  line-height: 1.6;
  margin-right: auto;
  color: ${({ theme }) => theme.colors.mainBgColor};
`;

export const DocumentLink = styled(Link)`
  ${({ theme }) => theme.typography.bodyText1};
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.disabledColor};
  &:focus {
    ${({ theme }) => theme.additional.noOutline};
  }

  &:hover {
    transition: all 0.3s;
    color: ${({ theme }) => theme.colors.mainBgColor};
  }
  & + & {
    margin-left: 80px;
  }
`;
