import React from 'react';
import Ruble from 'components/common/Ruble';
import { formatPriceToString } from 'utilities/formatPriceToString';
import {
  ImageWrap,
  Image,
  ItemWrap,
  Text,
  Price,
  Btn,
  CatalogItemTitle,
  StyledNoImage,
  DisabledItemWrap,
  StyledPreloader,
} from './styles';
import { Path } from 'constants/paths';
import { baseUrl } from 'api/apiClient';
import { useHistory } from 'react-router-dom';
import { GiftCard } from 'models/catalog/types';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import { usePurchaseGiftCardQuery } from 'queries/catalog/usePurschaseGiftCardQuery';

export const GiftCardCatalogItem: React.FC<
  GiftCard & {
    disabled?: boolean;
  }
> = ({ id, name, pictures, disabled, price, ...rest }) => {
  const history = useHistory();
  const {
    mutate: buyCard,
    isLoading: isButtonLoading,
  } = usePurchaseGiftCardQuery();

  const handleAddToCart = (id: number) => (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    buyCard(id.toString(), {
      onSuccess: res => {
        history.push(
          `${Path.directBuy.replace(':key', 'card')}/${res.data.order_id}`
        );
      },
      onError: () => {
        toast.error(<ErrorMessage text={'Не удалось купить'} />);
      },
    });
  };

  return disabled ? (
    <DisabledItemWrap {...rest}>
      <ImageWrap>
        {pictures?.length ? (
          <Image src={baseUrl + pictures[0]} alt={pictures[0].name} />
        ) : (
          <StyledNoImage />
        )}
      </ImageWrap>

      <CatalogItemTitle>{name}</CatalogItemTitle>
      <Text>Бонусные баллы</Text>
      <Price>
        {formatPriceToString(price)} {Ruble}
      </Price>
      <Btn disabled={disabled}>{'Купить'}</Btn>
    </DisabledItemWrap>
  ) : (
    <ItemWrap to={`${Path.catalogGiftCardsDetail}`} {...rest}>
      <ImageWrap>
        <>
          {pictures?.length ? (
            <Image src={baseUrl + pictures[0].path} alt={pictures[0].name} />
          ) : (
            <StyledNoImage />
          )}
        </>
      </ImageWrap>

      <CatalogItemTitle>{name}</CatalogItemTitle>
      <Text>Бонусные баллы</Text>
      <Price>
        {formatPriceToString(price)} {Ruble}
      </Price>
      {isButtonLoading ? (
        <StyledPreloader />
      ) : (
        <Btn onClick={() => handleAddToCart(id)} disabled={disabled}>
          {'Купить'}
        </Btn>
      )}
    </ItemWrap>
  );
};
