import styled from 'styled-components';
import bg from 'images/VIP_club_mini_banner.png';
import { Button } from 'ui/Button';

export const Wrap = styled.div`
  padding: 40px 42px;
  background-image: url(${bg});
  background-color: ${({ theme }) => theme.colors.actionColor};
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 14px;
  background-position: center right;
  margin-top: 30px;
`;
export const Text = styled.div`
  ${({ theme }) => theme.typography.headline2};
  max-width: 456px;
  margin-right: auto;
`;

export const StyledButton = styled(Button)`
  margin: 24px 0 0;
  background-color: ${({ theme }) => theme.colors.mainColor};
  color: ${({ theme }) => theme.colors.mainBgColor};
  &:hover {
    // background-color: ${({ theme }) => theme.colors.actionColor};
    & > span {
      //TODO: Дождаться от дизайнера состояний кнопки
      color: ${({ theme }) => theme.colors.actionColor}!important;
    }
  }
`;
