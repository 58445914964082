import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { removeUser } from 'models/auth';
import React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import TokenService from 'services/TokenService';
import { SuccessMessage } from 'ui/SuccessMessage';

export const useDeleteProfile = () =>
  useMutation(
    ['deleteAccount'],
    () => apiClient.delete(Endpoint.DELETE_USER_PROFILE),
    {
      onSuccess: () => {
        toast.success(<SuccessMessage text={'Аккаунт удален'} />);
        TokenService.removeToken();
        removeUser();
      },
    }
  );
