import { useQuery } from 'react-query';
import { MasterClassQuery } from './types';
import { getMasterClassFx, setMasterClass } from 'models/inner-insight/model';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';

export const useGetMasterClassQuery: MasterClassQuery = id =>
  useQuery('masterClass', () => getMasterClassFx(id), {
    onSuccess: res => {
      setMasterClass(res.data.data);
    },

    onError: res =>
      toast.error(<ErrorMessage text={res.response?.data.error} />),
    refetchOnWindowFocus: false,
    retry: false,
  });
