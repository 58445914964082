import { useMutation } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Path } from 'constants/paths';
import { CreateCourseOrderQuery } from './types';

export const useCreateCourseOrderQuery: CreateCourseOrderQuery = () => {
  const history = useHistory();
  return useMutation(
    'courseOrder',
    data => {
      return apiClient.post(Endpoint.PURCHASE_COURSE_ORDER, { ...data });
    },
    {
      onSuccess: res => {
        history.push(
          Path.directBuy.replace(':key', 'courses') + '/' + res.data.order_id
        );
      },
      onError: res => {
        toast.error(<ErrorMessage text={res.response?.data.error} />);
      },
    }
  );
};
