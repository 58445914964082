export const base64ToArrayBuffer: (data: string) => Uint8Array = data => {
  const bString = window.atob(data);
  return new Uint8Array(bString.length).map((_, i) => bString.charCodeAt(i));
};

export const base64toPDF: (base64: string) => void = base64 => {
  const bufferArray = base64ToArrayBuffer(base64);
  const blobStore = new Blob([bufferArray], { type: 'application/pdf' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blobStore);
    return;
  }
  const data = window.URL.createObjectURL(blobStore);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = data;
  link.download = 'file.pdf';
  link.click();
  window.URL.revokeObjectURL(data);
  link.remove();
};
