export const BellNotificationIcon = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.05021 3.05025C8.36296 1.7375 10.1434 1 12 1C13.8565 1 15.6369 1.7375 16.9497 3.05025C18.2625 4.36301 19 6.14349 19 8C19 11.3527 19.717 13.4346 20.3779 14.6461C20.7096 15.2544 21.0327 15.6535 21.2572 15.8904C21.3696 16.0091 21.458 16.0878 21.5112 16.1322C21.5379 16.1544 21.5557 16.168 21.5634 16.1737C21.5646 16.1746 21.5656 16.1753 21.5663 16.1758C21.9248 16.4221 22.0834 16.8725 21.957 17.2898C21.8293 17.7115 21.4406 18 21 18H2.99996C2.55929 18 2.17056 17.7115 2.04286 17.2898C1.91652 16.8725 2.07516 16.4221 2.43365 16.1758C2.43435 16.1753 2.4353 16.1746 2.4365 16.1737C2.4442 16.168 2.46206 16.1544 2.48868 16.1322C2.54189 16.0878 2.63028 16.0091 2.74275 15.8904C2.96718 15.6535 3.29027 15.2544 3.62206 14.6461C4.28289 13.4346 4.99996 11.3527 4.99996 8C4.99996 6.14348 5.73745 4.36301 7.05021 3.05025ZM2.44375 16.169C2.44383 16.1689 2.44391 16.1688 2.44399 16.1688C2.44399 16.1688 2.44398 16.1688 2.44398 16.1688L2.44375 16.169ZM5.14928 16H18.8506C18.7745 15.8753 18.6982 15.7434 18.6221 15.6039C17.7829 14.0654 17 11.6473 17 8C17 6.67392 16.4732 5.40215 15.5355 4.46447C14.5978 3.52678 13.326 3 12 3C10.6739 3 9.4021 3.52678 8.46442 4.46447C7.52674 5.40215 6.99996 6.67392 6.99996 8C6.99996 11.6473 6.21702 14.0654 5.37785 15.6039C5.30175 15.7434 5.22537 15.8753 5.14928 16Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.76817 20.135C10.2459 19.8579 10.8578 20.0205 11.1349 20.4983C11.2228 20.6498 11.349 20.7756 11.5008 20.863C11.6526 20.9505 11.8247 20.9965 11.9999 20.9965C12.1751 20.9965 12.3472 20.9505 12.4991 20.863C12.6509 20.7756 12.777 20.6498 12.8649 20.4983C13.1421 20.0205 13.754 19.8579 14.2317 20.135C14.7094 20.4122 14.8721 21.0241 14.5949 21.5018C14.3312 21.9564 13.9527 22.3338 13.4973 22.5961C13.0419 22.8584 12.5255 22.9965 11.9999 22.9965C11.4744 22.9965 10.958 22.8584 10.5026 22.5961C10.0472 22.3338 9.66866 21.9564 9.40494 21.5018C9.12782 21.0241 9.29044 20.4122 9.76817 20.135Z'
    />
  </svg>
);
