import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { EducationCategoriesKey } from 'components/Education/Categories/types';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import { Wrap, StyledTitle, StyledCategories, StyledList } from './styles';

const titles = {
  courses: 'Саморазвитие',
  trainings: 'Встречи',
  events: 'Мероприятия',
  coach_school: 'Inner insight',
  'vip-club': 'VIP-клуб',
};

export const Education: React.FC<
  RouteComponentProps<{ key: EducationCategoriesKey }>
> = ({ match }) => {
  const { key } = match.params;

  useScrollToTopOnMount();
  return (
    <div>
      <Wrap>
        <StyledTitle>{titles[key]}</StyledTitle>
        <StyledCategories categoryKey={key} />
        <StyledList />
      </Wrap>
    </div>
  );
};
