import React from 'react';
import { accordionData } from './accordionData';
import { FileLargeIcon } from 'icons/';
import { Accordion } from 'components/common/Accordion';
import {
  Wrap,
  Title,
  DownloadDoc,
  DownloadDocIcon,
  DownloadDocText,
  DownloadDocVolume,
  NoData,
} from './styles';
import { useStore } from 'effector-react';
import { Preloader } from 'ui/Preloader';
import { $legalDocuments } from 'models/legal-documents/model';
import { useLegalDocumentsQuery } from 'queries/useLegalDocumentsQuery';
import { baseUrl } from 'api/apiClient';

export const Docs: React.FC = props => {
  const { isLoading, isFetching, isError } = useLegalDocumentsQuery();
  const data = useStore($legalDocuments);
  const showPreloader = isLoading || isFetching;

  return (
    <Wrap {...props}>
      {accordionData.map(({ id, title, text }) => (
        <Accordion key={id} title={title} text={text} />
      ))}

      <Title>Документы</Title>
      {showPreloader ? (
        <Preloader />
      ) : data.length ? (
        data
          .filter(doc => doc.activity)
          .map(({ id, name, file, size }) => (
            <DownloadDoc href={baseUrl + file} key={id} target='_blank'>
              <DownloadDocIcon>{FileLargeIcon}</DownloadDocIcon>
              <DownloadDocText>{name}</DownloadDocText>
              <DownloadDocVolume>
                {Math.round(parseInt(size))} Кб
              </DownloadDocVolume>
            </DownloadDoc>
          ))
      ) : (
        <NoData>
          {isError ? 'Не удалось загрузить документы' : 'Нет документов'}
        </NoData>
      )}
    </Wrap>
  );
};
