import React from 'react';
import { Wrap, Title, Description, Text } from './styles';
import { NoDataProps } from './types';

export const NoData: React.FC<NoDataProps> = ({
  title = 'В этом чате пока нет сообщений',
  description = 'Вы можете задать любой интересующий вас вопрос по поводу взаимодействия с сайтом bsm.ru',
}) => {
  return (
    <Wrap>
      <Text>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Text>
    </Wrap>
  );
};
