import { combine, createEffect, createEvent, createStore } from 'effector';
import { AxiosError, AxiosResponse } from 'axios';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import {
  CalendarCategories,
  CalendarCategoriesKeys,
  CalendarCategoriesStore,
  CalendarCategory,
  CalendarDateResponse,
  CalendarEventKeys,
  CalendarEventType,
  CalendarStore,
  DropdownStateItemType,
  GetCalendarRequestType,
  MonthDaysType,
} from './types';
import { useStore, useStoreMap } from 'effector-react';
import {
  areIntervalsOverlapping,
  endOfMonth,
  format,
  getDaysInMonth,
  getWeekOfMonth,
  isBefore,
  isSameDay,
  isWithinInterval,
  set,
  startOfMonth,
} from 'date-fns';
import { beginningOfDay, endOfDay } from 'constants/timeConstants';
import { formatDayEventInMonth } from 'components/Calendar/helpers/formatDayEventInMonth';

const calendaryCategoriesInitialState: CalendarCategoriesStore = [
  { key: 'all', title: 'Все события', checked: true },
];

// События
export const setCalendarCategories = createEvent<CalendarCategories>();
export const selectCalendarCategory = createEvent<number>();
export const selectAllCalendarCategories = createEvent();
export const deselectAllCalendarCategories = createEvent();
export const setCalendarData = createEvent<CalendarDateResponse>();
export const toggleActiveEventsSwitch = createEvent<boolean>();

// Сторы
export const $calendarCategories = createStore<CalendarCategoriesStore>(
  calendaryCategoriesInitialState
);
// Стор в котором хранится готовые для передачи в $calendarData стор через фильтрацию и другие манипуляции.
// Он не должен изменяться каким-либо образом
export const $calendarData = createStore<CalendarStore>([]);
export const $activeEventSwitchState = createStore(false);
// Стор в которой данные, которые непосредственно отправляются во вью
export const $calendarEvents = combine(
  $calendarData,
  $activeEventSwitchState,
  (calendarStore, isSwitchOn) => {
    const currentDate = new Date();
    if (isSwitchOn) {
      return calendarStore.reduce((acc, event) => {
        const eventEndDate = new Date(event.date_end);
        if (isBefore(currentDate, eventEndDate)) {
          acc.push(event);
        }
        return acc;
      }, [] as CalendarStore);
    } else return calendarStore;
  }
);

// Эффекты
export const getCalendarCategoriesFx = createEffect<
  void,
  AxiosResponse<{ data: CalendarCategories }>,
  AxiosError<{ error: string }>
>(() => {
  return apiClient.get(Endpoint.GET_CALENDAR_CATEGORIES);
});

export const getCalendarFx = createEffect<
  GetCalendarRequestType,
  AxiosResponse<{ data: CalendarDateResponse }>,
  AxiosError<{ error: string }>
>(data => {
  return apiClient.post(Endpoint.GET_CALENDAR_PAGE, data);
});

export const handleCategoryChange = createEvent<{
  item: CalendarCategory;
  index: number;
}>();

// Подписки
$calendarCategories
  .on(setCalendarCategories, (state, payload) => {
    const newCategoriesArray = Array.from(calendaryCategoriesInitialState);
    const payloadKeys = Object.keys(payload);
    return payloadKeys.reduce((acc, key) => {
      const title = payload[key as CalendarCategoriesKeys];
      acc.push({
        key,
        title,
        checked: true,
      } as DropdownStateItemType);
      return acc;
    }, newCategoriesArray);
  })
  .on(selectCalendarCategory, (state, payload) => {
    const newArr = Array.from(state);
    newArr[payload] = {
      ...newArr[payload],
      checked: !newArr[payload].checked,
    };
    const categoryAllIndex = newArr?.findIndex(item => item.key === 'all');
    if (
      //Если "Все события" выбраны, но какая-то из категорий не выбрана, то снимается флаг с "Все события"
      newArr.find(item => item.key === 'all')?.checked &&
      newArr.find(item => item.key !== 'all' && !item.checked)
    ) {
      newArr[categoryAllIndex].checked = false;
    } else if (
      //Если "Все события" не выбраны, но все категории выбраны, то ставится флаг "Все события"
      !newArr.find(item => item.key === 'all')?.checked &&
      !newArr.find(item => item.key !== 'all' && !item.checked)
    ) {
      newArr[categoryAllIndex].checked = true;
    }
    return newArr;
  })
  .on(selectAllCalendarCategories, state => {
    return state.map(item => ({ ...item, checked: true }));
  })
  .on(deselectAllCalendarCategories, state => {
    return state.map(item => ({ ...item, checked: false }));
  });

$calendarData.on(setCalendarData, (state, payload) =>
  Object.keys(payload)
    .map(key => payload[key as CalendarEventKeys])
    // Разворачиваем массив массивов в единый массив
    ?.flat()
    // Сортируем события по стартовой дате
    ?.sort((a, b) =>
      a && b && isBefore(new Date(a.date_start), new Date(b.date_start))
        ? -1
        : +1
    )
);

$activeEventSwitchState.on(toggleActiveEventsSwitch, (_, payload) => payload);
//Данные, которые отдаются в рендер берутся из стора отформатированных значений ответа сервера.
$calendarEvents.on($calendarData, (_, store) => store);

handleCategoryChange.watch(({ item, index }) => {
  if (item.key === 'all') {
    if (item.checked) {
      deselectAllCalendarCategories();
    } else {
      selectAllCalendarCategories();
    }
  } else {
    selectCalendarCategory(index);
  }
});

// Селекторы
export const useCalendarCategories: () => CalendarCategoriesStore = () =>
  useStore($calendarCategories);
export const useActiveEventSwitch: () => boolean = () =>
  useStore($activeEventSwitchState);

export const useEventsInMonth: (current: Date) => MonthDaysType = (
  current: Date
) =>
  useStoreMap<CalendarStore, MonthDaysType, Date[]>({
    store: $calendarEvents,
    keys: [current],
    fn: state => {
      const currentDate = new Date(current);
      //Получаем количество дней в месяце
      const daysInMonth = getDaysInMonth(currentDate);
      //Получаем дату начала месяца. Нас интересует день недели
      const startOfMonthDate = startOfMonth(currentDate);
      //Получаем дату конца месяца. Нас интересует день недели
      const endOfMonthDate = endOfMonth(current);
      //Получается порядковый номер дня недели, чтобы потом сопоставить календарь с днями недели
      const monthStartWeekDay = Number(format(startOfMonthDate, 'i')) - 1;
      //Получается порядковый номер дня недели, чтобы потом сопоставить календарь с днями недели
      const monthEndWeekDay = Number(format(endOfMonthDate, 'i')) - 1;

      const eventsPerMonth = state?.filter(event => {
        const start = event?.date_start && new Date(event.date_start);
        const end = event?.date_end && new Date(event.date_end);
        const lastMonthDay = endOfMonth(currentDate);
        return (
          start &&
          end &&
          areIntervalsOverlapping(
            { start: currentDate, end: lastMonthDay },
            { start, end }
          )
        );
      });

      //Общий массив ячеек месяца с событиями
      const monthDaysArray: MonthDaysType = new Array(monthStartWeekDay).fill({
        day: '',
      });

      //Добавляем ячейки дней месяца
      for (let i = 1; i <= daysInMonth; i++) {
        const currentMonthDayStart = set(currentDate, {
          date: i,
          ...beginningOfDay,
        });
        const currentMonthDayEnd = set(currentDate, {
          date: i,
          ...endOfDay,
        });
        const eventsPerDay = eventsPerMonth?.reduce((acc, event) => {
          const start = new Date(event.date_start);
          const end = new Date(event.date_end);
          if (start && end) {
            if (isSameDay(start, end)) {
              if (
                isWithinInterval(start, {
                  start: currentMonthDayStart,
                  end: currentMonthDayEnd,
                })
              ) {
                acc.push(formatDayEventInMonth(event, currentMonthDayStart));
              }
            } else {
              if (
                areIntervalsOverlapping(
                  { start: currentMonthDayStart, end: currentMonthDayEnd },
                  { start, end }
                )
              ) {
                acc.push(formatDayEventInMonth(event, currentMonthDayStart));
              }
            }
          }

          return acc;
        }, [] as CalendarEventType[]);

        monthDaysArray.push({
          day: i.toString(),
          events: eventsPerDay,
        });
      }

      //Заполнение конца месяца пустыми ячейками
      for (let i = monthEndWeekDay; i < 6; i++) {
        monthDaysArray.push({ day: '' });
      }

      return monthDaysArray;
    },
  });

export const useEventsInDay: (
  current: Date
) => { monthDaysArray: MonthDaysType; weekDaysArray: MonthDaysType } = (
  current: Date
) =>
  useStoreMap<
    CalendarStore,
    { monthDaysArray: MonthDaysType; weekDaysArray: MonthDaysType },
    Date[]
  >({
    store: $calendarEvents,
    keys: [current],
    fn: state => {
      //Получаем количество дней в месяце
      const daysInMonth = getDaysInMonth(current);
      //Получаем дату начала месяца. Нас интересует день недели
      const startOfMonthDate = startOfMonth(current);
      //Получается порядковый номер дня недели, чтобы потом сопоставить календарь с днями недели
      const monthStartWeekDay = Number(format(startOfMonthDate, 'i')) - 1;

      const monthDaysArray: MonthDaysType = [];

      //Создаем пустые ячейки календаря, чтобы дни месяца соотвествовали дням недели
      for (let i = 0; i < monthStartWeekDay; i++) {
        monthDaysArray.push({ day: '' });
      }

      //Добавляем ячейки дней месяца
      for (let i = 1; i <= daysInMonth; i++) {
        const currentMonthDayStart = set(current, {
          date: i,
          ...beginningOfDay,
        });
        const currentMonthDayEnd = set(current, {
          date: i,
          ...endOfDay,
        });

        const eventsPerDay: CalendarEventType[] =
          state?.reduce((acc, event) => {
            const start = !!event.date_start && new Date(event.date_start);
            const end = !!event.date_end && new Date(event.date_end);
            if (isSameDay(start, end)) {
              if (
                start &&
                end &&
                isWithinInterval(start, {
                  start: currentMonthDayStart,
                  end: currentMonthDayEnd,
                })
              ) {
                acc.push(formatDayEventInMonth(event, currentMonthDayStart));
              }
            } else {
              if (
                start &&
                end &&
                areIntervalsOverlapping(
                  { start: currentMonthDayStart, end: currentMonthDayEnd },
                  { start, end }
                )
              ) {
                acc.push(formatDayEventInMonth(event, currentMonthDayStart));
              }
            }
            return acc;
          }, [] as CalendarEventType[]) ?? [];

        monthDaysArray.push({
          day: i.toString(),
          events: eventsPerDay,
        });
      }

      //Общий массив ячеек недели
      const weekDaysArray: MonthDaysType = monthDaysArray.slice(
        (getWeekOfMonth(current, { weekStartsOn: 1 }) - 1) * 7,
        (getWeekOfMonth(current, { weekStartsOn: 1 }) - 1) * 7 + 7
      );

      // Докидываем остаток пустых ячеек для конца недели
      for (let i = weekDaysArray.length; i < 7; i++) {
        weekDaysArray.push({ day: '' });
      }

      return { monthDaysArray, weekDaysArray };
    },
  });
