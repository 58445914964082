import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { useMutation } from 'react-query';
import { UseConfirmEmailChange } from './types';

export const useConfirmEmailChange: UseConfirmEmailChange = () => {
  return useMutation('confirmEmailChange', params =>
    apiClient.post(Endpoint.CHANGE_EMAIL_CONFIRM, {
      verification_code: params.verificationCode,
      otp: params.otp,
    })
  );
};
