import { useGetCalendarCategoriesQuery } from 'queries/calendar/useGetCalendarCategoriesQuery';
import {
  ActiveEvents,
  ActiveEventsSelect,
  ActiveEventsText,
  DropdownList,
  StyledPreloader,
  Wrap,
} from './styles';
import React from 'react';
import { Legend } from './Legend';
import {
  toggleActiveEventsSwitch,
  useActiveEventSwitch,
} from 'models/calendar/model';

export const titles = {
  courses: 'Саморазвитие',
  events: 'Мероприятия',
  trainings: 'Встречи',
  vip: 'VIP club',
};

export const Toolbar: React.FC = props => {
  const { isLoading } = useGetCalendarCategoriesQuery();
  const switchState = useActiveEventSwitch();
  if (isLoading)
    return (
      <Wrap>
        <StyledPreloader />
      </Wrap>
    );

  const handleToggle = () => {
    toggleActiveEventsSwitch(!switchState);
  };
  return (
    <Wrap {...props}>
      <DropdownList />
      <Legend />
      <ActiveEvents>
        <ActiveEventsText>Активные</ActiveEventsText>
        <ActiveEventsSelect isOn={switchState} handleSwitch={handleToggle} />
      </ActiveEvents>
    </Wrap>
  );
};
