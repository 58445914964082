import React from 'react';
// import { MasterClassItem } from 'components/Catalog/common/MasterClassItem';
// import { fakeData } from './fakeData';

export const Meetings: React.FC = () => {
  return (
    <>
      {/*TODO: доделать после доработок бэка*/}
      {/*{fakeData.map(item => (*/}
      {/*  <MasterClassItem key={item.id} {...item} />*/}
      {/*))}*/}
    </>
  );
};
