import {
  Descriptions,
  Icon,
  Title,
  Wrapper,
  TextSC,
  CloseIcon,
} from './styled';
import { HomeIcon } from 'icons/HomeIcon';
import { Address } from 'models/addresses/types';
import { XCloseIcon } from 'icons';
import { useDeleteAddressQuery } from 'queries/addresses/useDeleteAddressQuery';

interface IProps {
  data: Address;
  onDelete: () => void;
}

const AdressItem: React.FC<IProps> = ({ data, onDelete }) => {
  const mutaion = useDeleteAddressQuery();
  const deleteAddress = () => {
    mutaion.mutate(String(data.id), { onSuccess: () => onDelete() });
  };

  return (
    <Wrapper>
      <Icon>{HomeIcon}</Icon>
      <Descriptions>
        <Title>{data.name}</Title>
        <TextSC>{data.address.split(', ').slice(1).join(', ')}</TextSC>
      </Descriptions>
      <CloseIcon
        size={'medium'}
        buttonType={'link'}
        onClick={deleteAddress}
        iconFill={'#b1b1b1'}
        icon={XCloseIcon}
      />
    </Wrapper>
  );
};

export default AdressItem;
