type Progress = {
  done: number;
  total: number;
};
// type Progress = {
//   complete: number;
//   final_session: boolean;
//   final_test: boolean;
//   sessions:number
// };
export type HomeWorkStatus =
  | 'not_send'
  | 'on_checking'
  | 'need_work'
  | 'approved';

export type Lecture = {
  id: number;
  type: LectureType;
  picture?: string;
  name: string;
  order: number;
  date_start: Date;
  activity: boolean;
  materials: Materials;
  homework_status: HomeWorkStatus;
};

export enum LectureType {
  lecture = 'Темы',
  session = 'Коуч-сессия',
  final_session = 'Итоговая коуч-сессия',
  final_testing = 'Итоговый тест',
}

type Materials = {
  audios: number;
  videos: number;
  documents: number;
};

export type SchoolPurchase = {
  status: SchoolPurchaseStatus;
  full_price: number;
  half_price: number;
};

export enum SchoolPurchaseStatus {
  PAID = 'paid',
  NOT_PAID = 'not_paid',
  PREPAID = 'prepaid',
}

export type TCoachingSchools = {
  id: number;
  name: string;
  picture: string;
  age: string;
  activity: boolean;
  purchased: boolean;
  date_start: Date;
  payday: string;
  progress: Progress;
};

export type TCoachingSchool = {
  id: number;
  name: string;
  description: string;
  picture: string;
  activity: boolean;
  flow_number: number;
  seller: string;
  age: string;
  purchase: SchoolPurchase;
  date_start: Date;
  date_end: Date;
  payday: string;
  lectures: Lecture[];
};

export type TMasterClasses = {
  id: number;
  name: string;
  place: string;
  date_event: Date;
  activity: boolean;
  picture: string;
};

export type TMasterClass = {
  id: number;
  name: string;
  description: string;
  price: number;
  place: string;
  limit: number;
  remaining: number;
  date_event: Date;
  activity: boolean;
  picture: string;
};

export type TReply = {
  date_send: Date;
  id: number;
  mark: number;
  message: string;
  user_avatar: string | null;
  user_name: string;
};

export type TTestimonials = {
  rating: number;
  voices: number;
  replies: TReply[];
};
