import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 0 12px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const Item = styled.div`
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: 24px;
  }
`;

export const Icon = styled.div`
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 6px;
  padding: 7px;
  font-size: 0;
  fill: ${({ theme }) => theme.colors.disabledColor};
`;

export const Inner = styled.div`
  margin-left: 16px;
`;

export const Title = styled.p`
  margin: 8px 0 0;
  letter-spacing: 0px;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  margin: 40px 0;
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 8px 0 0;
`;

export const Link = styled.a`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 8px 0 0;
  display: block;
`;

export const Column1 = styled.div`
  grid-area: column1;
`;

export const Column2 = styled.div`
  grid-area: column2;
`;

export const Row = styled.div`
  display: grid;
  grid-template-areas: 'column1 column2';
  grid-template-columns: 440px 285px;
  grid-gap: 24px;
  grid-template-rows: auto;
`;

export const Heading = styled.h2`
  ${({ theme }) => theme.typography.headline1};
  margin: 0;
  width: 100%;
  margin-bottom: 10px;
`;

export const HighText = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 20px 0 0;
  width: 100%;
`;
