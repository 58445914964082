import styled from 'styled-components';
import { Button } from 'ui/Button';

export const Wrap = styled.div`
  position: absolute;
  top: 62px;
  right: 30px;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  width: 414px;
  height: 612px;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.12);
  border-radius: 14px 0 14px 14px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 17px;
`;

export const Title = styled.h3`
  margin: 0;
  ${({ theme }) => theme.typography.chatTitle};
`;

export const Status = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.disabledColor};
  margin-left: 10px;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 25px;
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  height: 1px;
  width: 100%;
`;

export const Body = styled.div`
  padding: 20px;
`;

export const Footer = styled.div`
  padding: 20px;
`;
