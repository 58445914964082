import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';

export const Title = styled.h2`
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 0;
  max-width: 440px;
  margin: 0;
`;

export const Wrap = styled.div`
  display: flex;
`;

export const Subtitle = styled.h3`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  max-width: 440px;
  margin: 0 0 32px 0;
`;

//TODO: Определиться со стилями слайдера с заказчиком
export const ActionButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.mainColor};
  color: ${({ theme }) => theme.colors.mainBgColor};
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.socialMediaNotActiveColor};
  }
`;
export const StyledLink = styled(Link)`
  display: block;
  margin-top: auto;
`;

export const Slide = styled.div<{ image: string }>`
  border-radius: 14px;
  padding: 40px 60px 55px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  ${({ image }) => `background-image: url(${image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 338px;
  &:nth-of-type(3n - 2) {
    background-color: ${({ theme }) => theme.colors.thirdBgColor};
  }
  &:nth-of-type(3n - 1) {
    background-color: ${({ theme }) => theme.colors.actionColor};
  }
  &:nth-of-type(3n) {
    background-color: ${({ theme }) => theme.colors.thirdColor};
  }

  & ${Title} + ${Subtitle} {
    margin-top: 30px;
  }
`;

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto;
`;
