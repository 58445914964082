import { useMutation, UseMutationResult } from 'react-query';
import { purchaseVipClubSubscriptionFx } from 'models/catalog/model';
import { AxiosError, AxiosResponse } from 'axios';

export const usePurchaseVIPClubSubscriptionQuery: () => UseMutationResult<
  AxiosResponse<{
    order_id: number;
  }>,
  AxiosError<{ error: string }>,
  number
> = () =>
  useMutation('puchaseVIPClub', id => purchaseVipClubSubscriptionFx(id));
