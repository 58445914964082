import React from 'react';

export const PositiveIcon = (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.998047 18.8352C0.998047 15.6082 3.22628 12.9197 6.30544 11.5974C6.74264 11.4096 7.23338 11.6552 7.38538 12.106C7.53738 12.5569 7.29271 13.0416 6.85816 13.2354C4.3225 14.3663 2.72112 16.5079 2.72112 18.8352C2.72112 20.3783 3.41716 21.8139 4.62023 22.9566L4.90524 23.2273L4.88759 23.62C4.84604 24.5445 4.672 25.5725 4.36355 26.5613C5.70054 26.1325 6.5889 25.561 7.13751 25.0426L7.5001 24.7L7.97774 24.8439C8.85585 25.1084 9.80363 25.2555 10.7991 25.2555C12.5057 25.2555 14.0761 24.8355 15.3722 24.1277C15.7898 23.8996 16.3248 23.994 16.595 24.3856C16.8653 24.7772 16.7685 25.3171 16.355 25.5525C14.7661 26.457 12.8517 26.9785 10.7991 26.9785C9.80557 26.9785 8.84877 26.8534 7.94548 26.623C7.03943 27.3562 5.73334 28.0325 3.95392 28.4646C2.93072 28.714 2.08648 27.6873 2.47338 26.7387C2.83831 25.8305 3.0619 24.8243 3.14144 23.9153C1.82417 22.5425 0.998047 20.7863 0.998047 18.8352ZM3.5454 26.7907L3.54735 26.7902L3.5454 26.7907Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.5959 12.9047C16.1201 12.9047 15.7344 12.519 15.7344 12.0432V10.6964C15.7344 10.2206 16.1201 9.83484 16.5959 9.83484C17.0717 9.83484 17.4575 10.2206 17.4575 10.6964V12.0432C17.4575 12.519 17.0717 12.9047 16.5959 12.9047Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.1213 12.9047C21.6455 12.9047 21.2598 12.519 21.2598 12.0432V10.6964C21.2598 10.2206 21.6455 9.83484 22.1213 9.83484C22.5971 9.83484 22.9828 10.2206 22.9828 10.6964V12.0432C22.9828 12.519 22.5971 12.9047 22.1213 12.9047Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.75195 13.8257C7.75195 8.2875 13.1038 4.06152 19.3581 4.06152C25.6124 4.06152 30.9642 8.2875 30.9642 13.8257C30.9642 16.2932 29.8723 18.5152 28.1328 20.2078C28.2641 21.4099 28.5609 22.6928 29.0356 23.8746L29.0361 23.8758C29.4587 24.9323 28.529 26.0879 27.3704 25.8068C24.9527 25.2214 23.2167 24.3002 22.0153 23.3231C21.1638 23.4939 20.2739 23.5899 19.3581 23.5899C13.1038 23.5899 7.75195 19.364 7.75195 13.8257ZM19.3581 5.7846C13.7381 5.7846 9.47503 9.52805 9.47503 13.8257C9.47503 18.1234 13.7381 21.8669 19.3581 21.8669C20.2884 21.8669 21.1927 21.7527 22.0542 21.5517C22.3282 21.4878 22.6163 21.5617 22.8257 21.7498C23.7198 22.553 25.1257 23.4062 27.2382 23.9925C26.7596 22.6492 26.4797 21.236 26.3766 19.9354C26.3553 19.6675 26.4603 19.405 26.6604 19.2257C28.2833 17.7713 29.2412 15.8754 29.2412 13.8257C29.2412 9.52805 24.9781 5.7846 19.3581 5.7846Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.2971 13.5198C12.7729 13.5198 13.1494 13.9113 13.2671 14.3723C13.7906 16.4239 16.1623 18.1841 19.3578 18.1841C22.5533 18.1841 24.925 16.4239 25.4486 14.3723C25.5662 13.9113 25.9428 13.5198 26.4186 13.5198C26.8944 13.5198 27.2875 13.9086 27.2116 14.3783C26.6848 17.6373 23.1951 19.9071 19.3578 19.9071C15.5206 19.9071 12.0308 17.6373 11.5041 14.3783C11.4281 13.9086 11.8213 13.5198 12.2971 13.5198Z'
      fill='#181818'
    />
  </svg>
);

