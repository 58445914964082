import styled from 'styled-components';

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline1}
`;

export const List = styled.div`
  margin: 24px 0 0;
  border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin: 20px 0 0;
  }
`;

export const ItemText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
  text-align: right;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.secondTextColor};
`;

export const Icon = styled.div<{ bgUrl: string }>`
  width: 60px;
  height: 60px;
  margin: 0 40px 0 0;
  background-image: url(${({ bgUrl }) => bgUrl});
`;

export const ItemTitle = styled.p`
  ${({ theme }) => theme.typography.bannerDescription};
`;

export const ItemSubtitle = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;
