export const SpinnerIcon = (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16 32C15.1716 32 14.5 31.3284 14.5 30.5C14.5 29.6716 15.1716 29 16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 17.5569 3.27312 19.0741 3.80018 20.5019C4.08708 21.279 3.68963 22.1416 2.91247 22.4285C2.13531 22.7154 1.27272 22.318 0.985825 21.5408C0.336423 19.7817 0 17.9128 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32Z'
      fill='#FED74D'
    />
  </svg>
);
