import React from 'react';
import { useGetSecretChatsQuery } from 'queries/catalog/useGetSecretChatsQuery';
import { useSecretChatList } from 'models/catalog/model';
import {
  NoData,
  StyledBanner,
  StyledPreloader,
  Title,
  Wrap,
  Text,
  Divider,
  Item,
  List,
} from './styles';
import { Features } from './Features';
import { Subscription } from './Subscription';
import BannerImage from 'images/secret_chat_banner.png';

export const Secret: React.FC = props => {
  const { isLoading } = useGetSecretChatsQuery();
  const data = useSecretChatList();
  const lowestPrice =
    data?.sort((a, b) => (a.price >= b.price ? +1 : -1))?.[0]?.price || 0;

  return isLoading ? (
    <StyledPreloader />
  ) : data.length ? (
    <Wrap {...props}>
      <StyledBanner
        data={{
          name: 'Секретный Telegram канал',
          description:
            'Кладезь ценнейшей информации по комфортной стоимости.\n' +
            'Максимально просто и доступно.',
          price: lowestPrice,
          picture: BannerImage,
        }}
      />
      <Title>Описание</Title>
      <Text>
        Читай, слушай, делай! <br />
        <br /> Только для тех, кто принял решение жить ОСОЗНАННО, кто понимает
        суть и политику проекта budsmuzhikom, кто отказался от идеализации и
        прочих ограничивающих установок. <br />
        <br />
        Только для тех, кто согласен, что УСПЕХ НЕИЗБЕЖЕН!
      </Text>
      <Divider />
      <Features />
      <Divider />
      <Title>Правила</Title>
      <List>
        <Item>
          Для купивших абонемент изначальная стоимость сохраняется на ВЕСЬ
          период его действия.
        </Item>
        <Item>
          До окончания периода действия можно оплатить продление абонемента все
          по той же цене.
        </Item>
        <Item>
          При отсутствии продления по окончании срока действия абонемент
          считается аннулированным и “заморозке” не подлежит. Дальнейшая
          подписка возможна только по новой цене, если она к тому времени
          менялась.
        </Item>
        <Item>
          При досрочном отказе от использования оплаченного абонемента возврат
          денег производится согласно учтенному времени пребывания на канале
          (вопросы по возврату СТРОГО по адресу электронной почты
          tele@budsmuzhikom.ru).
        </Item>
      </List>
      <Divider /> <Title>Обратите внимание!</Title>
      <List>
        <Item>
          Проверьте, что к вашему аккаунту привязан Ваш действующий НИК В
          ТЕЛЕГРАМ.
        </Item>
        <Item>
          После оплаты в течение суток с Вами свяжется администратор, и Вы
          будете подключены к секретному каналу BSM.{' '}
        </Item>
        <Item>
          Необходимое условие для подключения: Отрегулируйте настройки
          приватности таким образом, чтобы сделать ник и номер телефона
          ВИДИМЫМИ.{' '}
        </Item>
        <Item>
          Если в течение суток администратор с Вами не связался, следует
          сообщить об этом письмом по адресу{' '}
          <a href='mailto:tele@budsmuzhikom.ru'>tele@budsmuzhikom.ru</a>
        </Item>
      </List>
      <Divider />
      <Subscription data={data} />
    </Wrap>
  ) : (
    <NoData>Нет доступных чатов</NoData>
  );
};
