import { createEffect, createEvent, restore } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { AxiosError, AxiosResponse } from 'axios';
import { useStore } from 'effector-react';
import { PlansCategoryType, PlansResponseType } from './types';

export const setPlansData = createEvent<PlansResponseType>();
export const setPlansCategories = createEvent<PlansCategoryType>();

export const $plansData = restore<PlansResponseType>(setPlansData, {});
export const $plansCategories = restore<PlansCategoryType>(
  setPlansCategories,
  {}
);

export const getPlansDataFx = createEffect<
  string,
  AxiosResponse<{
    data: PlansResponseType;
  }>,
  AxiosError<{
    error: string;
  }>
>(category => apiClient.post(Endpoint.GET_PLANS, { category: category }));

export const getPlansCategoriesFx = createEffect<
  void,
  AxiosResponse<{
    data: PlansCategoryType;
  }>,
  AxiosError<{
    error: string;
  }>
>(() => apiClient.get(Endpoint.GET_PLANS_CATEGORIES));

export const usePlansCategories: () => PlansCategoryType = () =>
  useStore($plansCategories);
export const usePlansData: () => PlansResponseType = () => useStore($plansData);
