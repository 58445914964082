import { useQuery } from 'react-query';
import { CoachingSchoolsQuery } from './types';
import {
  getCoachingSchoolsFx,
  setCoachingActiveSchool,
  setCoachingSchools,
} from 'models/inner-insight/model';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';

export const useGetCoachingSchoolsQuery: CoachingSchoolsQuery = () =>
  useQuery('schools', getCoachingSchoolsFx, {
    onSuccess: res => {
      setCoachingSchools(res.data.data);
      const activeSchool =
        res.data.data.find(({ activity }) => activity) ?? null;
      setCoachingActiveSchool(activeSchool);
    },

    onError: res =>
      toast.error(<ErrorMessage text={res.response?.data.error} />),
    refetchOnWindowFocus: false,
    retry: false,
  });
