import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';

export const Inner = styled.div`
  margin-left: auto;
  width: 280px;
`;

export const Wrap = styled.div<{ bg?: string }>`
  padding: 43px 110px 43px 0;
  ${({ bg }) => !!bg && `background-image: url('${bg}')`};
  background-size: cover;
  border-radius: 14px;
`;

export const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 16px 0 0;
  color: ${({ theme }) => theme.colors.mainBgColor};
`;
export const Title = styled.h1`
  ${({ theme }) => theme.typography.headline1};
  color: ${({ theme }) => theme.colors.mainBgColor};
  margin: 0;
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

export const Price = styled.span`
  ${({ theme }) => theme.typography.headline3}
  color: ${({ theme }) => theme.colors.mainBgColor};
`;

export const StyledPreloader = styled(Preloader)``;

export const BannerButton = styled(Button)`
  margin-left: auto;
`;
