import MoreIcon from 'icons/MoreIcon';
import React from 'react';
import { AttentionSmallIcon } from 'icons/AttentionSmallIcon';
import { Link, useRouteMatch } from 'react-router-dom';

import {
  Header,
  ListOfProducts,
  MoreInfoButton,
  OrderDate,
  OrderState,
  OrderStateWrapper,
  OrderTotalSumm,
  Preview,
  ScrollContainerSC,
  Title,
  TrackNumber,
  Wrapper,
  NoPhotoText,
} from './styled';
import {
  NotAcceptedOrderStatuses,
  TBasket,
  TOrderItem,
} from 'models/orders/types';
import { declension } from 'utilities/declension';
import { baseUrl } from 'api/apiClient';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateFormats } from 'constants/dateFormats';
import Ruble from 'components/common/Ruble';
import { theme } from 'ui/theme';
import { formatPriceToString } from 'utilities/formatPriceToString';

interface IProps {
  approved?: boolean;
  data: TOrderItem;
}

// TODO лучше использовать useStoreMap на уровне модели
function getBasketItems(data: IProps['data']) {
  const fields = Object.keys(data.basket);
  const basketItems: TBasket[] = [];
  for (const field of fields) {
    basketItems.push(data.basket[field]);
  }

  return basketItems;
}

export const OrderItem: React.FC<IProps> = ({ data }) => {
  const matchUrl = useRouteMatch()?.url || '';

  // Пока все статусы кроме "Отменен" окрашиваются желтым, возможно так и останется, нужно смотреть боевые заказы
  const isPositiveStatus = !Object.values(NotAcceptedOrderStatuses).includes(
    data.status as NotAcceptedOrderStatuses
  );

  let basketItemsCount = Object.keys(data.basket).length;
  if (basketItemsCount === 1) {
    basketItemsCount = getBasketItems(data)[0].quantity;
  }
 
  return (
    <Wrapper>
      <Header>
        <Title>{`Заказ №${data.id}`}</Title>
        <OrderDate>
          от{' '}
          {format(new Date(data.date_insert), DateFormats['StartEventDate'], {
            locale: ru,
          })}
        </OrderDate>
        {/* TODO узнать - по ховеру на OrderItem подсвечивать кнопку MoreInfoButton ?*/}
        <Link to={`${matchUrl}/${data.id}`}>
          <MoreInfoButton
            hoverColor={theme.colors.actionColor}
            buttonType='link'
            icon={MoreIcon}
          />
        </Link>
      </Header>
      <OrderStateWrapper>
        <OrderState approved={isPositiveStatus}>
          {!isPositiveStatus && AttentionSmallIcon}
          {data.status}
        </OrderState>
      </OrderStateWrapper>
      {isPositiveStatus && (
        <>
          <OrderTotalSumm>{`${basketItemsCount} ${declension(
            ['товар', 'товара', 'товаров'],
            basketItemsCount
          )} на сумму ${parseInt(data.price)} ${Ruble}`}</OrderTotalSumm>
          {data.tracking && (
            <TrackNumber>{`Трек номер: ${data.tracking}`}</TrackNumber>
          )}
        </>
      )}
      <ListOfProducts>
        <ScrollContainerSC horizontal={true} vertical={false}>
          {getBasketItems(data).map(item => (
            <Preview key={item.product_id}>
              {item.image ? (
                <img src={baseUrl + item.image} alt={item.name} />
              ) : (
                <NoPhotoText>
                  {formatPriceToString(parseFloat(data.price))}
                </NoPhotoText>
              )}
            </Preview>
          ))}
        </ScrollContainerSC>
      </ListOfProducts>
    </Wrapper>
  );
};
