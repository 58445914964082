import { createEffect, createEvent, restore } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { TOrderItem } from './types';
import { AxiosError, AxiosResponse } from 'axios';

export const setOrders = createEvent<TOrderItem[]>();
export const clearOrders = createEvent();

export const $orders = restore(setOrders, []).reset(clearOrders);

export const ordersFx = createEffect<
  number,
  AxiosResponse<{
    data: TOrderItem[];
  }>,
  AxiosError<{
    error: string;
  }>
>((offset: number) =>
  apiClient.post(Endpoint.ORDER_LIST, {
    offset,
    limit: 12,
  })
);
