import { createEffect, createEvent, createStore } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import {
  LoginValuesType,
  PasswordFields,
  RegistrationFields,
  UserProfileType,
} from './types';
import { AxiosError, AxiosResponse } from 'axios';
import { useStore } from 'effector-react';
import { ServerValidationError } from '../../interfaces/formattedValidationError';
import { ChangePasswordRequest } from '../../queries/account/changePassword/types';

// TODO: Сделать селекторы для сторов

// Сторы

// TODO: Переделать на restore(event, defState)
export const $user = createStore<UserProfileType | null>(null);
export const $phone = createStore('');
export const $verificationCode = createStore('');
export const $registrationData = createStore<RegistrationFields>({});
export const $showModal = createStore(false);

export const useUserProfile = (): UserProfileType | null => useStore($user);

// Эффекты
//TODO: разобраться с типами
export const loginFx = createEffect<
  LoginValuesType,
  AxiosResponse<{ token: string }>,
  AxiosError<{ error: string }>
>(async values => await apiClient.post(Endpoint.LOGIN, values));

export const getUserFx = createEffect<
  void,
  AxiosResponse<UserProfileType>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_USER_PROFILE));

export const restorePhoneFx = createEffect<
  { phone?: string },
  AxiosResponse<{ message: string; verification_code: string }>,
  AxiosError<{ error: string }>
>(phone => apiClient.post(Endpoint.RESTORE_PASSWORD, phone));

export const confirmCodeFx = createEffect<
  { otp: string; verification_code: string },
  AxiosResponse<{ message: string; verification_code: string }>,
  AxiosError<{ error: string }>
>(({ otp, verification_code }) =>
  apiClient.post(Endpoint.CONFIRM_CODE, {
    otp,
    verification_code,
  })
);

export const setNewPasswordFx = createEffect<
  { new_password: string; repeat_password: string; verification_code: string },
  AxiosResponse,
  AxiosError<{ error: string }>
>(({ new_password, repeat_password, verification_code }) =>
  apiClient.post(Endpoint.CONFIRM_NEW_PASSWORD, {
    new_password,
    repeat_password,
    verification_code,
  })
);

export const registerFx = createEffect<
  RegistrationFields,
  AxiosResponse,
  AxiosError<{ error: string }>
>(data => apiClient.post(Endpoint.REGISTER_USER, data));

export const confirmRegistrationFx = createEffect<
  PasswordFields & { verification_code: string },
  AxiosResponse,
  AxiosError<{ error: string }>
>(val => apiClient.post(Endpoint.CONFIRM_REGISTRY, val));

export const changePasswordFx = createEffect<
  ChangePasswordRequest,
  AxiosResponse,
  AxiosError<{ error: ServerValidationError }>
>(({ new_password, password_repeat }) => {
  return apiClient.post(Endpoint.CHANGE_PASSWORD, {
    new_password,
    password_repeat,
  });
});

// События
export const setUser = createEvent<UserProfileType>();
export const removeUser = createEvent();
export const setVerificationCode = createEvent<string>();
export const setPhone = createEvent<string>();
export const setRegistrationFields = createEvent<RegistrationFields>();
export const clearStore = createEvent();
export const showAuthModal = createEvent();
export const hideAuthModal = createEvent();

// Подписки
$user.on(setUser, (state, payload) => payload).reset(removeUser);
$phone.on(setPhone, (state, payload) => payload).reset(clearStore);
$verificationCode
  .on(setVerificationCode, (state, payload) => payload)
  .reset(clearStore);
$registrationData
  .on(setRegistrationFields, (state, payload) => payload)
  .reset(clearStore);
$showModal.on(showAuthModal, () => true).reset(hideAuthModal);

export const useRegistrationData: () => RegistrationFields = () =>
  useStore($registrationData);
