import styled from 'styled-components';

export const Wrap = styled.div`
  & .plyr {
    position: relative;
    border-radius: 14px;
    --plyr-color-main: ${({ theme }) => theme.colors.actionColor};

    &-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      &__container {
        display: block;
        animation: flow 50s infinite;
        height: 100%;
        width: 100%;

        @keyframes flow {
          // верхний левый угол -50% -50%
          // верхний правый угол 50% -50%
          // нижний левый угол -50% 50%
          // нижний правый угол 50% 50%
          0%,
          100%,
          50% {
            transform: translate(0%, 0%);
          }
          12.5% {
            transform: translate(calc(100% - 300px), 0%);
          }
          25% {
            transform: translate(0%, calc(100% - 60px));
          }
          37.5% {
            transform: translate(calc(100% - 300px), calc(100% - 60px));
          }
          62.5% {
            transform: translate(0%, calc(100% - 60px));
          }
          75% {
            transform: translate(calc(100% - 300px), 0%);
          }
          87.5% {
            transform: translate(calc(100% - 300px), calc(100% - 60px));
          }
        }
      }

      &__text {
        ${({ theme }) => theme.typography.headline2};
        color: ${({ theme }) => theme.colors.actionColor};
        width: 300px;
        height: 60px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }
    }
  }

  & + & {
    margin: 24px 0 0;
  }
`;
