import { apiClient } from 'api/apiClient';
import { TOrderDedail } from 'models/orders/types';
import { useQuery } from 'react-query';

// TODO сделать норм типизацию
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetOrderInfoQuery = (id: string) => {
  const { data, ...rest } = useQuery(
    'orderInfo',
    async () => await apiClient.get(`/api/v1/order/${id}/detail`),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { data: data?.data as TOrderDedail, ...rest };
};
