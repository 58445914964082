import React from 'react';

export const PracticeIcon = (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.59961 24.0506V7.54831C1.59961 7.07249 1.98533 6.68677 2.46115 6.68677C2.93696 6.68677 3.32269 7.07249 3.32269 7.54831V23.7878H11.7957C12.5635 23.7878 13.3034 24.0944 13.8479 24.6389C14.0708 24.8618 14.3671 24.9853 14.6817 24.9853H17.7832C18.0932 24.9853 18.3951 24.8608 18.617 24.6389C19.1605 24.0954 19.8941 23.7878 20.6692 23.7878H29.1422V18.9246C29.1422 18.4488 29.528 18.0631 30.0038 18.0631C30.4796 18.0631 30.8653 18.4488 30.8653 18.9246V24.0506C30.8653 24.8557 30.2101 25.5109 29.405 25.5109H20.6692C20.3546 25.5109 20.0583 25.6344 19.8354 25.8573C19.291 26.4017 18.551 26.7084 17.7832 26.7084H14.6817C13.9065 26.7084 13.173 26.4008 12.6295 25.8573C12.4075 25.6353 12.1056 25.5109 11.7957 25.5109H3.0599C2.25477 25.5109 1.59961 24.8557 1.59961 24.0506Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.99414 5.02899C3.99414 4.47671 4.44186 4.02899 4.99414 4.02899H7.25069V5.75207H5.71722V21.3928H13.2382C14.1083 21.3928 14.8173 21.6576 15.3704 22.029V8.48305C15.3704 7.67629 15.129 7.00046 14.7074 6.53641C14.2981 6.08591 13.6446 5.75207 12.6395 5.75207H10.8432V4.02899H12.6395C14.0593 4.02899 15.202 4.51843 15.9827 5.37771C16.7511 6.22343 17.0935 7.34386 17.0935 8.48305V24.3134H15.2025L14.944 23.9688C14.6167 23.5324 14.0669 23.1159 13.2382 23.1159H4.99414C4.44186 23.1159 3.99414 22.6682 3.99414 22.1159V5.02899Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.4819 5.37771C17.2626 4.51843 18.4053 4.02899 19.8251 4.02899H27.4705C28.0228 4.02899 28.4705 4.47671 28.4705 5.02899V5.82525C28.4705 6.30106 28.0847 6.68679 27.6089 6.68679C27.1331 6.68679 26.7474 6.30106 26.7474 5.82525V5.75207H19.8251C18.82 5.75207 18.1665 6.08591 17.7572 6.53641C17.3356 7.00046 17.0942 7.67629 17.0942 8.48305V21.9112C17.1885 21.8517 17.2871 21.7973 17.3899 21.7481C17.9462 21.482 18.5748 21.3928 19.2264 21.3928H26.7474V20.7209C26.7474 20.2451 27.1331 19.8593 27.6089 19.8593C28.0847 19.8593 28.4705 20.2451 28.4705 20.7209V22.1159C28.4705 22.6682 28.0228 23.1159 27.4705 23.1159H19.2264C18.7164 23.1159 18.372 23.1884 18.1335 23.3025C17.9157 23.4067 17.7413 23.5661 17.5977 23.8455L17.3574 24.3134H15.3711V8.48305C15.3711 7.34386 15.7135 6.22343 16.4819 5.37771Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.39062 2.83075H11.7062V10.6186C11.7062 11.4173 10.8161 11.8937 10.1515 11.4507L9.04842 10.7153L7.94533 11.4507C7.28077 11.8937 6.39062 11.4173 6.39062 10.6186V2.83075ZM8.1137 4.55383V9.26752L9.04842 8.64437L9.98314 9.26752V4.55383H8.1137Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.6155 8.74438C22.1152 8.74438 20.0883 10.7713 20.0883 13.2716C20.0883 15.7719 22.1152 17.7988 24.6155 17.7988C27.1159 17.7988 29.1428 15.7719 29.1428 13.2716C29.1428 10.7713 27.1159 8.74438 24.6155 8.74438ZM18.3652 13.2716C18.3652 9.81966 21.1636 7.0213 24.6155 7.0213C28.0675 7.0213 30.8659 9.81966 30.8659 13.2716C30.8659 16.7236 28.0675 19.5219 24.6155 19.5219C21.1636 19.5219 18.3652 16.7236 18.3652 13.2716Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.418 12.0755C23.418 11.5997 23.8037 11.214 24.2795 11.214H24.477C25.0293 11.214 25.477 11.6617 25.477 12.214V14.8065H25.5502C26.026 14.8065 26.4117 15.1922 26.4117 15.668C26.4117 16.1439 26.026 16.5296 25.5502 16.5296H23.7539V12.9371C23.5684 12.9371 23.418 12.7866 23.418 12.6011V12.0755Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.13 15.6673C25.13 16.1435 24.7439 16.5296 24.2677 16.5296H23.583C23.1068 16.5296 22.7207 16.1435 22.7207 15.6673C22.7207 15.1911 23.1068 14.805 23.583 14.805H24.2677C24.7439 14.805 25.13 15.1911 25.13 15.6673Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.8782 9.67908C25.2089 9.67908 25.477 9.94715 25.477 10.2778C25.477 10.6085 25.2089 10.8766 24.8782 10.8766H24.3527C24.022 10.8766 23.7539 10.6085 23.7539 10.2778C23.7539 9.94715 24.022 9.67908 24.3527 9.67908H24.8782Z'
      fill='#181818'
    />
  </svg>
);

