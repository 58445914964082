import React, { ChangeEvent, useState } from 'react';
import { InputSuggestionProps } from './types';
import { formatSuggestionString } from 'utilities/formatSuggectionString';
import {
  InputLabel,
  Error,
  InputInner,
  Input,
  InputWrap,
  StyledPreloader,
  SuggestionContainer,
  SuggestionItem,
  SuggestionText,
  SuggestionAccentText,
  NoDataSuggestion,
  SuggestionSubtext,
} from './styles';
import { FieldRenderProps, useForm } from 'react-final-form';
import { DadataSuggestionsResponse } from 'queries/buy/daData/types';

export const CitySuggestion: React.FC<
  FieldRenderProps<InputSuggestionProps>
> = ({
  alignLabel = 'left',
  label,
  direction,
  labelWidth,
  inputWidth,
  disabled,
  className,
  fullWidth,
  onChange,
  isLoading,
  error,
  onChoose,
  suggestions,
  input,
  meta,
  ...rest
}) => {
  const form = useForm('DeliveryForm');
  const [showSuggestions, setShownSuggestions] = useState(false);
  const handleSuggestionChoose = (item: DadataSuggestionsResponse) => {
    form.change('city', item.data.city_with_type);
    form.change('city_search', item.data.city);
    form.change('postal_code', item.data.postal_code);
    setShownSuggestions(false);
    onChoose?.(item.data.postal_code, item.data.city);
  };

  return (
    <InputWrap error={error} direction={direction} className={className}>
      {label ? (
        <InputLabel
          direction={direction}
          alignLabel={alignLabel}
          labelWidth={labelWidth}
        >
          {label}
        </InputLabel>
      ) : null}
      <div>
        <InputInner
          isFocused={!!meta.active}
          fullWidth={fullWidth}
          inputWidth={inputWidth}
        >
          <Input
            type='text'
            disabled={disabled}
            value={input.value.toString()}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (!showSuggestions) setShownSuggestions(true);
              if (!e.target.value.match(/[a-zA-z]/)) {
                if (onChange) onChange(e);
                if (input.onChange) input.onChange(e);
              }
            }}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            {...rest}
          />
          {input.value && showSuggestions && input.onFocus ? (
            <SuggestionContainer>
              {isLoading ? (
                <StyledPreloader />
              ) : suggestions && suggestions.length > 0 ? (
                suggestions.map(
                  (item: DadataSuggestionsResponse, index: number) => {
                    const [
                      beforeText,
                      accentText,
                      afterText,
                    ] = formatSuggestionString(
                      item.data.city ?? '',
                      input.value as string
                    );

                    const subText = `${item.data.country ?? ''}${
                      item.data.region_with_type
                        ? `, ${item.data.region_with_type}`
                        : ''
                    }`;

                    return (
                      <SuggestionItem
                        key={index}
                        onClick={() => handleSuggestionChoose(item)}
                      >
                        <SuggestionText>
                          {beforeText}
                          <SuggestionAccentText>
                            {accentText}
                          </SuggestionAccentText>
                          {afterText}
                        </SuggestionText>
                        <SuggestionSubtext>{subText}</SuggestionSubtext>
                      </SuggestionItem>
                    );
                  }
                )
              ) : (
                <NoDataSuggestion>Данных нет</NoDataSuggestion>
              )}
            </SuggestionContainer>
          ) : null}
        </InputInner>
        {error ? <Error>{error}</Error> : null}
      </div>
    </InputWrap>
  );
};
