import React from 'react';
import { Title } from './styles';
import { data } from './data';
import { Accordion } from 'components/common/Accordion';

export const Faq: React.FC = () => {
  return (
    <>
      <Title>Часто задаваемые вопросы</Title>
      {data.map(({ id, title, text }) => (
        <Accordion key={id} title={title} text={text} />
      ))}
    </>
  );
};
