import { AddAddressModal } from 'components/Account/Content/Addresses/AddAddressModal';
import { PlusButtonIcon } from 'icons';
import { useAddresses } from 'models/addresses/model';
import { useGetAddressesQuery } from 'queries/addresses/useGetAddressesQuery';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { Preloader } from 'ui/Preloader';
import { RecipientFields } from '../common/RecipientFields/';
import { AddressOptions } from './AddressOptions/AddressOptions';
import {
  AddAdressButton,
  Divider,
  StyledDeliveryAddress,
  Title,
} from './styles';

export const CourierFormFields: React.FC = () => {
  const { isLoading, refetch } = useGetAddressesQuery();
  const addresses = useAddresses();
  const form = useForm('DeliveryForm');

  useEffect(() => {
    if (addresses[0]) {
      form.change(
        'address',
        `${addresses[0].address}, ${addresses[0].city_code}`
      );
      form.change('city_code', addresses[0].city_code);
    }
  }, [addresses, form]);

  const [openAddAddressModal, setOpenAddAddressModal] = useState(false);

  return (
    <>
      {!!addresses.length && <Title>Адрес доставки</Title>}
      {openAddAddressModal && (
        <AddAddressModal
          onSuccess={() => refetch()}
          closeModal={() => setOpenAddAddressModal(false)}
        />
      )}
      {isLoading && <Preloader />}
      {!isLoading &&
        (addresses.length ? (
          <div>
            <AddressOptions />
            <AddAdressButton
              icon={PlusButtonIcon}
              buttonType='link'
              onClick={() => setOpenAddAddressModal(true)}
            >
              Добавить адрес
            </AddAdressButton>
          </div>
        ) : (
          <StyledDeliveryAddress />
        ))}
      <Divider />
      <RecipientFields />
    </>
  );
};
