import styled from 'styled-components';

export const Wrap = styled.div`
  margin: 60px 0 0;
`;

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline2};
`;
