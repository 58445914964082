import React from 'react';
import styled from 'styled-components';
import { RecipientFields } from '../common/RecipientFields/';

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  height: 1px;
  width: 100%;
  margin: 60px 0;
`;

export const PickUpFormFields: React.FC = () => {
  return (
    <>
      {/*<PickUpOption />*/}
      <Divider />
      <RecipientFields />
    </>
  );
};
