import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { NewsArticle } from './NewsArticle';
import { NewsList } from './NewsList';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export const News: React.FC<RouteComponentProps<{ key?: string }>> = ({
  match,
}) => {
  const { key } = match.params;

  useScrollToTopOnMount();
  return key ? (
    <>
      <NewsArticle id={Number(key)} />
    </>
  ) : (
    <>
      <NewsList />
    </>
  );
};
