import { PopupSuccessIcon } from 'icons';
import { useAddReplySchoolQuery } from 'queries/inner-insight/useAddReplySchoolQuery/useAddReplySchoolQuery';
import { useAddReplyCourseQuery } from 'queries/useCourseQuery/useCourseQuery';
import React, { useState } from 'react';
import { Field, Form, FieldRenderProps } from 'react-final-form';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';
import {
  Title,
  Text,
  StyledRatingStars,
  StyledInputTextarea,
  StyledButton,
  Wrap,
  Logo,
  TitleSuccess,
  TextSuccess,
} from './styles';
import { ReviewModalProps, ReviewStepKeys } from './types';

export const ReviewModal: React.FC<ReviewModalProps> = ({
  handleClose,
  name,
  id,
  isSchool = false,
}) => {
  const [step, setStep] = useState<ReviewStepKeys>('form');
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);

  const { mutate: addReplyToCourse } = useAddReplyCourseQuery();
  const { mutate: addReplyToSchool } = useAddReplySchoolQuery();

  const onSubmit = ({ text }: { text: string }) => {
    isSchool
      ? addReplyToSchool(
          { mark: rating, text, school_id: id },
          { onSuccess: () => setStep('success') }
        )
      : addReplyToCourse(
          { mark: rating, text, course_id: id },
          { onSuccess: () => setStep('success') }
        );
  };

  const textAreaComponent: React.FC<FieldRenderProps<string>> = props => (
    <StyledInputTextarea height={192} {...props} />
  );

  const renderContent = () => {
    try {
      switch (step) {
        case 'form':
          return (
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Title>Новый отзыв на «{name}»</Title>
                  <Text>Оцените курс</Text>
                  <StyledRatingStars
                    size={'medium'}
                    rating={rating}
                    setRating={setRating}
                    hoverRating={hoverRating}
                    setHoverRating={setHoverRating}
                  />
                  <Field
                    label={'Комментарий'}
                    direction={'vertical'}
                    alignLabel={'left'}
                    name={'text'}
                    component={textAreaComponent}
                  />
                  <StyledButton>
                    <Button type='submit'>Оставить отзыв</Button>
                  </StyledButton>
                </form>
              )}
            />
          );

        case 'success':
          return (
            <Wrap>
              <Logo>{PopupSuccessIcon}</Logo>
              <TitleSuccess>Спасибо за отзыв!</TitleSuccess>
              <TextSuccess>
                Для нас очень важна обратная связь от Вас!
              </TextSuccess>
            </Wrap>
          );

        default:
          throw new Error(`Unknown order state: '${step}'!`);
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  return <Modal handleClose={handleClose}>{renderContent()}</Modal>;
};
