import React from 'react';
import Ruble from 'components/common/Ruble';
import { MinusIcon, TrashIcon, PlusIcon } from 'icons';
import { formatPriceToString } from 'utilities/formatPriceToString';
import {
  Wrap,
  Top,
  Bottom,
  Inner,
  PhotoWrap,
  Title,
  Count,
  Price,
  NewPrice,
  DeleteButton,
  Overlay,
  Photo,
  HandleCount,
  HandleCounter,
  HandleButton,
  Badge,
  BadgeText,
} from './styles';
import { CartItem as CartItemProps } from 'models/cart/types';
import { useDeleteCartItemQuery } from 'queries/useDeleteCartItemQuery';
import { Preloader } from 'ui/Preloader';
import { NoImage } from 'ui/NoImage';
import { baseUrl } from 'api/apiClient';
import { useCartItemUpdateQuery } from 'queries/cart/useCartItemUpdateQuery';

export const CartItem: React.FC<CartItemProps> = ({
  name,
  price,
  quantity,
  id,
  image,
  product_id,
  bonus,
  ...rest
}) => {
  const { mutate, isLoading } = useDeleteCartItemQuery();
  const {
    mutate: update,
    isLoading: isLoadingUpdate,
  } = useCartItemUpdateQuery();

  const showPreloader = isLoading || isLoadingUpdate;
  return (
    <Wrap {...rest}>
      {showPreloader ? (
        <Overlay>
          <Preloader />
        </Overlay>
      ) : null}
      <Top>
        <PhotoWrap>
          {image ? <Photo src={baseUrl + image} alt={name} /> : <NoImage />}
        </PhotoWrap>
        <Inner>
          {bonus ? (
            <Badge>
              <BadgeText>+ {bonus} Б</BadgeText>
            </Badge>
          ) : null}
          <Title>{name}</Title>
          <Count>{quantity} шт.</Count>
          <HandleCounter>
            <HandleButton
              onClick={() => {
                update({ product_id, operator: '-' });
              }}
            >
              {MinusIcon}
            </HandleButton>
            <HandleCount>{quantity}</HandleCount>
            <HandleButton
              onClick={() => {
                update({ product_id, operator: '+' });
              }}
            >
              {PlusIcon}
            </HandleButton>
          </HandleCounter>
        </Inner>
        <Price>
          <NewPrice>
            {formatPriceToString(price)} {Ruble}
          </NewPrice>
        </Price>
        <DeleteButton
          icon={TrashIcon}
          buttonType={'link'}
          iconFill={'#8d8d8d'}
          onClick={() => mutate(id)}
        />
      </Top>
      <Bottom />
    </Wrap>
  );
};
