import React, { useState } from 'react';
import { RatingStarIcon, CrownIcon } from 'icons';
import {
  List,
  Option,
  Divider,
  Inner,
  TextIcon,
  Title,
  Text,
  StyledButton,
} from './styles';
import { useGetBonusAmount } from 'queries/account/bonusAccount/useGetBonusAmount';
import { declension } from 'utilities/declension';
import { useGetInnerBudgetQuery } from 'queries/account/innerBudget/useGetInnerBudgetQuery';
import { Preloader } from 'ui/Preloader';
import { useDiscountCalculationQuery } from 'queries/useDiscountCalculateQuery';
import { useStore } from 'effector-react';
import {
  $accumulatedBonuses,
  $calculation,
  setAccumulatedBonuses,
} from 'models/order/model';
import { LoyaltyPointsProps } from './types';
import { toast } from 'react-toastify';
import { SuccessMessage } from 'ui/SuccessMessage';
import { LoyaltyModal } from './LoyaltyModal';
import { ErrorMessage } from 'ui/ErrorMessage';

const bonusDeclension = ['бонус', 'бонуса', 'бонусов'];
const innerBudgetDeclension = ['вжух', 'вжуха', 'вжухов'];

export const LoyaltyPoints: React.FC<LoyaltyPointsProps> = ({
  finalPrice,
  orderId,
  type,
  ...rest
}) => {
  const [isVisibleModalBonus, setVisibleModalBonus] = useState(false);
  const [isVisibleModalInnerBudget, setVisibleModalInnerBudget] = useState(
    false
  );

  const accumulatedBonuses = useStore($accumulatedBonuses);
  const calculation = useStore($calculation);

  const handleClose = () => {
    setVisibleModalBonus(false);
    setVisibleModalInnerBudget(false);
  };

  const { mutate } = useDiscountCalculationQuery();

  const {
    isLoading: isLoadingBonusAmount,
    data: bonusAmount,
  } = useGetBonusAmount();
  const {
    isLoading: isLoadingInnerBudget,
    data: innerBudget,
  } = useGetInnerBudgetQuery();

  const handleSubmit = (values: Record<string, number>) => {
    setAccumulatedBonuses(values);
    mutate(
      { ...accumulatedBonuses, ...values, order_id: orderId },
      {
        onSuccess: () => {
          toast.success(<SuccessMessage text='Бонусы успешно применены!' />);
          handleClose();
        },
        onError: err => {
          if (typeof err.response?.data.error === 'string') {
            toast.error(<ErrorMessage text={err.response?.data.error} />);
          }
        },
      }
    );
  };

  return (
    <div {...rest}>
      <List>
        {type === 'courses' || type === 'chat' ? (
          <>
            {isLoadingBonusAmount ? (
              <Preloader />
            ) : (
              <Option>
                <Inner>
                  <Title>Мой баланс </Title>
                  <Text>
                    <TextIcon>{RatingStarIcon}</TextIcon>
                    {`${
                      Number(bonusAmount.user_score) -
                      (calculation?.bonus_account ?? 0)
                    } ${declension(
                      bonusDeclension,
                      Number(bonusAmount.user_score)
                    )}`}
                  </Text>
                </Inner>
                <StyledButton
                  disabled={!bonusAmount.user_score}
                  buttonType={'secondary'}
                  onClick={() => setVisibleModalBonus(true)}
                >
                  Использовать
                </StyledButton>
              </Option>
            )}
            <Divider />
          </>
        ) : null}
        {isLoadingInnerBudget ? (
          <Preloader />
        ) : (
          <Option>
            <Inner>
              <Title>Премия</Title>
              <Text>
                <TextIcon>{CrownIcon}</TextIcon>
                {`${innerBudget}
                ${declension(innerBudgetDeclension, Number(innerBudget))}`}
              </Text>
            </Inner>
            <StyledButton
              buttonType={'secondary'}
              disabled={!innerBudget}
              onClick={() => setVisibleModalInnerBudget(true)}
            >
              Использовать
            </StyledButton>
          </Option>
        )}
      </List>

      {isVisibleModalBonus && (
        <LoyaltyModal
          fieldName='bonus'
          handleClose={handleClose}
          value={Number(bonusAmount.user_score)}
          finalPrice={finalPrice}
          handleSubmit={handleSubmit}
          type={'bonus'}
        />
      )}

      {isVisibleModalInnerBudget && (
        <LoyaltyModal
          fieldName='inner_budget'
          handleClose={handleClose}
          value={Number(innerBudget)}
          finalPrice={finalPrice}
          handleSubmit={handleSubmit}
          type={'premia'}
        />
      )}
    </div>
  );
};
