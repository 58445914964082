export const GiftIcon2 = (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.978027 5.98584V10.2902C0.978027 10.6862 1.30014 11.0076 1.69542 11.0076H5.28238V5.98584H0.978027Z'
      fill='#181818'
    />
    <path
      d='M11.0216 3.1165H8.75179C8.91463 3.0053 9.05453 2.89483 9.15209 2.79583C9.73102 2.21402 9.73102 1.26706 9.15209 0.685259C8.58965 0.118519 7.6097 0.119954 7.04798 0.685259C6.73663 0.997325 5.91163 2.26783 6.0257 3.1165H5.97405C6.08739 2.26783 5.26239 0.997325 4.95176 0.685259C4.38933 0.119954 3.40937 0.119954 2.84766 0.685259C2.26944 1.26706 2.26944 2.21402 2.84694 2.79583C2.94522 2.89483 3.08511 3.0053 3.24796 3.1165H0.978133C0.582851 3.1165 0.260742 3.43861 0.260742 3.83389V4.90998C0.260742 5.10798 0.421438 5.26868 0.619438 5.26868H5.28248V3.83389H6.71726V5.26868H11.3803C11.5783 5.26868 11.739 5.10798 11.739 4.90998V3.83389C11.739 3.43861 11.4176 3.1165 11.0216 3.1165ZM5.24016 3.09283C5.24016 3.09283 5.21002 3.1165 5.10744 3.1165C4.61172 3.1165 3.66548 2.60141 3.35629 2.29007C3.0557 1.98733 3.0557 1.49376 3.35629 1.19102C3.50192 1.04467 3.69489 0.964325 3.90007 0.964325C4.10452 0.964325 4.2975 1.04467 4.44313 1.19102C4.92666 1.67741 5.40731 2.91993 5.24016 3.09283ZM6.89159 3.1165C6.78972 3.1165 6.75959 3.09354 6.75959 3.09283C6.59244 2.91993 7.07309 1.67741 7.55661 1.19102C7.84572 0.899042 8.35148 0.897607 8.64346 1.19102C8.94476 1.49376 8.94476 1.98733 8.64346 2.29007C8.33426 2.60141 7.38802 3.1165 6.89159 3.1165Z'
      fill='#181818'
    />
    <path
      d='M6.71729 5.98584V11.0076H10.3042C10.7002 11.0076 11.0216 10.6862 11.0216 10.2902V5.98584H6.71729Z'
      fill='#181818'
    />
  </svg>
);
