import { useQuery } from 'react-query';
import { $news, newsFx, setNews } from 'models/news/model';
import { useStore } from 'effector-react';

export type TParams = {
  offset: number;
  limit: number;
};

// TODO: Доработать тип
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNewsQuery = (params: TParams) => {
  const news = useStore($news);
  const { data, ...rest } = useQuery(['news', params], () => newsFx(params), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      if (news.length === params.offset) {
        setNews([...news, ...data.data.data]);
      }
    },
  });

  return { data: data?.data.data, ...rest };
};
