import {
  getVIPClubSubscriptionsFx,
  setVIPClubSubscriptions,
} from 'models/catalog/model';
import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { VIPClubSubcriptionType } from 'models/catalog/types';

export const useGetVIPClubSubscriptionsQuery: () => UseQueryResult<
  AxiosResponse<{ data: VIPClubSubcriptionType[] }>,
  AxiosError<{ error: string }>
> = () =>
  useQuery('getVIPClubSubscriptions', getVIPClubSubscriptionsFx, {
    onSuccess: res => {
      setVIPClubSubscriptions(res.data.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
