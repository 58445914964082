export enum NotAcceptedOrderStatuses {
  canceled = 'Отменен',
}

export type TBasket = {
  name: string;
  product_id: number;
  quantity: number;
  image: string | null;
};

export type TOrderItem = {
  id: string;
  tracking: string | null;
  pay_system: string | null;
  price_delivery: string;
  price: string;
  discount: string;
  sum_paid: string;
  payed: string;
  status: string;
  date_insert: string;
  basket: Record<string, TBasket>;
};

export type TOrderDedail = {
  id: string;
  pay_system: string;
  date_insert: string;
  date_payed: string;
  status: string;
  price: number;
  discount: number;
  sum_paid: number;
  delivery: {
    delivery_price: number;
    name: string;
    payer_data: {
      address: string;
      email: string;
      payer_name: string;
      phone: string;
    };
    status: string;
    tracking_number: string;
  };
  basket: [
    {
      id: string;
      name: string;
      quantity: number;
      price: number;
      measure: string;
      image: string | null;
    }
  ];
};
