import React, { useRef } from 'react';
import Swiper, { SwiperRefNode } from 'react-id-swiper';
import Ruble from 'components/common/Ruble';
import { formatPriceToString } from 'utilities/formatPriceToString';
import { CatalogItemSetProps } from './types';
import {
  BookImage,
  Wrap,
  SwiperWrap,
  LeftArrow,
  RightArrow,
  SetTitle,
  Author,
  PriceWrap,
  NewPrice,
  Btn,
  StyledNoImage,
  DisabledItemWrap,
} from './styles';
import { CatalogItem } from 'models/catalog/types';
import { Path } from 'constants/paths';
import { useAddCartItemQuery } from 'queries/cart/useAddCartItemQuery';
import { useHistory } from 'react-router-dom';
import { baseUrl } from 'api/apiClient';

//TODO: Вынести компонент в сторибук

const swiperConfig = {
  spaceBetween: 24,
  centeredSlides: true,
  noSwiping: true,
  loop: true,
};

export const CatalogItemSet: React.FC<CatalogItemSetProps & CatalogItem> = ({
  price,
  buttonText,
  id,
  goodsType,
  disabled,
  name,
  has_proposals,
  preview_text,
  images,
  ...rest
}) => {
  const swiperRef = useRef<SwiperRefNode>(null);
  const mutation = useAddCartItemQuery();
  const history = useHistory();

  const goNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (swiperRef?.current?.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (swiperRef?.current?.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!has_proposals) {
      mutation.mutate({ id, count: '1' });
    } else {
      history.push(
        goodsType === 'books'
          ? Path.catalogBooks + '/' + id
          : Path.catalogGoods + '/' + id
      );
    }
  };

  return disabled ? (
    <DisabledItemWrap {...rest}>
      <SwiperWrap>
        <LeftArrow direction={'left'} onClick={goPrev} />
        <Swiper ref={swiperRef} {...swiperConfig}>
          {images?.length ? (
            images.map((image, idx) => (
              <BookImage key={idx} src={baseUrl + image} alt={image} />
            ))
          ) : (
            <StyledNoImage />
          )}
        </Swiper>
        <RightArrow onClick={goNext} />
      </SwiperWrap>
      <SetTitle>{name}</SetTitle>
      {preview_text ? <Author>{preview_text}</Author> : null}
      <PriceWrap>
        <NewPrice>
          {formatPriceToString(price)} {Ruble}
        </NewPrice>

        {/*TODO: Есть несоответствия данных с бэкенда с макетом*/}
        {/*<OldPrice>*/}
        {/*  {formatPriceToString(price.old)} {Ruble}*/}
        {/*</OldPrice>*/}
        <Btn onClick={handleClick} disabled={disabled}>
          {buttonText}
        </Btn>
      </PriceWrap>
    </DisabledItemWrap>
  ) : (
    <Wrap to={`${Path.catalogBooks}/${id}`} {...rest}>
      <SwiperWrap>
        <LeftArrow direction={'left'} onClick={goPrev} />
        <Swiper ref={swiperRef} {...swiperConfig}>
          {images?.length ? (
            images.map((image, idx) => (
              <BookImage key={idx} src={baseUrl + image} alt={image} />
            ))
          ) : (
            <StyledNoImage />
          )}
        </Swiper>
        <RightArrow onClick={goNext} />
      </SwiperWrap>
      <SetTitle>{name}</SetTitle>
      {preview_text ? <Author>{preview_text}</Author> : null}
      <PriceWrap>
        <NewPrice>
          {formatPriceToString(price)} {Ruble}
        </NewPrice>

        {/*TODO: Есть несоответствия данных с бэкенда с макетом*/}
        {/*<OldPrice>*/}
        {/*  {formatPriceToString(price.old)} {Ruble}*/}
        {/*</OldPrice>*/}
        <Btn onClick={handleClick} disabled={disabled}>
          {buttonText}
        </Btn>
      </PriceWrap>
    </Wrap>
  );
};
