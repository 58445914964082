import styled from 'styled-components';
import { NoImage } from 'ui/NoImage';

export const Inner = styled.div`
  display: flex;
  margin: 24px 0 0;
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline2}
  margin: 12px 0 0;
`;

export const PhotoWrap = styled.div`
  width: 140px;
  min-height: 96px;
  border-radius: 14px;
  margin: 0 40px 0 0;
  overflow: hidden;
`;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledNoImage = styled(NoImage)`
  padding: 16px;
`;

export const Description = styled.div`
  flex: 1 1 auto;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  margin: 40px 0 16px;
`;

export const Bottom = styled.div`
  text-align: right;
`;

export const DescriptionText = styled.p`
  margin: 24px 0 0;
  max-width: 348px;
  ${({ theme }) => theme.typography.bodyText2}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const DatetimeTitle = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText2}
`;

export const DatetimeText = styled.p`
  ${({ theme }) => theme.typography.bodyText2}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 4px 0 0 0;
`;
