import React from 'react';

export const CreditCardIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.25 3.75C1.83579 3.75 1.5 4.08579 1.5 4.5V13.5C1.5 13.9142 1.83579 14.25 2.25 14.25H15.75C16.1642 14.25 16.5 13.9142 16.5 13.5V4.5C16.5 4.08579 16.1642 3.75 15.75 3.75H2.25ZM0 4.5C0 3.25736 1.00736 2.25 2.25 2.25H15.75C16.9926 2.25 18 3.25736 18 4.5V13.5C18 14.7426 16.9926 15.75 15.75 15.75H2.25C1.00736 15.75 0 14.7426 0 13.5V4.5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 7.5C0 7.08579 0.335786 6.75 0.75 6.75H17.25C17.6642 6.75 18 7.08579 18 7.5C18 7.91421 17.6642 8.25 17.25 8.25H0.75C0.335786 8.25 0 7.91421 0 7.5Z'
    />
  </svg>
);