import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { TrainingCatalogItem } from 'models/catalog/types';
import { getTrainingsFx, setTrainings } from 'models/catalog/model';

export const useGetTrainingsListQuery: () => UseQueryResult = () =>
  useQuery<
    unknown,
    AxiosError<{ error: string }>,
    AxiosResponse<{ data: TrainingCatalogItem[] }>
  >('trainingsList', () => getTrainingsFx(), {
    onSuccess: res => {
      setTrainings(res.data.data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
