import React from 'react';

export const PopupAttentionIcon = (
  <svg
    width='61'
    height='60'
    viewBox='0 0 61 60'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='30.5' cy='30' r='30' fill='#FFF6F4' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.8247 12C31.0673 12 32.0747 13.0074 32.0747 14.25V33.75C32.0747 34.9926 31.0673 36 29.8247 36C28.5821 36 27.5747 34.9926 27.5747 33.75V14.25C27.5747 13.0074 28.5821 12 29.8247 12Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.8254 41.25C31.0681 41.25 32.0754 42.2574 32.0754 43.5C32.0754 44.7426 31.0681 45.75 29.8254 45.75C28.5828 45.75 27.5754 44.7426 27.5754 43.5C27.5754 42.2574 28.5828 41.25 29.8254 41.25Z'
    />
  </svg>
);
