import styled from 'styled-components';
import bg from 'images/VIP_club_mini_banner.png';

export const Wrap = styled.div`
  padding: 32px 0 32px 50px;
  background-image: url(${bg});
  background-color: #f8d5c3;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 14px;
  background-position: top right;
  margin-top: 30px;
`;
export const Text = styled.div`
  ${({ theme }) => theme.typography.headline3};
  max-width: 456px;
  margin-right: auto;
`;
