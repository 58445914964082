import React from 'react';
import { VideoIcon, HeadphonesIcon, FileIcon, BookOpenIcon } from 'icons/';
import {
  StyledDescription,
  StyledMaterials,
  StyledHomework,
  StyledCommentaries,
  Wrap,
  Divider,
  Title,
  StyledDate,
  List,
  Link,
  Text,
} from './styles';
import { LessonStore } from 'models/lesson/types';
import { ru } from 'date-fns/locale';
import { format } from 'date-fns';
import { DateFormats } from 'constants/dateFormats';

const formatDate = (date: Date) =>
  date ? format(new Date(date), DateFormats['DateTime'], { locale: ru }) : null;

export const Content: React.FC<{
  data: LessonStore;
}> = ({ data, ...rest }) => {
  const {
    name,
    description,
    audio,
    video,
    document,
    homework,
    date_start,
    expiration,
  } = data || {};
  return (
    <Wrap {...rest}>
      <StyledDate>
        {formatDate(date_start)}{' '}
        {expiration ? '-' + formatDate(expiration) : null}
      </StyledDate>
      <Title>{name}</Title>
      <List>
        {video.length ? (
          <Link>
            <div>{VideoIcon}</div>
            <Text>{video.length}</Text>
          </Link>
        ) : null}
        {audio.length ? (
          <Link>
            <div>{HeadphonesIcon}</div>
            <Text>{audio.length}</Text>
          </Link>
        ) : null}
        {document.length ? (
          <Link>
            <div>{FileIcon}</div>
            <Text>{document.length}</Text>
          </Link>
        ) : null}
        <Link style={{ marginLeft: 'auto' }}>
          <div>{BookOpenIcon}</div>
          <Text>1 задание </Text>
        </Link>
      </List>
      <Divider />
      <StyledDescription text={description} />
      <Divider />
      <StyledMaterials audio={audio} documents={document} />
      <Divider />
      <StyledHomework {...homework} />
      <StyledCommentaries />
    </Wrap>
  );
};
