import React, { useState } from 'react';
import Ruble from 'components/common/Ruble';
import { formatPriceToString } from 'utilities/formatPriceToString';
import {
  Wrap,
  Title,
  Options,
  Option,
  OptionRadio,
  OptionInner,
  OptionTitle,
  OptionText,
  OptionPrice,
  SubscribtionButton,
} from './styles';

// TODO: Прописать логику переключения радиобаттонов
export const Subscription: React.FC = props => {
  const [option, setOption] = useState<string | null>(null);
  const [optionPrice, setOptionPrice] = useState<number | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const price = e.target.getAttribute('data-price');
    setOptionPrice(Number(price));
    setOption(e.target.value);
  };

  return (
    <Wrap {...props}>
      <Title>Подписка</Title>
      <Options>
        <Option>
          <OptionRadio
            radioSize='large'
            onChange={handleChange}
            checked={option === '1'}
            value={'1'}
            data-price={62580}
          />
          <OptionInner>
            <OptionTitle>Доступ на 3 месяца</OptionTitle>
            <OptionText>
              Поквартальная оплата является рассрочкой годовой оплаты.
              Квартальный взнос долями НЕ ВОЗВРАЩАЕТСЯ
            </OptionText>
          </OptionInner>
          <OptionPrice>62 580 {Ruble}</OptionPrice>
        </Option>
        <Option>
          <OptionRadio
            radioSize='large'
            onChange={handleChange}
            checked={option === '2'}
            value={'2'}
            data-price={250000}
          />
          <OptionInner>
            <OptionTitle>Годовой доступ</OptionTitle>
            <OptionText>
              Стоимость годового членства в клубе фиксируется на момент
              вступления в клуб
            </OptionText>
          </OptionInner>
          <OptionPrice>250 000 {Ruble}</OptionPrice>
        </Option>
      </Options>
      <SubscribtionButton buttonType='primary' size='large'>
        Купить{' '}
        {optionPrice
          ? `«Доступ на 3 месяца» за ${formatPriceToString(
              optionPrice
            )} ${Ruble}`
          : null}
      </SubscribtionButton>
    </Wrap>
  );
};
