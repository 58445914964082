import styled from 'styled-components';
import { Categories } from './Categories/';
import { List } from './List';

export const Inner = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-areas:
    'empty empty empty content content content content content content content content content'
    'sidebar sidebar sidebar content content content content content content content content content';
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 42px 1fr;
  gap: 24px;
  margin-top: 40px;
  padding: 0 12px 100px;
  max-width: 1236px;
`;

export const StyledCategory = styled(Categories)`
  grid-area: sidebar;
`;

export const StyledList = styled(List)`
  grid-area: content;
`;

export const CarouselWrap = styled.div`
  & > .swiper-container {
    overflow: visible;
    max-width: 1006px;
    margin-left: 0;
  }
  max-width: 1236px;
  padding: 0 12px;
  margin: 0 auto;
`;
