import React, { ChangeEvent } from 'react';
import { InputTextProps } from 'ui/InputText/types';
import { Field, FieldRenderProps, useField } from 'react-final-form';
import { AddressSuggestion } from './AddressSuggestion';
import { useGetAddressDaDataSuggestionQuery } from 'queries/buy/daData/useGetAddressDaDataSuggestionQuery';
import { useDebouncedCallback } from 'use-debounce';

export const AddressSmartInput: React.FC<
  FieldRenderProps<InputTextProps>
> = props => {
  const {
    isLoading,
    mutate,
    data: suggestions,
  } = useGetAddressDaDataSuggestionQuery();

  const cityField = useField('city');
  const countryField = useField('country');

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target || {};
    if (value)
      mutate({
        country: countryField.input.value,
        address: `${cityField.input.value}, ${value}`,
      });
  };
  const debouncedHandleChange = useDebouncedCallback(handleOnChange, 300);

  return (
    <>
      <Field
        isLoading={isLoading}
        onChange={debouncedHandleChange}
        labelWidth={144}
        inputWidth={340}
        suggestions={suggestions}
        component={AddressSuggestion}
        name={'address'}
        {...props}
      />
    </>
  );
};
