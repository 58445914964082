import { createEffect, createEvent, createStore } from 'effector';
import { AxiosError, AxiosResponse } from 'axios';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import {
  GetAllNotificationRequestType,
  NotificationItemType,
  NotificationsStore,
} from './types';
import { useStore } from 'effector-react';

// События
export const setHasNewNotifications = createEvent<NotificationItemType[]>();
export const setNotifications = createEvent<NotificationItemType[]>();
export const clearNotifications = createEvent();

// Сторы
export const $notifications = createStore<NotificationsStore>([]);
export const $hasNewNotifications = createStore(false);

// Эффекты
export const getAllNotificationsFx = createEffect<
  GetAllNotificationRequestType,
  AxiosResponse<{ data: NotificationItemType[] }>,
  AxiosError<{ error: string }>
>(data => {
  return apiClient.post(Endpoint.GET_NOTIFICATIONS, data);
});

export const getNewNotificationsFx = createEffect<
  void,
  AxiosResponse<{ data: NotificationItemType[] }>,
  AxiosError<{ error: string }>
>(() => {
  return apiClient.get(Endpoint.GET_NEW_NOTIFICATIONS);
});

export const markAsReadNotificationsFx = createEffect<
  void,
  AxiosResponse<{ data: { success: boolean } }>,
  AxiosError<{ error: string }>
>(() => {
  return apiClient.post(Endpoint.MARK_AS_READ_NOTIFICATIONS);
});

// Подписки
$notifications
  .reset(clearNotifications)
  .on(setNotifications, (state, payload) => [...state, ...payload]);

$hasNewNotifications.on(setHasNewNotifications, (state, payload) => {
  return !!payload.length;
});

// Селекторы
export const useNotificationsData: () => NotificationsStore = () =>
  useStore($notifications);

export const useHasNewNotifications: () => boolean = () =>
  useStore($hasNewNotifications);
