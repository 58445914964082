import styled from 'styled-components';
import { InputTextProps } from './types';

export const InputLabel = styled.label<InputTextProps>`
  ${props => props.theme.typography.bodyText2};
  letter-spacing: 0px;
  text-align: ${props => props.alignLabel};
  margin-bottom: 8px;
  color: ${props => props.theme.colors.secondTextColor};
  align-self: flex-start;
  ${({ labelWidth }) => (labelWidth ? `width: ${labelWidth}px` : null)};

  ${({ labelWidth }) => (labelWidth ? `width: ${labelWidth}px` : null)};
`;

export const Error = styled.p`
  margin: 8px 0 0;
  max-width: 100%;
  text-align: left;
  color: ${props => props.theme.colors.errorColor};
`;

export const InputInner = styled.div<
  InputTextProps & { isFocused: boolean; disabled?: boolean }
>`
  padding: 12px 14px;
  border-radius: 14px;
  border: 1px solid ${props => props.theme.colors.secondBgColor};
  background-color: ${props => props.theme.colors.secondBgColor};
  transition: all 0.3s;
  display: flex;
  align-items: center;
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth}px` : null)};
  ${({ isFocused, theme }) =>
    isFocused &&
    `transition: all 0.3s;border-color: ${theme.colors.secondColor};`}
  ${({ disabled, theme }) =>
    !disabled &&
    `&:hover {
          transition: all 0.3s;
          border-color: ${theme.colors.secondColor};
        }`}
`;

export const Input = styled.input<InputTextProps>`
  outline: none;
  z-index: 3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 100%;
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.disabledColor
        : theme.colors.thirdTextAndIconColor};
  }
`;

export const InputWrap = styled.div<
  InputTextProps & {
    disabled?: boolean;
    error?: string;
  }
>`
  display: inline-flex;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'row'};
  ${({ direction }) =>
    direction !== 'vertical' ? 'align-items: center;' : null}
  ${({ direction }) =>
    direction !== 'vertical' ? `& > ${InputLabel} {margin: 0 16px 0 0;}` : null}

  ${props =>
    props.disabled &&
    `& > ${InputInner} {
        background-color: ${props.theme.colors.thirdBgColor};
        border-color: ${props.theme.colors.thirdBgColor};
        cursor: not-allowed;
      };
      
      & > ${Input} {
        cursor: not-allowed;
      }      
      & > ${InputLabel} {
        color: ${props.theme.colors.disabledColor};
      };`};

  & > ${Error} {
    transition: transform 0.3s, opacity 0.3s;
    ${({ error }) => {
      return !error
        ? 'transform: translate(0, -50%); opacity: 0;'
        : 'transform: translate(0, 0); opacity: 1;';
    }}
  }

  & > ${InputInner} {
    ${({ error, theme }) => error && `border-color: ${theme.colors.errorColor}`}
  }
`;

export const CalendarIconSC = styled.div`
  position: absolute;
  left: 466px;
  top: 14px;
  z-index: 3;
  font-size: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
  & > svg {
    fill-opacity: 0.4;
    width: 100%;
    height: 100%;
  }
`;
