import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import TokenService from '../services/TokenService';

export const baseUrl = 'https://bsm-develop.sandbox.zetest.site';

// Конфиг для работы axios
export const axiosConfig: AxiosRequestConfig = {
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const apiClient = (() => {
  const instance: AxiosInstance = axios.create(axiosConfig);
  // Add a request interceptor
  instance.interceptors.request.use(
    requestConfig => {
      const token = TokenService.getToken();
      if (token && !requestConfig.headers['Authorization']) {
        requestConfig.headers['Authorization'] = 'Bearer ' + token;
      }
      return requestConfig;
    },
    requestError => requestError
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    response => response,
    error => {
      return Promise.reject(error);
    }
  );

  return instance;
})();
