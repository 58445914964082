import styled from 'styled-components';

export const Title = styled.p`
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0;
`;

export const Subtitle = styled.p`
  ${({ theme }) => theme.typography.bodyText3};
  margin: 1px 0 0;
`;

export const Wrap = styled.div<{ image: string }>`
  padding: 18px 13px 18px 16px;
  overflow: hidden;
  border-radius: 14px;
  width: 183px;
  background: url(${({ image }) => image});
  background-size: cover;
  & + & {
    margin: 0 0 0 24px;
  }
`;
