import React from 'react';
import { Field, Form } from 'react-final-form';
import { PasswordInput, StyledForm } from './styles';
import { PasswordFormProps } from './types';

export const PasswordForm: React.FC<PasswordFormProps> = ({
  setButtonHandler,
  ...rest
}) => {
  return (
    <Form
      render={({ handleSubmit }) => {
        setButtonHandler(handleSubmit);
        return (
          <StyledForm onSubmit={handleSubmit}>
            <Field
              labelWidth={144}
              inputWidth={340}
              label='Пароль'
              direction='vertical'
              alignLabel='left'
              placeholder='Введите пароль'
              name='password'
              component={PasswordInput}
            />
            <Field
              labelWidth={144}
              inputWidth={340}
              label='Повторите пароль'
              direction='vertical'
              alignLabel='left'
              placeholder='Повторите пароль'
              name='repeat_password'
              component={PasswordInput}
            />
          </StyledForm>
        );
      }}
      {...rest}
    />
  );
};
