import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

export const Item = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 16px;
  }
`;

export const ItemTitle = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 0 24px 0 0;
`;

export const ItemDigit = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  font-weight: bold;
  margin: 0 0 0 auto;
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalTitle = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: 0 24px 0 0;
`;

export const TotalDigit = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: 0 0 0 auto;
`;

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline2};
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  height: 1px;
  width: 100%;
  margin: 24px 0;
`;

export const PaymentInfo = styled.div`
  padding: 24px;
  border-radius: 16px;
  margin: 24px 0 0;
  border: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
`;

export const StyledPreloader = styled(Preloader)`
  margin: 32px auto;
  text-align: center;
`;
