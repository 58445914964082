import React from 'react';
import {
  CatalogItemWrap,
  ImageWrap,
  Image,
  Title,
  EventDateWrap,
  EventBeginning,
  EventDate,
} from './styles';
import { baseUrl } from 'api/apiClient';
import { FreeItemProps } from './types';
import { NoImage } from 'ui/NoImage';
import { format } from 'date-fns';
import { DateFormats } from 'constants/dateFormats';
import { ru } from 'date-fns/locale';

export const FreeItem: React.FC<FreeItemProps> = ({
  image,
  title,
  handleClick,
  date,
  ...rest
}) => {
  const datetime = !!date && new Date(date);
  return (
    <CatalogItemWrap onClick={handleClick} {...rest}>
      <ImageWrap>
        {image ? <Image src={baseUrl + image} alt={title} /> : <NoImage />}
      </ImageWrap>
      <Title>{title}</Title>
      <EventDateWrap>
        {datetime ? (
          <EventBeginning>
            Дата добавления:{' '}
            <EventDate>
              {format(new Date(datetime), DateFormats['StartEventDate'], {
                locale: ru,
              })}
            </EventDate>
          </EventBeginning>
        ) : null}
      </EventDateWrap>
    </CatalogItemWrap>
  );
};
