import React from 'react';

export const FileIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.23223 1.56548C3.70107 1.09664 4.33696 0.833252 5 0.833252H10.8333C11.0543 0.833252 11.2663 0.921049 11.4226 1.07733L17.2559 6.91066C17.4122 7.06694 17.5 7.2789 17.5 7.49992V16.6666C17.5 17.3296 17.2366 17.9655 16.7678 18.4344C16.2989 18.9032 15.663 19.1666 15 19.1666H5C4.33696 19.1666 3.70107 18.9032 3.23223 18.4344C2.76339 17.9655 2.5 17.3296 2.5 16.6666V3.33325C2.5 2.67021 2.76339 2.03433 3.23223 1.56548ZM5 2.49992C4.77899 2.49992 4.56702 2.58772 4.41074 2.744C4.25446 2.90028 4.16667 3.11224 4.16667 3.33325V16.6666C4.16667 16.8876 4.25446 17.0996 4.41074 17.2558C4.56702 17.4121 4.77899 17.4999 5 17.4999H15C15.221 17.4999 15.433 17.4121 15.5893 17.2558C15.7455 17.0996 15.8333 16.8876 15.8333 16.6666V7.8451L10.4882 2.49992H5Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.8333 0.833252C11.2936 0.833252 11.6667 1.20635 11.6667 1.66659V6.66658H16.6667C17.1269 6.66658 17.5 7.03968 17.5 7.49992C17.5 7.96016 17.1269 8.33325 16.6667 8.33325H10.8333C10.3731 8.33325 10 7.96016 10 7.49992V1.66659C10 1.20635 10.3731 0.833252 10.8333 0.833252Z'
      fill='#B1B1B1'
    />
  </svg>
);

