import React from 'react';

export const ChatDialogLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M9.16602 44.4587V17.3753C9.16602 16.2295 10.1035 15.292 11.2493 15.292H25.8327'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M50.832 28.834V44.459'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M53.9577 44.459H35.2077L33.1244 46.5423H27.916L25.8327 44.459H6.04102V46.5423C6.04102 48.8444 7.9056 50.709 10.2077 50.709H49.791C52.0931 50.709 53.9577 48.8444 53.9577 46.5423V44.459Z'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M40.9368 9.04199C33.7493 9.04199 27.916 13.4691 27.916 18.9378C27.916 21.0941 28.8327 23.0941 30.3848 24.7191C30.166 26.3962 29.7389 28.167 29.0618 29.8024C28.8118 30.417 29.3639 31.0524 30.0202 30.8962C32.8327 30.2295 34.9368 29.2607 36.4785 28.2191C37.8639 28.6045 39.3639 28.8337 40.9368 28.8337C48.1244 28.8337 53.9577 24.4066 53.9577 18.9378C53.9577 13.4691 48.1244 9.04199 40.9368 9.04199Z'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M26.8737 42.375V39.25C26.8737 37.8958 24.0091 36.125 21.6654 36.125C19.3216 36.125 16.457 37.8958 16.457 39.25V42.375'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M21.666 25.709C20.0514 25.709 18.541 26.8861 18.541 28.334V30.3757C18.541 31.8236 20.0514 33.0007 21.666 33.0007C23.2806 33.0007 24.791 31.8236 24.791 30.3757V28.334C24.791 26.8861 23.2806 25.709 21.666 25.709Z'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect
          width='50'
          height='43.75'
          fill='white'
          transform='translate(5 8)'
        />
      </clipPath>
    </defs>
  </svg>
);