import React, { useState } from 'react';
import {
  Wrap,
  HomeworkTag,
  TitleWrap,
  Title,
  PurposeTitle,
  PurposeText,
  Result,
  ResultTitle,
  ResultTextarea,
  SubmitButton,
  NoData,
  HomeworkMaterials,
  HomeworkTitle,
} from './styles';
import { Field, Form } from 'react-final-form';
import {
  useUploadHomework,
  useUploadComment,
} from 'queries/lesson/useHomeworkQuery';
import { useStore } from 'effector-react';
import { useParams } from 'react-router';
import { LessonHomework } from 'models/lesson/types';
import { useHomeworkQuery } from 'queries/lesson/useHomeworkQuery';
import { $homework } from 'models/homework';
import { Preloader } from 'ui/Preloader';
import { statuses, Statuses, SubmitHandler } from './types';
import { resetForm } from './helpers';
import { FileList } from './FileList';
import { AxiosError } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';
import { formatValidationErrors } from 'utilities/formatValidationErrors';
import { useTheme } from 'styled-components';
import { HomeworkAudioMaterial } from './HomeworkAudioMaterial';
import { HomeworkDocMaterial } from './HomeworkDocMaterial';
import { HomeworkVideoMaterial } from './HomerworkVideoMaterial';

export const Homework: React.FC<LessonHomework> = ({ id, ...rest }) => {
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, File>>({});
  const { courseId } = useParams<{ courseId: string }>();
  const theme = useTheme();
  const homework = useStore($homework);

  const uploadHomeWork = useUploadHomework();
  const uploadComment = useUploadComment();
  const { isLoading, isError, isFetching } = useHomeworkQuery(id);

  const showPreloader = isLoading || isFetching;
  const isRevision = homework?.status === Statuses.REVISION;

  const onSubmit: SubmitHandler = async ({ message }, form) => {
    if (!homework) return;
    const files = Object.keys(uploadedFiles).map(Number);

    return await new Promise((resolve, reject) =>
      isRevision
        ? uploadComment.mutate(
            {
              complete_id: homework.id,
              type: 'complete_homework',
              files: files,
              message,
            },
            { onSuccess: resolve, onError: reject }
          )
        : uploadHomeWork.mutate(
            {
              course_id: Number(courseId),
              homework_id: id,
              message,
              file_id: files,
            },
            { onSuccess: resolve, onError: reject }
          )
    )
      .then(() => () => resetForm(form, setUploadedFiles))
      .catch((err: AxiosError<{ error: ServerValidationError }>) => {
        return formatValidationErrors(err.response?.data.error);
      });
  };

  if (showPreloader) return <Preloader />;
  if (!homework) return <NoData>Нет данных</NoData>;
  if (isError) return <NoData>Не удалось загрузить домашнее задание</NoData>;

  const status = statuses[homework.status];
  const isShowForm = !homework.status || isRevision;

  return (
    <Wrap {...rest}>
      <TitleWrap>
        <Title>Домашнее задание</Title>
        <HomeworkTag
          status={status?.color ?? 'wait'}
          text={homework.status ?? 'Не выполнено'}
          showIcon={true}
        />
      </TitleWrap>
      <div>
        <PurposeTitle>Цель:</PurposeTitle>
        <PurposeText>
          {homework.description ?? homework.homework.description}
        </PurposeText>
        {homework?.materials?.audios?.length ||
        homework?.materials?.documents?.length ||
        homework?.materials?.videos?.length ? (
          <HomeworkMaterials>
            <HomeworkTitle>Инструкция к выполнению:</HomeworkTitle>
            {homework?.materials?.audios?.length
              ? homework.materials.audios.map((item, index) => (
                  <HomeworkAudioMaterial key={index} {...item} />
                ))
              : null}
            {homework?.materials?.documents?.length
              ? homework.materials.documents.map((item, index) => (
                  <HomeworkDocMaterial key={index} {...item} />
                ))
              : null}
            {homework?.materials?.videos?.length
              ? homework.materials.videos.map((item, index) => (
                  <HomeworkVideoMaterial key={index} {...item} />
                ))
              : null}
          </HomeworkMaterials>
        ) : null}

        {isShowForm && (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Result>
                  <ResultTitle>Опишите результат</ResultTitle>
                  <Field
                    name={'message'}
                    fullWidth
                    backgroundColor={theme.colors.mainBgColor}
                    component={ResultTextarea}
                  />
                  <FileList
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    isRevision={isRevision}
                  />
                </Result>
                <SubmitButton type='submit'>Отправить</SubmitButton>
              </form>
            )}
          />
        )}
      </div>
    </Wrap>
  );
};
