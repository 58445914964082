import React from 'react';

export const CheckDeliveredIcon = (
  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g
      clipPath='url(#clip0)'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#B1B1B1'
    >
      <path d='M18.922 4.41a.833.833 0 010 1.18l-9.166 9.166a.833.833 0 01-1.179-1.178l9.167-9.167a.833.833 0 011.178 0zM14.756 4.41a.833.833 0 010 1.18l-9.167 9.166a.833.833 0 01-1.178 0L.244 10.589a.833.833 0 111.179-1.178L5 12.988l8.577-8.577a.833.833 0 011.179 0z' />
      <path d='M3.577 7.744a.833.833 0 011.179 0l1.666 1.667a.833.833 0 01-1.178 1.178L3.577 8.923a.833.833 0 010-1.179z' />
    </g>
    <defs>
      <clipPath id='clip0'>
        <path fill='#fff' d='M0 0h20v20H0z' />
      </clipPath>
    </defs>
  </svg>
);
