import React from 'react';

export const PlusButtonIcon = (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='20' cy='20' r='20' fill='#F2F2F2' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 12.0004C20.4658 12.0004 20.8434 12.378 20.8434 12.8438L20.8434 27.157C20.8434 27.6228 20.4658 28.0004 20 28.0004C19.5342 28.0004 19.1566 27.6228 19.1566 27.157L19.1566 12.8438C19.1566 12.378 19.5342 12.0004 20 12.0004Z'
      fill='#8D8D8D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 20.0004C12 19.5346 12.3776 19.157 12.8434 19.157L27.1566 19.157C27.6224 19.157 28 19.5346 28 20.0004C28 20.4662 27.6224 20.8438 27.1566 20.8438L12.8434 20.8438C12.3776 20.8438 12 20.4662 12 20.0004Z'
      fill='#8D8D8D'
    />
  </svg>
);
