import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';

export const Wrapper = styled.div`
  width: 500px;
`;

export const EmptyOrderMessage = styled.div`
  ${({ theme }) => theme.typography.bodyText1};
  margin-bottom: 24px;
`;

export const Title = styled.span<{ isSubTitle?: boolean }>`
  ${({ theme, isSubTitle }) =>
    isSubTitle ? theme.typography.headline3 : theme.typography.headline2};
  margin-right: 24px;
`;

export const ButtonSC = styled(Button)`
  &.LineBack {
    stroke-width: 0;
  }
  &.LineBack > span:nth-of-type(2) {
    margin-left: 0;
  }
`;

export const HeadInfo = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const TrackInfo = styled.div`
  margin-top: 16px;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${props => props.theme.colors.secondTextColor};
`;

export const TrackCode = styled.div`
  width: 500px;
  height: 48px;
  background: ${props => props.theme.colors.secondBgColor};
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  svg {
    cursor: pointer;
    margin-right: 13px;
  }
  span {
    margin-left: 14px;
  }
`;
export const DetailHeeader = styled.div`
  ${({ theme }) => theme.typography.bodyText1}
  font-weight:bold;
`;
export const DetailText = styled.div`
  margin-top: 6px;
  ${({ theme }) => theme.typography.bodyText2};
`;
export const Detail = styled.div`
  margin-top: 24px;
  &:last-child {
    position: relative;
  }
  &:last-child:before {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
    width: 100%;
    position: absolute;
    bottom: -40px;
  }
`;

export const Products = styled.div`
  margin-top: 65px;
  margin-bottom: 60px;
`;

export const ProducsInfo = styled.div`
  margin-top: 25px;
  display: flex;
  position: relative;
  margin-bottom: 46px;
  &:before {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};

    width: 100%;
    position: absolute;
    bottom: -24px;
  }
`;
export const Description = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PriceList = styled.div`
  margin-top: 41px;
  width: 320px;
  margin-left: 180px;
  position: relative;
`;

export const Name = styled.div`
  ${({ theme }) => theme.typography.bodyText1}
  font-weight: bold;
`;

export const Count = styled.div`
  ${({ theme }) => theme.typography.bodyText3}
  color: ${props => props.theme.colors.thirdTextAndIconColor};
`;

export const Price = styled.div`
  ${({ theme }) => theme.typography.bodyText1}
  font-weight: bold;
`;

export const Product = styled.div`
  display: flex;
  justify-content: space-between;
  & > span:nth-of-type(1) {
    ${({ theme }) => theme.typography.bodyText2}
  }
  & > span:nth-of-type(2) {
    ${({ theme }) => theme.typography.bodyText2}
    font-weight: bold;
  }
`;

export const Bonus = styled.div`
  display: flex;
  justify-content: space-between;
  & > span:nth-of-type(1) {
    ${({ theme }) => theme.typography.bodyText2}
  }
  & > span:nth-of-type(2) {
    ${({ theme }) => theme.typography.bodyText2}
    font-weight: bold;
  }
`;

export const Delivery = styled.div`
  display: flex;
  justify-content: space-between;
  & > span:nth-of-type(1) {
    ${({ theme }) => theme.typography.bodyText2}
  }
  & > span:nth-of-type(2) {
    ${({ theme }) => theme.typography.bodyText2}
    font-weight: bold;
  }
`;

export const Result = styled.div`
  &:before {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
    width: 100%;
    position: absolute;
    bottom: 34.4px;
  }
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  & > span:nth-of-type(1) {
    ${({ theme }) => theme.typography.bodyText1}
    font-weight: bold;
  }
  & > span:nth-of-type(2) {
    ${({ theme }) => theme.typography.bodyText1}
    font-weight: bold;
  }
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const PreloaderSC = styled(Preloader)`
  margin: auto;
`;

export const Preview = styled.div`
  background: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 14px;
  margin-right: 11px;
  width: 120px;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
