import styled from 'styled-components';
import { Tag } from 'ui/Tag';

export const Item = styled.div`
  display: grid;
  grid-template-areas: 'photo photo photo photo gap inner inner inner inner';
  grid-template-columns: repeat(9, 1fr);
  column-gap: 24px;
  padding: 0 0 20px;
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  width: 100%;
  height: 1px;
  margin: 40px 0;
`;

export const ImageWrap = styled.div`
  grid-area: photo;
  height: 265px;
  background-color: ${({ theme }) => theme.colors.actionColor};
  border-radius: 14px;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Inner = styled.div`
  grid-area: inner;
`;

export const TagItem = styled(Tag)``;

export const Price = styled.div`
  display: flex;
  align-items: center;
`;

export const Subtitle = styled.p`
  ${({ theme }) => theme.typography.bodyText2}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  & + ${Price} {
    margin: 30px 0 0;
  }
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline1}
  & + ${Subtitle} {
    margin: 20px 0 0;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const PriceTag = styled.p`
  ${({ theme }) => theme.typography.headline3}
  & + ${Link} {
    margin: 0 0 0 40px;
  }
`;

export const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 0;
  & ${TagItem} {
    margin: 0 5px;
  }

  & + ${Title} {
    margin: 12px 0 0 0;
  }
  & + ${Subtitle} {
    margin: 20px 0 0 0;
  }
`;

export const StartDatetime = styled.div`
  display: flex;
  margin: 30px 0 0;
`;

export const StartTime = styled.div`
  margin: 0 0 0 40px;
`;

export const StartText = styled.p`
  ${({ theme }) => theme.typography.title1};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0 0 8px;
`;

export const StartTitle = styled.p`
  margin: 0;
`;

export const Description = styled.div`
  width: calc(((100% - (24px * 8)) / 9) * 7 + (24px * 6));
`;

export const DescriptionTitle = styled.p`
  ${({ theme }) => theme.typography.headline3}
`;

export const DescriptionText = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 24px 0 0;
`;
