import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { CategoryButtonProps } from './types';

const ButtonIcon = styled.span`
  transition: fill 0.3s;
`;

const ButtonText = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  transition: color 0.3s;
`;

const ButtonWrap = styled.button<
  CategoryButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  border-radius: 14px;
  transition: all 0.3s;
  cursor: ${props => (!props.disabled ? 'pointer' : 'not-allowed')};
  color: ${props =>
    props.disabled
      ? props.theme.colors.disabledColor
      : props.isActive
      ? props.theme.colors.mainBgColor
      : props.theme.colors.mainColor};
  display: inline-flex;
  padding: ${props => props.theme.button.secondary.padding.large};
  align-items: center;
  margin: 0;
  outline: none;
  background-color: ${props =>
    props.isActive ? props.theme.colors.mainColor : 'transparent'};
  flex-direction: ${props =>
    props.iconAlign !== 'right' ? 'row' : 'row-reverse'};
  border: ${props =>
    props.disabled
      ? `2px solid ${props.theme.colors.thirdBgColor}`
      : '2px solid transparent'};
  &:hover {
    ${props =>
      !props.disabled && props.isActive
        ? `color: ${props.theme.colors.mainBgColor};`
        : `color: ${props.theme.colors.mainColor};`}
    ${props =>
      props.isActive
        ? `border: 2px solid ${props.theme.colors.mainColor};`
        : !props.disabled &&
          `border: 2px solid ${props.theme.colors.thirdBgColor};`}
    transition: all 0.3s;
  }
  &:focus {
    outline: none;
    ${({ disabled, theme }) =>
      !disabled ? `box-shadow: 0 0 0 2px ${theme.colors.thirdBgColor};` : null}
  }
  & ${ButtonIcon} {
    fill: ${props =>
      props.disabled
        ? props.theme.colors.disabledColor
        : props.isActive
        ? props.theme.colors.actionColor
        : props.theme.colors.mainColor};
  }

  & ${ButtonIcon} + ${ButtonText}, & ${ButtonText} + ${ButtonIcon} {
    margin-left: 10px;
  }
`;

const CategoryButton: React.FC<
  CategoryButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ label, isActive = true, icon, children, ...props }) => {
  const showText = !!label || !!children;
  return (
    <ButtonWrap type='button' isActive={isActive} {...props}>
      {icon ? <ButtonIcon>{icon}</ButtonIcon> : null}
      {showText ? <ButtonText>{label || children}</ButtonText> : null}
    </ButtonWrap>
  );
};

export default CategoryButton;
