import { useStore } from 'effector-react';
import { $comments, $homework } from 'models/homework/model';
import React from 'react';
import { Comment } from './Comment/';
import { Wrap, Title, Count, List, NoData } from './styles';
import { useUserProfile } from 'models/auth/model';

export const Commentaries: React.FC = props => {
  const comments = useStore($comments);
  const homework = useStore($homework);
  const user = useUserProfile();

  const completedHomeworkCount = homework?.status ? 1 : 0;
  const commentsCount = comments?.length ?? 0;

  return (
    <Wrap {...props}>
      <Title>
        Комментарии <Count>{completedHomeworkCount + commentsCount}</Count>
      </Title>
      {homework?.status || comments.length ? (
        <List>
          {homework?.status && (
            <Comment
              text={homework.message}
              author={{ name: user?.name, lastname: user?.last_name }}
              date={new Date(homework.date_send)}
              files={homework.attaches}
              photo={user?.avatar}
            />
          )}
          {comments.map(({ id, text, user, date_send, attach }) => {
            const [name, lastname] = user.name.split(' ');
            return (
              <Comment
                key={id}
                text={text}
                author={{ name, lastname }}
                date={new Date(date_send)}
                photo={user.avatar}
                files={attach}
              />
            );
          })}
        </List>
      ) : (
        <NoData>Пока нет ни одного комментария</NoData>
      )}
    </Wrap>
  );
};
