import React from 'react';

import { ButtonSC, Wrap } from './styled';

interface IProps {
  selected: number;
  setSelected: (value: number) => void;
  tabNames: string[];
}
// TODO? табы не оч красивые, возможно стоит переделать
export const Tabs: React.FC<IProps> = ({
  selected,
  setSelected,
  tabNames,
  ...rest
}) => {
  const [first, second, third] = tabNames;

  return (
    <Wrap {...rest}>
      <ButtonSC
        selected={selected === 0}
        buttonType='link'
        onClick={() => setSelected(0)}
      >
        {first}
      </ButtonSC>
      <ButtonSC
        selected={selected === 1}
        buttonType='link'
        onClick={() => setSelected(1)}
      >
        {second}
      </ButtonSC>
      <ButtonSC
        selected={selected === 2}
        buttonType='link'
        onClick={() => setSelected(2)}
      >
        {third}
      </ButtonSC>
    </Wrap>
  );
};
