import styled from 'styled-components';
import { Button } from 'ui/Button';
import { InputText } from 'ui/InputText';
export const Wrap = styled.div`
  flex-direction: column;
`;

export const ButtonSC = styled(Button)`
  margin-left: 24px;
`;

export const SubTitle = styled.div`
  ${({ theme }) => theme.typography.headline3}
  margin:24px 0 16px 0;
`;

export const TextSC = styled.div`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.secondTextColor};
  margin-bottom: 16px;
  width: 500px;
`;

export const InputSC = styled(InputText)``;
