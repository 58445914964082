import React, { useEffect, useMemo } from 'react';
import Swiper from 'react-id-swiper';
import { $promosData, clearPromoList } from 'models/sales/model';
import { useStore } from 'effector-react';
import {
  Title,
  Subtitle,
  ActionButton,
  Slide,
  StyledPreloader,
  Wrap,
  StyledLink,
} from './styles';
import { usePromosListQuery } from 'queries/sales/usePromosListQuery';
import { Path } from 'constants/paths';
import { SwiperOptions } from 'swiper';
import { getImagePath } from 'utilities/getImagePath';

const swiperConfig: SwiperOptions = {
  spaceBetween: 24,
  centeredSlides: true,
};

export const PromoCarousel: React.FC = () => {
  const { isLoading, isFetching } = usePromosListQuery();
  const data = useStore($promosData);
  const showPreloader = isLoading || isFetching;
  const filteredPromos = useMemo(() => data.filter(item => item.is_active), [
    data,
  ]);

  useEffect(() => {
    return () => clearPromoList();
  }, []);

  return showPreloader ? (
    <Wrap>
      <StyledPreloader />
    </Wrap>
  ) : data?.length ? (
    <Swiper {...swiperConfig}>
      {filteredPromos.map(item => (
        <Slide key={item.id} image={getImagePath(item)}>
          <Title>{item.name}</Title>
          <Subtitle>{item.preview}</Subtitle>
          <StyledLink to={`${Path.sales}/${item.id}`}>
            <ActionButton buttonType='primary' size={'large'}>
              Подробнее
            </ActionButton>
          </StyledLink>
        </Slide>
      ))}
    </Swiper>
  ) : null;
};
