export const FireSaleIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M10.4249 14.4048C10.9322 13.3904 11.5366 11.6631 10.6922 10.9182C9.91392 11.0351 9.28457 11.0358 8.7725 10.9208C7.48306 10.6313 7.07491 9.60973 7.42992 8.30146C5.69507 9.88684 5.62182 12.2245 6.26496 14.4755C6.36942 14.8412 5.96012 15.1407 5.64318 14.9294C5.53524 14.8574 3 13.1368 3 10.4873C3 6.14662 7.9211 5.95986 7.9211 1.41058C7.9211 1.06307 8.32829 0.87287 8.59477 1.0964C10.5612 2.74598 14.1395 6.71131 13.9387 10.3583C13.8392 12.1663 12.8569 13.7043 11.0192 14.9294C10.6716 15.1611 10.2376 14.7795 10.4249 14.4048Z' />
  </svg>
);
