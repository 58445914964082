import React from 'react';

export const UserLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M51.7392 43.6954V18.6954C51.7392 17.4997 50.761 16.5215 49.5653 16.5215H27.8262'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M35.4355 43.6953H55.0008V44.7823C55.0008 47.1844 53.0551 49.1301 50.6529 49.1301H35.4355'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M35.4355 35.5109C36.5225 34.6305 38.2508 33.9131 39.7725 33.9131C42.2182 33.9131 45.2182 35.7609 45.2182 37.174V40.4348'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M39.7824 23.0439C41.4671 23.0439 43.0432 24.2722 43.0432 25.7831V27.9135C43.0432 29.4244 41.4671 30.6526 39.7824 30.6526C38.0976 30.6526 36.5215 29.4244 36.5215 27.9135V25.7831C36.5215 24.2722 38.0976 23.0439 39.7824 23.0439Z'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M19.142 27.3913C22.7833 27.3913 25.6529 24.9891 25.6529 20.8696V16.5217C25.6529 12.4022 22.7615 10 19.1202 10C15.4789 10 12.6094 12.4022 12.6094 16.5217V20.8696C12.6094 24.9891 15.5007 27.3913 19.142 27.3913Z'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M33.25 50.2179L33.2391 38.8483C33.2391 33.37 23.9239 30.5439 19.1087 30.5439C14.3043 30.5439 5 33.37 5 38.8483L5.01087 50.2179'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>
);

