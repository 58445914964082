import styled from 'styled-components';
import { Button } from 'ui/Button';

export const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  padding: 10px 0;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.12);
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  width: 260px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
`;

export const LogoutButton = styled(Button)`
  padding: 12px 16px;
  width: 100%;
  justify-content: flex-start;
  & > span {
    ${({ theme }) => theme.typography.bodyText3};
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 12px 16px;
  ${({ theme }) => theme.typography.bodyText3};
  transition: background-color 0.3s;
  &:hover {
    transition: background-color 0.3s;
    background-color: ${({ theme }) => theme.colors.secondBgColor};
  }
`;
