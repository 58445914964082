import styled from 'styled-components';
import { Modal } from 'ui/Modal';
import { InputText } from 'ui/InputText';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';

export const StyledModal = styled(Modal)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const ModalIcon = styled.div`
  font-size: 0;
  background-color: #4bb5f1;
  align-self: center;
  padding: 11px;
  border-radius: 14px;

  & > svg {
    width: 36px;
    height: 36px;
    fill: ${({ theme }) => theme.colors.mainBgColor};
  }
`;

export const ModalTitle = styled.p`
  ${({ theme }) => theme.typography.headline2}
  margin: 24px 0 0;
`;

export const UsernameInput = styled(InputText)`
  margin: 40px 0 0;
`;

export const ModalButton = styled(Button)`
  width: 340px;
  display: block;
  margin: 40px auto 0;
`;

export const StyledPreloader = styled(Preloader)`
  text-align: center;
  margin: 40px 0 0;
  padding: 9px 0;
`;

export const CloseIcon = styled.div`
  font-size: 0;
  margin: 0 0 0 4px;
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  transition: background-color 0.3s;
  &:hover {
    transition: background-color 0.3s;
    background-color: ${({ theme }) => theme.colors.secondBgColor};
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;
