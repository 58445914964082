import styled from 'styled-components';

export const Wrap = styled.div`
  margin-top: 60px;
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline2};
  margin: 0;
`;

export const Count = styled.span`
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
`;

export const List = styled.div`
  margin-top: 24px;
`;

export const NoData = styled.div`
  margin-top: 24px;
  ${({ theme }) => theme.typography.bodyText2};
`;
