import styled from 'styled-components';

export const DropdownWrap = styled.div`
  position: relative;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  padding: 10px 0 20px;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.12);
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  width: 260px;
`;

export const DropdownItem = styled.div`
  padding: 12px 12px 12px 18px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondBgColor};
    transition: background-color 0.3s;
  }
`;
