import React from 'react';
import { Title, Subtitle, Wrap } from './styles';

export const MiniBanner: React.FC<{
  title: string;
  subtitle: string;
  image: string;
}> = ({ title, subtitle, ...rest }) => {
  return (
    <Wrap {...rest}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Wrap>
  );
};
