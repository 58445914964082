import styled from 'styled-components';

export const Wrap = styled.div`
  margin-top: 60px;
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline1};
`;

export const Inner = styled.div`
  margin: 24px -12px 0;
  display: flex;
`;

export const Item = styled.div`
  margin: 0 12px;
  width: calc((100% - 48px) / 2);
  border: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-radius: 14px;
  padding: 24px 24px 40px;
`;

export const ItemTitle = styled.div`
  margin: 40px 0 0;
  ${({ theme }) => theme.typography.headline2};
  padding: 0 16px;
`;

export const ItemText = styled.div`
  padding: 0 16px;
  ${({ theme }) => theme.typography.bodyText2}
  color: ${({ theme }) => theme.colors.secondTextColor};
`;

export const List = styled.ul`
  margin: 24px 0 0;
  padding: 0 16px 0 36px;
`;

export const ListItem = styled.li`
  ${({ theme }) => theme.typography.bodyText2};
  & + & {
    margin-top: 4px;
  }
`;

export const ItemImage = styled.img`
  width: 100%;
  border-radius: 14px;
`;
