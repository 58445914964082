import React from 'react';

export const ShoppingCartIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25 15.75C5.25 14.9216 5.92157 14.25 6.75 14.25C7.57843 14.25 8.25 14.9216 8.25 15.75C8.25 16.5784 7.57843 17.25 6.75 17.25C5.92157 17.25 5.25 16.5784 5.25 15.75Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5 15.75C13.5 14.9216 14.1716 14.25 15 14.25C15.8284 14.25 16.5 14.9216 16.5 15.75C16.5 16.5784 15.8284 17.25 15 17.25C14.1716 17.25 13.5 16.5784 13.5 15.75Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0.75C0 0.335786 0.335786 0 0.75 0H3.75C4.10747 0 4.41526 0.252289 4.48541 0.602807L5.11532 3.75H17.25C17.4735 3.75 17.6854 3.8497 17.8279 4.02192C17.9704 4.19414 18.0286 4.42093 17.9867 4.6405L16.7857 10.9386C16.6828 11.4566 16.401 11.9218 15.9896 12.2529C15.5803 12.5824 15.0687 12.7582 14.5435 12.75H7.26648C6.74128 12.7582 6.22965 12.5824 5.82036 12.2529C5.40919 11.922 5.12748 11.4569 5.0245 10.9392C5.02445 10.939 5.02454 10.9395 5.0245 10.9392L3.77159 4.6794C3.76651 4.6587 3.76229 4.63765 3.75896 4.61631L3.13524 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75ZM5.41555 5.25L6.49563 10.6464C6.52992 10.819 6.62385 10.9741 6.76096 11.0845C6.89807 11.1949 7.06964 11.2535 7.24563 11.2501L7.26 11.25H14.55L14.5644 11.2501C14.7404 11.2535 14.9119 11.1949 15.049 11.0845C15.1855 10.9746 15.2792 10.8205 15.3139 10.6488L16.3435 5.25H5.41555Z'
      />
    </g>
  </svg>
);

