import { InputText } from 'ui/InputText';
import styled from 'styled-components';
import { Button } from 'ui/Button/';
import InputDatepicker from 'ui/InputDatepicker/InputDatepicker';
import { Preloader } from 'ui/Preloader';
import { InputPhone } from 'ui/InputPhone';

export const Item = styled(InputText)`
  margin-bottom: 24px;
  display: flex;
  & > label {
    align-self: center;
  }
`;
export const Phone = styled(InputPhone)`
  margin-bottom: 24px;
  display: flex;
  & > label {
    align-self: center;
  }
`;

export const InputDatepickerSC = styled(InputDatepicker)`
  display: flex;
  & > label {
    align-self: center;
  }
`;

export const Gender = styled.div`
  margin: 16px 0 41px 0;
  display: flex;
`;

export const FormSC = styled.form`
  display: flex;
  flex-direction: column;
`;

export const LabelSC = styled.label`
  width: 144px;
  margin: 0 16px 0 0;
  color: ${props => props.theme.colors.secondTextColor};
`;

export const RadioGroup = styled.div`
  & > label:last-child {
    margin-left: 44px;
  }
`;

export const BornDate = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 24px;
`;

export const SocialNetworks = styled.div`
  margin-top: 40px;
  margin-bottom: 19px;
`;

export const Actions = styled.div`
  position: relative;
  display: flex;
  margin-top: 24px;
  align-items: center;
  & > button:nth-of-type(2) {
    margin-left: 40px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightBannerColumn = styled.div`
  margin-top: 56px;
  margin-left: 57px;
  & > img {
    cursor: pointer;
  }
`;

export const PreloaderSC = styled(Preloader)`
  margin: 0 10px 0 auto;
`;

export const ButtonSC = styled(Button)`
  // margin: 24px 0 60px 366px;
`;

export const SaveUserData = styled.div`
  margin-top: 24px;
  margin-bottom: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
