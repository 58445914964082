import React from 'react';

export const InstagramIcon = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 3C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H7ZM1 7C1 3.68629 3.68629 1 7 1H17C20.3137 1 23 3.68629 23 7V17C23 20.3137 20.3137 23 17 23H7C3.68629 23 1 20.3137 1 17V7Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.4833 8.98924C11.8591 8.89668 11.2216 9.0033 10.6615 9.29392C10.1014 9.58455 9.64721 10.0444 9.36351 10.608C9.07982 11.1717 8.98107 11.8104 9.08132 12.4334C9.18157 13.0564 9.47571 13.6319 9.9219 14.0781C10.3681 14.5243 10.9436 14.8184 11.5666 14.9187C12.1896 15.0189 12.8283 14.9202 13.392 14.6365C13.9556 14.3528 14.4155 13.8986 14.7061 13.3385C14.9967 12.7784 15.1033 12.1409 15.0108 11.5167C14.9164 10.88 14.6197 10.2906 14.1645 9.83547C13.7094 9.38034 13.12 9.08366 12.4833 8.98924ZM9.74037 7.51868C10.6739 7.0343 11.7363 6.85661 12.7766 7.01087C13.8378 7.16823 14.8202 7.6627 15.5788 8.42126C16.3373 9.17981 16.8318 10.1622 16.9891 11.2234C17.1434 12.2637 16.9657 13.3261 16.4813 14.2596C15.997 15.1931 15.2306 15.9501 14.2912 16.423C13.3518 16.8958 12.2872 17.0604 11.2489 16.8933C10.2105 16.7262 9.25134 16.236 8.50768 15.4923C7.76403 14.7487 7.2738 13.7895 7.10672 12.7511C6.93964 11.7128 7.10422 10.6482 7.57704 9.70884C8.04987 8.76944 8.80687 8.00305 9.74037 7.51868Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.5 6.5C16.5 5.94772 16.9477 5.5 17.5 5.5H17.51C18.0623 5.5 18.51 5.94772 18.51 6.5C18.51 7.05228 18.0623 7.5 17.51 7.5H17.5C16.9477 7.5 16.5 7.05228 16.5 6.5Z'
    />
  </svg>
);
