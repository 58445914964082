import styled from 'styled-components';
import { Button } from 'ui/Button';
import { InputText } from 'ui/InputText';
import { LoyaltyPoints } from './LoyaltyPoints';
import { Preloader } from 'ui/Preloader';

export const PromoCode = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTitle = styled.p`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.secondTextColor};
  max-width: 491px;
  margin: 0 0 16px;

  & + ${PromoCode} {
    margin-top: 24px;
  }
`;

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline2};
  & + ${SubTitle} {
    margin-top: 24px;
  }
`;

export const StyledButton = styled(Button)<{ invert?: boolean }>`
  align-self: flex-start;
  ${({ invert, theme }) =>
    !!invert &&
    `
  background-color: ${theme.colors.mainColor};
  color: ${theme.colors.mainBgColor};
  &:hover,
  &:active {
    background-color: ${theme.colors.socialMediaNotActiveColor};
  }
  `}
`;

export const Item = styled(InputText)`
  flex-grow: 1;
  & + & {
    margin: 24px 0 0;
  }
  & + ${StyledButton} {
    margin-left: 24px;
  }
`;

export const StyledLoyaltyPoints = styled(LoyaltyPoints)`
  margin-top: 24px;
`;

export const PromoCodeForm = styled.form`
  display: flex;
  align-items: center;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 0 63px 0 87px;
`;
