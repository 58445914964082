import React from 'react';
import {
  Wrap,
  Title,
  Subtitle,
  Inner,
  Item,
  ItemTitle,
  ItemText,
  List,
  ListItem,
} from './styles';

export const Statuses: React.FC = props => {
  return (
    <Wrap {...props}>
      <Title>VIP-места на всех текущих курсах</Title>
      <Subtitle>Чем дольше в клубе — тем выше статус</Subtitle>
      <Inner>
        <Item>
          <ItemTitle>Бронзовый</ItemTitle>
          <ItemText>На старте</ItemText>
          <List>
            <ListItem>Чат 24/7</ListItem>
            <ListItem>Участие в выездных интенсивах</ListItem>
            <ListItem>VIP-места на всех текущих курсах</ListItem>
          </List>
        </Item>
        <Item>
          <ItemTitle>Серебряный</ItemTitle>
          <ItemText>1 год в клубе</ItemText>
          <List>
            <ListItem>Возможности бронзового статуса</ListItem>
            <ListItem>Безлимитный доступ к записям всех вебинаров </ListItem>
          </List>
        </Item>
        <Item>
          <ItemTitle>Золотой</ItemTitle>
          <ItemText>2 годa в клубе</ItemText>
          <List>
            <ListItem>Возможности серебряного статуса</ListItem>
            <ListItem>Записи всех интенсивов</ListItem>
            <ListItem>Вступление в gold-сообщество</ListItem>
          </List>
        </Item>
      </Inner>
    </Wrap>
  );
};
