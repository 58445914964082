import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { RouteComponentProps } from 'react-router';
import { useGetBookItemQuery } from 'queries/catalog/useGetBookItemQuery';
import { $productItem, clearBookItem } from 'models/catalog/model';
import { Info } from './Info';
import Ruble from 'components/common/Ruble';
import { baseUrl } from 'api/apiClient';
import {
  StyledPreloader,
  NoItemText,
  Wrap,
  Preview,
  Photo,
  MainPhoto,
  ThumbPhoto,
  Options,
  Top,
  Bonus,
  Title,
  OfferOptions,
  OfferOption,
  ThumbItem,
  Price,
  PriceText,
  AddToCart,
  Description,
  DescriptionItem,
  DescriptionItemTitle,
  DescriptionItemText,
  Image,
  StyledNoImage,
  Author,
} from './styles';
import { ProductProposals } from 'models/catalog/types';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import { showAuthModal } from 'models/auth';
import { useAddCartItemQuery } from 'queries/cart/useAddCartItemQuery';
import { useUserProfile } from 'models/auth/model';
import { getImagePath } from 'utilities/getImagePath';

export const ProductPage: React.FC<
  RouteComponentProps<{ id: string; key: string }>
> = ({ match }) => {
  const { id, key: productKey } = match.params;
  const { isLoading, isFetching, isError } = useGetBookItemQuery(id);
  const { mutate: addToCart } = useAddCartItemQuery();
  const data = useStore($productItem);
  const user = useUserProfile();

  const showPreloader = isLoading || isFetching;
  const [mainPicture, setMainPicture] = useState('');
  const handlePictureChange = (path: string) => {
    setMainPicture(path);
  };

  const [productOption, setProductOption] = useState<ProductProposals | null>();

  useEffect(() => {
    // Если есть торговые предолжения, то берем и выбираем первое из них автоматом.
    if (data?.proposals && Object.keys(data.proposals).length) {
      const firstProposalKey: string = Object.keys(data.proposals)[0];
      const firstProposal = data.proposals[firstProposalKey];
      const image = getImagePath(firstProposal);
      if (!image) {
        firstProposal.images = data.images;
        setMainPicture(getImagePath(data));
      }
      setProductOption(firstProposal);
    } else if (data) {
      const {
        id,
        name,
        price,
        available,
        images,
        preview_text,
        weight,
        height,
        width,
        length,
      } = data;
      setProductOption({
        id,
        name,
        price,
        available,
        images,
        preview_text,
        weight,
        height,
        width,
        length,
      });
    }
  }, [data, id]);

  const handleChange = (proposal: ProductProposals) => {
    const firstProposal = Object.assign(proposal);
    const image = getImagePath(firstProposal);
    if (!image) {
      firstProposal.images = data?.images;
      setMainPicture(getImagePath(data));
    }
    setProductOption(firstProposal);
  };

  useEffect(() => {
    const image = getImagePath(productOption);
    setMainPicture(image);
  }, [productOption]);

  useEffect(() => {
    return () => clearBookItem();
  }, []);

  const handleAddToCart = () => {
    const { id } = productOption || {};
    if (!id) {
      toast.error(<ErrorMessage text={'Такого товара не существует!'} />);
      return;
    }
    if (!user) {
      showAuthModal();
      return;
    }
    addToCart({ id, count: '1' });
  };

  if (!showPreloader && !data)
    return <NoItemText>Такого товара нет!</NoItemText>;

  if (isError) return <NoItemText>Не удалось загрузить товар</NoItemText>;

  return showPreloader ? (
    <StyledPreloader />
  ) : (
    <Wrap>
      <Preview>
        <Photo>
          <MainPhoto>
            {mainPicture ? (
              <Image src={mainPicture} alt={productOption?.name} />
            ) : (
              <StyledNoImage />
            )}
          </MainPhoto>
          {productOption?.images?.length &&
          productOption?.images?.length > 1 ? (
            <ThumbPhoto>
              {productOption?.images?.map((image, index) => (
                <ThumbItem
                  key={index}
                  selected={baseUrl + image === mainPicture}
                  onClick={() => handlePictureChange(baseUrl + image)}
                >
                  <Image
                    src={`${baseUrl}${image}`}
                    alt={productOption?.name + index}
                  />
                </ThumbItem>
              ))}
            </ThumbPhoto>
          ) : null}
        </Photo>
        <Options>
          {productKey === 'books' ? (
            <Top>
              <Bonus>
                +{productOption?.price && Math.ceil(productOption?.price * 0.1)}{' '}
                Б
              </Bonus>
            </Top>
          ) : null}
          <Title>{productOption?.name || ''}</Title>
          {data?.preview_text && <Author>{data.preview_text}</Author>}
          {data?.proposals && Object.keys(data.proposals).length ? (
            <OfferOptions>
              {Object.keys(data.proposals).map((key: string) => {
                const { price, preview_text, available } = data.proposals[key];
                return (
                  <OfferOption
                    key={key}
                    disabled={!available}
                    price={price}
                    preview={preview_text}
                    currency={Ruble}
                    pressed={
                      !!available && productOption?.id.toString() === key
                    }
                    onClick={() => handleChange(data.proposals[key])}
                  />
                );
              })}
            </OfferOptions>
          ) : null}
          <Price>
            {productOption?.price && (
              <PriceText>
                {productOption.price} {Ruble}
              </PriceText>
            )}
            <AddToCart onClick={handleAddToCart}>В корзину</AddToCart>
          </Price>
          <Description>
            {data?.cover ? (
              <DescriptionItem>
                <DescriptionItemTitle>Тип обложки</DescriptionItemTitle>
                <DescriptionItemText>{data.cover}</DescriptionItemText>
              </DescriptionItem>
            ) : null}

            {productOption?.height &&
            productOption?.length &&
            productOption?.width ? (
              <DescriptionItem>
                <DescriptionItemTitle>Габариты</DescriptionItemTitle>
                <DescriptionItemText>{`${productOption.height}x${productOption.length}x${productOption.width}`}</DescriptionItemText>
              </DescriptionItem>
            ) : null}
            <DescriptionItem>
              {!!productOption?.weight && (
                <>
                  <DescriptionItemTitle>Вес</DescriptionItemTitle>
                  <DescriptionItemText>
                    {productOption.weight} г
                  </DescriptionItemText>
                </>
              )}
            </DescriptionItem>
          </Description>
        </Options>
      </Preview>
      <Info detailText={data?.detail_text} />
    </Wrap>
  );
};
