import { Modal } from 'ui/Modal';
import React from 'react';

import {
  Title,
  Text,
  Wrapper,
  ActivationCodeInput,
  Description,
  ActivationCode,
  ImgWrap,
  ImgSC,
} from './styled';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from 'icons/CopyIcon';
import { TGiftCard } from 'queries/account/giftCards/types';
import { NoImage } from 'ui/NoImage';
import { toast } from 'react-toastify';
import { SuccessMessage } from 'ui/SuccessMessage';
import { getImagePath } from 'utilities/getImagePath';

interface IProps {
  closeModal: () => void;
  cardInfo: TGiftCard;
}

export const ShareGiftCardModal: React.FC<IProps> = ({
  closeModal,
  cardInfo,
}) => {
  const imgSrc = getImagePath(cardInfo);

  const handleCopyLink = () => {
    closeModal();
    toast.success(<SuccessMessage text={'Код скопирован в буфер обмена'} />);
  };

  return (
    <Modal handleClose={closeModal}>
      <Wrapper>
        <ImgWrap>
          {imgSrc ? (
            <ImgSC src={imgSrc} alt={cardInfo.pictures[0].name} />
          ) : (
            <NoImage />
          )}
        </ImgWrap>
        <Title>Поделиться подарочной картой</Title>
        <Text>
          После активации карты другим пользователем вам будет начисленно 50
          бонусов
        </Text>
        <ActivationCode>
          <Description>Код активации</Description>
          <ActivationCodeInput>
            <span>{cardInfo.activation_code}</span>
            <CopyToClipboard
              text={cardInfo.activation_code}
              onCopy={handleCopyLink}
            >
              {CopyIcon}
            </CopyToClipboard>
          </ActivationCodeInput>
        </ActivationCode>
      </Wrapper>
    </Modal>
  );
};
