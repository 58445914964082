import { RegistrationForm } from './RegistrationForm';
import { ConfirmCodeForm } from '../../common/ConfirmCodeForm';
import { PasswordForm } from './PasswordForm';
import React from 'react';
import { FormsProps } from './types';

export const Forms: React.FC<FormsProps> = ({
  step,
  onSubmitRegistrationForm,
  phone,
  onSubmitConfirmCodeForm,
  setButtonHandler,
  onSubmitConfirmRegistrationForm,
}) => {
  switch (step) {
    case 'registration': {
      //Первый шаг регистрации
      return (
        <RegistrationForm
          onSubmit={onSubmitRegistrationForm}
          setButtonHandler={setButtonHandler}
        />
      );
    }
    case 'code': {
      return (
        // Второй шаг регистрации
        <ConfirmCodeForm
          phone={phone}
          type={'registration'}
          onSubmit={onSubmitConfirmCodeForm}
          setButtonHandler={setButtonHandler}
        />
      );
    }
    case 'password': {
      // Третий шаг регистрации
      return (
        <PasswordForm
          onSubmit={onSubmitConfirmRegistrationForm}
          setButtonHandler={setButtonHandler}
        />
      );
    }
    default:
      throw new Error(`Unknown order state: '${step}'!`);
  }
};
