import React from 'react';

export const CoursesIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M14.8506 5.96161L13.7115 7.10067L10.8639 4.2495L12.0029 3.11043C12.235 2.8784 12.6392 2.8784 12.8713 3.11043L14.8506 5.09325C15.0896 5.33231 15.0896 5.72255 14.8506 5.96161ZM7.63188 13.1909L4.78422 10.3397L9.84784 5.26551L12.6955 8.11669L7.63188 13.1909ZM4.1268 11.7143L6.25727 13.8483L3.29008 14.685L4.1268 11.7143ZM15.8666 4.07723L13.8873 2.09442C13.5006 1.7077 12.1049 0.976452 10.9904 2.09442L3.25844 9.83343C3.17055 9.92132 3.10727 10.0268 3.07563 10.1463L1.56391 15.5287C1.4936 15.7783 1.56743 16.0455 1.74672 16.2319C1.92954 16.4182 2.29516 16.4498 2.44985 16.4147L7.82875 14.8994C7.94829 14.8678 8.05375 14.8045 8.14164 14.7166L15.8666 6.97762C16.6646 6.17958 16.6646 4.8788 15.8666 4.07723Z' />
  </svg>
);
