import { useQuery } from 'react-query';
import { getFreeCoursesFx, setFreeCourses } from 'models/catalog/model';
import { FreeCoursesQuery } from './types';

export const useFreeCoursesQuery: FreeCoursesQuery = () =>
  useQuery('courses', () => getFreeCoursesFx(), {
    onSuccess: res => {
      setFreeCourses(res.data.data);
    },
    refetchOnWindowFocus: false,
  });
