import React from 'react';
import { data } from '../../data';
import decor from 'images/module_icon_decor.svg';
import {
  Title,
  List,
  Item,
  ItemText,
  Icon,
  ItemTitle,
  ItemSubtitle,
} from './styles';
import { zeroPad } from 'utilities/formatZeroPad';

export const StudyPlan: React.FC = props => {
  return (
    <div {...props}>
      <Title>Программа:</Title>
      <List>
        {data.map(({ count, icon, subtitle, title }, index) => (
          <Item key={index}>
            <Icon bgUrl={decor}>{icon}</Icon>
            <div>
              <ItemTitle>{title}</ItemTitle>
              <ItemSubtitle>{subtitle}</ItemSubtitle>
            </div>
            <ItemText>Модуль {zeroPad(count)}</ItemText>
          </Item>
        ))}
      </List>
    </div>
  );
};
