import { BgColors } from 'components/Course/Content/types';

export enum TCourseStatuses {
  NOT_PURCHASED = 'Не оплачен',
  PURCHASED = 'Оплачен',
  IN_PROGRESS = 'В процессе прохождения',
  COMPLETED = 'Завершен',
  CANCELLED = 'Отменен',
}

type TMaterials = {
  audios: number;
  documents: number;
  videos: number;
};

type TCourseFee = {
  chat_link: string;
  id: number;
  sessions: string;
  type: string;
  value: number;
  description: string;
  date: Date;
  bonus: number;
};

export type TRenewal = {
  id: number;
  course_id: number;
  prolongation_fee: number;
  prolongation_description: string;
  transition_fee: number;
  transition_description: string;
};

type TRoles = {
  id: number;
  name: string;
};

type TAdditional = {
  activity: boolean;
  id: number;
  name: string;
  price: number;
  materials: TMaterials;
};

type TProgress = {
  complete: number;
  percent: number;
  total: number;
};

export type TActive = {
  course_id: number;
  id: number;
  status: TCourseStatuses;
  tariff: string;
  user_id: number;
};

export type TReply = {
  date_send: Date;
  id: number;
  mark: number;
  message: string;
  user_avatar: string | null;
  user_name: string;
};

export type TTestimonials = {
  rating: number;
  voices: number;
  replies: TReply[];
};

export type TLecture = {
  activity: boolean;
  date_start?: Date;
  id: number;
  is_bonus?: boolean;
  name: string;
  order?: number;
  price?: number;
  materials: TMaterials;
  picture?: string | null;
  available?: boolean;
};

export type TCourse = {
  id: number;
  activity: boolean;
  chat_link: string;
  course_fee?: TCourseFee[];
  date_end: Date;
  date_start: Date;
  description: string;
  is_marathon: boolean;
  lectures: TLecture[];
  name: string;
  picture: string;
  renewal?: TRenewal;
  roles: TRoles[];
  seller: string;
  additionals?: TAdditional[];
  active?: TActive;
  progress?: TProgress;
  trailer?: number;
  bg_color: BgColors;
};
