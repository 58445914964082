import { Path } from 'constants/paths';
import React from 'react';
import { Link } from 'react-router-dom';
import { Wrap, Text, StyledButton } from './styles';

export const MiniBanner: React.FC = props => {
  return (
    <Wrap {...props}>
      <Text>Живые мероприятия в&nbsp;самых красивых уголках планеты</Text>
      <Link to={Path.vipClubMeetings}>
        <StyledButton size='large'>Смотреть расписание</StyledButton>
      </Link>
    </Wrap>
  );
};
