import styled from 'styled-components';
import { Button } from 'ui/Button';
import BalanceBg from 'images/bonus_balance_bg_stars.svg';

export const Wrapper = styled.div`
  width: 500px;
  padding: 0 0 40px 0;
`;

export const Title = styled.span<{ isSubTitle?: boolean }>`
  ${({ theme, isSubTitle }) =>
    isSubTitle ? theme.typography.headline3 : theme.typography.headline2};
  margin-right: 24px;
`;

export const HeadInfo = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const TextSC = styled.div`
  margin-top: 16px;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${props => props.theme.colors.secondTextColor};
  width: 500px;
`;

export const ReferalLinkInput = styled.div`
  width: 500px;
  height: 48px;
  margin-bottom: 40px;
  background: ${props => props.theme.colors.secondBgColor};
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  svg {
    cursor: pointer;
    margin-right: 13px;
  }
  span {
    margin-left: 14px;
  }
`;

export const InputSC = styled.input`
  display: none;
`;

export const PeriodField = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  & > label > svg {
    stroke-width: 20px;
  }
  &:before {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
    width: 100%;
    position: absolute;
    top: 34px;
  }
  margin-bottom: 24px;
`;

export const SelectPeriodButton = styled(Button)`
  display: flex;
  position: relative;
  top: 6px;
  span {
    ${({ theme }) => theme.typography.bodyText3};
  }
  & > span:nth-of-type(2) {
    margin-left: 0 !important ;
  }
  svg {
    stroke-width: 0;
  }
`;

export const RightBannerColumn = styled.div`
  margin-left: 57px;
  & > img {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Help = styled.div`
  margin-top: 11px;
  display: flex;
  svg {
    width: 24px;
    height: 24px;
  }
  a {
    margin-left: 8.83px;
    margin-top: 3px;
    color: ${({ theme }) => theme.colors.linksColor};
  }
`;

export const Balance = styled.div`
  padding-left: 16px;
  padding-top: 16px;
  overflow: hidden;
  width: 183px;
  border-radius: 14px;
  background: ${({ theme }) => theme.colors.bgStatusCheckErrorColor};
  background-image: url(${BalanceBg});
  background-repeat: no-repeat;
  background-position: 118%;
`;

export const BalanceTytle = styled.div`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const BalanceValue = styled.div`
  margin: 8px 13px 12px 0;
  word-break: break-word;
  max-width: 120px;
  ${({ theme }) => theme.typography.menuText};
`;

export const StyledButton = styled(Button)`
  margin: 24px 0 0;
`;

export const ClearDateRange = styled(Button)``;
