import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 0 12px 40px;
`;

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline2}
`;

export const Paragraph = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 24px 0 0;
`;

export const List = styled.ul`
  padding: 0 0 0 18px;
`;

export const ListItem = styled.li`
  & + & {
    margin-top: 10px;
  }
`;

export const Banner = styled.div`
  margin: 40px 0 0;
  padding: 30px 50px;
  background-color: ${({ theme }) => theme.colors.actionColor};
  border-radius: 14px;
  display: flex;
  align-items: center;
`;

export const BannerInner = styled.div`
  margin: 0 0 0 24px;
`;

export const BannerText = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 0;
`;

export const BannerTitle = styled.p`
  ${({ theme }) => theme.typography.headline1};
  margin: 0;
`;
