export const data = [
  {
    id: 1,
    title: 'Как и в каком формате проводится знакомство с коучингом?',
    text:
      'Встречи и беседы проводятся онлайн, группами. Знакомиться с материалами и отрабатывать практические задания можно из любой точки мира.',
  },
  {
    id: 2,
    title: 'Встречи будет вести только Владимир Баранов?',
    text:
      'Владимир Баранов поделится своими авторскими методиками и наработками. По отдельным темам могут быть проведены уникальные встречи с профильными специалистами.',
  },
  {
    id: 3,
    title: 'Когда старт ближайшего потока проекта?',
    text: 'Начало запланировано на сентябрь каждого года.',
  },
  {
    id: 4,
    title:
      'Сколько времени длится весь процесс? Сколько из него занимает теория, а сколько практика?',
    text:
      'Длительность проведения – 9 месяцев. 70% этого времени отводится для практики.',
  },
  {
    id: 5,
    title: 'Сколько выпускается специалистов на каждом потоке?',
    text: 'Примерно 70-80 человек в год из разных регионов и даже стран.',
  },
  {
    id: 6,
    title: 'Какой документ получат коучи в итоге?',
    text:
      'По окончании 9 месяцев проводится контроль качества, по результатам которого специалисты получают или не получают соответствующий сертификат.',
  },
  {
    id: 7,
    title: 'Когда можно будет начинать работу по консультированию клиентов?',
    text:
      'Все зависит от вашего желания и готовности работать. Ориентировочно – с 4-ого месяца участия в проекте.',
  },
  {
    id: 8,
    title: 'Смогут ли начинающие коучи сразу зарабатывать?',
    text:
      'Первые 40 часов сессий рекомендуется проводить за символическую стоимость, даже за чашку кофе. По истечение этого периода стартовая цена консультации вырастает.',
  },
  {
    id: 9,
    title: 'Какова стоимость?',
    text:
      'Стоимость фиксированная: 250 000 руб. Возможна оплата в рассрочку. Схема рассрочки: 50% сейчас, еще 50% в любое время до 15 августа 2021 года старта потока.',
  },
  {
    id: 10,
    title: 'Как часто придется выезжать на живые встречи?',
    text:
      'Будет три выезда в Москву для реальных встреч. Участие в них желательно, но все же обязательным не является. То есть если вы не поедете, все равно сможете получить свой сертификат.',
  },
];
