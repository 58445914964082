import React, { useEffect } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Path } from 'constants/paths';
import { YearPage } from './YearPage';
import { MonthPage } from './MonthPage';
import { DayPage } from './DayPage';
import {
  Wrap,
  YearHeader,
  NextYear,
  CurrentYear,
  PrevYear,
  StyledToolbar,
  Overlay,
  StyledOverlayPreloader,
} from './styles';
import { UseGetCalendarQuery } from 'queries/calendar/useGetCalendarQuery';
import { useCalendarCategories } from 'models/calendar/model';
import { MonthNumber } from 'constants/monthKey';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export const monthNumber: Record<string, string> = {
  january: '01',
  february: '02',
  march: '03',
  april: '04',
  may: '05',
  june: '06',
  july: '07',
  august: '08',
  september: '09',
  october: '10',
  november: '11',
  december: '12',
};

type MonthNumberType = keyof typeof MonthNumber;

export const Calendar: React.FC<
  RouteComponentProps<{ year: string; month?: string; day?: string }>
> = ({ match, history, ...rest }) => {
  const { year, month, day } = match.params || {};
  const { pathname } = history.location;
  const changeToPrevYear = () => {
    const prevYear = Number(year) - 1;
    history.push(pathname.replace(/\d{4}/, prevYear.toString()));
  };
  const changeToNextYear = () => {
    const nextYear = Number(year) + 1;
    history.push(pathname.replace(/\d{4}/, nextYear.toString()));
  };
  const { mutate: getCalendar, isLoading } = UseGetCalendarQuery();
  const categories = useCalendarCategories();

  useEffect(() => {
    const values = {
      courses: false,
      trainings: false,
      events: false,
      vip: false,
      coach_school: false,
      year,
      month: month ? MonthNumber[month as MonthNumberType] : '',
      day: '',
    };
    categories.reduce((acc, curr) => {
      if (curr.key !== 'all') {
        acc[curr.key] = curr.checked;
      }
      return acc;
    }, values);
    getCalendar(values);
  }, [categories, year, month, day, getCalendar]);

  useScrollToTopOnMount();
  return (
    <Wrap {...rest}>
      {isLoading ? (
        <Overlay>
          <StyledOverlayPreloader />
        </Overlay>
      ) : null}
      <YearHeader>
        <PrevYear direction={'left'} onClick={changeToPrevYear} />
        <CurrentYear to={`${Path.calendar}/${year}/`}>{year}</CurrentYear>
        <NextYear direction={'right'} onClick={changeToNextYear} />
      </YearHeader>
      <StyledToolbar />
      <Switch>
        <Route exact path={`${Path.calendar}/:year/`} component={YearPage} />
        <Route
          exact
          path={`${Path.calendar}/:year${Path.month}/:month`}
          component={MonthPage}
        />
        <Route
          exact
          path={`${Path.calendar}/:year${Path.month}/:month/:day`}
          component={DayPage}
        />
      </Switch>
    </Wrap>
  );
};
