import styled from 'styled-components';
import { InputTextarea } from 'ui/InputTextarea';
import { InputText } from 'ui/InputText';

export const ItemTextarea = styled(InputTextarea)`
  margin: 24px 0 0;
`;

export const Item = styled(InputText)`
  margin: 24px 0 0;
`;

export const SubTitle = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.secondTextColor};
`;

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline2};
  & + ${SubTitle} {
    margin-top: 24px;
  }
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  height: 1px;
  width: 100%;
  margin: 40px 0 24px;
`;
