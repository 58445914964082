import styled from 'styled-components';
import { Button } from 'ui/Button';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Wrapper = styled.div`
  width: 500px;

  border: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-radius: 14px;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 12px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;
export const Title = styled.span`
  ${({ theme }) => theme.typography.headline3};
`;

export const OrderDate = styled.span`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin-left: 12px;
`;

export const OrderState = styled.div<{ approved?: boolean }>`
  padding: 0 11px;
  height: 26px;
  border-radius: 6px;
  font-weight: bold;
  color: ${({ theme, approved }) =>
    approved ? theme.colors.mainColor : theme.colors.mainBgColor};
  background: ${({ theme, approved }) =>
    approved ? theme.colors.actionColor : theme.colors.errorColor};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 4px;
    fill: white;
  }
`;

export const OrderTotalSumm = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.mainColor};
  font-weight: bold;
`;

export const MoreInfoButton = styled(Button)`
  margin-left: 114px;
`;

export const TrackNumber = styled.div`
  margin-top: 8px;
`;

export const ListOfProducts = styled.div`
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    transform: rotate(90deg);
    transform-origin: left top;
    border-bottom: 50px solid #ffffff;
    width: 200px;
    position: absolute;
    top: -15px;
    z-index: 99;
    left: 530px;
    box-shadow: 0 35px 20px #ffffff;
  }
`;

export const Preview = styled.div`
  background: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 14px;
  margin-right: 11px;
  min-width: 120px;
  height: 120px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
  }
`;

export const NoPhotoText = styled.p`
  ${({ theme }) => theme.typography.headline2};
  font-weight: 800;
  padding: 16px;
`;

export const ScrollContainerSC = styled(ScrollContainer)`
  margin-top: 24px;
  display: flex;
  position: relative;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const OrderStateWrapper = styled.div`
  display: flex;
`;
