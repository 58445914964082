import { useQuery, UseQueryResult } from 'react-query';
import { getGiftCardsFx, setGiftCards } from 'models/catalog/model';
import { AxiosError, AxiosResponse } from 'axios';
import { GiftCard } from 'models/catalog/types';

export const useGetGiftCardsQuery: () => UseQueryResult<
  AxiosResponse<{ data: GiftCard[] }>,
  AxiosError<{ error: string }>
> = () =>
  useQuery('giftCards', getGiftCardsFx, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: res => {
      setGiftCards(res.data.data);
    },
  });
