import React from 'react';
import styled from 'styled-components';
import { AttentionSmallIcon, CheckSmallIcon } from 'icons';
import { TagProps } from './types';

const TagIcon = styled.span`
  font-size: 0;
`;

const TagText = styled.span`
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
`;

const TagWrap = styled.div<TagProps>`
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'disable':
      case 'wait':
        return theme.colors.secondColor;
      case 'success':
        return theme.colors.statusDoneColor;
      case 'progress':
        return theme.colors.actionColor;
      case 'error':
        return theme.colors.thirdColor;
    }
  }};
  padding: ${({ size }) => (size === 'medium' ? '4px 10px' : ' 6px 12px')};
  ${({ ghost, theme, status, size }) => {
    if (ghost) {
      switch (status) {
        case 'wait':
        case 'disable':
          return `border: 2px solid ${theme.colors.secondColor};  
                  background-color: transparent;
                  padding: ${size === 'medium' ? '2px 8px' : '4px 10px'};`;
        case 'success':
          return `border: 2px solid ${theme.colors.statusDoneColor};  
                  background-color: transparent;
                  padding: ${size === 'medium' ? '2px 8px' : '4px 10px'};`;
        case 'progress':
          return `border: 2px solid ${theme.colors.actionColor};  
                  background-color: transparent;
                  padding: ${size === 'medium' ? '2px 8px' : '4px 10px'};`;
        case 'error':
          return `border: 2px solid ${theme.colors.thirdColor};  
                  background-color: transparent;
                  padding: ${size === 'medium' ? '2px 8px' : '4px 10px'};`;
      }
    }
  }}

  & > ${TagText} {
    color: ${({ status, theme, ghost }) => {
      switch (status) {
        case 'disable':
          return theme.colors.secondTextColor;
        case 'wait':
          return theme.colors.mainColor;
        case 'progress':
          return theme.colors.mainColor;
        case 'error':
        case 'success':
          return ghost ? theme.colors.mainColor : theme.colors.mainBgColor;
      }
    }};
    font-size: ${({ size }) => (size === 'medium' ? '16px' : ' 14px')};
    line-height: ${({ size }) => (size === 'medium' ? '18px;' : '14px;')};
  }
  & > ${TagIcon} {
    fill: ${({ status, theme, ghost }) => {
      switch (status) {
        case 'disable':
          return theme.colors.secondTextColor;
        case 'wait':
        case 'progress':
          return theme.colors.mainColor;
        case 'error':
        case 'success':
          return ghost ? theme.colors.mainColor : theme.colors.mainBgColor;
      }
    }};
  }
  & ${TagIcon} + ${TagText} {
    margin-left: 4px;
  }
`;

const Tag: React.FC<TagProps> = ({
  status,
  showIcon,
  icon,
  text,
  ...props
}) => {
  return (
    <TagWrap status={status} text={text} {...props}>
      <>
        {showIcon &&
          (status === 'progress' ? (
            icon ? (
              <TagIcon>{icon}</TagIcon>
            ) : null
          ) : status === 'error' ? (
            <TagIcon>{AttentionSmallIcon}</TagIcon>
          ) : status === 'success' ? (
            <TagIcon>{CheckSmallIcon}</TagIcon>
          ) : null)}
        <TagText>{text}</TagText>
      </>
    </TagWrap>
  );
};

export default Tag;
