import styled from 'styled-components';

export const Item = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  padding: 20px 0;
  align-items: center;
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  font-weight: bold;
  margin: 0;
`;

export const Button = styled.div<{ isOpen: boolean }>`
  margin-left: auto;
  font-size: 0;
  & > svg {
    ${({ isOpen }) =>
      isOpen ? 'transform: rotate(-90deg)' : 'transform: rotate(90deg)'};
    transition: transform 0.3s;
  }
`;

export const Text = styled.p`
  margin-top: 16px;
`;
