import { CalendarEvent, CalendarEventType } from 'models/calendar/types';
import { isSameDay, isSameHour } from 'date-fns';

export const formatDayEventInDaySchedule: (
  event: CalendarEvent,
  hourStart: Date,
  beginningOfDay: Date,
  endingOfDay: Date
) => CalendarEventType = (event, hourStart, beginningOfDay, endingOfDay) => {
  const start = new Date(event.date_start);
  const end = new Date(event.date_end);
  return {
    type: event.type,
    details: event,

    //Проверка является ли событие началом линии на календаре
    start:
      // Или является началом дня время начала события
      isSameHour(hourStart, beginningOfDay) ||
      // Является ли нынешний час часом начала события
      isSameHour(hourStart, start),
    //Проверка является ли день календаря днём завершения курса
    end:
      // Или является концом дня время конца события
      !isSameDay(start, end)
        ? isSameHour(hourStart, endingOfDay) ||
          // Является ли нынешний час часом конца события
          isSameHour(hourStart, end)
        : isSameHour(endingOfDay, hourStart),

    purchased: event.purchased,
    title:
      isSameHour(hourStart, beginningOfDay) || isSameHour(hourStart, start)
        ? event.name
        : '',
  };
};
