import React from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { SidebarLessonItemProps } from './types';
import { Wrap, Inner, Photo, Title, StyledDate } from './styles';
import { baseUrl } from 'api/apiClient';

export const SidebarLessonItem: React.FC<SidebarLessonItemProps> = ({
  id,
  date_start,
  name,
  courseId,
  active,
  picture,
  isSchool,
}) => {
  const link = isSchool
    ? `/coaching/${courseId}/lesson/${id}`
    : `/course/${courseId}/lesson/${id}`;

  return (
    <Wrap $active={active} to={link}>
      {picture && <Photo src={baseUrl + picture} alt={name} />}
      <Inner>
        <StyledDate>
          {date_start &&
            format(new Date(date_start), 'd MMMM Y p', { locale: ru })}
        </StyledDate>
        <Title>{name}</Title>
      </Inner>
    </Wrap>
  );
};
