import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';
import { Link } from 'react-router-dom';

export const RegistrationButton = styled(Button)`
  margin: 8px 0 0;
  width: 340px;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 24px 0 0;
`;

export const StyledButton = styled(Button)`
  margin: 24px 0 0;
  width: 340px;
`;

export const PersonalDataConfirm = styled.div`
  width: 340px;
  margin: 24px auto 0;
  display: flex;
  align-items: flex-start;
`;

export const PersonalDataLink = styled(Link)``;

export const Text = styled.p`
  margin: 0 0 0 12px;
  text-align: left;
  ${({ theme }) => theme.typography.bodyText2}
`;

export const SendCodeButton = styled(Button)`
  margin: 24px 0 0;
`;

export const RegistrationMessage = styled.p`
  margin: 60px 0 0;
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.secondTextColor};
`;
