import React from 'react';
import { PopupSuccessIcon } from 'icons/';
import { Icon, Text } from './styles';

export const SuccessMessage: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <>
      <Icon>{PopupSuccessIcon}</Icon>
      <Text>{text || 'Текст сообщения!'}</Text>
    </>
  );
};
