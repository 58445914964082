import { useQuery } from 'react-query';
import { getCoursesFx, setCourses } from 'models/catalog/model';
import { CoursesQuery } from './types';

export const useCoursesQuery: CoursesQuery = () =>
  useQuery('courses', () => getCoursesFx(), {
    onSuccess: res => {
      setCourses(res.data.data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
