import styled from "styled-components";

export const Wrap = styled.div`
  margin-top: 60px;
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline2}
`;

export const ItemsWrap = styled.div`
  margin-top: 24px;
  display: flex;
  margin: 24px -24px -30px;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  display: flex;
  flex-basis: calc((100% - 167px) / 3);
  margin: 0 24px 30px;
`;

export const Icon = styled.div`
  line-height: 0;
  padding: 14px;
  border-radius: 50%;
  align-self: flex-start;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
`;

export const Text = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 20px;
`;
