import { TCoachingSchool } from 'models/inner-insight/types';
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Inner, Wrap, Text, Title, BannerButton } from './styles';

export const Banner: React.FC<{ school: TCoachingSchool }> = ({ school }) => {
  return (
    <Wrap src={school.picture}>
      <Inner>
        <Title>Inner&nbsp;Insight</Title>
        <Text>
          «Внутреннее вдохновение» – лучшая база информации о трансперсональном
          коучинге.
        </Text>
        <ScrollLink to='payment' spy smooth offset={-100}>
          <BannerButton size='large'>Стать учеником</BannerButton>
        </ScrollLink>
      </Inner>
    </Wrap>
  );
};
