import styled from 'styled-components';
import { CalendarEventTypes } from 'models/calendar/types';
import { calendarDayEventsColors } from 'constants/calendarEventsColors';

export const Wrap = styled.div`
  display: grid;
  grid-template-rows: repeat(24, 1fr);
  grid-template-columns: 130px auto;
  grid-auto-flow: column;
`;

export const ScheduleRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  flex-grow: 1;
  min-height: 42px;
  display: flex;
  &:first-of-type {
    border-radius: 14px 14px 0 0;
  }
`;

export const TimeCell = styled.div`
  display: flex;
  align-items: center;
`;

export const Time = styled.p`
  margin: 0 76px 0 0;
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.secondColor};
  padding: 10px;
`;

export const EventLine = styled.div<{
  type: CalendarEventTypes;
  start: boolean;
  end: boolean;
  purchased: boolean;
  columnCount: number;
}>`
  flex: 0 1 calc(100% / ${({ columnCount }) => columnCount});
  border-left: 1px solid;
  border-right: 1px solid;
  cursor: pointer;
  ${({ start }) =>
    start
      ? `border-top-left-radius: 4px; border-top-right-radius: 4px; border-top: 1px solid;`
      : null}
  ${({ end }) =>
    end
      ? `border-bottom-left-radius:4px; border-bottom-right-radius: 4px; border-bottom: 1px solid;`
      : null}
  border-color: ${({ type }) => calendarDayEventsColors.border[type]};
  ${({ purchased }) => (!purchased ? `opacity: 0.5;` : null)}
  padding: 2px 7px;
  background-color: ${({ type }) =>
    type ? calendarDayEventsColors.fill[type] : null};
`;
