import { Button } from 'ui/Button';
import styled from 'styled-components';

export const Wrap = styled.div`
  margin: 24px 0 0;
`;

export const ButtonSC = styled(Button)<{ selected: boolean }>`
  margin-right: 40px;
  border: none;
  ${({ theme }) => theme.additional.noOutline};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.mainColor : theme.colors.thirdTextAndIconColor};
  && {
    box-shadow: none;
  }
`;
