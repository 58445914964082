import React from 'react';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { InputPhoneProps } from './types';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/ru.json';

const InputLabel = styled.label<
  Pick<InputPhoneProps, 'alignLabel' | 'labelWidth'>
>`
  ${props => props.theme.typography.bodyText2};
  letter-spacing: 0;
  text-align: ${props => props.alignLabel};
  margin-bottom: 8px;
  flex-shrink: 0;
  color: ${props => props.theme.colors.secondTextColor};
  align-self: flex-start;
  ${({ labelWidth }) => (labelWidth ? `width: ${labelWidth}px` : null)};
`;

const Error = styled.p<Pick<InputPhoneProps, 'inputWidth'>>`
  margin: 8px 0 0;
  max-width: 100%;
  text-align: left;
  color: ${props => props.theme.colors.errorColor};
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth}px` : null)};
`;

const InputInner = styled.div<
  Pick<InputPhoneProps, 'inputWidth'> & {
    isFocused: boolean;
    disabled?: boolean;
  }
>`
  padding: 5px 14px;
  border-radius: 14px;
  border: 1px solid ${props => props.theme.colors.secondBgColor};
  background-color: ${props => props.theme.colors.secondBgColor};
  transition: all 0.3s;
  display: flex;
  align-items: center;
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth}px` : null)};
`;

const ClassNameToPhoneInputClassName: React.FC<{ className?: string }> = ({
  className,
  ...rest
}) => <PhoneInput containerClass={className} {...rest} />;

const Input = styled(ClassNameToPhoneInputClassName)<InputPhoneProps>`
  outline: none;
  z-index: 3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 100%;
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};
  ${({ isFocused, theme }) =>
    isFocused &&
    `transition: all 0.3s;border-color: ${theme.colors.secondColor};`}
  ${({ disabled, theme }) =>
    !disabled &&
    `&:hover {
          transition: all 0.3s;
          border-color: ${theme.colors.secondColor};
        }`}
    
    & .form-control {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    &:-webkit-autofill {
      background-color: transparent !important;
      -webkit-box-shadow: 0 0 0 50px
        ${({ theme }) => theme.colors.secondBgColor} inset;
    }
  }
  & .form-control::placeholder {
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.disabledColor
        : theme.colors.thirdTextAndIconColor};
  }

  & .form-control,
  & .flag-dropdown {
    border: none;
    background: transparent;
  }

  & .flag-dropdown.open {
    background: transparent;
    z-index: 7;
  }

  & .flag-dropdown.open .selected-flag,
  .selected-flag:hover,
  & .selected-flag:hover,
  & .selected-flag:focus {
    background: transparent;
  }
`;

const InputWrap = styled.div<
  Pick<InputPhoneProps, 'direction' | 'fullWidth'> & {
    disabled?: boolean;
    error?: string;
  }
>`
  display: inline-flex;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'row'};
  ${({ direction }) =>
    direction !== 'vertical' ? 'align-items: center;' : null}
  ${({ direction }) =>
    direction !== 'vertical' ? `& > ${InputLabel} {margin: 0 16px 0 0;}` : null}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};

  ${props =>
    props.disabled &&
    `& > ${InputInner} {
        background-color: ${props.theme.colors.thirdBgColor};
        border-color: ${props.theme.colors.thirdBgColor};
        cursor: not-allowed;
      };
      
      & > ${Input} {
        cursor: not-allowed;
      }      
      & > ${InputLabel} {
        color: ${props.theme.colors.disabledColor};
      };`};

  & > ${Error} {
    transition: transform 0.3s, opacity 0.3s;
    ${({ error }) => {
      return !error
        ? 'transform: translate(0, -50%); opacity: 0;'
        : 'transform: translate(0, 0); opacity: 1;';
    }}
  }

  & > ${InputInner} {
    ${({ error, theme }) => error && `border-color: ${theme.colors.errorColor}`}
  }
`;

//Доделать инпут с маской
const InputPhone: React.FC<InputPhoneProps> = ({
  alignLabel = 'left',
  label,
  disabled,
  direction,
  labelWidth,
  inputWidth,
  className,
  fullWidth,
  input,
  meta,
  ...rest
}) => {
  return (
    <InputWrap
      error={meta.error}
      disabled={disabled}
      direction={direction}
      className={className}
      fullWidth={fullWidth}
    >
      {label ? (
        <InputLabel alignLabel={alignLabel} labelWidth={labelWidth}>
          {label}
        </InputLabel>
      ) : null}
      <div>
        <InputInner isFocused={meta.active} inputWidth={inputWidth}>
          <Input
            type='text'
            disabled={disabled}
            {...input}
            {...rest}
            localization={ru}
          />
        </InputInner>
        {meta.error && meta.touched ? (
          <Error inputWidth={inputWidth}>{meta.error}</Error>
        ) : null}
        {meta.submitError && meta.touched ? (
          <Error inputWidth={inputWidth}>{meta.submitError}</Error>
        ) : null}
      </div>
    </InputWrap>
  );
};

export default InputPhone;
