import React from 'react';
import Ruble from 'components/common/Ruble';
import {
  Item,
  ItemTitle,
  ItemDigit,
  Total,
  TotalTitle,
  TotalDigit,
  Title,
  Divider,
  PaymentInfo,
  StyledPreloader,
} from './styles';
import { Calculation } from 'models/order/types';

export const TotalPayment: React.FC<{
  isLoading: boolean;
  calculation: Calculation | null;
}> = ({ calculation, isLoading, ...rest }) => {
  const {
    bonus_account,
    inner_budget,
    order_price,
    delivery_price,
    charge,
    final_price,
    discount_value,
  } = calculation || {};

  return (
    <div {...rest}>
      <Title>Сумма заказа</Title>
      <PaymentInfo>
        {isLoading ? (
          <StyledPreloader />
        ) : (
          <>
            <Item>
              <ItemTitle>Товар</ItemTitle>
              <ItemDigit>
                {order_price} {Ruble}
              </ItemDigit>
            </Item>
            {bonus_account ? (
              <Item>
                <ItemTitle>Оплата бонусами</ItemTitle>
                <ItemDigit>
                  -{bonus_account} {Ruble}
                </ItemDigit>
              </Item>
            ) : null}
            {inner_budget ? (
              <Item>
                <ItemTitle>Оплата премией</ItemTitle>
                <ItemDigit>
                  -{inner_budget} {Ruble}
                </ItemDigit>
              </Item>
            ) : null}
            {discount_value ? (
              <Item>
                <ItemTitle>Скидки от промокода</ItemTitle>
                <ItemDigit>
                  -{discount_value} {Ruble}
                </ItemDigit>
              </Item>
            ) : null}
            {delivery_price ? (
              <Item>
                <ItemTitle>Стоимость доставки</ItemTitle>
                <ItemDigit>
                  {delivery_price} {Ruble}
                </ItemDigit>
              </Item>
            ) : null}
            {charge ? (
              <Item>
                <ItemTitle>Будет начислено бонусов</ItemTitle>
                <ItemDigit>
                  {charge} {Ruble}
                </ItemDigit>
              </Item>
            ) : null}
            <Divider />
            <Total>
              <TotalTitle>Итого</TotalTitle>
              <TotalDigit>
                {final_price || 0} {Ruble}
              </TotalDigit>
            </Total>
          </>
        )}
      </PaymentInfo>
    </div>
  );
};
