import { Modal } from 'ui/Modal';
import React from 'react';
import { InputTextSC, Wrapp, ButtonSC } from './styled';
import { Field, Form } from 'react-final-form';
import { DeliveryAddressFields } from 'components/Buy/Content/Delivery/common/DeliveryAddressFields';
import { toast } from 'react-toastify';
import { SuccessMessage } from 'ui/SuccessMessage';
import { ErrorMessage } from 'ui/ErrorMessage';
import { formatValidationErrors } from 'utilities/formatValidationErrors';
import { AxiosError } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';
import { addAddressFx } from 'models/addresses/model';
import { FormValues } from 'models/addresses/types';

interface IProps {
  closeModal: () => void;
  onSuccess: () => void;
}

export const AddAddressModal: React.FC<IProps> = ({
  closeModal,
  onSuccess,
}) => {
  const submit = (values: FormValues) => {
    return addAddressFx(values)
      .then(() => {
        onSuccess();
        closeModal();
        toast.success(<SuccessMessage text='Адрес добавлен' />);
      })
      .catch((err: AxiosError<{ error: ServerValidationError }>) => {
        toast.error(
          <ErrorMessage text={'Не удалось добавить адрес. Произошла ошибка!'} />
        );
        return formatValidationErrors(err.response?.data.error);
      });
  };

  return (
    <Modal handleClose={closeModal}>
      <Wrapp>
        <Form
          onSubmit={submit}
          id='DeliveryForm'
          render={props => {
            return (
              <form onSubmit={props.handleSubmit}>
                <DeliveryAddressFields
                  hasCusmotStyle
                  titleName='Добавить адрес'
                />
                <Field
                  labelWidth={144}
                  inputWidth={340}
                  label='Название'
                  alignLabel='left'
                  name='name'
                  placeholder={'Введите название'}
                  component={InputTextSC}
                />
                <ButtonSC
                  disabled={
                    !props.values.country ||
                    !props.values.city ||
                    !props.values.address ||
                    !props.values.house ||
                    !props.values.name
                  }
                  type='submit'
                >
                  Добавить адрес
                </ButtonSC>
              </form>
            );
          }}
        />
      </Wrapp>
    </Modal>
  );
};
