import styled from 'styled-components';

export const Top = styled.div`
  display: flex;
  margin: 20px 0 40px;
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.colors.thirdBgColor};
  padding: 0 0 1px 0;
`;

export const BadgeText = styled.p`
  margin: 0;
`;

export const Badge = styled.div<{ selected: boolean }>`
  & + & {
    margin: 0 0 0 40px;
  }
  & ${BadgeText} {
    ${({ theme }) => theme.typography.menuText};
    color: ${({ theme, selected }) =>
      selected ? theme.colors.mainColor : theme.colors.thirdTextAndIconColor};
  }
  cursor: pointer;
  ${({ selected, theme }) =>
    selected ? `box-shadow: 0 2px 0 0 ${theme.colors.actionColor}` : null};
  padding: 20px 0 19px;
  transition: box-shadow 0.3s, color 0.3s;
`;

export const Bottom = styled.div`
  width: calc(((100% - (24px * 6)) / 9) * 7 + (9 * 12px));
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline3}
  margin: 0;
`;

export const Text = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.bodyText2}
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
`;

export const Delivery = styled.div`
  display: flex;
`;

export const DeliveryTitle = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.links}
`;

export const DeliveryText = styled.p`
  margin: 0 0 0 auto;
  width: 385px;
  ${({ theme }) => theme.typography.bodyText2}
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.linksColor};
  &:visited {
    color: ${({ theme }) => theme.colors.linksColor};
  }
`;

export const PaymentWrap = styled.div`
  display: flex;
  margin: 24px 0 0;
`;

export const Payment = styled.div`
  display: flex;
  & + & {
    margin: 0 0 0 60px;
  }
`;
export const PaymentIcon = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${({ theme }) => theme.colors.disabledColor};
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 8px;
`;

export const PaymentText = styled.p`
  margin: 0 0 0 16px;
  width: 237px;
`;
