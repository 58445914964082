import React from 'react';
import { Wrap, Title, ItemsWrap, Item, Icon, Text } from './styles';
import {
  PositiveIcon,
  PracticeIcon,
  PuzzleIcon,
  SuperheroIcon,
  AimIcon,
  SupportIcon,
  ToolbookIcon,
  HandPuzzleIcon,
  DialogIcon,
} from 'icons/';

export const Features: React.FC = props => {
  return (
    <Wrap {...props}>
      <Title>Возможности VIP-клуба</Title>
      <ItemsWrap>
        <Item>
          <Icon>{DialogIcon}</Icon>
          <Text>Чат с Владимиром Барановым и Алиной Ермаковой</Text>
        </Item>
        <Item>
          <Icon>{SupportIcon}</Icon>
          <Text>Мощная&nbsp;эмоциональная поддержка членов клуба</Text>
        </Item>
        <Item>
          <Icon>{PuzzleIcon}</Icon>
          <Text>Детальный разбор и анализ лично вашей ситуации</Text>
        </Item>
        <Item>
          <Icon>{HandPuzzleIcon}</Icon>
          <Text>Проработка решений лично вашей проблемы</Text>
        </Item>
        <Item>
          <Icon>{AimIcon}</Icon>
          <Text>Работа по личностному и карьерному росту</Text>
        </Item>
        <Item>
          <Icon>{PracticeIcon}</Icon>
          <Text>Море дополнительных знаний и практик</Text>
        </Item>
        <Item>
          <Icon>{ToolbookIcon}</Icon>
          <Text>Уникальные инструменты коучинга от профессионалов</Text>
        </Item>
        <Item>
          <Icon>{SuperheroIcon}</Icon>
          <Text>
            Помощь и поддержка при реализации плана до гарантированного
            результата
          </Text>
        </Item>
        <Item>
          <Icon>{PositiveIcon}</Icon>
          <Text>Море позитива</Text>
        </Item>
      </ItemsWrap>
    </Wrap>
  );
};
