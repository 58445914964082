import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Path } from 'constants/paths';

// Страницы
import { Catalog } from 'components/Catalog';
import { About } from 'components/About';
import { Education } from 'components/Education';
import { Course } from 'components/Course';
import { Lesson } from 'components/Lesson';
import { Buy } from 'components/Buy';
import { Calendar } from 'components/Calendar';
import { Sales } from 'components/Sales';
import { News } from 'components/News';
import { Account } from 'components/Account';
import { Notifications } from 'components/Notifications';
import { DirectBuy } from 'components/DirectBuy';
import { SberbankPaymentSuccess } from 'components/SberbankPaymentSuccess';
import { InstagramConnectSuccess } from '../InstagramConnectSuccess';
import { School } from 'components/School';

const StyledSwitch = styled(Switch)`
  flex: 1 0 100%;
`;

//TODO: Конфиг путей вынести в отдельный файл с массивом и промапить его.
export const Main: React.FC = () => {
  return (
    <StyledSwitch>
      <Route path={`${Path.account}/:key`} component={Account} />
      <Route path={Path.about + '/:key?'} component={About} />
      <Route path={`${Path.catalog}/:key?`} component={Catalog} />
      <Route path={`${Path.education}/:key?`} component={Education} />
      <Route path={`${Path.course}/:id`} component={Course} exact />
      <Route
        path={`${Path.course}/:courseId${Path.lesson}/:lessonId`}
        exact
        component={Lesson}
      />
      <Route
        path={`${Path.coaching}/:courseId${Path.lesson}/:lessonId`}
        exact
        component={Lesson}
      />
      <Route path={`${Path.directBuy}/:id`} component={DirectBuy} />
      <Route path={`${Path.buy}/:id?`} component={Buy} />
      <Route
        path={[
          `${Path.calendar}/:year${Path.month}/:month/:day?`,
          `${Path.calendar}/:year${Path.month}/:month?`,
          `${Path.calendar}/:year/`,
        ]}
        component={Calendar}
      />
      <Route path={`${Path.sales}/:key?`} component={Sales} />
      <Route path={`${Path.news}/:key?`} component={News} />
      <Route path={`${Path.notifications}`} component={Notifications} />
      <Route
        path={`${Path.sberbankSuccess}`}
        component={SberbankPaymentSuccess}
      />
      <Route
        path={`${Path.instagramSuccess}`}
        component={InstagramConnectSuccess}
      />
      <Route path={`${Path.school}/:id`} component={School} />
      <Redirect to={`${Path.catalog}/courses`} />
    </StyledSwitch>
  );
};
