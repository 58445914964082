import React from 'react';
import {
  StudyLargeIcon,
  BookLargeIcon,
  UserLargeIcon,
  ChatDialogLargeIcon,
  PuzzleHandLargeIcon,
  SmileHandLargeIcon,
  CoinsLargeIcon,
  SolarSystemLargeIcon,
  BlackholeLargeIcon,
  RocketShuttleLargeIcon,
} from 'icons/';

export const data: Array<{
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  count: number;
}> = [
  {
    icon: StudyLargeIcon,
    title: 'Знакомство с коучингом',
    subtitle: '2 вебинара по 3 часа',
    count: 1,
  },
  {
    icon: BookLargeIcon,
    title: 'Этический кодекс ICF',
    subtitle: 'Второй взнос до 15 августа 2021г.',
    count: 2,
  },
  {
    icon: UserLargeIcon,
    title: 'Умение слушать и слышать',
    subtitle: 'Второй взнос до 15 августа 2021г.',
    count: 3,
  },
  {
    icon: ChatDialogLargeIcon,
    title: 'Мощные вопросы и основы разговорного жанра',
    subtitle: 'Второй взнос до 15 августа 2021г.',
    count: 4,
  },
  {
    icon: PuzzleHandLargeIcon,
    title: 'Универсальные инструменты коуча',
    subtitle: 'Второй взнос до 15 августа 2021г.',
    count: 5,
  },
  {
    icon: SmileHandLargeIcon,
    title: 'Коучинг отношений и семейный коучинг',
    subtitle: 'Второй взнос до 15 августа 2021г.',
    count: 6,
  },
  {
    icon: CoinsLargeIcon,
    title: 'Трансперсональный коучинг. Часть 1',
    subtitle: 'Введение в психосинтез',
    count: 7,
  },
  {
    icon: SolarSystemLargeIcon,
    title: 'Трансперсональный коучинг. Часть 2',
    subtitle: 'Практика применения',
    count: 8,
  },
  {
    icon: BlackholeLargeIcon,
    title: 'Как продавать коучинг',
    subtitle: 'Тайные знания коммерческого успеха',
    count: 9,
  },
  {
    icon: RocketShuttleLargeIcon,
    title: 'Продвижение личного бренда коуча в Instagram',
    subtitle: 'Чек-лист по продвижению в конце обучения',
    count: 10,
  },
];
