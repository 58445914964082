import { useEffect, useRef } from 'react';

type SDEKWidgetOptions = {
  // СДЭК не предоставляет свои интерфейсов.
  // Накидал сам согласно документации
  showWarns?: boolean;
  showErrors?: boolean;
  showLogs?: boolean;
  hideMessages?: boolean;
  path?: string;
  templatepath?: string;
  choose?: boolean;
  popup?: boolean;
  country?: string;
  defaultCity?: string;
  cityFrom?: string;
  link?: string | null;
  hidedress?: boolean;
  hidecash?: boolean;
  hidedelt?: boolean;
  detailAddress?: boolean;
  region?: boolean;
  apikey?: string;
  goods?: Array<{
    length: number;
    width: number;
    height: number;
    weight: number;
  }>;
  onReady?: (arg: unknown) => void;
  onChoose?: (arg: unknown) => void;
  onChooseProfile?: (arg: unknown) => void;
  onChooseAddress?: (arg: unknown) => void;
  onCalculate?: (arg: unknown) => void;
};

export const useSDEKWidget: (
  options: SDEKWidgetOptions
) => unknown = options => {
  const widget = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.cdek.ru/widget/widjet.js';
    script.async = true;
    script.onload = () => {
      // @ts-expect-error СДЭК не предоставляет интерфейсов
      widget.current = new window.ISDEKWidjet(options);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [options]);
  return widget.current;
};
