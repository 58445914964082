import React from 'react';

export const HeadphonesIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.99984 3.33341C8.23173 3.33341 6.53603 4.03579 5.28579 5.28604C4.03555 6.53628 3.33317 8.23197 3.33317 10.0001V15.0001C3.33317 15.4603 2.96007 15.8334 2.49984 15.8334C2.0396 15.8334 1.6665 15.4603 1.6665 15.0001V10.0001C1.6665 7.78994 2.54448 5.67033 4.10728 4.10752C5.67008 2.54472 7.7897 1.66675 9.99984 1.66675C12.21 1.66675 14.3296 2.54472 15.8924 4.10752C17.4552 5.67033 18.3332 7.78994 18.3332 10.0001V15.0001C18.3332 15.4603 17.9601 15.8334 17.4998 15.8334C17.0396 15.8334 16.6665 15.4603 16.6665 15.0001V10.0001C16.6665 8.23197 15.9641 6.53628 14.7139 5.28604C13.4636 4.03579 11.7679 3.33341 9.99984 3.33341Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.6665 11.6666C1.6665 11.2063 2.0396 10.8333 2.49984 10.8333H4.99984C5.66288 10.8333 6.29876 11.0966 6.7676 11.5655C7.23645 12.0343 7.49984 12.6702 7.49984 13.3333V15.8333C7.49984 16.4963 7.23645 17.1322 6.7676 17.601C6.29876 18.0699 5.66288 18.3333 4.99984 18.3333H4.1665C3.50346 18.3333 2.86758 18.0699 2.39874 17.601C1.9299 17.1322 1.6665 16.4963 1.6665 15.8333V11.6666ZM3.33317 12.4999V15.8333C3.33317 16.0543 3.42097 16.2662 3.57725 16.4225C3.73353 16.5788 3.94549 16.6666 4.1665 16.6666H4.99984C5.22085 16.6666 5.43281 16.5788 5.58909 16.4225C5.74537 16.2662 5.83317 16.0543 5.83317 15.8333V13.3333C5.83317 13.1122 5.74537 12.9003 5.58909 12.744C5.43281 12.5877 5.22085 12.4999 4.99984 12.4999H3.33317ZM14.9998 12.4999C14.7788 12.4999 14.5669 12.5877 14.4106 12.744C14.2543 12.9003 14.1665 13.1122 14.1665 13.3333V15.8333C14.1665 16.0543 14.2543 16.2662 14.4106 16.4225C14.5669 16.5788 14.7788 16.6666 14.9998 16.6666H15.8332C16.0542 16.6666 16.2661 16.5788 16.4224 16.4225C16.5787 16.2662 16.6665 16.0543 16.6665 15.8333V12.4999H14.9998ZM13.2321 11.5655C13.7009 11.0966 14.3368 10.8333 14.9998 10.8333H17.4998C17.9601 10.8333 18.3332 11.2063 18.3332 11.6666V15.8333C18.3332 16.4963 18.0698 17.1322 17.6009 17.601C17.1321 18.0699 16.4962 18.3333 15.8332 18.3333H14.9998C14.3368 18.3333 13.7009 18.0699 13.2321 17.601C12.7632 17.1322 12.4998 16.4963 12.4998 15.8333V13.3333C12.4998 12.6702 12.7632 12.0343 13.2321 11.5655Z'
      fill='#B1B1B1'
    />
  </svg>
);

