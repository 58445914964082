import React, { useState } from 'react';
import { Button } from 'ui/Button';
import { ChevronDownSmallIcon } from 'icons';
import { DropdownWrap, DropdownContainer, DropdownItem } from './styles';
import {
  handleCategoryChange,
  useCalendarCategories,
} from 'models/calendar/model';
import { Checkbox } from 'ui/Checkbox';

export const Dropdown: React.FC = props => {
  const [open, setOpen] = useState(false);
  const dropdownListState = useCalendarCategories();
  const handleToggle = () => {
    setOpen(prevState => !prevState);
  };
  return (
    <DropdownWrap {...props}>
      <Button
        buttonType={'link'}
        icon={ChevronDownSmallIcon}
        iconAlign={'right'}
        onClick={handleToggle}
      >
        Все события
      </Button>
      {open ? (
        <DropdownContainer>
          {dropdownListState.map((item, index) => {
            return (
              <DropdownItem key={item.key}>
                <Checkbox
                  checked={item.checked}
                  labelText={item.title as string}
                  onChange={() => handleCategoryChange({ item, index })}
                />
              </DropdownItem>
            );
          })}
        </DropdownContainer>
      ) : null}
    </DropdownWrap>
  );
};
