import React from 'react';
import styled from 'styled-components';
import { Banner } from './Banner';
import { Features } from './Features';
import { MiniBanner } from './MiniBanner';
import { Statuses } from './Statuses';
import { Subscription } from './Subscription';
import { useGetVIPClubSubscriptionsQuery } from 'queries/catalog/useGetVIPClubSubscriptionsQuery';
import { useVIPClubSubscriptions } from 'models/catalog/model';
import { Preloader } from 'ui/Preloader';

const Wrap = styled.div`
  width: 100%;
  margin: 0 0 40px;
  padding: 0 0 48px;
  align-self: flex-start;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
  text-align: center;
`;

export const VipClub: React.FC = () => {
  const query = useGetVIPClubSubscriptionsQuery();
  const data = useVIPClubSubscriptions();
  return query.isLoading ? (
    <StyledPreloader />
  ) : (
    <Wrap>
      <Banner />
      <Features />
      <MiniBanner />
      <Statuses />
      <Subscription data={data} />
    </Wrap>
  );
};
