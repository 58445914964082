import React from 'react';

export const StudyLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.58583 20.3425L6.5925 19.7581C5.65 19.4767 5 18.611 5 17.6262C5 16.6738 5.60667 15.8297 6.50583 15.5267L28.1617 8.21103C28.9633 7.92966 29.8408 7.92966 30.6425 8.21103L53.295 15.5484C54.2158 15.8405 54.8333 16.6847 54.8333 17.6478V17.6695C54.8333 18.6326 54.2158 19.4767 53.3058 19.7798L30.2958 27.2469C29.6025 27.4959 28.855 27.4959 28.1617 27.2469L11.9983 21.4572'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M28.8327 16.0352L12.3443 21.3055C11.2502 21.6085 10.416 22.4851 10.416 24.6928V37.6792'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M34.2493 37.4301C32.8952 37.5924 31.4327 37.679 29.916 37.679C22.7335 37.679 16.916 35.7418 16.916 33.3502V23.6104'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M42.916 23.6104V30.1036'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M52.4283 41.3804L39.5258 32.0194C38.6808 31.4025 37.5 32.0086 37.5 33.0475V48.9342C37.5 49.4861 37.9767 49.6809 38.4208 49.248L42.6025 45.2114L46.6325 52.3107C46.9683 52.8626 47.77 52.9816 48.4092 52.5812L50.0992 51.5207C50.7275 51.1202 50.9767 50.3519 50.6625 49.8L46.7842 43.0795H51.8542C52.5042 43.0903 52.9158 41.7051 52.4283 41.3804Z'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>
);

