import React from 'react';
import { DateInputType } from 'react-date-range';
import { CalendarSC } from './styled';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateFormats } from 'constants/dateFormats';

interface IProps {
  date: DateInputType;
  setDate: React.Dispatch<React.SetStateAction<DateInputType>>;
  setShowPicker: (value: boolean) => void;
  changeFieldValue: (fieldName: string, value: string) => void;
}

function getHumanizedDate(date: Date) {
  if (!date) return '';

  return format(date, DateFormats.FullDateAsDigits, { locale: ru });
}

export const DataPicker: React.FC<IProps> = ({
  date,
  setDate,
  setShowPicker,
  changeFieldValue,
}) => {
  return (
    <CalendarSC
      onChange={item => {
        setDate(item as DateInputType);
        changeFieldValue('birthday', getHumanizedDate(item as Date));
        setShowPicker(false);
      }}
      date={date}
      // Тут крч нафакапили разработчики этого компонента.
      // @ts-expect-error: locale отсутствует в интерфейсе компонента, поэтому ругается на отсутствие этого пропса
      locale={ru}
    />
  );
};
