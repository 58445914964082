import { useMutation } from 'react-query';
import { paySberbankFx } from 'models/order/model';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import {
  SberbankPaymentRequest,
  SberbankPaymentResponse,
} from 'models/order/types';
import { AxiosError, AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSberbankPaymentQuery = () =>
  useMutation<
    AxiosResponse<SberbankPaymentResponse>,
    AxiosError<{ error?: string }>,
    SberbankPaymentRequest
  >('sberbankPayment', data => paySberbankFx(data), {
    onSuccess: res => {
      window.location.href = res.data.data;
    },
    onError: res => {
      if (res.response?.data.error) {
        toast.error(<ErrorMessage text={res.response.data.error} />);
      }
    },
  });
