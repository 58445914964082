import { useMutation } from 'react-query';
import { apiClient } from 'api/apiClient';
import { DaDataSuggestionQueryResponse } from '../types';
import { Endpoint } from 'constants/endpoints';

export const useGetAddressDaDataSuggestionQuery: DaDataSuggestionQueryResponse = () =>
  useMutation('getCityDaDataSuggestion', async ({ address, country }) => {
    return await apiClient
      .post(
        Endpoint.DADATA_SUGGESTION,
        JSON.stringify({
          address,
          country,
          from_bound: 'street',
          to_bound: 'street',
        })
      )
      .then(res => {
        return res.data;
      });
  });
