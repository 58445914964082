import { useMutation } from 'react-query';
import { calculateDiscountFx } from 'models/order';
import { setCalculation } from 'models/order/model';
import { DiscountCalculationQuery } from './types';

export const useDiscountCalculationQuery: DiscountCalculationQuery = () =>
  useMutation('addCartItem', data => calculateDiscountFx(data), {
    onSuccess: res => {
      setCalculation(res.data.data);
    },
  });
