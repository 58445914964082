import { useMutation } from 'react-query';
import { setNewPasswordFx } from 'models/auth/model';
import { ChangePasswordQueryProps } from './types';

export const useChangePasswordQuery: ChangePasswordQueryProps = callback =>
  useMutation(
    values => {
      return setNewPasswordFx(values);
    },
    {
      onSuccess: () => {
        //В случае успеха просто переключаем на модалку с успехом
        if (callback) callback();
      },
    }
  );
