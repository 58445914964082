import React from 'react';
import { Cart } from './Cart';
import { Delivery } from './Delivery';
import { Payment } from './Payment';
import { BuyCategoriesKey } from '../Steps/types';
import { RouteComponentProps } from 'react-router';

export const Content: React.FC<
  RouteComponentProps<{ id: string }> & {
    categoryKey: BuyCategoriesKey;
    changeToNextStep: () => void;
  }
> = ({ categoryKey, changeToNextStep, ...rest }) => {
  // try {
  switch (categoryKey) {
    case 'cart': {
      return (
        <div {...rest}>
          <Cart changeToNextStep={changeToNextStep} />
        </div>
      );
    }
    case 'delivery': {
      return (
        <div {...rest}>
          <Delivery changeToNextStep={changeToNextStep} {...rest} />
        </div>
      );
    }
    case 'payment': {
      return (
        <div {...rest}>
          <Payment />
        </div>
      );
    }
    default:
      console.error(`Unknown order state: '${categoryKey}'!`);
      return null;
  }
};
