import styled from 'styled-components';
import { DateRange } from 'react-date-range';

export const DateRangeSC = styled(DateRange)`
  .rdrDayToday > span > span {
    &:after {
      background: #fed74d;
    }
  }
  .rdrWeekDays {
    text-transform: capitalize;
  }
`;

export const Wrap = styled.div`
  overflow: hidden;
  max-width: 334px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #fed74d;
  border-radius: 14px;
`;
