import React, { useState } from 'react';
import { Item, ItemTitle, Info, Preview, PlayButton } from './styles';
import { HomeworkVideoFile } from 'models/homework/types';
import { PlayMediumIcon } from 'icons';
import { Modal } from 'ui/Modal';
import { VideoPlayer } from 'components/common/VideoPlayer';
import { formatSecondsToDuration } from 'utilities/formatSecondsToDuration';

export const HomeworkVideoMaterial: React.FC<HomeworkVideoFile> = data => {
  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <Item onClick={handleOpen}>
        <Preview>
          <PlayButton icon={PlayMediumIcon} />
        </Preview>
        <ItemTitle>{data.name}</ItemTitle>
        <Info>{formatSecondsToDuration(Number(data.duration))}</Info>
      </Item>
      {showModal ? (
        <Modal handleClose={handleClose}>
          <VideoPlayer
            source={{ ...data, id: data.id.toString() }}
            isTrailer={false}
          />
        </Modal>
      ) : null}
    </>
  );
};
