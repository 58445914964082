import React, { ChangeEvent, useState } from 'react';
import {
  ModalWrap,
  BonusDiscountText,
  BonusDiscountIcon,
  BonusDiscountTitle,
  BonusDiscountDigit,
  ModalButton,
  ModalText,
  Range,
  RangeInput,
  RangeWrap,
  RangeSelect,
  RangeTop,
  RangeStart,
  RangeEnd,
  BonusInput,
} from './styles';
import { Modal } from 'ui/Modal';
import Ruble from 'components/common/Ruble';
import { Field, Form } from 'react-final-form';
import { RatingStarIcon } from 'icons';
import { LoyaltyModalProps, RangeChangeEventHandler } from './types';
import { useMaxBonusPayPercent } from 'models/order/model';

export const LoyaltyModal: React.FC<LoyaltyModalProps> = ({
  handleClose,
  value,
  finalPrice,
  handleSubmit,
  fieldName,
  type,
}) => {
  const [range, setRange] = useState(0);
  const maxBonusPayPercent = useMaxBonusPayPercent();
  const getRangeValue = () => {
    //Если у нас модальное окно используется для бонусных баллов, то мы можем применить только половину от стоимости
    if (type === 'bonus') {
      const pricePercent = (finalPrice / 100) * maxBonusPayPercent;
      return value > pricePercent ? pricePercent : value;
    }

    //Если у нас модальное окно используется для премиальных вжухов, то мы можем покрыть 100% стоимости заказа
    if (type === 'premia') {
      return value > finalPrice ? finalPrice : value;
    }

    return value;
  };
  const handleChange: RangeChangeEventHandler = (e, onChange) => {
    if (Number(e.target.value) <= getRangeValue()) {
      onChange(e);
      setRange(Number(e.target.value));
    }
  };
  const percent = Math.round((range * 100) / Number(getRangeValue()));

  return (
    <Form
      onSubmit={handleSubmit}
      render={props => (
        <form onSubmit={props.handleSubmit}>
          <Modal handleClose={handleClose}>
            <ModalWrap>
              <div>
                <BonusDiscountText>Бонусная скидка</BonusDiscountText>
                <BonusDiscountTitle>
                  <BonusDiscountIcon>{RatingStarIcon}</BonusDiscountIcon>
                  <Field name={fieldName}>
                    {({ input, meta }) => (
                      <BonusInput
                        meta={meta}
                        {...input}
                        value={range}
                        inputWidth={112}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChange(e, input.onChange)
                        }
                      />
                    )}
                  </Field>
                  <BonusDiscountDigit>{Ruble}</BonusDiscountDigit>
                </BonusDiscountTitle>
              </div>
              <Range>
                <RangeTop>
                  <RangeStart>0 {Ruble}</RangeStart>
                  <RangeEnd>
                    {Math.round(getRangeValue())} {Ruble}
                  </RangeEnd>
                </RangeTop>
                <RangeWrap>
                  <RangeSelect width={percent.toString()} />
                  <Field name={fieldName} initialValue={0}>
                    {({ input }) => (
                      <RangeInput
                        {...input}
                        type='range'
                        min='0'
                        max={Math.round(getRangeValue())}
                        onChange={e => handleChange(e, input.onChange)}
                      />
                    )}
                  </Field>
                </RangeWrap>
              </Range>
              <ModalText>
                {fieldName === 'bonus'
                  ? 'Оплата бонусами не может превышать 50% от общей суммы заказа'
                  : 'Оплата премиальными вжухами не может превышать 100% от общей суммы заказа'}
              </ModalText>
              <ModalButton type='submit'>Использовать</ModalButton>
            </ModalWrap>
          </Modal>
        </form>
      )}
    />
  );
};
