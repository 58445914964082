import React from 'react';

export const FireLineIcon = (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.0009 38.3333C23.3161 38.3333 26.4955 37.0164 28.8397 34.6722C31.1839 32.328 32.5009 29.1485 32.5009 25.8333C32.5009 24.39 32.1175 23.005 31.6676 21.7167C28.8892 24.4617 26.7792 25.8333 25.3342 25.8333C31.9925 14.1667 28.3342 9.16667 18.3342 2.5C19.1676 10.8333 13.6742 14.6233 11.4376 16.7283C9.6131 18.4445 8.34628 20.6691 7.80136 23.1139C7.25644 25.5586 7.45854 28.1107 8.38147 30.4392C9.30439 32.7677 10.9056 34.7653 12.9774 36.1728C15.0492 37.5804 17.4961 38.3331 20.0009 38.3333ZM21.1842 8.725C26.5859 13.3083 26.6126 16.87 22.4392 24.1817C21.1709 26.4033 22.7759 29.1667 25.3342 29.1667C26.4809 29.1667 27.6409 28.8333 28.8659 28.175C28.5028 29.5466 27.8262 30.8153 26.8895 31.881C25.9527 32.9467 24.7814 33.7804 23.4677 34.3164C22.1539 34.8525 20.7337 35.0763 19.3188 34.9701C17.9039 34.864 16.5329 34.4309 15.3139 33.7048C14.0948 32.9788 13.061 31.9797 12.2937 30.7862C11.5264 29.5926 11.0467 28.2373 10.8923 26.8268C10.7378 25.4164 10.9129 23.9893 11.4038 22.658C11.8946 21.3268 12.6878 20.1276 13.7209 19.155C13.9309 18.9583 14.9959 18.0133 15.0426 17.9717C15.7492 17.3383 16.3309 16.7767 16.9059 16.1617C18.9559 13.965 20.4292 11.5283 21.1826 8.725H21.1842Z'
      fill='#FF4921'
    />
  </svg>
);

