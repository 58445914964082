import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 0 12px 10px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Member = styled.div`
  display: flex;
  padding: 0 0 58px;
  & + & {
    padding: 40px 0 58px;
    border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  }
`;

export const Info = styled.div`
  max-width: 215px;
  margin-right: 99px;
`;

export const Photo = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
`;

export const Name = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: 14px 0 0;
`;

export const Duty = styled.p`
  margin: 12px 0 0;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const Text = styled.p`
  margin: 0;

  & + & {
    margin-top: 20px;
  }
`;

export const TextWrap = styled.div`
  max-width: 400px;
`;
