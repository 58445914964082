export enum Endpoint {
  // USER
  'LOGIN' = '/api/v1/user/login',
  'USER_PROFILE' = '/api/v1/user/profile',
  'GET_DIPLOMAS' = '/api/v1/user/diploma',
  'GET_DIPLOMA' = '/api/v1/user/diploma/{id}',
  'INSTAGRAM' = '/api/v1/user/profile/instagram',
  'INSTAGRAM_CALLBACK' = '/api/v1/instagram/callback',
  'DELETE_INSTAGRAM' = '/api/v1/instagram/delete',
  'ORDER_LIST' = '/api/v1/order/list',
  'GET_ADDRESSES' = '/api/v1/address/get',
  'ADD_ADDRESS' = '/api/v1/address/add',
  'UPDATE_ADDRESS' = '/api/v1/address/update/{id}',
  'DELETE_ADDRESS' = '/api/v1/address/delete/',
  'GET_BONUS_AMOUNT' = '/api/v1/user/get-bonus-amount',
  'GET_BONUS_HISTORY' = '/api/v1/user/get-bonus-history',
  'GET_REFERAL_LINK' = '/api/v1/user/referral-link',
  'CHANGE_PASSWORD' = '/api/v1/user/profile/change-password',
  'CHECK_CURRENT_PASSWORD' = '/api/v1/user/profile/check-current-password',
  'GET_INNER_BUDGET' = '/api/v1/user/profile/inner-budget',
  'GET_INNER_BUDGET_HISTORY' = '/api/v1/user/profile/inner-budget/history',
  'CHANGE_EMAIL' = '/api/v1/user/profile/change-email',
  'CHANGE_EMAIL_CONFIRM' = '/api/v1/user/profile/change-email-confirm',
  'GET_USER_PROFILE' = '/api/v1/user/profile',
  'UPDATE_USER_PROFILE' = '/api/v1/user/profile/update',
  'UPLOAD_USER_AVATAR' = '/api/v1/user/profile/avatar-upload',
  'DELETE_USER_PROFILE' = '/api/v1/user/profile/delete',
  'GET_GIFTS_CARD' = '/api/v1/user/gift-cards',
  'ACTIVATE_CARD' = '/api/v1/user/activate-card',
  'GET_NEWS' = '/api/v1/articles',
  'REGISTER_USER' = '/api/v1/register',
  'CONFIRM_REGISTRY' = '/api/v1/register/confirm',

  // PASS RECOVERY
  'RESTORE_PASSWORD' = '/api/v1/recovery/change-password',
  'CONFIRM_NEW_PASSWORD' = '/api/v1/recovery/confirm-new-password',
  'CONFIRM_CODE' = '/api/v1/sms/check',

  // CATALOG
  'CATALOG_LIST' = '/api/v1/catalog/',
  'CATALOG_ITEM' = '/api/v1/catalog/product/',
  'GET_PRODUCT_ITEM' = '/api/v1/catalog/product/{id}',
  'GET_COURSES' = '/api/v1/courses',
  'GET_FREE_COURSES' = '/api/v1/free-webinars',
  'GET_CATALOG_GIFT_CARDS' = '/api/v1/gift-cards',
  'GET_INTENSIVES' = '/api/v1/intensives',
  'GET_INTENSIVE_ITEM' = '/api/v1/intensives/{id}',
  'GET_EVENTS' = '/api/v1/events',
  'GET_EVENT_ITEM' = '/api/v1/events/{id}',

  // CART
  'ADD_ITEM_TO_CART' = '/api/v1/basket/add',
  'UPDATE_ITEM_TO_CART' = '/api/v1/basket/update',
  'GET_CART' = '/api/v1/basket',
  'DELETE_ITEM_FROM_CART' = '/api/v1/basket/delete/',
  'PURSCHASE_GIFT_CARD' = '/api/v1/gift-cards/purchase/{id}',

  // LEGAL DOCUMENTS
  'GET_LEGAL_DOCUMENTS' = '/api/v1/legal-documents',

  // ORDER
  'GET_ORDER_DETAIL' = '/api/v1/order/{id}/detail',
  'CREATE_ORDER' = '/api/v1/order/create',
  'CALCULATE_DISCOUNT' = '/api/v1/discount/calculate',
  'APPLY_DISCOUNT' = '/api/v1/discount/use',
  'SBERBANK_PAYMENT' = '/api/v1/payments/{id}/sberbank',
  'PAYPAL_PAYMENT' = '/api/v1/payments/{id}/paypal',
  'PAYPAL_BUTTON_LINK' = '/api/v1/payments/{id}/paypal/button-link',
  'GET_SBERBANK_ORDER_DETAIL' = '/api/v1/sberbank/success-regular',
  'GET_DELIVERY_OPTIONS' = '/api/v1/delivery',
  'SET_DELIVERY' = '/api/v1/delivery',

  // TESTIMONIALS
  'GET_TESTIMONIALS' = '/api/v1/testimonials/list',

  // COURSE
  'ADD_TESTIMONIAL' = '/api/v1/courses/rate',
  'GET_COURSES_PURCHASED' = '/api/v1/courses/purchased',
  'PURCHASE_COURSE_ORDER' = '/api/v1/courses/order',
  'PROLONG_COURSE_ORDER' = '/api/v1/courses/prolong',
  'ADDITIONAL_COURSE_ORDER' = '/api/v1/additionals/order',

  // LESSON
  'GET_LESSON' = '/api/v1/lectures/{courseId}/',

  // SDEK
  'SDEK' = '/api/v1/cdek/service',
  'SEARCH_SDEK_CITY' = '/api/v1/cdek/cities',
  'CALCULATE_SDEK_DELIVERY' = '/api/v1/cdek/calculate',

  // HOME WORK
  'UPLOAD_HOMEWORK_FILE' = '/api/v1/homework/file/upload',
  'UPLOAD_HOMEWORK' = '/api/v1/homework/send',
  'GET_HOMEWORK' = '/api/v1/homework',

  // COMMENT
  'UPLOAD_COMMENT' = '/api/v1/comment/send',
  'UPLOAD_COMMENT_FILE' = '/api/v1/comment/file/upload',

  // FILES
  'GET_VIDEO' = '/api/v1/file/video',

  // PROMO
  'GET_PROMOS_LIST' = '/api/v1/promos',
  'GET_PROMO_ITEM' = '/api/v1/promos/{id}',

  // DIPLOMA
  'GET_DIPLOMA_PDF' = '/api/v1/diploma',
  // INNER_INSIGHT
  'GET_COACHING_LESSONS' = '/api/v1/coaching/{courseId}/lecture/',
  'GET_COACHING_SCHOOLS' = '/api/v1/coaching',
  'GET_PURCHASED_COACHING_SCHOOL' = '/api/v1/coaching/purchased',
  'PURCHASE_COACHING_SCHOOL' = '/api/v1/coaching/order',
  'PURCHASE_COACHING_SCHOOL_SECOND_PART' = '/api/v1/coaching/order-second-part',
  'GET_MASTER_CLASSES' = '/api/v1/master-classes',
  'ADD_REPLY_COACHING_SCHOOL' = '/api/v1/coaching/rate',

  // TRAINIGS
  'GET_TRAININGS_LIST' = '/api/v1/trainings',
  'GET_TRAININGS_ITEM' = '/api/v1/trainings/{id}',

  // CHAT
  'CHAT_SEND_MESSAGE' = '/api/v1/chat/send-message',
  'CHAT_GET_MESSAGES' = '/api/v1/chat/get-messages',
  'CHAT_GET_NEW_MESSAGES' = '/api/v1/chat/check-new-messages',
  'CHAT_SEND_FILE' = '/api/v1/chat/send-files',

  // DADATA
  'DADATA_SUGGESTION' = '/api/v1/dadata/address',

  // CALENDAR
  'GET_CALENDAR_CATEGORIES' = '/api/v1/calendar/categories',
  'GET_CALENDAR_PAGE' = '/api/v1/calendar',

  // NOTIFICATIONS
  'GET_NOTIFICATIONS' = '/api/v1/user/notifications',
  'GET_NEW_NOTIFICATIONS' = '/api/v1/user/notifications/new',
  'MARK_AS_READ_NOTIFICATIONS' = '/api/v1/user/notifications/read',

  //VIP-CLUB
  'GET_VIP_CLUB_SUBSCRIPTIONS' = '/api/v1/vip-club',
  'PURCHASE_VIP_CLUB_SUBSCRIPTION' = '/api/v1/vip-club/order',

  // VIP-CHAT
  'BUY_VIP_CHAT' = '/api/v1/vip-chats/order',
  'GET_VIP_CHATS' = '/api/v1/vip-chats',
  'GET_VIP_CHAT' = '/api/v1/vip-chats/{id}',

  // SECRET CHAT
  'BUY_SECRET_CHAT' = '/api/v1/secret-chats/order',
  'GET_SECRET_CHATS' = '/api/v1/secret-chats',
  'GET_SECRET_CHAT' = '/api/v1/secret-chats/{id}',

  // MY EDUCATION/PLANS
  'GET_PLANS_CATEGORIES' = '/api/v1/plans/categories',
  'GET_PLANS' = '/api/v1/plans',
}
