import React from 'react';

export const BookIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.5 1.5C1.08579 1.5 0.75 1.83579 0.75 2.25V13.5C0.75 13.9142 1.08579 14.25 1.5 14.25H6.75C7.14783 14.25 7.52936 14.408 7.81066 14.6893C8.09196 14.9706 8.25 15.3522 8.25 15.75C8.25 16.1642 8.58579 16.5 9 16.5C9.41421 16.5 9.75 16.1642 9.75 15.75C9.75 15.3522 9.90804 14.9706 10.1893 14.6893C10.4706 14.408 10.8522 14.25 11.25 14.25H16.5C16.9142 14.25 17.25 13.9142 17.25 13.5V2.25C17.25 1.83579 16.9142 1.5 16.5 1.5H12C11.0054 1.5 10.0516 1.89509 9.34835 2.59835C9.22214 2.72456 9.10586 2.85884 9 2.99998C8.89414 2.85884 8.77786 2.72456 8.65165 2.59835C7.94839 1.89509 6.99456 1.5 6 1.5H1.5ZM9.75 13.1519C10.2022 12.8909 10.7188 12.75 11.25 12.75H15.75V3H12C11.4033 3 10.831 3.23705 10.409 3.65901C9.98705 4.08097 9.75 4.65326 9.75 5.25V13.1519ZM8.25 13.1519V5.25C8.25 4.65326 8.01295 4.08097 7.59099 3.65901C7.16903 3.23705 6.59674 3 6 3H2.25V12.75H6.75C7.28117 12.75 7.79782 12.8909 8.25 13.1519Z'
    />
  </svg>
);
