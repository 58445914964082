import React from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Docs } from './Docs';
import { Contacts } from './Contacts';
import { Project } from './Project';
import { Team } from './Team';
import { Path } from 'constants/paths';

const ContentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px -40px;
`;

export const Content: React.FC = props => {
  return (
    <ContentWrap {...props}>
      <Switch>
        <Route exact path={`${Path.about}/project`}>
          <Project />
        </Route>
        <Route exact path={`${Path.about}/team`}>
          <Team />
        </Route>
        <Route exact path={`${Path.about}/docs`}>
          <Docs />
        </Route>
        <Route exact path={`${Path.about}/contacts`}>
          <Contacts />
        </Route>
        <Redirect from={Path.about} to={`${Path.about}/project`} />
      </Switch>
    </ContentWrap>
  );
};
