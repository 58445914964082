import styled from 'styled-components';
import { CategoryButton } from 'ui/CategoryButton';
import { Link } from 'react-router-dom';

export const Category = styled(CategoryButton)``;

export const StyledLink = styled(Link)``;

export const CategoryWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & ${StyledLink} + ${StyledLink} {
    margin-top: 12px;
  }
`;
