import React from 'react';
import { ChevronLeftSmallIcon, ChevronRightSmallIcon } from 'icons/';
import Button from 'ui/Button/Button';
import { RecommendedNews } from '../RecommendedNews';
import {
  Wrap,
  ArticleBanner,
  Text,
  BackToFilmsLink,
  PreloaderSC,
  HideableText,
  KeepReadingButton,
  Content,
  NoData,
} from './styles';
import { NewsArticleProps } from './types';
import { useGetOneNewsItem } from 'queries/news/one/useGetNewsItemQuery';
import { regExpHTML } from 'constants/regExps';
import { getImagePath } from 'utilities/getImagePath';

export const NewsArticle: React.FC<NewsArticleProps> = ({ id }) => {
  const { data, isLoading } = useGetOneNewsItem(id);
  const image = getImagePath(data);
  if (!isLoading && !data) {
    return (
      <Wrap>
        <BackToFilmsLink to='/news'>
          <Button
            buttonType={'link'}
            icon={ChevronLeftSmallIcon}
            label='Ко всем новостям'
          />
        </BackToFilmsLink>
        <Content>
          <NoData>Нет данных</NoData>
        </Content>
      </Wrap>
    );
  }

  return isLoading ? (
    <PreloaderSC />
  ) : (
    <>
      <Wrap>
        <ArticleBanner
          title={data.name}
          image={image}
          date={new Date(data.published_at)}
        />
        <BackToFilmsLink to='/news'>
          <Button
            buttonType={'link'}
            icon={ChevronLeftSmallIcon}
            label='Ко всем новостям'
          />
        </BackToFilmsLink>
        <Content>
          <HideableText shouldHide={!!data.link}>
            {regExpHTML.test(data.description) ? (
              <Text
                dangerouslySetInnerHTML={{ __html: `${data.description}` }}
              />
            ) : (
              <Text>{data.description}</Text>
            )}
          </HideableText>
          {data.link ? (
            <a
              href={
                data.link.includes('http') ? data.link : 'https://' + data.link
              }
            >
              <KeepReadingButton
                icon={ChevronRightSmallIcon}
                label='Продолжить чтение'
                iconAlign='right'
              />
            </a>
          ) : null}
        </Content>
      </Wrap>
      <RecommendedNews id={id} />
    </>
  );
};
