import styled from 'styled-components';
import { Steps } from './Steps';
import { Title } from './Title';
import { Content } from './Content';

export const Wrap = styled.div`
  display: grid;
  margin: 40px auto 0;
  grid-template-areas:
    'empty1 empty1 empty1 header header header header header header header header header'
    'sidebar sidebar sidebar content content content content content content content empty2 empty2';
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  gap: 24px;
  padding: 0 12px 100px;
  max-width: 1236px;
  width: 100%;
`;

export const StyledTitle = styled(Title)`
  grid-area: header;
`;

export const StyledSteps = styled(Steps)`
  grid-area: sidebar;
`;

export const StyledContent = styled(Content)`
  grid-area: content;
`;
