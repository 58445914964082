import React from 'react';
import { TextSC, Wrapper } from './styled';
import { Button } from 'ui/Button';
import { baseUrl } from 'api/apiClient';

export const EmptyBonus: React.FC = () => {
  return (
    <Wrapper>
      <TextSC>
        У вас нет операций с бонусами. Вы можете зарабатывать бонусы и
        оплачивать ими покупки.
      </TextSC>
      <div>
        <a href={baseUrl + '/upload/documents/bonus_programm.pdf'}>
          <Button>Подробнее о баллах</Button>
        </a>
      </div>
    </Wrapper>
  );
};
