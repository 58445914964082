import React from 'react';

export const SendIcon = (
  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.333 1.667l-9.166 9.166M18.333 1.667L12.5 18.333l-3.333-7.5-7.5-3.333 16.666-5.833z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
