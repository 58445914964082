import React, { ChangeEvent, useState } from 'react';
import { CrownIcon, RatingStarIcon } from 'icons';
import {
  List,
  Option,
  Inner,
  TextIcon,
  Title,
  Text,
  StyledButton,
  StyledPreloader,
  ModalWrap,
  BonusDiscountText,
  BonusDiscountIcon,
  BonusDiscountTitle,
  BonusDiscountDigit,
  ModalButton,
  ModalText,
  Range,
  RangeInput,
  RangeWrap,
  RangeSelect,
  RangeTop,
  RangeStart,
  RangeEnd,
  BonusInput,
} from './styles';
import { Modal } from 'ui/Modal';
import Ruble from 'components/common/Ruble';
import { useGetInnerBudgetQuery } from 'queries/account/innerBudget/useGetInnerBudgetQuery/';
import { Field, useForm } from 'react-final-form';
import { LoyaltyPointsProps } from './types';
import { declension } from 'utilities/declension';

export const LoyaltyPoints: React.FC<LoyaltyPointsProps> = ({
  orderPrice,
  isLoyaltyModalVisible,
  handleClose,
  handleOpen,
  ...rest
}) => {
  const { isLoading, data: innerBudget } = useGetInnerBudgetQuery();
  const [range, setRange] = useState(0);
  const form = useForm('PaymentForm');
  const rangeMax =
    innerBudget && innerBudget > orderPrice ? orderPrice : innerBudget || 0;
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) <= rangeMax) {
      setRange(Number(e.target.value));
      form.change('inner_budget', e.target.value);
    }
  };

  const percent = Math.round((range * 100) / Number(rangeMax));

  return (
    <>
      <div {...rest}>
        <List>
          {isLoading ? (
            <StyledPreloader />
          ) : (
            <Option>
              <Inner>
                <Title>Премия </Title>
                <Text>
                  <TextIcon>{CrownIcon}</TextIcon>
                  {innerBudget
                    ? `${innerBudget} ${declension(
                        ['вжух', 'вжуха', 'вжухов'],
                        innerBudget
                      )}`
                    : 0}
                </Text>
                <Field component={'input'} hidden name={'inner_budget'} />
              </Inner>
              <StyledButton
                buttonType={'secondary'}
                disabled={Number(innerBudget) < 1}
                onClick={handleOpen}
              >
                Использовать
              </StyledButton>
            </Option>
          )}
        </List>
      </div>
      {isLoyaltyModalVisible && (
        <Modal handleClose={handleClose}>
          <ModalWrap>
            <div>
              <BonusDiscountText>Бонусная скидка</BonusDiscountText>
              <BonusDiscountTitle>
                <BonusDiscountIcon>{RatingStarIcon}</BonusDiscountIcon>
                <Field name='inner_budget'>
                  {({ input, meta }) => (
                    <BonusInput
                      meta={meta}
                      {...input}
                      value={range}
                      inputWidth={112}
                      onChange={handleChange}
                    />
                  )}
                </Field>
                <BonusDiscountDigit>{Ruble}</BonusDiscountDigit>
              </BonusDiscountTitle>
            </div>
            <Range>
              <RangeTop>
                <RangeStart>0 {Ruble}</RangeStart>
                <RangeEnd>
                  {Math.round(rangeMax)} {Ruble}
                </RangeEnd>
              </RangeTop>
              <RangeWrap>
                <RangeSelect width={percent.toString()} />
                <RangeInput
                  type='range'
                  min='0'
                  value={range}
                  max={Math.round(rangeMax)}
                  onChange={handleChange}
                />
              </RangeWrap>
            </Range>
            <ModalText>
              Вы можете оплатить премиальными вжухами до 100% от общей стоимости
            </ModalText>
            <ModalButton type='submit'>Использовать</ModalButton>
          </ModalWrap>
        </Modal>
      )}
    </>
  );
};
