import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Main, Sidebar, Title, Wrap } from './styles';
import { Button } from 'ui/Button';
import { ChevronLeftIcon } from 'icons';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export type OrderType = 'card' | 'courses' | 'chat' | 'subscription';

export const DirectBuy: React.FC<
  RouteComponentProps<{ id: string; key: OrderType }>
> = ({ match, history }) => {
  const { id, key } = match.params;
  useScrollToTopOnMount();
  const handleBackButtonClick = () => {
    history.goBack();
  };

  return (
    <Wrap>
      <Title>Оплата</Title>
      <Sidebar>
        <Button
          buttonType={'link'}
          icon={ChevronLeftIcon}
          onClick={handleBackButtonClick}
        >
          Назад
        </Button>
      </Sidebar>
      <Main id={id} type={key} />
    </Wrap>
  );
};
