import React, { useEffect } from 'react';
import Button from 'ui/Button/Button';
import {
  Wrap,
  SalesDetailBanner,
  BackToSalesLink,
  SalesDetailContent,
  StyledPreloader,
  NoItemText,
} from './styles';
import { ChevronLeftSmallIcon } from 'icons/';
import { SalesDetailProps } from './types';
import { $promoItem, clearPromoItem } from 'models/sales/model';
import { useStore } from 'effector-react';
import { usePromoItemQuery } from 'queries/sales/usePromoItemQuery';
import { regExpHTML } from 'constants/regExps';

export const SalesDetail: React.FC<SalesDetailProps> = ({ id }) => {
  const query = usePromoItemQuery(`${id}`);
  const data = useStore($promoItem);
  const showPreloader = query.isLoading || query.isFetching;

  useEffect(() => {
    return () => clearPromoItem();
  }, []);

  return showPreloader ? (
    <StyledPreloader />
  ) : data ? (
    <Wrap>
      <SalesDetailBanner showButton={false} {...data} />
      <BackToSalesLink to='/sales'>
        <Button
          buttonType={'link'}
          icon={ChevronLeftSmallIcon}
          label='Ко всем акциям'
        />
      </BackToSalesLink>
      {regExpHTML.test(data.description) ? (
        <SalesDetailContent
          dangerouslySetInnerHTML={{ __html: `${data.description}` }}
        />
      ) : (
        <SalesDetailContent>{data?.description}</SalesDetailContent>
      )}
    </Wrap>
  ) : (
    <NoItemText>Такой акции нет!</NoItemText>
  );
};
