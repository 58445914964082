import styled from 'styled-components';
import { Button } from 'ui/Button';

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline1};
`;
export const Text = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
  margin-top: 24px;
  & + & {
    margin-top: 0;
  }
`;
export const MoreButton = styled(Button)`
  margin-top: 10px;

  &:focus {
    box-shadow: none;
    color: ${({ theme }) => theme.colors.secondTextColor};

    span {
      fill: ${({ theme }) => theme.colors.secondTextColor};
    }
  }
`;
