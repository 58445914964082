import styled from 'styled-components';
import { Switch as SwitchToggle } from 'ui/Switch';
import { Dropdown } from './Dropdown';
import { Preloader } from 'ui/Preloader';

export const ActiveEvents = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const ActiveEventsSelect = styled(SwitchToggle)``;

export const ActiveEventsText = styled.div`
  & + ${ActiveEventsSelect} {
    margin-left: 8px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
`;

export const DropdownList = styled(Dropdown)`
  margin-right: auto;
`;

export const StyledPreloader = styled(Preloader)`
  text-align: center;
  margin: 24px 0 12px;
`;
