import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';
import { InputText } from 'ui/InputText';

export const List = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-radius: 14px;
  padding: 24px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  width: 100%;
  height: 1px;
  margin: 24px 0;
`;

export const Inner = styled.div`
  max-width: 489px;
`;

export const TextIcon = styled.span``;

export const Title = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0;
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  font-weight: 700;
  margin: 8px 0 0;
  display: flex;
  align-items: center;

  & > ${TextIcon} {
    margin-right: 8px;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 0 auto 0;
  text-align: center;
`;

export const ModalWrap = styled.div`
  text-align: center;
`;

export const BonusDiscountText = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText2}
`;

export const BonusDiscountTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0 0;
  ${({ theme }) => theme.typography.headline2}
`;

export const BonusDiscountIcon = styled.div`
  margin: 0 20px 0 0;
`;

export const BonusDiscountDigit = styled.div`
  margin: 0 0 0 20px;
  ${({ theme }) => theme.typography.headline3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const BonusInput = styled(InputText)`
  & input {
    ${({ theme }) => theme.typography.headline3};
    text-align: center;
  }
`;

export const ModalButton = styled(Button)`
  margin: 24px 0 0;
`;

export const ModalText = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.bodyText3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const Range = styled.div`
  flex: 1 0 auto;
  margin: 0 22px 0 12px;
`;

export const RangeSelect = styled.div<{ width: string }>`
  background-color: ${({ theme }) => theme.colors.actionColor};
  border-radius: 5px;
  width: ${({ width }) => width + '%'};
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  height: 6px;
`;

export const RangeInput = styled.input`
  cursor: pointer;
  -webkit-appearance: none;
  background-color: transparent;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  &::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.mainBgColor};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08), 0 4px 4px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.mainBgColor};
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08), 0 4px 4px rgba(0, 0, 0, 0.1);
  }
`;

export const RangeWrap = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  margin: 8px 0 0;
  background-color: ${({ theme }) => theme.colors.secondColor};
`;

export const RangeTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RangeStart = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;
export const RangeEnd = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;
