import React from 'react';
import styled from 'styled-components';
import { Description } from './Description/';
import { Reviews } from './Reviews/';
import { OtherCourses } from './OtherCourses/';
import { Tariffs } from './Tariffs/';
import { Lessons } from './Lessons/';
import { TContent } from './types';
import { Trailer } from './Trailer';
import { TCourseStatuses } from 'models/courses/types';
import { Rules } from './Rules';

const CourseDescription = styled(Description)`
  width: calc(((100% - (24px * 8)) / 9) * 7 + (24px * 6));
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 40px 0;
  background: ${({ theme }) => theme.colors.thirdBgColor};
`;

export const Content: React.FC<TContent> = ({
  description,
  course_fee,
  lectures,
  additionals,
  name,
  active,
  id: courseId,
  trailer: trailerId,
  testimonials,
  renewal,
  isSchool = false,
  ...props
}) => {
  return (
    <div {...props}>
      <CourseDescription description={description} />
      <Divider />
      <Rules />
      {!isSchool && <Divider />}
      {!!trailerId && <Trailer trailerId={trailerId} />}
      <Tariffs
        course_fee={course_fee}
        id={courseId}
        renewal={renewal}
        active={active}
        isSchool={isSchool}
      />
      <Lessons
        lectures={lectures}
        additionals={additionals}
        active={active}
        id={courseId}
        isSchool={isSchool}
      />
      <Divider />
      {/* TODO: поменять статус на active.status */}
      <Reviews
        name={name}
        status={TCourseStatuses.COMPLETED}
        testimonials={testimonials}
        isSchool={isSchool}
      />
      {!isSchool && <OtherCourses id={courseId} />}
    </div>
  );
};
