import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { getSecretChatListFx, setSecretChatList } from 'models/catalog/model';
import { SecretChatListItemType } from 'models/catalog/types';

export const useGetSecretChatsQuery: () => UseQueryResult<
  AxiosResponse<{ data: SecretChatListItemType[] }>,
  AxiosError<{ error: string }>
> = () =>
  useQuery('getSecretChats', getSecretChatListFx, {
    onSuccess: res => {
      setSecretChatList(res.data.data);
    },
  });
