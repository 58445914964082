import React from 'react';
import { EmptyOrderMessage, Wrapper } from './styled';
import { Button } from 'ui/Button';
import { Link } from 'react-router-dom';
import { Path } from 'constants/paths';

const OrderEmpty: React.FC = () => {
  return (
    <Wrapper>
      <EmptyOrderMessage>
        У вас пока нет ни одного заказа на нашем сайте. Может, настало время это
        исправить?
      </EmptyOrderMessage>
      <div>
        <Link to={Path.catalogCourses}>
          <Button>Выбрать товары</Button>
        </Link>
      </div>
    </Wrapper>
  );
};

export default OrderEmpty;
