import styled from 'styled-components';
import bronzeBg from 'images/bg_bronze.png';
import silverBg from 'images/bg_silver.png';
import goldBg from 'images/bg_gold.png';

export const Wrap = styled.div`
  margin-top: 60px;
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline1};
`;

export const Subtitle = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
  margin-top: 8px;
`;

export const Inner = styled.div`
  margin: 24px -10px 0;
  display: flex;
`;

export const Item = styled.div`
  margin: 0 10px;
  width: calc((100% - 60px) / 3);
  border-radius: 14px;
  padding: 14px 20px 18px 18px;
  &:nth-of-type(1) {
    background-image: url(${bronzeBg});
  }
  &:nth-of-type(2) {
    background-image: url(${silverBg});
  }
  &:nth-of-type(3) {
    background-image: url(${goldBg});
  }
`;

export const ItemTitle = styled.div`
  ${({ theme }) => theme.typography.headline3};
`;

export const ItemText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.secondTextColor};
`;

export const List = styled.ul`
  padding-left: 17px;
  margin: 20px 0 0;
`;

export const ListItem = styled.li`
  ${({ theme }) => theme.typography.bodyText2};
  & + & {
    margin-top: 4px;
  }
`;
