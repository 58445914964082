import React from 'react';

export const BookLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17 33.4099L9.21 29.5099C7.86 28.8399 7 27.4499 7 25.9399V21.8799C7 20.3699 7.86 18.9799 9.21 18.2999L30.76 7.52988C32.17 6.82988 33.82 6.82988 35.23 7.52988L51.42 15.6199C52.89 16.3599 52.89 18.4599 51.42 19.1999L29.87 29.9699C29.32 30.2399 28.67 30.2499 28.12 29.9899L11.43 22.0899C10.76 21.7699 10 22.2599 10 22.9899V25.9999'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M33.93 36.9402L29.89 38.9602C29.33 39.2402 28.66 39.2402 28.1 38.9602L23 36.4102'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M52.9997 27.4102L42.6797 32.5702'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M51 20V25'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M23 42.2299L20 38.6999L17 39.1699V29.1699L23 32.2299V42.2299Z'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M50.78 42.4202L37 32.4102V49.4102C37 49.9202 37.44 50.1002 37.85 49.7002L41.71 45.9702L45.43 52.5302C45.74 53.0402 46.48 53.1502 47.07 52.7802L48.63 51.8002C49.21 51.4302 49.44 50.7202 49.15 50.2102L45.58 44.0002H50.26C50.85 44.0002 51.23 42.7202 50.78 42.4202Z'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>
);