import styled from 'styled-components';

export const Photo = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondColor};
`;

export const Name = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: 0 0 23px 0;
  word-break: break-word;
  line-height: 26px;
`;

export const UserData = styled.div`
  margin-left: 24px;
`;

export const Wrapper = styled.div`
  min-height: 80px;
  display: flex;
  position: relative;
  margin-bottom: 64px;
  width: 260px;
  &:before {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
    width: 100%;
    position: absolute;
    bottom: -24px;
  }
`;

export const Rating = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Value = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  font-weight: bold;
  margin-left: 9px;
`;

export const BonusAmount = styled.div`
  margin-right: 25px;
  display: flex;
`;

export const InnerBudget = styled.div`
  display: flex;
`;

export const PhotoBlank = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.actionColor};
`;

export const PhotoBlankText = styled.p`
  ${({ theme }) => theme.typography.headline3};
`;
