import React from 'react';
import { QuestionChatIcon, SolvePuzzleChatIcon, PlanChatIcon } from 'icons/';
import { Wrap, Title, ItemsWrap, Item, Icon, Text } from './styles';

export const Features: React.FC = props => {
  return (
    <Wrap {...props}>
      <Title>Возможности VIP-чата</Title>
      <ItemsWrap>
        <Item>
          <Icon>{QuestionChatIcon}</Icon>
          <Text>Любое количество Ваших&nbsp;вопросов</Text>
        </Item>
        <Item>
          <Icon>{SolvePuzzleChatIcon}</Icon>
          <Text>Разбор Вашей самой деликатной ситуации</Text>
        </Item>
        <Item>
          <Icon>{PlanChatIcon}</Icon>
          <Text>План решения любой Вашей проблемы</Text>
        </Item>
      </ItemsWrap>
    </Wrap>
  );
};
