import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Banner } from '../Banner';
import { $promosData } from 'models/sales';
import { useStore } from 'effector-react';
import { usePromosListQuery } from 'queries/sales/usePromosListQuery';
import { Preloader } from 'ui/Preloader';
import { clearPromoList } from 'models/sales/model';

const Wrap = styled.div`
  margin: 0 auto 100px;
  max-width: 1006px;
  display: grid;
  grid-row-gap: 24px;
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto;
  text-align: center;
`;

export const SalesList: React.FC = () => {
  const { isLoading, isFetching } = usePromosListQuery();
  const data = useStore($promosData);
  const showPreloader = isLoading || isFetching;

  useEffect(() => {
    return () => clearPromoList();
  }, []);

  const salesElements = useMemo(
    () =>
      data.map(element => <Banner showButton key={element.id} {...element} />),
    [data]
  );

  return showPreloader ? <StyledPreloader /> : <Wrap>{salesElements}</Wrap>;
};
