import { ChevronDownSmallIcon, ChevronUpSmallIcon } from 'icons';
import React, { useState } from 'react';
import { theme } from 'ui/theme';
import { truncate } from 'utilities/truncate';
import { Title, Text, MoreButton } from './styles';

const MAX_WORDS_VISIBLE = 100;

export const Description: React.FC<{ text: string }> = ({ text, ...rest }) => {
  const [visibility, setVisibility] = useState(false);

  const shortDescr = truncate(text, MAX_WORDS_VISIBLE);

  return (
    <div {...rest}>
      <Title>Описание</Title>
      <Text>{visibility ? text : shortDescr}</Text>
      {text.split(' ').length > MAX_WORDS_VISIBLE && (
        <MoreButton
          buttonType={'link'}
          icon={visibility ? ChevronUpSmallIcon : ChevronDownSmallIcon}
          iconAlign='right'
          iconFill={theme.colors.mainColor}
          onClick={() => setVisibility(!visibility)}
        >
          {visibility ? 'Скрыть' : 'Подробнее'}
        </MoreButton>
      )}
    </div>
  );
};
