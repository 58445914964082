import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

export const NewsWrap = styled.div`
  margin: 0 auto 24px;
  max-width: 1212px;
  display: grid;
  column-gap: 24px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const MoreButton = styled.div`
  margin-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};

  & > button > span > svg {
    width: 18px;
  }
`;

export const PreloaderSC = styled(Preloader)`
  display: flex;
  justify-content: center;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;
