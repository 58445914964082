import React from 'react';
import { useStore } from 'effector-react';
import { Title, Wrap, StyledPreloader, NoItemText, List } from './styles';
import { Subscription } from '../VipClub/Subscription';
import { useVipIntensivesQuery } from 'queries/catalog/useVipIntesivesQuery';
import { $intensivesData, useVIPClubSubscriptions } from 'models/catalog/model';
import { LargeCatalogItem } from '../../common/LargeCatalogItem';
import { useGetVIPClubSubscriptionsQuery } from 'queries/catalog/useGetVIPClubSubscriptionsQuery';

export const VipClubIntensives: React.FC = () => {
  const { isLoading } = useVipIntensivesQuery();
  const intensivesData = useStore($intensivesData);
  const subscriptionsQuery = useGetVIPClubSubscriptionsQuery();
  const subscriptionsData = useVIPClubSubscriptions();
  return (
    <Wrap>
      <Title>Встречи VIP-клуба</Title>
      {isLoading ? (
        <StyledPreloader />
      ) : intensivesData?.length ? (
        <List>
          {intensivesData.map(item => (
            <LargeCatalogItem
              itemType='intensive'
              disabled={!item.available}
              key={item.id}
              {...item}
              id={item.id.toString()}
            />
          ))}
        </List>
      ) : (
        <NoItemText>Интенсивов нет!</NoItemText>
      )}
      {subscriptionsQuery.isLoading ? (
        <StyledPreloader />
      ) : (
        <Subscription data={subscriptionsData} />
      )}
    </Wrap>
  );
};
