import React, { useMemo } from 'react';
import { useStore } from 'effector-react';
import { BookIcon, GoodsIcon } from 'icons/';
import { Path } from 'constants/paths';
import { CartItem } from './CartItem';
import Ruble from 'components/common/Ruble';
import { Button } from 'ui/Button/';
import {
  Bottom,
  Inner,
  Weight,
  TotalCount,
  TotalPrice,
  ButtonWrap,
  EmptyCartText,
  EmptyCartButtonWrap,
  StyledLink,
  StyledPreloader,
  Digit,
  StyledButtonPreloader,
} from './styles';
import { formatPriceToString } from 'utilities/formatPriceToString';
import { useCartQuery } from 'queries/cart/useCartQuery/useCartQuery';
import { useCreateOrderQuery } from 'queries/buy/useCreateOrderQuery';
import { showAuthModal } from 'models/auth';
import { $cart } from 'models/cart';
import { useUserProfile } from 'models/auth/model';
import { useHistory } from 'react-router-dom';

export const Cart: React.FC<{ changeToNextStep: () => void }> = ({
  changeToNextStep,
}) => {
  const { isLoading, isFetching } = useCartQuery();
  const history = useHistory();
  const OrderMutation = useCreateOrderQuery(order_id => {
    history.push(`${Path.buy}/${order_id}`);
    changeToNextStep();
  });
  const user = useUserProfile();
  const cartData = useStore($cart);
  const CartItemElements = useMemo(
    () =>
      cartData?.data.length
        ? cartData.data.map(item => (
            <CartItem key={item.product_id} {...item} />
          ))
        : null,
    [cartData]
  );

  const handleClick = () => {
    if (user) {
      OrderMutation.mutate();
    } else {
      showAuthModal();
    }
  };

  const totalWeight =
    cartData?.data.length &&
    cartData.data
      .map(item => item.weight)
      .reduce((acc, curr) => (acc || 0) + (curr || 0) || 0);

  return isLoading || isFetching ? (
    <StyledPreloader />
  ) : CartItemElements?.length ? (
    <>
      {CartItemElements}
      <Bottom>
        <Inner>
          {totalWeight ? (
            <Weight>
              Вес заказа
              <Digit>{totalWeight / 1000} кг</Digit>
            </Weight>
          ) : null}
          {/*TODO: Переделать подсчет не по товарным позициям, а по количество каждой позиции*/}
          <TotalCount>Итого {CartItemElements.length} товара</TotalCount>
          <TotalPrice>
            {cartData ? formatPriceToString(cartData.base_price) : '0'} {Ruble}
          </TotalPrice>
        </Inner>
        <ButtonWrap>
          {OrderMutation.isLoading ? (
            <StyledButtonPreloader />
          ) : (
            <Button disabled={!user} onClick={handleClick}>
              Оформить заказ
            </Button>
          )}
        </ButtonWrap>
      </Bottom>
    </>
  ) : (
    <>
      <EmptyCartText>
        Ваша корзина пуста. Вы можете приобрести наши книги и товары в
        соответствующих разделах
      </EmptyCartText>
      <EmptyCartButtonWrap>
        <StyledLink to={Path.catalogBooks}>
          <Button buttonType={'secondary'} icon={BookIcon}>
            Книги
          </Button>
        </StyledLink>
        <StyledLink to={Path.catalogGoods}>
          <Button buttonType={'secondary'} icon={GoodsIcon}>
            Товары
          </Button>
        </StyledLink>
      </EmptyCartButtonWrap>
    </>
  );
};
