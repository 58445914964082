import React, { memo } from 'react';
import {
  DeleteUploadIcon,
  FileIcon,
  HeadphonesIcon,
  PaperClipSmallIcon,
  VideoIcon,
} from 'icons/';
import {
  ResultFilesList,
  UploadDeleteButton,
  UploadInfo,
  UploadTitle,
  UploadImg,
  UploadFileIcon,
  UploadFile,
  AddUploadFileInfo,
  AddUploadFileTitle,
  AddUploadFileIcon,
  AddUploadFile,
  AddUploadFileInput,
} from './styles';

import {
  regExpAudio,
  regExpImage,
  regExpOnlyFilesHomeWork,
  regExpVideo,
} from 'constants/regExps';

import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import groupBy from 'lodash/groupBy';
import uniqueId from 'lodash/uniqueId';
import { FileListProps } from './types';
import { useUploadFile } from 'queries/lesson/useHomeworkQuery';

const FileListComponent: React.FC<FileListProps> = ({
  uploadedFiles,
  setUploadedFiles,
  isRevision,
}) => {
  const uploadFile = useUploadFile();

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;

    const { disallowFiles, allowFiles } = groupBy(
      Array.from(files),
      ({ name }) => {
        const ext = name.split('.').pop();
        // TODO: бекенд добавит txt в список поддерживаемых расширений
        return !ext || !regExpOnlyFilesHomeWork.test(ext)
          ? 'disallowFiles'
          : 'allowFiles';
      }
    );

    const disallowFileNames = disallowFiles?.map(({ name }) => name).join(', ');

    if (disallowFiles)
      toast.error(
        <ErrorMessage
          text={`Следующие расширения файлов не поддерживаются: ${disallowFileNames}`}
        />
      );

    if (!allowFiles) return;

    uploadFile.mutate(
      { files: allowFiles, isComment: isRevision },
      {
        onSuccess: ({ data: { file_id } }) => {
          const newFiles = file_id.reduce<
            {
              [key in string]: File;
            }
          >((acc, curr, i) => {
            acc[curr] = allowFiles[i];
            return acc;
          }, {});
          setUploadedFiles({ ...uploadedFiles, ...newFiles });
        },
      }
    );
    e.target.value = '';
  };

  const handleDeleteFile = (id: string) => {
    const newFiles: Record<string, File> = Object.assign({}, uploadedFiles);
    delete newFiles[id];
    setUploadedFiles({ ...newFiles });
  };

  const createFilePreview = (fileName: string) => {
    const ext = fileName.split('.').pop();
    if (!ext) return;
    if (regExpImage.test(ext)) return;
    if (regExpVideo.test(ext)) return VideoIcon;
    if (regExpAudio.test(ext)) return HeadphonesIcon;
    return FileIcon;
  };

  return (
    <ResultFilesList>
      {uploadedFiles &&
        Object.keys(uploadedFiles).map(key => {
          const fileIcon = createFilePreview(uploadedFiles[key].name);
          return (
            <UploadFile key={uniqueId()}>
              {fileIcon ? (
                <UploadFileIcon>{fileIcon}</UploadFileIcon>
              ) : (
                <UploadImg
                  src={URL.createObjectURL(uploadedFiles[key])}
                  alt={uploadedFiles[key].name}
                />
              )}
              <UploadTitle>{uploadedFiles[key].name}</UploadTitle>
              <UploadInfo>
                {Math.round(uploadedFiles[key].size / 1000)} Кб
              </UploadInfo>
              <UploadDeleteButton
                type='button'
                onClick={() => handleDeleteFile(key)}
              >
                {DeleteUploadIcon}
              </UploadDeleteButton>
            </UploadFile>
          );
        })}
      <AddUploadFile>
        <AddUploadFileTitle htmlFor='file'>
          <AddUploadFileIcon>{PaperClipSmallIcon}</AddUploadFileIcon>
          Загрузить файл
        </AddUploadFileTitle>
        <AddUploadFileInput
          type='file'
          id='file'
          onChange={handleChangeFile}
          multiple
        />
        <AddUploadFileInfo>(Не более 100 Мб)</AddUploadFileInfo>
      </AddUploadFile>
    </ResultFilesList>
  );
};

export const FileList = memo(FileListComponent);
