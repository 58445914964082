import { createEffect } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { $user, setUser } from 'models/auth';
import { UpdateUserRequest } from './types';

export const uploadAvatarFx = createEffect((blob: FormData) =>
  apiClient.post(Endpoint.UPLOAD_USER_AVATAR, blob)
);

export const updateUserProfileFx = createEffect((params: UpdateUserRequest) => {
  return apiClient.post(Endpoint.UPDATE_USER_PROFILE, params);
});

$user.on(setUser, (_, payload) => payload);
