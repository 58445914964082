import {
  useCoachingActiveSchool,
  useCoachingSchool,
  useTestimonialsSchool,
} from 'models/inner-insight/model';
import { useGetCoachingSchoolQuery } from 'queries/inner-insight/useGetCoachingSchoolQuery';
import React, { useEffect } from 'react';
import { Preloader } from 'ui/Preloader';
import { Banner } from 'components/Course/Banner';
import { Wrap, CourseCategory, CourseContent } from 'components/Course/styles';
import { SchoolProps } from './types';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export const School: React.FC<SchoolProps> = ({ match }) => {
  const school = useCoachingActiveSchool();
  const data = useCoachingSchool();
  const { isLoading, isFetching, isError, refetch } = useGetCoachingSchoolQuery(
    Number(match.params.id),
    school?.purchased
  );
  const testimonialsSchool = useTestimonialsSchool();

  const showPreloader = isLoading || isFetching;
  useScrollToTopOnMount();
  // TODO вернуть если понадобится, очистка школы пока не нужна
  // useEffect(() => {
  //   return () => {
  //     clearCoachingSchool();
  //   };
  // }, []);

  useEffect(() => {
    refetch();
  }, [match, refetch]);

  if (showPreloader) return <Preloader />;
  if (isError) return <div>Не удалось загрузить школу</div>;
  if (!data) return <div>Нет такой школы</div>;

  return (
    <>
      <Banner
        name={data.name}
        date_start={data.date_start}
        date_end={data.date_end}
        picture={data.picture}
        bg_color={'black'}
        // progress={school?.progress}
      />
      <Wrap>
        <CourseCategory textBackButton='Inner insight' />
        <CourseContent
          description={data.description}
          lectures={data.lectures}
          name={data.name}
          id={data.id}
          isSchool
          testimonials={testimonialsSchool}
        />
      </Wrap>
    </>
  );
};
