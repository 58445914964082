import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { getVIPChatListFx, setVIPChatList } from 'models/catalog/model';
import { VIPChatListItemType } from 'models/catalog/types';

export const useGetVIPChatsQuery: () => UseQueryResult<
  AxiosResponse<{ data: VIPChatListItemType[] }>,
  AxiosError<{ error: string }>
> = () =>
  useQuery('getVIPChats', getVIPChatListFx, {
    onSuccess: res => {
      setVIPChatList(res.data.data);
    },
  });
