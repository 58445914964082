import React, { useState } from 'react';
import { WeekCalendarProps } from './types';
import {
  WeekDays,
  Day,
  WeekDaysWrap,
  Cell,
  DayDate,
  EventLine,
} from './styles';
import { Link } from 'react-router-dom';
import { EventModal } from '../../MonthPage/MonthCalendar/EventModal';

export const WeekCalendar: React.FC<WeekCalendarProps> = ({
  selectedDay,
  days,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<{
    id: number;
    index: number;
  } | null>(null);

  const handleOpen = (id: number, index: number) => {
    if (id && (index || index === 0)) {
      setVisible(true);
      setCurrentEvent({ id, index });
    }
  };
  const handleClose = () => setVisible(false);

  return (
    <div {...rest}>
      {visible && !!currentEvent && (
        <EventModal
          data={
            days[currentEvent.index].events?.find(
              event => event.details.id === currentEvent.id
            )?.details
          }
          handleClose={handleClose}
        />
      )}
      <WeekDays>
        <Day>Пн</Day>
        <Day>Вт</Day>
        <Day>Ср</Day>
        <Day>Чт</Day>
        <Day>Пт</Day>
        <Day>Сб</Day>
        <Day>Вс</Day>
      </WeekDays>
      <WeekDaysWrap>
        {days.map((item, index) => (
          <Cell key={index}>
            {item.day && (
              <DayDate
                as={item.day ? Link : 'div'}
                to={item.day ? `${item.day}` : ''}
                selected={selectedDay === item.day}
              >
                {item.day}
              </DayDate>
            )}
            {item.events?.map((item, idx) => (
              <EventLine
                key={idx}
                {...item}
                onClick={() => handleOpen(item.details.id, index)}
              >
                {item.title}
              </EventLine>
            ))}
          </Cell>
        ))}
      </WeekDaysWrap>
    </div>
  );
};
