export enum MonthKey {
  'january' = 'Jan',
  'february' = 'Feb',
  'march' = 'Mar',
  'april' = 'Apr',
  'may' = 'May',
  'june' = 'Jun',
  'july' = 'Jul',
  'august' = 'Aug',
  'september' = 'Sep',
  'october' = 'Oct',
  'november' = 'Nov',
  'december' = 'Dec',
}

export enum MonthNumber {
  'january' = '01',
  'february' = '02',
  'march' = '03',
  'april' = '04',
  'may' = '05',
  'june' = '06',
  'july' = '07',
  'august' = '08',
  'september' = '09',
  'october' = '10',
  'november' = '11',
  'december' = '12',
}
