export const data = [
  {
    id: 1,
    title: 'Что включает в себя данная услуга?',
    text:
      'Закрытый чат Whatsapp длительностью 24 часа с Владимиром Барановым и Алиной Ермаковой с полным разбором вашей личной ситуации.',
  },
  {
    id: 2,
    title: 'Для кого предназначен такой чат?',
    text:
      'Для тех, чья проблема не терпит отлагательств, кому нужно принять срочное или жизненно важное решение, либо вопрос очень деликатный и требует полной приватности',
  },
  {
    id: 3,
    title: 'Можно обращаться с любой проблемой или только касающейся отношений?',
    text: 'С любой. Личные проблемы, отношения в семье, сложности с детьми, проблемы в бизнесе, финансовый «ступор» - все эти вопросы мы рассматриваем.',
  },
  {
    id: 4,
    title:
      'Можно ли задавать вопросы в аудио- или видеоформате?',
    text:
      'Нет, принимается только текстовая форма сообщений. Перед завершением чата проводится итоговый голосовой созвон.',
  },
  {
    id: 5,
    title: 'Время открытия чата можно выбрать на свое усмотрение?',
    text: 'Нет, чат для каждого клиента открывается в 12 часов по московскому времени и действует ровно сутки',
  },
  {
    id: 6,
    title: 'Насколько реально решить жизненно важную проблему человека всего за одни сутки?',
    text:
      'Вполне реально. Каждому его проблема кажется уникальной, но причины, как правило, у всех типовые, и со стороны их увидеть гораздо легче.',
  },
  {
    id: 7,
    title: 'Что я получу в результате?',
    text:
      'Вы выясните точные причины вашей ситуации и получите четкий пошаговый план для ее благополучного решения. Остальное зависит от Вас.',
  },
  {
    id: 8,
    title: 'Сколько стоит такая услуга?',
    text:
      'Стоимость составляет 100 000 руб. за сутки полноценной работы над вашей проблемой.',
  },
  {
    id: 9,
    title: 'Почему так дорого?',
    text:
      'К нам обращаются люди с действительно серьезными проблемами, решение которых имеет по факту огромную ценность и экономит им в результате куда больше денег. Не забывайте, что вы получаете высококвалифицированную помощь двух экспертов экстра-класса.',
  },
  {
    id: 10,
    title: 'Квартал членства в VIP-клубе стоит не намного больше. Может, выгоднее пойти со своей проблемой в клуб?',
    text:
      'Конечно, если ваша проблема не срочная, и вы готовы к ее разбору в общем чате с другими членами клуба – добро пожаловать. Особенность разборов в персональном VIP-чате – срочность и полнейшая конфиденциальность.',
  },
  {
    id: 10,
    title: 'Квартал членства в VIP-клубе стоит не намного больше. Может, выгоднее пойти со своей проблемой в клуб?',
    text:
      'Конечно, если ваша проблема не срочная, и вы готовы к ее разбору в общем чате с другими членами клуба – добро пожаловать. Особенность разборов в персональном VIP-чате – срочность и полнейшая конфиденциальность.',
  },
  {
    id: 11,
    title: 'Если в процессе обсуждения выяснится, что психическое состояние клиента отличается от нормального, и ему нужна помощь соответствующего специалиста, что тогда?',
    text:
      'Если мы определяем, что человеку требуется психиатр, то общение в чате  прекращается, чат закрывается, деньги возвращаются.',
  },
  {
    id: 12,
    title: 'Услуга доступна только женщинам или мужчинам тоже?',
    text:
      ' Для мужчин эта услуга особенно актуальна по причине полной конфиденциальности. Если для женщины обычно является нормой разобрать свою проблему в общем чате с другими девушками, то для мужчины это часто недопустимый вариант.',
  },
  {
    id: 13,
    title: 'Можно ли обратиться за помощью в персональном чате вдвоем с мужем/женой?',
    text:
      'Да, можно, но общение будет проводиться по отдельности.',
  },
  {
    id: 14,
    title: 'Стоимость услуги для пары выше, чем для одного человека?',
    text:
      'Верно, стоимость - как для двух разных людей, поскольку это будет два полноценных персональных чата.',
  },
  {
    id: 15,
    title: 'Сколько времени длится работа в чате для пары?',
    text:
      'По 24 часа для каждого партнера, затем предоставляется еще дополнительное время для подведения резюме (присутствуют оба участника). Таким образом, общее время – более двух суток.',
  },
  {
    id: 16,
    title: 'Как проходит разбор в персональном чате?',
    text:
      'Общение идет поочередно, разбирается личная история сначала одного супруга, затем другого. Каждому даются рекомендации для решения вопросов. В завершении производится общий итоговый созвон с обоими участниками.',
  },
  {
    id: 17,
    title: 'Существуют ли какие-то особые условия для получения услуги семейной парой?',
    text:
      'В первую очередь, максимальная честность – это очень важно для получения результата. Следует также сохранять полную конфиденциальность, не делиться друг с другом подробностями обсуждения и деталями полученных рекомендаций.',
  },
];
