import React, { useState } from 'react';
import {
  Top,
  Badge,
  BadgeText,
  Bottom,
  Text,
} from './styles';
import { regExpHTML } from 'constants/regExps';

export const Info: React.FC<{ detailText?: string }> = ({ detailText }) => {
  const [visiblePage, setVisiblePage] = useState(1);

  const renderPage = (id: number) => {
    switch (id) {
      case 1: {
        if (!detailText) {
          return <Text>Нет описания</Text>;
        }
        return (
          <div>
            {regExpHTML.test(detailText) ? (
              <Text dangerouslySetInnerHTML={{ __html: detailText }} />
            ) : (
              <Text>{detailText}</Text>
            )}
          </div>
        );
      }
      default:
        console.error(`Unknown state: '${id}'!`);
        return null;
    }
  };

  return (
    <>
      <Top>
        <Badge selected={visiblePage === 1} onClick={() => setVisiblePage(1)}>
          <BadgeText>Описание</BadgeText>
        </Badge>
        {/*<Badge selected={visiblePage === 2} onClick={() => setVisiblePage(2)}>*/}
        {/*  <BadgeText>F.A.Q</BadgeText>*/}
        {/*</Badge>*/}
      </Top>
      <Bottom>{renderPage(visiblePage)}</Bottom>
    </>
  );
};
