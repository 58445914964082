import Ruble from 'components/common/Ruble';
import { useStore } from 'effector-react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { TCourse } from 'models/courses/types';
import React, { useState } from 'react';
import { formatPriceToString } from 'utilities/formatPriceToString';
import {
  Options,
  Option,
  OptionRadio,
  OptionInner,
  OptionTitle,
  OptionText,
  OptionPrice,
  OptionPriceLimit,
  TariffsButton,
  OptionIcon,
  OptionWrap,
  OptionBonus,
  OptionPriceBonus,
  OptionPriceWrap,
} from '../styles';
import { Map, TOption } from '../types';
import { DateFormats } from 'constants/dateFormats';
import { useCreateCourseOrderQuery } from 'queries/courses/useCreateCourseOrderQuery';
import { VipFilledIcon } from 'icons';
import { showAuthModal } from 'models/auth/model';
import { $user} from 'models/auth';

const feeTypes: Map = {
  regular: 'Базовый',
  vip: 'VIP',
};

export const OptionTariffs: React.FC<Pick<TCourse, 'course_fee' | 'id'>> = ({
  course_fee = [],
  id,
}) => {
  const { mutate: purchase } = useCreateCourseOrderQuery();
  const [option, setOption] = useState<TOption>({
    price: course_fee[0].value,
    title: feeTypes[course_fee[0].type],
    checked: course_fee[0].id,
  });
  const isAuthed = useStore($user);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setOption({
      price: Number(target.getAttribute('data-price')),
      title: target.getAttribute('data-title'),
      checked: Number(target.value),
    });
  };

  const handleClick = () => {
    purchase({
      course_id: id,
      course_fee: Number(option.checked),
    });
  };

  return (
    <>
      <Options>
        {course_fee.map(({ id, type, value, description, date, bonus }) => (
          <Option key={id}>
            <OptionRadio
              radioSize='large'
              onChange={handleChange}
              checked={option.checked === id}
              value={id}
              data-price={value}
              data-title={feeTypes[type]}
            />
            <OptionInner>
              <OptionWrap>
                <OptionTitle>{feeTypes[type]}</OptionTitle>
                {feeTypes[type] === feeTypes.vip && (
                  <OptionIcon>{VipFilledIcon}</OptionIcon>
                )}
              </OptionWrap>
              <OptionText>{description}</OptionText>
            </OptionInner>
            <OptionPriceWrap>
              <OptionPriceBonus>
                <OptionBonus
                  status={'progress'}
                  size={'medium'}
                  text={`+${bonus} Б`}
                />
                <OptionPrice>
                  {formatPriceToString(value)} {Ruble}
                </OptionPrice>
              </OptionPriceBonus>
              <OptionPriceLimit>
                до{' '}
                {format(new Date(date), DateFormats['StartEventDate'], {
                  locale: ru,
                })}{' '}
                г.
              </OptionPriceLimit>
            </OptionPriceWrap>
          </Option>
        ))}
      </Options>

      <TariffsButton buttonType='primary' size='large' onClick={() => isAuthed ? handleClick() : showAuthModal()}>
        Купить
        {` «${option.title}» за ${formatPriceToString(option.price)} ${Ruble}`}
      </TariffsButton>
    </>
  );
};
