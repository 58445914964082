import styled from 'styled-components';
import { Announcement } from './Announcement';
import { Banner } from './Banner';
import { CourseContain } from './CourseContain';
import { Tariffs } from './Tariffs';
import { StudyPlan } from './StudyPlan';

export const StyledBanner = styled(Banner)`
  width: 100%;
  align-self: start;
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  height: 1px;
  width: 100%;
  margin: 40px 0;
`;

export const Wrap = styled.div`
  padding: 0 0 40px;
`;

export const StyledAnnouncement = styled(Announcement)`
  margin-top: 24px;
`;

export const StyledCourseContain = styled(CourseContain)`
  margin-top: 24px;
  max-width: calc(((100% + 24px) / 9) * 7 - 24px);
`;

export const StyledStudyPlan = styled(StudyPlan)``;

export const StyledTariffs = styled(Tariffs)``;
