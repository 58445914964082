import { getHomeworkFx, setHomework } from 'models/homework';
import {
  uploadCommentFx,
  uploadFileFx,
  uploadHomeworkFx,
  setComment,
  setComments,
} from 'models/homework/model';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { SuccessMessage } from 'ui/SuccessMessage';
import {
  HomeworkQuery,
  UploadComment,
  UploadFile,
  UploadHomework,
} from './types';

export const useHomeworkQuery: HomeworkQuery = id =>
  useQuery('homework', () => getHomeworkFx(id), {
    onSuccess: res => {
      const { comments, ...homework } = res.data.data;
      setHomework(homework);
      setComments(comments);
    },
    refetchOnWindowFocus: false,
  });

export const useUploadFile: UploadFile = () =>
  useMutation(
    'uploadFile',
    ({ files, isComment }) => {
      const formData = new FormData();
      files.forEach(file => formData.append('file[]', file));
      return uploadFileFx({ formData, isComment });
    },
    {
      onSuccess: () => {
        toast.success(<SuccessMessage text='Файлы успешно загружены' />);
      },
    }
  );

export const useUploadHomework: UploadHomework = () =>
  useMutation('uploadHomework', data => uploadHomeworkFx(data), {
    onSuccess: res => {
      setHomework(res.data.data);
      toast.success(
        <SuccessMessage text='Домашнее задание успешно отправлено' />
      );
    },
  });

export const useUploadComment: UploadComment = () =>
  useMutation('uploadComment', data => uploadCommentFx(data), {
    onSuccess: res => {
      setComment(res.data.data);
      toast.success(<SuccessMessage text='Комментарий успешно отправлен' />);
    },
  });
