import { CheckDeliveredIcon, SmallLogoIcon } from 'icons';
import { useChatMessages } from 'models/chat/model';
import { useChatGetMessagesQuery } from 'queries/chat/useChatGetMessagesQuery';
import { useChatGetNewMessagesQuery } from 'queries/chat/useChatGetMessagesQuery/useChatGetMessagesQuery';
import React from 'react';
import { NoData } from '../NoData';
import {
  Avatar,
  CheckIcon,
  Date as StyledDate,
  Message,
  Spinner,
  Text,
  TextWrap,
  Time,
  Wrap,
} from './styles';
import { ru } from 'date-fns/locale';
import { format } from 'date-fns';
import { DateFormats } from 'constants/dateFormats';
import { formatRelative } from 'date-fns';
import { useScrollIntoView } from 'hooks/useScrollIntoView';
import { TMessage } from 'models/chat/types';

const formatRelativeLocale = {
  lastWeek: 'неделю назад',
  yesterday: 'вчера',
  today: 'сегодня',
  other: DateFormats.FullDateAsDigits, // Difference: Add time to the date
};
const locale = {
  ...ru,
  formatRelative: (token: keyof typeof formatRelativeLocale) =>
    formatRelativeLocale[token],
};

const humanizedDate = (prev: TMessage | undefined, curr: TMessage) => {
  if (
    prev &&
    formatRelative(new Date(prev.date.date), new Date(), { locale }) ===
      formatRelative(new Date(curr.date.date), new Date(), { locale })
  ) {
    return null;
  }
  return (
    <StyledDate>
      {formatRelative(new Date(curr.date.date), new Date(), { locale })}
    </StyledDate>
  );
};

export const Messages: React.FC = () => {
  const messages = useChatMessages();

  const { isLoading, isError } = useChatGetMessagesQuery();
  useChatGetNewMessagesQuery(messages[messages.length - 1]?.id);

  const messagesEndRef = useScrollIntoView(messages);

  return (
    <Wrap>
      {isLoading ? (
        <Spinner />
      ) : messages.length ? (
        messages.map(({ id, text, isUserMessage, date, unread }, index) => (
          <React.Fragment key={id}>
            {humanizedDate(messages[index - 1], messages[index])}
            <Message ref={messagesEndRef} isUserMessage={isUserMessage}>
              {!isUserMessage && <Avatar>{SmallLogoIcon}</Avatar>}
              <TextWrap>
                <Text>{text}</Text>
                <Time>
                  {format(new Date(date.date), DateFormats.HourAndMinutes, {
                    locale: ru,
                  })}
                </Time>
                {isUserMessage && !unread && (
                  <CheckIcon>{CheckDeliveredIcon}</CheckIcon>
                )}
              </TextWrap>
            </Message>
          </React.Fragment>
        ))
      ) : isError ? (
        <NoData
          title='Произошла ошибка'
          description='Не удалось загрузить чат'
        />
      ) : (
        <NoData />
      )}
    </Wrap>
  );
};
