import { useStore } from 'effector-react';
import { $orders, ordersFx, setOrders } from 'models/orders/model';
import { TOrderItem } from 'models/orders/types';
import { useQuery } from 'react-query';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useOrderListQuery = (offset: number) => {
  const orders = useStore($orders);
  const { data, ...rest } = useQuery(
    ['orders', offset],
    () => ordersFx(offset),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setOrders([...orders, ...data.data.data]);
      },
    }
  );

  return { data: data?.data.data as TOrderItem[], ...rest };
};
