import React from 'react';
import {
  Wrap,
  Title,
  Inner,
  Item,
  ItemTitle,
  ItemText,
  List,
  ListItem,
  ItemImage,
} from './styles';
import gold from 'images/gold.png';
import silver from 'images/silver.png';

export const Statuses: React.FC = props => {
  return (
    <Wrap {...props}>
      <Title>Повышайте статус</Title>
      <Inner>
        <Item>
          <ItemImage src={silver} />
          <ItemTitle>Серебро</ItemTitle>
          <ItemText>При вступлении в клуб с поквартальным взносом</ItemText>
          <List>
            <ListItem>Чат поддержки 24/7</ListItem>
            <ListItem>Право участия в выездных мероприятиях</ListItem>
            <ListItem>
              Доступ ко всем записям и материалам оплаченного периода
            </ListItem>
          </List>
        </Item>
        <Item>
          <ItemImage src={gold} />
          <ItemTitle>Золото</ItemTitle>
          <ItemText>
            После годового пребывания либо при единовременной оплате годового
            членства
          </ItemText>
          <List>
            <ListItem>Все права статуса СЕРЕБРО</ListItem>
            <ListItem>
              Неограниченный доступ к записям и онлайн-материалам: «Я люблю
              себя», «Волшебная таблетка счастья», «Быть с мужиком. Как?», «Мама
              2.0», «Как разбудить вожака? Шаг 1»
            </ListItem>
            <ListItem>Разбор по методу КУБ (1 раз в год)</ListItem>
            <ListItem>
              Разбор по методу СУПЕРКУБ (неограниченное количество раз, но
              только на ВЫЕЗДНОМ ИНТЕНСИВЕ!)
            </ListItem>
          </List>
        </Item>
      </Inner>
    </Wrap>
  );
};
