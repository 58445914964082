import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router-dom';
import { MonthKey } from 'constants/monthKey';
import { ru } from 'date-fns/locale';
import { Path } from 'constants/paths';
import { MonthsType } from '../common/types';
import { MonthPageWrap, Miniature, Content, Week, Day } from './styles';
import { useEventsInDay } from 'models/calendar/model';

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const DayPage: React.FC<
  RouteComponentProps<{ year: string; month: MonthsType; day: string }>
> = ({ history, match }) => {
  const { year, month, day } = match.params;
  const currentDate = useMemo(
    () => new Date(`${year} ${MonthKey[month]} ${day}`),
    [year, month, day]
  );
  //Общий массив ячеек месяца
  const { monthDaysArray, weekDaysArray } = useEventsInDay(currentDate);

  //Функция перелистывания месячной миниатюры
  const handleMonthChange = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      if (month === 'december') {
        history.push(
          `${Path.calendar}/${parseInt(year) + 1}${Path.month}/january/${day}`
        );
      } else {
        const currentMonthIndex = months.findIndex(item => item === month);
        const nextMonth = months[currentMonthIndex + 1];
        history.push(
          `${Path.calendar}/${year}${Path.month}/${nextMonth}/${day}`
        );
      }
    } else if (direction === 'prev') {
      if (month === 'january') {
        history.push(
          `${Path.calendar}/${parseInt(year) - 1}${Path.month}/december/${day}`
        );
      } else {
        const currentMonthIndex = months.findIndex(item => item === month);
        const prevMonth = months[currentMonthIndex - 1];
        history.push(
          `${Path.calendar}/${year}${Path.month}/${prevMonth}/${day}`
        );
      }
    }
  };

  return (
    <MonthPageWrap>
      <Miniature
        days={monthDaysArray}
        title={format(currentDate, 'LLLL', { locale: ru })}
        handleMonthChange={handleMonthChange}
      />
      <Content>
        <Week selectedDay={day} days={weekDaysArray} />
        <Day currentDayDate={currentDate} />
      </Content>
    </MonthPageWrap>
  );
};
