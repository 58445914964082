import { useQuery, UseQueryResult } from 'react-query';
import { Endpoint } from 'constants/endpoints';
import { apiClient } from 'api/apiClient';
import { TDiplomasResponse } from './types';
import { AxiosError, AxiosResponse } from 'axios';

export const useDiplomasQuery: () => UseQueryResult<
  AxiosResponse<{ data?: TDiplomasResponse }>,
  AxiosError<{ error: string }>
> = () =>
  useQuery<
    void,
    AxiosError<{ error: string }>,
    AxiosResponse<{ data?: TDiplomasResponse }>
  >(['diplomas'], () => apiClient.get(Endpoint.GET_DIPLOMAS), {
    refetchOnWindowFocus: false,
    retry: false,
  });
