import {
  getCalendarCategoriesFx,
  setCalendarCategories,
} from 'models/calendar/model';
import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CalendarCategories } from 'models/calendar/types';

type getCalendarCategoriesQueryType = () => UseQueryResult<
  AxiosResponse<{ data: CalendarCategories }>,
  AxiosError<{ error: string }>
>;

export const useGetCalendarCategoriesQuery: getCalendarCategoriesQueryType = () =>
  useQuery('getCalendarCategories', () => getCalendarCategoriesFx(), {
    onSuccess: res => {
      setCalendarCategories(res.data.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
