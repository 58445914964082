import React from 'react';

export const VebinarsIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.64611 1.33875C3.88997 1.20823 4.18588 1.22254 4.41603 1.37596L14.916 8.37596C15.1247 8.51506 15.25 8.74924 15.25 9C15.25 9.25077 15.1247 9.48494 14.916 9.62404L4.41603 16.624C4.18588 16.7775 3.88997 16.7918 3.64611 16.6613C3.40224 16.5307 3.25 16.2766 3.25 16V2C3.25 1.7234 3.40224 1.46926 3.64611 1.33875ZM4.75 3.40139V14.5986L13.1479 9L4.75 3.40139Z'
    />
  </svg>
);
