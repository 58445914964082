import React from 'react';
import { Title, List, ListItem, ListItemText } from './styles';

export const CourseContain: React.FC = props => {
  return (
    <div {...props}>
      <Title>В программу входят:</Title>
      <List>
        <ListItem>
          <ListItemText>Теоретический материал - 20 видеозаписей.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Еженедельные онлайн-беседы с Владимиром Барановым
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Для удобного обмена информацией, решения организационных и
            технических вопросов создан чат в мессенджере WhatsApp.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Cтоимость - 250 000 руб.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Возможна оплата в рассрочку. Схема рассрочки: 50% сейчас, еще 50% в
            любое время до 15 августа 2021г.
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
};
