import React, { useMemo, useState } from 'react';
import { usePlansQuery } from 'queries/education/useMyEducationQuery';
import { usePlansData } from 'models/education/model';
import { List, NoData, StyledPreloader, StyledTabs } from './styles';
import { RouteComponentProps } from 'react-router';
import { CoursesPlansItem } from '../common/CoursesPlansItem';
import { Title } from '../../Title';
import { MasterClassItem } from '../common/MasterClassItem';
import { Link } from 'react-router-dom';
import { Path } from 'constants/paths';
import { Button } from 'ui/Button';
import { Inner } from './styles';

const tabNames = ['Все', 'Активные', 'Завершенные'];

export const CoachSchool: React.FC<RouteComponentProps<{ key: string }>> = ({
  match,
}) => {
  const query = usePlansQuery(match.params.key);
  const data = usePlansData();

  const [selectedTab, setSelectedTab] = useState(0);
  const coach_school = useMemo(() => {
    if (!data) return [];
    const futureEvents = data.coach_school?.future || [];
    const pastEvents = data.coach_school?.past || [];
    return selectedTab === 1
      ? futureEvents
      : selectedTab === 2
      ? pastEvents
      : futureEvents.concat(pastEvents);
  }, [data, selectedTab]);

  const master_classes = useMemo(() => {
    if (!data) return [];
    const futureEvents = data.master_classes?.future || [];
    const pastEvents = data.master_classes?.past || [];

    return selectedTab === 1
      ? futureEvents
      : selectedTab === 2
      ? pastEvents
      : futureEvents.concat(pastEvents);
  }, [data, selectedTab]);

  if (query.isLoading || query.isFetching) return <StyledPreloader />;
  if (!data && !query.isLoading)
    return <NoData>У вас нет курсов в процессе</NoData>;

  return (
    <div>
      <StyledTabs
        setSelected={setSelectedTab}
        selected={selectedTab}
        tabNames={tabNames}
      />
      <List>
        {coach_school.length ? (
          coach_school.map((item, index) => (
            <CoursesPlansItem key={index} {...item} coachSchool />
          ))
        ) : (
          <Inner>
            <NoData>
              У вас нет школ коучинга. Вы можете начать обучение в любое время.
            </NoData>
            <Link to={Path.catalogInnerInsight}>
              <Button>Выбрать школу коучинга</Button>
            </Link>
          </Inner>
        )}

        {master_classes.length ? (
          <>
            <Title>Мастер классы</Title>

            {master_classes.map((item, index) => (
              <MasterClassItem key={index} {...item} />
            ))}
          </>
        ) : null}
      </List>
    </div>
  );
};
