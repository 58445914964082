import { useStore } from 'effector-react';
import {
  $course,
  clearCourse,
  useCourseTestimonials,
} from 'models/courses/model';
import { useCourseQuery } from 'queries/useCourseQuery/';
import { useCoursesQuery } from 'queries/useCoursesQuery';
import React, { useEffect } from 'react';
import { Preloader } from 'ui/Preloader';
import { Banner } from './Banner/';
import { Wrap, CourseCategory, CourseContent, NoData } from './styles';
import { CourseProps } from './types';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export const Course: React.FC<CourseProps> = ({ match }) => {
  const { isLoading: isLoadingCourses } = useCoursesQuery();
  const { isLoading, isFetching, isError, refetch } = useCourseQuery(
    Number(match.params.id)
  );
  const data = useStore($course);
  const testimonials = useCourseTestimonials();
  const showPreloader = isLoading || isFetching || isLoadingCourses;
  useScrollToTopOnMount();
  useEffect(() => {
    return () => {
      clearCourse();
    };
  }, []);

  // запрашиваем новые данные, если адрес сменился
  useEffect(() => {
    refetch();
  }, [match, refetch]);

  if (showPreloader) return <Preloader />;
  if (!data) return <NoData>Нет данных</NoData>;
  if (isError) return <NoData>Не удалось загрузить саморазвития</NoData>;

  return (
    <>
      <Banner
        name={data.name}
        date_start={data.date_start}
        date_end={data.date_end}
        picture={data.picture}
        progress={data.progress}
        active={data.active}
        bg_color={data.bg_color}
      />
      <Wrap>
        <CourseCategory
          course_fee={data.course_fee}
          trailer={data.trailer}
          textBackButton='К Саморазвитию'
          isCourse
        />
        <CourseContent
          description={data.description}
          course_fee={data.course_fee}
          lectures={data.lectures}
          additionals={data.additionals}
          name={data.name}
          active={data.active}
          id={data.id}
          trailer={data.trailer}
          testimonials={testimonials}
          renewal={data.renewal}
        />
      </Wrap>
    </>
  );
};
