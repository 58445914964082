import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 500px;
`;

export const TextSC = styled.div`
  ${({ theme }) => theme.typography.bodyText1};
  margin-bottom: 24px;
  line-height: 160%;
`;
