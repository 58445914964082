import React from 'react';
import {
  CloseButton,
  Header,
  Separator,
  Status,
  Title,
  Wrap,
  Footer,
} from './styles';
import { XCloseIcon } from 'icons';
import { theme } from 'ui/theme';
import { Form } from './Form';
import { Messages } from './Messages';
import { setShowChat, useShowChat } from 'models/chat/model';

export const Chat: React.FC = () => {
  const isOpenChat = useShowChat();

  if (!isOpenChat) return null;

  return (
    <Wrap>
      <Header>
        <Title>Админ сайта БСМ</Title>
        <Status />
        <CloseButton
          size={'medium'}
          buttonType={'link'}
          onClick={() => setShowChat(false)}
          iconFill={theme.colors.disabledColor}
          icon={XCloseIcon}
        />
      </Header>
      <Separator />
      <Messages />
      <Separator />
      <Footer>
        <Form />
      </Footer>
    </Wrap>
  );
};
