import React from 'react';
import Ruble from 'components/common/Ruble';
import { formatPriceToString } from 'utilities/formatPriceToString';
import {
  Wrap,
  Price,
  PriceDigit,
  Title,
  Inner,
  NoPhoto,
  NoPhotoText,
  Photo,
} from './styles';

import { CartItem } from 'models/cart/types';
import { baseUrl } from 'api/apiClient';

export const PaymentLot: React.FC<CartItem> = ({
  name,
  price,
  image,
  ...rest
}) => {
  return (
    <Wrap {...rest}>
      {image ? (
        <Photo src={`${baseUrl}${image}`} alt={name} />
      ) : (
        <NoPhoto>
          <NoPhotoText>{formatPriceToString(price)}</NoPhotoText>
        </NoPhoto>
      )}
      <Inner>
        {/*TODO: Непонятно что с тегами с бэка*/}
        {/*<TagsWrap>*/}
        {/*  {tags.map((tag: TagProps, idx) => (*/}
        {/*    <TagItem*/}
        {/*      key={idx}*/}
        {/*      status={tag.status}*/}
        {/*      ghost={tag.ghost}*/}
        {/*      text={tag.text}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</TagsWrap>*/}
        <Title>{name}</Title>
        <Price>
          <PriceDigit>
            {formatPriceToString(price)} {Ruble}
          </PriceDigit>
          {/*TODO: Наименования тарифов не приходят с бека*/}
          {/*<PriceText>{quantity}</PriceText>*/}
        </Price>
      </Inner>
    </Wrap>
  );
};
