import React from 'react';
import { StyledForm, SubTitle, Title, Item, Divider } from './styles';
import { Field, Form } from 'react-final-form';
import { useUserProfile } from 'models/auth/model';
import { EmailCheckProps } from './types';

export const EmailCheck: React.FC<EmailCheckProps> = ({
  className,
  handleSubmit,
  setButtonHandler,
}) => {
  const userProfile = useUserProfile();
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        email: userProfile?.email,
      }}
      render={({ handleSubmit }) => {
        setButtonHandler(handleSubmit);
        return (
          <StyledForm onSubmit={handleSubmit} className={className}>
            <Title>E-mail для чека</Title>
            <SubTitle>
              Мы отправим вам электронный чек на электронную почту, указанную в
              профиле{' '}
            </SubTitle>
            <Field
              defaultValue={userProfile?.email}
              labelWidth={144}
              inputWidth={340}
              label='E-mail'
              name='email'
              alignLabel='left'
              type='email'
              placeholder='Введите e-mail'
              component={Item}
            />
            <Divider />
          </StyledForm>
        );
      }}
    />
  );
};
