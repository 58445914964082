import { useMutation } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Path } from 'constants/paths';
import { CreateSchoolOrderQuery } from './types';
import { SchoolPurchaseStatus } from 'models/inner-insight/types';

export const useCreateSchoolOrderQuery: CreateSchoolOrderQuery = () => {
  const history = useHistory();
  return useMutation(
    'schoolOrder',
    ({ purchaseStatus, ...data }) => {
      const endpoint =
        purchaseStatus === SchoolPurchaseStatus.PREPAID
          ? `${Endpoint.PURCHASE_COACHING_SCHOOL_SECOND_PART}/${data.school_id}`
          : Endpoint.PURCHASE_COACHING_SCHOOL;
      return apiClient.post(endpoint, data);
    },
    {
      onSuccess: res => {
        history.push(
          Path.directBuy.replace(':key', 'school') + '/' + res.data.order_id
        );
      },
      onError: res => {
        toast.error(<ErrorMessage text={res.response?.data.error} />);
      },
    }
  );
};
