import styled from 'styled-components';

export const ResultFilesList = styled.div`
  margin: 16px 0 0;
`;

export const UploadDeleteButton = styled.button`
  font-size: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
`;

export const UploadInfo = styled.p`
  margin: 0;
  flex-shrink: 0;
  ${({ theme }) =>
    `color: ${theme.colors.thirdTextAndIconColor}; ${theme.typography.bodyText2}`};
  & + ${UploadDeleteButton} {
    margin-left: 8px;
  }
`;
export const UploadTitle = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  font-weight: bold;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  & + ${UploadInfo} {
    margin-left: 8px;
  }
`;

export const UploadImg = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  object-fit: cover;
  & + ${UploadTitle} {
    margin-left: 10px;
  }
`;

export const UploadFileIcon = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  padding: 7px;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  & + ${UploadTitle} {
    margin-left: 10px;
  }
`;

export const UploadFile = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin: 10px 0 0;
  }
`;

export const AddUploadFileInfo = styled.p`
  margin: 0 0 0 auto;
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const AddUploadFileTitle = styled.label`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 0;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  & + ${AddUploadFileInfo} {
    margin-left: auto;
  }
`;

export const AddUploadFileIcon = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  padding: 7px;
  margin-right: 3px;
`;

export const AddUploadFile = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  padding: 7px 16px;
  border-radius: 14px;
  margin: 16px 0 0;
`;

export const AddUploadFileInput = styled.input`
  display: none;
`;
