import React from 'react';
import styled from 'styled-components';
import { Banner } from './Banner';
import { Features } from './Features';
import { MiniBanner } from './MiniBanner';
import { Statuses } from './Statuses';
import { Subscription } from './Subscription';

const Wrap = styled.div`
  width: 100%;
  margin: 0 0 40px;
  padding: 0 12px 48px;
  align-self: flex-start;
`;

export const VipClub: React.FC = props => {
  return (
    <Wrap {...props}>
      <Banner />
      <Features />
      <MiniBanner />
      <Statuses />
      <Subscription />
    </Wrap>
  );
};
