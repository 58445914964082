import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SidebarLessonItem } from './SidebarLessonItem';
import { useStore } from 'effector-react';
import { $course } from 'models/courses/model';
import { useParams } from 'react-router';
import { useCourseQuery } from 'queries/useCourseQuery';
import { Preloader } from 'ui/Preloader';
import { isAfter } from 'date-fns';
import { Params } from './types';
import { useCoachingSchool } from 'models/inner-insight/model';

const Wrap = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Sidebar: React.FC<{ isSchool?: boolean }> = props => {
  const { courseId, lessonId } = useParams<Params>();
  const { isLoading, isFetching, isError, refetch } = useCourseQuery(
    Number(courseId)
  );
  const course = useStore($course);
  const isSchool = props.isSchool;
  const school = useCoachingSchool();

  const showPreloader = !isSchool && (isLoading || isFetching);

  useEffect(() => {
    refetch();
  }, [courseId, refetch]);

  if (showPreloader) return <Preloader />;
  if (!isSchool && !course) return <div>Нет данных</div>;
  if (!isSchool && isError) return <div>Не удалось загрузить лекции</div>;

  const coachingLectures = school?.lectures.filter(
    lecture =>
      lecture.activity &&
      lecture.date_start &&
      isAfter(new Date(), new Date(lecture.date_start))
  );
  const courseLectures = course?.lectures.filter(
    lecture =>
      lecture.activity &&
      lecture.date_start &&
      isAfter(new Date(), new Date(lecture.date_start))
  );

  return (
    <Wrap {...props}>
      {isSchool
        ? coachingLectures &&
          coachingLectures.map(item => (
            <SidebarLessonItem
              isSchool
              key={item.id}
              active={item.id === Number(lessonId)}
              courseId={courseId}
              {...item}
            />
          ))
        : courseLectures &&
          courseLectures.map(item => (
            <SidebarLessonItem
              key={item.id}
              active={item.id === Number(lessonId)}
              courseId={courseId}
              {...item}
            />
          ))}
    </Wrap>
  );
};
