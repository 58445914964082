import { createEffect, createEvent, restore } from 'effector';
import { AxiosError, AxiosResponse } from 'axios';
import { LessonRequest, LessonStore } from './types';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';

// Эффекты
export const getLessonFx = createEffect<
  LessonRequest,
  AxiosResponse<{ data: LessonStore }>,
  AxiosError<{ error: string }>
>(async ({ courseId, lessonId, isSchool }) =>
  isSchool
    ? apiClient.get(
        Endpoint.GET_COACHING_LESSONS.replace('{courseId}', courseId) + lessonId
      )
    : apiClient.get(
        Endpoint.GET_LESSON.replace('{courseId}', courseId) + lessonId
      )
);

// События
export const setLesson = createEvent<LessonStore>();
export const $lesson = restore(setLesson, null);
