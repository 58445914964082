import { useMutation, UseMutationResult } from 'react-query';
import { purchaseSecretChatFx } from 'models/catalog/model';
import { AxiosError, AxiosResponse } from 'axios';

export const usePurchaseSecretChatQuery: () => UseMutationResult<
  AxiosResponse<{ order_id: number }>,
  AxiosError<{
    error: string;
    details: {
      file: string;
      line: number;
      message: string;
    };
  }>,
  number
> = () => useMutation('purchaseVIPChat', id => purchaseSecretChatFx(id));
