import React from 'react';
import Rating from '../Rating/Rating';
import { Wrap, Title, Inner, Date as DateStyled, Top } from './styles';
import { format, getYear } from 'date-fns';
import { ru } from 'date-fns/locale';
import { TBanner } from './types';
import { ProgressBar } from 'ui/ProgressBar';
import { baseUrl } from 'api/apiClient';
import { TCourseStatuses } from 'models/courses/types';

export const Banner: React.FC<TBanner> = ({
  name,
  date_start,
  date_end,
  picture,
  progress,
  active,
  ...rest
}) => {
  return (
    <Wrap picture={baseUrl + picture} {...rest}>
      <Inner>
        <Top>
          <Rating />
        </Top>
        <DateStyled>
          {format(new Date(date_start), 'LLLL', { locale: ru })}-
          {format(new Date(date_end), 'LLLL', { locale: ru })}{' '}
          {getYear(new Date(date_end))} года
        </DateStyled>
        <Title>{name}</Title>
        <ProgressBar
          progress={progress}
          active={active?.status === TCourseStatuses.IN_PROGRESS}
        />
      </Inner>
    </Wrap>
  );
};
