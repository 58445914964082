import {
  useCoachingActiveSchool,
  useCoachingSchool,
  useCoachingSchools,
} from 'models/inner-insight/model';
import { TCoachingSchool, TCoachingSchools } from 'models/inner-insight/types';
import { useGetCoachingSchoolQuery } from 'queries/inner-insight/useGetCoachingSchoolQuery';
import { useGetCoachingSchoolsQuery } from 'queries/inner-insight/useGetCoachingSchoolsQuery';

type FetchSchools = () => {
  isLoading: boolean;
  isError: boolean;
  school: TCoachingSchool | null;
  schools: TCoachingSchools[];
};

export const useFetchSchools: FetchSchools = () => {
  const {
    isLoading: isLoadingSchools,
    isFetching: isFetchingSchools,
    isError: isErrorSchools,
  } = useGetCoachingSchoolsQuery();

  const { id } = useCoachingActiveSchool() ?? {};
  const {
    isLoading: isLoadingSchool,
    isFetching: isFetchingSchool,
    isError: isErrorSchool,
  } = useGetCoachingSchoolQuery(id);

  const school = useCoachingSchool();
  const schools = useCoachingSchools();

  const isLoading =
    isLoadingSchools ||
    isFetchingSchools ||
    isLoadingSchool ||
    isFetchingSchool;

  const isError = isErrorSchools || isErrorSchool;

  return {
    isLoading,
    isError,
    school,
    schools,
  };
};
