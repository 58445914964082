import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { ButtonProps } from './types';

const ButtonIcon = styled.span<ButtonProps>`
  font-size: 0;
  transition: all 0.3s;
`;

const ButtonText = styled.span`
  font-size: 16px;
  line-height: 22px;
  transition: all 0.3s;
  font-weight: bold;
  & + ${ButtonIcon} {
    margin-left: 10px;
  }
`;

const ButtonWrap = styled.button<
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>`
  & ${ButtonIcon} + ${ButtonText}, & ${ButtonText} + ${ButtonIcon} {
    margin-left: ${({ marginIcon }) => marginIcon ?? '10px'};
  }
  border-radius: 14px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  margin: 0;
  outline: none;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s;
  color: ${({ buttonType, disabled, theme }) => {
    switch (buttonType) {
      case 'primary':
        return disabled ? theme.colors.mainBgColor : theme.colors.mainColor;
      case 'secondary':
      case 'link':
        return disabled ? theme.colors.disabledColor : theme.colors.mainColor;
    }
  }};
  padding: ${({ buttonType, theme, size }) => {
    switch (buttonType) {
      case 'primary':
        return size === 'large'
          ? theme.button.primary.padding.large
          : theme.button.primary.padding.medium;
      case 'secondary':
        return size === 'large'
          ? theme.button.secondary.padding.large
          : theme.button.secondary.padding.medium;
      case 'link':
        return theme.button.link.padding;
    }
  }};
  flex-direction: ${({ iconAlign }) =>
    iconAlign === 'right' ? 'row-reverse' : 'row'};
  border: ${({ buttonType, theme }) =>
    buttonType === 'secondary'
      ? `2px solid ${theme.colors.thirdBgColor}`
      : 'none'};
  background-color: ${props =>
    props.buttonType === 'primary'
      ? props.disabled
        ? props.theme.colors.secondColor
        : props.theme.colors.actionColor
      : 'transparent'};

  &:focus {
    outline: none;
    ${({ disabled, theme }) =>
      !disabled ? `box-shadow: 0 0 0 2px ${theme.colors.thirdBgColor};` : null}
  }

  &:active {
    color: ${({ buttonType, disabled, theme }) => {
      switch (buttonType) {
        case 'primary':
          return disabled ? theme.colors.mainBgColor : theme.colors.mainBgColor;
        case 'secondary':
          return disabled
            ? theme.colors.disabledColor
            : theme.colors.mainBgColor;
        case 'link':
          return disabled ? theme.colors.disabledColor : theme.colors.mainColor;
      }
    }};
    background-color: ${props => {
      switch (props.buttonType) {
        case 'primary':
          return props.disabled
            ? props.theme.colors.secondColor
            : props.theme.colors.mainColor;
        case 'secondary':
          return props.disabled ? 'transparent' : props.theme.colors.mainColor;
        case 'link':
          return 'transparent';
      }
    }};
    transition: all 0.3s;
    ${props =>
      props.buttonType === 'secondary' &&
      !props.disabled &&
      `border: 2px solid ${props.theme.colors.mainColor};`}
  }
  ${props =>
    props.icon &&
    `& > ${ButtonIcon} {
            transition: all 0.3s;
            fill: ${
              !props.iconFill ? props.theme.colors.mainBgColor : props.iconFill
            };
          }`};

  & > ${ButtonIcon} {
    fill: ${props => {
      if (!props.iconFill) {
        return props.theme.colors.mainColor;
      } else {
        return props.iconFill;
      }
    }};
  }

  &:hover {
    transition: all 0.3s;
    svg {
      transition: fill 0.3s;
      fill: ${props => props.hoverColor};
    }
    background-color: ${props => {
      switch (props.buttonType) {
        case 'primary':
          return props.disabled
            ? props.theme.colors.secondColor
            : props.theme.colors.mainColor;
        case 'secondary':
          return props.disabled ? 'transparent' : props.theme.colors.mainColor;
        case 'link':
          return 'transparent';
      }
    }};

    & > ${ButtonIcon} {
      fill: ${props => {
        if (!props.iconFill) {
          switch (props.buttonType) {
            case 'primary':
              return props.disabled
                ? props.theme.colors.mainBgColor
                : props.theme.colors.mainBgColor;
            case 'secondary':
              return props.disabled
                ? props.theme.colors.disabledColor
                : props.theme.colors.mainBgColor;
            case 'link':
              return props.disabled
                ? props.theme.colors.disabledColor
                : props.theme.colors.secondTextColor;
          }
        } else {
          return props.iconFill + '80';
        }
      }};
    }

    ${props =>
      props.buttonType === 'secondary' &&
      !props.disabled &&
      `border: 2px solid ${props.theme.colors.mainColor};`};
    & > ${ButtonText} {
      transition: all 0.3s;
      color: ${props => {
        switch (props.buttonType) {
          case 'primary':
            return props.disabled
              ? props.theme.colors.mainBgColor
              : props.theme.colors.mainBgColor;
          case 'secondary':
            return props.disabled
              ? props.theme.colors.disabledColor
              : props.theme.colors.mainBgColor;
          case 'link':
            return props.disabled
              ? props.theme.colors.disabledColor
              : props.theme.colors.secondTextColor;
        }
      }};
    }
  }

  & ${ButtonIcon} {
    ${({ iconFill }) => (iconFill ? `fill: ${iconFill}` : null)}
  }
`;

const Button: React.FC<
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  buttonType = 'primary',
  icon,
  label,
  children,
  type = 'button',
  iconAlign = 'left',
  iconFill,
  ...props
}) => {
  const btnText = label || children;
  return (
    <ButtonWrap
      buttonType={buttonType}
      icon={icon}
      type={type}
      iconFill={iconFill}
      {...props}
    >
      {iconAlign === 'left' ? (
        <>
          {icon ? <ButtonIcon>{icon}</ButtonIcon> : null}
          {btnText ? <ButtonText>{btnText}</ButtonText> : null}
        </>
      ) : (
        <>
          {btnText ? <ButtonText>{btnText}</ButtonText> : null}
          {icon ? <ButtonIcon>{icon}</ButtonIcon> : null}
        </>
      )}
    </ButtonWrap>
  );
};

export default Button;
