export const ActiveBellNotificationIcon = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.76841 21.135C10.2461 20.8579 10.8581 21.0205 11.1352 21.4982C11.2231 21.6497 11.3493 21.7755 11.5011 21.863C11.6529 21.9504 11.825 21.9964 12.0002 21.9964C12.1754 21.9964 12.3475 21.9504 12.4993 21.863C12.6511 21.7755 12.7773 21.6497 12.8652 21.4982C13.1423 21.0205 13.7542 20.8579 14.232 21.135C14.7097 21.4121 14.8723 22.024 14.5952 22.5018C14.3315 22.9564 13.9529 23.3337 13.4975 23.596C13.0421 23.8584 12.5258 23.9964 12.0002 23.9964C11.4746 23.9964 10.9583 23.8584 10.5028 23.596C10.0474 23.3337 9.6689 22.9564 9.40518 22.5018C9.12806 22.024 9.29069 21.4121 9.76841 21.135Z'
    />
    <path d='M12.4108 2.01205C12.1465 2.62133 12 3.29356 12 4C10.6739 4.00002 9.40221 4.5268 8.46454 5.46447C7.52686 6.40215 7.00008 7.67392 7.00008 9C7.00008 12.6473 6.21715 15.0654 5.37797 16.6039C5.30188 16.7434 5.22549 16.8753 5.1494 17H18.8508C18.7747 16.8753 18.6983 16.7434 18.6222 16.6039C17.783 15.0654 17.0001 12.6473 17.0001 9C17.7065 8.99999 18.3787 8.85347 18.988 8.58917C18.996 8.72559 19.0001 8.86259 19.0001 9C19.0001 12.3527 19.7171 14.4346 20.378 15.6461C20.7098 16.2544 21.0329 16.6535 21.2573 16.8904C21.3698 17.0091 21.4581 17.0878 21.5114 17.1322C21.538 17.1544 21.5558 17.168 21.5635 17.1737L21.5664 17.1758C21.9249 17.4221 22.0835 17.8725 21.9572 18.2898C21.8295 18.7115 21.4407 19 21.0001 19H3.00008C2.55941 19 2.17068 18.7115 2.04299 18.2898C1.91664 17.8725 2.07528 17.4221 2.43377 17.1758L2.43663 17.1737C2.44432 17.168 2.46218 17.1544 2.4888 17.1322C2.54202 17.0878 2.6304 17.0091 2.74288 16.8904C2.9673 16.6535 3.29039 16.2544 3.62218 15.6461C4.28301 14.4346 5.00008 12.3527 5.00008 9C5.00008 7.14348 5.73758 5.36301 7.05033 4.05025C8.36309 2.7375 10.1436 2 12.0001 2C12.1375 2 12.2744 2.00404 12.4108 2.01205Z' />
    <rect x='14' y='1' width='6' height='6' rx='3' fill='#FED74D' />
  </svg>
);
