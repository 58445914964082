import styled from 'styled-components';
import { MonthMiniature } from '../common/MonthMiniature';
import { WeekCalendar } from './WeekCalendar';
import { DaySchedule } from './DaySchedule';

export const MonthPageWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  column-gap: 24px;
  grid-template-areas: 'miniature miniature miniature calendar calendar calendar calendar calendar calendar calendar calendar calendar';
  margin-top: 40px;
  margin-bottom: 100px;
`;

export const Miniature = styled(MonthMiniature)`
  grid-area: miniature;
`;

export const Content = styled.div`
  grid-area: calendar;
`;

export const Week = styled(WeekCalendar)``;

export const Day = styled(DaySchedule)`
  margin-top: 24px;
`;
