import React from 'react';

export const PaperClipSmallIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.15 1.98991C12.4865 1.98991 11.8501 2.2535 11.3809 2.72268L3.72259 10.381C2.94066 11.1629 2.50138 12.2235 2.50138 13.3293C2.50138 14.4351 2.94066 15.4956 3.72259 16.2775C4.50451 17.0594 5.56502 17.4987 6.67083 17.4987C7.77664 17.4987 8.83715 17.0594 9.61907 16.2775L17.2774 8.61917C17.6028 8.29374 18.1305 8.29374 18.4559 8.61917C18.7814 8.94461 18.7814 9.47225 18.4559 9.79768L10.7976 17.456C9.7031 18.5505 8.21866 19.1654 6.67083 19.1654C5.123 19.1654 3.63856 18.5505 2.54407 17.456C1.44959 16.3615 0.834717 14.8771 0.834717 13.3293C0.834717 11.7814 1.44959 10.297 2.54407 9.20251L10.2024 1.54417C10.9842 0.762424 12.0444 0.323242 13.15 0.323242C14.2556 0.323242 15.3158 0.762424 16.0976 1.54417C16.8793 2.32592 17.3185 3.3862 17.3185 4.49176C17.3185 5.59732 16.8793 6.6576 16.0976 7.43935L8.43092 15.0977C7.96191 15.5667 7.32579 15.8302 6.6625 15.8302C5.99921 15.8302 5.36309 15.5667 4.89407 15.0977C4.42506 14.6287 4.16157 13.9925 4.16157 13.3293C4.16157 12.666 4.42506 12.0299 4.89407 11.5608L11.9694 4.49383C12.2951 4.16858 12.8227 4.16889 13.1479 4.49452C13.4732 4.82015 13.4729 5.34779 13.1472 5.67303L6.07259 12.7394C5.91635 12.8958 5.82824 13.1082 5.82824 13.3293C5.82824 13.5505 5.91613 13.7627 6.07259 13.9192C6.22904 14.0756 6.44124 14.1635 6.6625 14.1635C6.88376 14.1635 7.09595 14.0756 7.25241 13.9192L14.9191 6.26084C15.3881 5.79168 15.6519 5.15514 15.6519 4.49176C15.6519 3.82823 15.3883 3.19187 14.9191 2.72268C14.4499 2.2535 13.8135 1.98991 13.15 1.98991Z'
      fill='#B1B1B1'
    />
  </svg>
);

