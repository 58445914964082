import styled from 'styled-components';
import { CategoryButton } from 'ui/CategoryButton/';
import { Button } from 'ui/Button/';

export const Category = styled(CategoryButton)``;

export const CategoryWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & ${Category} + ${Category} {
    margin-top: 12px;
  }
`;

export const BackButton = styled(Button)`
  margin-bottom: 32px;
`;

export const AccountActions = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${props => props.theme.colors.secondTextColor};
  }
  button:nth-of-type(1) {
    margin-bottom: 8px;
  }
`;
