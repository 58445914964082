import { MiddleCatalogItem } from 'components/Catalog/common/MiddleCatalogItem';
import React, { useEffect } from 'react';
import { useCoursesQuery } from 'queries/useCoursesQuery';
import { clearCatalog, useCatalogStore } from 'models/catalog/model';
import { StyledPreloader } from '../styles';
import styled from 'styled-components';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Path } from 'constants/paths';

const NoItemsText = styled.p`
  ${({ theme }) => theme.typography.headline3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const Courses: React.FC = () => {
  const { isLoading, isFetching, isError } = useCoursesQuery();
  const data = useCatalogStore();
  const showPreloader = isLoading || isFetching;
  useEffect(() => {
    return () => {
      clearCatalog();
    };
  }, []);

  return (
    <>
      {showPreloader ? (
        <StyledPreloader />
      ) : data.length ? (
        data.map(
          ({
            date_start,
            date_end,
            id,
            picture,
            name,
            is_marathon,
            purchased,
            bg_color,
          }) => {
            const start = new Date(date_start).getTime();
            const end = new Date(date_end).getTime();
            const duration = formatDistance(start, end, { locale: ru });
            // Если вдруг появляется activity - false то сразу писать бэкэндщику!!!

            return (
              <MiddleCatalogItem
                image={picture}
                id={id}
                key={id}
                duration={duration}
                title={name}
                datetime={new Date(date_start)}
                is_marathon={is_marathon}
                purchased={purchased}
                bg_color={bg_color}
                url={`${Path.course}/${id}`}
              />
            );
          }
        )
      ) : (
        <NoItemsText>
          {isError ? 'Не удалось загрузить товары' : 'Нет товаров'}
        </NoItemsText>
      )}
    </>
  );
};
