import React from 'react';
import { Field, Form } from 'react-final-form';
import { StyledForm, Input, Phone } from './styles';
import { RegistrationFormProps } from './types';

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  setButtonHandler,
  onSubmit,
  ...rest
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        setButtonHandler(handleSubmit);
        return (
          <StyledForm onSubmit={handleSubmit}>
            <Field
              labelWidth={144}
              inputWidth={340}
              label={'Телефон'}
              direction={'vertical'}
              alignLabel={'left'}
              placeholder={'Введите телефон'}
              name={'phone'}
              component={Phone}
            />
            <Field
              labelWidth={144}
              inputWidth={340}
              label={'Имя'}
              direction={'vertical'}
              alignLabel={'left'}
              placeholder={'Введите имя'}
              name={'name'}
              component={Input}
            />
            <Field
              labelWidth={144}
              inputWidth={340}
              label={'Фамилия'}
              direction={'vertical'}
              alignLabel={'left'}
              placeholder={'Введите фамилию'}
              name={'last_name'}
              component={Input}
            />
          </StyledForm>
        );
      }}
      {...rest}
    />
  );
};
