import styled from 'styled-components';

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0;
  margin: 0 10px 0 0;
  & > svg {
    width: 60px;
    height: 60px;
  }
`;

export const Title = styled.div`
  margin-top: 24px;
  ${({ theme }) => theme.typography.headline2};
`;

export const Text = styled.div`
  max-width: 500px;
  text-align: center;
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActivationCodeInput = styled.div`
  width: 340px;
  height: 48px;
  margin-bottom: 40px;
  background: ${props => props.theme.colors.secondBgColor};
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  svg {
    cursor: pointer;
    margin-right: 13px;
  }
  span {
    margin-left: 14px;
  }
`;

export const Description = styled.div`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const ActivationCode = styled.div`
  margin-top: 24px;
`;

export const ImgWrap = styled.div`
  border-radius: 14px;
  overflow: hidden;
  width: 140px;
  height: 96px;
  p {
    margin: 0;
  }
`;

export const ImgSC = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
