import { CartResponse } from '../models/cart/types';

class CartService {
  getCart() {
    const localStorageCart = localStorage.getItem('cart');
    return localStorageCart ? JSON.parse(localStorageCart) : null;
  }

  setCart(cartObject: CartResponse) {
    localStorage.setItem('cart', JSON.stringify(cartObject));
  }

  clearCart() {
    localStorage.removeItem('cart');
  }
}

export default new CartService();
