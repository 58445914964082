import styled from 'styled-components';
import { InputPassword } from 'ui/InputPassword';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';
import { InputPhone } from 'ui/InputPhone';

export const Wrap = styled.div`
  text-align: center;
`;
export const Title = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.headline2};
`;
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Login = styled(InputPhone)`
  margin: 24px 0 0;
`;
export const Password = styled(InputPassword)`
  margin: 24px 0 0;
`;

export const LoginButton = styled(Button)`
  margin: 24px 0 0;
  width: 340px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  opacity: 0.5;
`;

export const RegistrationButton = styled(Button)`
  margin: 8px 0 0;
  width: 340px;
`;

export const RestorePasswordButton = styled(Button)`
  margin: 24px 0 0;
  & > span {
    font-weight: 400;
  }
  color: ${({ theme }) => theme.colors.secondTextColor};
`;

export const RegistrationMessage = styled.p`
  margin: 60px 0 0;
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.secondTextColor};
`;

export const StyledPreloader = styled(Preloader)`
  margin: 60px 0 0;
`;
