import { useQuery } from 'react-query';
import {
  getLegalDocumentsFx,
  setLegalDocuments,
} from 'models/legal-documents/model';
import { LegalDocumentsQuery } from './types';

export const useLegalDocumentsQuery: LegalDocumentsQuery = () =>
  useQuery('legalDocuments', () => getLegalDocumentsFx(), {
    onSuccess: res => {
      setLegalDocuments(res.data.data);
    },
    refetchOnWindowFocus: false,
  });
