import styled from 'styled-components';
import { Button } from 'ui/Button';

export const Wrap = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  position: relative;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 0;
`;

export const Badge = styled.div`
  padding: 4px 8px;
  border-radius: 12px;
  margin: 0 0 12px;
  background: #feeba6;
  display: inline-block;
`;

export const BadgeText = styled.div`
  ${({ theme }) => theme.typography.title2}
`;

export const Bottom = styled.div`
  padding: 0 0 24px 163px;
`;

export const Inner = styled.div`
  max-width: 311px;
`;

export const PhotoWrap = styled.div`
  border-radius: 14px;
  align-self: flex-start;
  width: 120px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  overflow: hidden;
  & + ${Inner} {
    margin: 0 0 0 42px;
  }
`;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline3};
`;

export const Count = styled.p`
  margin: 12px 0 0;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const Price = styled.div`
  margin: 48px 0 0 auto;
  width: 92px;
  align-self: flex-start;
  text-align: right;
`;

export const OldPrice = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-decoration: line-through;
`;

export const NewPrice = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: 0;
  & + ${OldPrice} {
    margin: 12px 0 0;
  }
`;

export const DeleteButton = styled(Button)`
  align-self: flex-start;
  margin: 48px 0 0 99px;
`;

export const Overlay = styled.div`
  background-color: ${({ theme }) => theme.colors.mainBgColor + '50'};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

export const HandleCounter = styled.div`
  border-radius: 4px;
  display: inline-flex;
  justify-content: flex-start;
  overflow: hidden;
  margin: 12px 0 0;
`;

export const HandleCount = styled.div`
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  padding: 6px 10px;
`;

export const HandleButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  border: none;
  padding: 8px;
  font-size: 0;
  transition: background-color 0.3s;
  &:hover {
    transition: background-color 0.3s;
    background-color: ${({ theme }) => theme.colors.secondColor};
  }
`;
