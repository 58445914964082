import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import {
  getMessagesFx,
  setHasNewMessages,
  setMessages,
  useHasNewMessages,
} from 'models/chat/model';
import { TMessage } from 'models/chat/types';
import { useQuery } from 'react-query';
import { ChatGetMessages, ChatGetNewMessages } from './types';

export const useChatGetMessagesQuery: ChatGetMessages = (
  messageCount,
  lastMessageId
) =>
  useQuery(
    'chatGetMessages',
    () => getMessagesFx({ messageCount, lastMessageId }),
    {
      onSuccess: res => {
        setMessages(
          res.data.sort((a: TMessage, b: TMessage) => {
            return (
              new Date(a.date.date).getTime() - new Date(b.date.date).getTime()
            );
          })
        );
      },
      enabled: useHasNewMessages(),
      refetchOnWindowFocus: false,
    }
  );

export const useChatGetNewMessagesQuery: ChatGetNewMessages = (
  lastMessageId = 0
) =>
  useQuery(
    'chatGetNewMessages',
    () => apiClient.get(Endpoint.CHAT_GET_NEW_MESSAGES + `/${lastMessageId}`),
    {
      onSuccess: res => {
        setHasNewMessages(res.data.existNewMessage);
      },
      refetchOnWindowFocus: false,
      refetchInterval: 60000, // 60 sec
      refetchIntervalInBackground: true,
    }
  );
