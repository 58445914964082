import React from 'react';
import { Wrap, Schools } from './styles';
import { Path } from 'constants/paths';
import { MiddleCatalogItem } from 'components/Catalog/common/MiddleCatalogItem';
import { ListProps } from './types';
import { MasterClasses } from './MasterClasses';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export const List: React.FC<ListProps> = ({ schools }) => {
  useScrollToTopOnMount();

  return (
    <Wrap>
      <Schools>
        {schools.map(
          ({ name, date_start, id, purchased, picture, ...rest }) => (
            <MiddleCatalogItem
              key={id}
              {...rest}
              id={id}
              title={name}
              image={picture}
              datetime={new Date(date_start)}
              purchased={purchased}
              // TODO: решить, будет ли bg_color-подложка
              bg_color={'yellow'}
              url={`${Path.school}/${id}`}
            />
          )
        )}
      </Schools>

      <MasterClasses />
    </Wrap>
  );
};
