import React, { InputHTMLAttributes, LabelHTMLAttributes } from 'react';
import styled from 'styled-components';
import { CheckSmallIcon } from 'icons';
import { CheckboxProps } from './types';

const CheckboxKnob = styled.span`
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  transition: background-color 0.3s;
  position: relative;
  font-size: 0;
`;
const CheckboxInput = styled.input`
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
`;
const CheckboxText = styled.span`
  ${props => props.theme.typography.bodyText2};
`;
const CheckboxInner = styled.span`
  border-radius: 4px;
  transition: background-color 0.3s;
`;

const CheckboxConfirm = styled.div<{ checked?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s;
  opacity: ${({ checked }) => (checked ? 1 : 0)};
`;
const CheckboxLabel = styled.label<
  CheckboxProps & InputHTMLAttributes<HTMLInputElement>
>`
  padding: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  & ${CheckboxInner} {
    display: block;
    background-color: ${props =>
      props.checked
        ? props.theme.colors.actionColor
        : props.theme.colors.mainBgColor};
    width: ${props => (props.radioSize === 'large' ? '33.33px' : '20px')};
    height: ${props => (props.radioSize === 'large' ? '33.33px' : '20px')};
    border: ${props => `2px solid ${props.theme.colors.secondColor}`};
  }

  & ${CheckboxInput}:checked + ${CheckboxInner} {
    ${props =>
      props.checked
        ? `border: 2px solid ${props.theme.colors.actionColor};`
        : null}
  }
  & ${CheckboxKnob} + ${CheckboxText} {
    margin-left: 8px;
  }
`;

export const Checkbox: React.FC<
  CheckboxProps &
    InputHTMLAttributes<HTMLInputElement> &
    LabelHTMLAttributes<HTMLLabelElement>
> = ({ labelText, radioSize = 'small', checked, className, ...props }) => {
  return (
    <CheckboxLabel
      radioSize={radioSize}
      checked={checked}
      className={className}
    >
      <CheckboxKnob>
        <CheckboxInput type='checkbox' checked={checked} {...props} />
        <CheckboxInner />
        <CheckboxConfirm checked={checked}>{CheckSmallIcon}</CheckboxConfirm>
      </CheckboxKnob>
      {labelText ? <CheckboxText>{labelText}</CheckboxText> : null}
    </CheckboxLabel>
  );
};

export default Checkbox;
