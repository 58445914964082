import { useMutation } from 'react-query';
import { confirmRegistrationFx } from 'models/auth/model';
import { ConfirmRegistrationQueryProps } from './types';

export const useConfirmRegistrationQuery: ConfirmRegistrationQueryProps = callback =>
  useMutation(
    val => {
      return confirmRegistrationFx(val);
    },
    {
      onSuccess: () => {
        if (callback) callback();
      },
    }
  );
