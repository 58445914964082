import { Button } from 'ui/Button';
import styled from 'styled-components';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddAdressButton = styled(Button)`
  align-self: flex-end;
  svg {
    stroke-width: 0;
  }
  margin-top: 0;
  margin-bottom: 60px;
`;

export const AddressesList = styled.div`
  margin-bottom: 12px;
`;
