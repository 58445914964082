import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';
import { Tag as TagItem } from 'ui/Tag';
import { ProductOption } from 'ui/ProductOption';
import { Button } from 'ui/Button';
import { NoImage } from 'ui/NoImage';

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
  text-align: center;
`;

export const NoItemText = styled.p`
  ${({ theme }) => theme.typography.headline3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const Wrap = styled.div`
  width: 100%;
  margin: 24px 0 0;
`;

export const Preview = styled.div`
  display: flex;
`;

export const Photo = styled.div`
  width: calc(((100% - (8 * 24px)) / 9) * 4 + (5 * 24px));
  padding: 0 30px 0 0;
  flex-shrink: 0;
`;

export const MainPhoto = styled.div`
  position: relative;
  padding: 100% 0 0;
  overflow: hidden;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  left: 0;
  top: 0;
`;

export const StyledNoImage = styled(NoImage)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  left: 0;
  top: 0;
`;

export const ThumbPhoto = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0;
  flex-wrap: wrap;
`;

export const ThumbItem = styled.button<{ selected: boolean }>`
  position: relative;
  border: 0;
  width: 70px;
  height: 70px;
  border-radius: 14px;
  overflow: hidden;
  margin-top: 16px;
  margin-right: 16px;
  ${({ theme, selected }) =>
    selected ? `box-shadow: 0 0 0 1px  ${theme.colors.actionColor}` : null}
`;

export const Options = styled.div`
  margin: 0 0 0 24px;
`;

export const Top = styled.div`
  display: flex;
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled(TagItem)`
  & + & {
    margin: 0 0 0 12px;
  }
`;

export const Bonus = styled.div`
  padding: 4px 8px;
  background-color: #feeba6;
  border-radius: 12px;
  ${({ theme }) => theme.typography.title2}
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline1}
  margin: 12px 0 0;
`;

export const Author = styled.p`
  ${({ theme }) => theme.typography.bodyText1};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 6px 0 0;
`;

export const OfferOptions = styled.div`
  margin: 10px 0 0;
`;

export const OfferOption = styled(ProductOption)`
  margin: 10px 10px 0 0;
`;

export const Price = styled.div`
  margin: 30px 0 0;
  display: flex;
  align-items: center;
`;

export const PriceText = styled.div`
  ${({ theme }) => theme.typography.headline3}
`;

export const AddToCart = styled(Button)`
  margin: 0 0 0 40px;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 0;
`;

export const DescriptionItem = styled.div`
  & + & {
    margin: 0 0 0 40px;
  }
`;

export const DescriptionItemTitle = styled.p`
  ${({ theme }) => theme.typography.title1}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  font-weight: normal;
  margin: 0;
`;

export const DescriptionItemText = styled.p`
  ${({ theme }) => theme.typography.bodyText2}
  margin: 8px 0 0;
`;
