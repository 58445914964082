import styled from 'styled-components';
const PhotoWidth = '80px';
const PhotoHeight = '80px';

export const Photo = styled.img`
  width: ${PhotoWidth};
  height: ${PhotoHeight};
  background-color: ${({ theme }) => theme.colors.secondColor};
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
`;

export const ClickableArea = styled.div`
  cursor: pointer;
  border-radius: 50%;
  height: ${PhotoHeight};
  overflow: hidden;
  position: relative;
  &:hover {
    & > svg {
      opacity: 1;
    }

    & > ${ImageOverlay} {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
  & > svg {
    z-index: 1;
    transition: opacity 0.3s;
    opacity: 0;
    fill: ${({ theme }) => theme.colors.mainBgColor};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ChangeMyAvatarSC = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 24px;
`;

export const Warning = styled.label`
  color: ${props => props.theme.colors.secondTextColor};
  width: 232px;
  height: 40px;
  font-size: 14px;
  margin-left: 82px;
`;
