export const GoodsIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='5.25' y='7.5' width='1.5' height='6' rx='0.75' />
    <rect x='4.5' y='4.5' width='9' height='1.5' rx='0.75' />
    <rect x='11.25' y='7.5' width='1.5' height='6' rx='0.75' />
    <rect x='8.25' y='7.5' width='1.5' height='6' rx='0.75' />
    <path d='M3 1.5H5.25C5.66421 1.5 6 1.83579 6 2.25C6 2.66421 5.66421 3 5.25 3H3V5.25C3 5.66421 2.66421 6 2.25 6C1.83579 6 1.5 5.66421 1.5 5.25V3C1.5 2.17157 2.17157 1.5 3 1.5Z' />
    <path d='M1.5 12.75V15C1.5 15.8284 2.17157 16.5 3 16.5H5.25C5.66421 16.5 6 16.1642 6 15.75C6 15.3358 5.66421 15 5.25 15H3V12.75C3 12.3358 2.66421 12 2.25 12C1.83579 12 1.5 12.3358 1.5 12.75Z' />
    <path d='M15 12.75V15H12.75C12.3358 15 12 15.3358 12 15.75C12 16.1642 12.3358 16.5 12.75 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V12.75C16.5 12.3358 16.1642 12 15.75 12C15.3358 12 15 12.3358 15 12.75Z' />
    <path d='M16.5 5.25V3C16.5 2.17157 15.8284 1.5 15 1.5H12.75C12.3358 1.5 12 1.83579 12 2.25C12 2.66421 12.3358 3 12.75 3H15V5.25C15 5.66421 15.3358 6 15.75 6C16.1642 6 16.5 5.66421 16.5 5.25Z' />
  </svg>
);
