import React from 'react';
import { Field } from 'react-final-form';
import { SubTitle, Title, ItemTextarea, Item, Divider } from './styles';
import { useUserProfile } from 'models/auth/model';

export const RecipientFields: React.FC = props => {
  const userProfile = useUserProfile();
  return (
    <div {...props}>
      <Title>Получатель</Title>
      <SubTitle>
        Указывайте все личные данные — у вас могут попросить паспорт, прежде чем
        передать оплаченный заказ
      </SubTitle>
      <Field
        name={'last_name'}
        labelWidth={144}
        inputWidth={340}
        label={'Фамилия'}
        defaultValue={userProfile?.last_name}
        alignLabel={'left'}
        placeholder={'Введите фамилию'}
        component={Item}
      />
      <Field
        name={'name'}
        labelWidth={144}
        inputWidth={340}
        label={'Имя'}
        defaultValue={userProfile?.name}
        alignLabel={'left'}
        placeholder={'Введите имя'}
        component={Item}
      />
      <Field
        name={'e-mail'}
        labelWidth={144}
        inputWidth={340}
        label={'E-mail'}
        alignLabel={'left'}
        defaultValue={userProfile?.email}
        placeholder={'Введите e-mail'}
        component={Item}
      />
      <Field
        name={'phone'}
        labelWidth={144}
        inputWidth={340}
        label={'Телефон'}
        alignLabel={'left'}
        defaultValue={userProfile?.phone}
        placeholder={'Введите телефон'}
        component={Item}
      />
      <Field
        name={'comment'}
        labelWidth={144}
        inputWidth={340}
        label={'Примечание'}
        alignLabel={'left'}
        rows={6}
        component={ItemTextarea}
        placeholder={'Укажите в этом поле важную информацию'}
      />
      <Divider />
    </div>
  );
};
