import React from 'react';
import { ArrayElement } from '../../GiftCards/types';
import { fakeData } from '../fakeData';
import { DateSC, Operation, Value, Wrap } from './styled';

interface IProps {
  data: ArrayElement<typeof fakeData>;
}

const BonusItem: React.FC<IProps> = ({ data }) => {
  return (
    <Wrap>
      <DateSC>{data.date}</DateSC>
      {data.operations.map(operation => (
        <Operation key={operation.id}>
          <div>{operation.name}</div>
          <div>
            <Value>
              {`${operation.isPositive ? ' + ' : ' - '}${
                operation.bonusValue
              } бонусов`}
            </Value>
          </div>
        </Operation>
      ))}
    </Wrap>
  );
};

export default BonusItem;
