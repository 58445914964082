import React from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FileIcon, HeadphonesIcon, VideoIcon } from 'icons/';
import {
  Item,
  Inner,
  Photo,
  PhotoBlank,
  PhotoBlankText,
  Text,
  Date,
  Author,
  FilesList,
  FileInfo,
  FileTitle,
  FileImg,
  FileIconContainer,
  File,
} from './styles';
import { CommentProps } from './types';
import { DateFormats } from 'constants/dateFormats';
import {
  formatUserMiniatureName,
  formatUserName,
} from 'utilities/formatUserMiniatureName';
import { regExpAudio, regExpImage, regExpVideo } from 'constants/regExps';
import { baseUrl } from 'api/apiClient';

export const Comment: React.FC<CommentProps> = ({
  photo,
  author,
  date,
  text,
  files = [],
}) => {
  const getPreviewIcon = (ext: string) => {
    if (!ext) return;
    if (regExpImage.test(ext)) return;
    if (regExpVideo.test(ext)) return VideoIcon;
    if (regExpAudio.test(ext)) return HeadphonesIcon;
    return FileIcon;
  };

  return (
    <Item>
      {photo ? (
        <Photo
          src={baseUrl + photo}
          alt={`${author?.name} ${author?.lastname}`}
        />
      ) : (
        <PhotoBlank>
          <PhotoBlankText>
            {formatUserMiniatureName(author.name, author.lastname)}
          </PhotoBlankText>
        </PhotoBlank>
      )}
      <Inner>
        <Author>{formatUserName(author.name, author.lastname)}</Author>
        <Date>{format(date, DateFormats['DateTime'], { locale: ru })}</Date>
        <Text>{text}</Text>
        <FilesList>
          {files.map(file => {
            const url = baseUrl + file.path;
            const fileIcon = getPreviewIcon(file.extension);
            return (
              <File key={file.path} href={url} target='_blank'>
                {fileIcon ? (
                  <FileIconContainer>{fileIcon}</FileIconContainer>
                ) : (
                  <FileImg src={url} alt={file?.name} />
                )}
                <FileTitle>{file.name}</FileTitle>
                <FileInfo>{Math.round(file.size / 1000)} Кб</FileInfo>
              </File>
            );
          })}
        </FilesList>
      </Inner>
    </Item>
  );
};
