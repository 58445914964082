import React, { HTMLAttributes } from 'react';
import { formatPriceToString } from 'utilities/formatPriceToString';
import Ruble from 'components/common/Ruble';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import {
  Item,
  Divider,
  ImageWrap,
  Image,
  Inner,
  Price,
  Subtitle,
  Title,
  PriceTag,
  StartDatetime,
  StartTime,
  StartText,
  StartTitle,
  Description,
  DescriptionTitle,
  DescriptionText,
  Link,
} from './styles';
import { DateFormats } from 'constants/dateFormats';
import { TrainingItem as TrainingItemType } from 'models/catalog/types';
import { baseUrl } from 'api/apiClient';
import { Button } from 'ui/Button';
import { NoImage } from 'ui/NoImage';

type TrainingItemProps = TrainingItemType & HTMLAttributes<HTMLDivElement>;

export const TrainingItem: React.FC<TrainingItemProps> = ({
  date_event,
  description,
  external_link,
  name,
  picture,
  place,
  price,
  className,
}) => {
  const datetime = new Date(date_event);
  const date = format(datetime, DateFormats['StartEventDate'], { locale: ru }),
    time = format(datetime, 'HH:mm', { locale: ru });

  return (
    <div className={className}>
      <Item>
        <ImageWrap>
          {picture ? <Image src={baseUrl + picture} alt={name} /> : <NoImage />}
        </ImageWrap>
        <Inner>
          <Title>{name}</Title>
          <Subtitle>{place}</Subtitle>
          <Price>
            <PriceTag>
              {formatPriceToString(price)} {Ruble}
            </PriceTag>
            <Link href={external_link} target='_blank'>
              <Button size={'large'}>Купить билет</Button>
            </Link>
          </Price>
          <StartDatetime>
            <div>
              <StartText>Дата</StartText>
              <StartTitle>{date}</StartTitle>
            </div>
            <StartTime>
              <StartText>Время</StartText>
              <StartTitle>{time}</StartTitle>
            </StartTime>
          </StartDatetime>
        </Inner>
      </Item>
      <Divider />
      <Description>
        <DescriptionTitle>О мероприятии</DescriptionTitle>
        <DescriptionText>{description}</DescriptionText>
      </Description>
    </div>
  );
};
