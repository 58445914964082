export const ContactPhoneIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16.5583 19.1083C16.4916 19.1083 16.4166 19.1083 16.35 19.1C13.6583 18.8083 11.0416 17.875 8.7833 16.4083C6.6833 15.075 4.86663 13.2583 3.5333 11.1583C2.06663 8.89167 1.1333 6.26667 0.849968 3.575C0.783302 2.89167 0.983302 2.24167 1.41663 1.73334C1.84163 1.21667 2.44997 0.908336 3.1083 0.841669C3.1833 0.833336 3.2583 0.833336 3.3333 0.833336H5.8333C5.84163 0.833336 5.84997 0.833336 5.8583 0.833336C7.09163 0.833336 8.14997 1.75 8.32497 2.98334C8.42497 3.725 8.6083 4.45 8.86664 5.15C9.2083 6.05834 8.99163 7.09167 8.29997 7.78334L7.69997 8.38334C8.6833 9.91667 9.9833 11.225 11.525 12.2083L12.125 11.6083C12.8166 10.925 13.8583 10.7 14.7666 11.0417C15.4583 11.3 16.1916 11.4833 16.925 11.575C18.1833 11.75 19.1083 12.8417 19.0833 14.1V16.5917C19.0833 17.2583 18.825 17.8833 18.3583 18.3583C17.8916 18.8333 17.2583 19.0917 16.5916 19.1C16.575 19.1083 16.5666 19.1083 16.5583 19.1083ZM5.84997 2.5C5.84163 2.5 5.84163 2.5 5.84997 2.5H3.3333C3.0333 2.525 2.8333 2.625 2.69163 2.8C2.54997 2.96667 2.4833 3.18334 2.49997 3.40834C2.7583 5.825 3.59997 8.2 4.92497 10.2583C6.1333 12.1583 7.77497 13.8 9.67497 15.0083C11.725 16.3417 14.0916 17.175 16.5083 17.4417C16.8 17.475 17 17.3583 17.1583 17.2C17.3166 17.0417 17.4 16.8333 17.4 16.6083V14.1083C17.4 14.1 17.4 14.0917 17.4 14.0833C17.4083 13.6583 17.1 13.3 16.6833 13.2417C15.8333 13.1333 14.9833 12.9167 14.1666 12.6167C13.8583 12.5 13.5166 12.575 13.2833 12.8L12.225 13.8583C11.9583 14.125 11.55 14.175 11.225 13.9917C9.0083 12.7333 7.17497 10.8917 5.91663 8.68334C5.74163 8.34167 5.79163 7.93334 6.0583 7.66667L7.11663 6.60834C7.34163 6.38334 7.41664 6.04167 7.29997 5.73334C6.99163 4.91667 6.7833 4.06667 6.66663 3.20834C6.61663 2.80834 6.2583 2.5 5.84997 2.5Z' />
  </svg>
);
