import React from 'react';

const MoreIcon = (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='20' cy='20' r='19.5' stroke='#EAEAEA' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.2929 13.2929C17.6834 12.9024 18.3166 12.9024 18.7071 13.2929L24.7071 19.2929C25.0976 19.6834 25.0976 20.3166 24.7071 20.7071L18.7071 26.7071C18.3166 27.0976 17.6834 27.0976 17.2929 26.7071C16.9024 26.3166 16.9024 25.6834 17.2929 25.2929L22.5858 20L17.2929 14.7071C16.9024 14.3166 16.9024 13.6834 17.2929 13.2929Z'
      fill='#181818'
    />
  </svg>
);

export default MoreIcon;
