import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { useMutation } from 'react-query';
import { ActivateGiftCard } from './types';

export const useActivateGiftCard: ActivateGiftCard = (
  onSuccessMutation: (bonusValue: number) => void
) =>
  useMutation(
    ['activateGiftCard'],
    (id: string) =>
      apiClient.post(Endpoint.ACTIVATE_CARD, {
        activation_code: id,
      }),
    {
      onSuccess: data => {
        onSuccessMutation(data.data.data.nominal);
      },
    }
  );
