import React from 'react';
import styled from 'styled-components';
import { InputTextareaProps } from './types';

const InputLabel = styled.label<InputTextareaProps>`
  ${props => props.theme.typography.bodyText2};
  letter-spacing: 0;
  flex-shrink: 0;
  text-align: ${props => props.alignLabel};
  margin-bottom: 8px;
  color: ${props => props.theme.colors.secondTextColor};
  align-self: flex-start;
  padding: 13px 0;
  ${({ labelWidth }) => (labelWidth ? `width: ${labelWidth}px` : null)};
`;

const Error = styled.p<Pick<InputTextareaProps, 'inputWidth'>>`
  margin: 8px 0 0;
  max-width: 100%;
  text-align: left;
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth}px` : null)};
  color: ${props => props.theme.colors.errorColor};
`;

export const InputInner = styled.div<
  Pick<
    InputTextareaProps,
    'backgroundColor' | 'inputWidth' | 'fullWidth' | 'error'
  > & { isFocused: boolean; disabled?: boolean }
>`
  padding: 12px 14px;
  border-radius: 14px;
  border: 1px solid ${props => props.theme.colors.secondBgColor};
  transition: all 0.3s;
  min-height: inherit;
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor}` : null};
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth}px` : null)};
  ${({ error, theme }) =>
    error ? `border-color: ${theme.colors.errorColor}` : null}
  ${({ isFocused, theme }) =>
    isFocused &&
    `transition: all 0.3s;border-color: ${theme.colors.secondColor};`}

  ${({ disabled, theme }) =>
    !disabled &&
    `&:hover {
          transition: all 0.3s;
          border-color: ${theme.colors.secondColor};
        }`}
`;

const Input = styled.textarea<InputTextareaProps>`
  outline: none;
  z-index: 3;
  line-height: 22px;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
  ${({ height }) => height && `height: ${height}px`};
  resize: none;
  width: 100%;
  ${({ theme }) => theme.typography.bodyText2};
  font: inherit;
  min-height: inherit;
  &:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px ${({ theme }) => theme.colors.secondBgColor}
      inset;
  }
  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.disabledColor
        : theme.colors.thirdTextAndIconColor};
  }
`;

const InputWrap = styled.div<
  Pick<InputTextareaProps, 'direction' | 'fullWidth'> & {
    disabled?: boolean;
    error?: boolean;
  }
>`
  display: inline-flex;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'row'};
  ${({ direction }) =>
    direction !== 'vertical' ? 'align-items: center;' : null}
  ${({ direction }) =>
    direction !== 'vertical' ? `& > ${InputLabel} {margin: 0 16px 0 0;}` : null}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};

  ${props =>
    props.disabled &&
    `& > ${InputInner} {
        background-color: ${props.theme.colors.thirdBgColor};
        border-color: ${props.theme.colors.thirdBgColor};
        cursor: not-allowed;
      };
      
      & > ${Input} {
        cursor: not-allowed;
      }      
      & > ${InputLabel} {
        color: ${props.theme.colors.disabledColor};
      };`};

  & ${Error} {
    transition: transform 0.3s, opacity 0.3s;
    ${({ error }) => {
      return !error
        ? 'transform: translate(0, -50%); opacity: 0;'
        : 'transform: translate(0, 0); opacity: 1;';
    }};
  }
  & ${InputInner} {
    ${({ error, theme }) => error && `border-color: ${theme.colors.errorColor}`}
  }
`;

// TODO: Доработать компонент для работы с формами;
const InputTextarea: React.FC<InputTextareaProps> = ({
  alignLabel = 'left',
  label,
  disabled,
  direction,
  labelWidth,
  inputWidth,
  className,
  fullWidth,
  input,
  meta,
  backgroundColor,
  ...rest
}) => {
  return (
    <InputWrap
      disabled={disabled}
      direction={direction}
      fullWidth={fullWidth}
      className={className}
      error={meta.error || meta.submitError}
    >
      {label ? (
        <InputLabel
          name={meta.name}
          alignLabel={alignLabel}
          labelWidth={labelWidth}
        >
          {label}
        </InputLabel>
      ) : null}
      <div style={{ width: '100%', minHeight: 'inherit' }}>
        <InputInner
          isFocused={meta.active}
          inputWidth={inputWidth}
          fullWidth={fullWidth}
          error={meta.error || meta.submitError}
          backgroundColor={backgroundColor}
        >
          <Input disabled={disabled} {...input} {...rest} />
        </InputInner>
        {meta.error && meta.touched ? (
          <Error inputWidth={inputWidth}>{meta.error}</Error>
        ) : null}
        {meta.submitError && meta.touched ? (
          <Error inputWidth={inputWidth}>{meta.submitError}</Error>
        ) : null}
      </div>
    </InputWrap>
  );
};

export default InputTextarea;
