import { useCheckCurrentPassword } from 'queries/account/changePassword/useCheckCurrentPassword';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import Title from '../../Title';
import { ChangePassword } from './ChangePassword';
import { FormSC, Input, ButtonSC, Wrap, TextSC } from './styled';
import { AxiosError } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';
import { formatValidationErrors } from 'utilities/formatValidationErrors';

export const Password: React.FC = () => {
  const [availableToChange, setAvailableToChange] = useState(false);
  const { data, isLoading, mutate } = useCheckCurrentPassword();

  const handleSubmit = async (values: { password?: string }) =>
    await new Promise((resolve, reject) =>
      mutate(values.password, { onSuccess: resolve, onError: reject })
    )
      .then(() => setAvailableToChange(true))
      .catch((err: AxiosError<{ error: ServerValidationError }>) => {
        return formatValidationErrors(err.response?.data.error);
      });

  useEffect(() => {
    if (!data) return;

    setAvailableToChange(data.data.success);
  }, [data]);

  return (
    <Wrap>
      <Title>{'Пароль'}</Title>

      {!availableToChange ? (
        <>
          <TextSC>Чтобы продолжить, введите ваш текущий пароль</TextSC>
          <Form
            onSubmit={handleSubmit}
            render={props => {
              return (
                <FormSC onSubmit={props.handleSubmit}>
                  <Field
                    labelWidth={144}
                    inputWidth={340}
                    label='Пароль'
                    alignLabel='left'
                    name='password'
                    component={Input}
                  />
                  <ButtonSC disabled={isLoading} type='submit'>
                    Изменить пароль
                  </ButtonSC>
                </FormSC>
              );
            }}
          />
        </>
      ) : (
        <ChangePassword />
      )}
    </Wrap>
  );
};
