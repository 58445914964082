import styled from 'styled-components';
import { Description } from './Description';
import { Materials } from './Materials';
import { Homework } from './Homework';
import { Commentaries } from './Commentaries';

export const StyledDescription = styled(Description)`
  width: calc(((100% - (24px * 8)) / 9) * 7 + (24px * 6));
`;

export const StyledMaterials = styled(Materials)`
  width: calc(((100% - (24px * 8)) / 9) * 7 + (24px * 6));
`;

export const StyledHomework = styled(Homework)`
  width: calc(((100% - (24px * 8)) / 9) * 7 + (24px * 6));
`;

export const StyledCommentaries = styled(Commentaries)`
  width: calc(((100% - (24px * 8)) / 9) * 7 + (24px * 6));
`;

export const Wrap = styled.div`
  margin-top: 16px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 40px 0;
  background: ${({ theme }) => theme.colors.thirdBgColor};
`;

export const Title = styled.h1`
  margin: 0;
  ${({ theme }) => theme.typography.headline1}
`;

export const StyledDate = styled.div`
  ${({ theme }) => theme.typography.bodyText3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  & + ${Title} {
    margin-top: 10px;
  }
`;

export const List = styled.div`
  padding: 16px 0;
  margin: 8px 0 0;
  display: flex;

  & + ${Divider} {
    margin-top: 8px;
  }
`;

export const Link = styled.a`
  font-size: 0;
  display: flex;
  align-items: center;
  & + & {
    margin: 0 0 0 24px;
  }
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0 0 0 8px;
`;
