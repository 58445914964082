import { RefObject, useEffect, useRef } from 'react';
/**
 * Прокручивает скролл к возвращаемому элементу
 * @param deps
 * @returns ref
 */
export const useScrollIntoView = (
  ...deps: unknown[]
): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView();
  }, [deps]);

  return ref;
};
