export const AtSignIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.99992 7.5C8.61921 7.5 7.49992 8.61929 7.49992 10C7.49992 11.3807 8.61921 12.5 9.99992 12.5C11.3806 12.5 12.4999 11.3807 12.4999 10C12.4999 8.61929 11.3806 7.5 9.99992 7.5ZM5.83325 10C5.83325 7.69881 7.69873 5.83333 9.99992 5.83333C12.3011 5.83333 14.1666 7.69881 14.1666 10C14.1666 12.3012 12.3011 14.1667 9.99992 14.1667C7.69873 14.1667 5.83325 12.3012 5.83325 10Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.08333 1.7127C7.95387 0.828736 10.0684 0.603536 12.0832 1.07372C14.0979 1.5439 15.8944 2.68182 17.1804 4.30243C18.4665 5.92305 19.1665 7.93106 19.1666 9.99995V10.8333C19.1666 11.7174 18.8154 12.5652 18.1903 13.1904C17.5652 13.8155 16.7173 14.1667 15.8333 14.1667C14.9492 14.1667 14.1014 13.8155 13.4762 13.1904C12.8511 12.5652 12.4999 11.7174 12.4999 10.8333V6.66667C12.4999 6.20643 12.873 5.83333 13.3333 5.83333C13.7935 5.83333 14.1666 6.20643 14.1666 6.66667V10.8333C14.1666 11.2754 14.3422 11.6993 14.6548 12.0118C14.9673 12.3244 15.3912 12.5 15.8333 12.5C16.2753 12.5 16.6992 12.3244 17.0118 12.0118C17.3243 11.6993 17.4999 11.2754 17.4999 10.8333V10.0001C17.4999 10 17.4999 10.0001 17.4999 10.0001C17.4998 8.30734 16.9271 6.6644 15.8749 5.33845C14.8227 4.01249 13.3528 3.08147 11.7044 2.69678C10.056 2.31208 8.32588 2.49633 6.79544 3.21958C5.265 3.94282 4.02423 5.16252 3.27487 6.68034C2.52552 8.19817 2.31165 9.92485 2.66805 11.5796C3.02445 13.2344 3.93016 14.72 5.2379 15.7948C6.54564 16.8695 8.1785 17.4703 9.87098 17.4994C11.5635 17.5285 13.216 16.9842 14.5599 15.9551C14.9253 15.6752 15.4484 15.7446 15.7282 16.11C16.008 16.4754 15.9387 16.9985 15.5733 17.2783C13.9307 18.5362 11.9109 19.2014 9.84233 19.1658C7.77374 19.1303 5.77802 18.396 4.17967 17.0824C2.58132 15.7687 1.47435 13.9531 1.03875 11.9306C0.603146 9.90804 0.864535 7.79765 1.78041 5.94253C2.69629 4.08741 4.21279 2.59667 6.08333 1.7127Z'
      fill='#B1B1B1'
    />
  </svg>
);