import React from 'react';

export const SolarSystemLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M33.6191 35.0003L31.3091 34.0403L29.9891 36.1803L28.6791 34.0303L26.3591 34.9903L26.5591 32.4903L24.1191 31.9003L25.7591 30.0003L24.1291 28.0903L26.5691 27.5003L26.3791 25.0003L28.6891 25.9603L30.0091 23.8203L31.3191 25.9703L33.6391 25.0103L33.4391 27.5103L35.8791 28.1003L34.2391 30.0003L35.8691 31.9103L33.4291 32.5003L33.6191 35.0003Z'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
    <path
      d='M35 46C36.6569 46 38 44.6569 38 43C38 41.3431 36.6569 40 35 40C33.3431 40 32 41.3431 32 43C32 44.6569 33.3431 46 35 46Z'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
    <path
      d='M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
    <path
      d='M32 43.86C31.35 43.95 30.68 44 30 44C22.27 44 16 37.73 16 30C16 22.27 22.27 16 30 16C37.73 16 44 22.27 44 30'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M30 9C41.6 9 51 18.4 51 30C51 41.6 41.6 51 30 51C18.4 51 9 41.6 9 30C9 27.05 9.61 24.25 10.7 21.7'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);