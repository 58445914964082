import React from 'react';
import styled from 'styled-components';
import { ShoppingCartIcon, TruckIcon, CreditCardIcon } from 'icons/';
import { BuyCategoriesKey } from './types';
import { StepIcon, StepText, StepWrap } from './styles';

const Step = styled(StepWrap)``;
const CategoryWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & ${Step} + ${Step} {
    margin-top: 12px;
  }
`;

export const Steps: React.FC<{ stepKey: BuyCategoriesKey }> = ({
  stepKey,
  ...rest
}) => {
  return (
    <CategoryWrap {...rest}>
      <Step isActive={stepKey === 'cart'}>
        <StepIcon>{ShoppingCartIcon}</StepIcon>
        <StepText>Корзина</StepText>
      </Step>
      <Step isActive={stepKey === 'delivery'}>
        <StepIcon>{TruckIcon}</StepIcon>
        <StepText>Доставка</StepText>
      </Step>
      <Step isActive={stepKey === 'payment'}>
        <StepIcon>{CreditCardIcon}</StepIcon>
        <StepText>Оплата</StepText>
      </Step>
    </CategoryWrap>
  );
};
