import React from 'react';

export const AttentionSmallIcon = (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.00039 0C6.53887 0 6.97539 0.436522 6.97539 0.975V6.525C6.97539 7.06348 6.53887 7.5 6.00039 7.5C5.46191 7.5 5.02539 7.06348 5.02539 6.525V0.975C5.02539 0.436522 5.46191 0 6.00039 0Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.00039 9.75005C6.53887 9.75005 6.97539 10.1866 6.97539 10.725C6.97539 11.2635 6.53887 11.7 6.00039 11.7C5.46191 11.7 5.02539 11.2635 5.02539 10.725C5.02539 10.1866 5.46191 9.75005 6.00039 9.75005Z'
    />
  </svg>
);