import styled from 'styled-components';

export const Wrap = styled.div`
  margin-top: 40px;
  width: 706px;
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline1};
  margin: 0;
`;

export const Text = styled.div`
  ${({ theme }) => theme.typography.bodyText2}
  margin-top: 24px;
  display: block;
`;

export const Members = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MembersItem = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const Photo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondColor};
`;

export const Inner = styled.div`
  margin-left: 18px;
`;

export const ItemTitle = styled.div`
  ${({ theme }) => theme.typography.headline3};
`;

export const ItemText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;
