import React, { useState } from 'react';
import { usePlansData } from 'models/education/model';
import { usePlansQuery } from 'queries/education/useMyEducationQuery';
import { RouteComponentProps } from 'react-router';
import { CoursesPlansItem } from '../common/CoursesPlansItem';
import { Inner, List, NoData, StyledPreloader, StyledTabs } from './styles';
import { Link } from 'react-router-dom';
import { Button } from 'ui/Button';
import { Path } from 'constants/paths';

export const SelfEducation: React.FC<RouteComponentProps<{ key: string }>> = ({
  match,
}) => {
  const query = usePlansQuery(match.params.key);
  const data = usePlansData();
  const { courses } = data || {};

  const [selectedTab, setSelectedTab] = useState(0);
  if (query.isLoading || query.isFetching) return <StyledPreloader />;
  if (!data && !query.isLoading)
    return <NoData>У вас нет курсов в процессе</NoData>;

  return (
    <div>
      <StyledTabs
        setSelected={setSelectedTab}
        selected={selectedTab}
        tabNames={['Все', 'Активные', 'Завершенные']}
      />
      <List>
        {(selectedTab === 0 || selectedTab === 1) &&
          courses?.future?.map(item => (
            <CoursesPlansItem key={item.id} {...item} />
          ))}
        {(selectedTab === 0 || selectedTab === 2) &&
          courses?.past?.map(item => (
            <CoursesPlansItem key={item.id} {...item} />
          ))}
        {!courses?.future?.length && !courses?.past?.length ? (
          <Inner>
            <NoData>
              У вас нет активных курсов. Вы можете начать обучение в любое
              время.
            </NoData>
            <Link to={Path.catalogCourses}>
              <Button>Выбрать курс</Button>
            </Link>
          </Inner>
        ) : null}
      </List>
    </div>
  );
};
