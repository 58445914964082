import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { ChevronRightIcon, ChevronRightSmallIcon } from 'icons';
import { ArrowButtonProps } from './types';

const ButtonWrap = styled.button<HTMLButtonElement | ArrowButtonProps>`
  border: none;
  background-color: ${props => props.theme.colors.mainBgColor};
  padding: ${({ size }) =>
    size && size === 'small' ? '0' : '8px 7px 8px 9px'};
  border-radius: 50%;
  font-size: 0;
  transition: all 0.3s;
  outline: none;
  cursor: pointer;
  ${props =>
    props.direction &&
    props.direction !== 'right' &&
    `transform: rotate(${
      props.direction === 'left'
        ? '180deg'
        : props.direction === 'top'
        ? '-90deg'
        : '90deg'
    });`}
  &:hover {
    background-color: ${props => props.theme.colors.secondColor};
  }
  &:focus {
    outline: none;
    ${({ disabled, theme }) =>
      !disabled ? `box-shadow: 0 0 0 2px ${theme.colors.thirdBgColor};` : null}
  }
`;

export const ArrowButton: React.FC<
  ArrowButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = props => {
  return (
    <ButtonWrap type='button' {...props}>
      {props.size && props.size === 'small'
        ? ChevronRightSmallIcon
        : ChevronRightIcon}
    </ButtonWrap>
  );
};

export default ArrowButton;
