class TokenService {
  getToken() {
    return localStorage.getItem('session');
  }

  setToken(token: string) {
    localStorage.setItem('session', token);
  }

  removeToken() {
    localStorage.removeItem('session');
  }
}

export default new TokenService();
