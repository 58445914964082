import React from 'react';

const KeyIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.2803 0.96967C16.5732 1.26256 16.5732 1.73744 16.2803 2.03033L15.3106 3L17.0303 4.71967C17.3232 5.01256 17.3232 5.48744 17.0303 5.78033L14.4053 8.40533C14.1124 8.69822 13.6375 8.69822 13.3446 8.40533L11.625 6.68566L9.56513 8.74549C9.79572 9.05735 9.98881 9.39636 10.1397 9.7556C10.3888 10.3484 10.5181 10.9845 10.5202 11.6275C10.5224 12.2704 10.3973 12.9074 10.1523 13.5018C9.90724 14.0962 9.54703 14.6363 9.0924 15.0909C8.63777 15.5456 8.0977 15.9058 7.50329 16.1508C6.90888 16.3959 6.27186 16.5209 5.62892 16.5188C4.98598 16.5166 4.34982 16.3873 3.75706 16.1383C3.16431 15.8892 2.62666 15.5254 2.17509 15.0678L2.16946 15.0621C1.28144 14.1426 0.790102 12.9112 0.80121 11.633C0.812317 10.3547 1.32502 9.13203 2.22888 8.22817C3.13274 7.3243 4.35546 6.81161 5.63366 6.8005C6.66328 6.79155 7.66255 7.10864 8.49285 7.69645L15.2196 0.96967C15.5125 0.676777 15.9874 0.676777 16.2803 0.96967ZM14.25 4.06066L12.6856 5.625L13.875 6.81434L15.4393 5.25L14.25 4.06066ZM9.07204 9.23858C8.78943 9.5212 8.33648 9.5316 8.04135 9.26696C8.03472 9.26027 8.02807 9.25362 8.02138 9.24699C8.01949 9.24512 8.01759 9.24324 8.0157 9.24137L8.54125 8.70873M3.24566 14.0171C2.63262 13.3809 2.29347 12.5296 2.30115 11.646C2.30884 10.7611 2.66379 9.91458 3.28954 9.28883C3.91529 8.66308 4.76178 8.30813 5.6467 8.30044C6.53161 8.29275 7.38415 8.63293 8.02068 9.24772C8.02748 9.25428 8.03437 9.2607 8.04135 9.26696C8.34616 9.57423 8.5891 9.93735 8.75682 10.3366C8.92922 10.747 9.01875 11.1874 9.02024 11.6325C9.02173 12.0776 8.93516 12.5186 8.76551 12.9301C8.59586 13.3416 8.34648 13.7155 8.03174 14.0303C7.717 14.345 7.3431 14.5944 6.93159 14.764C6.52007 14.9337 6.07906 15.0203 5.63395 15.0188C5.18884 15.0173 4.74842 14.9278 4.33805 14.7554C3.92891 14.5835 3.5577 14.3326 3.24566 14.0171ZM3.24566 14.0171C3.24658 14.0181 3.2475 14.019 3.24843 14.02L2.70896 14.541L3.24283 14.0142C3.24377 14.0152 3.24472 14.0161 3.24566 14.0171ZM9.07204 9.23858C9.07229 9.23833 9.07254 9.23808 9.07279 9.23783L9.07204 9.23858Z'
    />
  </svg>
);

export default KeyIcon;
