import { useMutation } from 'react-query';
import { confirmCodeFx } from 'models/auth/model';
import { confirmCodeQueryProps } from './types';

export const useConfirmCodeQuery: confirmCodeQueryProps = callback =>
  useMutation(
    ({ otp, verification_code }) => {
      return confirmCodeFx({ otp, verification_code });
    },
    {
      onSuccess: () => {
        if (callback) callback();
      },
    }
  );
