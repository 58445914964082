import React from 'react';
import { updateCartItemFx, setCart } from 'models/cart/model';
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CartResponse, UpdateCartItemRequest } from 'models/cart/types';
import CartService from 'services/CartService';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';

export const useCartItemUpdateQuery: () => UseMutationResult<
  AxiosResponse<{ data: CartResponse }>,
  AxiosError<{ error: string }>,
  UpdateCartItemRequest
> = () =>
  useMutation('updateCartItem', params => updateCartItemFx(params), {
    onSuccess: res => {
      CartService.setCart(res.data.data);
      setCart(res.data.data);
    },
    onError: err => {
      toast.error(<ErrorMessage text={err.response?.data.error} />);
    },
  });
