import { RouteComponentProps } from 'react-router';
import { useSberbankTransactionCheckQuery } from 'queries/useSberbankTransactionCheckQuery/useSberbankTransactionCheckQuery';
import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

export const StyledPreloader = styled(Preloader)`
  margin: 30px auto 0;
  padding: 0 0 6px 0;
  text-align: center;
`;

export const SberbankPaymentSuccess: React.FC<
  RouteComponentProps<{ orderId: string; land: string }>
> = props => {
  const params = new URLSearchParams(props.location.search);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const orderId = params.get('orderId')!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const lang = params.get('lang')!;

  const checkTransaction = useSberbankTransactionCheckQuery(
    props.location.pathname,
    { orderId, lang }
  );

  return checkTransaction.isLoading ? <StyledPreloader /> : null;
};
