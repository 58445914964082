import { deleteAddressFx } from 'models/addresses/model';
import React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { SuccessMessage } from 'ui/SuccessMessage';
import { DeleteAddressQuery } from './types';

export const useDeleteAddressQuery: DeleteAddressQuery = () =>
  useMutation('deleteAddress', id => deleteAddressFx(id), {
    onSuccess: () => {
      toast.success(<SuccessMessage text={'Адрес удален'} />);
    },
  });
