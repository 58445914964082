import { useMutation, UseMutationResult } from 'react-query';
import { getCalendarFx, setCalendarData } from 'models/calendar/model';
import { AxiosError, AxiosResponse } from 'axios';
import {
  CalendarDateResponse,
  GetCalendarRequestType,
} from 'models/calendar/types';

type UseGetCalendarQueryType = () => UseMutationResult<
  AxiosResponse<{ data: CalendarDateResponse }>,
  AxiosError<{ error: string }>,
  GetCalendarRequestType
>;

export const UseGetCalendarQuery: UseGetCalendarQueryType = () =>
  useMutation('getCalendar', data => getCalendarFx(data), {
    onSuccess: res => {
      setCalendarData(res.data.data);
    },
    retry: false,
  });
