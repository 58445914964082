import {
  FormattedValidationError,
  ServerValidationError,
} from '../interfaces/formattedValidationError';

type formatValidationErrorsFunctionType = (
  errors?: ServerValidationError
) => FormattedValidationError;

export const formatValidationErrors: formatValidationErrorsFunctionType = errors => {
  if (errors && typeof errors === 'object' && Object.keys(errors).length) {
    const formattedErrors: FormattedValidationError = Object.keys(
      errors
    ).reduce((acc, key) => {
      acc[key] = errors[key].join(', ');
      return acc;
    }, {} as FormattedValidationError);
    return formattedErrors;
  } else {
    return {};
  }
};
