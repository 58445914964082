import { createOrderFx } from 'models/order/model';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import { CreateOrderQuery } from './types';

export const useCreateOrderQuery: CreateOrderQuery = callback =>
  useMutation('orderDetail', createOrderFx, {
    onSuccess: res => {
      if (callback) callback(res.data.order_id);
    },
    onError: res => {
      if (res.response?.data.error) {
        toast.error(<ErrorMessage text={res.response.data.error} />);
      }
    },
  });
