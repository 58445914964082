import styled from 'styled-components';
// import bg from 'images/VIP_chat_banner.png';
import { Button } from 'ui/Button';
import { baseUrl } from 'api/apiClient';

export const Inner = styled.div`
  margin-left: auto;
  width: 280px;
`;

export const Wrap = styled.div<{ src: string }>`
  padding: 64px 110px 64px 0;
  background-image: url(${({ src }) => baseUrl + src});
  background-size: cover;
  border-radius: 14px;
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 16px 0 0;
  color: ${({ theme }) => theme.colors.mainBgColor};
`;
export const Title = styled.h1`
  ${({ theme }) => theme.typography.headline1};
  color: ${({ theme }) => theme.colors.mainBgColor};
  margin: 0;
`;

export const BannerButton = styled(Button)`
  margin-top: 40px;
`;
