import React from 'react';
import { Modal } from 'ui/Modal';
import { ModalProps } from 'ui/Modal/types';
import { CalendarEvent } from 'models/calendar/types';
import {
  Bottom,
  DatetimeText,
  DatetimeTitle,
  Description,
  DescriptionText,
  StyledNoImage,
  Divider,
  Inner,
  Photo,
  PhotoWrap,
  Title,
} from './styles';
import { Tag } from 'ui/Tag';
import { baseUrl } from 'api/apiClient';
import { Link } from 'react-router-dom';
import { DateFormats } from 'constants/dateFormats';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Path } from 'constants/paths';
import { Button } from 'ui/Button';

const titles = {
  course: 'Саморазвитие',
  training: 'Мероприятие',
  offline_event: 'Мероприятие',
  master_class: 'Мастер-класс',
  intensive: 'Интенсив',
  coach_school: 'Inner insight',
  empty: '',
};
const links = {
  course: Path.course,
  training: Path.catalogTrainings,
  offline_event: Path.catalogEvents,
  master_class: Path.vipClubMeetings,
  intensive: Path.vipClubMeetings,
  coach_school: Path.school,
  empty: '',
};

export const EventModal: React.FC<ModalProps & { data?: CalendarEvent }> = ({
  handleClose,
  data,
}) => {
  const datetime = data ? new Date(data.date_start) : null;
  const dayMonthYearDate =
    datetime &&
    format(datetime, DateFormats.StartEventDate, {
      locale: ru,
    });
  const weekDayAndTime =
    datetime &&
    format(datetime, DateFormats.weekDayAndTime, {
      locale: ru,
    });
  return data ? (
    <Modal handleClose={handleClose}>
      <div>
        <Tag status={'progress'} ghost text={titles[data.type]} />
      </div>
      <Title>{data.name}</Title>
      <Inner>
        <PhotoWrap>
          {data.picture ? (
            <Photo src={baseUrl + data.picture} />
          ) : (
            <StyledNoImage />
          )}
        </PhotoWrap>
        <Description>
          {datetime ? (
            <div>
              <DatetimeTitle>{dayMonthYearDate}</DatetimeTitle>
              <DatetimeText>{weekDayAndTime}</DatetimeText>
            </div>
          ) : null}
          {data.place ? <DescriptionText>{data.place}</DescriptionText> : null}
        </Description>
      </Inner>
      <Divider />
      <Bottom>
        {data.type !== 'empty' ? (
          <Link to={links[data.type] + '/' + data.id}>
            <Button>Подробнее</Button>
          </Link>
        ) : null}
      </Bottom>
    </Modal>
  ) : null;
};
