import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Wrap,
  Content,
  Sidebar,
  Title,
  NotificationsWrap,
  NotificationItem,
  NotificationPicture,
  Inner,
  NotificationTitle,
  NotificationDate,
  NotificationText,
  StyledButton,
  NoData,
  StyledPreloader,
  StyledButtonPreloader,
} from './styles';
import { Button } from 'ui/Button';
import { Path } from 'constants/paths';
import { ChevronDownSmallIcon, ChevronLeftIcon } from 'icons';
import { useNotificationsData } from 'models/notifications/';
import { useGetAllNotificationsQuery } from 'queries/notifications/useGetAllNotificationsQuery';
import { useMarkAsReadNotificationsQuery } from 'queries/notifications/useMarkAsReadNotificationsQuery';
import { format, isToday } from 'date-fns';
import { DateFormats } from 'constants/dateFormats';
import { ru } from 'date-fns/locale';
import USER_BIRTHDAY_IMAGE from 'images/USER_BIRTHDAY_IMAGE.png';
import HOMEWORK_ACTIVE_IMAGE from 'images/HOMEWORK_ACTIVE_IMAGE.png';
import COURSE_IMAGE from 'images/COURSE_IMAGE.png';
import COURSE_END_IMAGE from 'images/COURSE_END_IMAGE.png';
import INTENSIVE_EVENTS_IMAGE from 'images/INTENSIVE_EVENTS_IMAGE.png';
import HOMEWORK_DONE_IMAGE from 'images/HOMEWORK_DONE_IMAGE.png';
import TRAINING_EVENTS_IMAGE from 'images/TRAINING_EVENTS_IMAGE.png';
import OFFLINE_EVENTS_IMAGE from 'images/OFFLINE_EVENTS_IMAGE.png';
import LECTURE_ACTIVE_IMAGE from 'images/LECTURE_ACTIVE_IMAGE.png';
import VIP_CLUB_IMAGE from 'images/VIP_CLUB_IMAGE.png';

const LIMIT = 6;
const OFFSET = 6;
const typeImages = {
  USER_BIRTHDAY: USER_BIRTHDAY_IMAGE,
  TRAINING_EVENTS: TRAINING_EVENTS_IMAGE,
  OFFLINE_EVENTS: OFFLINE_EVENTS_IMAGE,
  INTENSIVE_EVENTS: INTENSIVE_EVENTS_IMAGE,
  VIP_CLUB_PRICE: VIP_CLUB_IMAGE,
  VIP_CLUB_SUBSCRIBE: VIP_CLUB_IMAGE,
  COURSE_ACTIVE: COURSE_IMAGE,
  COURSE: COURSE_IMAGE,
  COURSE_END: COURSE_END_IMAGE,
  LECTURE_ACTIVE: LECTURE_ACTIVE_IMAGE,
  HOMEWORK_ACTIVE: HOMEWORK_ACTIVE_IMAGE,
  HOMEWORK_DONE: HOMEWORK_DONE_IMAGE,
};

export const Notifications: React.FC = () => {
  const data = useNotificationsData();
  const [offset, setOffset] = useState(0);
  const { mutate } = useMarkAsReadNotificationsQuery();
  const getAllNotificationsQuery = useGetAllNotificationsQuery({
    limit: LIMIT,
    offset,
  });

  const getMore = () => {
    setOffset(offset + OFFSET);
    getAllNotificationsQuery.refetch();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      mutate();
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [mutate]);

  const showButton = data.length % 6 === 0;

  return (
    <Wrap>
      <Sidebar>
        <Link to={Path.catalog}>
          <Button buttonType='link' icon={ChevronLeftIcon}>
            На главную
          </Button>
        </Link>
      </Sidebar>
      <Content>
        <Title>Уведомления</Title>
        {!getAllNotificationsQuery.isLoading && !data ? (
          <NoData>Нет данных</NoData>
        ) : null}
        {getAllNotificationsQuery.isLoading && !data ? (
          <StyledPreloader />
        ) : null}
        {!getAllNotificationsQuery.isLoading && data ? (
          <NotificationsWrap>
            {data.map((item, index) => (
              <NotificationItem key={index} isNew={!item.isRead}>
                <NotificationPicture>
                  <img src={typeImages[item.event]} alt='Kitten' />
                </NotificationPicture>
                <Inner>
                  <NotificationTitle>{item.name} </NotificationTitle>
                  <NotificationText>{item.message}</NotificationText>
                </Inner>
                <NotificationDate>
                  {isToday(new Date(item.dataSend))
                    ? 'Сегодня'
                    : format(
                        new Date(item.dataSend),
                        DateFormats.FullDateAsDigits,
                        { locale: ru }
                      )}
                </NotificationDate>
              </NotificationItem>
            ))}
          </NotificationsWrap>
        ) : null}
        {getAllNotificationsQuery.isFetching ? (
          <StyledButtonPreloader />
        ) : showButton ? (
          <StyledButton
            buttonType='link'
            icon={ChevronDownSmallIcon}
            iconAlign={'right'}
            onClick={getMore}
          >
            Показать ещё
          </StyledButton>
        ) : null}
      </Content>
    </Wrap>
  );
};
