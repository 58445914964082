import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';
import { TrainingItem } from '../../common/TrainingItem';

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledTrainingItem = styled(TrainingItem)`
  margin-top: 24px;
`;

export const StyledPreloader = styled(Preloader)`
  grid-area: content;
  margin: 120px auto 0;
  text-align: center;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;
