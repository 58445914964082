import React, { useState } from 'react';
import { DiplomaItem } from './DiplomaItem';
import bannerImage from 'images/diploma.png';
import {
  Wrap,
  DiplomasList,
  NoData,
  RightBannerColumn,
  Content,
} from './styled';
import Title from './Title';
import { Tabs } from 'ui/Tabs';
import { useDiplomasQuery } from 'queries/account/diplomas/useDiplomasQuery';
import { Preloader } from 'ui/Preloader';
import { TDiplomasResponse } from 'queries/account/diplomas/types';
import { EmptyItem } from './EmptyItem/EmptyItem';
import { MiniBanner } from './MiniBanner';

export enum Filter {
  ALL,
  OWNED,
  AVAILABLE,
}

function filterData(sortType: Filter, data?: TDiplomasResponse) {
  if (!data) return;

  if (sortType === Filter.ALL) return [...data.available, ...data.my_diplomas];
  if (sortType === Filter.OWNED) return data.my_diplomas;
  if (sortType === Filter.AVAILABLE) return data.available;
}

export const Diplomas: React.FC = () => {
  const { data, isLoading, isError } = useDiplomasQuery();
  const [sortType, setSortType] = useState(Filter.ALL);
  const filteredDiplomas = filterData(sortType, data?.data.data);
  return (
    <Wrap>
      <Title>{'Дипломы'}</Title>
      <Tabs
        tabNames={['Все', 'Полученные', 'Доступные']}
        selected={sortType}
        setSelected={setSortType}
      />
      <Content>
        <DiplomasList>
          {isError ? (
            <NoData>Не удалось загрузить данные о дипломах</NoData>
          ) : isLoading ? (
            <Preloader />
          ) : filteredDiplomas?.length && data ? (
            filteredDiplomas.map(item => (
              <DiplomaItem data={item} key={item.id} />
            ))
          ) : (
            <EmptyItem sortType={sortType} />
          )}
        </DiplomasList>
        <RightBannerColumn>
          <MiniBanner
            image={bannerImage}
            subtitle={'Выполняйте задания, проходите саморазвитие'}
            title={'Открывайте для себя новые знания и новые дипломы'}
          />
        </RightBannerColumn>
      </Content>
    </Wrap>
  );
};
