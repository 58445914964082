import React, { ChangeEvent } from 'react';
import { Field, useField } from 'react-final-form';
import { HouseSuggestion } from './HouseSuggestion';
import { useGetHouseDaDataSuggestionQuery } from 'queries/buy/daData/useHouseDaDataSuggestionQuery';
import { useDebouncedCallback } from 'use-debounce';

export const HouseSmartInput: React.FC = props => {
  const {
    mutate,
    data: suggestions,
    isLoading,
  } = useGetHouseDaDataSuggestionQuery();
  const cityField = useField('city');
  const streetField = useField('address');
  const countryField = useField('country');

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target || {};
    const {
      input: { value: city },
    } = cityField;
    const {
      input: { value: address },
    } = streetField;
    if (value) {
      mutate({
        country: countryField.input.value,
        address: `${city}, ${address}, ${value}`,
      });
    }
  };
  const debouncedHandleChange = useDebouncedCallback(handleOnChange, 300);

  return (
    <Field
      name={'house'}
      onChange={debouncedHandleChange}
      labelWidth={144}
      inputWidth={340}
      suggestions={suggestions}
      component={HouseSuggestion}
      isLoading={isLoading}
      {...props}
    />
  );
};
