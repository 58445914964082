import React from 'react';
import { Title, List, AudioItem, DocumentItem } from './styles';
import { LessonFile } from 'models/lesson/types';

type MaterialsProps = {
  audio: LessonFile[];
  documents: LessonFile[];
};

export const Materials: React.FC<MaterialsProps> = ({
  audio,
  documents,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Title>Материалы</Title>
      <List>
        {audio.map((item, index) => (
          <AudioItem key={index} {...item} />
        ))}
        {documents.map((item, index) => (
          <DocumentItem key={index} {...item} />
        ))}
      </List>
    </div>
  );
};
