import styled from 'styled-components';

export const SelectAndPreview = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
`;

export const Confirm = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6%;
`;

export const PreviewImage = styled.img`
  width: 80px;
  height: 80px;
`;

export const NoImagePreview = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    fill: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  }
`;

export const PreviewTitle = styled.p`
  ${({ theme }) => theme.typography.headline2}
  margin:  24px 0;
`;
