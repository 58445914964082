import React from 'react';

export const GiftIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.33333 9.16675C3.79357 9.16675 4.16667 9.53984 4.16667 10.0001V17.5001H15.8333V10.0001C15.8333 9.53984 16.2064 9.16675 16.6667 9.16675C17.1269 9.16675 17.5 9.53984 17.5 10.0001V18.3334C17.5 18.7937 17.1269 19.1667 16.6667 19.1667H3.33333C2.8731 19.1667 2.5 18.7937 2.5 18.3334V10.0001C2.5 9.53984 2.8731 9.16675 3.33333 9.16675Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.833496 5.83333C0.833496 5.3731 1.20659 5 1.66683 5H18.3335C18.7937 5 19.1668 5.3731 19.1668 5.83333V10C19.1668 10.4602 18.7937 10.8333 18.3335 10.8333H1.66683C1.20659 10.8333 0.833496 10.4602 0.833496 10V5.83333ZM2.50016 6.66667V9.16667H17.5002V6.66667H2.50016Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.99984 5C10.4601 5 10.8332 5.3731 10.8332 5.83333V18.3333C10.8332 18.7936 10.4601 19.1667 9.99984 19.1667C9.5396 19.1667 9.1665 18.7936 9.1665 18.3333V5.83333C9.1665 5.3731 9.5396 5 9.99984 5Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.25016 2.49992C5.91864 2.49992 5.6007 2.63161 5.36628 2.86604C5.13186 3.10046 5.00016 3.4184 5.00016 3.74992C5.00016 4.08144 5.13186 4.39938 5.36628 4.6338C5.6007 4.86822 5.91864 4.99992 6.25016 4.99992H8.87851C8.77678 4.73376 8.648 4.4411 8.48873 4.15152C7.96459 3.19854 7.24508 2.49992 6.25016 2.49992ZM10.0002 5.83325C10.8173 5.66982 10.8173 5.66954 10.8172 5.66924L10.8171 5.66855L10.8167 5.6669L10.8158 5.66244L10.813 5.64903C10.8107 5.63811 10.8075 5.62329 10.8034 5.60482C10.7951 5.5679 10.7832 5.5163 10.7671 5.4521C10.7351 5.32389 10.6865 5.14444 10.6188 4.93063C10.4844 4.50602 10.2696 3.93104 9.94909 3.34832C9.3274 2.21797 8.17191 0.833252 6.25016 0.833252C5.47661 0.833252 4.73475 1.14054 4.18777 1.68752C3.64079 2.2345 3.3335 2.97637 3.3335 3.74992C3.3335 4.52347 3.64079 5.26533 4.18777 5.81231C4.73475 6.35929 5.47661 6.66659 6.25016 6.66659H10.0002C10.2498 6.66659 10.4863 6.55465 10.6446 6.36158C10.8029 6.1685 10.8663 5.91463 10.8173 5.66982L10.0002 5.83325Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.0509 3.34832C10.6726 2.21797 11.8281 0.833252 13.7498 0.833252C14.5234 0.833252 15.2653 1.14054 15.8122 1.68752C16.3592 2.2345 16.6665 2.97637 16.6665 3.74992C16.6665 4.52347 16.3592 5.26533 15.8122 5.81231C15.2653 6.35929 14.5234 6.66659 13.7498 6.66659H9.99984C9.75018 6.66659 9.51367 6.55465 9.35539 6.36158C9.19711 6.1685 9.13373 5.91463 9.18269 5.66982L9.99984 5.83325C9.18269 5.66982 9.18275 5.66954 9.18281 5.66924L9.18295 5.66855L9.18328 5.6669L9.18419 5.66244L9.18699 5.64903C9.1893 5.63811 9.19249 5.62329 9.19662 5.60482C9.20486 5.5679 9.21685 5.5163 9.2329 5.4521C9.26495 5.32389 9.31347 5.14444 9.38117 4.93063C9.51564 4.50602 9.73042 3.93104 10.0509 3.34832ZM11.1215 4.99992H13.7498C14.0814 4.99992 14.3993 4.86822 14.6337 4.6338C14.8681 4.39938 14.9998 4.08144 14.9998 3.74992C14.9998 3.4184 14.8681 3.10046 14.6337 2.86604C14.3993 2.63161 14.0814 2.49992 13.7498 2.49992C12.7549 2.49992 12.0354 3.19854 11.5113 4.15152C11.352 4.4411 11.2232 4.73376 11.1215 4.99992ZM10.8167 5.99809C10.8167 5.99831 10.8167 5.99829 10.8167 5.99802L10.8167 5.99809Z'
      fill='#181818'
    />
  </svg>
);

