import React, { memo, useEffect } from 'react';
import { Container, Title, Wrap, List, Item } from './styles';
import { RouteComponentProps } from 'react-router';
import { Path } from 'constants/paths';
import { useCatalogQuery } from 'queries/catalog/useCatalogQuery';
import { CatalogItem } from 'models/catalog/types';
import { clearCatalog } from 'models/catalog';
import {
  Btn,
  CatalogItemTitle,
  Image,
  ImageWrap,
  Price,
  StyledNoImage,
  Text,
  StyledPreloader,
} from './styles';
import { baseUrl } from 'api/apiClient';
import { formatPriceToString } from 'utilities/formatPriceToString';
import Ruble from 'components/common/Ruble';
import { showAuthModal } from 'models/auth';
import { useUserProfile } from 'models/auth/model';
import { useHistory } from 'react-router-dom';
import { useAddCartItemQuery } from 'queries/cart/useAddCartItemQuery';
import { useGoodsCatalogQuery } from 'queries/catalog/useGoodsCatalogQuery';
import { useRecommendedGoods } from 'models/catalog/model';

const RecommendedGoods: React.FC<{ data: CatalogItem[] }> = ({ data }) => {
  const mutation = useAddCartItemQuery();
  const history = useHistory();
  const user = useUserProfile();
  const [
    {
      isLoading: isClothesLoading,
      isFetching: isClothesFetching,
      isError: isClothesError,
    },
    {
      isLoading: isMerchLoading,
      isFetching: isMerchFetching,
      isError: isMerchError,
    },
  ] = useGoodsCatalogQuery();

  const showError = isClothesError || isMerchError;

  const showPreloader =
    isClothesLoading || isClothesFetching || isMerchLoading || isMerchFetching;

  useEffect(() => {
    return () => {
      clearCatalog();
    };
  }, []);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: CatalogItem
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      showAuthModal();
      return;
    }
    if (!item.has_proposals) {
      mutation.mutate({ id: item.id, count: '1' });
    } else {
      history.push(Path.catalogGoods + '/' + item.id);
    }
  };

  if (showError) {
    return null;
  }

  return (
    <Wrap>
      <Container>
        {showPreloader ? (
          <StyledPreloader />
        ) : (
          <>
            <Title>С этим товаром покупают</Title>
            <List>
              {data.map(item => (
                <Item key={item.id} to={`${Path.catalogGoods}/${item.id}`}>
                  <ImageWrap>
                    <>
                      {item.detail ? (
                        <Image src={baseUrl + item.detail} alt={item.name} />
                      ) : (
                        <StyledNoImage />
                      )}
                    </>
                  </ImageWrap>
                  <CatalogItemTitle>{item.name}</CatalogItemTitle>
                  <Text>{item.preview_text}</Text>
                  <Price>
                    {formatPriceToString(item.price)} {Ruble}
                  </Price>
                  <Btn onClick={e => handleClick(e, item)}>В корзину</Btn>
                </Item>
              ))}
            </List>
          </>
        )}
      </Container>
    </Wrap>
  );
};

const RecommendedBooks: React.FC<{ data: CatalogItem[] }> = ({ data }) => {
  const mutation = useAddCartItemQuery();
  const history = useHistory();
  const user = useUserProfile();
  const {
    isLoading: isBooksLoading,
    isFetching: isBooksFetching,
    isError: isBooksError,
  } = useCatalogQuery('books');

  const showError = isBooksError;

  const showPreloader = isBooksLoading || isBooksFetching;

  useEffect(() => {
    return () => {
      clearCatalog();
    };
  }, []);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: CatalogItem
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      showAuthModal();
      return;
    }
    if (!item.has_proposals) {
      mutation.mutate({ id: item.id, count: '1' });
    } else {
      history.push(Path.catalogBooks + '/' + item.id);
    }
  };

  if (showError) {
    return null;
  }

  return (
    <Wrap>
      <Container>
        {showPreloader ? (
          <StyledPreloader />
        ) : (
          <>
            <Title>С этим товаром покупают</Title>
            <List>
              {data.map(item => (
                <Item key={item.id} to={`${Path.catalogGoods}/${item.id}`}>
                  <ImageWrap>
                    <>
                      {item.detail ? (
                        <Image src={baseUrl + item.detail} alt={item.name} />
                      ) : (
                        <StyledNoImage />
                      )}
                    </>
                  </ImageWrap>
                  <CatalogItemTitle>{item.name}</CatalogItemTitle>
                  <Text>{item.preview_text}</Text>
                  <Price>
                    {formatPriceToString(item.price)} {Ruble}
                  </Price>
                  <Btn onClick={e => handleClick(e, item)}>В корзину</Btn>
                </Item>
              ))}
            </List>
          </>
        )}
      </Container>
    </Wrap>
  );
};

export const RecommendedComponent: React.FC<
  RouteComponentProps<{ key: string; id: string }>
> = ({ match }) => {
  const { id, key: type } = match.params;
  const data: CatalogItem[] = useRecommendedGoods(id);

  if (type === 'books') {
    return <RecommendedBooks data={data} />;
  } else if (type === 'goods' || type === 'gift-cards') {
    return <RecommendedGoods data={data} />;
  } else {
    return null;
  }
};

export const Recommended = memo(RecommendedComponent);
