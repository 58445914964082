import { baseUrl } from '../api/apiClient';

export const getImagePath: (
  data?: Record<string, unknown> | null
) => string = data => {
  if (!data) return '';

  if (Object.prototype.hasOwnProperty.call(data, 'picture') && data.picture) {
    return baseUrl + data.picture;
  }

  if (Object.prototype.hasOwnProperty.call(data, 'pictures')) {
    if (Array.isArray(data.pictures) && data.pictures.length) {
      if (Object.prototype.hasOwnProperty.call(data.pictures[0], 'path')) {
        return baseUrl + data.pictures[0].path;
      } else {
        return baseUrl + data.pictures[0];
      }
    } else {
      return '';
    }
  }

  if (Object.prototype.hasOwnProperty.call(data, 'image') && data.image) {
    return baseUrl + data.image;
  }

  if (Object.prototype.hasOwnProperty.call(data, 'images')) {
    if (Array.isArray(data.images) && data.images.length) {
      if (Object.prototype.hasOwnProperty.call(data.images[0], 'path')) {
        return baseUrl + data.images[0].path;
      } else {
        return baseUrl + data.images[0];
      }
    } else {
      return '';
    }
  }

  return '';
};
