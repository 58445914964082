import styled from 'styled-components';
import { InputText } from 'ui/InputText';
import { Button } from 'ui/Button';
import { CodeInput } from 'ui/CodeInput';
import { Countdown } from '../Countdown';

export const Wrap = styled.div`
  text-align: center;
`;

export const Title = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.headline2};
`;

export const Text = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
`;

export const Phone = styled(InputText)`
  margin: 24px 0 0;
`;

export const RestoreButton = styled(Button)`
  margin: 24px 0 0;
  width: 340px;
`;

export const SmsCode = styled(CodeInput)`
  margin: 24px 0 0;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  opacity: 0.5;
`;

export const StyledCountdown = styled(Countdown)`
  width: 177px;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;
