import React from 'react';

export const SmallLogoIcon = (
  <svg width='40' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 9.655A9.655 9.655 0 019.655 0h20.69C35.677 0 40 4.323 40 9.655v20.69A9.655 9.655 0 0130.345 40H9.655C4.323 40 0 35.677 0 30.345V9.655z'
      fill='#FED74D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.051 7.586c.101 0 .187.043.252.111.066.065.11.15.11.252V32.05c0 .2-.161.363-.36.363H7.949a.348.348 0 01-.143-.03.25.25 0 01-.064-.044H7.74a.368.368 0 00-.018-.012c-.01-.007-.021-.013-.03-.022-.007-.007-.013-.016-.018-.025l-.015-.023a.551.551 0 00-.014-.018c-.011-.016-.024-.032-.03-.05a.355.355 0 01-.029-.14V7.947c0-.2.162-.36.363-.36H32.05zm-19.05 1.28H8.863v22.268H19.52v-2.026h-6.5v-.97c0-.304.305-.304.305-.304h6.195v-3.257h-6.5v-.97c0-.305.305-.305.305-.305h6.195V20.42h-6.5v-.97c0-.304.305-.304.305-.304h6.195V16.59h-5.88a.652.652 0 01-.453-.187.647.647 0 01-.187-.451V8.865zm7.797 22.268h10.336V8.866H14.278v6.445h5.884a.64.64 0 01.64.64v11.833a23.958 23.958 0 001.44-.129c.654-.078 1.091-.157 1.374-.223a3.34 3.34 0 00.494-.141l.007-.003c.006-.002.014-.005.02-.009.405-.15.746-.286 1.12-.607.198-.172.324-.29.402-.37a.76.76 0 00.126-.166l.008-.015c.018-.032.038-.075.063-.136a.656.656 0 01.16-.225l.002-.002.002-.001h.002l.001-.002a.646.646 0 01.4-.159h.022a.644.644 0 01.637.594.466.466 0 01-.002.09l-.002.025c-.003.01-.003.02-.004.03 0 .004 0 .01-.002.014a.697.697 0 01-.04.136c-.037.09-.079.179-.13.276-.053.095-.12.19-.194.283-.155.186-.336.351-.615.593-.556.483-1.118.695-1.538.852-.11.04-.318.113-.655.19-.34.075-.818.161-1.507.244a24.65 24.65 0 01-1.595.142v2.07zm5.51-18.594a1.45 1.45 0 11-2.9.001 1.45 1.45 0 012.9 0z'
      fill='#181818'
    />
  </svg>
);
