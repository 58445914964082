import React from 'react';

export const PuzzleIcon = (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.9995 29.539H5.8297C3.9737 29.539 2.46094 28.0263 2.46094 26.1703V15.1741H7.73655C8.41745 15.1741 8.80613 15.9515 8.39759 16.4962C8.09249 16.903 7.92723 17.2462 7.92723 17.9073C7.92723 18.4158 8.1497 18.8988 8.54378 19.223C8.94421 19.5535 9.44635 19.687 9.97391 19.5917C10.8447 19.4328 11.4295 18.5874 11.3468 17.6213C11.3087 17.1954 11.1879 16.8903 10.8892 16.4962C10.4807 15.9515 10.8693 15.1741 11.5502 15.1741H15.9995V16.8267H12.885C12.9422 17.0365 12.974 17.2526 12.9994 17.4814C13.1583 19.2993 11.9888 20.9074 10.279 21.2188C9.28109 21.4032 8.26411 21.1362 7.49501 20.4942C6.71956 19.8523 6.27463 18.9116 6.27463 17.9073C6.27463 17.5132 6.31277 17.1573 6.40175 16.8267H4.11354V26.1703C4.11354 27.1173 4.88263 27.8864 5.8297 27.8864H15.9995V29.539Z'
      fill='#181818'
    />
    <path
      d='M26.168 29.5388H15.1719V24.2632C15.1719 23.5823 15.9492 23.1936 16.494 23.6021C16.9007 23.9072 17.244 24.0725 17.905 24.0725C18.4135 24.0725 18.8966 23.85 19.2207 23.456C19.5513 23.0555 19.6847 22.5534 19.5894 22.0258C19.4305 21.1614 18.5851 20.5639 17.619 20.6529C17.1931 20.691 16.888 20.8118 16.494 21.1105C15.9492 21.5191 15.1719 21.1304 15.1719 20.4495V16.0002H16.8245V19.1147C17.0342 19.0575 17.2503 19.0257 17.4792 19.0003C19.297 18.835 20.9051 20.0109 21.2166 21.7207C21.4009 22.7186 21.1339 23.7356 20.492 24.5047C19.85 25.2738 18.9093 25.7187 17.905 25.7187C17.5109 25.7187 17.155 25.6806 16.8245 25.5916V27.8862H26.168C27.1151 27.8862 27.8842 27.1171 27.8842 26.17V16.0002H29.5368V26.17C29.5368 28.026 28.024 29.5388 26.168 29.5388Z'
      fill='#181818'
    />
    <path
      d='M29.5386 16.8265H24.263C23.5821 16.8265 23.1934 16.0491 23.602 15.5044C23.9071 15.0976 24.0723 14.7543 24.0723 14.0933C24.0723 13.5848 23.8498 13.1017 23.4558 12.7712C23.0553 12.4407 22.5532 12.3072 22.0256 12.4026C21.1612 12.5615 20.5701 13.4068 20.6527 14.373C20.6908 14.7988 20.8116 15.1039 21.1103 15.498C21.5189 16.0427 21.1302 16.8201 20.4493 16.8201H16V15.1739H19.1145C19.0573 14.9641 19.0255 14.748 19.0001 14.5192C18.8412 12.7013 20.0107 11.0932 21.7205 10.7818C22.7185 10.5974 23.7354 10.8644 24.5045 11.5064C25.2736 12.1483 25.7186 13.0954 25.7186 14.0933C25.7186 14.4874 25.6804 14.8433 25.5914 15.1739H27.886V5.83031C27.886 4.88324 27.1169 4.11415 26.1698 4.11415H16V2.46155H26.1698C28.0258 2.46155 29.5386 3.97431 29.5386 5.83031V16.8265Z'
      fill='#181818'
    />
    <path
      d='M16.8258 16.0002H15.1732V12.8856C14.9635 12.9428 14.7474 12.9746 14.5186 13.0001C12.7071 13.1653 11.0926 11.9894 10.7811 10.2796C10.5968 9.2817 10.8638 8.26472 11.5057 7.49562C12.1477 6.72017 13.0884 6.27524 14.0927 6.27524C14.4868 6.27524 14.8427 6.31338 15.1732 6.40236V4.11415H5.8297C4.88263 4.11415 4.11354 4.88324 4.11354 5.83031V16.0002H2.46094V5.83031C2.46094 3.97431 3.9737 2.46155 5.8297 2.46155H16.8258V7.73716C16.8258 8.41806 16.0485 8.80674 15.5038 8.3982C15.097 8.0931 14.7537 7.92784 14.0927 7.92784C13.5842 7.92784 13.1011 8.15031 12.777 8.54439C12.4401 8.94483 12.313 9.45332 12.4083 9.97452C12.5672 10.839 13.4062 11.4364 14.3787 11.3475C14.8046 11.3093 15.1097 11.1885 15.5038 10.8898C16.0485 10.4813 16.8258 10.8699 16.8258 11.5508V16.0002Z'
      fill='#181818'
    />
  </svg>
);

