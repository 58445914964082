import React, { useState } from 'react';
import { MonthCalendarProps } from '../types';
import {
  WeekDays,
  Day,
  MonthDaysWrap,
  DayDate,
  Cell,
  EventLine,
  EventLineText,
} from './styles';
import { Link } from 'react-router-dom';
import { EventModal } from './EventModal';

export const MonthCalendar: React.FC<MonthCalendarProps> = ({
  days,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<{
    id: number;
    index: number;
  } | null>(null);

  const handleOpen = (id: number, index: number) => {
    if (id && index) {
      setVisible(true);
      setCurrentEvent({ id, index });
    }
  };
  const handleClose = () => setVisible(false);
  return (
    <div {...rest}>
      {visible && !!currentEvent && (
        <EventModal
          data={
            days[currentEvent.index].events?.find(
              event => event.details.id === currentEvent.id
            )?.details
          }
          handleClose={handleClose}
        />
      )}
      <WeekDays>
        <Day>Пн</Day>
        <Day>Вт</Day>
        <Day>Ср</Day>
        <Day>Чт</Day>
        <Day>Пт</Day>
        <Day>Сб</Day>
        <Day>Вс</Day>
      </WeekDays>
      <MonthDaysWrap>
        {days.map((item, index) => (
          <Cell key={index}>
            <DayDate to={item.day ?? ''} as={item.day ? Link : 'div'}>
              {item.day}
            </DayDate>
            {item.events?.map((item, idx) => (
              <EventLine
                key={idx}
                {...item}
                onClick={() => handleOpen(item.details.id, index)}
              >
                <EventLineText>{item.title}</EventLineText>
              </EventLine>
            ))}
          </Cell>
        ))}
      </MonthDaysWrap>
    </div>
  );
};
