import styled from 'styled-components';
import { InputPhone } from 'ui/InputPhone';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
`;

export const Phone = styled(InputPhone)`
  margin: 24px 0 0;
`;
