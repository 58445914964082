import styled from 'styled-components';
import { Button } from 'ui/Button';
import { InputText } from 'ui/InputText';

export const FormSC = styled.form`
  display: flex;
  flex-direction: column;
  button {
    align-self: center;
    margin-top: 16px;
  }
`;

export const InputTextSC = styled(InputText)`
  margin: 24px 0 0;
`;

export const Title = styled.div`
  margin-bottom: 30px;
  ${({ theme }) => theme.typography.headline2};
`;

export const Wrapp = styled.div`
  width: 697px;
`;

export const ButtonSC = styled(Button)`
  margin: 24px 160px 0;
`;
