import React from 'react';

const BenefitIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.7803 3.21967C15.0732 3.51256 15.0732 3.98744 14.7803 4.28033L4.28033 14.7803C3.98744 15.0732 3.51256 15.0732 3.21967 14.7803C2.92678 14.4874 2.92678 14.0126 3.21967 13.7197L13.7197 3.21967C14.0126 2.92678 14.4874 2.92678 14.7803 3.21967Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.875 3.75C4.25368 3.75 3.75 4.25368 3.75 4.875C3.75 5.49632 4.25368 6 4.875 6C5.49632 6 6 5.49632 6 4.875C6 4.25368 5.49632 3.75 4.875 3.75ZM2.25 4.875C2.25 3.42525 3.42525 2.25 4.875 2.25C6.32475 2.25 7.5 3.42525 7.5 4.875C7.5 6.32475 6.32475 7.5 4.875 7.5C3.42525 7.5 2.25 6.32475 2.25 4.875Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.125 12C12.5037 12 12 12.5037 12 13.125C12 13.7463 12.5037 14.25 13.125 14.25C13.7463 14.25 14.25 13.7463 14.25 13.125C14.25 12.5037 13.7463 12 13.125 12ZM10.5 13.125C10.5 11.6753 11.6753 10.5 13.125 10.5C14.5747 10.5 15.75 11.6753 15.75 13.125C15.75 14.5747 14.5747 15.75 13.125 15.75C11.6753 15.75 10.5 14.5747 10.5 13.125Z'
    />
  </svg>
);

export default BenefitIcon;
