import React from 'react';

export const CheckSmallIcon = (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.1457 2.37343C11.4917 2.73027 11.483 3.30005 11.1261 3.64607L4.93864 9.64607C4.58954 9.98459 4.03468 9.98459 3.68558 9.64607L0.873079 6.9188C0.516242 6.57277 0.507476 6.00299 0.8535 5.64616C1.19952 5.28932 1.7693 5.28055 2.12614 5.62658L4.31211 7.7463L9.87308 2.35385C10.2299 2.00783 10.7997 2.01659 11.1457 2.37343Z'
    />
  </svg>
);