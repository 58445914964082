import styled from 'styled-components';
import { Tag } from 'ui/Tag';
import { BgColors } from 'models/education/types';
import { theme } from 'ui/theme';

export const bgColors: Record<BgColors, string> = {
  black: 'black',
  gray: theme.colors.secondBgColor,
  yellow: theme.colors.actionColor,
};

export const ImageWrap = styled.div<{ bg_color: BgColors; image: string }>`
  height: 256px;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  background-color: ${({ bg_color, image }) => !!image && bgColors[bg_color]};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CatalogItemWrap = styled.div`
  flex-basis: calc((100% - 48px) / 2);
  margin: 0 12px 40px;
  padding-bottom: 24px;
`;

export const TagItem = styled(Tag)``;

export const EventDateWrap = styled.div`
  margin-top: 24px;
  display: flex;
`;

export const EventBeginning = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const EventDate = styled.span`
  ${({ theme }) => theme.typography.bodyText1};
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const EventDuration = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0 0 0 40px;
`;

export const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px -5px 0;
  & ${TagItem} {
    margin: 0 5px;
  }
`;
export const CatalogItemTitle = styled.div`
  ${({ theme }) => theme.typography.headline2};
  margin-top: 12px;
  max-width: 100%;
`;
