import React, { useMemo, useState } from 'react';
import { ChevronDownSmallIcon } from 'icons/';
import Button from 'ui/Button/Button';
import { NewsItem } from '../NewsItem';
import { useNewsQuery } from 'queries/news/useNewsQuery';
import { MoreButton, NewsWrap, NoData, PreloaderSC } from './styled';
import { NewsListItem } from 'models/news/types';
import { useStore } from 'effector-react';
import { $news } from 'models/news/model';

function useNewsLayout(data: NewsListItem[]) {
  // TODO: переделать на useSelector
  return useMemo(() => {
    if (!data) return;

    const col1: Array<JSX.Element> = [];
    const col2: Array<JSX.Element> = [];
    const col3: Array<JSX.Element> = [];
    const col4: Array<JSX.Element> = [];

    data.forEach((item, index) => {
      let contained = false;

      switch ((index + 1) % 12) {
        case 2:
        case 7:
        case 9:
        case 0:
          contained = true;
      }

      const element = (
        <NewsItem contained={contained} key={item.id} item={item} />
      );

      switch (index % 4) {
        case 0:
          col1.push(element);
          break;
        case 1:
          col2.push(element);
          break;
        case 2:
          col3.push(element);
          break;
        default:
          col4.push(element);
      }
    });

    return [
      <div key='col1'>{col1}</div>,
      <div key='col2'>{col2}</div>,
      <div key='col3'>{col3}</div>,
      <div key='col4'>{col4}</div>,
    ];
  }, [data]);
}

export const NewsList: React.FC = () => {
  const [offset, setOffset] = useState(0);
  const { data, isLoading } = useNewsQuery({
    limit: 12,
    offset,
  });

  const news = useStore($news);
  // TODO по возможности переделать, не стоит такое выносить в хук, хуки только для логики, а не верстки
  const newsElements = useNewsLayout(news);

  const getMore = () => {
    setOffset(offset + 12);
  };

  if (!isLoading && !data) {
    return <NoData>Нет данных</NoData>;
  }

  return isLoading ? (
    <PreloaderSC />
  ) : (
    <>
      <NewsWrap>{newsElements}</NewsWrap>
      {/*TODO: Доработать показ кнопки, если будут доработки на бэкенде.*/}
      {/*Тут логика такая, что если в сумме у нас новостей кратно 12,*/}
      {/*значит у нас подгрузилась полная пачка, следовательно мы можем еще загрузить,*/}
      {/*а если в сумме не кратно 12, значит мы в конце дозагрузили какие-то*/}
      {/*остатки меньше 12 штук*/}
      {newsElements?.length && newsElements.length % 12 === 0 ? (
        <MoreButton>
          <Button
            buttonType={'link'}
            icon={ChevronDownSmallIcon}
            iconAlign='right'
            onClick={getMore}
          >
            Показать ещё
          </Button>
        </MoreButton>
      ) : null}
    </>
  );
};
