import React from 'react';
import styled from 'styled-components';
import { SpinnerIcon } from 'icons';

const Wrap = styled.div`
  position: relative;
  z-index: 1000;
  margin: 0 auto;
  & > svg {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Preloader: React.FC = props => {
  return <Wrap {...props}>{SpinnerIcon}</Wrap>;
};
