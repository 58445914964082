import React from 'react';

export const TelegramIcon = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path d='M23.6297 2.38403C23.3365 2.02551 22.8974 1.82812 22.3932 1.82812C22.1191 1.82812 21.8286 1.8858 21.5302 1.99988L1.21464 9.75494C0.136512 10.1664 -0.00869035 10.7838 0.000648019 11.1152C0.00998639 11.4467 0.189979 12.0549 1.28971 12.4048C1.2963 12.4069 1.30289 12.4089 1.30949 12.4107L5.52348 13.6166L7.80241 20.1334C8.11314 21.0218 8.81059 21.5737 9.62284 21.5737C10.135 21.5737 10.6387 21.3591 11.0796 20.9533L13.6861 18.5533L17.4667 21.5975C17.4671 21.5978 17.4676 21.598 17.468 21.5984L17.5039 21.6273C17.5072 21.6299 17.5107 21.6326 17.514 21.6352C17.9342 21.9611 18.3929 22.1332 18.8409 22.1334H18.8411C19.7165 22.1334 20.4136 21.4854 20.6167 20.4827L23.9454 4.04645C24.079 3.38672 23.967 2.79639 23.6297 2.38403ZM6.9332 13.3876L15.0633 9.23419L10.001 14.6133C9.91801 14.7014 9.85923 14.8092 9.8303 14.9266L8.85416 18.8806L6.9332 13.3876ZM10.1269 19.9188C10.0932 19.9497 10.0594 19.9774 10.0255 20.0028L10.9311 16.3348L12.5785 17.6614L10.1269 19.9188ZM22.5671 3.76721L19.2384 20.2037C19.2064 20.361 19.104 20.727 18.8409 20.727C18.7109 20.727 18.5476 20.6561 18.3804 20.5274L14.0965 17.0781C14.0959 17.0775 14.0952 17.077 14.0945 17.0766L11.5454 15.024L18.8662 7.24512C19.1006 6.99609 19.1218 6.61469 18.9165 6.34113C18.7111 6.06757 18.3388 5.98151 18.0343 6.13715L5.99369 12.2884L1.72239 11.0663L22.0317 3.31366C22.2033 3.24811 22.323 3.23438 22.3932 3.23438C22.4362 3.23438 22.5127 3.2395 22.5411 3.27448C22.5785 3.32007 22.6261 3.47552 22.5671 3.76721Z' />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' />
      </clipPath>
    </defs>
  </svg>
);
