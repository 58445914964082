import { uploadAvatarFx } from 'models/userProfile/model';
import { useMutation } from 'react-query';
import { dataURItoBlob } from 'utilities/dataURItoBlob';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUploadAvatar = (setAvatarId: (id: string) => void) => {
  return useMutation(
    'uploadAvatar',
    (avatar: string) => {
      const formData = new FormData();
      formData.append('avatar', dataURItoBlob(avatar));
      return uploadAvatarFx(formData);
    },
    {
      onSuccess: data => {
        setAvatarId('' + data.data.file_id);
      },
    }
  );
};
