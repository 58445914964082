import {
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector';
import { AxiosError, AxiosResponse } from 'axios';
import {
  CatalogCourses,
  CatalogEvent,
  CatalogFreeCourses,
  CatalogIntensive,
  CatalogItem,
  CatalogStore,
  CoursesStore,
  EventItem,
  EventsStore,
  FreeCoursesStore,
  GiftCard,
  GiftCardStore,
  GoodsCode,
  IntensiveItem,
  IntensivesStore,
  ProductItem,
  TrainingCatalogItem,
  TrainingItem,
  VIPClubSubcriptionType,
  TrainingsStore,
  VIPChatListItemType,
  CoursesRole,
  SecretChatListItemType,
} from './types';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { useStore, useStoreMap } from 'effector-react';
import { $user } from '../auth';
import { UserProfileType } from '../auth/types';

// События
export const setCatalog = createEvent<Record<string, CatalogItem>>();
export const setCourses = createEvent<Array<CatalogCourses>>();
export const setEvents = createEvent<CatalogEvent[]>();
export const setTrainings = createEvent<TrainingsStore>();
export const setTrainingItem = createEvent<TrainingItem>();
export const setIntensives = createEvent<CatalogIntensive[]>();
export const setFreeCourses = createEvent<CatalogFreeCourses[]>();
export const setProductItem = createEvent<ProductItem>();
export const setGiftCards = createEvent<GiftCard[]>();
export const setEventItem = createEvent<EventItem>();
export const setIntensiveItem = createEvent<IntensiveItem>();
export const setVIPClubSubscriptions = createEvent<VIPClubSubcriptionType[]>();
export const setVIPChatList = createEvent<VIPChatListItemType[]>();
export const setSecretChatList = createEvent<SecretChatListItemType[]>();

export const clearCatalog = createEvent();
export const clearBookItem = createEvent();
export const clearGiftCards = createEvent();
export const clearEventItem = createEvent();
export const clearTrainingItem = createEvent();
export const clearIntensiveItem = createEvent();

// Сторы
export const $catalogData = createStore<CatalogStore>([]);
export const $coursesData = restore<CoursesStore>(setCourses, []);
export const $filteredCourses = createStore<CoursesStore>([]);
export const $giftCardData = createStore<GiftCardStore>([]);
export const $freeCoursesData = createStore<FreeCoursesStore>([]);
export const $eventsData = createStore<EventsStore>([]);
export const $trainingsData = restore<TrainingsStore>(setTrainings, []);
export const $trainingItem = restore<TrainingItem>(setTrainingItem, null);
export const $intensivesData = createStore<IntensivesStore>([]);
export const $eventItem = createStore<EventItem | null>(null);
export const $intensiveItem = createStore<IntensiveItem | null>(null);
export const $productItem = createStore<ProductItem | null>(null);
export const $VIPClubSubscriptions = restore<VIPClubSubcriptionType[]>(
  setVIPClubSubscriptions,
  []
);
export const $vipChatList = restore<VIPChatListItemType[]>(setVIPChatList, []);
export const $secretChatList = restore<SecretChatListItemType[]>(
  setSecretChatList,
  []
);

// Эффекты
//TODO: разобраться с типами по окончанию фичи
export const getCatalogFx = createEffect<
  GoodsCode,
  AxiosResponse<{ data: Record<string, CatalogItem> }>,
  AxiosError<{ error: string }>
>(code => apiClient.get(Endpoint.CATALOG_LIST + code));

export const getCoursesFx = createEffect<
  void,
  AxiosResponse<{
    data: Array<CatalogCourses>;
  }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_COURSES));

export const getFreeCoursesFx = createEffect<
  void,
  AxiosResponse<{
    data: CatalogFreeCourses[];
  }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_FREE_COURSES));

export const getGiftCardsFx = createEffect<
  unknown,
  AxiosResponse<{
    data: GiftCard[];
  }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_CATALOG_GIFT_CARDS));

export const purchaseGiftCardFx = createEffect<
  string,
  AxiosResponse<{
    order_id: number;
  }>,
  AxiosError<{ error: string }>
>(id => apiClient.get(Endpoint.PURSCHASE_GIFT_CARD.replace('{id}', id)));

export const purchaseVipClubSubscriptionFx = createEffect<
  number,
  AxiosResponse<{
    order_id: number;
  }>,
  AxiosError<{ error: string }>
>(vip_club_id =>
  apiClient.post(Endpoint.PURCHASE_VIP_CLUB_SUBSCRIPTION, {
    vip_club_id,
  })
);

export const getEventsFx = createEffect<
  unknown,
  AxiosResponse<{ data: CatalogEvent[] }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_EVENTS));

export const getTrainingsFx = createEffect<
  void,
  AxiosResponse<{ data: TrainingCatalogItem[] }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_TRAININGS_LIST));

export const getEventItemFx = createEffect<
  string,
  AxiosResponse<{ data: EventItem }>,
  AxiosError<{ error: string }>
>(id => apiClient.get(Endpoint.GET_EVENT_ITEM.replace('{id}', id)));

export const getTrainingItemFx = createEffect<
  string,
  AxiosResponse<{ data: TrainingItem }>,
  AxiosError<{ error: string }>
>(id => apiClient.get(Endpoint.GET_TRAININGS_ITEM.replace('{id}', id)));

export const getIntensiveItemFx = createEffect<
  string,
  AxiosResponse<{ data: IntensiveItem }>,
  AxiosError<{ error: string }>
>(id => apiClient.get(Endpoint.GET_INTENSIVE_ITEM.replace('{id}', id)));

export const getProductItemFx = createEffect<
  string,
  AxiosResponse<{ data: ProductItem }>,
  AxiosError<{ error: string }>
>(id => apiClient.get(Endpoint.GET_PRODUCT_ITEM.replace('{id}', id)));

export const getIntensivesFx = createEffect<
  void,
  AxiosResponse<{
    data: {
      id: number;
      name: string;
      description: string;
      price: number;
      date_event: Date;
      place: string;
      activity: boolean;
      external_link: string;
    }[];
  }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_INTENSIVES));

export const getVIPClubSubscriptionsFx = createEffect<
  unknown,
  AxiosResponse<{
    data: VIPClubSubcriptionType[];
  }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_VIP_CLUB_SUBSCRIPTIONS));

export const getVIPChatListFx = createEffect<
  unknown,
  AxiosResponse<{ data: { data: VIPChatListItemType[] } }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_VIP_CHATS));

export const getSecretChatListFx = createEffect<
  unknown,
  AxiosResponse<{ data: { data: SecretChatListItemType[] } }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_SECRET_CHATS));

export const purchaseVIPChatFx = createEffect<
  number,
  AxiosResponse<{ order_id: number }>,
  AxiosError<{ error: string }>
>(vip_chat_id => apiClient.post(Endpoint.BUY_VIP_CHAT, { vip_chat_id }));

export const purchaseSecretChatFx = createEffect<
  number,
  AxiosResponse<{ order_id: number }>,
  AxiosError<{ error: string }>
>(chat_id =>
  apiClient.post(Endpoint.BUY_SECRET_CHAT, { secret_chat_id: chat_id })
);

// Подписки
$catalogData
  .on(setCatalog, (state, payload) => {
    //Преобразуем в массив товаров из объекта товаров и отправляем в стор
    const upcomingStateArray = Object.keys(payload).map(id => ({
      ...payload[id],
      id,
    }));
    return [...state, ...upcomingStateArray];
  })
  .reset(clearCatalog);

$coursesData.reset(clearCatalog);

$freeCoursesData
  .on(setFreeCourses, (state, payload) => payload)
  .reset(clearCatalog);

$eventsData.on(setEvents, (state, payload) => payload).reset(clearCatalog);

$eventItem.on(setEventItem, (state, payload) => payload).reset(clearEventItem);
$productItem
  .on(setProductItem, (state, payload) => payload)
  .reset(clearBookItem);

$giftCardData
  .on(setGiftCards, (state, payload) => payload)
  .reset(clearGiftCards);

$eventsData.on(setEvents, (state, payload) => payload).reset(clearCatalog);

$eventItem.on(setEventItem, (state, payload) => payload).reset(clearEventItem);

$intensiveItem
  .on(setIntensiveItem, (state, payload) => payload)
  .reset(clearIntensiveItem);

$intensivesData
  .on(setIntensives, (state, payload) => payload)
  .reset(clearCatalog);

$trainingsData.reset(clearCatalog);

$trainingItem.reset(clearTrainingItem);

export const useVIPClubSubscriptions = (): VIPClubSubcriptionType[] =>
  useStore($VIPClubSubscriptions);
export const useVIPChatList = (): VIPChatListItemType[] =>
  useStore($vipChatList);
export const useSecretChatList = (): SecretChatListItemType[] =>
  useStore($secretChatList);
export const useCatalogStore: () => CatalogCourses[] = () =>
  useStore($filteredCourses);
export const useSortedGiftCards: () => GiftCardStore = () =>
  useStoreMap({
    store: $giftCardData,
    keys: [],
    fn: state => {
      return state.sort((a, b) => (a.price > b.price ? +1 : -1));
    },
  });

export const useRecommendedGoods: (id: string) => CatalogItem[] = id =>
  useStoreMap({
    store: $catalogData,
    keys: [id],
    fn: state => state.filter(item => item.id !== id).slice(0, 4),
  });

sample<UserProfileType | null, Array<CatalogCourses>, Array<CatalogCourses>>({
  source: $user,
  clock: $coursesData,
  fn: (user, courses) => {
    const is_vip = user?.roles.is_vip;
    const is_apprentice = user?.roles.is_apprentice;
    return courses.reduce((acc, curr) => {
      const isCourseForVip = curr.role === ('vip_user' as CoursesRole);
      const isCourseForCoachSchool =
        curr.role === ('coach_apprentice' as CoursesRole);
      const isCourseForRegularUser =
        curr.role === ('regular_user' as CoursesRole);

      if (
        (isCourseForVip && is_vip) ||
        (isCourseForCoachSchool && is_apprentice) ||
        isCourseForRegularUser
      ) {
        acc.push(curr);
      }
      return acc;
    }, [] as CoursesStore);
  },
  target: $filteredCourses,
});
