import React from 'react';
// Иконки
import { InstagramIcon, LogoIcon } from 'icons/';

import {
  FooterWrap,
  Top,
  FooterColumn,
  Logo,
  LogoImage,
  LogoText,
  Instagram,
  InstagramText,
  InstagramSvg,
  List,
  ListTitle,
  ListItemWrap,
  ListItemLink,
  Contacts,
  Phone,
  Address,
  Email,
  Bottom,
  Container,
  Copyright,
  DocumentLink,
} from './styles';
import { Path } from 'constants/paths';
import { scrollToTop } from 'utilities/scrollToTop';

export const Footer: React.FC = () => {
  return (
    <FooterWrap>
      <Top>
        <Container>
          <FooterColumn>
            <Logo to={'/'}>
              <LogoImage>{LogoIcon}</LogoImage>
              <LogoText>
                Будь
                <br />С мужиком
              </LogoText>
            </Logo>
            <Instagram>
              <InstagramText>Мы в Instagram</InstagramText>
              <InstagramSvg
                href='https://www.instagram.com/budsmuzhikom.ru/'
                target={'_blank'}
              >
                {InstagramIcon}
              </InstagramSvg>
            </Instagram>
          </FooterColumn>
          <FooterColumn>
            <List>
              <ListTitle>Каталог</ListTitle>
              <ListItemWrap>
                <ListItemLink to={Path.catalogCourses} onClick={scrollToTop}>
                  Саморазвитие
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={Path.catalogBooks} onClick={scrollToTop}>
                  Книги
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={Path.catalogTrainings} onClick={scrollToTop}>
                  Встречи
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={Path.catalogEvents} onClick={scrollToTop}>
                  Мероприятия
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={Path.catalogGoods} onClick={scrollToTop}>
                  Товары
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={Path.catalogVipChat} onClick={scrollToTop}>
                  VIP-chat. 24 часа
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink
                  to={Path.catalogInnerInsight}
                  onClick={scrollToTop}
                >
                  Inner insight
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={Path.catalogVipClub} onClick={scrollToTop}>
                  VIP-club
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={Path.catalogFree} onClick={scrollToTop}>
                  FREE
                </ListItemLink>
              </ListItemWrap>
            </List>
          </FooterColumn>
          <FooterColumn>
            <List>
              <ListTitle>Информация</ListTitle>
              <ListItemWrap>
                <ListItemLink to={Path.sales} onClick={scrollToTop}>
                  Акции
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={Path.news} onClick={scrollToTop}>
                  Блог
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink
                  to={`${Path.about}/project`}
                  onClick={scrollToTop}
                >
                  О проекте
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={`${Path.about}/docs`} onClick={scrollToTop}>
                  Правовые документы
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink to={`${Path.about}/docs`} onClick={scrollToTop}>
                  Бонусная программа
                </ListItemLink>
              </ListItemWrap>
              <ListItemWrap>
                <ListItemLink
                  to={`${Path.about}/contacts`}
                  onClick={scrollToTop}
                >
                  Контакты
                </ListItemLink>
              </ListItemWrap>
            </List>
          </FooterColumn>
          <FooterColumn>
            <Contacts>
              <Phone>+7 (985) 921-88-81</Phone>
              <Address>
                105318, г. Москва,
                <br />
                м. Семёновская,
                <br />
                ул. Щербаковская, 3,
                <br />
                Бизнес-центр «Щербаковский»,
                <br />
                офис N 413
                <br />
                Пн-пт: с 15:00 до 20:00
              </Address>
              <Email href='mailto:admin@budsmuzhikom.ru'>
                admin@budsmuzhikom.ru
              </Email>
            </Contacts>
          </FooterColumn>
        </Container>
      </Top>
      <Bottom>
        <Container>
          <Copyright>© budsmuzhikom, 2021</Copyright>
          <DocumentLink to={`${Path.about}/docs`} onClick={scrollToTop}>
            Договор оферты
          </DocumentLink>
          <DocumentLink to={`${Path.about}/docs`} onClick={scrollToTop}>
            Политика конфиденциальности
          </DocumentLink>
        </Container>
      </Bottom>
    </FooterWrap>
  );
};
