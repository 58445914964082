import React from 'react';
import { HeaderContentSC, StyledLink } from './styled';
import VipClub from 'images/VIP_club.png';
import referralLink from 'images/referral_link.png';
import coachingSchool from 'images/coaching_school.png';
import { Path } from 'constants/paths';
import { MiniBanner } from './MiniBanner';
import { useUserProfile } from 'models/auth/model';

export const HeaderContent: React.FC = ({ ...props }) => {
  const user = useUserProfile();
  const isVip = user?.roles.is_vip;
  const isApprentice = user?.roles.is_apprentice;
  return (
    <HeaderContentSC {...props}>
      <StyledLink to={Path.catalogVipClub}>
        <MiniBanner
          image={VipClub}
          title={'VIP-клуб'}
          subtitle={isVip ? 'Вы участник' : 'Вступить'}
        />
      </StyledLink>
      <StyledLink to={Path.catalogInnerInsight}>
        <MiniBanner
          image={coachingSchool}
          title={'Inner insight'}
          subtitle={isApprentice ? 'Вы ученик' : 'Вступить'}
        />
      </StyledLink>
      <StyledLink to={`${Path.account}/bonus-account`}>
        <MiniBanner
          image={referralLink}
          title={'Получите 300 бонусов'}
          subtitle={'За каждого приглашенного друга'}
        />
      </StyledLink>
    </HeaderContentSC>
  );
};
