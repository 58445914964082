import styled from 'styled-components';

export const Title = styled.p`
  margin: 0;
  margin-bottom: 24px;
  ${({ theme }) => theme.typography.headline1}
`;

export const StyledText = styled.div`
  ${({ theme }) => theme.typography.bodyText2}
`;

export const Wrap = styled.div`
  margin-top: 24px;
`;
