import styled from 'styled-components';
import { CalendarEventTypes } from 'models/calendar/types';
import { calendarDayEventsColors } from 'constants/calendarEventsColors';

export const WeekDays = styled.div`
  display: flex;
`;

export const Day = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  font-weight: bold;
  width: calc(100% / 7);
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondColor};
`;

export const WeekDaysWrap = styled.div`
  display: flex;
  border-left: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
`;

export const Cell = styled.div`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText2};
  width: calc(100% / 7);
  transition: background-color 0.3s;
  border-right: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  min-height: 129px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DayDate = styled.div<{
  selected: boolean;
  to: string;
}>`
  ${({ to }) => (to ? 'cursor: pointer' : null)};
  padding: 9px 10px;
  margin: 0;
  min-width: 40px;
  text-align: center;
  border-radius: 14px;
  transition: background-color 0.3s;
  ${({ selected, theme }) =>
    selected
      ? `background-color: ${theme.colors.actionColor}`
      : `&:hover {
transition: background-color 0.3s;
background-color: ${theme.colors.secondBgColor}`}};
  ${({ theme }) => theme.typography.bodyText2};
`;

export const EventLine = styled.div<{
  type: CalendarEventTypes;
  start: boolean;
  end: boolean;
  purchased: boolean;
  title: string;
  onClick: (e: React.MouseEvent<MouseEvent>) => void;
}>`
  ${({ title }) => !!title && `z-index: 2;`}
  width: 100%;
  height: 4px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  min-height: 26px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  ${({ start }) =>
    start
      ? `border-bottom-left-radius: 4px; border-top-left-radius: 4px; border-left: 1px solid;`
      : null}
  ${({ end }) =>
    end
      ? `border-bottom-right-radius:4px; border-top-right-radius: 4px; border-right: 1px solid;`
      : null}
  border-color: ${({ type }) => calendarDayEventsColors.border[type]};
  ${({ purchased }) => (!purchased ? `opacity: 0.5;` : null)}
  padding: 2px 7px;

  margin: 12px 0 0 0;
  & + & {
    margin: 4px 0 0;
  }

  background-color: ${({ type }) =>
    type ? calendarDayEventsColors.fill[type] : null};
`;
