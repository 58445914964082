import React from 'react';
import { VideoIcon, HeadphonesIcon, FileIcon, BookOpenIcon } from 'icons';
import Ruble from 'components/common/Ruble';
import {
  Wrap,
  Badge,
  BadgeText,
  Inner,
  Photo,
  ActionButton,
  Title,
  Date as DateStyled,
  Bottom,
  IconWrap,
  Text,
  Top,
} from './styles';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { LessonProps } from './types';
import { baseUrl } from 'api/apiClient';
import { useCreateCourseAdditionalOrderQuery } from 'queries/courses/useCreateCourseAdditionalOrderQuery';
import { Link } from 'react-router-dom';

//TODO: Вынести компонент в сторибук

export const Lesson: React.FC<LessonProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  id,
  courseId,
  date_start,
  is_bonus,
  name,
  price,
  materials,
  picture,
  className,
  isAvailable,
  isSchool,
  status,
}) => {
  const { mutate } = useCreateCourseAdditionalOrderQuery();
  const handleBuyAdditionalLecture = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    mutate({ course_id: courseId, additional_id: id });
  };

  const getPath = () => {
    return isSchool
      ? `/coaching/${courseId}/lesson/${id}`
      : `/course/${courseId}/lesson/${id}`;
  };

  return (
    <Wrap
      as={isAvailable ? Link : 'div'}
      to={isAvailable ? getPath() : ''}
      $isAvailable={isAvailable}
      className={className}
      status={status}
    >
      {is_bonus && (
        <Badge>
          <BadgeText>Бонус</BadgeText>
        </Badge>
      )}
      <Top>
        {picture && <Photo src={baseUrl + picture} alt={name} />}
        <Inner>
          {date_start && (
            <DateStyled>
              {format(new Date(date_start), 'dd MMM yyyy, HH:mm', {
                locale: ru,
              })}{' '}
            </DateStyled>
          )}
          <Title>{name}</Title>
          {!!price && (
            <ActionButton onClick={handleBuyAdditionalLecture}>
              Купить всё за {price}
              {Ruble}
            </ActionButton>
          )}
        </Inner>
      </Top>
      <Bottom>
        {!is_bonus && (
          <>
            {!!materials.videos && (
              <IconWrap>
                <div>{VideoIcon}</div>
                <Text>{materials.videos}</Text>
              </IconWrap>
            )}

            {!!materials.audios && (
              <IconWrap>
                <div>{HeadphonesIcon}</div>
                <Text>{materials.audios}</Text>
              </IconWrap>
            )}

            {!!materials.documents && (
              <IconWrap>
                <div>{FileIcon}</div>
                <Text>{materials.documents}</Text>
              </IconWrap>
            )}
            {!price && (
              <IconWrap style={{ marginLeft: 'auto' }}>
                <div>{BookOpenIcon}</div>
                <Text>1 задание</Text>
              </IconWrap>
            )}
          </>
        )}
      </Bottom>
    </Wrap>
  );
};
