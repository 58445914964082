import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NoImage } from 'ui/NoImage';
import { Tag } from 'ui/Tag';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  padding: 60px 0 48px 0;
`;

export const Container = styled.div`
  max-width: 1212px;
  margin: 0 auto;
  padding: 0 12px;
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline2};
`;

export const List = styled.div`
  display: flex;
  margin: 24px 0 0;
`;

export const ImageWrap = styled.div<{ border?: boolean }>`
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  padding-top: 100%;
  ${({ border, theme }) =>
    border && `border: 2px solid ${theme.colors.thirdBgColor}`}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledNoImage = styled(NoImage)`
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Item = styled(Link)`
  flex-basis: 208px;
  max-width: calc(100% / 5);
  & + & {
    margin: 0 0 0 43px;
  }
  padding: 0 12px 48px;
  display: flex;
  flex-direction: column;
`;

export const DisabledItemWrap = styled.div`
  cursor: not-allowed;
  flex-basis: calc(100% / 4);
  max-width: calc(100% / 4);
  margin: 0 0 40px;
  padding: 0 12px 48px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
`;

export const TagItem = styled(Tag)``;
export const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px -5px 0;
  & ${TagItem} {
    margin: 0 5px;
  }
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText1};
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 6px 0 12px;
`;

export const Price = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: auto 0 0;
`;

export const Btn = styled(Button)`
  margin: 12px 0 0;
`;

export const CatalogItemTitle = styled.div`
  ${({ theme }) => theme.typography.headline3};
  margin-top: 12px;
  max-width: 100%;
`;
export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
`;
