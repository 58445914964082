import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Path } from 'constants/paths';
import { Books } from './Books';
import { Courses } from './Courses';
import { Events } from './Events';
import { Trainings } from './Trainings';
import { Goods } from './Goods';
import { VipChat } from './VipChat';
import { VipClub } from './VipClub';
import { Free } from './Free';
import { Event } from './Event';
import { InnerInsight } from './InnerInsight';
import { ListWrap, ContentWrap, StyledTitle, StyledButton } from './styles';
import { CatalogListProps } from './types';
import { ChevronLeftSmallIcon } from 'icons';
import { ProductPage } from '../common/ProductPage';
import { GiftCardItem } from '../common/GiftCardItem';
import { VipClubIntensives } from './VipClubIntensives/VipClubIntensives';
import { Intensive } from './Intensive';
import { Training } from './Training';
import { Secret } from './Secret/Secret';
import { MasterClass } from './MasterClass';

export const List: React.FC<CatalogListProps> = ({
  categoryTitle,
  ...rest
}) => {
  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };
  return (
    <ListWrap {...rest}>
      <Switch>
        <Route
          exact
          path={[
            Path.catalogCourses,
            Path.catalogBooks,
            Path.catalogEvents,
            Path.catalogTrainings,
            Path.catalogGoods,
            Path.catalogVipChat,
            Path.catalogInnerInsight,
            Path.catalogVipClub,
            Path.catalogFree,
            Path.catalogSecret,
          ]}
        >
          {categoryTitle ? <StyledTitle>{categoryTitle}</StyledTitle> : null}
        </Route>
        <Route path={`${Path.catalogBooks}/:id`}>
          <StyledButton
            buttonType={'link'}
            icon={ChevronLeftSmallIcon}
            onClick={handleClick}
            label='Все книги'
          />
        </Route>
        <Route path={`${Path.catalogGoods}/:id`}>
          <StyledButton
            buttonType={'link'}
            icon={ChevronLeftSmallIcon}
            onClick={handleClick}
            label='Все товары'
          />
        </Route>
        <Route path={`${Path.catalogGiftCards}/:id`}>
          <StyledButton
            buttonType={'link'}
            icon={ChevronLeftSmallIcon}
            onClick={handleClick}
            label='Все товары'
          />
        </Route>
        <Route path={`${Path.catalogEvents}/:id`}>
          <StyledButton
            buttonType={'link'}
            icon={ChevronLeftSmallIcon}
            onClick={handleClick}
            label='Все мероприятия'
          />
        </Route>
        <Route path={`${Path.catalogTrainings}/:id`}>
          <StyledButton
            buttonType={'link'}
            icon={ChevronLeftSmallIcon}
            onClick={handleClick}
            label='Все встречи'
          />
        </Route>
        <Route exact path={`${Path.vipClubMeetings}`}>
          <StyledButton
            buttonType={'link'}
            icon={ChevronLeftSmallIcon}
            onClick={handleClick}
            label='VIP-клуб'
          />
        </Route>
        <Route path={`${Path.vipClubMeetings}/:id`}>
          <StyledButton
            buttonType={'link'}
            icon={ChevronLeftSmallIcon}
            onClick={handleClick}
            label='Все встречи VIP-клуба'
          />
        </Route>
      </Switch>
      <ContentWrap>
        <Switch>
          <Route exact path={Path.vipClubMeetings}>
            <VipClubIntensives />
          </Route>
          <Route
            exact
            path={`${Path.vipClubMeetings}/:id`}
            component={Intensive}
          />
          <Route exact path={Path.catalogTrainings}>
            <Trainings />
          </Route>
          <Route
            exact
            path={`${Path.catalogTrainings}/:id`}
            component={Training}
          />
          <Route exact path={Path.catalogBooks}>
            <Books />
          </Route>
          <Route
            path={`${Path.catalogGiftCardsDetail}`}
            component={GiftCardItem}
          />
          <Route path={`${Path.catalogEvents}/:id`} component={Event} />
          <Route
            path={`${Path.catalogInnerInsight + Path.masterClass}/:id`}
            component={MasterClass}
          />
          <Route
            path={`${Path.catalogBooks.replace('books', ':key')}/:id`}
            component={ProductPage}
          />
          <Route
            path={`${Path.catalogGoods.replace('goods', ':key')}/:id`}
            component={ProductPage}
          />
          <Route path={Path.catalogCourses}>
            <Courses />
          </Route>
          <Route exact path={Path.catalogEvents}>
            <Events />
          </Route>
          <Route path={Path.catalogGoods}>
            <Goods />
          </Route>
          <Route path={Path.catalogVipChat}>
            <VipChat />
          </Route>
          <Route path={Path.catalogInnerInsight}>
            <InnerInsight />
          </Route>

          <Route exact path={Path.catalogVipClub}>
            <VipClub />
          </Route>
          <Route exact path={Path.catalogSecret}>
            <Secret />
          </Route>
          <Route path={Path.catalogFree}>
            <Free />
          </Route>
          <Route path={Path.catalog + Path.event} component={Event} />
          <Redirect from={Path.catalog} to={Path.catalogCourses} />
        </Switch>
      </ContentWrap>
    </ListWrap>
  );
};
