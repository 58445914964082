import React, {
  ChangeEvent,
  RefObject,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import { PlayMediumIcon, PauseMediumIcon } from 'icons/';
import {
  Inner,
  ItemTitle,
  Info,
  PlayButton,
  PlaybackRate,
  PlaybackRateItem,
  PlayLine,
  PlayLineInput,
  Wrap,
  PlayLineWrap,
  PlayLineDone,
} from './styles';
import { baseUrl } from 'api/apiClient';
import { formatSecondsToDuration } from 'utilities/formatSecondsToDuration';
import { HomeworkFile } from 'models/homework/types';

const playbackOptions = [0.5, 0.75, 1, 1.25, 1.5];

export const HomeworkAudioMaterial: React.FC<HomeworkFile> = ({
  name,
  path,
  extension,
  ...rest
}) => {
  const [play, setPlay] = useState(false);
  const [currentPercent, setCurrentPercent] = useState(0);
  const audioRef: RefObject<HTMLAudioElement> = useRef<HTMLAudioElement>(null);
  const sliderRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const [durationSec, setDurationSec] = useState<number | undefined>();
  const [playbackRate, setPlaybackRate] = useState(1);

  const handlePlay = () => {
    if (play) {
      audioRef.current?.pause();
      setPlay(false);
    } else {
      audioRef.current?.play();
      setPlay(true);
    }
  };

  const handlePlaybackRate = (rate: number) => {
    setPlaybackRate(rate);
    if (audioRef.current) audioRef.current.playbackRate = rate;
  };

  const handleEnd = () => {
    setPlay(false);
    setDurationSec(audioRef.current?.duration);
  };

  const handlePlayback = (e: SyntheticEvent<HTMLAudioElement>) => {
    if (sliderRef.current) {
      const { duration, currentTime } = e.currentTarget || {};
      sliderRef.current.value = currentTime.toString();
      setCurrentPercent(Math.round((currentTime * 100) / duration));
      setDurationSec(duration - currentTime);
    }
  };

  const handleRewind = (e: ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current) {
      audioRef.current.currentTime = Number(e.currentTarget.value);
    }
  };

  return (
    <Wrap {...rest}>
      <Inner>
        <audio
          ref={audioRef}
          onTimeUpdate={handlePlayback}
          onEnded={handleEnd}
          preload='metadata'
          onLoadedMetadata={() => setDurationSec(audioRef.current?.duration)}
        >
          <source src={`${baseUrl}${path}`} type={'audio/' + extension} />
        </audio>

        <PlayButton
          icon={play ? PauseMediumIcon : PlayMediumIcon}
          onClick={handlePlay}
        />
        {play ? (
          <PlayLine>
            <PlayLineWrap>
              <PlayLineDone width={currentPercent} />
              <PlayLineInput
                type='range'
                onChange={handleRewind}
                ref={sliderRef}
                max={audioRef.current?.duration.toString()}
              />
            </PlayLineWrap>
          </PlayLine>
        ) : (
          <ItemTitle>{name}</ItemTitle>
        )}
        <Info>{formatSecondsToDuration(durationSec)}</Info>
      </Inner>
      {play ? (
        <PlaybackRate>
          {playbackOptions.map(item => (
            <PlaybackRateItem
              key={item}
              isActive={playbackRate === item}
              onClick={() => handlePlaybackRate(item)}
            >
              {item === 1 ? 'Обычная' : `${item}x`}
            </PlaybackRateItem>
          ))}
        </PlaybackRate>
      ) : null}
    </Wrap>
  );
};
