import React, { ChangeEvent, useEffect } from 'react';
import { InputTextProps } from 'ui/InputText/types';
import { Field, FieldRenderProps, useField, useForm } from 'react-final-form';
import { useCalculateSDEKDeliveryQuery } from 'queries/buy/useCalculateSDEKDeliveryQuery';
import { useCityDaDataSuggestionQuery } from 'queries/buy/daData/useCityDaDataSuggestionQuery';
import { CitySuggestion } from './CitySuggestion';
import { Preloader } from 'ui/Preloader';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

const StyledPreloader = styled(Preloader)`
  margin: 24px 0 0;
  text-align: center;
`;

export const CitySmartInput: React.FC<
  FieldRenderProps<InputTextProps>
> = props => {
  const {
    mutate,
    data: suggestions,
    isLoading: isDadataLoading,
  } = useCityDaDataSuggestionQuery();
  const {
    mutate: calculate,
    data: city_code,
    isSuccess,
    isLoading,
  } = useCalculateSDEKDeliveryQuery();
  const match = useRouteMatch<{ id: string }>();
  const { id: order_id } = match.params;
  const form = useForm('DeliveryForm');
  const countryField = useField('country');
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    form.change('city_code', '');
    form.change('postal_code', '');
    form.change('street', '');
    form.change('house', '');
    form.change('flat', '');
    form.change('domophone', '');

    const { value } = event.target || {};
    if (value && countryField.input.value) {
      mutate({ country: countryField.input.value, address: value });
    }
  };
  const debouncedHandleChange = useDebouncedCallback(handleOnChange, 300);

  const handleChoose = (postal_code: string, city: string) => {
    calculate({
      postal_code,
      city,
      order_id: order_id ? Number(order_id) : undefined,
      type: 'courier',
    });
  };

  useEffect(() => {
    form.change('city_code', city_code);
  }, [city_code, form]);

  return (
    <>
      <Field name={'city_code'} hidden component='input' />
      <Field
        name={'city'}
        onChange={debouncedHandleChange}
        labelWidth={144}
        inputWidth={340}
        suggestions={suggestions}
        onChoose={handleChoose}
        component={CitySuggestion}
        isSuccess={isSuccess}
        isLoading={isDadataLoading || isLoading}
        {...props}
      />

      {isLoading && <StyledPreloader />}
    </>
  );
};
