import React from 'react';

export const ToolbookIcon = (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.00096 2.09231C6.77831 2.09231 5.78558 3.08505 5.78558 4.30769V23.3846H4.0625V4.30769C4.0625 2.13342 5.82669 0.369232 8.00096 0.369232H26.7087V21.7846H24.6163V20.0615H24.9856V2.09231H8.00096Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.0625 23.3635C4.0625 21.7399 5.23125 20.0615 6.98558 20.0615H25.8471V21.7846H6.98558C6.46298 21.7846 5.78558 22.3849 5.78558 23.3635C5.78558 23.8164 5.94837 24.2448 6.19587 24.5486C6.44313 24.8522 6.72985 24.9846 6.98558 24.9846H15.7548C16.2306 24.9846 16.6163 25.3703 16.6163 25.8461C16.6163 26.322 16.2306 26.7077 15.7548 26.7077H6.98558C6.10285 26.7077 5.35879 26.2493 4.8599 25.6368C4.36125 25.0246 4.0625 24.2117 4.0625 23.3635Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.846 23.3846C26.3218 23.3846 26.7075 23.7703 26.7075 24.2462V25.7077C26.7075 26.26 26.2598 26.7077 25.7075 26.7077H25.4768C25.001 26.7077 24.6152 26.322 24.6152 25.8462V25.3538C24.6152 25.1499 24.7805 24.9846 24.9845 24.9846V24.2462C24.9845 23.7703 25.3702 23.3846 25.846 23.3846Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.6016 22.5231H24.2477V29.2428C24.2477 30.0907 23.2589 30.5538 22.6075 30.0111L20.9246 28.6086L19.2417 30.0111C18.5904 30.5538 17.6016 30.0907 17.6016 29.2428V22.5231ZM19.3246 24.2461V27.699L20.9246 26.3657L22.5246 27.699V24.2461H19.3246Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.4358 5.3344C19.4227 5.19995 20.562 5.36887 21.3716 6.16468C21.5355 6.32575 21.6282 6.54562 21.6292 6.77539C21.6302 7.00517 21.5393 7.22582 21.3769 7.38829L19.5653 9.19984L20.3346 9.96912L22.1524 8.156C22.315 7.99387 22.5355 7.90333 22.7651 7.90446C22.9947 7.9056 23.2143 7.99832 23.3753 8.16205C24.1711 8.97166 24.34 10.111 24.2055 11.0979C24.0701 12.0918 23.6102 13.1025 22.911 13.8017L22.5086 14.2041C21.8984 14.8143 21.0785 15.2132 20.249 15.3624C19.642 15.4716 18.9858 15.454 18.3762 15.2493L15.164 18.4615C14.8275 18.798 14.282 18.798 13.9456 18.4615C13.6091 18.1251 13.6091 17.5796 13.9456 17.2431L17.5805 13.6082C17.8631 13.3256 18.303 13.2744 18.6429 13.4847C18.9635 13.6829 19.4253 13.7599 19.9439 13.6665C20.46 13.5737 20.951 13.3249 21.2902 12.9857L21.6927 12.5833C22.0938 12.1821 22.4073 11.5329 22.4982 10.8653C22.5295 10.6356 22.5326 10.4233 22.5114 10.2316L20.9422 11.7967C20.6056 12.1324 20.0608 12.1321 19.7246 11.7959L17.7377 9.80904C17.5762 9.64747 17.4854 9.42833 17.4854 9.19984C17.4854 8.97134 17.5762 8.75221 17.7377 8.59064L19.3001 7.02828C19.1089 7.00739 18.8973 7.01052 18.6684 7.04171C18.0007 7.13266 17.3515 7.44613 16.9504 7.84729L16.548 8.2497C16.2087 8.58891 15.96 9.07997 15.8671 9.59603C15.7738 10.1147 15.8507 10.5765 16.049 10.897C16.2591 11.2367 16.2082 11.6763 15.9259 11.959L12.2968 15.5935C11.9606 15.9302 11.4151 15.9306 11.0784 15.5944C10.7417 15.2582 10.7413 14.7127 11.0775 14.376L14.2845 11.1642C14.0797 10.5545 14.062 9.89805 14.1713 9.29094C14.3205 8.46144 14.7193 7.64153 15.3296 7.0313L15.732 6.62889C16.4311 5.92972 17.4419 5.4698 18.4358 5.3344Z'
      fill='#181818'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.38498 19.6923C6.90916 19.6923 6.52344 19.3066 6.52344 18.8308V4.55386C6.52344 4.07805 6.90916 3.69232 7.38498 3.69232C7.86079 3.69232 8.24651 4.07805 8.24651 4.55386V18.8308C8.24651 19.3066 7.86079 19.6923 7.38498 19.6923Z'
      fill='#181818'
    />
  </svg>
);

