import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: 'sidebar sidebar sidebar content content content content content content content empty empty';
  gap: 24px;
  margin: 40px auto 0;
  padding: 0 12px 100px;
  width: 100%;
  max-width: 1236px;
`;
export const Sidebar = styled.div`
  grid-area: sidebar;
`;
export const Content = styled.div`
  grid-area: content;
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline1}
  margin: 0;
`;

export const NotificationsWrap = styled.div`
  margin: 16px 0 0;
`;

export const NotificationItem = styled.div<{ isNew: boolean }>`
  display: flex;
  padding: 24px 0;
  position: relative;
  &:before {
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.colors.actionColor};
    height: 12px;
    width: 12px;
    border-radius: 50%;
    position: absolute;
    top: 62px;
    right: 0;
    transform: scale(0);
    transition: transform 0.3s;
  }
  ${({ isNew }) =>
    isNew
      ? `
      &:before {
        transition: transform 0.3s;
        transform: scale(1);
      }`
      : null}
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
`;

export const NotificationPicture = styled.div`
  flex: 0 0 auto;
  border-radius: 14px;
  overflow: hidden;
  width: 80px;
  height: 80px;
  & img {
    width: 100%;
  }
`;

export const Inner = styled.div`
  flex-grow: 1;
  margin: 0 24px;
`;

export const NotificationTitle = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline3}
`;

export const NotificationText = styled.p`
  margin: 16px 0 0;
  ${({ theme }) => theme.typography.bodyText2}
  color: ${({ theme }) => theme.colors.secondTextColor}
`;

export const NotificationDate = styled.p`
  flex: 1 0 146px;
  text-align: right;
  margin: 0;
  ${({ theme }) => theme.typography.bodyText3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const StyledButton = styled(Button)`
  display: flex;
  margin: 24px auto 0;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
  text-align: center;
`;
export const StyledButtonPreloader = styled(Preloader)`
  margin: 12px auto 0;
  text-align: center;
`;
