import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Main } from 'components/Main';
import React from 'react';
import styled from 'styled-components';
import { Slide, ToastContainer } from 'react-toastify';
export const StyledToastContainer = styled(ToastContainer)`
  & .Toastify__toast {
    padding: 10px 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
    border-radius: 14px;
    min-height: 0;
    background-color: ${({ theme }) => theme.colors.mainBgColor};
  }
  & .Toastify__toast-body {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
`;

export const App: React.FC = () => {
  return (
    <div className='App'>
      <StyledToastContainer
        position={'top-center'}
        limit={5}
        closeButton={false}
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        transition={Slide}
      />
      <Header />
      <Main />
      <Footer />
    </div>
  );
};
