const HelpCircleIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 2.50016C5.8579 2.50016 2.50004 5.85803 2.50004 10.0002C2.50004 14.1423 5.8579 17.5002 10 17.5002C14.1422 17.5002 17.5 14.1423 17.5 10.0002C17.5 5.85803 14.1422 2.50016 10 2.50016ZM0.833374 10.0002C0.833374 4.93755 4.93743 0.833496 10 0.833496C15.0626 0.833496 19.1667 4.93755 19.1667 10.0002C19.1667 15.0628 15.0626 19.1668 10 19.1668C4.93743 19.1668 0.833374 15.0628 0.833374 10.0002Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.2151 6.68676C9.82718 6.62022 9.42821 6.69312 9.08888 6.89255C8.74955 7.09198 8.49174 7.40507 8.36113 7.77636C8.2084 8.21052 7.73264 8.43866 7.29848 8.28594C6.86432 8.13321 6.63618 7.65745 6.7889 7.22329C7.05013 6.4807 7.56574 5.85452 8.24441 5.45566C8.92308 5.0568 9.72101 4.911 10.4969 5.04408C11.2727 5.17716 11.9765 5.58054 12.4834 6.18276C12.9903 6.78487 13.2677 7.54689 13.2667 8.33392C13.2663 9.60921 12.3208 10.4514 11.6456 10.9015C11.2826 11.1436 10.9255 11.3215 10.6624 11.4384C10.5297 11.4974 10.418 11.5422 10.3374 11.5728C10.2971 11.5882 10.2644 11.6001 10.2406 11.6086L10.2117 11.6187L10.2024 11.6219L10.1992 11.623L10.1979 11.6234C10.1977 11.6235 10.1969 11.6237 9.93335 10.8332L10.1969 11.6237C9.76025 11.7693 9.28832 11.5333 9.14278 11.0967C8.99734 10.6604 9.23289 10.1888 9.66895 10.0429L9.66789 10.0432C9.66797 10.0432 9.66805 10.0432 9.66895 10.0429L9.68225 10.0382C9.69507 10.0337 9.71608 10.026 9.74411 10.0154C9.8003 9.99395 9.88386 9.96058 9.98552 9.9154C10.1912 9.82398 10.4591 9.68943 10.7211 9.51478C11.2958 9.13167 11.6 8.7241 11.6 8.33316L11.6 8.33192C11.6006 7.93832 11.4619 7.55721 11.2084 7.2561C10.9549 6.95499 10.603 6.7533 10.2151 6.68676Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.16663 14.1668C9.16663 13.7066 9.53972 13.3335 9.99996 13.3335H10.0083C10.4685 13.3335 10.8416 13.7066 10.8416 14.1668C10.8416 14.6271 10.4685 15.0002 10.0083 15.0002H9.99996C9.53972 15.0002 9.16663 14.6271 9.16663 14.1668Z'
      fill='#B1B1B1'
    />
  </svg>
);

export default HelpCircleIcon;
