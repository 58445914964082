import styled from 'styled-components';
import { Button } from 'ui/Button/';
import { InputPassword } from 'ui/InputPassword';

export const Input = styled(InputPassword)`
  margin-bottom: 24px;
  user-select: none;
`;

export const ButtonSC = styled(Button)`
  margin: 0 0 0 160px;
  user-select: none;
`;

export const FormSC = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const TextSC = styled.div`
  ${({ theme }) => theme.typography.bodyText1}
  margin:24px 0 24px 0
`;
