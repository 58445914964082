import React from 'react';
import { UserProfileType } from 'models/auth/types';
import { useGetInstagramLinkQuery } from 'queries/account/instagram/useGetInstagramLinkQuery';
import { InstagramConnectButton } from 'components/common/InstagramConnectButton';
import { useDeleteInstagramConnectQuery } from 'queries/account/instagram/useDeleteInstagramConnectQuery';

export const InstagramButton: React.FC<Pick<UserProfileType, 'instagram'>> = ({
  instagram,
}) => {
  const { isLoading, data } = useGetInstagramLinkQuery();
  const {
    mutate: deleteInstagram,
    isLoading: isLoadingDelete,
  } = useDeleteInstagramConnectQuery();
  const { data: instLink } = data?.data || {};
  const handleClick = () => {
    if (!instagram) {
      window.open(instLink, '_self');
    } else {
      window.open('https://instagram.com/' + instagram, '_self');
    }
  };

  return (
    <InstagramConnectButton
      instagramTag={instagram}
      disabled={isLoading}
      onClick={handleClick}
      handleDelete={deleteInstagram}
      isLoadingDelete={isLoadingDelete}
    />
  );
};
