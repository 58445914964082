import { useQuery } from 'react-query';
import { getLessonFx, setLesson } from 'models/lesson';
import { LessonQuery } from './types';

export const useLessonQuery: LessonQuery = data =>
  useQuery('lesson', () => getLessonFx(data), {
    onSuccess: res => {
      setLesson(res.data.data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
