import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SalesDetail } from './SalesDetail';
import { SalesList } from './SalesList';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export const Sales: React.FC<RouteComponentProps<{ key?: string }>> = ({
  match,
}) => {
  const { key } = match.params;

  useScrollToTopOnMount();
  return key ? (
    <>
      <SalesDetail id={Number(key)} />
    </>
  ) : (
    <>
      <SalesList />
    </>
  );
};
