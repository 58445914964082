import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  padding: 32px 0;
`;

export const Inner = styled.div``;

export const Photo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  flex-shrink: 0;
  & + ${Inner} {
    margin-left: 24px;
  }
`;

export const PhotoBlank = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.actionColor};
  & + ${Inner} {
    margin-left: 24px;
  }
`;

export const PhotoBlankText = styled.p`
  ${({ theme }) => theme.typography.headline2};
`;

export const Text = styled.div`
  ${({ theme }) => theme.typography.bodyText1}
  line-height: 1.6;
`;

export const Date = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  & + ${Text} {
    margin-top: 34px;
  }
`;

export const Author = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.headline3};
  & + ${Date} {
    margin-top: 12px;
  }
`;

export const FilesList = styled.div`
  margin-top: 24px;
`;

export const FileInfo = styled.p`
  margin: 0;
  flex-shrink: 0;
  ${({ theme }) =>
    `color: ${theme.colors.thirdTextAndIconColor}; ${theme.typography.bodyText2}`};
`;

export const FileTitle = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  font-weight: bold;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  & + ${FileInfo} {
    margin-left: 8px;
  }
`;

export const FileImg = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  object-fit: cover;
  & + ${FileTitle} {
    margin-left: 10px;
  }
`;

export const FileIconContainer = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  padding: 7px;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  & + ${FileTitle} {
    margin-left: 10px;
  }
`;

export const File = styled.a`
  display: flex;
  align-items: center;
  max-width: 594px;

  & + & {
    margin: 10px 0 0;
  }
`;
