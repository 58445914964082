import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.span`
  ${({ theme }) => theme.typography.headline1};
`;

export const Title: React.FC = ({ children, ...props }) => {
  return <StyledTitle {...props}>{children}</StyledTitle>;
};
