import Ruble from 'components/common/Ruble';
import { TCourse, TRenewal } from 'models/courses/types';
import React, { useState } from 'react';
import { formatPriceToString } from 'utilities/formatPriceToString';
import {
  Options,
  Option,
  OptionRadio,
  OptionInner,
  OptionTitle,
  OptionText,
  OptionPrice,
  TariffsButton,
} from '../styles';
import { TOption } from '../types';
import { useProlongCourseQuery } from 'queries/courses/useProlongCourseQuery/useProlongCourseQuery';

export const OptionProlongation: React.FC<
  Pick<TCourse, 'id' | 'active'> & { renewal: TRenewal }
> = ({ renewal, id, active }) => {
  const { mutate: prolong } = useProlongCourseQuery();

  const [option, setOption] = useState<TOption>({
    price: renewal.prolongation_fee,
    title: 'Продлить доступ к курсу',
    checked: renewal.prolongation_fee,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOption({
      price: Number(e.target.value),
      title: e.target.getAttribute('data-title'),
      checked: Number(e.target.value),
    });
  };

  const handleClick = () => {
    prolong({
      course_id: id,
      renewal_fee: Number(option.checked),
    });
  };

  return (
    <>
      <Options>
        <Option>
          <OptionRadio
            radioSize='large'
            onChange={handleChange}
            checked={option.checked === renewal.prolongation_fee}
            value={renewal.prolongation_fee}
            data-title='Продлить доступ к курсу'
          />
          <OptionInner>
            <OptionTitle>Продлить доступ к курсу</OptionTitle>
            <OptionText>{renewal.prolongation_description}</OptionText>
          </OptionInner>
          <OptionPrice>
            {formatPriceToString(renewal.prolongation_fee)} {Ruble}
          </OptionPrice>
        </Option>
        {active?.tariff !== 'vip' ? (
          <Option>
            <OptionRadio
              radioSize='large'
              onChange={handleChange}
              checked={option.checked === renewal.transition_fee}
              value={renewal.transition_fee}
              data-title='Доплата до VIP'
            />
            <OptionInner>
              <OptionTitle>Доплата до VIP</OptionTitle>
              <OptionText>{renewal.transition_description}</OptionText>
            </OptionInner>
            <OptionPrice>
              {formatPriceToString(renewal.transition_fee)} {Ruble}
            </OptionPrice>
          </Option>
        ) : null}
      </Options>
      <TariffsButton buttonType='primary' size='large' onClick={handleClick}>
        {`${option.title} за ${formatPriceToString(option.price)} ${Ruble}`}
      </TariffsButton>
    </>
  );
};
