import React from 'react';
import {
  Wrap,
  StyledAnnouncement,
  StyledBanner,
  StyledCourseContain,
  StyledTariffs,
  Divider,
  StyledStudyPlan,
} from './styles';
import { Faq } from './Faq';
import { PaymentProps } from './types';
import { Description } from './Description';

export const Payment: React.FC<PaymentProps> = ({ school, ...props }) => {
  return (
    <Wrap {...props}>
      <StyledBanner school={school} />
      <StyledAnnouncement school={school} />
      <Description />
      <StyledCourseContain />
      <Divider />
      <StyledStudyPlan />
      <StyledTariffs
        id={school.id}
        purchase={school.purchase}
        dateEnd={school.date_end}
      />
      <Faq />
    </Wrap>
  );
};
