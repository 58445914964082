export const CoinsLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M15.2789 17.4694L14.4789 18.2694C13.5989 19.1494 13.3389 20.3194 13.8989 20.8794L14.9089 21.8894C15.4689 22.4494 16.6389 22.1894 17.5189 21.3094L18.3189 20.5094C19.0089 19.8194 19.3489 18.9194 19.1489 18.2894L18.3589 15.7694C18.1589 15.1394 18.4089 14.3294 19.2589 13.4794C20.1089 12.6294 21.3089 12.3394 21.8689 12.8994L22.8789 13.9094C23.4389 14.4694 23.1789 15.6394 22.2989 16.5194L21.4989 17.3194'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M22.3789 13.4095L24.7689 11.0195'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M14.4095 21.3896L12.0195 23.7796'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M23.974 22.9782C28.8144 18.1337 30.2394 11.7097 27.1569 8.62981C24.0744 5.54991 17.6516 6.98041 12.8112 11.8249C7.97079 16.6694 6.54575 23.0934 9.62826 26.1733C12.7108 29.2532 19.1336 27.8227 23.974 22.9782Z'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M9.62891 26.1691L12.8189 29.3591C15.8989 32.4391 22.3189 31.0091 27.1689 26.1591C31.9989 21.3091 33.4289 14.8891 30.3489 11.8091L27.1589 8.61914'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M44.2803 30.1392L43.7603 29.2892C43.1903 28.3492 42.2403 27.8792 41.6303 28.2392L40.5403 28.8992C39.9403 29.2592 39.9203 30.3292 40.4903 31.2692L41.0103 32.1192C41.4603 32.8592 42.1603 33.3392 42.7503 33.2992L45.0903 33.1492C45.6803 33.1092 46.3203 33.4992 46.8703 34.4092C47.4203 35.3192 47.4203 36.4192 46.8203 36.7792L45.7303 37.4392C45.1303 37.7992 44.1803 37.3392 43.6003 36.3892L43.0803 35.5392'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M46.2793 37.1191L47.8393 39.6791'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M41.0891 28.57L39.5391 26'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M37.7098 36.4693C34.5598 31.2793 34.6798 25.4393 37.9798 23.4293C41.2798 21.4193 46.5198 24.0093 49.6698 29.1993C52.8198 34.3893 52.6998 40.2293 49.3998 42.2393C46.0898 44.2493 40.8598 41.6693 37.7098 36.4693Z'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M37.9792 23.4395L34.5592 25.5195C31.2592 27.5295 31.1292 33.3595 34.2792 38.5595C37.4292 43.7495 42.6692 46.3395 45.9692 44.3295L49.3892 42.2495'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M17.8394 41.0099L16.9694 41.3199C16.0094 41.6599 15.4094 42.4299 15.6294 43.0399L16.0194 44.1399C16.2394 44.7499 17.1894 44.9699 18.1494 44.6199L19.0194 44.3099C19.7694 44.0399 20.3294 43.4999 20.4094 42.9599L20.7094 40.8199C20.7794 40.2899 21.2494 39.7799 22.1694 39.4499C23.0894 39.1199 24.0794 39.3199 24.2994 39.9299L24.6894 41.0299C24.9094 41.6399 24.3094 42.4099 23.3494 42.7499L22.4794 43.0599'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M24.4902 40.4993L27.0902 39.5693'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M15.8305 43.5996L13.2305 44.5296'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M22.327 48.1153C27.5903 46.2346 30.8868 41.9947 29.69 38.6454C28.4931 35.296 23.2562 34.1055 17.9929 35.9862C12.7296 37.867 9.43312 42.1068 10.63 45.4561C11.8268 48.8055 17.0637 49.9961 22.327 48.1153Z'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M10.6406 45.4496L11.8806 48.9096C13.0806 52.2596 18.3106 53.4496 23.5706 51.5696C28.8306 49.6896 32.1306 45.4496 30.9306 42.0996L29.6906 38.6396'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect
          width='45.96'
          height='47.47'
          fill='white'
          transform='translate(7 6)'
        />
      </clipPath>
    </defs>
  </svg>
);