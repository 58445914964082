import styled from 'styled-components';
import { BgColors } from '../Content/types';
import { bgColors } from '../helpers';

export const Wrap = styled.div<{ picture: string; bg_color: BgColors }>`
  background-color: ${({ bg_color }) => bgColors[bg_color]};
  background-size: contain;
  background-position: center right;
  background-image: ${({ picture }) => `url('${picture}')`};
  background-repeat: no-repeat;
  border-radius: 14px;
  max-width: 1236px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
`;

export const Title = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.mainBgColor};
  margin: 3px 0 0;
`;

export const Inner = styled.div`
  width: 50%;
  padding: 40px 0 40px 40px;
`;

export const Date = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 72px 0 0;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
`;
