import styled from 'styled-components';
import { Title } from './Title';
import { Preloader } from 'ui/Preloader';
import { Button } from 'ui/Button';

export const ListWrap = styled.div`
  margin: 0 -12px -40px;
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px 40px;
  width: 100%;
`;

export const StyledTitle = styled(Title)`
  margin: 0 24px 24px;
`;

export const StyledButton = styled(Button)`
  margin: 0 12px 18px;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
`;
