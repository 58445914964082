import React from 'react';
export const DeleteUploadIcon = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 15.9999C15.6095 16.3905 14.9764 16.3905 14.5858 15.9999L9.00005 10.4141C8.60952 10.0236 8.60952 9.39045 9.00005 8.99992C9.39057 8.6094 10.0237 8.6094 10.4143 8.99992L16 14.5857C16.3906 14.9762 16.3906 15.6094 16 15.9999Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 8.99992C16.3906 9.39045 16.3906 10.0236 16 10.4141L10.4143 15.9999C10.0237 16.3904 9.39057 16.3904 9.00005 15.9999C8.60952 15.6094 8.60952 14.9762 9.00005 14.5857L14.5858 8.99992C14.9764 8.6094 15.6095 8.6094 16 8.99992Z'
      fill='white'
    />
  </svg>
);