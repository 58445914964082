import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.span`
  ${({ theme }) => theme.typography.headline2};
  margin-bottom: 24px;
`;

const Title: React.FC = props => {
  return <StyledTitle {...props} />;
};

export default Title;
