import { useQuery } from 'react-query';
import { getUserFx, setUser } from 'models/auth/model';
import { userProfileQueryProps } from './types';

export const useUserProfileQuery: userProfileQueryProps = () =>
  useQuery('user', () => getUserFx(), {
    onSuccess: response => {
      setUser(response.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
