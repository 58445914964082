import { ItemKnot, ItemText, LegendItem, LegendWrap } from './styles';
import { theme } from 'ui/theme';
import React from 'react';
import { useCalendarCategories } from 'models/calendar/model';

export const titles = {
  courses: 'Саморазвитие',
  events: 'Мероприятия',
  trainings: 'Встречи',
  vip: 'VIP club',
};

export const eventsColors: Record<
  'courses' | 'events' | 'trainings' | 'vip' | 'coach_school',
  string
> = {
  courses: theme.colors.actionColor,
  events: theme.colors.linksColor,
  trainings: theme.colors.thirdColor,
  vip: theme.colors.mainColor,
  coach_school: theme.colors.statusDoneColor,
};

export const Legend: React.FC = () => {
  const newCategoriesArray = useCalendarCategories();

  return (
    <LegendWrap>
      {newCategoriesArray?.map(({ key, title }) =>
        key !== 'all' ? (
          <LegendItem key={key}>
            <ItemKnot color={eventsColors[key]} />
            <ItemText>{title}</ItemText>
          </LegendItem>
        ) : null
      )}
    </LegendWrap>
  );
};
