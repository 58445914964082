import { BgColors } from 'components/Course/Content/types';
import { bgColors } from 'components/Course/helpers';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AgeRestriction } from 'ui/AgeRestriction';
import { Tag } from 'ui/Tag';
import { Progress } from './types';

export const ImageWrap = styled.div<{
  border?: boolean;
  bg_color: BgColors;
  image?: string;
}>`
  height: 256px;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  background-color: ${({ bg_color, image }) => image && bgColors[bg_color]};
  ${({ border, theme }) =>
    border && `border: 2px solid ${theme.colors.thirdBgColor}`};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Age = styled(AgeRestriction)`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
`;

export const CatalogItemWrap = styled(Link)`
  flex-basis: calc((100% - 48px) / 2);
  margin: 0 12px 40px;
  padding-bottom: 24px;
`;

export const TagItem = styled(Tag)``;

export const EventDateWrap = styled.div`
  margin-top: 24px;
  display: flex;
`;

export const EventBeginning = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const EventDate = styled.span`
  ${({ theme }) => theme.typography.bodyText1};
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const EventDuration = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0 0 0 40px;
`;

export const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px -5px 0;
  & ${TagItem} {
    margin: 0 5px;
  }
`;
export const CatalogItemTitle = styled.div`
  ${({ theme }) => theme.typography.headline2};
  margin-top: 12px;
  max-width: 100%;
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 25px 0 0;
`;

export const ProgressBarPercent = styled.p`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0 14px 0 0;
`;

export const ProgressBarDot = styled.div<{ started: boolean }>`
  background-color: ${({ theme, started }) =>
    started ? theme.colors.actionColor : theme.colors.secondColor};
  border-radius: 5px;
  height: 6px;
  width: 6px;
  margin: 0 4px 0 0;
`;

export const ProgressBarLine = styled.div`
  background-color: ${({ theme }) => theme.colors.secondColor};
  border-radius: 5px;
  height: 6px;
  flex: 1 0 220px;
`;

export const ProgressBarDone = styled.div<{
  progress?: Progress;
}>`
  background: ${({ theme }) => theme.colors.actionColor};
  border-radius: 5px;
  height: 100%;
  width: calc(
    (100% / ${({ progress }) => progress?.total}) *
      ${({ progress }) => (progress?.done ? progress?.done : 0)}
  );
`;

export const ProgressBarFinish = styled.div<{
  progress?: Progress;
}>`
  width: 24px;
  height: 24px;
  display: flex;
  margin: 0 0 0 4px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  background-color: ${({ theme, progress }) =>
    progress?.done === progress?.total
      ? theme.colors.actionColor
      : theme.colors.secondColor};
  border-radius: 50%;
`;

export const ProgressBarCount = styled.p`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  white-space: nowrap;
  margin: 0 0 0 40px;
`;
