import React from 'react';

export const FileLargeIcon = (
  <svg
    width='34'
    height='34'
    viewBox='0 0 34 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.2322 8.56555C10.7011 8.0967 11.337 7.83331 12 7.83331H17.8333C18.0543 7.83331 18.2663 7.92111 18.4226 8.07739L24.2559 13.9107C24.4122 14.067 24.5 14.279 24.5 14.5V23.6666C24.5 24.3297 24.2366 24.9656 23.7678 25.4344C23.2989 25.9033 22.663 26.1666 22 26.1666H12C11.337 26.1666 10.7011 25.9033 10.2322 25.4344C9.76339 24.9656 9.5 24.3297 9.5 23.6666V10.3333C9.5 9.67027 9.76339 9.03439 10.2322 8.56555ZM12 9.49998C11.779 9.49998 11.567 9.58778 11.4107 9.74406C11.2545 9.90034 11.1667 10.1123 11.1667 10.3333V23.6666C11.1667 23.8877 11.2545 24.0996 11.4107 24.2559C11.567 24.4122 11.779 24.5 12 24.5H22C22.221 24.5 22.433 24.4122 22.5893 24.2559C22.7455 24.0996 22.8333 23.8877 22.8333 23.6666V14.8452L17.4882 9.49998H12Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.8333 7.83331C18.2936 7.83331 18.6667 8.20641 18.6667 8.66665V13.6666H23.6667C24.1269 13.6666 24.5 14.0397 24.5 14.5C24.5 14.9602 24.1269 15.3333 23.6667 15.3333H17.8333C17.3731 15.3333 17 14.9602 17 14.5V8.66665C17 8.20641 17.3731 7.83331 17.8333 7.83331Z'
      fill='#B1B1B1'
    />
  </svg>
);

