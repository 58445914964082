import { Modal } from 'ui/Modal';
import { Authorisation } from './Authorisation';
import { PhoneRestore } from './PhoneRestore';
import React, { useState } from 'react';
import { HeaderModalProps, HeaderModalStepKeys } from './types';
import { SuccessFeedback } from './SuccessFeedback';
import { Registration } from './Registration';

export const HeaderModal: React.FC<HeaderModalProps> = ({ closeModal }) => {
  const [step, setStep] = useState<HeaderModalStepKeys>('auth');
  const [successTitle, setSuccessTitle] = useState('');
  const [successText, setSuccessText] = useState('');

  const handleSuccessFeedbackContent = (title?: string, text?: string) => {
    if (title) setSuccessTitle(title);
    if (text) setSuccessText(text);
  };

  const renderContent = () => {
    switch (step) {
      case 'auth': {
        return <Authorisation close={closeModal} setStep={setStep} />;
      }
      case 'registration': {
        return (
          <Registration
            closeModal={closeModal}
            goBack={() => setStep('auth')}
            handleSuccess={() => {
              setStep('success');
              handleSuccessFeedbackContent(
                'Вы успешно зарегистрировались!',
                'Используйте логин и пароль для входа в аккаунт'
              );
            }}
          />
        );
      }
      case 'restore': {
        return (
          <PhoneRestore
            handleSuccess={() => {
              setStep('success');
              handleSuccessFeedbackContent(
                'Пароль успешно изменен!',
                'Используйте новый пароль для входа в аккаунт'
              );
            }}
          />
        );
      }
      case 'success': {
        return (
          <SuccessFeedback
            handleClose={closeModal}
            timeout={3000}
            title={successTitle}
            text={successText}
          />
        );
      }
    }
  };

  return <Modal handleClose={closeModal}>{renderContent()}</Modal>;
};
