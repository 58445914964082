import styled from 'styled-components';
import { LoginButton } from '../Header/styles';

export const IconBackgrounds = {
  telegram: '#4BB5F1',
  instagram:
    'linear-gradient(152.53deg,#feda75 13.16%,#fa7e1e 34.27%,#d62976 56.36%,#962fbf 74.37%,#4f5bd5 91.51%)',
};

export const SocialNetworkIcon = styled.a<{
  iconBackground: string;
  isActive?: boolean;
}>`
  padding: 9px;
  margin: 0;
  border-radius: 14px;
  font-size: 0;
  background: ${({ theme, isActive, iconBackground }) =>
    isActive ? iconBackground : theme.colors.secondBgColor};
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: block;

  & + ${LoginButton} {
    margin-left: auto;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: ${({ theme, isActive, iconBackground }) =>
      !isActive ? iconBackground : theme.colors.secondBgColor};
    transition: all 0.3s;
    opacity: 0;
  }

  &:focus {
    outline: none;
    ${({ theme }) => `box-shadow: 0 0 0 2px ${theme.colors.thirdBgColor};`}
  }

  ${({ isActive, theme }) =>
    !isActive &&
    `
  &:hover {
    & > svg {
      transition: all 0.3s;
      fill: ${theme.colors.mainBgColor};
    }

    &:after {
      transition: all 0.3s;
      opacity: 1;
    }
  }
  `}
  & > svg {
    position: relative;
    transition: all 0.3s;
    width: 22px;
    height: 22px;
    z-index: 2;
    fill: ${({ theme }) => theme.colors.mainColor};
    ${({ isActive, theme }) => isActive && `fill: ${theme.colors.mainBgColor}`}
  }
`;
