import styled from 'styled-components';
import { CategoryButton } from 'ui/CategoryButton';
import { Button } from 'ui/Button';
import { StickyChildArgs } from 'react-sticky';

export const Category = styled(CategoryButton)``;

export const CategoryWrap = styled.div<{
  offsetFromTop: number;
  sticky: StickyChildArgs;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: ${({ sticky }) => (sticky.isSticky ? 'fixed' : 'absolute')};
  top: ${({ offsetFromTop, sticky }) =>
    sticky.isSticky
      ? `${Number(sticky.style.top) - offsetFromTop}px`
      : `initial`};
  & ${Category} + ${Category} {
    margin-top: 12px;
  }
`;

export const BackButton = styled(Button)`
  margin-bottom: 32px;
`;
