import React from 'react';
import styled from 'styled-components';
import Month from './Month/Month';
import { eachMonthOfInterval } from 'date-fns';
import { RouteComponentProps } from 'react-router-dom';

const YearWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, auto);
  column-gap: 24px;
  row-gap: 40px;
  margin-top: 40px;
  margin-bottom: 100px;
`;

export const YearPage: React.FC<RouteComponentProps<{ year: string }>> = ({
  match,
}) => {
  const { year } = match.params;
  const monthsArray = eachMonthOfInterval({
    //Указываем начало года это месяц январь
    start: new Date(`${year} Jan`),
    //Указываем конец года это месяц декабрь
    end: new Date(`${year} Dec`),
  });

  return (
    <YearWrap>
      {monthsArray.map((item, idx) => (
        <Month date={item} key={idx} />
      ))}
    </YearWrap>
  );
};
