import BacsIcon from 'icons/BacsIcon';
import { RatingStarIcon } from 'icons/RatingStarIcon';
import React from 'react';
import {
  BonusAmount,
  InnerBudget,
  Name,
  Photo,
  PhotoBlank,
  PhotoBlankText,
  Rating,
  UserData,
  Value,
  Wrapper,
} from './styles';
import { baseUrl } from 'api/apiClient';
import { Preloader } from 'ui/Preloader';
import { useGetBonusAmount } from 'queries/account/bonusAccount/useGetBonusAmount';
import { useUserProfile } from 'models/auth/model';
import { useGetInnerBudgetQuery } from 'queries/account/innerBudget/useGetInnerBudgetQuery';
import { formatUserMiniatureName } from 'utilities/formatUserMiniatureName';

export const Header: React.FC = props => {
  const user = useUserProfile();
  const {
    data: bonusAmount,
    isLoading: isBonusAmountLoading,
  } = useGetBonusAmount();
  const {
    data: innerBudget,
    isLoading: InnerBudgetisLoading,
  } = useGetInnerBudgetQuery();

  const isLoading = isBonusAmountLoading || InnerBudgetisLoading;

  return (
    <Wrapper {...props}>
      {user && !isLoading ? (
        <>
          {user?.avatar ? (
            <Photo src={baseUrl + user?.avatar} alt={user?.name} />
          ) : (
            <PhotoBlank>
              <PhotoBlankText>
                {formatUserMiniatureName(user?.name, user?.last_name)}
              </PhotoBlankText>
            </PhotoBlank>
          )}
          <UserData>
            <Name>{`${user?.name} ${user?.last_name}`}</Name>
            <Rating>
              <BonusAmount>
                {RatingStarIcon}
                <Value>{bonusAmount.user_score}</Value>
              </BonusAmount>
              <InnerBudget>
                {BacsIcon}
                <Value>{innerBudget}</Value>
              </InnerBudget>
            </Rating>
          </UserData>
        </>
      ) : (
        <Preloader />
      )}
    </Wrapper>
  );
};
