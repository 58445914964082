import styled from 'styled-components';

export const Icon = styled.div`
  font-size: 0;
  margin: 0 10px 0 0;
`;

export const Text = styled.span`
  ${({ theme }) => theme.typography.bodyText2}
`;
