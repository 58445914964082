import { useMutation } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { ChatSendFile } from './types';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';

export const useChatSendFileQuery: ChatSendFile = () => {
  return useMutation(
    'chatSendFile',
    files => {
      // TODO: отправка файлов в разработке
      const formData = new FormData();
      Array.from(files).forEach(file => formData.append('file[]', file));
      return apiClient.post(Endpoint.CHAT_SEND_FILE, formData);
    },
    {
      onError: res => {
        toast.error(<ErrorMessage text={res.response?.data.error} />);
      },
    }
  );
};
