import React from 'react';

export const BlackholeLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M14.1699 26.9998C14.4299 23.5698 17.0699 17.3998 25.5299 14.4298C34.7099 11.2098 46.3999 13.8898 50.8199 17.8998'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M41.6789 48.5098C51.7889 42.9198 53.8489 31.5598 46.9089 23.7398C41.6989 17.8798 31.3189 16.5298 23.7289 20.7198C18.0289 23.8698 16.5789 29.9998 20.4989 34.4098C23.4389 37.7198 29.2889 38.4798 33.5689 36.1198C36.7789 34.3398 37.5989 30.8898 35.3889 28.3998'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M46.8199 33.5195C46.5499 36.9495 43.9099 43.1095 35.4499 46.0795C26.2699 49.3095 14.5899 46.6195 10.1699 42.6095'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M19.2998 12C9.18981 17.59 7.12981 28.95 14.0698 36.77C19.2798 42.64 29.6598 43.99 37.2498 39.8C42.9498 36.65 44.3998 30.52 40.4798 26.12C37.5398 22.81 31.6898 22.05 27.4098 24.41C24.1998 26.19 23.3798 29.64 25.5898 32.13'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect
          width='42.98'
          height='38.51'
          fill='white'
          transform='translate(9 11)'
        />
      </clipPath>
    </defs>
  </svg>
);