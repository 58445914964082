import React, { useEffect } from 'react';
import {
  Wrap,
  StyledSidebar,
  StyledContent,
  BackButton,
  StyledPreloader,
  PlayerWrap,
  NoData,
} from './styles';
import { RouteComponentProps } from 'react-router';
import { useStore } from 'effector-react';
import { $lesson } from 'models/lesson';
import { useLessonQuery } from 'queries/lesson/useLessonQuery';
import 'plyr/dist/plyr.css';
import { ChevronLeftIcon } from 'icons';
import { VideoPlayer } from 'components/common/VideoPlayer';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import { Path } from 'constants/paths';

export const Lesson: React.FC<
  RouteComponentProps<{ courseId: string; lessonId: string }>
> = ({ match, history }) => {
  const { lessonId, courseId } = match.params || {};
  const isSchool = match.url.includes(Path.coaching);
  const lesson = useStore($lesson);
  const { isLoading, isFetching, refetch, error, isError } = useLessonQuery({
    courseId,
    lessonId,
    isSchool,
  });
  useScrollToTopOnMount();

  useEffect(() => {
    refetch();
  }, [match, refetch]);

  useScrollToTopOnMount();

  const showPreloader = isLoading || isFetching;

  const handleBackButton = () => {
    history.push(isSchool ? `/school/${courseId}` : `/course/${courseId}`, {
      canView: true,
    });
  };

  if (isError && error) {
    return <NoData>{error.response?.data.error}</NoData>;
  }

  return showPreloader ? (
    <StyledPreloader />
  ) : lesson ? (
    <Wrap>
      <StyledSidebar />
      <PlayerWrap>
        {lesson.video.map((source, index) => (
          <VideoPlayer key={index} source={source} index={index} />
        ))}
      </PlayerWrap>
      <BackButton
        buttonType={'link'}
        icon={ChevronLeftIcon}
        onClick={handleBackButton}
      >
        Ко всем темам
      </BackButton>
      <StyledContent data={lesson} />
    </Wrap>
  ) : null;
};
