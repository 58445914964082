import styled from 'styled-components';

export const Wrap = styled.div`
  text-align: center;
  position: relative;
`;

export const Title = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.headline2};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  opacity: 0.5;
`;
