import styled from 'styled-components';
import { Button } from 'ui/Button';

export const List = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-radius: 14px;
  padding: 24px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  width: 100%;
  height: 1px;
  margin: 24px 0;
`;

export const Inner = styled.div`
  max-width: 489px;
`;

export const TextIcon = styled.span``;

export const Title = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0;
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  font-weight: 700;
  margin: 8px 0 0;
  display: flex;
  align-items: center;

  & > ${TextIcon} {
    margin-right: 8px;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
`;
