import React from 'react';
import { TextSC, Wrap } from './styled';
import { Button } from 'ui/Button';
import { Link } from 'react-router-dom';
import { Path } from 'constants/paths';
import { Filter } from '../GiftCards';

interface IProps {
  sortType: Filter;
}

export const EmptyItem: React.FC<IProps> = ({ sortType }) => {
  const descriptions = {
    [Filter.ACTIVATED]: 'активированных',
    [Filter.UNACTIVATED]: 'неактивированных',
    [Filter.ALL]: 'подарочных',
  };

  return (
    <Wrap>
      <TextSC>
        {`У вас нет ${descriptions[sortType]} карт. Вы можете приобрести подарочную карту
        или получить ее в подарок`}
      </TextSC>
      <Link to={Path.catalogGoods}>
        <Button buttonType='primary'>Приобрести карту</Button>
      </Link>
    </Wrap>
  );
};
