import { useMutation, UseMutationResult } from 'react-query';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateFormats } from 'constants/dateFormats';
import { getBonusHistoryFx } from 'models/bonus/model';
import { BonusHistoryItem, BonusHistoryMutateProps } from 'models/bonus/types';
import { AxiosError, AxiosResponse } from 'axios';

export const useGetBonusHistoryQuery: () => UseMutationResult<
  AxiosResponse<{ data: BonusHistoryItem[] }>,
  AxiosError<{ error: string }>,
  BonusHistoryMutateProps
> = () =>
  useMutation<
    AxiosResponse<{ data: BonusHistoryItem[] }>,
    AxiosError<{ error: string }>,
    BonusHistoryMutateProps
  >(
    'bonusHistory',
    ({ range, offset, isEmptyPeriod }) => {
      const { startDate, endDate } = range[0] || {};
      return !isEmptyPeriod && startDate && endDate
        ? getBonusHistoryFx({
            date_start: format(
              new Date(startDate),
              DateFormats.FullDateAsDigits,
              {
                locale: ru,
              }
            ),
            date_end: format(new Date(endDate), DateFormats.FullDateAsDigits, {
              locale: ru,
            }),
            offset,
          })
        : getBonusHistoryFx({
            date_start: '',
            date_end: '',
            offset,
          });
    },
    {
      retry: 0,
    }
  );
