import styled from 'styled-components';
import { Title } from './Title';
import { Categories } from './Categories';
import { List } from './List/';

export const Wrap = styled.div`
  display: grid;
  margin: 40px auto 0;
  grid-template-areas:
    'empty empty empty header header header header header header header header header'
    'sidebar sidebar sidebar content content content content content content content content content';
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto 1fr;
  gap: 24px;
  padding: 0 12px 100px;
  max-width: 1236px;
`;

export const StyledTitle = styled(Title)`
  grid-area: header;
`;

export const StyledCategories = styled(Categories)`
  grid-area: sidebar;
`;

export const StyledList = styled(List)`
  grid-area: content;
`;

export const titles = {
  'self-education': 'Саморазвитие',
  trainings: 'Встречи',
  rasstanovki: 'Расстановки',
  'vip-club': 'VIP-клуб',
  'inner-insight': 'Inner insight',
};
