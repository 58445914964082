import { useMutation, UseMutationResult } from 'react-query';
import { purchaseGiftCardFx } from 'models/catalog/model';
import { AxiosError, AxiosResponse } from 'axios';

export const usePurchaseGiftCardQuery: () => UseMutationResult<
  AxiosResponse<{
    order_id: number;
  }>,
  AxiosError<{ error: string }>,
  string
> = () => {
  return useMutation('purschaseGiftCard', id => purchaseGiftCardFx(id));
};
