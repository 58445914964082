import styled from 'styled-components';
import { EventItem } from '../../common/EventItem';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledEventItem = styled(EventItem)`
  margin-top: 24px;
`;

export const StyledPreloader = styled(Preloader)`
  grid-area: content;
  margin: 120px auto 0;
  text-align: center;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;
