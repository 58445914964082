import React from 'react';

export const InfoIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 8C9.55228 8 10 8.3731 10 8.83333V12.1667C10 12.6269 9.55228 13 9 13C8.44772 13 8 12.6269 8 12.1667V8.83333C8 8.3731 8.44772 8 9 8Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 6C8 5.44772 8.44549 5 8.99502 5H9.00498C9.55451 5 10 5.44772 10 6C10 6.55228 9.55451 7 9.00498 7H8.99502C8.44549 7 8 6.55228 8 6Z'
    />
  </svg>
);

