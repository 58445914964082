import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';
import { Tabs } from 'ui/Tabs';

export const NoData = styled.p`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
  margin: 0 0 24px;
`;

export const StyledPreloader = styled(Preloader)`
  grid-area: content;
  margin: 120px auto 0;
  text-align: center;
`;

export const StyledTabs = styled(Tabs)`
  margin: 16px 0 0;
`;

export const List = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 24px -12px -40px;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;
