import { useStore } from 'effector-react';
import { ChevronDownSmallIcon } from 'icons';
import { $orders, clearOrders } from 'models/orders/model';
import { useOrderListQuery } from 'queries/orders/useOrderListQuery';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Button } from 'ui/Button';
import Title from '../../Title';
import OrderEmpty from './OrderEmpty';
import { OrderInfo } from './OrderInfo';
import { OrderItem } from './OrderItem';
import { MoreButton, PreloaderSC, Wrapper } from './styled';

const PACK_VALUE = 12;

export const Orders: React.FC = () => {
  const matchUrl = useRouteMatch()?.url || '';
  const [offset, setOffset] = useState(0);
  const { data, isLoading, isFetching } = useOrderListQuery(offset);
  const orders = useStore($orders);
  const showButton = !!orders.length && data?.length % PACK_VALUE === 0;
  useEffect(() => {
    return () => {
      clearOrders();
    };
  }, []);

  return (
    // TODO вынести рутинг или изменить структуру
    <Switch>
      <Route path={`${matchUrl}/:id`} component={OrderInfo} />
      <Route
        path={`${matchUrl}`}
        render={() => (
          <Wrapper>
            <Title>{'Заказы'}</Title>
            {!orders.length && !isLoading && !isFetching && <OrderEmpty />}
            {isLoading && <PreloaderSC />}
            {orders.map(order => (
              <OrderItem data={order} key={order.id} />
            ))}
            {showButton && (
              <MoreButton>
                <Button
                  buttonType={'link'}
                  icon={ChevronDownSmallIcon}
                  iconAlign='right'
                  onClick={() => setOffset(offset + PACK_VALUE)}
                >
                  Показать ещё
                </Button>
              </MoreButton>
            )}
          </Wrapper>
        )}
      />
    </Switch>
  );
};
