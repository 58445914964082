import styled from 'styled-components';

export const StepIcon = styled.span`
  transition: fill 0.3s;
`;

export const StepText = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  transition: color 0.3s;
`;

export const StepWrap = styled.div<{ isActive: boolean }>`
  border-radius: 14px;
  transition: all 0.3s;
  color: ${props =>
    props.isActive
      ? props.theme.colors.mainBgColor
      : props.theme.colors.mainColor};
  display: inline-flex;
  padding: ${props => props.theme.button.secondary.padding.large};
  align-items: center;
  margin: 0;
  outline: none;
  border: 2px solid transparent;
  background-color: ${props =>
    props.isActive ? props.theme.colors.mainColor : 'transparent'};
  &:hover {
    ${props =>
      props.isActive
        ? `color: ${props.theme.colors.mainBgColor};`
        : `color: ${props.theme.colors.mainColor};`}
    ${props =>
      props.isActive
        ? `border: 2px solid ${props.theme.colors.mainColor};`
        : null}
    transition: all 0.3s;
  }
  &:focus {
    outline: auto;
  }
  & ${StepIcon} {
    fill: ${props =>
      props.isActive
        ? props.theme.colors.actionColor
        : props.theme.colors.mainColor};
  }

  & ${StepIcon} + ${StepText}, & ${StepText} + ${StepIcon} {
    margin-left: 10px;
  }
`;
