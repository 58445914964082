import React, { ChangeEvent } from 'react';
import {
  Title,
  Item,
  CityInput,
  AddressInput,
  HouseInput,
  Country,
  LabelSC,
  RadioGroup,
} from './styles';
import { Field, useField, useForm } from 'react-final-form';
import { Radio } from 'ui/Radio';

export const DeliveryAddressFields: React.FC<{
  titleName?: string;
  hasCusmotStyle?: boolean;
}> = ({ titleName, hasCusmotStyle, ...rest }) => {
  const cityCodeField = useField('city_code');
  const countryField = useField('country');
  const form = useForm('DeliveryForm');
  const handleCountryChange = () => {
    form.change('city_code', '');
    form.change('postal_code', '');
    form.change('address', '');
    form.change('house', '');
    form.change('flat', '');
    form.change('domophone', '');
    form.change('city', '');
  };

  return (
    <div {...rest}>
      <Title hasCusmotStyle={hasCusmotStyle}>
        {titleName || 'Адрес доставки'}
      </Title>
      <Country>
        <LabelSC>Страна</LabelSC>
        <RadioGroup>
          <Field name='country' type='radio' value='Россия'>
            {({ input }) => {
              return (
                <Radio
                  labelText='Россия'
                  name={input.name}
                  type='radio'
                  value={input.value}
                  checked={input.checked}
                  onChange={(e: ChangeEvent) => {
                    handleCountryChange();
                    input.onChange(e);
                  }}
                />
              );
            }}
          </Field>
          <Field name='country' type='radio' value='Казахстан'>
            {({ input }) => {
              return (
                <Radio
                  labelText='Казахстан'
                  name={input.name}
                  type='radio'
                  value={input.value}
                  checked={input.checked}
                  onChange={(e: ChangeEvent) => {
                    handleCountryChange();
                    input.onChange(e);
                  }}
                />
              );
            }}
          </Field>
          <Field name='country' type='radio' value='Беларусь'>
            {({ input }) => {
              return (
                <Radio
                  labelText='Беларусь'
                  name={input.name}
                  type='radio'
                  value={input.value}
                  checked={input.checked}
                  onChange={(e: ChangeEvent) => {
                    handleCountryChange();
                    input.onChange(e);
                  }}
                />
              );
            }}
          </Field>
        </RadioGroup>
      </Country>

      {countryField.input.value ? (
        <>
          <Field
            name={'city'}
            labelWidth={144}
            inputWidth={340}
            label={'Город'}
            alignLabel={'left'}
            placeholder={'Введите город'}
            component={CityInput}
          />
          {cityCodeField.input.value ? (
            <>
              <Field
                name={'address'}
                labelWidth={144}
                inputWidth={340}
                label={'Улица'}
                alignLabel={'left'}
                placeholder={'Введите улицу'}
                component={
                  countryField.input.value === 'Россия' ? AddressInput : Item
                }
              />
              <Field
                name={'house'}
                labelWidth={144}
                inputWidth={340}
                label={'Дом'}
                alignLabel={'left'}
                placeholder={'Введите дом'}
                component={
                  countryField.input.value === 'Россия' ? HouseInput : Item
                }
              />
              <Field
                name={'flat'}
                labelWidth={144}
                inputWidth={340}
                label={'Квартира'}
                alignLabel={'left'}
                placeholder={'Введите квартиру'}
                component={Item}
              />
              <Field
                name={'domophone'}
                labelWidth={144}
                inputWidth={340}
                label={'Домофон'}
                alignLabel={'left'}
                placeholder={'Введите номер домофона'}
                component={Item}
              />
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
  //Непонятная логика работы адресов для доставки курьером
  //TODO: Разузнать у дизайнера алгоритм работы выбора адресов для курьера и переделать логику под новые требования
  // ) : (
  //   <>
  //     <StyledDeliveryAddressOption />
  //   </>
  // );
};
