import React from 'react';
import { TextSC, Wrapper } from './styled';
import { Button } from 'ui/Button';

const EmptyBonus: React.FC = () => {
  return (
    <Wrapper>
      <TextSC>
        У вас нет операций с бонусами. Вы можете зарабатывать бонусы и
        оплачивать ими покупки.
      </TextSC>
      <div>
        <Button>Подробнее о баллах</Button>
      </div>
    </Wrapper>
  );
};

export default EmptyBonus;
