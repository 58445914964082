import styled, { css } from 'styled-components';
import { InputText } from 'ui/InputText';
import { CitySmartInput } from './CitySmartInput';
import { AddressSmartInput } from './AddressSmartInput';
import { HouseSmartInput } from './HouseSmartInput';

export const CityInput = styled(CitySmartInput)`
  margin: 24px 0 0;
`;
export const AddressInput = styled(AddressSmartInput)`
  margin: 24px 0 0;
`;

export const HouseInput = styled(HouseSmartInput)`
  margin: 24px 0 0;
`;

export const Item = styled(InputText)`
  & + &,
  ${CityInput} + &,
  ${HouseInput} + &,
  ${AddressInput} + & {
    margin: 24px 0 0;
  }
`;

export const Title = styled.p<{ hasCusmotStyle?: boolean }>`
  margin: 0;
  ${({ theme }) => theme.typography.headline2};
  & + ${Item} {
    margin-top: 24px;
  }
  ${({ hasCusmotStyle }) =>
    hasCusmotStyle &&
    css`
      margin-bottom: 124px;
    `};
`;

export const Country = styled.div`
  margin: 16px 0 0 0;
  display: flex;
`;

export const LabelSC = styled.label`
  width: 144px;
  margin: 0 16px 0 0;
  color: ${props => props.theme.colors.secondTextColor};
`;

export const RadioGroup = styled.div`
  & > label {
    margin: 0 0 0 16px;
    &:first-child {
      margin: 0;
    }
  }
`;
