import { useQuery, UseQueryResult } from 'react-query';
import { getPromosListFx, setPromosList } from 'models/sales/model';
import { AxiosError, AxiosResponse } from 'axios';
import { CatalogPromoItem } from 'models/sales/types';

export const usePromosListQuery: () => UseQueryResult<
  AxiosResponse<{ data: CatalogPromoItem[] }>,
  AxiosError<{ error: string }>
> = () =>
  useQuery('promosList', getPromosListFx, {
    onSuccess: res => {
      setPromosList(res.data.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
