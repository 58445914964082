import React from 'react';
import styled from 'styled-components';

const Title = styled.p`
  ${({ theme }) => theme.typography.headline1}
  margin: 0 0 24px;
`;

export const Rules: React.FC = () => {
  return (
    <div id='rules'>
      <Title>Правила</Title>
      <p>
        <span role='img' aria-labelledby='pin'>
          📌
        </span>
        Всем участникам тарифа Базовый предлагается 1 сессия (~30 мин) с коучем
        по улучшению самооценки, для тарифа ВИП - 2 сессии (~по 30 мин). Это
        входит в стоимость марафона.
        <br />
        <br />
        <span role='img' aria-labelledby='attention'>
          ❗
        </span>
        ️ Обращаем ваше внимание, что КОУЧИНГ - это работа клиента над своим
        запросом. Коуч лишь направляет вас точными и мощными вопросами.
        <br />
        <br />
        <span role='img' aria-labelledby='danger'>
          ⚠️
        </span>
        Коуч НЕ консультирует и НЕ говорит, что вам делать. Это ваша работа, все
        ответы внутри вас. Коуч НЕ занимается проблемами, сформированными в
        прошлом (проблемами с родителями/партнерами и т.д.). КОУЧ ЗАНИМАЕТСЯ
        ТОЛЬКО НАСТОЯЩИМ И БУДУЩИМ.
        <br />
        <br />
        <span role='img' aria-labelledby='attention'>
          ❗
        </span>
        ️ С каждым участником коуч свяжется на второй неделе марафона!
        <br />
        <br />
        <span role='img' aria-labelledby='heart'>
          ❤️
        </span>
        ПРЕДУПРЕЖДЕНИЕ
        <span role='img' aria-labelledby='heart'>
          ❤️
        </span>
        <br />
        <br />
        <span role='img' aria-labelledby='double-attention'>
          ‼️
        </span>
        БУДЬТЕ ВНИМАТЕЛЬНЫ!
        <span role='img' aria-labelledby='double-attention'>
          ‼️
        </span>
        <br />
        <br />
        <span role='img' aria-labelledby='bulb'>
          💡
        </span>
        Вся информация в рабочем чате строго конфиденциальна, ее распространение
        преследуется в соответствии с действующим законодательством.
        <span role='img' aria-labelledby='stop'>
          🚫
        </span>
        Передача третьим лицам материалов курса, а также личных данных
        участников (телефонов, фамилий и пр.) также категорически не допускается
        и преследуется по закону! <br />
        <br />
        <span role='img' aria-labelledby='stop'>
          🚫
        </span>
        Создатели и участники альтернативных чатов будут без объяснений УДАЛЕНЫ
        из рабочего чата марафона и продолжат самостоятельное изучение лекций
        только на сайте.
        <br />
        <br />
        <span role='img' aria-labelledby='lights'>
          🚨
        </span>
        Информационные материалы в чате и личном кабинете предназначаются
        ИСКЛЮЧИТЕЛЬНО для персонального ознакомления авторизованным
        пользователем.
        <br />
        <br />
        <span role='img' aria-labelledby='lights'>
          🚨
        </span>
        Копирование или запись материалов преследуется по закону. За нарушение
        предусмотрена уголовная ответственность!
        <br />
        <br />
        <span role='img' aria-labelledby='heart'>
          ❤️
        </span>
        ТЕХНИЧЕСКИЕ ВОПРОСЫ
        <span role='img' aria-labelledby='heart'>
          ❤️
        </span>
        <br />
        <br />
        <span role='img' aria-labelledby='tools'>
          🛠
        </span>
        По ВСЕМ ТЕХНИЧЕСКИМ вопросам просим обращаться на почтовый адрес
        администраторов
        <span role='img' aria-labelledby='letter'>
          📧
        </span>
        admin@budsmuzhikom.ru
        <br />
        <br />
        Вам ответит дежурный администратор и поможет решить вопрос.
        <br />
        <br />
        Искренне Ваша, команда budsmuzhikom
      </p>
    </div>
  );
};
