import styled from 'styled-components';
import { Button } from 'ui/Button';
import { EmailCheck } from './EmailCheck';
import { PaymentOption } from './PaymentOption';
import { TotalPayment } from './TotalPayment';
import { Preloader } from 'ui/Preloader';

export const StyledEmailCheck = styled(EmailCheck)`
  margin-top: 60px;
`;
export const StyledPaymentOption = styled(PaymentOption)``;

export const PaymentLotList = styled.div`
  display: flex;
  flex-direction: column;
  & + ${StyledPaymentOption} {
    margin: 60px 0 0;
  }
`;

export const StyledTotalPayment = styled(TotalPayment)`
  margin-top: 60px;
`;
export const PaymentButton = styled(Button)`
  width: 100%;
  margin: 24px 0 0;
`;

export const OfferLicence = styled.div`
  display: flex;
  margin: 24px 0 0;
  align-items: flex-start;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 30px auto 0;
  padding: 0 0 6px 0;
  text-align: center;
`;

export const OfferLicenceText = styled.label`
  margin: 0 0 0 12px;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;
