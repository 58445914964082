import { createEffect, createEvent, createStore } from 'effector';
import { AxiosError, AxiosResponse } from 'axios';
import { Address, FormValues } from './types';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { useStore } from 'effector-react';
import { formatAddress } from 'utilities/formatAddress';

export const getAddressesFx = createEffect<
  void,
  AxiosResponse<{ data: Address[] }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_ADDRESSES));

export const $addresses = createStore<Address[]>([]);
export const setAddresses = createEvent<Address[]>();

$addresses.on(setAddresses, (store, payload) => payload);

export const deleteAddressFx = createEffect<
  string,
  AxiosResponse<{ data: unknown }>,
  AxiosError<{ error: string }>
>(id => apiClient.delete(Endpoint.DELETE_ADDRESS + id));

export const addAddressFx = createEffect(async (values: FormValues) => {
  return apiClient.post(Endpoint.ADD_ADDRESS, {
    name: values.name,
    address: formatAddress(values),
    city_code: String(values.city_code),
  });
});

export const useAddresses: () => Address[] = () => useStore($addresses);
