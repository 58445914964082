import { createEffect, createEvent, createStore } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { TCourse, TReply, TTestimonials } from './types';
import { AxiosError, AxiosResponse } from 'axios';
import { useStore } from 'effector-react';

const initialTestimonials = {
  rating: 0,
  voices: 0,
  replies: [],
};

export const $course = createStore<TCourse | null>(null);
export const $testimonials = createStore<TTestimonials>(initialTestimonials);

export const getCourseFx = createEffect<
  { id: number; purchased?: boolean },
  AxiosResponse<{
    data: TCourse;
  }>,
  AxiosError<{ error: string }>
>(({ id, purchased = false }) =>
  purchased
    ? apiClient.get(Endpoint.GET_COURSES_PURCHASED + `/${id}`)
    : apiClient.get(Endpoint.GET_COURSES + `/${id}`)
);

export const getRepliesWithOffsetFx = createEffect<
  { id: number; currentOffset: number },
  AxiosResponse<{
    data: TReply[];
  }>,
  AxiosError<{ error: string }>
>(({ id, currentOffset }) =>
  apiClient.post(Endpoint.GET_TESTIMONIALS, {
    entity_id: id,
    entity_type: 'courses',
    offset: currentOffset,
  })
);

export const addReplyFx = createEffect<
  { mark: number; text: string; course_id: number },
  AxiosResponse<{
    data: TReply;
  }>,
  AxiosError<{ error: string }>
>(data => apiClient.post(Endpoint.ADD_TESTIMONIAL, data));

export const setCourse = createEvent<TCourse>();
export const clearCourse = createEvent();
export const setTestimonials = createEvent<TTestimonials | undefined>();
export const setRepliesWithOffset = createEvent<TReply[]>();
export const setReply = createEvent<TReply>();
export const clearCourseTestimonials = createEvent();

$course.on(setCourse, (_, payload) => payload).reset(clearCourse);
$testimonials
  .on(setTestimonials, (_, payload) => payload)
  .on(setRepliesWithOffset, (state, payload) => ({
    ...state,
    replies: [...state.replies, ...payload],
  }))
  .on(setReply, (state, payload) => ({
    ...state,
    replies: [...state.replies, payload],
  }))
  .reset(clearCourseTestimonials);

export const useCourseTestimonials = (): TTestimonials =>
  useStore($testimonials);
