import React from 'react';
import { TrailerProps } from './types';
import { Title } from './styles';
import { VideoPlayer } from 'components/common/VideoPlayer';

export const Trailer: React.FC<TrailerProps> = ({ trailerId }) => {
  return (
    <div id='trailer'>
      <Title>Трейлер</Title>
      <VideoPlayer source={{ id: String(trailerId) }} isTrailer />
    </div>
  );
};
