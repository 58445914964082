import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { CatalogCategoriesKey } from './Categories/types';
import { PromoCarousel } from 'components/common/PromoCarousel';
import { Inner, StyledCategory, StyledList, CarouselWrap } from './styles';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import { Path } from 'constants/paths';
import { Recommended } from './Recommended';
const titles = {
  courses: 'Саморазвитие',
  books: 'Книги',
  trainings: 'Встречи',
  events: 'Мероприятия',
  goods: 'Товары',
  'vip-chat': 'VIP-chat',
  'inner-insight': 'Inner insight',
  'vip-club': 'VIP-клуб',
  free: 'FREE',
  secret: 'Telegram канал',
};

export const Catalog: React.FC<
  RouteComponentProps<{ key: CatalogCategoriesKey }>
> = ({ match }) => {
  const { key } = match.params;
  useScrollToTopOnMount();
  return (
    <div>
      <CarouselWrap>
        <PromoCarousel />
      </CarouselWrap>
      <Inner>
        <StyledCategory categoryKey={key} />
        <StyledList categoryTitle={titles[key]} />
      </Inner>
      <Route
        path={[
          `${Path.catalogBooks.replace('books', ':key')}/:id`,
          `${Path.catalogGiftCards.replace('gift-cards', ':key')}/:id`,
          `${Path.catalogGoods.replace('goods', ':key')}/:id`,
        ]}
        component={Recommended}
      />
    </div>
  );
};
