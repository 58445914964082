import React, { useEffect, useState } from 'react';
import {
  Balance,
  BalanceTytle,
  BalanceValue,
  ClearDateRange,
  Content,
  HeadInfo,
  Help,
  InputSC,
  PeriodField,
  ReferalLinkInput,
  RightBannerColumn,
  SelectPeriodButton,
  StyledButton,
  TextSC,
  Title,
  Wrapper,
} from './styled';
import CopyIcon from 'icons/CopyIcon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ChevronDownSmallIcon } from 'icons/ChevronDownSmallIcon';
import { EmptyBonus } from './EmptyBonus';
import HelpCircleIcon from 'icons/HelpCircleIcon';
import { RangeWithKey } from 'react-date-range';
import { SuccessMessage } from 'ui/SuccessMessage';
import { toast } from 'react-toastify';
import { useGetReferralLink } from 'queries/account/bonusAccount/useGetReferralLink';
import { Preloader } from 'ui/Preloader';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useGetBonusHistoryQuery } from 'queries/account/bonusAccount/useGetBonusHistoryQuery';
import { DateFormats } from 'constants/dateFormats';
import { useGetBonusAmount } from 'queries/account/bonusAccount/useGetBonusAmount';
import { declension } from 'utilities/declension';
import groupBy from 'lodash/groupBy';
import { OperationItem } from './OperationItem';
import { baseUrl } from 'api/apiClient';
import { DateRangePicker } from 'components/common/DateRangePicker';
import {
  clearBonusHistory,
  setBonusHistory,
  useBonusHistory,
} from 'models/bonus';
import { BonusHistoryItem } from 'models/bonus/types';
import { XCloseIcon } from 'icons';

function getFormatedPeriod(date: RangeWithKey) {
  const start = date.startDate;
  const end = date.endDate;
  if (!start || !end) return '';

  return `
    ${format(new Date(start), DateFormats.FullDateAsDigits, {
      locale: ru,
    })}-${format(new Date(end), DateFormats.FullDateAsDigits, {
    locale: ru,
  })}`;
}

const OFFSET = 5;

export const BonusAccount: React.FC = () => {
  const [openDatapicker, setOpenDatapicker] = useState(false);
  const [offset, setOffset] = useState(0);
  const [dateRange, setDateRange] = useState<RangeWithKey[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [isEmptyPeriod, setIsEmptyPeriod] = useState(true);

  const handleClearDateRange = () => {
    setOpenDatapicker(false);
    setIsEmptyPeriod(true);
  };

  const { data: bonusAmount } = useGetBonusAmount();
  const { data: referralLink } = useGetReferralLink();
  const bonusHistory = useBonusHistory();
  const {
    isLoading: isHistoryLoading,
    mutate: fetchBonusHistory,
  } = useGetBonusHistoryQuery();

  const unitedHistoryByDate = Object.values(
    groupBy(bonusHistory, result =>
      format(new Date(result.date_create), DateFormats.BonusHistoryDate, {
        locale: ru,
      })
    )
  );

  const hasNoBonusHistory =
    !isHistoryLoading &&
    !unitedHistoryByDate.length &&
    isEmptyPeriod &&
    !offset;

  const handleCopyLink = () =>
    toast.success(
      <SuccessMessage text={'Cсылка скопированна в буфер обмена'} />
    );

  const handlePeriodSelector = () => setOpenDatapicker(!openDatapicker);

  useEffect(() => {
    fetchBonusHistory(
      {
        offset,
        range: dateRange,
        isEmptyPeriod,
      },
      {
        onSuccess: res => {
          setBonusHistory(res.data.data);
        },
      }
    );
  }, [dateRange, fetchBonusHistory, isEmptyPeriod, offset]);

  useEffect(() => {
    setOffset(0);
    clearBonusHistory();
  }, [dateRange, isEmptyPeriod]);

  useEffect(() => {
    return () => {
      clearBonusHistory();
    };
  }, []);

  const handleMoreButtonClick = () => setOffset(state => state + OFFSET);

  return (
    <Wrapper>
      <HeadInfo>
        <Title>{'Бонусный счет'}</Title>
      </HeadInfo>
      <Content>
        {bonusAmount && (
          <div>
            <Title isSubTitle>{'Ваша реферальная ссылка'}</Title>
            <TextSC>
              Поделитесь ссылкой с другом и когда он зарегистрируется, получите
              300 бонусов на счет
            </TextSC>
            {referralLink && (
              <ReferalLinkInput>
                <span>{referralLink.data.substr(0, 21) + '...'}</span>
                <CopyToClipboard
                  onCopy={handleCopyLink}
                  text={referralLink.data}
                >
                  {CopyIcon}
                </CopyToClipboard>
              </ReferalLinkInput>
            )}
            <Title isSubTitle>История операций</Title>

            <>
              {!hasNoBonusHistory && (
                <PeriodField>
                  <SelectPeriodButton
                    iconAlign={'right'}
                    icon={isEmptyPeriod && ChevronDownSmallIcon}
                    buttonType='link'
                    onClick={handlePeriodSelector}
                    label={
                      !isEmptyPeriod
                        ? getFormatedPeriod(dateRange[0])
                        : 'Период'
                    }
                  />
                  {!isEmptyPeriod && (
                    <ClearDateRange
                      size={'medium'}
                      buttonType={'link'}
                      onClick={handleClearDateRange}
                      iconFill={'#b1b1b1'}
                      icon={XCloseIcon}
                    />
                  )}
                  <InputSC
                    name='period'
                    type='text'
                    value={getFormatedPeriod(dateRange[0])}
                    readOnly
                  />
                </PeriodField>
              )}

              {openDatapicker && (
                <DateRangePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setIsEmptyPeriod={setIsEmptyPeriod}
                  closePicker={setOpenDatapicker}
                />
              )}
              <div>
                {hasNoBonusHistory && <EmptyBonus />}
                {unitedHistoryByDate.map((item: BonusHistoryItem[], index) => (
                  <OperationItem key={index} data={item} />
                ))}
              </div>
              {isHistoryLoading ? (
                <Preloader />
              ) : (
                <StyledButton
                  buttonType={'link'}
                  onClick={handleMoreButtonClick}
                  icon={ChevronDownSmallIcon}
                >
                  Загрузить ещё
                </StyledButton>
              )}
            </>
          </div>
        )}
        <RightBannerColumn>
          {bonusAmount ? (
            <>
              <Balance>
                <BalanceTytle>Мой баланс</BalanceTytle>
                <BalanceValue>
                  {`
                  ${bonusAmount.user_score}
                   ${declension(
                     ['бонус', 'бонуса', 'бонусов'],
                     Number(bonusAmount.user_score)
                   )}
                   `}
                </BalanceValue>
              </Balance>
              <Help>
                {HelpCircleIcon}
                <a href={baseUrl + '/upload/documents/bonus_programm.pdf'}>
                  Условия бонусной программы
                </a>
              </Help>
            </>
          ) : (
            <Preloader />
          )}
        </RightBannerColumn>
      </Content>
    </Wrapper>
  );
};
