import { LeftSidebar } from './LeftSidebar';
import styled from 'styled-components';
import { Content } from './Content';
import { HeaderContent } from './HeaderContent';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  display: grid;
  margin: 40px auto 0;
  grid-template-areas:
    'sidebar sidebar sidebar header header header header header header header header header'
    'sidebar sidebar sidebar content content content content content content content content content';
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  gap: 24px;
  padding: 0 12px 100px;
  max-width: 1236px;
  width: 100%;
`;

export const HeaderContentSC = styled(HeaderContent)`
  grid-area: header;
`;

export const LeftSidebarSC = styled(LeftSidebar)`
  grid-area: sidebar;
`;
export const ContentSC = styled(Content)`
  grid-area: content;
  padding-top: 40px;
  padding-left: 60px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 14px;
  width: 903px;
`;
export const StyledPreloader = styled(Preloader)`
  grid-area: content;
  margin: 120px auto 0;
  text-align: center;
`;
