import { useMutation, useQuery } from 'react-query';
import {
  addReplyFx,
  getCourseFx,
  getRepliesWithOffsetFx,
  setCourse,
  setRepliesWithOffset,
  setReply,
  setTestimonials,
} from 'models/courses/model';
import {
  addReplyCourseQuery,
  UseCourseQuery,
  UseRepliesWithOffsetQuery,
} from './types';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';
import { getCoursesFx } from 'models/catalog';

const fetchPurchasedCourse = async (id: number) => {
  const courses = await getCoursesFx();
  const purchased = courses.data.data.find(course => course.id === id)
    ?.purchased;
  return getCourseFx({ id, purchased });
};

export const useCourseQuery: UseCourseQuery = id =>
  useQuery('course', () => fetchPurchasedCourse(id), {
    onSuccess: res => {
      const { testimonials, ...course } = res.data.data;
      setCourse(course);

      if (testimonials?.replies.length > 3) {
        testimonials.replies = testimonials.replies.slice(0, 3);
      }
      setTestimonials(testimonials);
    },
    refetchOnWindowFocus: false,
  });

export const useRepliesWithOffsetQuery: UseRepliesWithOffsetQuery = (
  id,
  currentOffset
) =>
  useQuery('replies', () => getRepliesWithOffsetFx({ id, currentOffset }), {
    onSuccess: res => {
      setRepliesWithOffset(res.data.data);
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

export const useAddReplyCourseQuery: addReplyCourseQuery = () =>
  useMutation('addReply', data => addReplyFx(data), {
    onSuccess: res => {
      setReply(res.data.data);
    },
    onError: res => {
      toast.error(<ErrorMessage text={res.response?.data.error} />);
    },
  });
