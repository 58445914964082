import React from 'react';
import { Wrap, Title } from './styles';
import { TCourse } from 'models/courses/types';
import { OptionTariffs } from './OptionTariffs';
import { OptionProlongation } from './OptionProlongation';

export const Tariffs: React.FC<
  Pick<TCourse, 'course_fee' | 'id' | 'renewal' | 'active'> & {
    isSchool?: boolean;
  }
> = ({ course_fee, id, renewal, active, isSchool }) => {
  // TODO, есть вопрос по тарифам, пока их скрыл
  if (isSchool) return null;

  return (
    <Wrap id='tariffs'>
      <Title>Тарифы</Title>
      {course_fee ? <OptionTariffs course_fee={course_fee} id={id} /> : null}
      {/*Active не совсем флаг активности. Это скорее описание статуса покупки курса*/}
      {renewal ? (
        <OptionProlongation renewal={renewal} id={id} active={active} />
      ) : null}
    </Wrap>
  );
};
