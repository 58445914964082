// Форматирует секунды в длительность: HH:MM:SS
export const formatSecondsToDuration = (duration?: number): string => {
  if (!duration) return '00:00:00';

  const sec = Math.round(duration);
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;

  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  let sSeconds = seconds.toString();

  if (hours < 10) sHours = '0' + sHours;
  if (minutes < 10) sMinutes = '0' + sMinutes;
  if (seconds < 10) sSeconds = '0' + sSeconds;

  return `${sHours}:${sMinutes}:${sSeconds}`;
};
