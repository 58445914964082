import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { useQuery } from 'react-query';
import { BonusAmountResponse } from './types';

export const useGetBonusAmount = () => {
  const { data, ...rest } = useQuery(
    ['bonusAmount'],
    () => apiClient.get(Endpoint.GET_BONUS_AMOUNT),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { data: data?.data as BonusAmountResponse, ...rest };
};
