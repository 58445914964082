import React, { useRef, useState } from 'react';
import { useStore } from 'effector-react';
import {
  Wrap,
  Title,
  Overlay,
  StyledPreloader,
  RestoreButton,
  SendCodeButton,
} from './styles';
import { LogoIcon } from 'icons';
import { ConfirmCodeForm } from '../common/ConfirmCodeForm';
import { PhoneForm } from './PhoneForm';
import { NewPasswordForm } from './NewPasswordForm';
import {
  $phone,
  $verificationCode,
  setPhone,
  setVerificationCode,
} from 'models/auth/model';
import { useRestorePhoneQuery } from 'queries/useRestorePhoneQuery';
import { useConfirmCodeQuery } from 'queries/useConfirmCodeQuery';
import { useChangePasswordQuery } from 'queries/useChangePasswordQuery';
import { NewPasswordFields, SmsCodeFields } from 'models/auth/types';
import { AxiosError } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';
import { formatValidationErrors } from 'utilities/formatValidationErrors';
import {
  SetSubmitCallbackType,
  SubmitCallbackType,
} from 'interfaces/externalSubmitTypes';

export const PhoneRestore: React.FC<{
  handleSuccess: () => void;
}> = ({ handleSuccess, ...rest }) => {
  const [step, setStep] = useState<'phone' | 'code' | 'newPassword'>('phone');
  const phone = useStore($phone);
  const verification_code = useStore($verificationCode);

  //Колбэк который передаем в useRestorePhoneQuery чтобы он отработал в onSuccess
  function handleRestorePhoneSuccessSubmit(phone: string, code: string) {
    setPhone(phone);
    setVerificationCode(code);
    setStep('code');
  }

  //Колбэк который передаем в useConfirmCodeQuery чтобы он отработал в onSuccess
  function handleConfirmCodeSuccessSubmit() {
    setStep('newPassword');
  }

  const restorePhoneMutation = useRestorePhoneQuery(
    handleRestorePhoneSuccessSubmit
  );

  const confirmCodeMutation = useConfirmCodeQuery(
    handleConfirmCodeSuccessSubmit
  );

  const changePasswordMutation = useChangePasswordQuery(handleSuccess);

  const onSubmitRestorePhoneForm = async (values: { phone: string }) =>
    await new Promise((resolve, reject) =>
      restorePhoneMutation.mutate(values, {
        onError: reject,
        onSuccess: resolve,
      })
    ).catch((err: AxiosError<{ error: ServerValidationError }>) => {
      return formatValidationErrors(err.response?.data.error);
    });

  const onSubmitConfirmCodeForm = async (values: SmsCodeFields) =>
    await new Promise((resolve, reject) =>
      confirmCodeMutation.mutate(
        {
          ...values,
          verification_code,
        },
        {
          onError: reject,
          onSuccess: resolve,
        }
      )
    ).catch((err: AxiosError<{ error: ServerValidationError }>) => {
      return formatValidationErrors(err.response?.data.error);
    });

  const onSubmitChangePasswordForm = async (values: NewPasswordFields) =>
    await new Promise((resolve, reject) =>
      changePasswordMutation.mutate(
        {
          ...values,
          verification_code,
        },
        {
          onError: reject,
          onSuccess: resolve,
        }
      )
    ).catch((err: AxiosError<{ error: ServerValidationError }>) => {
      return formatValidationErrors(err.response?.data.error);
    });

  const fetching = restorePhoneMutation.isLoading;

  // Делаем отправки форм через внешний сабмит
  const buttonHandler = useRef<SubmitCallbackType>();
  const setButtonHandler: SetSubmitCallbackType = func => {
    buttonHandler.current = func;
  };
  const submitHandler = () => {
    buttonHandler?.current?.();
  };

  const renderForm = (value: 'phone' | 'code' | 'newPassword') => {
    switch (value) {
      case 'phone': {
        return (
          // Форма ввода телефона для отправки смс-кода
          <PhoneForm
            onSubmit={onSubmitRestorePhoneForm}
            setButtonHandler={setButtonHandler}
          />
        );
      }
      case 'code': {
        return (
          // Форма для ввода смс-кода
          <ConfirmCodeForm
            type={'restore'}
            phone={phone}
            onSubmit={onSubmitConfirmCodeForm}
            setButtonHandler={setButtonHandler}
          />
        );
      }

      case 'newPassword': {
        return (
          <NewPasswordForm
            setButtonHandler={setButtonHandler}
            onSubmit={onSubmitChangePasswordForm}
          />
        );
      }
      default:
        console.error(`Unknown state: '${value}'!`);
        return null;
    }
  };

  return (
    <Wrap {...rest}>
      {fetching ? <Overlay /> : null}
      <div>{LogoIcon}</div>
      <Title>Восстановление пароля</Title>
      {renderForm(step)}
      {fetching ? (
        <StyledPreloader />
      ) : step === 'phone' ? (
        <RestoreButton onClick={submitHandler}>Изменить пароль</RestoreButton>
      ) : step === 'code' ? (
        <SendCodeButton onClick={submitHandler}>Подтвердить код</SendCodeButton>
      ) : step === 'newPassword' ? (
        <RestoreButton onClick={submitHandler}>Изменить пароль</RestoreButton>
      ) : null}
    </Wrap>
  );
};
