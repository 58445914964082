import React from 'react';
import styled from 'styled-components';
import { SwitchProps } from './types';

const SwitchWrap = styled.div`
  position: relative;
  display: inline-block;
`;

const SwitchKnob = styled.div<{ htmlFor?: string }>`
  transition: all 0.3s;
  width: 22px;
  height: 22px;
  background-color: ${props => props.theme.colors.mainBgColor};
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
`;

const SwitchLabel = styled.label<{ isOn: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 44px;
  height: 26px;
  background: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  background-color: ${props =>
    props.isOn
      ? props.theme.colors.statusDoneColor
      : props.theme.colors.secondColor};
  & > ${SwitchKnob} {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  &:active > ${SwitchKnob} {
    width: 60px;
  }
`;
const SwithcCheckbox = styled.input`
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 3em;
  outline: none;
  z-index: 2;
  &:checked + ${SwitchLabel} > ${SwitchKnob} {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`;

const Switch: React.FC<
  SwitchProps & React.LabelHTMLAttributes<HTMLLabelElement>
> = ({ isOn, handleSwitch, id, htmlFor, className, ...rest }) => {
  const handleChange = () => {
    if (handleSwitch) {
      handleSwitch();
    }
  };

  return (
    <SwitchWrap className={className}>
      <SwithcCheckbox
        type='checkbox'
        checked={isOn}
        onChange={handleChange}
        id={id}
      />
      <SwitchLabel isOn={isOn} {...rest}>
        <SwitchKnob htmlFor={htmlFor} />
      </SwitchLabel>
    </SwitchWrap>
  );
};

export default Switch;
