import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { MyData } from './MyData';
import { Path } from 'constants/paths';
import { Orders } from './Orders';
import { Diplomas } from './Diplomas';
import { GiftCards } from './GiftCards';
import { BonusAccount } from './BonusAccount';
import Premium from './Premium';
import { Addresses } from './Addresses';
import { Password } from './Password';
import { accountTitles } from '../Account';

const ListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px -40px;
`;

export const Content: React.FC = props => {
  const paths = Object.keys(accountTitles);

  return (
    <ListWrap {...props}>
      <Switch>
        <Route path={`${Path.account}/${paths[0]}`}>
          <MyData />
        </Route>
        <Route path={`${Path.account}/${paths[1]}`}>
          <Orders />
        </Route>
        <Route path={`${Path.account}/${paths[2]}`}>
          <Diplomas />
        </Route>
        <Route path={`${Path.account}/${paths[3]}`}>
          <GiftCards />
        </Route>
        <Route path={`${Path.account}/${paths[4]}`}>
          <BonusAccount />
        </Route>
        <Route path={`${Path.account}/${paths[5]}`}>
          <Premium />
        </Route>
        <Route path={`${Path.account}/${paths[6]}`}>
          <Addresses />
        </Route>
        <Route path={`${Path.account}/${paths[7]}`}>
          <Password />
        </Route>
      </Switch>
    </ListWrap>
  );
};
