import React from 'react';
import {
  AtSignIcon,
  ContactPhoneIcon,
  HomeIcon,
  InstagramIcon,
  WebIcon,
} from 'icons';
import {
  Wrap,
  Item,
  Icon,
  Inner,
  Title,
  Divider,
  Text,
  Column1,
  Column2,
  Row,
  Link,
  Heading,
  HighText,
} from './styles';

export const Contacts: React.FC = props => {
  return (
    <Wrap {...props}>
      <Row>
        <Column1>
          <Item>
            <Icon>{AtSignIcon}</Icon>
            <Inner>
              <Title>E-mail</Title>
              <Link href='mailto:admin@budsmuzhikom.ru'>
                admin@budsmuzhikom.ru
              </Link>
            </Inner>
          </Item>
          <Item>
            <Icon>{ContactPhoneIcon}</Icon>
            <Inner>
              <Title>Телефон</Title>
              <Link href='tel:+79859218881'>+7(985)921-88-81</Link>
            </Inner>
          </Item>
        </Column1>
        <Column2>
          <Item>
            <Icon>{HomeIcon}</Icon>
            <Inner>
              <Title>Фактический адрес</Title>
              <Text>
                105318, г. Москва, м.&nbsp;Семёновская,
                ул.&nbsp;Щербаковская,&nbsp;3, <br /> Бизнес-центр
                &laquo;Щербаковский&raquo;,&nbsp;офис N&nbsp;413 Пн-пт:
                с&nbsp;15:00 до&nbsp;20:00
              </Text>
            </Inner>
          </Item>
        </Column2>
      </Row>

      <Divider />

      <Row>
        <Column1>
          <Item>
            <Icon>{WebIcon}</Icon>
            <Inner>
              <Title>Адрес сайта</Title>
              <Link href='https://budsmuzhikom.ru'>budsmuzhikom.ru</Link>
            </Inner>
          </Item>
          <Item>
            <Icon>{WebIcon}</Icon>
            <Inner>
              <Title>Адрес сайта Школы коучинга</Title>
              <Link href='http://innerinsight.ru'>innerinsight.ru</Link>
            </Inner>
          </Item>
        </Column1>
        <Column2>
          <Item>
            <Icon>{InstagramIcon}</Icon>
            <Inner>
              <Title>Блоги в Instagram</Title>
              <Link href='https://www.instagram.com/budsmuzhikom'>
                @budsmuzhikom
              </Link>
              <Link href='https://www.instagram.com/innerinsight'>
                @innerinsight
              </Link>
              <Link href='https://www.instagram.com/rosrascentr'>
                @rosrascentr
              </Link>
            </Inner>
          </Item>
        </Column2>
      </Row>

      <Divider />

      <Heading>Реквизиты</Heading>
      <HighText>ИП Ермакова Алина Михайловна</HighText>
      <HighText>ИНН 771986295094 / ОГРН 318774600342999</HighText>
      <HighText>
        Юридический адрес: 105318 г. Москва, Щербаковская ул., дом 35,
        абонентский ящик 33
      </HighText>
    </Wrap>
  );
};
