import React from 'react';
import { Field, Form } from 'react-final-form';
import { NewPassword, RepeatPassword, StyledForm } from './styles';
import { NewPasswordFormProps } from './types';

export const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
  setButtonHandler,
  ...rest
}) => {
  return (
    <Form
      render={({ handleSubmit }) => {
        setButtonHandler(handleSubmit);
        return (
          <StyledForm onSubmit={handleSubmit}>
            <Field
              labelWidth={144}
              inputWidth={340}
              label='Новый пароль'
              direction='vertical'
              alignLabel='left'
              placeholder='Введите новый пароль'
              name='new_password'
              component={NewPassword}
            />
            <Field
              labelWidth={144}
              inputWidth={340}
              label='Повторите пароль'
              direction='vertical'
              alignLabel='left'
              placeholder='Повторите новый пароль'
              name='repeat_password'
              component={RepeatPassword}
            />
          </StyledForm>
        );
      }}
      {...rest}
    />
  );
};
