import { Dispatch, SetStateAction } from 'react';
import { TForm } from './types';

export const resetForm = (
  form: TForm,
  setUploadedFiles: Dispatch<SetStateAction<Record<string, File>>>
): void => {
  form.reset();
  setUploadedFiles({});
};
