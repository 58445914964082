import styled from 'styled-components';
import { Tag } from 'ui/Tag';
import { Button } from 'ui/Button';
import { InputTextareaProps } from 'ui/InputTextarea/types';
import { InputTextarea } from 'ui/InputTextarea';

export const Wrap = styled.div`
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  padding: 30px 42px 44px;
`;

export const HomeworkTag = styled(Tag)`
  margin-left: 20px;
`;

export const TitleWrap = styled.div`
  align-items: center;
  display: flex;
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline2};
  margin: 0;
`;

export const PurposeTitle = styled.p`
  ${({ theme }) => theme.typography.menuText};
`;

export const PurposeText = styled.p`
  ${({ theme }) => theme.typography.bodyText2}
  margin: 8px 0 30px;
`;

export const Result = styled.div`
  font-size: 0;
  margin: 30px 0 0;
`;

export const ResultTitle = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0;
  margin: 0;
`;

export const ResultTextarea = styled(InputTextarea)<InputTextareaProps>`
  ${({ theme }) => theme.typography.bodyText2};
  min-height: 160px;
  width: 100%;
  outline: none;
  margin: 16px 0 0;
`;

export const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
  margin: 16px 0 0;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const HomeworkMaterials = styled.div``;

export const HomeworkTitle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
`;
