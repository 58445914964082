import { useQuery } from 'react-query';
import { MasterClassesQuery } from './types';
import {
  getMasterClassesFx,
  setMasterClasses,
} from 'models/inner-insight/model';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';

export const useGetMasterClassesQuery: MasterClassesQuery = () =>
  useQuery('masterClasses', getMasterClassesFx, {
    onSuccess: res => {
      setMasterClasses(res.data.data);
    },

    onError: res =>
      toast.error(<ErrorMessage text={res.response?.data.error} />),
    refetchOnWindowFocus: false,
    retry: false,
  });
