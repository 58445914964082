export const InjectionIcon = (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M31.1797 19.4609C30.7267 19.4609 30.3594 19.8282 30.3594 20.2812V21.1016H28.7188V18.6406C28.7188 18.1876 28.3515 17.8203 27.8984 17.8203C27.4454 17.8203 27.0781 18.1876 27.0781 18.6406V19.4609H23.7074C23.7273 19.217 23.7384 18.9785 23.741 18.7463C25.2745 12.6154 25.3828 12.3968 25.3828 11.1243C25.3828 6.64454 21.7383 3 17.2585 3H9.74219C7.84289 3 6.24153 4.29784 5.77702 6.05309C4.62978 7.4882 4 9.28102 4 11.1243C4 12.3941 4.10473 12.6009 5.64194 18.7468C5.69805 23.6173 9.63462 31 14.7188 31C18.2119 31 20.9368 27.5581 22.3687 24.3828H27.0781V25.2031C27.0781 25.6562 27.4454 26.0234 27.8984 26.0234C28.3515 26.0234 28.7188 25.6562 28.7188 25.2031V22.7422H30.3594V23.5625C30.3594 24.0155 30.7267 24.3828 31.1797 24.3828C31.6327 24.3828 32 24.0155 32 23.5625V20.2812C32 19.8282 31.6327 19.4609 31.1797 19.4609ZM9.74219 4.64062H17.2585C20.8336 4.64062 23.7422 7.54918 23.7422 11.1243C23.7422 11.9247 23.6958 12.1867 22.9878 15.009C21.5926 11.8059 18.4114 9.5625 14.7188 9.5625H9.74219C8.38523 9.5625 7.28125 8.45852 7.28125 7.10156C7.28125 5.7446 8.38523 4.64062 9.74219 4.64062ZM5.64062 11.1243C5.64062 10.3513 5.77959 9.58952 6.044 8.87442C6.67909 10.1939 7.99995 11.1234 9.54154 11.198C8.1831 12.152 7.07694 13.4759 6.39805 15.0209C5.68711 12.1873 5.64062 11.9259 5.64062 11.1243ZM14.7188 29.3594C10.7011 29.3594 7.28125 22.7146 7.28125 18.6406C7.28125 14.5396 10.6177 11.2031 14.7188 11.2031C18.7896 11.2031 22.1016 14.5396 22.1016 18.6406C22.1016 18.9028 22.0874 19.1773 22.0603 19.4609H19.6406C19.1876 19.4609 18.8203 19.8282 18.8203 20.2812V21.1016H14.7188C14.2657 21.1016 13.8984 21.4688 13.8984 21.9219C13.8984 22.3749 14.2657 22.7422 14.7188 22.7422H18.8203V23.5625C18.8203 24.0155 19.1876 24.3823 19.6406 24.3823H20.55C19.3905 26.6593 17.2611 29.3594 14.7188 29.3594ZM27.0781 22.7422C26.2701 22.7422 21.2868 22.7422 20.4609 22.7422V21.1016H27.0781V22.7422Z'
      fill='black'
    />
  </svg>
);
