import { createEffect, createEvent, createStore, restore } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { AxiosError, AxiosResponse } from 'axios';
import { TMessage } from './types';
import { useStore } from 'effector-react';

export const getMessagesFx = createEffect<
  { messageCount?: number; lastMessageId?: number },
  AxiosResponse<{
    data: TMessage[];
  }>,
  AxiosError<{ error: string }>
>(({ messageCount = 0, lastMessageId = 0 }) =>
  apiClient.get(
    Endpoint.CHAT_GET_MESSAGES + `/${messageCount}/${lastMessageId}`
  )
);

export const setMessages = createEvent<TMessage[]>();
export const setHasNewMessages = createEvent<boolean>();
export const setShowChat = createEvent<boolean>();

export const $messages = restore(setMessages, []);
export const $hasNewMessages = restore(setHasNewMessages, false);
export const $showChat = createStore(false);

export const useChatMessages = (): TMessage[] => useStore($messages);
export const useShowChat = (): boolean => useStore($showChat);
export const useHasNewMessages = (): boolean => useStore($hasNewMessages);

$showChat.on(setShowChat, (_, payload) => payload);
