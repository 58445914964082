import { getOrderFx, setOrder } from 'models/order/model';
import { OrderDetailQueryProps } from './types';
import { useQuery } from 'react-query';

export const useOrderDetailQuery: OrderDetailQueryProps = id =>
  useQuery('orderDetail', () => getOrderFx(id), {
    onSuccess: res => {
      setOrder(res.data);
    },
    refetchOnWindowFocus: false,
  });
