import { Modal } from 'ui/Modal';
import React from 'react';
import { Icon, Title, Text, Wrapper, Actions } from './styles';
import { Button } from 'ui/Button';
import { useDeleteProfile } from 'queries/account/deleteProfile/useDeleteProfile';
import { PopupAttentionIcon } from 'icons/PopupAttentionIcon';

interface IProps {
  closeModal: () => void;
}

export const ConfirmDeleteAccountModal: React.FC<IProps> = ({ closeModal }) => {
  const handleClose = () => closeModal();
  const mutation = useDeleteProfile();
  const handleDeleteAccount = () => {
    mutation.mutate();
  };

  return (
    <Modal handleClose={closeModal}>
      <Wrapper>
        <Icon>{PopupAttentionIcon}</Icon>
        <Title>Вы уверены, что хотите удалить аккаунт?</Title>
        <Text>Для восстановления обратитесь к администратору ресурса</Text>
        <Actions>
          <Button onClick={handleClose} buttonType='secondary'>
            Отменить
          </Button>
          <Button onClick={handleDeleteAccount} buttonType='primary'>
            Да, удалить
          </Button>
        </Actions>
      </Wrapper>
    </Modal>
  );
};
