import React from 'react';
import { RatingStars } from 'ui/RatingStars';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import {
  ItemWrap,
  Photo,
  PhotoBlank,
  PhotoBlankText,
  Inner,
  Name,
  Date as StyledDate,
  Text,
} from './styles';
import { TReply } from 'models/courses/types';
import { formatUserMiniatureName } from 'utilities/formatUserMiniatureName';
import { baseUrl } from 'api/apiClient';

export const ReviewItem: React.FC<Omit<TReply, 'id'>> = ({
  date_send,
  user_avatar,
  message,
  user_name,
  mark,
  ...rest
}) => {
  const day = format(new Date(date_send), 'd'),
    month = format(new Date(date_send), 'MMMM', { locale: ru }),
    year = format(new Date(date_send), 'Y');

  const [firstName, lastName] = user_name.split(' ');

  return (
    <ItemWrap {...rest}>
      {user_avatar ? (
        <Photo src={baseUrl + user_avatar} alt={'Фото пользователя'} />
      ) : (
        <PhotoBlank>
          <PhotoBlankText>
            {formatUserMiniatureName(firstName, lastName)}
          </PhotoBlankText>
        </PhotoBlank>
      )}

      <Inner>
        <RatingStars rating={mark} size={'small'} />
        <Name>{user_name ? user_name : 'Неизвестный автор'}</Name>
        <StyledDate>{`${day}
          ${month}
          ${year}`}</StyledDate>
        <Text>{message}</Text>
      </Inner>
    </ItemWrap>
  );
};
