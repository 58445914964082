import styled from 'styled-components';
import { Wrap, Subtitle, Title } from './MiniBanner/styles';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)``;

export const HeaderContentSC = styled.div`
  width: 903px;
  display: flex;
  justify-content: space-between;
  margin-left: -12px;
  ${StyledLink} + ${StyledLink} ${Wrap} {
    margin: 0 0 0 24px;
  }

  & ${StyledLink}:nth-of-type(1) ${Wrap} ${Title} {
    color: ${({ theme }) => theme.colors.thirdColor};
  }
  & ${StyledLink}:nth-of-type(2) ${Wrap} ${Title} {
    color: ${({ theme }) => theme.colors.mainBgColor};
  }
  & ${StyledLink}:nth-of-type(3) ${Wrap} ${Title} {
    color: ${({ theme }) => theme.colors.mainColor};
  }

  & ${StyledLink}:nth-of-type(1) ${Wrap} ${Subtitle} {
    color: ${({ theme }) => theme.colors.secondColor};
  }
  & ${StyledLink}:nth-of-type(2) ${Wrap} ${Subtitle} {
    color: ${({ theme }) => theme.colors.mainBgColor};
  }
  & ${StyledLink}:nth-of-type(3) ${Wrap} ${Subtitle} {
    color: ${({ theme }) => theme.colors.mainColor};
  }
`;
