import styled from 'styled-components';
import { ArrowButton } from 'ui/ArrowButton';
import { Toolbar } from './Toolbar';
import { Preloader } from 'ui/Preloader';
import { Link } from 'react-router-dom';

export const Wrap = styled.div`
  max-width: 1236px;
  padding: 0 12px;
  margin: 0 auto;
  width: 100%;
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.mainBgColor + 80};
  z-index: ${({ theme }) => theme.zIndex.globalOverlay};
`;

export const StyledOverlayPreloader = styled(Preloader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ theme }) => Number(theme.zIndex.suggestionDropdown) + 1};
`;

export const YearHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NextYear = styled(ArrowButton)``;

export const CurrentYear = styled(Link)`
  ${({ theme }) => theme.typography.headline1};
  margin: 0;
  & + ${NextYear} {
    margin-left: 40px;
  }
`;

export const PrevYear = styled(ArrowButton)`
  & + ${CurrentYear} {
    margin-left: 40px;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  margin: 28px 0 0;
  padding: 20px 0;
`;
