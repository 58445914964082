import { useMutation } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';
import { CalculateSDEKDeliveryQuery } from './types';

type SDEKResponseItemType = {
  city: string;
  code: number;
  country: string;
  fias_guid: string;
  kladr_code: string;
  latitude: number;
  longitude: number;
  payment_limit: number;
  region: string;
  region_code: number;
  sub_region: string;
  time_zone: string;
};

export const useCalculateSDEKDeliveryQuery: CalculateSDEKDeliveryQuery = () =>
  useMutation(
    'calculateSdek',
    async ({ postal_code, city, order_id, type }) => {
      // Небольшой костыль для городов, которые не дают нам почтовые индексы, например Казахстанские города
      if (postal_code) {
        const cityRequest = await apiClient
          .post(Endpoint.SEARCH_SDEK_CITY, { postal_code, city })
          .catch(err => {
            throw new Error(err);
          });

        if (!order_id) return cityRequest.data.data[0].code;

        await apiClient
          .post(Endpoint.CALCULATE_SDEK_DELIVERY, {
            order_id,
            type,
            city_to_id: cityRequest.data.data.find(
              (item: SDEKResponseItemType) => item.city === city
            )?.code,
          })
          .catch(err => {
            throw new Error(err);
          });

        return cityRequest.data.data[0].code;
      } else {
        const cityRequest = await apiClient
          .get(Endpoint.SDEK, {
            params: {
              isdek_action: 'getCity',
              city,
            },
          })
          .catch(err => {
            throw new Error(err);
          });

        // TODO: эндпоинт cityRequest не всегда возвращает одинаковую структуру.
        if (!order_id) {
          if (Array.isArray(cityRequest.data.data)) {
            return cityRequest.data.data[0].code;
          } else {
            return cityRequest.data.id;
          }
        }

        await apiClient
          .post(Endpoint.CALCULATE_SDEK_DELIVERY, {
            order_id,
            type,
            city_to_id: cityRequest.data.id,
          })
          .catch(err => {
            throw new Error(err);
          });

        return cityRequest.data.id;
      }
    },
    {
      onError: () => {
        toast.error(
          <ErrorMessage text='К сожалению, сервис не осуществляет доставку в выбранный вами город. Для уточнения возможностей доставки пожалуйста свяжитесь с администратором.' />
        );
      },
    }
  );
