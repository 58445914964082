import React from 'react';
import { LargeLogoIcon } from 'icons/';
import {
  Wrap,
  Title,
  Paragraph,
  List,
  ListItem,
  Banner,
  BannerInner,
  BannerText,
  BannerTitle,
} from './styles';

export const Project: React.FC = props => {
  return (
    <Wrap {...props}>
      <Title>Будь с мужиком!</Title>
      <Paragraph>
        Женщин веками учили быть удобными и&nbsp;послушными для мужчин. Учили
        игнорировать свои желания, ограничивать свое развитие, терпеть
        повышенные требования к&nbsp;себе, взваливать на&nbsp;себя
        ответственность вообще за&nbsp;все.
      </Paragraph>
      <Paragraph>
        Мужчины объявили себя «высшей расой», представителей которой
        на&nbsp;планете якобы недостаток, и&nbsp;поэтому их&nbsp;нужно всячески
        беречь, холить и&nbsp;лелеять. Результат: женщины стали сильными,
        мужчины стали слабыми.
      </Paragraph>
      <Paragraph>
        Проект{' '}
        <a href='https://www.instagram.com/budsmuzhikom'>@budsmuzhikom</a>{' '}
        призван легко и&nbsp;непринужденно поставить все на&nbsp;свои места
        и&nbsp;напомнить каждой женщине ее&nbsp;святую миссию:
      </Paragraph>
      <List>
        <ListItem>Быть счастливой!</ListItem>
        <ListItem>Любить себя!</ListItem>
        <ListItem>Быть с мужиком, а не с мудаком!</ListItem>
      </List>
      <Paragraph>
        Именно к&nbsp;такой женщине автоматически притягивается достойный
        мужчина: сильный, надежный, амбициозный. Умеющий покорять
        и&nbsp;завоевывать.
      </Paragraph>
      <Paragraph>
        Сотни тысяч людей уже прочли наши книги, прошли наши саморазвития
        и&nbsp;тренинги, надежно и&nbsp;безвозвратно изменив свою жизнь
        к&nbsp;лучшему.
      </Paragraph>
      <Banner>
        <div>{LargeLogoIcon}</div>
        <BannerInner>
          <BannerText>Девиз проекта:</BannerText>
          <BannerTitle>Успех неизбежен!</BannerTitle>
        </BannerInner>
      </Banner>
    </Wrap>
  );
};
