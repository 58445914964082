import { createEffect, createEvent, createStore } from 'effector';
import { AxiosError, AxiosResponse } from 'axios';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import {
  CartItem,
  CartItemRequest,
  CartResponse,
  CartStore,
  UpdateCartItemRequest,
} from './types';
import { regExpOnlyDigits } from 'constants/regExps';

// Сторы
export const $cart = createStore<CartStore | null>(null);

// Эффекты
//TODO: разобраться с типами по окончанию фичи
export const addCartItemFx = createEffect<
  CartItemRequest,
  AxiosResponse<{ data: CartResponse }>,
  AxiosError<{ error: string }>
>(({ id, count }) => {
  return apiClient.post(Endpoint.ADD_ITEM_TO_CART, {
    product_id: Number(id),
    quantity: Number(count),
  });
});
export const updateCartItemFx = createEffect<
  UpdateCartItemRequest,
  AxiosResponse<{ data: CartResponse }>,
  AxiosError<{ error: string }>
>(data => {
  return apiClient.post(Endpoint.UPDATE_ITEM_TO_CART, {
    ...data,
    product_id: Number(data.product_id),
  });
});

export const deleteCartItemFx = createEffect<
  string,
  AxiosResponse<{ data: CartResponse }>,
  AxiosError<{ error: string }>
>(id => {
  return apiClient.delete(Endpoint.DELETE_ITEM_FROM_CART + id);
});

export const getCartFx = createEffect<
  void,
  AxiosResponse<CartResponse>,
  AxiosError<{ error: string }>
>(() => {
  return apiClient.get(Endpoint.GET_CART);
});

// События
export const setCart = createEvent<CartResponse>();

// Подписки
// TODO: Тут надо разобраться баг ли это или нет.
//  Типы Event<CartResponse> и Unit<CartResponse> не совместимы, хотя Event extends Unit.
$cart.on(setCart, (state, payload) => {
  //Преобразуем в массив товаров из объекта товаров и отправляем в стор
  // Отсеиваем через регулярку ключи, которые не являются id'шниками товара
  const data = Object.keys(payload)
    .filter(key => regExpOnlyDigits.test(key))
    .map(key => {
      // @ts-expect-error: TODO
      const item: CartItem = { ...payload[key], id: key };
      return item;
    });

  return {
    base_price: payload.base_price,
    price: payload.price,
    data,
  };
});
