import { useQuery } from 'react-query';
import { CoachingSchoolQuery } from './types';
import {
  getCoachingSchoolFx,
  setCoachingSchool,
  setTestimonials,
  useCoachingActiveSchool,
} from 'models/inner-insight/model';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import { TReply } from 'models/inner-insight/types';

export const useGetCoachingSchoolQuery: CoachingSchoolQuery = (id, purchased) =>
  useQuery('school', () => getCoachingSchoolFx({ id, purchased }), {
    onSuccess: res => {
      const { testimonials, ...school } = res.data.data;
      setCoachingSchool(school);

      if (testimonials) {
        const rating =
          (testimonials as TReply[]).reduce(
            (prev, curr) => prev + curr.mark,
            0
          ) / testimonials.length;
        setTestimonials({
          rating: rating,
          voices: testimonials?.length,
          replies: testimonials,
        });
      }
    },
    onError: res =>
      toast.error(<ErrorMessage text={res.response?.data.error} />),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!useCoachingActiveSchool(),
  });
