import React, { useEffect, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { useConfirmCodeQuery } from 'queries/useConfirmCodeQuery';
import { useRegistrationQuery } from 'queries/useRegistrationQuery';
import {
  $verificationCode,
  clearStore,
  setRegistrationFields,
  setVerificationCode,
  useRegistrationData,
} from 'models/auth/model';
import { Overlay, Title, Wrap } from './styles';
import { LogoIcon } from 'icons';
import {
  PasswordFields,
  RegistrationFields,
  SmsCodeFields,
} from 'models/auth/types';
import { RegistrationProps, RegistrationStepKeys } from './types';
import { useConfirmRegistrationQuery } from 'queries/useConfirmRegistrationQuery';
import { Forms } from './Forms';
import { Buttons } from './Buttons';
import { AxiosError } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';
import { formatValidationErrors } from 'utilities/formatValidationErrors';
import {
  SetSubmitCallbackType,
  SubmitCallbackType,
} from 'interfaces/externalSubmitTypes';

export const Registration: React.FC<RegistrationProps> = ({
  goBack,
  handleSuccess,
  closeModal,
}) => {
  const [step, setStep] = useState<RegistrationStepKeys>('registration');
  const [checked, setChecked] = useState(false);
  const values = useRegistrationData();
  const verification_code = useStore($verificationCode);

  // Функции которые вызываются в onSuccess в хуках сетевых запросов
  function handleConfirmCodeSuccessSubmit() {
    setStep('password');
  }
  function handleConfirmRegistrationSubmit() {
    handleSuccess();
  }
  function handleRegistrationSuccessSubmit(
    values: RegistrationFields,
    verification_code: string
  ) {
    if (values) setRegistrationFields(values);
    if (verification_code) setVerificationCode(verification_code);
    setStep('code');
  }

  // Все хуки запросов каждого из шагов процесса регистрации
  const confirmCodeMutation = useConfirmCodeQuery(
    handleConfirmCodeSuccessSubmit
  );
  const RegistrationMutation = useRegistrationQuery(
    handleRegistrationSuccessSubmit
  );
  const ConfirmRegistrationMutation = useConfirmRegistrationQuery(
    handleConfirmRegistrationSubmit
  );

  // Проверка состояния запроса для отображения спинера
  const isFetching =
    confirmCodeMutation.isLoading ||
    RegistrationMutation.isLoading ||
    ConfirmRegistrationMutation.isLoading;

  // Колбэки на onSubmit в каждой из форм
  const onSubmitConfirmCodeForm = async (values: SmsCodeFields) =>
    await new Promise((resolve, reject) =>
      confirmCodeMutation.mutate(
        {
          ...values,
          verification_code,
        },
        {
          onError: reject,
          onSuccess: resolve,
        }
      )
    ).catch((err: AxiosError<{ error: ServerValidationError }>) => {
      return formatValidationErrors(err.response?.data.error);
    });

  const onSubmitRegistrationForm = async (values: RegistrationFields) =>
    await new Promise((resolve, reject) =>
      RegistrationMutation.mutate(values, {
        onError: reject,
        onSuccess: resolve,
      })
    ).catch((err: AxiosError<{ error: ServerValidationError }>) => {
      return formatValidationErrors(err.response?.data.error);
    });

  const onSubmitConfirmRegistrationForm = async (values: PasswordFields) =>
    await new Promise((resolve, reject) =>
      ConfirmRegistrationMutation.mutate(
        {
          ...values,
          verification_code: verification_code,
          referal: '',
        },
        {
          onError: reject,
          onSuccess: resolve,
        }
      )
    ).catch((err: AxiosError<{ error: ServerValidationError }>) => {
      return formatValidationErrors(err.response?.data.error);
    });

  // Функция возвращающая нам нужным заголовок
  const renderTitle = () => {
    switch (step) {
      case 'registration': {
        return 'Регистрация';
      }
      case 'code': {
        return 'Подтвердите телефон';
      }
      case 'password': {
        return 'Придумайте пароль';
      }
    }
  };

  // Вспомогательные переменная и функция для внешней отправки форм
  const buttonHandler = useRef<SubmitCallbackType>();
  const setButtonHandler: SetSubmitCallbackType = func => {
    buttonHandler.current = func;
  };

  // Приводим в чистое состояние сторы при демонтаже компонента
  useEffect(() => {
    return function () {
      clearStore();
    };
  }, []);

  return (
    <Wrap>
      {isFetching ? <Overlay /> : null}

      <div>{LogoIcon}</div>
      <Title>{renderTitle()}</Title>
      <Forms
        phone={values.phone || ''}
        step={step}
        setButtonHandler={setButtonHandler}
        onSubmitConfirmCodeForm={onSubmitConfirmCodeForm}
        onSubmitRegistrationForm={onSubmitRegistrationForm}
        onSubmitConfirmRegistrationForm={onSubmitConfirmRegistrationForm}
      />
      <Buttons
        step={step}
        setChecked={() => {
          setChecked(prevState => !prevState);
        }}
        closeModal={closeModal}
        checked={checked}
        isFetching={isFetching}
        buttonHandler={buttonHandler.current}
        goBack={goBack}
      />
    </Wrap>
  );
};
