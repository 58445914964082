import React from 'react';
import { format, formatDistance, isWithinInterval } from 'date-fns';
import { ru } from 'date-fns/locale';
import {
  CatalogItemWrap,
  ImageWrap,
  Image,
  TagsWrap,
  TagItem,
  CatalogItemTitle,
  EventDateWrap,
  EventBeginning,
  EventDate,
  EventDuration,
} from './styles';
import { baseUrl } from 'api/apiClient';
import { NoImage } from 'ui/NoImage';
import { DateFormats } from 'constants/dateFormats';
import { PlanEntityType } from 'models/education/types';
import { ProgressBar } from 'ui/ProgressBar';

export const CoursesPlansItem: React.FC<
  PlanEntityType & { coachSchool?: boolean }
> = ({
  id,
  name,
  status,
  date_start,
  date_end,
  date_event,
  picture,
  progress,
  coachSchool,
  bg_color,
  ...rest
}) => {
  const startDate = date_start
    ? format(new Date(date_start), DateFormats['StartEventDate'], {
        locale: ru,
      })
    : date_event
    ? format(new Date(date_event), DateFormats['StartEventDate'], {
        locale: ru,
      })
    : null;

  const todayDate = new Date();

  const active =
    !!date_start &&
    !!date_end &&
    isWithinInterval(todayDate, {
      start: new Date(date_start),
      end: new Date(date_end),
    });

  const duration =
    !!date_start &&
    !!date_end &&
    formatDistance(new Date(date_start), new Date(date_end), { locale: ru });

  return (
    <CatalogItemWrap key={id} {...rest}>
      <ImageWrap bg_color={bg_color} image={picture}>
        {picture ? <Image src={baseUrl + picture} alt={name} /> : <NoImage />}
      </ImageWrap>
      <TagsWrap>
        {status ? (
          <TagItem status={'progress'} size={'medium'} text={status} />
        ) : null}
        {coachSchool ? (
          <TagItem
            status={'progress'}
            size={'medium'}
            ghost
            text={'Inner Insight'}
          />
        ) : null}
      </TagsWrap>
      <CatalogItemTitle>{name}</CatalogItemTitle>
      <EventDateWrap>
        {startDate ? (
          <EventBeginning>
            Начало: <EventDate>{startDate}</EventDate>
          </EventBeginning>
        ) : null}
        {duration ? <EventDuration>{duration}</EventDuration> : null}
      </EventDateWrap>
      {progress ? <ProgressBar progress={progress} active={active} /> : null}
    </CatalogItemWrap>
  );
};
