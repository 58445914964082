import { AwardIcon } from 'icons';
import BenefitIcon from 'icons/BenefitIcon';
import BonusIcon from 'icons/BonusIcon';
import GiftCardsIcon from 'icons/GiftCardsIcon';
import HomeBoldIcon from 'icons/HomeBoldIcon';
import KeyIcon from 'icons/KeyIcon';
import ShoppingBagIcon from 'icons/ShoppingBagIcon';
import UserIcon from 'icons/UserIcon';
import { CategoriesProps } from 'interfaces/CategoriesProps';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { accountTitles } from '../Account';
import { Header } from './Header';
import { Category, CategoryWrap, AccountActions } from './styles';
import { LeftSidebardKey } from './types';
import { Button } from 'ui/Button';
import TokenService from 'services/TokenService';
import { removeUser } from 'models/auth/model';
import { ConfirmDeleteAccountModal } from './ConfirmDeleteAccountModal';

export const LeftSidebar: React.FC<CategoriesProps<LeftSidebardKey>> = ({
  categoryKey,
  ...rest
}) => {
  const history = useHistory();
  const path = Object.keys(accountTitles) as LeftSidebardKey[];

  const [
    openConfirmDeleteAccountModal,
    setOpenConfirmDeleteAccountModal,
  ] = useState(false);

  const handleCloseModal = () => setOpenConfirmDeleteAccountModal(false);
  const handleOpenModal = () => setOpenConfirmDeleteAccountModal(true);

  const handleExitProfile = () => {
    TokenService.removeToken();
    removeUser();
    history.push('/catalog/courses');
  };

  return (
    <>
      {openConfirmDeleteAccountModal && (
        <ConfirmDeleteAccountModal closeModal={handleCloseModal} />
      )}

      <CategoryWrap {...rest}>
        <Header {...rest} />
        <Link to={`/account/${path[0]}`}>
          <Category isActive={categoryKey === path[0]} id={'1'} icon={UserIcon}>
            Мои данные
          </Category>
        </Link>
        <Link to={`/account/${path[1]}`}>
          <Category
            isActive={categoryKey === path[1]}
            id={'2'}
            icon={ShoppingBagIcon}
          >
            Заказы
          </Category>
        </Link>
        <Link to={`/account/${path[2]}`}>
          <Category
            isActive={categoryKey === path[2]}
            id={'3'}
            icon={AwardIcon}
          >
            Дипломы
          </Category>
        </Link>
        <Link to={`/account/${path[3]}`}>
          <Category
            isActive={categoryKey === path[3]}
            id={'4'}
            // TODO GiftCardsIcon заливается если не активна, такая же проблема в другом разделе "Каталог"
            icon={GiftCardsIcon}
          >
            Подарочные карты
          </Category>
        </Link>
        <Link to={`/account/${path[4]}`}>
          <Category
            isActive={categoryKey === path[4]}
            id={'5'}
            icon={BonusIcon}
          >
            Бонусный счет
          </Category>
        </Link>
        <Link to={`/account/${path[5]}`}>
          <Category
            isActive={categoryKey === path[5]}
            id={'6'}
            icon={BenefitIcon}
          >
            Премия
          </Category>
        </Link>
        <Link to={`/account/${path[6]}`}>
          <Category
            isActive={categoryKey === path[6]}
            id={'7'}
            icon={HomeBoldIcon}
          >
            Адреса
          </Category>
        </Link>
        <Link to={`/account/${path[7]}`}>
          <Category isActive={categoryKey === path[7]} id={'8'} icon={KeyIcon}>
            Пароль
          </Category>
        </Link>
        <AccountActions>
          <Button
            className='hidden'
            buttonType='link'
            onClick={handleExitProfile}
          >
            Выйти
          </Button>
          <Button
            className='hidden'
            buttonType='link'
            onClick={handleOpenModal}
          >
            Удалить аккаунт
          </Button>
        </AccountActions>
      </CategoryWrap>
    </>
  );
};
