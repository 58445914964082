import React from 'react';

export const PopupSuccessIcon = (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='20' cy='20' r='20' fill='#F4FAE9' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.7071 13.2929C29.0976 13.6834 29.0976 14.3166 28.7071 14.7071L17.7071 25.7071C17.3166 26.0976 16.6834 26.0976 16.2929 25.7071L11.2929 20.7071C10.9024 20.3166 10.9024 19.6834 11.2929 19.2929C11.6834 18.9024 12.3166 18.9024 12.7071 19.2929L17 23.5858L27.2929 13.2929C27.6834 12.9024 28.3166 12.9024 28.7071 13.2929Z'
      fill='#92C91F'
    />
  </svg>
);
