import React from 'react';
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input';
import styled from 'styled-components';
import { CodeInputProps } from './types';
import { FieldRenderProps } from 'react-final-form';

const StyledReactCodeInput = styled(ReactCodeInput)`
  display: flex !important;
  input + input {
    margin: 0 0 0 8px;
  }
  & > input {
    outline: none;
    padding: 14px 13px 12px;
    border-radius: 14px;
    border: 1px solid ${props => props.theme.colors.secondBgColor};
    background-color: ${props => props.theme.colors.secondBgColor};
    transition: all 0.3s;
    display: flex;
    align-items: center;
    width: 40px;
    ${({ theme }) => theme.typography.headline3};
    ${({ disabled, theme }) =>
      !disabled &&
      `&:hover {
          transition: all 0.3s;
          border-color: ${theme.colors.secondColor};
        }`}
  }
`;

const Error = styled.p`
  margin: 8px 0 0;
  max-width: 100%;
  color: ${props => props.theme.colors.errorColor};
`;

const Wrap = styled.div<
  CodeInputProps & {
    disabled?: boolean;
  }
>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.disabled &&
    `& > input {
        background-color: ${props.theme.colors.thirdBgColor};
        border-color: ${props.theme.colors.thirdBgColor};
        cursor: not-allowed;
      };
      
      & > ${StyledReactCodeInput} {
        cursor: not-allowed;
      };      
     `};

  & > ${Error} {
    transition: transform 0.3s, opacity 0.3s;
    ${({ error }) => {
      return !error
        ? 'transform: translate(0, -50%); opacity: 0;'
        : 'transform: translate(0, 0); opacity: 1;';
    }}
  }
`;

export const CodeInput: React.FC<
  ReactCodeInputProps & FieldRenderProps<string, HTMLInputElement>
> = ({ input, inputMode, meta, ...rest }) => {
  return (
    <Wrap error={meta.error || meta.submitError}>
      <StyledReactCodeInput
        {...input}
        type={input.type as 'text' | 'number' | 'password' | 'tel'}
        inputMode={inputMode}
        {...rest}
      />
      {meta.error && meta.touched ? <Error>{meta.error}</Error> : null}
      {meta.submitError && meta.touched ? (
        <Error>{meta.submitError}</Error>
      ) : null}
    </Wrap>
  );
};
