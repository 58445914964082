import { loginFx } from 'models/auth/model';
import TokenService from 'services/TokenService';
import { useMutation } from 'react-query';
import { LoginQuery } from './types';

export const useLoginQuery: LoginQuery = (callback?: () => void) =>
  useMutation(values => loginFx(values), {
    onSuccess: response => {
      TokenService.setToken(response.data.token);
      if (callback) callback();
    },
  });
