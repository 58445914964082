import { SmallCatalogItem } from 'components/Catalog/common/SmallCatalogItem';
import React, { useEffect } from 'react';
import { useGoodsCatalogQuery } from 'queries/catalog/useGoodsCatalogQuery';
import { CatalogItem, GiftCard } from 'models/catalog/types';
import { useStore } from 'effector-react';
import { $catalogData } from 'models/catalog';
import {
  $giftCardData,
  clearCatalog,
  clearGiftCards,
} from 'models/catalog/model';
import { StyledPreloader } from '../styles';
import styled from 'styled-components';
import { useGetGiftCardsQuery } from 'queries/catalog/useGetGiftCardsQuery';
import { GiftCardCatalogItem } from '../../common/GiftCardCatalogItem';

const NoItemsText = styled.p`
  ${({ theme }) => theme.typography.headline3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;
export const Goods: React.FC = () => {
  const goodsQuery = useGoodsCatalogQuery();
  const giftCardsQuery = useGetGiftCardsQuery();
  const giftCards: GiftCard[] = useStore($giftCardData);
  const goods: CatalogItem[] = useStore($catalogData);
  const isLoading =
    goodsQuery[0].isLoading ||
    goodsQuery[1].isLoading ||
    giftCardsQuery.isLoading;
  const isFetching =
    goodsQuery[0].isFetching ||
    goodsQuery[1].isFetching ||
    giftCardsQuery.isFetching;
  const isError = goodsQuery[0].isError || goodsQuery[1].isError;
  useEffect(() => {
    return () => {
      clearCatalog();
      clearGiftCards();
    };
  }, []);

  return (
    <>
      {isLoading || isFetching ? (
        <StyledPreloader />
      ) : !giftCards.length && !goods.length ? (
        <NoItemsText>
          {isError ? 'Не удалось загрузить товары' : 'Нет товаров'}
        </NoItemsText>
      ) : (
        <>
          {giftCards?.length
            ? giftCards.map(item => {
                return <GiftCardCatalogItem key={item.id} {...item} />;
              })
            : null}
          {goods.length
            ? goods.map(item => {
                const buttonText = !item.available
                  ? 'Товар недоступен'
                  : 'В корзину';
                return (
                  <SmallCatalogItem
                    goodsType={'goods'}
                    key={item.id}
                    buttonText={buttonText}
                    disabled={!item.available}
                    {...item}
                  />
                );
              })
            : null}
        </>
      )}
    </>
  );
};
