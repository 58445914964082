import React, { useState } from 'react';
import {
  Title,
  HeadInfo,
  Wrapper,
  TextSC,
  ReferalLinkInput,
  InputSC,
  PeriodField,
  SelectPeriodButton,
  RightBannerColumn,
  Content,
} from './styled';
import CopyIcon from 'icons/CopyIcon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ChevronDownSmallIcon } from 'icons/ChevronDownSmallIcon';
import { fakeData } from './fakeData';
import BonusItem from './BonusItem';
import EmptyBonus from './EmptyBonus';
import BacsImage from 'images/referral_link_bacs.png';
import { DateRangePicker } from 'components/common/DateRangePicker';
import { RangeWithKey } from 'react-date-range';
import { MiniBanner } from './MiniBanner';

// TODO? сделать нормальное форматирование даты
function getDate(date: RangeWithKey) {
  const start = date.startDate;
  const end = date.endDate;
  if (!start || !end) return '';

  return `${start.getDate()}.${
    start.getMonth() + 1
  }.${start.getFullYear()} - ${end.getDate()}.${
    end.getMonth() + 1
  }.${end.getFullYear()}`;
}

const Premium: React.FC = () => {
  const [openDatapicker, setOpenDatapicker] = useState(false);
  // Раздел с компонентом Premium еще не делался,
  // но тут есть такой же дата пикер как в бонусном счете,
  // возможно нужно будет сделать этот пропс не обязательным
  const [, setIsEmptyPeriod] = useState(true);
  const [dateRange, setDateRange] = useState<RangeWithKey[]>([
    {
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    },
  ]);

  return (
    <Wrapper>
      <HeadInfo>
        <Title>{'Премия'}</Title>
      </HeadInfo>
      <Content>
        {fakeData.length ? (
          <div>
            <Title isSubTitle>{'Код рекомендации'}</Title>
            <TextSC>
              Делитесь этим промо-кодом со своими знакомыми, учениками,
              подписчиками. Они получат скидку при оформлении покупки наших
              продуктов, а ваш счет пополнится на 500 вжухов.
            </TextSC>
            <ReferalLinkInput>
              <span>{'35jo89fp101'}</span>
              <CopyToClipboard text={'35jo89fp101'}>{CopyIcon}</CopyToClipboard>
            </ReferalLinkInput>
            <Title isSubTitle>{'История операций'}</Title>
            <PeriodField>
              <SelectPeriodButton
                iconAlign={'right'}
                icon={ChevronDownSmallIcon}
                buttonType='link'
                onClick={() => setOpenDatapicker(!openDatapicker)}
              >
                Период
              </SelectPeriodButton>
              <InputSC
                name='period'
                type='text'
                value={`${getDate(dateRange[0])}`}
                readOnly
              />
            </PeriodField>
            {openDatapicker && (
              <DateRangePicker
                dateRange={dateRange}
                setDateRange={setDateRange}
                closePicker={setOpenDatapicker}
                setIsEmptyPeriod={setIsEmptyPeriod}
              />
            )}

            <div>
              {fakeData.map(item => (
                <BonusItem key={item.id} data={item} />
              ))}
            </div>
          </div>
        ) : (
          <EmptyBonus />
        )}
        <RightBannerColumn>
          <MiniBanner
            image={BacsImage}
            title='Премия'
            subtitle={'500 баксов'}
          />
        </RightBannerColumn>
      </Content>
    </Wrapper>
  );
};

export default Premium;
