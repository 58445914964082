import { useQuery, UseQueryResult } from 'react-query';
import {
  getAllNotificationsFx,
  setNotifications,
} from 'models/notifications/model';
import {
  GetAllNotificationRequestType,
  NotificationItemType,
} from 'models/notifications/types';
import { AxiosError, AxiosResponse } from 'axios';

export type GetAllNotificationsQueryType = (
  data: GetAllNotificationRequestType
) => UseQueryResult<
  AxiosResponse<{ data: NotificationItemType[] }>,
  AxiosError<{ error: string }>
>;

export const useGetAllNotificationsQuery: GetAllNotificationsQueryType = data =>
  useQuery('getAllNotifications', () => getAllNotificationsFx(data), {
    onSuccess: res => {
      setNotifications(res.data.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
