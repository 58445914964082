import styled from 'styled-components';

export const DateWrap = styled.div`
  margin-right: 40px;
  padding: 0 0 0 9px;
`;

export const Image = styled.img`
  border-radius: 14px;
  margin-left: 40px;
  margin-top: 22px;
  width: 140px;
  height: 96px;
  object-fit: cover;
`;

export const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 28px;
  width: 464px;
`;

export const Title = styled.span`
  ${({ theme }) => theme.typography.headline2};
`;

export const Address = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin-top: 50px;
`;

export const ItemWrap = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  padding: 40px 0;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  align-self: flex-start;
  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  }

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.actionColor};
  }
`;
