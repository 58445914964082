import React, { useState } from 'react';
import {
  ButtonSC,
  DiplomDate,
  Name,
  Preview,
  PreviewWrapper,
  Wrap,
} from './styled';
import { StatusDoneIcon } from 'icons';
import { DiplomaModal } from '../DiplomModal';
import { baseUrl } from 'api/apiClient';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateFormats } from 'constants/dateFormats';
import { TDiploma } from 'queries/account/diplomas/types';
import { Link } from 'react-router-dom';
import { Path } from 'constants/paths';
import { NoImage } from 'ui/NoImage';
import { scrollToTop } from 'utilities/scrollToTop';

interface IProps {
  data: TDiploma;
}

export const DiplomaItem: React.FC<IProps> = ({ data }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const closeModal = () => setIsOpenModal(false);
  const openModal = () => setIsOpenModal(true);
  return (
    <Wrap>
      {isOpenModal && <DiplomaModal {...data} closeModal={closeModal} />}
      <PreviewWrapper onClick={openModal} received={data.received}>
        {data.picture ? (
          <Preview src={baseUrl + data.picture} alt={data.name} />
        ) : (
          <NoImage />
        )}
      </PreviewWrapper>
      <Name received={data.received}>{data.name}</Name>
      {data.received && (
        <DiplomDate>
          {StatusDoneIcon}
          {format(new Date(data.date_received), DateFormats['StartEventDate'], {
            locale: ru,
          })}
        </DiplomDate>
      )}
      {!data.received && (
        <Link to={`${Path.course}/${data.type_id}`} onClick={scrollToTop}>
          <ButtonSC buttonType='primary'>Получить</ButtonSC>
        </Link>
      )}
    </Wrap>
  );
};
