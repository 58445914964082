import styled from 'styled-components';

export const Top = styled.div`
  display: flex;
  margin: 20px 0 40px;
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.colors.thirdBgColor};
  padding: 0 0 1px 0;
`;

export const BadgeText = styled.p`
  margin: 0;
`;

export const Badge = styled.div<{ selected: boolean }>`
  & + & {
    margin: 0 0 0 40px;
  }
  & ${BadgeText} {
    ${({ theme }) => theme.typography.menuText};
    color: ${({ theme, selected }) =>
      selected ? theme.colors.mainColor : theme.colors.thirdTextAndIconColor};
  }
  cursor: pointer;
  ${({ selected, theme }) =>
    selected ? `box-shadow: 0 2px 0 0 ${theme.colors.actionColor}` : null};
  padding: 20px 0 19px;
  transition: box-shadow 0.3s, color 0.3s;
`;

export const Bottom = styled.div`
  width: calc(((100% - (24px * 6)) / 9) * 7 + (9 * 12px));
`;

export const Text = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.bodyText2}
`;
