import styled from 'styled-components';
import { Button } from 'ui/Button';

export const Wrapper = styled.div`
  width: 500px;

  border: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-radius: 14px;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 12px;
  display: flex;
  align-items: start;
`;

export const Title = styled.span`
  ${({ theme }) => theme.typography.headline3};
  margin-bottom: 12px;
`;

export const Icon = styled.div`
  padding: 9px;
  border-radius: 14px;
  font-size: 0;
  background: ${({ theme }) => theme.colors.secondBgColor};
  & > svg {
    position: relative;
    transition: all 0.3s;
    width: 22px;
    height: 22px;
    z-index: 2;
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

export const Descriptions = styled.div`
  margin-left: 41px;
  margin-bottom: 24px;
`;

export const TextSC = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const CloseIcon = styled(Button)<{ customStyle?: string }>`
  margin-right: 24px;
  margin-left: auto;
  ${({ customStyle }) => (customStyle ? customStyle : '')}
`;
