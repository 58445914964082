import { setDeliveryFx } from 'models/order/model';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import { SetDeliveryQuery } from './types';

export const useSetDeliveryQuery: SetDeliveryQuery = callback =>
  useMutation('orderDetail', values => setDeliveryFx(values), {
    onSuccess: () => {
      callback?.();
    },
    onError: res => {
      if (res.response?.data.error) {
        toast.error(<ErrorMessage text={res.response.data.error} />);
      }
    },
  });
