import { useQuery, UseQueryResult } from 'react-query';
import {
  getNewNotificationsFx,
  setHasNewNotifications,
} from 'models/notifications/model';
import { NotificationItemType } from 'models/notifications/types';
import { AxiosError, AxiosResponse } from 'axios';

export type GetAllNotificationsQueryType = () => UseQueryResult<
  AxiosResponse<{ data: NotificationItemType[] }>,
  AxiosError<{ error: string }>
>;

export const useGetNewNotificationsQuery: GetAllNotificationsQueryType = () =>
  useQuery('getNewNotifications', () => getNewNotificationsFx(), {
    onSuccess: res => {
      setHasNewNotifications(res.data.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
    // повтор запроса каждый 10 минут. Проверка на наличие новые уведомлений
    refetchInterval: 60 * 10 * 1000,
  });
