import React, { useEffect, useState } from 'react';
import { ResendCodeButton, Wrap, StyledPreloader } from './styles';
import { formatTimeToString } from 'utilities/formatTimeToString';
import { useStore } from 'effector-react';
import {
  $phone,
  setVerificationCode,
  useRegistrationData,
} from 'models/auth/model';
import { useRestorePhoneQuery } from 'queries/useRestorePhoneQuery';
import { useRegistrationQuery } from 'queries/useRegistrationQuery';

export const Countdown: React.FC<{
  type: 'registration' | 'restore';
}> = ({ type, ...rest }) => {
  const [counter, setCounter] = useState(60000);
  const phone = useStore($phone);
  const values = useRegistrationData();
  const restorePhoneMutation = useRestorePhoneQuery();
  const RegistrationMutation = useRegistrationQuery();

  useEffect(() => {
    let timeout: number | undefined;
    if (counter > 0) {
      timeout = window.setTimeout(() => setCounter(counter - 1000), 1000);
    }
    return () => {
      if (timeout) window.clearTimeout(timeout);
    };
  }, [counter]);

  const handleClick = () => {
    if (type === 'registration') {
      RegistrationMutation.mutate(values, {
        onSuccess: res => {
          setVerificationCode(res.data.verification_code);
          setCounter(60000);
        },
      });
    } else if (type === 'restore') {
      restorePhoneMutation.mutate(
        { phone },
        {
          onSuccess: res => {
            setVerificationCode(res.data.verification_code);
            setCounter(60000);
          },
        }
      );
    }
  };

  if (restorePhoneMutation.isLoading) {
    return <StyledPreloader />;
  }

  return counter ? (
    <Wrap {...rest}>
      Получить новый код можно через {formatTimeToString(counter)}
    </Wrap>
  ) : (
    <ResendCodeButton size={'large'} buttonType={'link'} onClick={handleClick}>
      Получить новый код
    </ResendCodeButton>
  );
};
