import { RecipientFields } from '../common/RecipientFields';
import React from 'react';
import { Divider } from './styles';
import { OfficeMap } from './OfficeMap';

export const OfficeFormFields: React.FC = () => {
  return (
    <>
      <OfficeMap />
      <Divider />
      <RecipientFields />
    </>
  );
};
