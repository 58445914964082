import React, { useState } from 'react';
import styled from 'styled-components';
import { EyeOnIcon, EyeOffIcon } from 'icons';
import { InputPasswordProps } from './types';

const InputLabel = styled.label<
  Pick<InputPasswordProps, 'direction' | 'alignLabel' | 'labelWidth'>
>`
  ${props => props.theme.typography.bodyText2};
  letter-spacing: 0;
  flex-shrink: 0;
  text-align: ${props => props.alignLabel};
  margin-bottom: 8px;
  color: ${props => props.theme.colors.secondTextColor};
  align-self: flex-start;
  ${({ direction }) => (direction === 'horizontal' ? 'padding: 13px 0;' : null)}
  ${({ labelWidth }) => (labelWidth ? `width: ${labelWidth}px` : null)};
`;

const Error = styled.p<
  Pick<InputPasswordProps, 'inputWidth'> & { error?: string }
>`
  margin: 8px 0 0;
  max-width: 100%;
  color: ${props => props.theme.colors.errorColor};
  text-align: left;
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth}px` : null)};
`;

const InputInner = styled.div<
  Pick<InputPasswordProps, 'fullWidth' | 'inputWidth' | 'error'> & {
    disabled?: boolean;
    isFocused: boolean;
  }
>`
  padding: 12px 14px;
  border-radius: 14px;
  border: 1px solid ${props => props.theme.colors.secondBgColor};
  background-color: ${props => props.theme.colors.secondBgColor};
  transition: all 0.3s;
  display: flex;
  align-items: center;
  ${({ error, theme }) =>
    error ? `border-color: ${theme.colors.errorColor}` : null}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth}px` : null)};
  ${({ isFocused, theme }) =>
    isFocused &&
    `transition: all 0.3s;border-color: ${theme.colors.secondColor};`}
  ${({ disabled, theme }) =>
    !disabled &&
    `&:hover {
          transition: all 0.3s;
          border-color: ${theme.colors.secondColor};
        }`}
`;

const Input = styled.input<{ disabled?: boolean }>`
  outline: none;
  z-index: 2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
  background: none;
  margin: 0;
  width: 100%;
  padding: 0;
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
  &:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px ${({ theme }) => theme.colors.secondBgColor}
      inset;
  }
  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.disabledColor
        : theme.colors.thirdTextAndIconColor};
  }
`;

const InputHideIcon = styled.div`
  font-size: 0;
  margin-left: 8px;
`;

const InputWrap = styled.div<
  Pick<InputPasswordProps, 'direction'> & {
    disabled?: boolean;
    error?: string;
  }
>`
  display: inline-flex;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'row'};
  ${({ direction }) =>
    direction !== 'vertical' ? 'align-items: center;' : null}
  ${({ direction }) =>
    direction !== 'vertical' ? `& > ${InputLabel} {margin: 0 16px 0 0;}` : null}

  ${props =>
    props.disabled &&
    `& > ${InputInner} {
        background-color: ${props.theme.colors.thirdBgColor};
        border-color: ${props.theme.colors.thirdBgColor};
        cursor: not-allowed;
      };
      
      & > ${Input} {
        cursor: not-allowed;
      }      
      & > ${InputLabel} {
        color: ${props.theme.colors.disabledColor};
      };`};

  & > ${Error} {
    transition: transform 0.3s, opacity 0.3s;
    ${({ error }) => {
      return !error
        ? 'transform: translate(0, -50%); opacity: 0;'
        : 'transform: translate(0, 0); opacity: 1;';
    }}
  }

  & > ${InputInner} {
    ${({ error, theme }) => error && `border-color: ${theme.colors.errorColor}`}
  }
`;
const InputPassword: React.FC<InputPasswordProps> = ({
  alignLabel = 'left',
  label,
  disabled,
  direction = 'horizontal',
  labelWidth,
  inputWidth,
  className,
  fullWidth,
  input,
  meta,
  ...rest
}) => {
  const [isVisible, setVisible] = useState(false);
  return (
    <InputWrap
      error={meta.error || meta.submitError}
      disabled={disabled}
      direction={direction}
      className={className}
    >
      {label ? (
        <InputLabel
          direction={direction}
          alignLabel={alignLabel}
          labelWidth={labelWidth}
        >
          {label}
        </InputLabel>
      ) : null}
      <div>
        <InputInner
          isFocused={meta.active}
          fullWidth={fullWidth}
          inputWidth={inputWidth}
          error={meta.error || meta.submitError}
        >
          <Input
            type={isVisible ? 'text' : 'password'}
            disabled={disabled}
            {...input}
            {...rest}
          />
          <InputHideIcon
            onClick={() => {
              if (!disabled) setVisible(!isVisible);
            }}
          >
            {!isVisible ? EyeOnIcon : EyeOffIcon}
          </InputHideIcon>
        </InputInner>
        {meta.error && meta.touched ? (
          <Error inputWidth={inputWidth}>{meta.error}</Error>
        ) : null}
        {meta.submitError && meta.touched ? (
          <Error inputWidth={inputWidth}>{meta.submitError}</Error>
        ) : null}
      </div>
    </InputWrap>
  );
};

export default InputPassword;
