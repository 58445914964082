import { useAddresses } from 'models/addresses/model';
import { ChangeEvent } from 'react';
import { Field, useForm } from 'react-final-form';
import {
  List,
  Option,
  Divider,
  Inner,
  OptionRadio,
  OptionTitle,
  OptionText,
} from './styles';

export const AddressOptions: React.FC = () => {
  const addresses = useAddresses();
  const form = useForm('DeliveryForm');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.split(',');
    form.change('city_code', value[value.length - 1]);
    form.change('address', value.slice(0, value.length - 2));
  };

  return (
    <List>
      {addresses.map((item, index) =>
        item ? (
          <div key={item.id}>
            <Option>
              <Field
                // id={item.id}
                name='address'
                radioSize='large'
                value={`${item.address}, ${item.city_code}`}
                type='radio'
                onChange={handleChange}
                component={OptionRadio}
              />
              <Inner>
                <OptionTitle>{item.name}</OptionTitle>
                <OptionText>{item.address}</OptionText>
              </Inner>
            </Option>
            {addresses.length === index + 1 ? null : <Divider />}
          </div>
        ) : null
      )}
    </List>
  );
};
