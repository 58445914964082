import React from 'react';
import { Title, Wrap } from './styles';
import { CDEKMap } from '../../common/CDEKMap/CDEKMap';

export const OfficeMap: React.FC = props => {
  return (
    <Wrap {...props}>
      <Title>Адрес пункта выдачи</Title>
      <CDEKMap />
    </Wrap>
  );
};
