import { useMutation } from 'react-query';
import { registerFx } from 'models/auth/model';
import { RegistrationQueryProps } from './types';

export const useRegistrationQuery: RegistrationQueryProps = callback =>
  useMutation(val => registerFx(val), {
    onSuccess: (response, values) => {
      if (callback) callback(values, response.data.verification_code);
    },
  });
