import React from 'react';
import {
  Wrap,
  Title,
  StyledForm,
  Login,
  Password,
  LoginButton,
  RestorePasswordButton,
  RegistrationMessage,
  RegistrationButton,
  Overlay,
  StyledPreloader,
} from './styles';
import { LogoIcon } from 'icons';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { LoginValuesType } from 'models/auth/types';
import { useHistory } from 'react-router-dom';
import { AuthorisationProps } from './types';
import { useLoginQuery } from 'queries/auth/useLoginQuery';
import { useUserProfileQuery } from 'queries/auth/useUserProfileQuery';
import { formatValidationErrors } from 'utilities/formatValidationErrors';
import { AxiosError } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';

export const Authorisation: React.FC<AuthorisationProps> = ({
  close,
  setStep,
}) => {
  const history = useHistory();
  const userProfileQuery = useUserProfileQuery();
  const handleLoginSuccess = () => {
    close();
    userProfileQuery.refetch();
    history.push('/catalog/courses');
    location.reload();
  };
  const { isLoading, mutate } = useLoginQuery(handleLoginSuccess);
  const onSubmit = async (values: LoginValuesType) => {
    return await new Promise((resolve, reject) =>
      mutate(values, {
        onError: reject,
        onSuccess: resolve,
      })
    ).catch((err: AxiosError<{ error: ServerValidationError }>) => {
      return formatValidationErrors(err.response?.data.error);
    });
  };

  return (
    <Wrap>
      {isLoading ? <Overlay /> : null}

      <div>{LogoIcon}</div>
      <Title>Авторизация</Title>
      <Form
        onSubmit={onSubmit}
        render={(props: FormRenderProps<LoginValuesType>) => {
          return (
            <StyledForm onSubmit={props.handleSubmit}>
              <Field
                labelWidth={144}
                inputWidth={340}
                label={'Телефон'}
                name={'login'}
                direction={'vertical'}
                alignLabel={'left'}
                placeholder={'Введите телефон'}
                component={Login}
              />
              <Field
                labelWidth={144}
                inputWidth={340}
                label={'Пароль'}
                name={'password'}
                direction={'vertical'}
                alignLabel={'left'}
                placeholder={'Введите пароль'}
                component={Password}
              />
              {isLoading ? (
                <StyledPreloader />
              ) : (
                <LoginButton type={'submit'}>Войти</LoginButton>
              )}
              <RestorePasswordButton
                buttonType={'link'}
                onClick={() => {
                  setStep('restore');
                }}
              >
                Забыли пароль?
              </RestorePasswordButton>
              <RegistrationMessage>
                Вы еще не зарегистрированы?
              </RegistrationMessage>
              <RegistrationButton
                buttonType={'link'}
                onClick={() => setStep('registration')}
              >
                Зарегистрироваться
              </RegistrationButton>
            </StyledForm>
          );
        }}
      />
    </Wrap>
  );
};
