import React, { useMemo } from 'react';
import { MapWrap } from './styles';
import { Field, useForm } from 'react-final-form';
import { Item } from '../RecipientFields/styles';
import { useSDEKWidget } from 'hooks/useSDEKWidget';
import { toast } from 'react-toastify';
import { SuccessMessage } from 'ui/SuccessMessage';
import { baseUrl } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';

export const CDEKMap: React.FC = () => {
  const form = useForm('OfficeForm');
  const options = useMemo(
    () => ({
      defaultCity: 'Москва', //какой город отображается по умолчанию
      country: 'RU', // можно выбрать страну, для которой отображать список ПВЗ
      link: 'SDEKWidget', // id элемента страницы, в который будет вписан виджет
      path: 'https://widget.cdek.ru/widget/scripts/', //директория с библиотеками
      servicepath: `${baseUrl}${Endpoint.SDEK}`,
      templatepath: '/templates/index.json',
      hidedelt: true, //скрыл варианты дост авки
      hidedress: true, //скрыл выбор мест с возможностью примерки
      onChoose: (wat: unknown) => {
        // @ts-expect-error СДЭК не предоставляет интерфейсов
        form.change('pvz_code', wat.id);
        // @ts-expect-error СДЭК не предоставляет интерфейсов
        form.change('address', wat.PVZ.Address);
        // @ts-expect-error СДЭК не предоставляет интерфейсов
        form.change('city_code', wat.PVZ.CityCode);
        toast.success(
          <SuccessMessage // @ts-expect-error СДЭК не предоставляет интерфейсов
            text={'Успешно выбран пункты выдачи ' + wat.PVZ.Address}
          />
        );
      },
    }),
    [form]
  );

  useSDEKWidget(options);

  return (
    <>
      <MapWrap id='SDEKWidget' />
      {/*скрытое поле для сохранение выбранного id офиса выдачи заказов*/}
      <Field name={'pvz_code'} hidden component={Item} />
      <Field name={'city_code'} hidden component={Item} />
      <Field
        name={'address'}
        component={Item}
        fullWidth={true}
        disabled
        label={'Выбранный пункт'}
        placeholder={'Выберите пункт в виджете'}
        direction={'vertical'}
      />
    </>
  );
};

declare global {
  interface Window {
    ISDEKWidjet: unknown;
  }
}
