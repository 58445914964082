import styled from 'styled-components';
import { DirectPayment } from './Payment';

export const Wrap = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-areas:
    'empty1 empty1 empty1 header header header header header header header header header'
    'sidebar sidebar sidebar content content content content content content content empty2 empty2';
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  gap: 24px;
  padding: 0 12px 100px;
  max-width: 1236px;
  width: 100%;
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline1};
  margin: 0 12px 24px;
  grid-area: header;
`;

export const Sidebar = styled.div`
  grid-area: sidebar;
`;

export const Main = styled(DirectPayment)`
  grid-area: content;
`;
