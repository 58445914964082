import React from 'react';
import { Form } from 'react-final-form';
import { Title, StyledLoyaltyPoints } from './styles';
import { LoyaltyProgramProps } from './types';

export const LoyaltyProgram: React.FC<LoyaltyProgramProps> = ({
  orderPrice,
  handleFormSubmit,
  isLoyaltyModalVisible,
  handleClose,
  handleOpen,
}) => {
  return (
    <Form
      onSubmit={handleFormSubmit}
      id={'PaymentForm'}
      render={props => {
        return (
          <form onSubmit={props.handleSubmit}>
            <Title>Программа лояльности</Title>
            <StyledLoyaltyPoints
              orderPrice={orderPrice}
              isLoyaltyModalVisible={isLoyaltyModalVisible}
              handleClose={handleClose}
              handleOpen={handleOpen}
            />
          </form>
        );
      }}
    />
  );
};
