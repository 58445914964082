import styled from 'styled-components';

export const LegendWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 32px;
  }
`;

export const ItemKnot = styled.div<{ color?: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  ${({ color }) => (color ? `background-color: ${color}` : null)};
  margin: 0 8px 0 0;
  font-size: 0;
`;

export const ItemText = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 0;
`;
