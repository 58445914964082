import React from 'react';
import {
  Wrap,
  StyledTitle,
  StyledCategories,
  StyledContent,
  AboutWrap,
} from './styles';
import { RouteComponentProps } from 'react-router-dom';
import { AboutCategoriesKey } from './Categories/types';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

const titles = {
  project: 'О Проекте',
  team: 'Команда',
  docs: 'Правовые документы',
  contacts: 'Контакты',
  consultation: 'Связаться с админом',
};

export const About: React.FC<
  RouteComponentProps<{ key: AboutCategoriesKey }>
> = ({ match }) => {
  const { key } = match.params;
  useScrollToTopOnMount();
  return (
    <AboutWrap>
      <Wrap>
        <StyledTitle>{titles[key]}</StyledTitle>
        <StyledCategories categoryKey={key} />
        <StyledContent />
      </Wrap>
    </AboutWrap>
  );
};
