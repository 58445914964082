import React, { useState } from 'react';
import { $calendarEvents } from 'models/calendar/model';
import { areIntervalsOverlapping, format, isSameDay, set } from 'date-fns';
import { useStore } from 'effector-react';
import { Wrap, ScheduleRow, TimeCell, Time, EventLine } from './styles';
import { formatDayEventInDaySchedule } from '../../helpers/formatDayEventInDaySchedule';
import { CalendarEventType, DaysType } from 'models/calendar/types';
import { endOfDay as dayEnding } from 'constants/timeConstants';
import { EventModal } from '../../MonthPage/MonthCalendar/EventModal';

const hours = Array.from(Array(24).keys());

export const DaySchedule: React.FC<{
  currentDayDate: Date;
}> = ({ currentDayDate, ...rest }) => {
  const calendarData = useStore($calendarEvents);

  const [visible, setVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<{
    id: number;
    index: number;
  } | null>(null);

  const hourArray = hours.map(hour => ({
    time: set(currentDayDate, { hours: hour }),
  }));

  const eventsArray = hourArray.reduce((acc, element, _, array) => {
    const thisTime = element.time;
    const hourBeginning = set(thisTime, {
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    const hourEnding = set(thisTime, {
      minutes: 59,
      seconds: 59,
      milliseconds: 999,
    });

    const events: CalendarEventType[] = calendarData.reduce((events, event) => {
      const start = !!event.date_start && new Date(event.date_start);
      const end = !!event.date_end && new Date(event.date_end);
      const startOfDay = array[0].time,
        endOfDay = array[array.length - 1].time;
      if (
        start &&
        end &&
        areIntervalsOverlapping(
          { start: hourBeginning, end: hourEnding },
          {
            start,
            end: isSameDay(start, end)
              ? set(start, { ...dayEnding, hours: 23 })
              : end,
          }
        )
      ) {
        events.push(
          formatDayEventInDaySchedule(
            event,
            // Текущий час
            hourBeginning,
            // Время начала дня
            startOfDay,
            // Время конца дня
            endOfDay
          )
        );
      }
      return events;
    }, [] as CalendarEventType[]);

    acc.push({ ...element, events });

    return acc;
  }, [] as DaysType);

  const columnCount = Math.max(
    ...(eventsArray?.map(el => el.events?.length || 0) || [])
  );

  const handleOpen = (id: number, index: number) => {
    if (id && (index || index === 0)) {
      setVisible(true);
      setCurrentEvent({ id, index });
    }
  };
  const handleClose = () => setVisible(false);

  return (
    <Wrap {...rest}>
      {visible && !!currentEvent && (
        <EventModal
          data={
            eventsArray[currentEvent.index].events?.find(
              event => event.details.id === currentEvent.id
            )?.details
          }
          handleClose={handleClose}
        />
      )}

      {eventsArray.map((item, index) => (
        <TimeCell key={index}>
          <Time>{format(item.time, 'HH:mm')}</Time>
        </TimeCell>
      ))}
      {eventsArray.map((item, index) => (
        <ScheduleRow key={index}>
          {item.events?.map((event, idx) => (
            <EventLine
              columnCount={columnCount}
              key={idx}
              {...event}
              onClick={() => handleOpen(event.details.id, index)}
            >
              {event.title}
            </EventLine>
          ))}
        </ScheduleRow>
      ))}
    </Wrap>
  );
};
