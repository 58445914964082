import React from 'react';
import styled from 'styled-components';
import { Features, Description, Banner, Faq } from './';
import { useGetVIPChatsQuery } from 'queries/catalog/useGetVIPChatsQuery/useGetVIPChatsQuery';
import { useVIPChatList } from 'models/catalog/model';
import { Preloader } from 'ui/Preloader';

const Wrap = styled.div`
  width: 100%;
  margin: 0 0 40px;
  padding: 0 12px 48px;
  align-self: flex-start;
`;
export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const VipChat: React.FC = props => {
  const { isLoading } = useGetVIPChatsQuery();
  const data = useVIPChatList();
  return isLoading ? (
    <StyledPreloader />
  ) : data.length ? (
    <Wrap {...props}>
      <Banner data={data[0]} />
      <Description />
      <Features />
      <Faq />
    </Wrap>
  ) : (
    <NoData>Нет доступных чатов</NoData>
  );
};
