import React from 'react';

export const StatusDoneIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='8' cy='8' r='8' fill='#92C91F' />
    <g clipPath='url(#clip0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9209 5.23692C12.1845 5.5088 12.1778 5.94292 11.906 6.20655L7.19167 10.778C6.92569 11.0359 6.50294 11.0359 6.23696 10.778L4.0941 8.70006C3.82222 8.43642 3.81555 8.0023 4.07918 7.73043C4.34282 7.45855 4.77694 7.45187 5.04881 7.71551L6.71431 9.33054L10.9512 5.222C11.2231 4.95837 11.6572 4.96505 11.9209 5.23692Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect
          width='9.14286'
          height='9.14286'
          fill='white'
          transform='translate(3.42847 3.42847)'
        />
      </clipPath>
    </defs>
  </svg>
);
