import React from 'react';
import { useStore } from 'effector-react';
import { Wrap, StyledPreloader, StyledIntensiveItem } from './styles';
import { RouteComponentProps } from 'react-router';
import { $intensiveItem } from 'models/catalog/model';
import { useGetIntensiveItemQuery } from 'queries/catalog/useGetIntensiveItemQuery/useGetIntensiveItemQuery';
import { NoData } from '../Event/styles';

export const Intensive: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
  ...rest
}) => {
  const { id } = match.params;
  const { isLoading, isFetching } = useGetIntensiveItemQuery(id);
  const data = useStore($intensiveItem);

  if (isLoading || isFetching) return <StyledPreloader />;
  if (!data) return <NoData>Нет данных</NoData>;

  return (
    <Wrap {...rest}>
      <StyledIntensiveItem {...data} />
    </Wrap>
  );
};
