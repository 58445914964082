import { useQuery } from 'react-query';
import { getCartFx } from 'models/cart';
import { CartQueryProps } from './types';
import { setCart } from 'models/cart/model';

export const useCartQuery: CartQueryProps = () =>
  useQuery('cartData', () => getCartFx(), {
    refetchOnWindowFocus: false,
    onSuccess: res => {
      // TODO: Убрал функционал для работы с локальной корзиной до реализации на бэкенде
      // const localCart = CartService.getCart();
      // if (!localCart && res.data.data) {
      // 2) Если в локале нет корзины, а на сервере есть,
      // нужно сохранить корзину с сервера в локал и использовать её.
      // CartService.setCart(res.data.data);
      // setCart(res.data.data);
      // } else if (localCart && res.data.data.price === 0) {
      // 3) Если на сервере корзины нет, а в локале есть,
      // необходимо передать на сервер данные о составе корзины.
      // TODO: Тут надо отправить запрос на синхронизацию корзины на бэк
      // setCart(localCart);
      // } else if (localCart && res.data.data.price !== 0) {
      // 4) Если корзина есть и на сервере и  в локале,
      // необходимо на сервере удалить все товары из корзины и добавить те, которые сохранены в локале.
      // TODO: Тут надо отправить запрос на синхронизацию корзины на бэк
      // setCart(localCart);
      // } else if (!localCart && res.data.data.price === 0) {
      // 1) Если обе корзины пусты, нужно показать страницу пустой корзины, она должна быть отрисована в макете.
      setCart(res.data.data);
      // }
    },
    retry: 1,
  });
