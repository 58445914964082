import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';

// TODO тип
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetInnerBudgetQuery = () =>
  useQuery<void, AxiosError, number>('InnerBudget', () =>
    apiClient.get(Endpoint.GET_INNER_BUDGET).then(res => res.data.data),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
