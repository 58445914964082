import { updateUserProfileFx } from 'models/userProfile/model';
import { useMutation, UseMutationResult } from 'react-query';
import { UserProfileType } from 'models/auth/types';
import { AxiosError, AxiosResponse } from 'axios';
import { UpdateUserFormValues } from 'models/userProfile/types';

export const useUpdateUserProfile: () => UseMutationResult<
  AxiosResponse<UserProfileType>,
  AxiosError<{ error: string }>,
  UpdateUserFormValues
> = () =>
  useMutation<
    AxiosResponse<UserProfileType>,
    AxiosError<{ error: string }>,
    UpdateUserFormValues
  >(['updateUserProfile'], params => {
    const values = {
      ...params,
      avatar: params['avatar'] ? Number(params['avatar']) : undefined,
    };
    return updateUserProfileFx(values);
  });
