import React from 'react';

export const PlayMediumIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M15 9L6 14.1962L6 3.80385L15 9Z' />
  </svg>
);
