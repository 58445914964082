import {
  PersonalDataConfirm,
  PersonalDataLink,
  RegistrationButton,
  SendCodeButton,
  StyledButton,
  StyledPreloader,
  Text,
  RegistrationMessage,
} from './styles';
import { Checkbox } from 'ui/Checkbox';
import React from 'react';
import { ButtonProps } from './types';
import { Path } from 'constants/paths';

export const Buttons: React.FC<ButtonProps> = ({
  step,
  setChecked,
  checked,
  isFetching,
  buttonHandler,
  closeModal,
  goBack,
}) => {
  switch (step) {
    case 'registration': {
      return (
        <>
          <PersonalDataConfirm>
            <Checkbox onClick={setChecked} checked={checked} readOnly />
            <Text>
              Я согласен(-на) с{' '}
              <PersonalDataLink to={Path.about + '/docs'} onClick={closeModal}>
                условиями обработки персональных данных
              </PersonalDataLink>
            </Text>
          </PersonalDataConfirm>
          {isFetching ? (
            <StyledPreloader />
          ) : (
            <StyledButton disabled={!checked} onClick={buttonHandler}>
              Далее
            </StyledButton>
          )}
          <RegistrationMessage>Вы уже зарегистрированы?</RegistrationMessage>
          <RegistrationButton buttonType={'link'} onClick={goBack}>
            Войти
          </RegistrationButton>
        </>
      );
    }

    case 'code': {
      return (
        <>
          {isFetching ? (
            <StyledPreloader />
          ) : (
            <SendCodeButton onClick={buttonHandler}>
              Подтвердить код
            </SendCodeButton>
          )}
        </>
      );
    }

    case 'password': {
      return (
        <>
          {isFetching ? (
            <StyledPreloader />
          ) : (
            <StyledButton onClick={buttonHandler}>
              Завершить регистрацию
            </StyledButton>
          )}
        </>
      );
    }
    default:
      throw new Error(`Unknown order state: '${step}'!`);
  }
};
