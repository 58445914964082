import styled from 'styled-components';
import { CodeInput } from 'ui/CodeInput';

export const Title = styled.div`
  width: 501px;
  text-align: center;
  margin-top: 24px;
  ${({ theme }) => theme.typography.headline2};
`;

export const Text = styled.div`
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CodeInputSC = styled(CodeInput)`
  margin-top: 24px;
`;

export const FormSC = styled.form`
  display: flex;
  flex-direction: column;
`;
