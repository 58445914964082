import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { ArticleBannerProps } from './types';
import { DateFormats } from 'constants/dateFormats';

const Wrap = styled.div<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 14px;
  height: 340px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 60px 55px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-line;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BannerTitle = styled.p`
  margin: 0;
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  position: relative;
  color: ${({ theme }) => theme.colors.mainBgColor};
`;

const BannerDate = styled.span<{ $hasImage: boolean }>`
  font-weight: 400;
  font-size: 18px;
  line-height: 28.8px;
  position: relative;
  color: ${({ theme, $hasImage }) =>
    !$hasImage ? theme.colors.mainBgColor : theme.colors.mainBgColor};
`;

export const NoImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.actionColor};
`;

export const Banner: React.FC<ArticleBannerProps> = ({
  title,
  date,
  image,
  ...rest
}) => (
  <Wrap image={image} {...rest}>
    <Background>
      {image ? <BackgroundImage src={image} alt={title} /> : <NoImage />}
    </Background>
    <BannerTitle>{title}</BannerTitle>
    <BannerDate $hasImage={!!image}>
      {format(date, DateFormats['StartEventDate'], { locale: ru })}
    </BannerDate>
  </Wrap>
);
