import React, { useState } from 'react';
import { ChevronRightIcon } from 'icons';
import { Item, Header, Title, Button, Text } from './styles';
import { AccordionItemProps } from './types';
import { regExpHTML } from 'constants/regExps';

export const Accordion: React.FC<AccordionItemProps> = ({ title, text }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Item>
      <Header
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <Title>{title}</Title>
        <Button isOpen={isOpen}>{ChevronRightIcon}</Button>
      </Header>
      {isOpen ? (
        regExpHTML.test(text) ? (
          <Text dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <Text>{text}</Text>
        )
      ) : null}
    </Item>
  );
};
