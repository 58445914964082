import Ruble from 'components/common/Ruble';
import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { formatPriceToString } from 'utilities/formatPriceToString';
import { ProductOptionProps } from './types';

const ButtonCount = styled.span`
  ${({ theme }) => theme.typography.bodyText3};
  letter-spacing: 0;
  text-align: center;
  color: ${props => props.theme.colors.thirdTextAndIconColor};
`;

const ButtonPrice = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: ${props => props.theme.colors.mainColor};
`;

const ButtonWrap = styled.button<
  Pick<ProductOptionProps, 'pressed' | 'disabled'>
>`
  border: 1px solid ${props => props.theme.colors.thirdBgColor};
  background-color: ${props =>
    !props.pressed
      ? props.theme.colors.mainBgColor
      : props.theme.colors.thirdBgColor};
  padding: 16px;
  border-radius: 14px;
  min-width: 112px;
  outline: none;

  ${({ disabled, theme }) =>
    disabled
      ? `background-color: ${theme.colors.disabledColor};
        cursor: not-allowed;
        & > ${ButtonCount}, & > ${ButtonPrice} {
         color: ${theme.colors.thirdBgColor};
  } `
      : null};
  & > ${ButtonCount}, & > ${ButtonPrice} {
    display: block;
  }
  & ${ButtonCount} + ${ButtonPrice} {
    margin-top: 8px;
  }
`;

export const ProductOption: React.FC<
  ProductOptionProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  preview,
  pressed,
  price,
  currency = Ruble,
  disabled,

  ...rest
}) => {
  return (
    <ButtonWrap type='button' pressed={pressed} disabled={disabled} {...rest}>
      {preview ? (
        <ButtonCount className='count'>
          {/*TODO: Разобраться с Димой что должно выводиться здесь*/}
          {preview}
        </ButtonCount>
      ) : null}
      <ButtonPrice className='price'>
        {formatPriceToString(price)} {currency}
      </ButtonPrice>
    </ButtonWrap>
  );
};

export default ProductOption;
