import React, { useState } from 'react';
import { useStore } from 'effector-react';
import Ruble from 'components/common/Ruble';
import { formatPriceToString } from 'utilities/formatPriceToString';
import {
  Wrap,
  Title,
  Options,
  Option,
  OptionRadio,
  OptionInner,
  OptionTitle,
  OptionText,
  OptionPrice,
  SubscribtionButton,
  StyledPreloader,
} from './styles';
import { VIPClubSubcriptionType } from 'models/catalog/types';
import { declension } from 'utilities/declension';
import { usePurchaseVIPClubSubscriptionQuery } from 'queries/catalog/usePurchaseVIPClubSubcriptionQuery';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Path } from 'constants/paths';
import { Element } from 'react-scroll';
import { showAuthModal } from 'models/auth/model';
import { $user} from 'models/auth';

// TODO: Прописать логику переключения радиобаттонов

export const Subscription: React.FC<{ data: VIPClubSubcriptionType[] }> = ({
  data,
  ...rest
}) => {
  //eslint-disable-next-line
  const [optionId, setOptionId] = useState<string | null>();
  const [optionPrice, setOptionPrice] = useState<number | null>();
  const [optionValidity, setOptionValidity] = useState<string | null>();
  const purchaseQuery = usePurchaseVIPClubSubscriptionQuery();
  const history = useHistory();
  const isAuthed = useStore($user);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const price = e.target.getAttribute('data-price');
    const validity = e.target.getAttribute('data-duration');
    setOptionPrice(Number(price));
    setOptionValidity(validity);
    setOptionId(e.target.value);
  };

  const handleClick = (id?: string | null) => {
    if (!id) {
      toast.error(<ErrorMessage text={'Вы не выбрали план подписки!'} />);
      return;
    }
    purchaseQuery.mutate(Number(id), {
      onSuccess: res => {
        history.push(
          `${Path.directBuy.replace(':key', 'subscription')}/${
            res.data.order_id
          }`
        );
      },
    });
  };

  return (
    <Element name={'subscription'}>
      <Wrap {...rest}>
        <Title>Подписка</Title>
        <Options>
          {data
            ? data.map((item, index) => (
                <Option key={index}>
                  <OptionRadio
                    radioSize='large'
                    onChange={handleChange}
                    value={item.id}
                    checked={item.id.toString() === optionId}
                    data-price={item.price}
                    data-duration={item.validity}
                  />
                  <OptionInner>
                    <OptionTitle>
                      Доступ на {item.validity}{' '}
                      {declension(['день', 'дня', 'дней'], item.validity)}
                    </OptionTitle>
                    <OptionText>{item.description}</OptionText>
                  </OptionInner>
                  <OptionPrice>
                    {formatPriceToString(item.price)} {Ruble}
                  </OptionPrice>
                </Option>
              ))
            : null}
        </Options>
        {purchaseQuery.isLoading ? (
          <StyledPreloader />
        ) : (
          <SubscribtionButton
            buttonType='primary'
            size='large'
            onClick={() => isAuthed ? handleClick(optionId) : showAuthModal()}
          >
            Купить{' '}
            {optionPrice && optionValidity
              ? `«Доступ на ${optionValidity}  ${declension(
                  ['день', 'дня', 'дней'],
                  Number(optionValidity)
                )}» за ${formatPriceToString(optionPrice)} ${Ruble}`
              : null}
          </SubscribtionButton>
        )}
      </Wrap>
    </Element>
  );
};
