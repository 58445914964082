import React from 'react';

const CopyIcon = (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.0833 9.16667C9.57699 9.16667 9.16659 9.57707 9.16659 10.0833V18.3333C9.16659 18.8396 9.57699 19.25 10.0833 19.25H18.3333C18.8395 19.25 19.2499 18.8396 19.2499 18.3333V10.0833C19.2499 9.57707 18.8395 9.16667 18.3333 9.16667H10.0833ZM7.33325 10.0833C7.33325 8.56455 8.56447 7.33334 10.0833 7.33334H18.3333C19.852 7.33334 21.0833 8.56455 21.0833 10.0833V18.3333C21.0833 19.8521 19.852 21.0833 18.3333 21.0833H10.0833C8.56447 21.0833 7.33325 19.8521 7.33325 18.3333V10.0833Z'
      fill='#8D8D8D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.66675 2.75C3.42363 2.75 3.19047 2.84657 3.01857 3.01848C2.84666 3.19039 2.75008 3.42355 2.75008 3.66666V11.9167C2.75008 12.1598 2.84666 12.3929 3.01857 12.5648C3.19048 12.7368 3.42363 12.8333 3.66675 12.8333H4.58341C5.08968 12.8333 5.50008 13.2437 5.50008 13.75C5.50008 14.2563 5.08968 14.6667 4.58341 14.6667H3.66675C2.9374 14.6667 2.23793 14.3769 1.7222 13.8612C1.20648 13.3455 0.916748 12.646 0.916748 11.9167V3.66666C0.916748 2.93732 1.20648 2.23785 1.7222 1.72212C2.23793 1.2064 2.9374 0.916664 3.66675 0.916664H11.9167C12.6461 0.916664 13.3456 1.2064 13.8613 1.72212C14.377 2.23785 14.6667 2.93732 14.6667 3.66666V4.58333C14.6667 5.08959 14.2563 5.5 13.7501 5.5C13.2438 5.5 12.8334 5.08959 12.8334 4.58333V3.66666C12.8334 3.42355 12.7368 3.19039 12.5649 3.01848C12.393 2.84657 12.1599 2.75 11.9167 2.75H3.66675Z'
      fill='#8D8D8D'
    />
  </svg>
);

export default CopyIcon;
