// Принимаем на входе время в миллисекундах
export const formatTimeToString = (time: number): string => {
  const minutes = Math.floor(time / 1000 / 60);
  const seconds = Math.round(time / 1000);
  return `${minutes.toString().match(/\d{2}/) ? minutes : '0' + minutes}:${
    seconds < 60
      ? seconds.toString().match(/\d{2}/)
        ? seconds
        : '0' + seconds
      : '00'
  }`;
};
