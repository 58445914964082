import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { useQuery } from 'react-query';
import { ReferralLinkResponse } from './types';

export const useGetReferralLink = () => {
  const { data, ...rest } = useQuery(
    ['referralLink'],
    () => apiClient.get(Endpoint.GET_REFERAL_LINK),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { data: data?.data as ReferralLinkResponse, ...rest };
};
