import React, { useEffect, useState } from 'react';
import { Button } from 'ui/Button/';
import { ReviewItem } from './ReviewItem/';
import {
  Count,
  FeedbackButton,
  Inner,
  MoreButton,
  Rating,
  RatingComponent,
  ReviewCount,
  ReviewList,
  ReviewRating,
  ReviewWrap,
  Text,
  Title,
  Wrap,
} from './styles';
import { useParams } from 'react-router';
import { useRepliesWithOffsetQuery } from 'queries/useCourseQuery';
import { Preloader } from 'ui/Preloader';
import { clearCourseTestimonials } from 'models/courses/model';
import { declension } from 'utilities/declension';
import { ReviewModal } from './ReviewModal';
import { ReviewsProps } from './types';
import { TCourseStatuses } from 'models/courses/types';
import { clearSchoolTestimonials } from 'models/inner-insight/model';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';

const DECLINATION_VOICES = ['оценка', 'оценки', 'оценок'];
const MAX_COUNT_REPLIES = 3;

export const Reviews: React.FC<ReviewsProps> = ({
  name,
  status,
  testimonials: { rating, voices, replies },
  isSchool = false,
  ...props
}) => {
  const { id } = useParams<{ id: string }>();
  const [isOpen, setOpen] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(MAX_COUNT_REPLIES);

  const { isLoading, isFetching, isError, refetch } = useRepliesWithOffsetQuery(
    Number(id),
    currentOffset
  );

  const showPreloader = isLoading || isFetching;

  useEffect(() => {
    return () =>
      isSchool ? clearSchoolTestimonials() : clearCourseTestimonials();
  }, [isSchool]);

  const handleShowAllReviews = () => {
    refetch();
    setCurrentOffset(currentOffset + MAX_COUNT_REPLIES);
  };

  const handleReviewModal = () => {
    if (status !== TCourseStatuses.COMPLETED) {
      setOpen(true);
    } else {
      toast.error(
        <ErrorMessage
          text={'Отзыв можно оставить только на завершенный курс!'}
        />
      );
    }
  };

  return (
    <Wrap id='reviews' {...props}>
      <Title>Отзывы {!!voices && <Count>{voices}</Count>}</Title>
      <Inner>
        <Rating>
          <RatingComponent size={'medium'} rating={rating} />
          <ReviewWrap>
            {!!rating && <ReviewRating>{rating}</ReviewRating>}
            <ReviewCount>
              (
              {voices
                ? `${voices} ${declension(DECLINATION_VOICES, voices)}`
                : 'нет оценок'}
              )
            </ReviewCount>
          </ReviewWrap>
        </Rating>
        {status === TCourseStatuses.COMPLETED ? (
          <>
            <Text>Поздравляем с успешным прохождением курса «{name}»!</Text>
            <FeedbackButton size={'medium'} onClick={handleReviewModal}>
              Написать отзыв
            </FeedbackButton>
          </>
        ) : (
          <Text>
            Мы не нашли данный курс в списках пройденных вами. Вы можете
            оставлять отзывы только к тем курсам, которые прошли.
          </Text>
        )}
      </Inner>
      {replies?.length ? (
        <ReviewList>
          {replies?.map(item => (
            <ReviewItem key={item.id} {...item} />
          ))}
          {showPreloader && <Preloader />}
          {isError && 'Не удалось загрузить отзывы'}
        </ReviewList>
      ) : null}

      {replies?.length ? (
        <MoreButton>
          <Button buttonType={'link'} onClick={handleShowAllReviews}>
            Смотреть еще отзывы
          </Button>
        </MoreButton>
      ) : null}
      {isOpen ? (
        <ReviewModal
          handleClose={() => setOpen(false)}
          name={name}
          id={Number(id)}
          isSchool={isSchool}
        />
      ) : null}
    </Wrap>
  );
};
