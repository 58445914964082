import styled from 'styled-components';
import { Lesson } from './Lesson';

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline1};
`;

export const BonusTitle = styled.p`
  margin: 40px 0 0;
  ${({ theme }) => theme.typography.headline1};
  color: ${({ theme }) => theme.colors.statusDoneLightColor};
`;

export const AdditionalTitle = styled.p`
  margin: 40px 0 0;
  ${({ theme }) => theme.typography.headline1};
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 24px -12px -24px;
`;

export const Wrap = styled.div`
  margin-top: 40px;
`;

export const ListItem = styled(Lesson)`
  margin: 0 12px 24px;
  width: calc((100% - 48px) / 2);
`;

export const Present = styled.div`
  display: flex;
  align-items: center;
  margin: 37px 0 0;
`;

export const PresentText = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
`;

export const PresentIcon = styled.div`
  & + ${PresentText} {
    margin-left: 16px;
  }
  padding: 8px;
  border: 2px solid ${({ theme }) => theme.colors.statusDoneColor};
  border-radius: 50%;
  font-size: 0;
  align-self: flex-start;
`;

export const AccentPresentText = styled.span`
  color: ${({ theme }) => theme.colors.statusDoneColor};
`;
