import React, { useEffect, useRef, useState } from 'react';
import Title from '../../Title';
import {
  BornDate,
  RadioGroup,
  FormSC,
  Gender,
  Item,
  LabelSC,
  SocialNetworks,
  Actions,
  ButtonSC,
  Wrap,
  InputDatepickerSC,
  RightBannerColumn,
  SaveUserData,
  PreloaderSC,
} from './styled';
import { ChangeMyAvatar } from './ChangeMyAvatar';
import { Field, Form } from 'react-final-form';
import { useUpdateUserProfile } from 'queries/account/myData/useUpdateUserProfile';
import { Radio } from 'ui/Radio';
import { baseUrl } from 'api/apiClient';
import bannerImage from 'images/referral_link_profile.png';
import { useUserProfile } from 'models/auth/model';
import { useUserProfileQuery } from 'queries/auth/useUserProfileQuery';
import { ConfirmEmailChange } from './ConfirmEmailChange';
import { useChangeEmail } from 'queries/account/myData/useChangeEmail';
import { MiniBanner } from './MiniBanner';
import { InstagramButton } from './InstagramButton/';
import { TelegramButton } from './TelegramButton/';
import {
  SetSubmitCallbackType,
  SubmitCallbackType,
} from 'interfaces/externalSubmitTypes';
import { UpdateUserFormValues } from 'models/userProfile/types';
import { UserProfileType } from 'models/auth/types';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';

const filterChangedValues = (
  values: UpdateUserFormValues,
  user: UserProfileType
) => {
  return Object.keys(values).reduce<UpdateUserFormValues>((acc, key) => {
    const userField = user[key as keyof UserProfileType];
    const formField = values[key as keyof UpdateUserFormValues];
    if (formField && userField !== formField) {
      acc[key as keyof UpdateUserFormValues] = formField;
    }
    return acc;
  }, {});
};

export const MyData: React.FC = () => {
  const [openConfirmEmailChangeModal, setOpenConfirmEmailModal] = useState(
    false
  );
  const handleCloseModal = () => setOpenConfirmEmailModal(false);

  const [avatarId, setAvatarId] = useState<string>();
  const user = useUserProfile();
  const { refetch } = useUserProfileQuery();

  const [avatar, setAvatar] = useState('');
  const updateUserProfileMutation = useUpdateUserProfile();
  const [verificationCode, setVerificationCode] = useState('');
  const changeEmailMutation = useChangeEmail();
  const disableEditingBirthday = !!user?.birthday?.length;

  useEffect(() => {
    if (!user) return;

    if (user.avatar) {
      setAvatar(baseUrl + user.avatar);
    }
  }, [user]);

  const isUpdatingProfile =
    updateUserProfileMutation.isLoading || changeEmailMutation.isLoading;

  const submit = (values: UpdateUserFormValues) => {
    if (user) {
      const filteredValues = filterChangedValues(values, user);
      if (!Object.keys(filteredValues).length) {
        toast.error(<ErrorMessage text='Вы не изменяли данных' />);
        return;
      }
      // Если поменялся имейл, его надо подтвердить через смс код
      if (filteredValues.email) {
        changeEmailMutation.mutate(filteredValues.email, {
          onSuccess: res => {
            setVerificationCode(res.data.verification_code);
            setOpenConfirmEmailModal(true);
          },
        });
      }

      updateUserProfileMutation.mutate(filteredValues, {
        onSuccess: () => {
          refetch();
        },
      });
    }
  };

  // Делаем отправки форм через внешний сабмит
  const buttonHandler = useRef<SubmitCallbackType>();
  const setButtonHandler: SetSubmitCallbackType = func => {
    buttonHandler.current = func;
  };
  const submitHandler = () => {
    buttonHandler?.current?.();
  };

  return (
    <>
      {openConfirmEmailChangeModal && (
        <ConfirmEmailChange
          verificationCode={verificationCode}
          closeModal={handleCloseModal}
        />
      )}
      <Wrap>
        <Title>{'Мои данные'}</Title>
        <>
          <ChangeMyAvatar
            avatar={avatar}
            setAvatar={setAvatar}
            setAvatarId={setAvatarId}
          />
          <Form
            initialValues={{
              last_name: user?.last_name,
              name: user?.name,
              middle_name: user?.middle_name,
              sex: user?.sex,
              birthday: user?.birthday,
              email: user?.email,
              phone: user?.phone,
              avatar: avatarId || undefined,
            }}
            onSubmit={submit}
            render={props => {
              setButtonHandler(props.handleSubmit);
              return (
                <FormSC onSubmit={props.handleSubmit}>
                  <Field
                    labelWidth={144}
                    inputWidth={340}
                    label='Фамилия'
                    name='last_name'
                    alignLabel='left'
                    component={Item}
                  />
                  <Field
                    labelWidth={144}
                    inputWidth={340}
                    label='Имя'
                    name='name'
                    alignLabel='left'
                    component={Item}
                  />
                  <Field
                    labelWidth={144}
                    inputWidth={340}
                    label='Отчество'
                    name='middle_name'
                    alignLabel='left'
                    component={Item}
                  />

                  <Gender>
                    <LabelSC>Пол</LabelSC>
                    <RadioGroup>
                      <Field name='sex' type='radio' value='M'>
                        {({ input }) => {
                          return (
                            <Radio
                              labelText='Мужской'
                              name={input.name}
                              type='radio'
                              value='M'
                              checked={input.checked}
                              onChange={input.onChange}
                            />
                          );
                        }}
                      </Field>
                      <Field name='sex' type='radio' value='F'>
                        {({ input }) => {
                          return (
                            <Radio
                              labelText='Женский'
                              name={input.name}
                              type='radio'
                              value='F'
                              checked={input.checked}
                              onChange={input.onChange}
                            />
                          );
                        }}
                      </Field>
                    </RadioGroup>
                  </Gender>
                  <BornDate>
                    <Field
                      editable={!disableEditingBirthday}
                      disabled={disableEditingBirthday}
                      name='birthday'
                      labelWidth={144}
                      inputWidth={340}
                      label='Дата рождения'
                      changeFieldValue={props.form.change}
                      tooltipMessage='Дата рождения уже была задана ранее!'
                      component={InputDatepickerSC}
                      readOnly
                    />
                  </BornDate>
                  <Field
                    labelWidth={144}
                    inputWidth={340}
                    label='E-mail'
                    alignLabel='left'
                    name='email'
                    component={Item}
                  />
                  <Field
                    disabled
                    labelWidth={144}
                    inputWidth={340}
                    label='Телефон'
                    alignLabel='left'
                    name='phone'
                    component={Item}
                  />
                </FormSC>
              );
            }}
          />
          <SocialNetworks>
            <Title>{'Аккаунты'}</Title>
            <Actions>
              <InstagramButton instagram={user?.instagram || null} />
              <TelegramButton telegram={user?.telegram || null} />
            </Actions>
          </SocialNetworks>
          <SaveUserData>
            {isUpdatingProfile && <PreloaderSC />}
            <ButtonSC disabled={isUpdatingProfile} onClick={submitHandler}>
              Сохранить
            </ButtonSC>
          </SaveUserData>
        </>
      </Wrap>
      <RightBannerColumn>
        <MiniBanner
          image={bannerImage}
          title={'Получите 500 бонусов'}
          subtitle='За полностью заполненный профиль'
        />
      </RightBannerColumn>
    </>
  );
};
