import { useQuery } from 'react-query';
import { getCatalogFx } from 'models/catalog';
import { setCatalog } from 'models/catalog/model';
import { CatalogQueryProps } from './types';

export const useCatalogQuery: CatalogQueryProps = code =>
  useQuery('catalogData', () => getCatalogFx(code), {
    onSuccess: res => {
      setCatalog(res.data.data);
    },
    refetchOnWindowFocus: false,
  });
