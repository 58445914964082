import React from 'react';
import { FileIcon } from 'icons/';
import { Item, ItemTitle, Icon, Info } from './styles';
import { LessonFile } from 'models/lesson/types';
import { baseUrl } from 'api/apiClient';

export const DocumentItem: React.FC<LessonFile> = ({
  name,
  path,
  size,
  ...rest
}) => {
  return (
    <Item href={`${baseUrl}${path}`} target='_blank' {...rest}>
      <Icon>{FileIcon}</Icon>
      <ItemTitle>{name}</ItemTitle>
      <Info>{Math.round(size / 1000)} Кб</Info>
    </Item>
  );
};
