import styled from 'styled-components';
import { Button } from 'ui/Button';
export const Wrap = styled.div`
  width: 213px;
  margin-right: 70px;
  margin-bottom: 44px;
`;

export const Name = styled.div<{ received: boolean }>`
  margin-top: 12px;
  ${({ theme }) => theme.typography.headline3}
  ${({ received }) => (!received ? 'opacity: 0.5;' : null)};
`;

export const DiplomDate = styled.div`
  & > svg {
    margin-right: 8px;
  }
  ${({ theme }) => theme.typography.bodyText3};
  color: ${props => props.theme.colors.thirdTextAndIconColor};
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

export const ButtonSC = styled(Button)`
  margin-top: 12px;
`;

export const Preview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PreviewWrapper = styled.div<{ received: boolean }>`
  width: 224px;
  height: 156px;
  cursor: pointer;
  border-radius: 14px;
  overflow: hidden;
  transition: box-shadow 0.3s;
  ${({ received }) => (!received ? 'opacity: 0.5;' : null)};

  &:hover {
    transition: box-shadow 0.3s;
    box-shadow: 0 0 4px 2px ${({ theme }) => theme.colors.actionColor};
  }
`;
