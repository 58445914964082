import React, { useEffect } from 'react';
import { useEventsQuery } from 'queries/catalog/useEventsQuery';
import { $eventsData, clearCatalog } from 'models/catalog/model';
import { useStore } from 'effector-react';
import { LargeCatalogItem } from '../../common/LargeCatalogItem';
import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
`;

export const Events: React.FC = () => {
  const { isLoading } = useEventsQuery();
  const data = useStore($eventsData);

  useEffect(() => {
    return () => {
      clearCatalog();
    };
  }, []);

  return isLoading ? (
    <StyledPreloader />
  ) : (
    <>
      {data.map((item, index) => (
        <LargeCatalogItem key={index} {...item} />
      ))}
    </>
  );
};
