import styled from 'styled-components';
import { ArrowButton } from 'ui/ArrowButton';
import { Button } from 'ui/Button';
import { NoImage } from 'ui/NoImage';
import { Link } from 'react-router-dom';

export const BookImage = styled.img`
  max-width: 100%;
  object-fit: contain;
`;

export const Wrap = styled(Link)`
  width: calc((100% - 48px) / 2);
  margin: 0 12px 40px;
  padding: 24px 30px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  & .swiper-container {
    width: 260px;
    height: 260px;
  }
`;

export const StyledNoImage = styled(NoImage)`
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

export const SwiperWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftArrow = styled(ArrowButton)`
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondColor};
  }
`;

export const RightArrow = styled(ArrowButton)`
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondColor};
  }
`;

export const SetTitle = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: 12px 0 0;
`;

export const Author = styled.p`
  ${({ theme }) => theme.typography.bodyText1};
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 6px 0 0;
`;

export const PriceWrap = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const OldPrice = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-decoration: line-through;
`;

export const NewPrice = styled.span`
  ${({ theme }) => theme.typography.headline3};
  & + ${OldPrice} {
    margin-left: 16px;
  }
`;

export const Btn = styled(Button)`
  margin-left: auto;
`;

export const DisabledItemWrap = styled.div`
  width: calc((100% - 48px) / 2);
  margin: 0 12px 40px;
  padding: 24px 30px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  & .swiper-container {
    width: 260px;
    height: 260px;
    z-index: 0;
  }

  position: relative;
  cursor: not-allowed;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-color: ${({ theme }) => theme.colors.mainBgColor};
    opacity: 0.5;
  }
`;
