import { SmsCode, StyledCountdown, StyledForm, Text } from './styles';
import React from 'react';
import { ConfirmCodeFormProps } from './types';
import { Field, Form } from 'react-final-form';

export const ConfirmCodeForm: React.FC<ConfirmCodeFormProps> = ({
  phone,
  setButtonHandler,
  type,
  ...rest
}) => {
  return (
    <Form
      id={'otp'}
      render={({ handleSubmit }) => {
        setButtonHandler(handleSubmit);
        return (
          <StyledForm onSubmit={handleSubmit}>
            <Text>
              Мы отправили код подтверждения на&nbsp;привязанный к&nbsp;аккаунту
              номер «+{phone}»
            </Text>
            <Field
              name={'otp'}
              inputMode={'numeric'}
              type={'text'}
              component={SmsCode}
            />

            <StyledCountdown type={type} />
          </StyledForm>
        );
      }}
      {...rest}
    />
  );
};
