import React, { useState } from 'react';
import {
  SubTitle,
  Title,
  StyledButton,
  Item,
  StyledLoyaltyPoints,
  PromoCodeForm,
  StyledPreloader,
} from './styles';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { SuccessMessage } from 'ui/SuccessMessage';
import { LoaltyProgramProps } from './types';
import { FormApi } from 'final-form';
import { useDiscountCalculationQuery } from 'queries/useDiscountCalculateQuery';
import { $accumulatedBonuses, setAccumulatedBonuses } from 'models/order/model';
import { useStore } from 'effector-react';
import { ErrorMessage } from 'ui/ErrorMessage';
import { AxiosError } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';

export const LoyaltyProgram: React.FC<LoaltyProgramProps> = ({
  orderId,
  finalPrice,
  type,
  ...rest
}) => {
  const { mutate, isLoading } = useDiscountCalculationQuery();
  const accumulatedBonuses = useStore($accumulatedBonuses);
  const [promocodeSuccess, setPromoCodeSuccess] = useState(false);

  const handleSubmit = async (
    values: { promocode?: string },
    form: FormApi
  ) => {
    if (!values.promocode?.trim()) {
      toast.error(<ErrorMessage text={'Введите промокод'} />);
      return;
    }

    return await new Promise((resolve, reject) => {
      if (promocodeSuccess) {
        mutate(
          { order_id: orderId },
          {
            onSuccess: resolve,
            onError: reject,
          }
        );
      } else {
        mutate(
          { ...accumulatedBonuses, ...values, order_id: orderId },
          {
            onSuccess: resolve,
            onError: reject,
          }
        );
      }
    })
      .then(res => {
        if (promocodeSuccess) {
          setAccumulatedBonuses({ ...accumulatedBonuses, promocode: '' });
          toast.success(
            <SuccessMessage text='Вы отменили применение промокода!' />
          );
          setPromoCodeSuccess(false);
          form.reset();
        } else {
          setAccumulatedBonuses(values);
          toast.success(<SuccessMessage text='Промокод успешно применён!' />);
          setPromoCodeSuccess(true);
        }
        return res;
      })
      .catch(
        (
          err: AxiosError<{
            error: ServerValidationError | string;
            details: {
              file: string;
              line: number;
              message: string;
            };
          }>
        ) => {
          setAccumulatedBonuses({ ...accumulatedBonuses, promocode: '' });
          if (typeof err.response?.data.details?.message === 'string') {
            toast.error(
              <ErrorMessage text={err.response?.data.details?.message} />
            );
          }
        }
      );
  };

  return (
    <div {...rest}>
      <Title>Программа лояльности</Title>
      {type === 'courses' || type === 'chat' ? (
        <>
          <SubTitle>
            Если у вас есть промокод, введите его в поле ниже, чтобы
            воспользоваться специальным предложением
          </SubTitle>
          <Form
            render={props => (
              <PromoCodeForm onSubmit={props.handleSubmit}>
                <Field
                  name={'promocode'}
                  component={Item}
                  status={promocodeSuccess ? 'success' : 'wait'}
                  fullWidth
                  disabled={promocodeSuccess}
                />
                {isLoading ? (
                  <StyledPreloader />
                ) : (
                  <StyledButton invert={promocodeSuccess} type={'submit'}>
                    {promocodeSuccess ? 'Удалить' : 'Активировать'}
                  </StyledButton>
                )}
              </PromoCodeForm>
            )}
            onSubmit={handleSubmit}
          />
        </>
      ) : null}
      <StyledLoyaltyPoints
        orderId={orderId}
        finalPrice={finalPrice}
        type={type}
      />
    </div>
  );
};
