import React from 'react';
import { Title, StyledText, Wrap } from './styles';

export const Description: React.FC = () => {
  return (
    <Wrap>
      <Title>Описание</Title>
      <StyledText>
        Inner Insight - лучший опыт коучинга в России и один из лучших в
        зарубежье, поскольку аккредитован по высоким международным стандартам.
        Коучинг - это работа клиента над своим запросом. Коуч лишь направляет
        вас точными и мощными вопросами. Коуч НЕ консультирует и НЕ говорит, что
        вам делать. Это ВАША РАБОТА, все ответы внутри вас.
      </StyledText>
      <br />
      <StyledText>
        В ходе еженедельных онлайн-бесед с Владимиром Барановым ведется
        отработка практических навыков под его руководством. Все участники
        получают ответы на вопросы по материалу и техникам. Записи онлайн-бесед
        размещаются в личном кабинете, и участники могут их просматривать в
        любое удобное время. Для проверки приобретенных навыков и уровня
        квалификации в финале проводится контроль качества, оценивается
        проведение коучем сессии со случайными клиентом и тестирование.
      </StyledText>
    </Wrap>
  );
};
