import React from 'react';
import { Field, Form } from 'react-final-form';
import { StyledForm, Phone } from './styles';
import { PhoneFormProps } from './types';

export const PhoneForm: React.FC<PhoneFormProps> = ({
  setButtonHandler,
  ...rest
}) => {
  return (
    <Form
      render={({ handleSubmit }) => {
        setButtonHandler(handleSubmit);
        return (
          <StyledForm onSubmit={handleSubmit}>
            <Field
              labelWidth={144}
              inputWidth={340}
              label={'Телефон'}
              direction={'vertical'}
              alignLabel={'left'}
              placeholder={'Введите телефон'}
              name={'phone'}
              component={Phone}
            />
          </StyledForm>
        );
      }}
      {...rest}
    />
  );
};
