import { Modal } from 'ui/Modal';
import React, { ChangeEvent, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { Button } from 'ui/Button';
import {
  Confirm,
  SelectAndPreview,
  PreviewImage,
  PreviewTitle,
  NoImagePreview,
} from './styled';
import { useUploadAvatar } from 'queries/account/myData/useUploadAvatar';
import CameraIcon from 'icons/Camera';

interface IProps {
  closeModal: () => void;
  setAvatar: (values: string) => void;
  setAvatarId: (values: string) => void;
}

export const SelectAvatarModal: React.FC<IProps> = ({
  closeModal,
  setAvatar,
  setAvatarId,
}) => {
  const [preview, setPreview] = useState('');
  const uploadAvatar = useUploadAvatar(setAvatarId);

  const onClose = () => setPreview('');
  const onCrop = (pv: string) => setPreview(pv);

  const selectAndClose = () => {
    setAvatar(preview);
    uploadAvatar.mutate(preview);
    closeModal();
  };

  function onBeforeFileLoad(event: ChangeEvent<HTMLInputElement>) {
    const availableExtensions = ['px', 'jpg', 'png'];
    const maxSizeInBites = 6291456;
    const minSizeInBites = 16384;
    if (event?.target?.files?.length) {
      const isNormalImageExtension = availableExtensions.includes(
        event.target.files[0].name.split('.').pop() as string
      );

      if (!isNormalImageExtension) {
        alert('Недопустимый формат изображения');
        event.target.value = '';
        return;
      }

      if (event.target.files[0].size > maxSizeInBites) {
        alert(`Размер картинки больше ${maxSizeInBites / 1024 / 1024} Мб`);
        event.target.value = '';
        return;
      }

      if (event.target.files[0].size < minSizeInBites) {
        alert(`Размер картинки меньше ${minSizeInBites / 1024} Кб`);
        event.target.value = '';
        return;
      }
    }
  }

  return (
    <Modal crossIconStyle={'padding:38px !important'} handleClose={closeModal}>
      <SelectAndPreview>
        <Avatar
          label='Выберите файл'
          width={320}
          height={320}
          imageWidth={320}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
          src={preview}
        />
        <PreviewTitle>Изменить фото</PreviewTitle>
        {preview ? (
          <PreviewImage src={preview} alt='fawef' />
        ) : (
          <NoImagePreview>{CameraIcon}</NoImagePreview>
        )}
      </SelectAndPreview>

      {preview && (
        <Confirm>
          <Button onClick={selectAndClose}>Готово</Button>
        </Confirm>
      )}
    </Modal>
  );
};
