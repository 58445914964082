import React, { useEffect, useState } from 'react';
import { BuyCategoriesKey } from './Steps/types';
import { Wrap, StyledTitle, StyledSteps, StyledContent } from './styles';
import { RouteComponentProps } from 'react-router';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export const titles = {
  cart: 'Корзина',
  delivery: 'Доставка',
  payment: 'Оплата',
};

export const steps: BuyCategoriesKey[] = ['cart', 'delivery', 'payment'];

export const Buy: React.FC<RouteComponentProps<{ id: string }>> = props => {
  const [stepKey, setStepKey] = useState<BuyCategoriesKey>('cart');
  useEffect(() => {
    return () => {
      setStepKey('cart');
    };
  }, []);

  const changeToNextStep = () => {
    const currentStepIndex = steps.findIndex(key => key === stepKey);
    if (currentStepIndex + 1 >= steps.length) {
      // console.error('This is the last step');
    } else {
      setStepKey(steps[currentStepIndex + 1]);
    }
  };

  useScrollToTopOnMount();
  return (
    <Wrap>
      <StyledTitle>{titles[stepKey]}</StyledTitle>
      <StyledSteps stepKey={stepKey} />
      <StyledContent
        categoryKey={stepKey}
        changeToNextStep={changeToNextStep}
        {...props}
      />
    </Wrap>
  );
};
