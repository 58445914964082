import {
  getPlansCategoriesFx,
  setPlansCategories,
} from 'models/education/model';
import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { PlansCategoryType } from 'models/education/types';

export const usePlansCategoriesQuery: () => UseQueryResult<
  AxiosResponse<{ data: PlansCategoryType }>,
  AxiosError<{ error: string }>
> = () =>
  useQuery('plansCategories', () => getPlansCategoriesFx(), {
    onSuccess: res => {
      setPlansCategories(res.data.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
