import React from 'react';

export const PuzzleHandLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M26 45V43C26 43 29 40.75 29 38V35'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M18 21L13 26C11.59 27.3 11 28.04 11 30V36.79V45'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M15 33.0004C15 33.0004 21.16 26.8404 23 25.0004C24.84 23.1604 27.71 27.7504 25.51 30.4904C24.43 31.5704 23 33.0004 23 33.0004C23 33.4504 23 35.6104 23 36.0004C23 39.9204 19.59 40.0004 19 40.0004'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M29 45H9V53H29V45Z'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
    <path
      d='M26 49H24'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
    <path
      d='M23 35H40C41.1 35 42 34.1 42 33C42 33 42 28.76 42 23.99C42 22.72 43.36 22.82 43.75 23.33C44.5 24.32 45.66 25 47 25C49.21 25 51 23.21 51 21C51 18.79 49.21 17 47 17C45.66 17 44.5 17.68 43.75 18.67C43.36 19.19 42 19.28 42 18.01V9.99C42 8.89 41.1 8 40 8H33C31.73 8 31.81 9.36 32.33 9.75C33.32 10.5 34 11.66 34 13C34 15.21 32.21 17 30 17C27.79 17 26 15.21 26 13C26 11.66 26.68 10.5 27.67 9.75C28.19 9.36 28.27 8 27 8C26 8 20 8 20 8C18.9 8 18 8.9 18 10V30'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
  </svg>
);

