import React from 'react';
import styled from 'styled-components';
import {
  ConsultationIcon,
  FileTextIcon,
  InfoIcon,
  MapPinIcon,
  UsersIcon,
} from 'icons/';
import { AboutCategoriesKey } from './types';
import { CategoryButton } from 'ui/CategoryButton';
import { CategoryItem } from 'components/Catalog/Categories/types';
import { Link } from 'react-router-dom';
import { Path } from 'constants/paths';
import { setShowChat, useShowChat } from 'models/chat/model';
import { useUserProfile } from '../../../models/auth/model';

const Category = styled(CategoryButton)`
  text-align: left;
`;

const CategoryWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & ${Category} + ${Category} {
    margin-top: 12px;
  }
`;

export const StyledLink = styled(Link)`
  & + {
    margin-top: 12px;
  }
`;

const categories: CategoryItem[] = [
  {
    id: 1,
    key: 'project',
    icon: InfoIcon,
    title: 'О Проекте',
  },
  {
    id: 2,
    key: 'team',
    icon: UsersIcon,
    title: 'Команда',
  },
  {
    id: 3,
    key: 'docs',
    icon: FileTextIcon,
    title: 'Правовые документы',
  },
  {
    id: 4,
    key: 'contacts',
    icon: MapPinIcon,
    title: 'Контакты',
  },
];

export const Categories: React.FC<{ categoryKey: AboutCategoriesKey }> = ({
  categoryKey,
  ...rest
}) => {
  const showChat = useShowChat();
  const user = useUserProfile();
  return (
    <CategoryWrap {...rest}>
      {categories.map(({ id, key, icon, title }) => {
        return (
          <Link key={id} to={`${Path.about}/${key}`}>
            <CategoryButton isActive={categoryKey === key} icon={icon}>
              {title}
            </CategoryButton>
          </Link>
        );
      })}
      {user ? (
        <CategoryButton
          isActive={showChat}
          onClick={() => setShowChat(!showChat)}
          icon={ConsultationIcon}
        >
          Связаться с админом
        </CategoryButton>
      ) : null}
    </CategoryWrap>
  );
};
