import styled from 'styled-components';
import Button from 'ui/Button/Button';
import Tag from 'ui/Tag/Tag';
import { baseUrl } from 'api/apiClient';

export const Wrap = styled.div<{ image: string }>`
  background-image: ${({ image }) => `url(${baseUrl}${image})`};
  background-size: cover;
  background-position: center;
  border-radius: 14px;
  height: 340px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 60px 55px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-line;
`;

export const BannerTitle = styled.div<{ brightText?: boolean }>`
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  color: ${({ theme, brightText }) =>
    brightText ? theme.colors.mainBgColor : theme.colors.mainColor};
`;

export const BannerSubTitle = styled.div<{ brightText?: boolean }>`
  font-weight: 400;
  font-size: 24px;
  line-height: 26.4px;
  color: ${({ theme, brightText }) =>
    brightText ? theme.colors.mainBgColor : theme.colors.mainColor};
`;

export const BannerButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.mainColor};
  background-color: ${({ theme }) => theme.colors.mainColor};
  color: ${({ theme }) => theme.colors.mainBgColor};
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.socialMediaNotActiveColor};
  }
`;

export const SalesDataWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const StatusTag = styled(Tag)`
  margin-right: 80px;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colors.mainColor};
`;

export const Status = styled.div`
  &:before {
    display: block;
    margin: 0 0 6px;
    ${({ theme }) => theme.typography.tabsText};
    color: ${props => props.theme.colors.secondTextColor};
    content: 'Статус';
  }
`;
