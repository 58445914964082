import { useQuery } from 'react-query';
import { getProductItemFx, setProductItem } from 'models/catalog/model';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetBookItemQuery = (id: string) =>
  useQuery(
    'get_book_item',
    () => {
      return getProductItemFx(id);
    },
    {
      onSuccess: res => {
        // TODO: Доделать запрос после обновления бэкенда
        setProductItem(res.data.data);
      },
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
