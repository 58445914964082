export const LockIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.75 9C3.33579 9 3 9.33579 3 9.75V15C3 15.4142 3.33579 15.75 3.75 15.75H14.25C14.6642 15.75 15 15.4142 15 15V9.75C15 9.33579 14.6642 9 14.25 9H3.75ZM1.5 9.75C1.5 8.50736 2.50736 7.5 3.75 7.5H14.25C15.4926 7.5 16.5 8.50736 16.5 9.75V15C16.5 16.2426 15.4926 17.25 14.25 17.25H3.75C2.50736 17.25 1.5 16.2426 1.5 15V9.75Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 2.25C8.20435 2.25 7.44129 2.56607 6.87868 3.12868C6.31607 3.69129 6 4.45435 6 5.25V8.25C6 8.66421 5.66421 9 5.25 9C4.83579 9 4.5 8.66421 4.5 8.25V5.25C4.5 4.05653 4.97411 2.91193 5.81802 2.06802C6.66193 1.22411 7.80653 0.75 9 0.75C10.1935 0.75 11.3381 1.22411 12.182 2.06802C13.0259 2.91193 13.5 4.05653 13.5 5.25V8.25C13.5 8.66421 13.1642 9 12.75 9C12.3358 9 12 8.66421 12 8.25V5.25C12 4.45435 11.6839 3.69129 11.1213 3.12868C10.5587 2.56607 9.79565 2.25 9 2.25Z'
    />
  </svg>
);
