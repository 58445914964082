export const fakeData = [
  {
    id: '1',
    date: '15 декабря',
    operations: [
      {
        id: '1',
        name: 'Пригласи друга',
        bonusValue: '500',
        isPositive: true,
      },
      {
        id: '2',
        name: 'Добро пожаловать в БСМ',
        bonusValue: '1000',
        isPositive: true,
      },
    ],
  },
  {
    id: '2',
    date: '16 января',
    operations: [
      {
        id: '1',
        name: 'Пригласи друга',
        bonusValue: '500',
        isPositive: true,
      },
      {
        id: '2',
        name: 'Оплата заказа № 78968956',
        bonusValue: '3500',
        isPositive: false,
      },
    ],
  },
];
