import React from 'react';
import Ruble from 'components/common/Ruble';
import { formatPriceToString } from 'utilities/formatPriceToString';
import { SmallCatalogItemProps } from './types';
import {
  ImageWrap,
  Image,
  ItemWrap,
  Text,
  Price,
  Btn,
  CatalogItemTitle,
  StyledNoImage,
  DisabledItemWrap,
} from './styles';
import { useAddCartItemQuery } from 'queries/cart/useAddCartItemQuery';
import { Path } from 'constants/paths';
import { baseUrl } from 'api/apiClient';
import { useHistory } from 'react-router-dom';
import { CatalogItem } from 'models/catalog/types';
import { showAuthModal, useUserProfile } from 'models/auth/model';
//TODO: Вынести компонент в сторибук

export const SmallCatalogItem: React.FC<
  SmallCatalogItemProps & CatalogItem
> = ({
  price,
  buttonText,
  id,
  goodsType,
  disabled,
  detail,
  preview_text,
  name,
  has_proposals,
  ...rest
}) => {
  const mutation = useAddCartItemQuery();
  const history = useHistory();
  const user = useUserProfile();
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      showAuthModal();
      return;
    }
    if (!has_proposals) {
      mutation.mutate({ id, count: '1' });
    } else {
      history.push(
        goodsType === 'books'
          ? Path.catalogBooks + '/' + id
          : Path.catalogGoods + '/' + id
      );
    }
  };
  return disabled ? (
    <DisabledItemWrap>
      <ImageWrap>
        <>
          {detail ? (
            <Image src={baseUrl + detail} alt={name} />
          ) : (
            <StyledNoImage />
          )}
          {/*TODO: Заказчик просил убрать временно всякие ограничения по возрасту*/}
          {/*{restriction ? <Age age={restriction} /> : null}*/}
        </>
      </ImageWrap>
      <CatalogItemTitle>{name}</CatalogItemTitle>
      <Text>{preview_text}</Text>
      <Price>
        {formatPriceToString(price)} {Ruble}
      </Price>
      <Btn disabled={disabled}>{buttonText}</Btn>
    </DisabledItemWrap>
  ) : (
    <ItemWrap
      to={
        goodsType === 'books'
          ? `${Path.catalogBooks}/${id}`
          : `${Path.catalogGoods}/${id}`
      }
      {...rest}
    >
      <ImageWrap>
        <>
          {detail ? (
            <Image src={baseUrl + detail} alt={name} />
          ) : (
            <StyledNoImage />
          )}
          {/*TODO: Заказчик просил убрать временно всякие ограничения по возрасту*/}
          {/*{restriction ? <Age age={restriction} /> : null}*/}
        </>
      </ImageWrap>
      <CatalogItemTitle>{name}</CatalogItemTitle>
      <Text>{preview_text}</Text>
      <Price>
        {formatPriceToString(price)} {Ruble}
      </Price>
      <Btn onClick={handleClick} disabled={disabled}>
        {buttonText}
      </Btn>
    </ItemWrap>
  );
};
