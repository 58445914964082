import { useQuery, UseQueryResult } from 'react-query';
import { getIntensivesFx, setIntensives } from 'models/catalog/model';
import { AxiosError, AxiosResponse } from 'axios';
import { CatalogIntensive } from 'models/catalog/types';

export const useVipIntensivesQuery: () => UseQueryResult<
  AxiosResponse<{ data: CatalogIntensive[] }>,
  AxiosError<{ error: string }>
> = () =>
  useQuery('vipIntensives', () => getIntensivesFx(), {
    onSuccess: res => {
      setIntensives(res.data.data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
