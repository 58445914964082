import { useQuery, UseQueryResult } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { AxiosError, AxiosResponse } from 'axios';

export const useGetInstagramLinkQuery: () => UseQueryResult<
  AxiosResponse<{ data: string }>,
  AxiosError<{ error: string }>
> = () =>
  useQuery<
    unknown,
    AxiosError<{ error: string }>,
    AxiosResponse<{ data: string }>
  >('instagramLink', () => apiClient.get(Endpoint.INSTAGRAM));
