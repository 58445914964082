import styled from 'styled-components';
import { Tag } from 'ui/Tag';
import { AgeRestriction } from 'ui/AgeRestriction';

export const DateWrap = styled.div`
  margin-right: 40px;
  padding: 0 0 0 9px;
`;

export const Image = styled.img`
  border-radius: 14px;
  margin-left: 40px;
  margin-top: 22px;
  width: 140px;
  height: 96px;
  object-fit: cover;
`;

export const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 28px;
  width: 464px;
`;

export const TagItem = styled(Tag)``;

export const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 0;
  & ${TagItem} {
    margin: 0 5px;
  }
`;

export const Title = styled.span`
  ${({ theme }) => theme.typography.headline2};
`;

export const Address = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin-top: 50px;
`;

export const ItemWrap = styled.div<{ to?: string }>`
  cursor: pointer;
  margin-left: 12px;
  margin-right: 12px;
  padding: 40px 0;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  align-self: flex-start;
  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  }

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.actionColor};
  }
`;

export const DisabledItemWrap = styled.div`
  cursor: not-allowed;
  margin-left: 12px;
  margin-right: 12px;
  padding: 40px 0 40px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  align-self: flex-start;
  position: relative;
  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-color: ${({ theme }) => theme.colors.mainBgColor};
    opacity: 0.5;
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Age = styled(AgeRestriction)``;

export const Placement = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0;
`;
export const PlacementDigit = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const AttentionPlacement = styled.div`
  ${({ theme }) => theme.typography.statusText};
  align-self: flex-start;
  margin-top: auto;
  background-color: ${({ theme }) => theme.colors.bgStatusNotDoneColor};
  padding: 10px 20px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.thirdColor};
`;
