import { addReplyFx, setReply } from 'models/inner-insight/model';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import { addReplySchoolQuery } from './types';

export const useAddReplySchoolQuery: addReplySchoolQuery = () =>
  useMutation('addReply', data => addReplyFx(data), {
    onSuccess: res => {
      setReply(res.data.data);
    },
    onError: res => {
      const markError =
        typeof res.response?.data.error === 'object'
          ? res.response?.data.error.mark
          : '';
      const textError =
        typeof res.response?.data.error === 'object'
          ? res.response?.data.error.text
          : '';

      markError &&
        toast.error(
          <ErrorMessage
            text={typeof res.response?.data.error === 'object' ? markError : ''}
          />
        );
      textError &&
        toast.error(
          <ErrorMessage
            text={typeof res.response?.data.error === 'object' ? textError : ''}
          />
        );
      typeof res.response?.data.error !== 'object' &&
        toast.error(<ErrorMessage text={res.response?.data.error} />);
    },
  });
