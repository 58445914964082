import styled from 'styled-components';

export const Wrap = styled.div`
  margin-bottom: 40px;
`;

export const DateSC = styled.div`
  ${({ theme }) => theme.typography.bodyText2}
  font-weight:bold;
  margin-bottom: 24px;
`;

export const Operation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  ${({ theme }) => theme.typography.bodyText2}
`;

export const Value = styled.div`
  font-weight: bold;
`;
