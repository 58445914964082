import React from 'react';
import { Wrap, Text } from './styles';

export const MiniBanner: React.FC = props => {
  return (
    <Wrap {...props}>
      <Text>Живые мероприятия в&nbsp;самых красивых уголках планеты</Text>
    </Wrap>
  );
};
