import { IconBackgrounds, SocialNetworkIcon } from '../styles';
import { InstagramIcon, XCloseIcon } from 'icons';
import React, { ButtonHTMLAttributes } from 'react';
import { CloseIcon, StyledButton } from './styles';
import { Preloader } from 'ui/Preloader';

type InstagramConnectButtonProps = {
  instagramTag: string | null;
  handleDelete: () => void;
  isLoadingDelete: boolean;
};

export const InstagramConnectButton: React.FC<
  InstagramConnectButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ instagramTag, disabled, handleDelete, isLoadingDelete, onClick }) => {
  return (
    <>
      <StyledButton
        disabled={disabled}
        active={!!instagramTag}
        icon={
          isLoadingDelete ? (
            <Preloader />
          ) : (
            <SocialNetworkIcon
              isActive={!!instagramTag}
              iconBackground={IconBackgrounds.instagram}
            >
              {InstagramIcon}
            </SocialNetworkIcon>
          )
        }
        onClick={onClick}
        buttonType='link'
      >
        {instagramTag ? instagramTag : 'Привязать Instagram'}
      </StyledButton>
      {instagramTag && !isLoadingDelete ? (
        <CloseIcon onClick={handleDelete}>{XCloseIcon}</CloseIcon>
      ) : null}
    </>
  );
};
