import React from 'react';

export const RatingStarIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.30657 0.883611C9.4373 0.619556 9.70654 0.452148 10.0008 0.452148C10.2951 0.452148 10.5647 0.619556 10.695 0.883611L13.185 5.92956C13.2976 6.15781 13.5159 6.31615 13.7683 6.35283L19.3368 7.16182C19.6289 7.2041 19.8705 7.40861 19.9615 7.68863C20.0526 7.96865 19.9766 8.27672 19.7656 8.48209L15.7367 12.4101C15.5541 12.5879 15.4709 12.8442 15.5136 13.0953L16.4645 18.6417C16.5137 18.9325 16.3951 19.2259 16.156 19.3994C15.9174 19.572 15.602 19.5948 15.3406 19.4585L10.3598 16.8395C10.1345 16.7209 9.86488 16.7209 9.63923 16.8395L4.65843 19.4581C4.39783 19.5948 4.08156 19.5724 3.84297 19.3994C3.60437 19.2264 3.48528 18.9325 3.5349 18.6417L4.48584 13.0953C4.52899 12.8442 4.44529 12.5879 4.26278 12.4101L0.233784 8.48209C0.0227993 8.27628 -0.053138 7.96865 0.0379005 7.68863C0.128939 7.40861 0.370989 7.2041 0.663089 7.16182L6.2324 6.35283C6.48438 6.31615 6.7027 6.1578 6.81531 5.92913L9.30657 0.883611Z'
      fill='#FED74D'
    />
  </svg>
);

