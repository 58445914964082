import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateViewProps } from './types';

const DateWrap = styled.div`
  display: flex;
  text-decoration: none;
`;

const Day = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0;
  text-align: left;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.mainColor};
`;

const InnerWrap = styled.div`
  margin-left: 10px;
  display: block;
`;

const Divider = styled.div`
  margin: 3px 0;
  width: 1px;
  height: 28px;
  background-color: ${props => props.theme.colors.thirdTextAndIconColor};
`;

const Month = styled.p`
  margin: 0;
  ${props => props.theme.typography.bodyText1}
  text-transform: capitalize;
`;

const Time = styled.p`
  margin: 0;
  ${props => props.theme.typography.bodyText1}
  color: ${props => props.theme.colors.thirdTextAndIconColor};
`;

export const DateView: React.FC<DateViewProps> = ({ date }) => {
  const day = format(date, 'd'),
    month = format(date, 'MMMM', { locale: ru }),
    year = format(date, 'Y'),
    weekDay = format(date, 'EEEEEE', {
      locale: ru,
    }),
    time = format(date, 'p', {
      locale: ru,
    });

  return (
    <DateWrap>
      <Day>{day}</Day>
      <Divider />
      <InnerWrap>
        <Month>
          {month} {year}
        </Month>
        <Time>
          {weekDay.replace(/^\W/, s => s.toUpperCase())}, в {time}
        </Time>
      </InnerWrap>
    </DateWrap>
  );
};

export default DateView;
