import styled from 'styled-components';
import { Button } from 'ui/Button';

export const Wrapper = styled.div`
  width: 500px;
`;

export const EmptyOrderMessage = styled.div`
  ${({ theme }) => theme.typography.bodyText1};
  margin-bottom: 24px;
`;

export const Title = styled.span<{ isSubTitle?: boolean }>`
  ${({ theme, isSubTitle }) =>
    isSubTitle ? theme.typography.headline3 : theme.typography.headline2};
  margin-right: 24px;
`;

export const ButtonSC = styled(Button)`
  &.LineBack {
    stroke-width: 0px;
  }
  &.LineBack > span:nth-of-type(2) {
    margin-left: 0;
  }
`;

export const HeadInfo = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const TextSC = styled.div`
  margin-top: 16px;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${props => props.theme.colors.secondTextColor};
`;

export const ReferalLinkInput = styled.div`
  width: 500px;
  height: 48px;
  margin-bottom: 40px;
  background: ${props => props.theme.colors.secondBgColor};
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  svg {
    cursor: pointer;
    margin-right: 13px;
  }
  span {
    margin-left: 14px;
  }
`;

export const Products = styled.div`
  margin-top: 65px;
  margin-bottom: 60px;
`;

export const ProducsInfo = styled.div`
  margin-top: 25px;
  display: flex;
  position: relative;
  &:before {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};

    width: 100%;
    position: absolute;
    bottom: -24px;
  }
`;
export const Description = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.div`
  ${({ theme }) => theme.typography.bodyText1}
  font-weight: bold;
`;

export const Count = styled.div`
  ${({ theme }) => theme.typography.bodyText3}
  color: ${props => props.theme.colors.thirdTextAndIconColor};
`;

export const Price = styled.div`
  ${({ theme }) => theme.typography.bodyText1}
  font-weight: bold;
`;

export const InputSC = styled.input`
  border: none;
  outline: none;
  text-align: center;
  position: absolute;
  top: 10px;
  left: calc(250px - 163.11px / 2);
  ${({ theme }) => theme.typography.bodyText3};
`;

export const PeriodField = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
  & > label > svg {
    stroke-width: 20px;
  }
  &:before {
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
    width: 100%;
    position: absolute;
    top: 34px;
  }
  margin-bottom: 24px;
`;

export const SelectPeriodButton = styled(Button)`
  display: flex;
  position: relative;
  top: 6px;
  span {
    ${({ theme }) => theme.typography.bodyText3};
    text-color: red;
  }
  & > span:nth-of-type(2) {
    margin-left: 0px !important ;
  }
  svg {
    stroke-width: 0;
  }
`;

export const RightBannerColumn = styled.div`
  margin-left: 57px;
  & > img {
    cursor: pointer;
  }
`;
export const Content = styled.div`
  display: flex;
`;

export const Help = styled.div`
  display: flex;
  svg {
    width: 24px;
    height: 24px;
  }
  a {
    margin-left: 8.83px;
    margin-top: 3px;
    color: ${({ theme }) => theme.colors.linksColor};
  }
`;
