import { IconBackgrounds, SocialNetworkIcon } from 'components/common/styles';
import { TelegramIcon, XCloseIcon } from 'icons';
import { Button } from 'ui/Button';
import React, { useState } from 'react';
import {
  ModalButton,
  ModalIcon,
  ModalTitle,
  StyledModal,
  StyledPreloader,
  UsernameInput,
  CloseIcon,
} from './styles';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useUpdateUserProfile } from 'queries/account/myData/useUpdateUserProfile';
import { useUserProfileQuery } from 'queries/auth/useUserProfileQuery';
import { AxiosError } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';
import { formatValidationErrors } from 'utilities/formatValidationErrors';
import { SuccessMessage } from 'ui/SuccessMessage';
import { toast } from 'react-toastify';

export const TelegramButton: React.FC<{ telegram?: string | null }> = ({
  telegram,
}) => {
  const [visible, setVisible] = useState(false);
  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);
  const { refetch } = useUserProfileQuery();
  const updateProfileQuery = useUpdateUserProfile();

  const handleSubmitForm = async (values: { telegram: string }) => {
    return await new Promise((resolve, reject) => {
      return updateProfileQuery.mutate(
        {
          telegram: values.telegram,
        },
        {
          onSuccess: res => {
            closeModal();
            toast.success(
              <SuccessMessage text={'Ваш телеграм успешно привязан!'} />
            );
            refetch();
            resolve(res);
          },
          onError: reject,
        }
      );
    }).catch((err: AxiosError<{ error: ServerValidationError }>) => {
      return formatValidationErrors(err.response?.data.error);
    });
  };

  const handleDelete = async () => {
    return await new Promise(resolve => {
      return updateProfileQuery.mutate(
        { telegram: null },
        {
          onSuccess: res => {
            toast.success(
              <SuccessMessage text={'Ваш телеграм успешно отвязан!'} />
            );
            refetch();
            resolve(res);
          },
        }
      );
    });
  };

  return (
    <>
      <Button
        icon={
          <SocialNetworkIcon
            isActive={!!telegram}
            iconBackground={IconBackgrounds.telegram}
          >
            {TelegramIcon}
          </SocialNetworkIcon>
        }
        buttonType='link'
        onClick={() => !telegram && openModal()}
      >
        {telegram ?? 'Привязать Telegram'}
      </Button>
      {telegram && !updateProfileQuery.isLoading ? (
        <CloseIcon onClick={handleDelete}>{XCloseIcon}</CloseIcon>
      ) : null}
      {visible ? (
        <StyledModal handleClose={closeModal}>
          <ModalIcon>{TelegramIcon}</ModalIcon>
          <ModalTitle>Привязать Telegram</ModalTitle>
          <Form
            onSubmit={handleSubmitForm}
            render={(props: FormRenderProps<{ telegram: string }>) => {
              return (
                <form onSubmit={props.handleSubmit}>
                  <Field
                    name='telegram'
                    label='Введите @username'
                    direction={'vertical'}
                    inputWidth={340}
                    placeholder={'Используйте символ «@»'}
                    component={UsernameInput}
                  />
                  {updateProfileQuery.isLoading ? (
                    <StyledPreloader />
                  ) : (
                    <ModalButton type={'submit'} size={'large'}>
                      Привязать
                    </ModalButton>
                  )}
                </form>
              );
            }}
          />
        </StyledModal>
      ) : null}
    </>
  );
};
