import { Modal } from 'ui/Modal';
import React from 'react';
import { PopupSuccessIcon } from 'icons/';
import { Icon, Title, Text, Wrapper } from './styled';

interface IProps {
  closeModal: () => void;
}

export const SuccessChangeModal: React.FC<IProps> = ({ closeModal }) => {
  return (
    <Modal handleClose={closeModal}>
      <Wrapper>
        <Icon>{PopupSuccessIcon}</Icon>
        <Title>Пароль успешно изменен!</Title>
        <Text>Используйте его каждый раз при входе</Text>
      </Wrapper>
    </Modal>
  );
};
