import React from 'react';
import styled from 'styled-components';
import { AgeRestrictionProps } from './types';

const AgeRestrictionText = styled.span`
  ${({ theme }) => theme.typography.bodyText3};
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.mainColor};
`;

const AgeRestrictionWrap = styled.div`
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 6px 5px 6px 7px;
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.secondColor};
`;

const AgeRestriction: React.FC<AgeRestrictionProps> = ({ age, ...props }) => {
  return (
    <AgeRestrictionWrap {...props}>
      <AgeRestrictionText>
        {typeof age === 'number' ? `${age}+` : age}
      </AgeRestrictionText>
    </AgeRestrictionWrap>
  );
};

export default AgeRestriction;
