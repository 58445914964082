import React from 'react';
import {
  ButtonSC,
  Title,
  HeadInfo,
  Wrapper,
  TrackInfo,
  TrackCode,
  Detail,
  DetailHeeader,
  DetailText,
  Products,
  ProducsInfo,
  Description,
  PriceList,
  Product,
  Bonus,
  Result,
  Name,
  Count,
  Price,
  NoData,
  PreloaderSC,
  Preview,
  Delivery,
} from './styled';
import { ChevronLeftIcon } from 'icons';
import { OrderState } from '../OrderItem/styled';
import { AttentionSmallIcon } from 'icons/AttentionSmallIcon';
import CopyIcon from 'icons/CopyIcon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, RouteComponentProps } from 'react-router-dom';
import { usePreviousUrl } from 'hooks/usePreviousUrl';
import { NotAcceptedOrderStatuses } from 'models/orders/types';
import { useGetOrderInfoQuery } from 'queries/orders/useGetOrderInfoQuery';
import { baseUrl } from 'api/apiClient';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateFormats } from 'constants/dateFormats';
import Ruble from 'components/common/Ruble';
import { SuccessMessage } from 'ui/SuccessMessage';
import { toast } from 'react-toastify';

export const OrderInfo: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const previousUrl = usePreviousUrl();
  const { data, isLoading } = useGetOrderInfoQuery(match.params.id);

  const isAcceptedStatus =
    !!data?.status &&
    !Object.values(NotAcceptedOrderStatuses).includes(
      data.status as NotAcceptedOrderStatuses
    );

  const handleCopyLink = () =>
    toast.success(
      <SuccessMessage text={'Трек-номер скопирован в буфер обмена'} />
    );

  return isLoading ? (
    <PreloaderSC />
  ) : (
    <Wrapper>
      <Link to={previousUrl}>
        <ButtonSC className='LineBack' icon={ChevronLeftIcon} buttonType='link'>
          К списку заказов
        </ButtonSC>
      </Link>
      {!data ? (
        <NoData>Нет данных</NoData>
      ) : (
        <>
          <HeadInfo>
            <Title>{`Заказ №${data.id}`}</Title>
            <OrderState approved={isAcceptedStatus}>
              {!isAcceptedStatus && AttentionSmallIcon}
              {data.status}
            </OrderState>
          </HeadInfo>
          <Title isSubTitle>{'Данные доставки'}</Title>
          {(!data.delivery.payer_data ||
            data.delivery.name === 'Без доставки') && (
            <DetailText>Без доставки</DetailText>
          )}
          {data.delivery.tracking_number && (
            <>
              <TrackInfo>
                Используйте этот трек-номер для отслеживания заказа на сайте
                <a href='https://www.cdek.ru/'> www.cdek.ru/ru/tracking</a>
              </TrackInfo>
              <TrackCode>
                <span>{data.delivery.tracking_number}</span>
                <CopyToClipboard text={String(data.delivery.tracking_number)} onCopy={handleCopyLink}>
                  {CopyIcon}
                </CopyToClipboard>
              </TrackCode>
            </>
          )}
          <div>
            <Detail>
              <DetailHeeader>Дата оформления</DetailHeeader>
              <DetailText>
                {format(
                  new Date(data.date_insert),
                  DateFormats['StartEventDate'],
                  {
                    locale: ru,
                  }
                )}
              </DetailText>
            </Detail>
            <Detail>
              <DetailHeeader>Способ оплаты</DetailHeeader>
              <DetailText>{data.pay_system}</DetailText>
            </Detail>
            {/* TODO когда будет ясно с доставкой и самовывозов, вернуться сюда и переделать блок data.payer_data.address && (...)  */}
            {data.delivery.payer_data && data.delivery.name !== 'Без доставки' && (
              <Detail>
                <DetailHeeader>{data.delivery.name}</DetailHeeader>
                <DetailText>{data.delivery.payer_data.address}</DetailText>
              </Detail>
            )}

            {data.delivery.payer_data && (
              <Detail>
                <DetailHeeader>Получатель</DetailHeeader>
                <DetailText>
                  {`${data.delivery.payer_data.payer_name}, ${data.delivery.payer_data.phone},
                    ${data.delivery.payer_data.email}`}
                </DetailText>
              </Detail>
            )}
          </div>

          <Products>
            <Title isSubTitle>{'Состав заказа'}</Title>
            {data.basket.map(basketItem => (
              <ProducsInfo key={basketItem.id}>
                <Preview>
                  <img
                    src={baseUrl + basketItem.image || ''}
                    alt={basketItem.name}
                  />
                </Preview>
                <Description>
                  <Name>{basketItem.name}</Name>
                  <Count>{`${basketItem.quantity} ${basketItem.measure}`}</Count>
                  <Price>{basketItem.price} ₽</Price>
                </Description>
              </ProducsInfo>
            ))}
            <PriceList>
              <Product>
                <span>Товар</span>
                <span>
                  {data.delivery.delivery_price
                    ? data.price - data.delivery.delivery_price
                    : data.price}
                  {Ruble}
                </span>
              </Product>
              <Bonus>
                <span>Оплата бонусами</span>
                <span>-{data.discount} ₽</span>
              </Bonus>
              <Delivery>
                <span>Стоимость доставки</span>
                <span>{data.delivery.delivery_price} ₽</span>
              </Delivery>
              <Result>
                <span>Итого</span>
                <span>{data.price} ₽</span>
              </Result>
            </PriceList>
          </Products>
        </>
      )}
    </Wrapper>
  );
};
