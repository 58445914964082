import React from 'react';
import {
  CoursesIcon,
  TreningsIcon,
  RasstanovkiIcon,
  VipIcon,
  CouchingIcon,
} from 'icons/';
import { CategoriesProps } from 'interfaces/CategoriesProps';
import { EducationCategoriesKey } from './types';
import { CategoryWrap, StyledLink } from './styles';
import { Path } from 'constants/paths';
import { CategoryButton } from 'ui/CategoryButton';
import { usePlansCategoriesQuery } from 'queries/education/usePlansCategoriesQuery/usePlansCategoriesQuery';
import { usePlansCategories } from 'models/education/model';
import { Preloader } from 'ui/Preloader';

export const categoriesIcons: Record<string, React.ReactElement> = {
  courses: CoursesIcon,
  trainings: TreningsIcon,
  events: RasstanovkiIcon,
  'vip-club': VipIcon,
  coach_school: CouchingIcon,
};

export const Categories: React.FC<CategoriesProps<EducationCategoriesKey>> = ({
  categoryKey,
  ...rest
}) => {
  const plansQuery = usePlansCategoriesQuery();
  const categories = usePlansCategories();
  return (
    <CategoryWrap {...rest}>
      {plansQuery.isLoading ? (
        <Preloader />
      ) : (
        categories &&
        Object.keys(categories).map((key, index) => {
          return (
            <StyledLink key={index} to={`${Path.education}/${key}`}>
              <CategoryButton
                isActive={categoryKey === key}
                icon={categoriesIcons[key]}
              >
                {categories[key]}
              </CategoryButton>
            </StyledLink>
          );
        })
      )}
    </CategoryWrap>
  );
};
