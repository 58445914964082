import React from 'react';
import memberPhoto1 from 'images/photo_member_1.png';
import memberPhoto2 from 'images/photo_member_2.png';
import {
  Wrap,
  Title,
  Text,
  Members,
  MembersItem,
  Photo,
  Inner,
  ItemTitle,
  ItemText,
} from './styles';

export const Description: React.FC = props => {
  return (
    <Wrap {...props}>
      <Title>Описание</Title>
      <Text>
        В Вашем распоряжении – отдельный чат с Владимиром Барановым и Алиной
        Ермаковой продолжительностью 24 часа.
      </Text>
      <Members>
        <MembersItem>
          <Photo src={memberPhoto1} alt={'Владимир Баранов'} />
          <Inner>
            <ItemTitle>Владимир Баранов</ItemTitle>
            <ItemText>
              Креативный директор <br />
              проекта @budsmuzhikom
            </ItemText>
          </Inner>
        </MembersItem>
        <MembersItem>
          <Photo src={memberPhoto2} alt={'Алина Ермакова'} />
          <Inner>
            <ItemTitle>Алина Ермакова</ItemTitle>
            <ItemText>
              Директор проекта
              <br /> @budsmuzhikom
            </ItemText>
          </Inner>
        </MembersItem>
      </Members>
    </Wrap>
  );
};
