import React from 'react';

export const MapPinIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 1.5C7.4087 1.5 5.88258 2.13214 4.75736 3.25736C3.63214 4.38258 3 5.9087 3 7.5C3 9.82354 4.5118 12.0782 6.16946 13.8279C6.98296 14.6866 7.799 15.3896 8.41251 15.8781C8.64084 16.0599 8.84027 16.2114 9 16.3294C9.15973 16.2114 9.35916 16.0599 9.58749 15.8781C10.201 15.3896 11.017 14.6866 11.8305 13.8279C13.4882 12.0782 15 9.82354 15 7.5C15 5.9087 14.3679 4.38258 13.2426 3.25736C12.1174 2.13214 10.5913 1.5 9 1.5ZM9 17.25C8.58397 17.874 8.58379 17.8739 8.58357 17.8738L8.58143 17.8723L8.57643 17.869L8.55924 17.8574C8.54464 17.8474 8.52381 17.8332 8.49717 17.8147C8.4439 17.7778 8.36735 17.7241 8.27084 17.6544C8.07791 17.5151 7.80477 17.3117 7.47812 17.0516C6.826 16.5323 5.95454 15.7821 5.08054 14.8596C3.3632 13.0468 1.5 10.4265 1.5 7.5C1.5 5.51088 2.29018 3.60322 3.6967 2.1967C5.10322 0.790176 7.01088 0 9 0C10.9891 0 12.8968 0.790176 14.3033 2.1967C15.7098 3.60322 16.5 5.51088 16.5 7.5C16.5 10.4265 14.6368 13.0468 12.9195 14.8596C12.0455 15.7821 11.174 16.5323 10.5219 17.0516C10.1952 17.3117 9.92209 17.5151 9.72916 17.6544C9.63265 17.7241 9.5561 17.7778 9.50283 17.8147C9.47619 17.8332 9.45536 17.8474 9.44076 17.8574L9.42357 17.869L9.41857 17.8723L9.41699 17.8734C9.41678 17.8735 9.41603 17.874 9 17.25ZM9 17.25L9.41603 17.874C9.1641 18.042 8.83549 18.0417 8.58357 17.8738L9 17.25Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 6C8.17157 6 7.5 6.67157 7.5 7.5C7.5 8.32843 8.17157 9 9 9C9.82843 9 10.5 8.32843 10.5 7.5C10.5 6.67157 9.82843 6 9 6ZM6 7.5C6 5.84315 7.34315 4.5 9 4.5C10.6569 4.5 12 5.84315 12 7.5C12 9.15685 10.6569 10.5 9 10.5C7.34315 10.5 6 9.15685 6 7.5Z'
    />
  </svg>
);

