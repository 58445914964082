import styled from 'styled-components';

type TNewsWrapper = {
  image: string;
  contained: boolean;
};

export const NewsDate = styled.span`
  ${({ theme }) => `${theme.typography.tabsText};
  color: ${theme.colors.thirdTextAndIconColor};
  `}
`;

export const NewsItemTitle = styled.div`
  ${({ theme }) => theme.typography.headline3};
  margin-top: 16px;
  max-width: 100%;
  cursor: pointer;
`;

export const NewsItemPreviewText = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin-top: 8px;
  max-width: 100%;
`;

export const ImageWrap = styled.div`
  height: 200px;
  border-radius: 14px;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ItemWrap = styled.div<TNewsWrapper>`
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;
  ${({ contained, theme, image }) =>
    contained &&
    `height: 373px;
${
  image
    ? `background: linear-gradient(180deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.6) 80%), url(${image})`
    : `background-color: ${theme.colors.actionColor}`
};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 22px 22px 30px;
    border-radius: 14px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    ${NewsDate} {
          color: ${
            contained && !image
              ? theme.colors.thirdTextAndIconColor
              : theme.colors.secondColor
          };

  };
    ${NewsItemTitle} {
      color: ${
        contained && !image ? theme.colors.mainColor : theme.colors.mainBgColor
      };
  };
  };`}
`;

export const ContainedImage = styled.div`
  position: absolute;
  top: 30px;
  left: 22px;
  right: 0;
  bottom: 0;
`;
