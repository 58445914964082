import React, { useMemo } from 'react';
import { GiftIcon } from 'icons/';
import {
  Title,
  BonusTitle,
  AdditionalTitle,
  List,
  Wrap,
  ListItem,
  Present,
  PresentText,
  PresentIcon,
  AccentPresentText,
} from './styles';
import { LessonsProps } from './types';
import { TCourseStatuses, TLecture } from 'models/courses/types';
import groupBy from 'lodash/groupBy';
import { getAvailableStatus } from './helpers';
import { Lecture, LectureType } from 'models/inner-insight/types';
import { Dictionary } from 'lodash';
import { Divider } from 'components/Lesson/Content/styles';

const renderLectures = (
  lectures: TLecture[],
  courseId: number,
  courseStatus?: TCourseStatuses
) =>
  lectures.map(
    ({
      id: lectureId,
      date_start,
      is_bonus,
      name,
      price,
      materials,
      picture,
      activity,
      available,
    }) => {
      const isAvailable = getAvailableStatus(
        available,
        is_bonus,
        date_start,
        courseStatus
      );

      return (
        activity && (
          <ListItem
            key={lectureId}
            courseId={courseId}
            id={lectureId}
            date_start={date_start}
            is_bonus={is_bonus}
            name={name}
            price={price}
            materials={materials}
            picture={picture}
            isAvailable={isAvailable}
          />
        )
      );
    }
  );

const renderCoachingLectures = (
  lectures: Dictionary<Lecture[]>,
  courseId: number
) => {
  const elements = [];

  for (const property in lectures) {
    elements.push(
      <>
        <Divider />
        <Title>{property === 'true' ? 'Темы' : 'Контроль'}</Title>
        <List>
          {lectures[property].map(item => (
            <ListItem
              key={item.id}
              courseId={courseId}
              id={item.id}
              date_start={item.date_start}
              name={item.name}
              materials={item.materials}
              picture={item.picture}
              isAvailable={item.activity}
              isSchool
              status={item.homework_status}
            />
          ))}
        </List>
      </>
    );
  }
  return elements;
};

export const Lessons: React.FC<LessonsProps> = ({
  lectures,
  additionals,
  active,
  id,
  isSchool,
  ...props
}) => {
  const { bonusLectures, regularLectures } = useMemo(
    () =>
      groupBy(lectures, ({ is_bonus }) =>
        is_bonus ? 'bonusLectures' : 'regularLectures'
      ),
    [lectures]
  );

  const coachLectures = groupBy(
    lectures as Lecture[],
    item =>
      LectureType[(item.type as unknown) as keyof typeof LectureType] ===
      LectureType['lecture']
  );

  return (
    <Wrap id='lessons' {...props}>
      {isSchool ? (
        renderCoachingLectures(coachLectures, id)
      ) : regularLectures?.length ? (
        <>
          <Title>Темы</Title>
          <List>{renderLectures(regularLectures, id, active?.status)}</List>
        </>
      ) : null}

      {bonusLectures?.length ? (
        <>
          <BonusTitle>Бонусные темы</BonusTitle>
          <List> {renderLectures(bonusLectures, id, active?.status)}</List>
          <Present>
            <PresentIcon>{GiftIcon}</PresentIcon>
            <PresentText>
              Получите бонусные темы{' '}
              <AccentPresentText>в подарок </AccentPresentText>
              за выполнение всех домашних заданий
            </PresentText>
          </Present>
        </>
      ) : null}

      {additionals?.length ? (
        <>
          <AdditionalTitle>Дополнительные материалы</AdditionalTitle>
          <List>{renderLectures(additionals, id, active?.status)}</List>
        </>
      ) : null}
    </Wrap>
  );
};
