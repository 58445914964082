import React from 'react';
import { Inner, Wrap, Text, Title, StyledButton } from './styles';

export const Banner: React.FC = props => {
  return (
    <Wrap {...props}>
      <Inner>
        <Title>VIP-клуб</Title>
        <Text>
          Уникальная по эффективности программа в рамках проекта
          «БУДЬ&nbsp;С&nbsp;МУЖИКОМ»
        </Text>
        <StyledButton size='large'>Вступить в клуб</StyledButton>
      </Inner>
    </Wrap>
  );
};
