import React from 'react';
import { MonthMiniatureProps } from '../../MonthPage/types';
import { Path } from 'constants/paths';
import { useParams } from 'react-router';
import {
  MonthDaysWrap,
  MonthTitle,
  WeekDayTitle,
  DayDate,
  EventLine,
  MonthDayCell,
  MonthDayWeekCell,
  MonthHeader,
} from './styles';
import { ArrowButton } from 'ui/ArrowButton';
import { Link } from 'react-router-dom';

export const MonthMiniature: React.FC<MonthMiniatureProps> = ({
  days,
  title,
  handleMonthChange,
  ...rest
}) => {
  const { year, month, day } = useParams<{
    year: string;
    month: string;
    day?: string;
  }>();
  return (
    <div {...rest}>
      <MonthHeader>
        <ArrowButton
          size={'small'}
          direction={'left'}
          onClick={() => handleMonthChange('prev')}
        />
        <MonthTitle to={`${Path.calendar}/${year}/month/${month}/`}>
          {title}
        </MonthTitle>
        <ArrowButton
          size={'small'}
          direction={'right'}
          onClick={() => handleMonthChange('next')}
        />
      </MonthHeader>
      <MonthDaysWrap>
        <MonthDayWeekCell>
          <WeekDayTitle>Пн</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Вт</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Ср</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Чт</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Пт</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Сб</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Вс</WeekDayTitle>
        </MonthDayWeekCell>
        {days.map((item, idx) => {
          return (
            <MonthDayCell
              key={idx}
              isSelected={item.day === day}
              as={item.day ? Link : 'div'}
              to={item.day ?? ''}
            >
              <DayDate>{item.day}</DayDate>
              {item.events?.map((item, index) => (
                <EventLine key={index} {...item} />
              ))}
            </MonthDayCell>
          );
        })}
      </MonthDaysWrap>
    </div>
  );
};
