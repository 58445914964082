import styled from 'styled-components';
import { Button } from 'ui/Button';
import { IconBackgrounds, SocialNetworkIcon } from '../styles';

export const StyledButton = styled(Button)<{ active: boolean }>`
  & ${SocialNetworkIcon} {
    ${({ active, theme }) =>
      active
        ? `background: ${IconBackgrounds.instagram}; & > svg {fill: ${theme.colors.mainBgColor}}`
        : null}
  }
  &:hover ${SocialNetworkIcon} {
    ${({ active, theme }) =>
      active ? `& > svg {fill: ${theme.colors.secondColor};}` : null}
  }
`;

export const CloseIcon = styled.div`
  font-size: 0;
  margin: 0 0 0 4px;
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  transition: background-color 0.3s;
  &:hover {
    transition: background-color 0.3s;
    background-color: ${({ theme }) => theme.colors.secondBgColor};
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;
