import React, { useEffect } from 'react';
import { ErrorMessage } from '../ui/ErrorMessage';
import { toast } from 'react-toastify';

export const usePaypalQuery: (
  options: {
    value: string;
    buttonUrl?: string;
  },
  handlePayment: (paypal_order_id: string, paypal_authorize_id: string) => void
) => unknown = (options, handlePayment) => {
  useEffect(() => {
    if (options?.buttonUrl) {
      const script = document.createElement('script');
      script.setAttribute('data-sdk-integration-source', 'button-factory');
      script.src = options.buttonUrl;
      script.async = true;
      script.onload = () => {
        // @ts-expect-error paypal не предоставляет интерфейсов
        window.paypal
          .Buttons({
            style: {
              shape: 'rect',
              color: 'silver',
              layout: 'horizontal',
              label: 'paypal',
            },
            createOrder: function (data: unknown, actions: unknown) {
              //формирование оплаты. Отрабатывает при нажатии на кнопку, открывается окно с iframe и подтверждении захватываются средства
              // @ts-expect-error paypal не предоставляет интерфейсов
              return actions.order.create({
                purchase_units: [
                  { amount: { currency_code: 'RUB', value: options.value } },
                ],
              });
            },
            onApprove: function (data: unknown, actions: unknown) {
              //после того как средства заморозили, отправляем на бек запрос в очередь на обработку.
              // @ts-expect-error paypal не предоставляет интерфейсов
              actions.order.authorize().then(function (authorization: unknown) {
                // Get the authorization id
                const authorizationID =
                  // @ts-expect-error paypal не предоставляет интерфейсов
                  authorization.purchase_units[0].payments.authorizations[0].id;

                // @ts-expect-error paypal не предоставляет интерфейсов
                handlePayment(data.orderID, authorizationID);
              });
            },

            onError: function (err: unknown) {
              //TODO: тут нужно будет обработать ошибки, например при не достатке средств.
              // В данном случае на бек ничего не отправляется. Нужно будет воспроизвести такую ошибку.
              // нужно доработать

              // @ts-expect-error paypal не предоставляет интерфейсов
              if (err.response?.data.error) {
                // @ts-expect-error paypal не предоставляет интерфейсов
                toast.error(<ErrorMessage text={err.response.data.error} />);
              }
            },
          })
          .render('#paypal-button-container');
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [handlePayment, options]);
};
