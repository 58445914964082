export enum Path {
  // ЛИЧНЫЙ КАБИНЕТ
  'account' = '/account',
  'myOrders' = '/account/orders',

  // ДИПЛОМ
  'diploma' = '/diploma',

  // КАТАЛОГ
  'catalog' = '/catalog',
  'catalogBooks' = '/catalog/books',
  'catalogGoods' = '/catalog/goods',
  'catalogGiftCards' = '/catalog/gift-cards',
  'catalogGiftCardsDetail' = '/catalog/gift-cards/detail',
  'catalogCourses' = '/catalog/courses',
  'catalogEvents' = '/catalog/events',
  'catalogTrainings' = '/catalog/trainings',
  'catalogVipChat' = '/catalog/vip-chat',
  'catalogVipClub' = '/catalog/vip-club',
  'catalogInnerInsight' = '/catalog/inner-insight',
  'catalogFree' = '/catalog/free',
  'catalogSecret' = '/catalog/secret',
  'vipClubMeetings' = '/catalog/vip-club/meetings',

  // МЕРОПРИЯТИЯ
  'event' = '/event',

  // КУРС
  'course' = '/course',

  // КОРЗИНА, ДОСТАВКА, ОПЛАТА
  'buy' = '/buy',
  'directBuy' = '/buy/:key/order',

  // УРОКИ, ТЕМЫ
  'lesson' = '/lesson',

  // О КОМПАНИИ
  'about' = '/about',

  // МОЕ ОБУЧЕНИЕ
  'education' = '/education',

  // КАЛЕНДАРЬ
  'calendar' = '/calendar',
  'month' = '/month',

  // РАСПРОДАЖИ
  'sales' = '/sales',

  // НОВОСТИ
  'news' = '/news',

  // УВЕДОМЛЕНИЯ
  'notifications' = '/notifications',

  // УСПЕШНАЯ ОПЛАТА СБЕРБАНК
  'sberbankSuccess' = '/sberbank/success-regular',

  // УСПЕШНАЯ ОПЛАТА СБЕРБАНК
  'instagramSuccess' = '/instagram/oauth/',

  // ШКОЛА КОУЧИНГА
  'school' = '/school',
  'coaching' = '/coaching',

  // МАСТЕР-КЛАССЫ
  'masterClass' = '/master-class',
}
