import React from 'react';
import { format, differenceInCalendarMonths } from 'date-fns';
import { ru } from 'date-fns/locale';
import decor_01 from 'images/circle_decor_01.svg';
import decor_02 from 'images/circle_decor_02.svg';
import {
  Wrap,
  Frame,
  BoldText,
  Text,
  Header,
  HeaderDigit,
  HeaderText,
} from './styles';
import { TCoachingSchool } from 'models/inner-insight/types';
import { DateFormats } from 'constants/dateFormats';
import { declension } from 'utilities/declension';

function getShcoolDuration(start: Date, end: Date) {
  return differenceInCalendarMonths(new Date(end), new Date(start));
}

export const Announcement: React.FC<{
  school: TCoachingSchool;
  className?: string;
}> = ({ school, className }) => {
  return (
    <Wrap className={className}>
      <Frame>
        <Header
          style={{
            backgroundImage: `url(${decor_01})`,
          }}
        >
          <HeaderDigit style={{ marginLeft: 32, marginRight: 25 }}>
            {getShcoolDuration(school.date_start, school.date_end)}
          </HeaderDigit>
          <HeaderText>месяцев</HeaderText>
        </Header>
        <Text>
          Длительность обучения в&nbsp;международной академии трансперсонального
          коучинга «Внутреннее вдохновение»
        </Text>
      </Frame>
      <Frame>
        <Header style={{ backgroundImage: `url(${decor_02})` }}>
          <HeaderDigit style={{ marginLeft: 27, marginRight: 30 }}>
            {school.flow_number}
          </HeaderDigit>
          <HeaderText>
            {declension(['поток', 'потока', 'потоков'], school.flow_number)}
          </HeaderText>
        </Header>
        <Text>
          Старт курса
          <BoldText>
            {format(
              new Date(school.date_start),
              DateFormats['StandAloneDate'],
              {
                locale: ru,
              }
            )}
          </BoldText>
        </Text>
      </Frame>
    </Wrap>
  );
};
