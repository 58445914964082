import React from 'react';
import { DateView } from 'ui/Date';
import { Address, DateWrap, MainWrap, Title, Image, ItemWrap } from './styles';
import { NoImage } from 'ui/NoImage';
import { baseUrl } from 'api/apiClient';
import { PlanEntityType } from 'models/education/types';

export const MasterClassItem: React.FC<PlanEntityType> = ({
  name,
  place,
  date_event = '',
  picture,
}) => {
  const datetime = !!date_event && new Date(date_event);
  return (
    <ItemWrap>
      <DateWrap>
        {datetime ? <DateView date={datetime} /> : null}
        {picture ? <Image src={baseUrl + picture} alt={name} /> : <NoImage />}
      </DateWrap>
      <MainWrap>
        <Title>{name}</Title>
        <Address>{place}</Address>
      </MainWrap>
    </ItemWrap>
  );
};
