import React from 'react';
import { Link } from 'react-router-dom';
import { Wrap, BannerTitle, BannerSubTitle, BannerButton } from './styles';
import { CatalogPromoItem } from 'models/sales/types';

export const Banner: React.FC<
  CatalogPromoItem & { buttonText?: string; showButton: boolean }
> = ({
  id,
  name,
  preview,
  pictures,
  buttonText = 'Подробнее',
  showButton,
  ...rest
}) => {
  return (
    <Wrap image={pictures?.[0]?.path} {...rest}>
      <BannerTitle>{name}</BannerTitle>
      <BannerSubTitle>{preview}</BannerSubTitle>
      {showButton ? (
        <Link to={`/sales/${id}`}>
          <BannerButton buttonType='secondary'>{buttonText}</BannerButton>
        </Link>
      ) : null}
    </Wrap>
  );
};
