import { useStore } from 'effector-react';
import { $news } from 'models/news/model';
import { NewsListItem } from 'models/news/types';
import { useNewsQuery } from 'queries/news/useNewsQuery';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { NewsItem } from '../NewsItem';
import { NewsWrap, PreloaderSC, Title, Wrap } from './styled';

export const RecommendedNews: React.FC<{ id: number }> = ({ id, ...rest }) => {
  const { isLoading } = useNewsQuery({
    offset: 0,
    limit: 12,
  });
  const news = useStore($news);
  const recommendedNews = useMemo(
    () =>
      news
        .reduce((acc, curr) => {
          if (curr.id !== id) {
            acc.push(curr);
          }
          return acc;
        }, [] as NewsListItem[])
        .slice(-4),
    [id, news]
  );
  return isLoading ? (
    <PreloaderSC />
  ) : recommendedNews.length ? (
    <Wrap {...rest}>
      <NewsWrap>
        <Title>С этой новостью читают</Title>
        {recommendedNews.map((item: NewsListItem, index: number) => (
          <Link key={item.id} to={`/news/${item.id}`}>
            <NewsItem item={item} contained={index === 2} />
          </Link>
        ))}
      </NewsWrap>
    </Wrap>
  ) : null;
};
