import { useQuery } from 'react-query';
import { AddressesQuery } from './types';
import { getAddressesFx, setAddresses } from 'models/addresses/model';

export const useGetAddressesQuery: AddressesQuery = () =>
  useQuery('addresses', () => getAddressesFx(), {
    onSuccess: res => {
      setAddresses(res.data.data);
    },
    refetchOnWindowFocus: false,
  });
