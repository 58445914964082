import { ActionButton } from 'ui/ActionButton';
import styled from 'styled-components';
import { Button } from 'ui/Button';

export const Wrap = styled.div`
  margin-top: 24px;
  width: 500px;
  height: 200px;
  display: flex;
  align-items: start;
`;

export const ShareButton = styled(ActionButton)`
  margin-left: 16px;
  & > span > svg {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

export const Description = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline3};
  margin-bottom: 8px;
`;

export const Value = styled.div`
  ${({ theme }) => theme.typography.bodyText1};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin-bottom: 24px;
`;

export const State = styled.div`
  width: 150px;
  padding: 4px 11px 4px 11px;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 26px;
  background: ${({ theme }) => theme.colors.statusDoneLightColor};
  color: white;
  border-radius: 6px;
  & > svg {
    fill: ${({ theme }) => theme.colors.mainBgColor};
    margin-right: 6px;
  }
`;

export const DateSC = styled.div`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin-top: 12px;
`;

export const Actions = styled.div`
  margin-top: 21px;
  display: flex;
  align-items: center;
`;

export const ActivateButton = styled(Button)`
  height: 40px;
`;

export const ImgWrap = styled.div`
  overflow: hidden;
  border-radius: 14px;
  width: 240px;
  height: 140px;
`;

export const ImgSC = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
