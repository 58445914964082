import { createEffect, createEvent, createStore } from 'effector';
import { AxiosError, AxiosResponse } from 'axios';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import {
  AccumulatedBonuses,
  Calculation,
  DeliveryOptions,
  DiscountCalculationRequest,
  Order,
  OrderId,
  PaypalPaymentRequest,
  SberbankPaymentRequest,
  SberbankPaymentResponse,
  SetDeliveryRequest,
} from './types';
import { useStoreMap } from 'effector-react';

// Сторы
export const $order = createStore<Order | null>(null);
export const $calculation = createStore<Calculation | null>(null);
export const $accumulatedBonuses = createStore<AccumulatedBonuses | null>(null);
export const $deliveryOptions = createStore<DeliveryOptions[] | null>(null);

// Эффекты
//TODO: разобраться с типами по окончанию фичи
export const getOrderFx = createEffect<
  string,
  AxiosResponse<{ data: Order }>,
  AxiosError<{ error: string }>
>(id => {
  return apiClient.get(Endpoint.GET_ORDER_DETAIL.replace('{id}', id));
});

export const createOrderFx = createEffect<
  void,
  AxiosResponse<{ order_id: OrderId }>,
  AxiosError<{ error: string }>
>(() => {
  return apiClient.post(Endpoint.CREATE_ORDER);
});

export const setDeliveryFx = createEffect<
  SetDeliveryRequest,
  AxiosResponse<{ order_id: OrderId }>,
  AxiosError<{ error: string }>
>(({ orderId, delivery_type_id, city_code, ...values }) => {
  return apiClient.post(`${Endpoint.SET_DELIVERY}/${orderId}`, {
    delivery_type_id: delivery_type_id,
    city_code: city_code ? Number(city_code) : undefined,
    ...values,
  });
});

export const getDeliveryOptionsFx = createEffect<
  void,
  AxiosResponse<{ data: DeliveryOptions[] }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_DELIVERY_OPTIONS));

export const calculateDiscountFx = createEffect<
  DiscountCalculationRequest,
  AxiosResponse<{ data: Calculation }>,
  AxiosError<{ error: string }>
>(({ order_id, promocode, distributor_code, inner_budget, bonus }) => {
  return apiClient.post(Endpoint.CALCULATE_DISCOUNT, {
    order_id: Number(order_id),
    promocode: promocode || '',
    distributor_code: distributor_code || '',
    bonus: Number(bonus) || 0,
    inner_budget: Number(inner_budget) || 0,
  });
});

export const paySberbankFx = createEffect<
  SberbankPaymentRequest,
  AxiosResponse<SberbankPaymentResponse>,
  AxiosError<{ error: string }>
>(({ order_id, email, promocode, distributor_code, bonus, inner_budget }) => {
  return apiClient.post(Endpoint.SBERBANK_PAYMENT.replace('{id}', order_id), {
    promocode: promocode || '',
    distributor_code: distributor_code || '',
    bonus: Number(bonus) || 0,
    inner_budget: Number(inner_budget) || 0,
    payment_token: '',
    email,
  });
});

export const payPaypalFx = createEffect<
  PaypalPaymentRequest,
  AxiosResponse<{ data: string }>,
  AxiosError<{ error: string }>
>(data => {
  return apiClient.post(Endpoint.PAYPAL_PAYMENT.replace('{id}', data.id), data);
});

export const getPaypalButtonLinkFx = createEffect<
  string,
  AxiosResponse<{ data: { buttonUrl: string } }>,
  AxiosError<{ error: string }>
>(id => {
  return apiClient.get(Endpoint.PAYPAL_BUTTON_LINK.replace('{id}', id));
});

// События
export const setOrder = createEvent<Order>();
export const setCalculation = createEvent<Calculation>();
export const setAccumulatedBonuses = createEvent<AccumulatedBonuses>();
export const setDeliveryOptions = createEvent<DeliveryOptions[]>();
export const clearStore = createEvent();
export const clearDeliveryOptions = createEvent();

// Подписки
$order.on(setOrder, (_, payload) => payload).reset(clearStore);
$calculation.on(setCalculation, (_, payload) => payload);
$accumulatedBonuses.on(setAccumulatedBonuses, (state, payload) => ({
  ...state,
  ...payload,
}));
$deliveryOptions
  .on(setDeliveryOptions, (state, payload) => payload)
  .reset(clearDeliveryOptions);

// Селекторы

export const useMaxBonusPayPercent: () => Order['max_bonus_pay_percent'] = () =>
  useStoreMap({
    store: $order,
    keys: [],
    fn: state => state?.max_bonus_pay_percent || 50,
  });
