import React from 'react';

export const VipIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.99305 2.87619C9.24517 2.87384 9.4818 2.99756 9.62373 3.20595L12.55 7.50249L15.9795 6.21086C16.2337 6.11513 16.5197 6.1634 16.7284 6.33727C16.9371 6.51113 17.0362 6.78375 16.988 7.05105L15.6424 14.5034C15.5776 14.8626 15.2649 15.1239 14.8999 15.1239H3.15212C2.78815 15.1239 2.47606 14.8641 2.41008 14.5061L1.01252 6.92436C0.962774 6.65449 1.06347 6.37889 1.27547 6.20465C1.48748 6.03042 1.77737 5.98502 2.03249 6.0861L5.57462 7.48953L8.36864 3.21768C8.50664 3.00668 8.74093 2.87855 8.99305 2.87619ZM9.0128 4.98975L6.50054 8.83081C6.30438 9.13073 5.92431 9.25129 5.59114 9.11928L2.74365 7.99108L3.78028 13.6148H14.2694L15.2666 8.09193L12.5264 9.12392C12.2005 9.24667 11.8329 9.1304 11.6369 8.84255L9.0128 4.98975Z'
    />
  </svg>
);
