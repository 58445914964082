import { HomeWorkStatus } from 'models/inner-insight/types';
import styled, { css } from 'styled-components';
import { Button } from 'ui/Button';

const setBackground = (status?: HomeWorkStatus) => {
  if (status === 'approved')
    return css`
      ${({ theme }) => theme.colors.bgStatusDoneColor}
    `;
  if (status === 'on_checking')
    return css`
      ${({ theme }) => theme.colors.bgStatusCheckErrorColor}
    `;
  if (status === 'need_work')
    return css`
      ${({ theme }) => theme.colors.bgStatusNotDoneColor}
    `;

  return css`
    ${({ theme }) => theme.colors.secondBgColor}
  `;
};

export const Wrap = styled.div<{
  $isAvailable?: boolean;
  to: string;
  status?: HomeWorkStatus;
}>`
  background: ${props => setBackground(props.status)};
  border-radius: 14px;
  position: relative;
  opacity: ${({ $isAvailable }) => ($isAvailable ? 1 : 0.5)};
  cursor: ${({ $isAvailable }) => ($isAvailable ? 'pointer' : 'not-allowed')};
`;

export const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 16px;
  border-radius: 0 14px;
  background-color: ${({ theme }) => theme.colors.statusDoneColor};
`;

export const BadgeText = styled.span`
  ${({ theme }) => theme.typography.bodyText3};
  line-height: 1;
  color: ${({ theme }) => theme.colors.mainBgColor};
`;

export const Inner = styled.div``;

export const Photo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  align-self: flex-start;
  & + ${Inner} {
    margin-left: 16px;
  }
`;

export const ActionButton = styled(Button)`
  margin-top: 16px;
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: 4px 0 0;
`;

export const Date = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText3};
`;

export const Bottom = styled.div`
  padding: 12px 16px 16px;
  display: flex;
`;

export const IconWrap = styled.div`
  font-size: 0;
  display: flex;
  align-items: center;
  & + & {
    margin: 0 0 0 24px;
  }
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0 0 0 8px;
`;

export const Top = styled.div`
  padding: 16px 16px 42px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  display: flex;
  align-items: center;
`;
