import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  StyledPaymentOption,
  StyledTotalPayment,
  PaymentButton,
  OfferLicence,
  StyledPreloader,
  OfferLicenceText,
  StyledEmailCheck,
} from './styles';
import { LoyaltyProgram } from './LoaltyProgram';
import { $calculation, $order } from 'models/order/model';
import { useStore } from 'effector-react';
import { useDiscountCalculationQuery } from 'queries/useDiscountCalculateQuery';
import { useSberbankPaymentQuery } from 'queries/userSberbankPaymentQuery';
import { useOrderDetailQuery } from 'queries/useOrderDetailQuery';
import { Checkbox } from 'ui/Checkbox';
import { PaymentLotList } from './styles';
import { Preloader } from 'ui/Preloader';
import { PaymentLot } from './PaymentLot';
import { usePaypalPaymentQuery } from 'queries/usePaypalPaymentQuery';
import { PaypalButton } from './PaypalButton';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Path } from 'constants/paths';
import {
  SetSubmitCallbackType,
  SubmitCallbackType,
} from 'interfaces/externalSubmitTypes';

export type PaymentOptionKeys = 'bank' | 'paypal';
export type CalculateFormHandler = (values: { inner_budget: string }) => void;

export const Payment: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const { id: orderId } = match.params;
  const history = useHistory();
  // STATE
  const [option, setOption] = useState<PaymentOptionKeys>('bank');
  const [isChecked, setChecked] = useState(false);
  const [isLoyaltyModalVisible, setLoyaltyModalVisible] = useState(false);
  // STORE
  const calculation = useStore($calculation);
  const orderDetails = useStore($order);

  // QUERY, MUTATION
  const { isLoading, mutate: calculate } = useDiscountCalculationQuery();
  const sberbankMutation = useSberbankPaymentQuery();
  const paypalMutation = usePaypalPaymentQuery();
  const orderDetailQuery = useOrderDetailQuery(orderId || '');

  const handleCloseLoyaltyModal = () => setLoyaltyModalVisible(false);
  const handleOpenLoyaltyModal = () => setLoyaltyModalVisible(true);
  const handlePaymentSuccess = useCallback(() => {
    history.push(`${Path.myOrders}`);
  }, [history]);
  const handleCheckboxChange = () => {
    setChecked(v => !v);
  };

  const handleCalculateFormSubmit: CalculateFormHandler = ({
    inner_budget,
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (orderId) {
      calculate({ inner_budget: Number(inner_budget), order_id: orderId });
      handleCloseLoyaltyModal();
    }
  };

  function handleSberbankPayment(values: { email: string }) {
    if (orderId)
      sberbankMutation.mutate({
        ...values,
        ...calculation,
        order_id: orderId,
      });
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOption(e.target.value as PaymentOptionKeys);
  };

  useEffect(() => {
    calculate({ order_id: orderId });
  }, [calculate, orderId]);

  const showPreloader = sberbankMutation.isLoading || paypalMutation.isLoading;
  const handlePaypalPayment = useCallback(
    (paypal_order_id: string, paypal_authorize_id: string) => {
      paypalMutation.mutate(
        {
          paypal_order_id,
          id: orderId.toString(),
          paypal_authorize_id,
        },
        {
          onSuccess: () => {
            handlePaymentSuccess();
          },
        }
      );
    },
    [handlePaymentSuccess, orderId, paypalMutation]
  );

  // Вспомогательные переменная и функция для внешней отправки форм
  const buttonHandler = useRef<SubmitCallbackType>();
  const setButtonHandler: SetSubmitCallbackType = func => {
    buttonHandler.current = func;
  };

  return orderDetailQuery.isLoading ? (
    <StyledPreloader />
  ) : (
    <>
      <PaymentLotList>
        {orderDetailQuery.isLoading || orderDetailQuery.isFetching ? (
          <Preloader />
        ) : (
          orderDetails?.basket.map((item, idx) => (
            <PaymentLot key={idx} {...item} />
          ))
        )}
      </PaymentLotList>

      <StyledPaymentOption handleChange={handleChange} option={option} />
      <StyledEmailCheck
        handleSubmit={handleSberbankPayment}
        setButtonHandler={setButtonHandler}
      />
      <LoyaltyProgram
        orderPrice={calculation?.final_price || 0}
        handleFormSubmit={handleCalculateFormSubmit}
        isLoyaltyModalVisible={isLoyaltyModalVisible}
        handleClose={handleCloseLoyaltyModal}
        handleOpen={handleOpenLoyaltyModal}
      />
      <StyledTotalPayment isLoading={isLoading} calculation={calculation} />
      {showPreloader ? (
        <StyledPreloader />
      ) : option === 'bank' ? (
        <PaymentButton
          disabled={!isChecked}
          type='button'
          onClick={buttonHandler.current}
        >
          Оплатить
        </PaymentButton>
      ) : orderId ? (
        option === 'paypal' && calculation ? (
          <PaypalButton
            id={orderId}
            disabled={!isChecked}
            value={calculation.final_price.toString()}
            handlePayment={handlePaypalPayment}
          />
        ) : null
      ) : (
        <StyledPreloader />
      )}
      <OfferLicence>
        <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
        <OfferLicenceText>
          Я прочитал(а) и соглашаюсь с ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ сайта,
          ПРАВИЛАМИ ОПЛАТЫ, условиями ДОГОВОРА - ОФЕРТЫ, при оплате выездных или
          расстановочных мероприятий - с ДОГОВОРОМ-ОФЕРТОЙ ДЛЯ ВЫЕЗДНЫХ
          МЕРОПРИЯТИЙ и ДОГОВОРОМ-ОФЕРТОЙ ДЛЯ РАССТАНОВОЧНЫХ МЕРОПРИЯТИЙ, а при
          оплате членства в ВИП-Клубе - с условиями ДОГОВОРА-ОФЕРТЫ ДЛЯ
          УЧАСТНИКОВ ВИП-КЛУБА.
        </OfferLicenceText>
      </OfferLicence>
    </>
  );
};
