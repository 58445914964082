import styled from 'styled-components';
import { InputPassword } from 'ui/InputPassword';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
`;

export const PasswordInput = styled(InputPassword)`
  margin: 24px 0 0;
`;
