import React from 'react';

export const CouchingIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M17.5603 6.37813L9.31034 2.62812C9.11315 2.53847 8.88681 2.53847 8.68962 2.62812L0.439648 6.37813C-0.147638 6.64507 -0.146231 7.47979 0.441968 7.74473L2.24924 8.5588V12.1624C2.24924 12.9969 2.72087 13.7596 3.46744 14.1335C6.95055 15.8743 11.0487 15.8743 14.5313 14.1338C15.2783 13.7597 15.7492 12.997 15.7492 12.1624V8.57448L17.5627 7.74262C18.1469 7.47465 18.1454 6.64409 17.5603 6.37813ZM8.99998 4.13474L15.4444 7.06403L14.6983 7.40628C14.6943 7.408 14.6904 7.40944 14.6865 7.41123L9.07212 9.98706L2.56924 7.05784L8.99998 4.13474ZM14.2492 12.1624C14.2492 12.4292 14.0988 12.6728 13.8602 12.7923C10.8003 14.3215 7.19893 14.3215 4.13851 12.792C3.89997 12.6725 3.74922 12.4288 3.74922 12.1624V9.23454L8.76693 11.4947C8.77794 11.4997 8.78901 11.5042 8.80019 11.5086C8.80156 11.5092 8.8029 11.5098 8.8043 11.5103C8.86379 11.5334 8.92549 11.5483 8.98789 11.5556C8.99133 11.5561 8.99478 11.5566 8.99826 11.557C9.02076 11.5593 9.04337 11.5603 9.06597 11.5606C9.06984 11.5607 9.0737 11.5609 9.07757 11.5609C9.11188 11.5608 9.1462 11.5581 9.18026 11.5532C9.18561 11.5525 9.19099 11.5519 9.19629 11.5511C9.19728 11.5509 9.19823 11.5507 9.19921 11.5505C9.21134 11.5485 9.2234 11.5462 9.23546 11.5436C9.2389 11.5428 9.24231 11.5418 9.24576 11.541C9.25529 11.5388 9.26481 11.5366 9.27424 11.534C9.28316 11.5315 9.29202 11.5287 9.30088 11.5259C9.30479 11.5246 9.30872 11.5236 9.31259 11.5223C9.3243 11.5184 9.3359 11.5141 9.34743 11.5096C9.34842 11.5092 9.34943 11.5089 9.35042 11.5085C9.35921 11.505 9.36789 11.5012 9.37658 11.4974C9.38027 11.4958 9.38399 11.4943 9.38769 11.4926H9.38772L9.39261 11.4904L14.2492 9.26256V12.1624Z' />
  </svg>
);
