import styled from 'styled-components';
import { AudioItem as Audio } from './AudioItem';
import { DocumentItem as Document } from './DocumentItem';

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline2};
`;

export const List = styled.div`
  margin-top: 24px;
`;

export const DocumentItem = styled(Document)`
  & + & {
    margin-top: 10px;
  }
`;
export const AudioItem = styled(Audio)`
  & + & {
    margin-top: 10px;
  }
  & + ${DocumentItem} {
    margin-top: 10px;
  }
`;
