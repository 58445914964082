import styled from 'styled-components';
import { Button } from 'ui/Button';

export const Wrap = styled.div`
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  border-radius: 14px;
  padding: 7px;
  margin: 8px 0 0;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemTitle = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 0 auto 0;
`;

export const Icon = styled.div`
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  & + ${ItemTitle} {
    margin-left: 12px;
  }
`;

export const Info = styled.p`
  margin: 0 5px 0 8px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const PlayButton = styled(Button)`
  padding: 9px;
  border-radius: 50%;
  & + ${ItemTitle} {
    margin-left: 12px;
  }
`;

export const PlaybackRate = styled.div`
  display: flex;
  margin: 0 0 0 55px;
`;

export const PlaybackRateItem = styled.p<{ isActive: boolean }>`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText2};
  cursor: pointer;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.mainColor : theme.colors.secondColor};

  & + & {
    margin: 0 0 0 20px;
  }
`;

export const PlayLine = styled.div`
  flex: 1 0 auto;
  margin: 0 22px 0 12px;
`;

export const PlayLineDone = styled.div<{ width: number }>`
  background-color: ${({ theme }) => theme.colors.actionColor};
  border-radius: 5px;
  width: ${({ width }) => width + '%'};
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  height: 6px;
  transition: width 0.5s;
`;

export const PlayLineInput = styled.input`
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
`;
export const PlayLineWrap = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.secondColor};
`;
