import styled from 'styled-components';
import { Banner } from '../Banner';
import { Link } from 'react-router-dom';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  display: grid;
  margin: 0 auto 142px;
  align-items: start;
  grid-template-areas:
    'space1 banner banner banner banner banner banner banner banner banner banner space2'
    'space1 backLink backLink content content content content content content content space3 space2';
  grid-template-columns: repeat(12, 1fr);
  gap: 40px 25px;
  max-width: 1212px;
`;

export const SalesDetailBanner = styled(Banner)`
  grid-area: banner;
`;

export const BackToSalesLink = styled(Link)`
  grid-area: backLink;
  & > button > span > svg {
    width: 18px;
  }
`;

export const SalesDetailContent = styled.p`
  grid-area: content;
  margin: 0;
  white-space: pre-line;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto;
  text-align: center;
`;

export const NoItemText = styled.p`
  ${({ theme }) => theme.typography.headline3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;
