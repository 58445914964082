import styled from 'styled-components';
import { Button } from 'ui/Button';
import { DeliveryAddressFields } from '../common/DeliveryAddressFields';

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  height: 1px;
  width: 100%;
  margin: 60px 0;
`;

export const ButtonWrap = styled.div`
  text-align: right;
`;

export const StyledDeliveryAddress = styled(DeliveryAddressFields)`
  margin-top: 60px;
`;

export const AddAdressButton = styled(Button)`
  align-self: flex-end;
  svg {
    stroke-width: 0;
  }

  margin: 24px 0 0 521.5px;
`;

export const Title = styled.p`
  margin-top: 60px;
  ${({ theme }) => theme.typography.headline2};
`;
