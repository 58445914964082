import styled from 'styled-components';
import { Button } from 'ui/Button';
import { NavLink } from 'react-router-dom';

export const NavigationButton = styled(Button)`
  white-space: nowrap;
  transition: opacity 0.3s;
  &:focus {
    box-shadow: none;
  }
`;

export const FireSale = styled.div`
  margin: 0 4px 0 0;
  transition: opacity 0.3s;

  fill: ${({ theme }) => theme.colors.thirdColor};
`;

export const NavigationItem = styled(NavLink)<{ $sale?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 32px 22px;
  position: relative;
  &:after {
    display: block;
    position: absolute;
    content: '';
    background-color: ${({ theme }) => theme.colors.actionColor};
    bottom: 0;
    left: 50%;
    right: 50%;
    height: 2px;
    transition: all 0.3s;
  }
  &:hover,
  &:active {
    &:after {
      left: 0;
      right: 0;
      transition: all 0.3s;
    }

    & ${NavigationButton}, & ${FireSale} {
      transition: opacity 0.3s;
      opacity: 0.8;
    }
  }

  &:focus {
    ${({ theme }) => theme.additional.noOutline};
  }

  &.active-tab {
    &:after {
      left: 0;
      right: 0;
      transition: all 0.3s;
    }

    & ${NavigationButton}, & ${FireSale} {
      transition: opacity 0.3s;
      opacity: 0.8;
    }
  }

  ${({ $sale, theme }) =>
    $sale
      ? `& ${NavigationButton} {color: ${theme.colors.thirdColor}}; `
      : null}
`;
