import { useMutation } from 'react-query';
import { restorePhoneFx } from 'models/auth/model';
import { RestorePhoneQueryProps } from './types';

export const useRestorePhoneQuery: RestorePhoneQueryProps = callback =>
  useMutation(values => restorePhoneFx(values), {
    onSuccess: (response, values) => {
      if (callback) callback(values.phone, response.data.verification_code);
    },
  });
