import React, { useState } from 'react';
import Ruble from 'components/common/Ruble';
import { formatPriceToString } from 'utilities/formatPriceToString';
import {
  Wrap,
  Title,
  Options,
  Option,
  OptionRadio,
  OptionInner,
  OptionTitle,
  OptionText,
  OptionPrice,
  TariffsButton,
} from './styles';
import { useCreateSchoolOrderQuery } from 'queries/inner-insight/useCreateSchoolOrderQuery';
import { TariffsProps } from './types';
import { SchoolPurchaseStatus } from 'models/inner-insight/types';
import { format } from 'date-fns';
import { DateFormats } from 'constants/dateFormats';
import { ru } from 'date-fns/locale';

function getWord(status: SchoolPurchaseStatus) {
  return SchoolPurchaseStatus.NOT_PAID === status ? 'первый' : 'второй';
}
// TODO: Прописать логику переключения радиобаттонов

export const Tariffs: React.FC<TariffsProps> = ({
  id,
  purchase,
  dateEnd,
  ...props
}) => {
  const [option, setOption] = useState<number>(1);
  const [optionPrice, setOptionPrice] = useState<number>(purchase.full_price);

  const { mutate: buy } = useCreateSchoolOrderQuery();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const price = e.target.getAttribute('data-price');
    setOptionPrice(Number(price));
    setOption(Number(e.target.value));
  };

  const handleBuy = () => {
    buy({
      school_id: id,
      order_type: option,
      purchaseStatus: purchase.status,
    });
  };

  return (
    <Wrap {...props}>
      <Title id='payment'>Оплата</Title>
      <Options>
        {purchase.status === SchoolPurchaseStatus.NOT_PAID && (
          <Option>
            <OptionRadio
              radioSize='large'
              onChange={handleChange}
              checked={option === 1}
              value={1}
              data-price={purchase.half_price}
            />
            <OptionInner>
              <OptionTitle>Оплата в рассрочку. Первый взнос</OptionTitle>
              <OptionText>
                Второй взнос до{' '}
                {format(new Date(dateEnd), DateFormats.StartEventDate, {
                  locale: ru,
                })}
                г.
              </OptionText>
            </OptionInner>
            <OptionPrice>
              {formatPriceToString(purchase.half_price)} {Ruble}
            </OptionPrice>
          </Option>
        )}

        {purchase.status === SchoolPurchaseStatus.PREPAID && (
          <Option>
            <OptionRadio
              radioSize='large'
              onChange={handleChange}
              checked={option === 1}
              value={1}
              data-price={purchase.half_price}
            />
            <OptionInner>
              <OptionTitle>Оплата в рассрочку. Второй взнос</OptionTitle>
              <OptionText>
                Второй взнос до{' '}
                {format(new Date(dateEnd), DateFormats.StartEventDate, {
                  locale: ru,
                })}
                г.
              </OptionText>
            </OptionInner>
            <OptionPrice>
              {formatPriceToString(purchase.half_price)} {Ruble}
            </OptionPrice>
          </Option>
        )}

        {purchase.status === SchoolPurchaseStatus.NOT_PAID && (
          <Option>
            <OptionRadio
              radioSize='large'
              onChange={handleChange}
              checked={option === 2}
              value={'2'}
              data-price={purchase.full_price}
            />
            <OptionInner>
              <OptionTitle>Полная оплата</OptionTitle>
              <OptionText>
                Открывает мгновенный и полный доступ к материалам{' '}
              </OptionText>
            </OptionInner>
            <OptionPrice>
              {formatPriceToString(purchase.full_price)} {Ruble}
            </OptionPrice>
          </Option>
        )}
      </Options>
      <TariffsButton buttonType='primary' size='large' onClick={handleBuy}>
        {optionPrice
          ? option === 1
            ? `Внести ${getWord(purchase.status)} взнос ${formatPriceToString(
                optionPrice
              )} ${Ruble}`
            : `Оплатить полный доступ к материалам ${formatPriceToString(
                optionPrice
              )} ${Ruble}`
          : null}
      </TariffsButton>
    </Wrap>
  );
};
