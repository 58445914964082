import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  padding: 60px;
  width: 100%;
`;
export const NewsWrap = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'title title title title'
    'item1 item2 item3 item4';
  max-width: 1212px;
  margin: 0 auto;
`;

export const Title = styled.h1`
  margin: 0;
  grid-area: title;
  ${({ theme }) => theme.typography.headline2};
`;

export const PreloaderSC = styled(Preloader)`
  display: flex;
  justify-content: center;
`;
