import React from 'react';

export const VideoIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.548 5.09236C19.8255 5.2352 20 5.52118 20 5.83334V14.1667C20 14.4788 19.8255 14.7648 19.548 14.9076C19.2704 15.0505 18.9363 15.0262 18.6823 14.8448L12.849 10.6781C12.63 10.5217 12.5 10.2691 12.5 10C12.5 9.73088 12.63 9.47832 12.849 9.32189L18.6823 5.15523C18.9363 4.97379 19.2704 4.94953 19.548 5.09236ZM14.7671 10L18.3333 12.5473V7.45266L14.7671 10Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 4.99992C2.03976 4.99992 1.66667 5.37301 1.66667 5.83325V14.1666C1.66667 14.6268 2.03976 14.9999 2.5 14.9999H11.6667C12.1269 14.9999 12.5 14.6268 12.5 14.1666V5.83325C12.5 5.37301 12.1269 4.99992 11.6667 4.99992H2.5ZM0 5.83325C0 4.45254 1.11929 3.33325 2.5 3.33325H11.6667C13.0474 3.33325 14.1667 4.45254 14.1667 5.83325V14.1666C14.1667 15.5473 13.0474 16.6666 11.6667 16.6666H2.5C1.11929 16.6666 0 15.5473 0 14.1666V5.83325Z'
      fill='#B1B1B1'
    />
  </svg>
);

