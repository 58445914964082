// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ChangeEvent } from 'react';
import MasterCardLogo from 'images/mastercard_logo.png';
import VisaLogo from 'images/visa_logo.png';
import JCBLogo from 'images/jcb_logo.png';
import PaypalLogo from 'images/paypal_logo.png';
import {
  List,
  Option,
  Inner,
  OptionRadio,
  OptionTitle,
  OptionText,
  Title,
  Logos,
  LogoIcon,
  Divider,
} from './styles';
import { PaymentOptionKeys } from '../Payment';

export const PaymentOption: React.FC<{
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  option: PaymentOptionKeys;
}> = ({ option, handleChange, ...rest }) => {
  return (
    <div {...rest}>
      <Title>Способ оплаты</Title>
      <List>
        <Option>
          <OptionRadio
            radioSize='large'
            checked={option === 'bank'}
            onChange={handleChange}
            value={'bank'}
          />
          <Inner>
            <Logos>
              <LogoIcon src={MasterCardLogo} alt={'MasterCardLogo'} />
              <LogoIcon src={VisaLogo} alt={'VisaLogo'} />
              <LogoIcon src={JCBLogo} alt={'JCBLogo'} />
            </Logos>
            <OptionTitle>Картой любого банка</OptionTitle>
            <OptionText>С помощью платежного шлюза Сбербанк</OptionText>
          </Inner>
        </Option>
        <Divider />
        <Option>
          <OptionRadio
            radioSize='large'
            checked={option === 'paypal'}
            onChange={handleChange}
            value={'paypal'}
          />
          <Inner>
            <Logos>
              <LogoIcon src={PaypalLogo} />
            </Logos>
            <OptionTitle>Платёжная система PayPal</OptionTitle>
            <OptionText>
              Подходит только для клиентов из других стран, кроме России
            </OptionText>
          </Inner>
        </Option>
      </List>
    </div>
  );
};
