import { CDEKCourierFormValues } from '../components/Buy/Content/Delivery/types';

export const formatAddress: (
  values: Pick<
    CDEKCourierFormValues,
    | 'postal_code'
    | 'country'
    | 'city'
    | 'address'
    | 'house'
    | 'flat'
    | 'domophone'
  >
) => string = values => {
  const addressArray = [];
  if (values.postal_code) {
    addressArray.push(values.postal_code);
  }
  if (values.country) {
    addressArray.push(values.country);
  }
  if (values.city) {
    addressArray.push(values.city);
  }
  if (values.address) {
    addressArray.push(values.address);
  }
  if (values.house) {
    addressArray.push(values.house);
  }
  if (values.flat) {
    addressArray.push(`кв.${values.flat}`);
  }
  if (values.domophone) {
    addressArray.push(`домофон: ${values.domophone}`);
  }
  return addressArray.join(', ');
};
