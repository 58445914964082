import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ListWrap } from './styles';
import { SelfEducation } from './SeldEducation';
import { Meetings } from './Meetings';
import { CoachSchool } from './CoachSchool';
import { VipClub } from './VipClub';
import { Path } from 'constants/paths';

export const List: React.FC = props => {
  return (
    <ListWrap {...props}>
      <Switch>
        <Route
          path={`${Path.education}/:key(courses)`}
          component={SelfEducation}
        />
        <Route path={`${Path.education}/:key(trainings)`}>
          <Meetings />
        </Route>
        <Route
          path={`${Path.education}/:key(events)`}
          component={CoachSchool}
        />
        <Route
          path={`${Path.education}/:key(coach_school)`}
          component={CoachSchool}
        />
        <Route path={`${Path.education}/vip-club`}>
          <VipClub />
        </Route>
        <Redirect from={`${Path.education}`} to={`${Path.education}/courses`} />
      </Switch>
    </ListWrap>
  );
};
