import styled from 'styled-components';

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline1}
`;

export const List = styled.ul`
  margin: 24px 0 0 0;
  padding: 0 0 0 18px;
`;

export const ListItem = styled.li`
  & + & {
    margin: 20px 0 0;
  }
`;

export const ListItemText = styled.span`
  ${({ theme }) => theme.typography.bodyText2}
`;
