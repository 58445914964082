import { useMutation } from 'react-query';
import { apiClient } from 'api/apiClient';
import { DaDataSuggestionQueryResponse } from '../types';
import { Endpoint } from 'constants/endpoints';

export const useCityDaDataSuggestionQuery: DaDataSuggestionQueryResponse = () =>
  useMutation('getCityDaDataSuggestion', ({ address, country }) =>
    apiClient
      .post(Endpoint.DADATA_SUGGESTION, {
        address,
        country,
        from_bound: 'city',
        to_bound: 'city',
      })
      .then(res => {
        return res.data;
      })
  );
