import { theme } from 'ui/theme';

export const calendarMonthEventsColors: Record<
  | 'course'
  | 'offline_event'
  | 'training'
  | 'intensive'
  | 'coach_school'
  | 'master_class'
  | 'empty',
  string
> = {
  course: theme.colors.actionColor,
  offline_event: theme.colors.linksColor,
  training: theme.colors.thirdColor,
  intensive: theme.colors.mainColor,
  coach_school: theme.colors.statusDoneColor,
  master_class: theme.colors.mainColor,
  empty: 'transparent',
};
export const calendarDayEventsColors: {
  border: Record<
    | 'course'
    | 'offline_event'
    | 'training'
    | 'intensive'
    | 'coach_school'
    | 'master_class'
    | 'empty',
    string
  >;
  fill: Record<
    | 'course'
    | 'offline_event'
    | 'training'
    | 'intensive'
    | 'coach_school'
    | 'master_class'
    | 'empty',
    string
  >;
} = {
  border: {
    course: theme.colors.actionColor,
    offline_event: theme.colors.linksColor,
    training: theme.colors.thirdColor,
    intensive: theme.colors.mainColor,
    coach_school: theme.colors.statusDoneColor,
    master_class: theme.colors.mainColor,
    empty: 'transparent',
  },
  fill: {
    course: theme.colors.bgStatusColor,
    offline_event: theme.colors.bgBlueColor,
    training: theme.colors.redDividingLine,
    intensive: theme.colors.secondBgColor,
    coach_school: theme.colors.statusDoneColor,
    master_class: theme.colors.secondBgColor,
    empty: 'transparent',
  },
};
