import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Preloader } from 'ui/Preloader';

export const Bottom = styled.div`
  margin: 40px 0 0;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
`;

export const Weight = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const Digit = styled.span`
  font-weight: bold;
  margin: 0 0 0 24px;
`;

export const TotalCount = styled.p`
  margin: 0 0 0 auto;
`;

export const TotalPrice = styled.p`
  margin: 0 0 0 40px;
  ${({ theme }) => theme.typography.headline3};
`;

export const ButtonWrap = styled.div`
  margin: 40px 0 0;
  text-align: right;
`;

export const EmptyCartText = styled.p`
  max-width: 500px;
  margin: 0;
`;

export const EmptyCartButtonWrap = styled.div`
  margin: 24px 0 0;
  & > button + button {
    margin: 0 0 0 12px;
  }
  display: flex;
`;

export const StyledLink = styled(NavLink)`
  & + & {
    margin-left: 16px;
  }
`;

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
  text-align: center;
`;

export const StyledButtonPreloader = styled(Preloader)`
  margin: 0 63px 0 87px;
`;
