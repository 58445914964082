import { CalendarEvent, CalendarEventType } from 'models/calendar/types';
import {
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

export const formatDayEventInMonth: (
  event: CalendarEvent,
  dayStart: Date
) => CalendarEventType = (event, dayStart) => {
  const start = new Date(event.date_start);
  const end = new Date(event.date_end);
  return {
    type: event.type,
    details: event,
    //Проверка является ли событие началом линии на календаре
    start:
      // Или является началом недели день начала события
      isSameDay(dayStart, startOfWeek(dayStart, { weekStartsOn: 1 })) ||
      // Или является началом месяца день начала события
      isSameDay(dayStart, startOfMonth(dayStart)) ||
      // Является ли нынешний день днём начала события
      isSameDay(dayStart, start),
    //Проверка является ли день календаря днём завершения курса
    end:
      // Или является концом недели день начала события
      isSameDay(dayStart, endOfWeek(dayStart, { weekStartsOn: 1 })) ||
      // Или является концом месяца день начала события
      isSameDay(dayStart, endOfMonth(dayStart)) ||
      // Является ли нынешний день днём конца события
      isSameDay(dayStart, end),
    purchased: event.purchased,
    title:
      isSameDay(dayStart, startOfWeek(dayStart, { weekStartsOn: 1 })) ||
      isSameDay(dayStart, startOfMonth(dayStart)) ||
      isSameDay(dayStart, start)
        ? isSameDay(start, end) ||
          isSameDay(dayStart, endOfWeek(dayStart, { weekStartsOn: 1 })) ||
          isSameDay(
            startOfWeek(dayStart, { weekStartsOn: 1 }),
            endOfMonth(dayStart)
          )
          ? // Много проверок, чтобы понять где находится ячейка и при необходимости укоротить её содержимое
            `${event.name.slice(0, 10)}...`
          : event.name
        : '',
  };
};
