import React from 'react';
import { Path } from 'constants/paths';
import { FireSaleIcon } from 'icons';
import { useUserProfile } from 'models/auth/model';
import { NavigationButton, FireSale, NavigationItem } from './styles';

export const Navigation: React.FC = () => {
  const user = useUserProfile();
  return (
    <>
      <NavigationItem
        isActive={(_, location) =>
          location.pathname.startsWith(Path.catalogCourses)
        }
        activeClassName={'active-tab'}
        to={Path.catalogCourses}
      >
        <NavigationButton label='Каталог' buttonType='link' tabIndex={-1} />
      </NavigationItem>
      <NavigationItem
        isActive={(_, location) => location.pathname.startsWith(Path.sales)}
        activeClassName={'active-tab'}
        to={Path.sales}
        $sale={true}
      >
        {/*TODO: Сделать условие переключения горячих предложений*/}
        <FireSale>{FireSaleIcon}</FireSale>
        <NavigationButton label='Акции' buttonType='link' tabIndex={-1} />
      </NavigationItem>
      <NavigationItem
        isActive={(_, location) => location.pathname.startsWith(Path.news)}
        activeClassName={'active-tab'}
        to={Path.news}
      >
        <NavigationButton label='Блог' buttonType='link' tabIndex={-1} />
      </NavigationItem>
      {user ? (
        <NavigationItem
          isActive={(_, location) =>
            location.pathname.startsWith(Path.education)
          }
          activeClassName={'active-tab'}
          to={Path.education}
        >
          <NavigationButton label='Планы' buttonType='link' tabIndex={-1} />
        </NavigationItem>
      ) : null}
      <NavigationItem
        isActive={(_, location) => location.pathname.startsWith(Path.about)}
        activeClassName={'active-tab'}
        to={Path.about}
      >
        <NavigationButton label='О проекте' buttonType='link' tabIndex={-1} />
      </NavigationItem>
    </>
  );
};
