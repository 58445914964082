import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { useMutation, UseMutationResult } from 'react-query';
import { CheckCurrentPasswordResponse } from './types';
import { AxiosError, AxiosResponse } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';

export const useCheckCurrentPassword: () => UseMutationResult<
  AxiosResponse<CheckCurrentPasswordResponse>,
  AxiosError<{ error: ServerValidationError }>,
  string | undefined
> = () =>
  useMutation<
    AxiosResponse<CheckCurrentPasswordResponse>,
    AxiosError<{ error: ServerValidationError }>,
    string | undefined
  >(
    ['useCheckCurrentPassword'],
    (password?: string) =>
      apiClient.post(Endpoint.CHECK_CURRENT_PASSWORD, {
        password,
      }),
    {
      retry: 0,
    }
  );
