import React from 'react';
import styled from 'styled-components';
import {
  CoursesIcon,
  TreningsIcon,
  BookIcon,
  RasstanovkiIcon,
  VipIcon,
  CouchingIcon,
  ConsultationIcon,
  VebinarsIcon,
  GoodsIcon,
  LockIcon,
} from 'icons/';
import { CategoryButton } from 'ui/CategoryButton/';
import { CatalogCategoriesKey, CategoriesProps, CategoryItem } from './types';
import { Link } from 'react-router-dom';
import { Path } from 'constants/paths';

const CategoryLink = styled(Link)``;

const CategoryWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & ${CategoryLink} + ${CategoryLink} {
    margin-top: 12px;
  }
`;

const categories: CategoryItem[] = [
  {
    id: 1,
    key: 'courses',
    icon: CoursesIcon,
    title: 'Саморазвитие',
  },
  {
    id: 2,
    key: 'books',
    icon: BookIcon,
    title: 'Книги',
  },
  {
    id: 3,
    key: 'trainings',
    icon: TreningsIcon,
    title: 'Встречи',
  },
  {
    id: 4,
    key: 'events',
    icon: RasstanovkiIcon,
    title: 'Мероприятия',
  },
  {
    id: 5,
    key: 'goods',
    icon: GoodsIcon,
    title: 'Товары',
  },
  {
    id: 6,
    key: 'vip-chat',
    icon: ConsultationIcon,
    title: 'VIP-chat',
  },
  {
    id: 7,
    key: 'secret',
    icon: LockIcon,
    title: 'Telegram канал',
  },

  {
    id: 8,
    key: 'vip-club',
    icon: VipIcon,
    title: 'VIP-клуб',
  },
  {
    id: 9,
    key: 'inner-insight',
    icon: CouchingIcon,
    title: 'Inner insight',
  },
  {
    id: 10,
    key: 'free',
    icon: VebinarsIcon,
    title: 'FREE',
  },
];

export const Categories: React.FC<CategoriesProps<CatalogCategoriesKey>> = ({
  categoryKey,
  ...rest
}) => {
  return (
    <CategoryWrap {...rest}>
      {categories.map(({ id, key, icon, title }) => {
        return (
          <CategoryLink key={id} to={`${Path.catalog}/${key}`}>
            <CategoryButton isActive={categoryKey === key} icon={icon}>
              {title}
            </CategoryButton>
          </CategoryLink>
        );
      })}
    </CategoryWrap>
  );
};
