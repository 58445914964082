import React from 'react';
import ru from 'date-fns/esm/locale/ru';
import { DateRangeSC, Wrap } from './styled';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { RangeWithKey, Range } from 'react-date-range';
import { Button } from 'ui/Button';

interface IProps {
  dateRange: Range[] | undefined;
  setDateRange: (value: RangeWithKey[]) => void;
  closePicker: (value: false) => void;
  setIsEmptyPeriod: (value: false) => void;
}

export const DateRangePicker: React.FC<IProps> = ({
  dateRange,
  setDateRange,
  closePicker,
  setIsEmptyPeriod,
}) => {
  return (
    <Wrap>
      <DateRangeSC
        rangeColors={['#FED74D']}
        locale={ru}
        editableDateInputs
        onChange={item => {
          setDateRange([(item as { selection: RangeWithKey }).selection]);
          setIsEmptyPeriod(false);
        }}
        moveRangeOnFirstSelection={false}
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        ranges={dateRange}
      />
      <Button buttonType='primary' onClick={() => closePicker(false)}>
        Ок
      </Button>
    </Wrap>
  );
};
