import styled from 'styled-components';
import { Dropdown } from './Dropdown';

const PHOTO_SIZE = 52;

export const Photo = styled.img`
  width: ${PHOTO_SIZE}px;
  height: ${PHOTO_SIZE}px;
  border-radius: 50%;
`;

export const PhotoBlank = styled.div`
  width: ${PHOTO_SIZE}px;
  height: ${PHOTO_SIZE}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.actionColor};
`;

export const PhotoBlankText = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: 0;
`;

export const Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledDropdown = styled(Dropdown)`
  font-size: 0;
`;
