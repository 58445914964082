import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  padding: 27px;
  height: 467px;
`;

export const Text = styled.div`
  margin: auto;
  text-align: center;
`;

export const Title = styled.h4`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0;
`;
