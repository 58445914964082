import React from 'react';
import { UserMiniatureProps } from './types';
import { UserProfileType } from 'models/auth/types';
import {
  Photo,
  PhotoBlank,
  PhotoBlankText,
  StyledDropdown,
  Wrap,
} from './styles';
import { formatUserMiniatureName } from 'utilities/formatUserMiniatureName';
import { baseUrl } from 'api/apiClient';

const UserMiniatureComponent: React.FC<UserMiniatureProps<UserProfileType>> = ({
  data,
  ...rest
}) => {
  const { name, last_name, avatar } = data;

  return (
    <Wrap {...rest}>
      {avatar ? (
        <Photo src={baseUrl + avatar} alt={name} />
      ) : (
        <PhotoBlank>
          <PhotoBlankText>
            {formatUserMiniatureName(name, last_name)}
          </PhotoBlankText>
        </PhotoBlank>
      )}
      <StyledDropdown />
    </Wrap>
  );
};

export const UserMiniature = React.memo(UserMiniatureComponent);
