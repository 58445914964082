import React from 'react';
// Иконки
import { LogoIcon, CalendarIcon, InstagramIcon } from 'icons/';

import { Navigation } from './Navigation/';
import { format } from 'date-fns';
import { Path } from 'constants/paths';
import { HeaderProps } from './types';
import {
  Logo,
  LogoText,
  LoginButton,
  HeaderWrap,
  Calendar,
  Container,
  Inner,
  Border,
  ChatButton,
  ChatWrap,
} from './styles';
import { HeaderModal } from './HeaderModal';
import { Cart } from './Cart';
import { useStore } from 'effector-react';
import { useUserProfileQuery } from 'queries/auth/useUserProfileQuery';
import { UserMiniature } from './UserMiniature';
import {
  $showModal,
  hideAuthModal,
  showAuthModal,
  useUserProfile,
} from 'models/auth/model';
import { Chat } from 'components/Chat';
import { IconBackgrounds, SocialNetworkIcon } from 'components/common/styles';
import { setShowChat, useShowChat } from 'models/chat/model';
import { Notification } from './Notification';
import { Preloader } from 'ui/Preloader';

export const Header: React.FC<HeaderProps> = () => {
  const currentYear = format(new Date(), 'yyyy');
  const isOpen = useStore($showModal);
  const { isLoading } = useUserProfileQuery();
  const user = useUserProfile();
  const openAuthModal = () => showAuthModal();
  const showChat = useShowChat();

  return (
    <HeaderWrap>
      {isOpen ? <HeaderModal closeModal={hideAuthModal} /> : null}
      <Container>
        <Inner>
          <Logo to={Path.catalogCourses}>
            <div>{LogoIcon}</div>
            <LogoText>
              Будь
              <br />
              С&nbsp;мужиком
            </LogoText>
          </Logo>
          <Navigation />

          <SocialNetworkIcon
            target='_blank'
            href='https://www.instagram.com/budsmuzhikom.ru/'
            iconBackground={IconBackgrounds.instagram}
          >
            {InstagramIcon}
          </SocialNetworkIcon>

          {user ? (
            <>
              <Calendar to={`${Path.calendar}/${currentYear}/`}>
                {CalendarIcon}
              </Calendar>
              <Notification />
              <Cart />
              <ChatWrap>
                <ChatButton
                  size={'medium'}
                  onClick={() => setShowChat(!showChat)}
                >
                  Связаться
                </ChatButton>
                <Chat />
              </ChatWrap>
            </>
          ) : null}
          {isLoading ? (
            <Preloader />
          ) : user ? (
            <UserMiniature data={user} />
          ) : (
            <LoginButton
              buttonType={'secondary'}
              size={'medium'}
              disabled={false}
              onClick={openAuthModal}
            >
              Войти
            </LoginButton>
          )}
        </Inner>
      </Container>
      <Border />
    </HeaderWrap>
  );
};
