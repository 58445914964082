import React from 'react';

export const SuperheroIcon = (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.2387 7.74419C11.0282 7.74419 10.8168 7.66315 10.6557 7.50031C10.3335 7.17463 10.3335 6.64748 10.6557 6.3218L15.4516 1.47447C15.7738 1.14954 16.2954 1.14954 16.6176 1.47447C16.9398 1.80015 16.9398 2.32729 16.6176 2.65298L11.8217 7.50031C11.6606 7.66315 11.45 7.74419 11.2387 7.74419Z'
      fill='#181818'
    />
    <path
      d='M8.24068 10.7739C8.03011 10.7739 7.8188 10.6928 7.65768 10.53C7.33546 10.2043 7.33546 9.67714 7.65768 9.35146L8.85665 8.13963C9.17888 7.8147 9.70043 7.8147 10.0227 8.13963C10.3449 8.46531 10.3449 8.99246 10.0227 9.31814L8.82368 10.53C8.66257 10.6928 8.452 10.7739 8.24068 10.7739Z'
      fill='#181818'
    />
    <path
      d='M17.2329 26.5278C17.0223 26.5278 16.811 26.4468 16.6499 26.2839C16.3276 25.9582 16.3276 25.4311 16.6499 25.1054L22.0452 19.6521C22.3675 19.3272 22.889 19.3272 23.2112 19.6521C23.5335 19.9778 23.5335 20.505 23.2112 20.8306L17.8159 26.2839C17.6548 26.4468 17.4442 26.5278 17.2329 26.5278Z'
      fill='#181818'
    />
    <path
      d='M14.2368 29.557C14.0262 29.557 13.8149 29.476 13.6538 29.3131C13.3316 28.9874 13.3316 28.4603 13.6538 28.1346L14.8527 26.9228C15.175 26.5978 15.6965 26.5978 16.0187 26.9228C16.341 27.2485 16.341 27.7756 16.0187 28.1013L14.8198 29.3131C14.6587 29.476 14.4481 29.557 14.2368 29.557Z'
      fill='#181818'
    />
    <path
      d='M4.64374 28.9513C4.60103 28.9513 4.55757 28.9483 4.51485 28.9415C4.27731 28.9036 4.06824 28.7627 3.9416 28.5552C3.3496 27.5835 2.73738 25.231 4.66023 23.2875L16.6499 11.1692C18.0228 9.77103 19.6039 10.1921 20.6732 10.6299C20.6785 10.6322 20.6837 10.6345 20.689 10.6367L24.2274 12.1712L29.3073 11.3222C29.5524 11.2813 29.8019 11.354 29.9877 11.5199C30.1736 11.6857 30.2755 11.9273 30.2657 12.178C30.2627 12.2598 30.2238 13.0044 29.9075 13.8208C29.4399 15.0274 28.6119 15.7779 27.5119 15.9915L23.5493 16.8436C23.4189 16.8716 23.2832 16.8671 23.1543 16.8307L18.7129 15.5697C18.2745 15.4454 18.019 14.9849 18.1419 14.5426C18.2648 14.0996 18.7204 13.8413 19.1588 13.9655L23.4039 15.1705L27.1761 14.3593C27.1821 14.3578 27.1889 14.3563 27.1949 14.3555C27.8401 14.2329 28.1953 13.6868 28.3886 13.1634L24.2574 13.8542C24.1023 13.8799 23.9427 13.8602 23.798 13.7981L20.046 12.1705C19.1161 11.7902 18.4521 11.6978 17.8174 12.3447L5.82623 24.466C5.21175 25.0871 4.93974 25.8013 5.01692 26.5898C5.02067 26.6246 5.02442 26.6587 5.02891 26.6928C7.04993 24.953 11.7544 20.8979 12.4828 20.2307C12.5577 20.1617 12.6446 20.108 12.739 20.0709C12.7488 20.0671 13.7784 19.6588 14.7278 18.9855C15.1003 18.7212 15.6143 18.8121 15.8758 19.1885C16.1374 19.5649 16.0475 20.0845 15.675 20.3488C14.772 20.9896 13.8346 21.4145 13.4802 21.5644C12.1905 22.7225 5.47253 28.4999 5.17803 28.7529C5.02816 28.8817 4.83783 28.9513 4.64374 28.9513Z'
      fill='#181818'
    />
    <path
      d='M27.2868 9.94914C27.0912 9.94914 26.8956 9.87946 26.7382 9.73782C26.3988 9.43184 26.3688 8.90469 26.6723 8.56159L28.9009 6.03718C29.1774 5.55775 29.1474 5.02379 29.0335 4.5845L27.558 5.92736C27.2201 6.23486 26.6985 6.2076 26.3935 5.86601C26.0885 5.52443 26.1163 4.99728 26.4542 4.68902L28.769 2.58194C28.9451 2.42137 29.1819 2.34639 29.4172 2.37366C29.6525 2.40168 29.8653 2.5312 29.9994 2.72812C30.0616 2.81976 31.5124 4.99198 30.2602 6.98317C30.2362 7.02104 30.2093 7.0574 30.18 7.09072L27.902 9.67117C27.7394 9.85522 27.5131 9.94914 27.2868 9.94914Z'
      fill='#181818'
    />
    <path
      d='M7.64194 30.7688C7.62546 30.7688 7.60897 30.7681 7.59174 30.7673C7.35644 30.7529 7.13838 30.637 6.99225 30.4492C6.76745 30.1591 4.83635 27.559 6.25863 25.7314C6.54039 25.3701 7.0582 25.3072 7.41639 25.592C7.77383 25.8768 7.83603 26.4002 7.55427 26.7622C7.2253 27.1848 7.46285 27.9657 7.78807 28.6095L21.2518 15.0013C21.574 14.6757 22.0955 14.6757 22.4178 15.0013C22.74 15.327 22.74 15.8542 22.4178 16.1798L8.22494 30.5249C8.06983 30.6817 7.86001 30.7688 7.64194 30.7688Z'
      fill='#181818'
    />
    <path
      d='M6.26086 20.5011C6.13422 20.5011 6.00533 20.4716 5.88469 20.4087C5.36388 20.1384 4.57706 19.8059 3.8824 19.5135C3.60589 19.3969 3.34362 19.2863 3.11881 19.1878C2.72165 19.0144 2.47586 18.6629 2.46162 18.2471C2.4354 17.5109 3.16677 16.93 3.55718 16.7248C5.16156 15.8833 6.0473 14.8176 7.16833 13.4687C7.41712 13.1688 7.6749 12.859 7.95066 12.5401C8.73299 11.6343 9.30625 11.42 10.3344 11.0897C10.8417 10.9269 11.3527 10.8254 11.8473 10.7269C12.5952 10.5785 13.3018 10.4384 13.9418 10.1028C14.1523 9.99226 14.3539 9.86653 14.5682 9.73323C15.0201 9.45148 15.4877 9.15988 16.0887 8.98189C17.8452 8.46081 19.2165 9.55297 20.2154 10.5626C20.5376 10.8883 20.5376 11.4154 20.2154 11.7411C19.8932 12.066 19.3716 12.066 19.0494 11.7411C17.7717 10.4497 17.0943 10.4202 16.5525 10.5808C16.1644 10.6959 15.8256 10.9072 15.4337 11.1518C15.2037 11.295 14.9661 11.4434 14.7009 11.5828C13.8518 12.0281 12.9946 12.1978 12.1658 12.3629C11.6959 12.4561 11.2523 12.5447 10.8349 12.6788C9.99266 12.9499 9.70865 13.0408 9.19309 13.6376C8.92782 13.9444 8.67604 14.2481 8.43175 14.5412C7.35792 15.8341 6.34029 17.0588 4.6415 18.0275C5.33241 18.3191 6.09301 18.6433 6.64004 18.9265C7.04469 19.1371 7.2043 19.6392 6.99673 20.0482C6.84911 20.336 6.55986 20.5011 6.26086 20.5011Z'
      fill='#181818'
    />
    <path
      d='M22.6285 10.7728C20.8218 10.7728 19.4062 9.26637 19.4062 7.34334V6.47688C19.4062 4.55385 20.8218 3.04739 22.6285 3.04739C24.4352 3.04739 25.8507 4.55385 25.8507 6.47688V7.34259C25.8507 9.26637 24.4352 10.7728 22.6285 10.7728ZM22.6285 4.71367C21.7315 4.71367 21.0548 5.47182 21.0548 6.47688V7.34259C21.0548 8.34765 21.7315 9.10581 22.6285 9.10581C23.5255 9.10581 24.2021 8.34765 24.2021 7.34259V6.47688C24.2021 5.47182 23.5255 4.71367 22.6285 4.71367Z'
      fill='#181818'
    />
  </svg>
);

