import { createEffect, createEvent, restore } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import {
  TCoachingSchools,
  TCoachingSchool,
  TMasterClass,
  TMasterClasses,
  TTestimonials,
  TReply,
} from './types';
import { useStore } from 'effector-react';
import { QueryFunctionContext } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

const initialTestimonials = {
  rating: 0,
  voices: 0,
  replies: [],
};

export const getCoachingSchoolsFx = createEffect<
  QueryFunctionContext,
  AxiosResponse<{
    data: TCoachingSchools;
  }>,
  AxiosError<{
    error: string;
  }>
>(() => apiClient.get(Endpoint.GET_COACHING_SCHOOLS));

export const getCoachingSchoolFx = createEffect<
  { id?: number; purchased?: boolean },
  AxiosResponse<{
    data: TCoachingSchool;
  }>,
  AxiosError<{
    error: string;
  }>
>(({ id, purchased }) =>
  purchased
    ? apiClient.get(`${Endpoint.GET_PURCHASED_COACHING_SCHOOL}/${id}`)
    : apiClient.get(`${Endpoint.GET_COACHING_SCHOOLS}/${id}`)
);

export const getMasterClassesFx = createEffect<
  unknown,
  AxiosResponse<{
    data: TMasterClasses[];
  }>,
  AxiosError<{
    error: string;
  }>
>(() => apiClient.get(Endpoint.GET_MASTER_CLASSES));

export const getMasterClassFx = createEffect<
  number,
  AxiosResponse<{
    data: TMasterClass;
  }>,
  AxiosError<{
    error: string;
  }>
>(id => apiClient.get(`${Endpoint.GET_MASTER_CLASSES}/${id}`));

export const addReplyFx = createEffect<
  {
    mark: number;
    text: string;
    school_id: number;
  },
  AxiosResponse<{
    data: TReply;
  }>,
  AxiosError<{
    error: { mark: string; text: string };
  }>
>(data => apiClient.post(Endpoint.ADD_REPLY_COACHING_SCHOOL, data));

export const setCoachingSchools = createEvent<TCoachingSchools[]>();
export const setCoachingSchool = createEvent<TCoachingSchool>();
export const setCoachingActiveSchool = createEvent<TCoachingSchools | null>();
export const setMasterClasses = createEvent<TMasterClasses[]>();
export const setMasterClass = createEvent<TMasterClass | null>();
export const setTestimonials = createEvent<TTestimonials>();
export const setRepliesWithOffset = createEvent<TReply[]>();
export const setReply = createEvent<TReply>();
export const clearCoachingSchool = createEvent();
export const clearMasterClass = createEvent();
export const clearSchoolTestimonials = createEvent();

export const $coachingSchools = restore(setCoachingSchools, []);
export const $coachingSchool = restore(setCoachingSchool, null).reset(
  clearCoachingSchool
);
export const $coachingActiveSchool = restore(setCoachingActiveSchool, null);
export const $masterClasses = restore(setMasterClasses, []);
export const $masterClass = restore(setMasterClass, null);
export const $testimonials = restore(setTestimonials, initialTestimonials)
  .on(setTestimonials, (_, payload) => payload)
  .on(setRepliesWithOffset, (state, payload) => ({
    ...state,
    replies: [...state.replies, ...payload],
  }))
  .on(setReply, (state, payload) => ({
    ...state,
    replies: [...state.replies, payload],
  }))
  .reset(clearSchoolTestimonials);

export const useCoachingSchools = (): TCoachingSchools[] =>
  useStore($coachingSchools);
export const useCoachingSchool = (): TCoachingSchool | null =>
  useStore($coachingSchool);
export const useCoachingActiveSchool = (): TCoachingSchools | null =>
  useStore($coachingActiveSchool);
export const useMasterClasses = (): TMasterClasses[] =>
  useStore($masterClasses);
export const useMasterClass = (): TMasterClass | null => useStore($masterClass);
export const useTestimonialsSchool = (): TTestimonials =>
  useStore($testimonials);
