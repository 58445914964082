import { regExpHTML } from 'constants/regExps';
import { ChevronDownSmallIcon } from 'icons';
import { ChevronUpSmallIcon } from 'icons';
import { TCourse } from 'models/courses/types';
import React, { useState } from 'react';
import { theme } from 'ui/theme';
import { truncate } from 'utilities/truncate';
import { Title, Text, MoreButton } from './styles';

const MAX_WORDS_VISIBLE = 100;

export const Description: React.FC<Pick<TCourse, 'description'>> = ({
  description,
}) => {
  const [visibility, setVisibility] = useState(false);

  const shortDescr = truncate(description, MAX_WORDS_VISIBLE);

  return (
    <div id='description'>
      <Title>Описание</Title>
      {regExpHTML.test(description) ? (
        <Text
          dangerouslySetInnerHTML={{
            __html: `${visibility ? description : shortDescr}`,
          }}
        />
      ) : (
        <Text>{visibility ? description : shortDescr}</Text>
      )}
      {description.split(' ').length > MAX_WORDS_VISIBLE && (
        <MoreButton
          buttonType={'link'}
          icon={visibility ? ChevronUpSmallIcon : ChevronDownSmallIcon}
          iconAlign='right'
          iconFill={theme.colors.mainColor}
          onClick={() => setVisibility(!visibility)}
        >
          {visibility ? 'Скрыть' : 'Подробнее'}
        </MoreButton>
      )}
    </div>
  );
};
