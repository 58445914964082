import { useMutation } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { ChatSendMessage } from './types';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';

export const useChatSendMessageQuery: ChatSendMessage = () => {
  return useMutation(
    'chatSendMessage',
    message => apiClient.post(Endpoint.CHAT_SEND_MESSAGE, message),
    {
      onError: res => {
        toast.error(<ErrorMessage text={res.response?.data.error} />);
      },
    }
  );
};
