import React from 'react';
import { DateView } from 'ui/Date';
import {
  Address,
  DateWrap,
  InfoWrap,
  MainWrap,
  Title,
  Image,
  DisabledItemWrap,
  ItemWrap,
} from './styles';
import { NoImage } from 'ui/NoImage';
import { CatalogEvent, TrainingCatalogItem } from 'models/catalog/types';
import { baseUrl } from 'api/apiClient';
import { Path } from 'constants/paths';
import { showAuthModal, useUserProfile } from 'models/auth/model';
import { Link } from 'react-router-dom';

//TODO: Вынести компонент в сторибук

type meetingType = 'event' | 'intensive' | 'training' | 'master-class';
type LargeCatalogItemProps = {
  itemType?: meetingType;
  disabled?: boolean;
};

export const LargeCatalogItem: React.FC<
  LargeCatalogItemProps & CatalogEvent & TrainingCatalogItem
> = ({ name, place, date_event, picture, id, itemType, disabled }) => {
  const eventDate = new Date(date_event);
  const user = useUserProfile();
  const url =
    itemType === 'intensive'
      ? `${Path.vipClubMeetings}/${id}`
      : itemType === 'training'
      ? `${Path.catalogTrainings}/${id}`
      : itemType === 'master-class'
      ? `${Path.catalogInnerInsight + Path.masterClass}/${id}`
      : `${Path.catalogEvents}/${id}`;

  if (disabled) {
    return (
      <DisabledItemWrap>
        <DateWrap>
          <DateView date={eventDate} />
          {picture ? <Image src={baseUrl + picture} alt={name} /> : <NoImage />}
        </DateWrap>
        <MainWrap>
          <Title>{name}</Title>
          <Address>{place}</Address>
        </MainWrap>
        <InfoWrap>
          {/*TODO: Заказчик просил скрыть возрастные ограничения.*/}
          {/*{restriction ? <Age age={restriction} /> : null}*/}
        </InfoWrap>
      </DisabledItemWrap>
    );
  }

  return (
    <ItemWrap
      as={user ? Link : 'div'}
      to={user ? url : ''}
      onClick={() => !user && showAuthModal()}
    >
      <DateWrap>
        <DateView date={eventDate} />
        {picture ? <Image src={baseUrl + picture} alt={name} /> : <NoImage />}
      </DateWrap>
      <MainWrap>
        <Title>{name}</Title>
        <Address>{place}</Address>
      </MainWrap>
      <InfoWrap>
        {/*TODO: Заказчик просил скрыть возрастные ограничения.*/}
        {/*{restriction ? <Age age={restriction} /> : null}*/}
      </InfoWrap>
    </ItemWrap>
  );
};
