import styled from 'styled-components';

export const ItemWrap = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  padding: 32px 0;
  display: flex;
`;

export const Photo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

export const PhotoBlank = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.actionColor};
`;

export const PhotoBlankText = styled.p`
  ${({ theme }) => theme.typography.headline2};
`;

export const Inner = styled.div`
  margin: 0 0 0 24px;
  max-width: 592px;
`;

export const Name = styled.p`
  margin: 12px 0 0;
  ${({ theme }) => theme.typography.headline3};
`;

export const Date = styled.p`
  margin: 12px 0 0;
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const Text = styled.p`
  margin: 24px 0 0;
`;
