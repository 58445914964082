import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Schools = styled.div`
  display: flex;
  min-width: 960px;
`;
