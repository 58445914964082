import styled from "styled-components";

export const Wrap = styled.div`
  margin-top: 60px;
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline2}
`;

export const ItemsWrap = styled.div`
  margin-top: 24px;
  display: flex;
`;

export const Item = styled.div`
  display: flex;
  width: 260px;
  align-items: center;
`;

export const Icon = styled.div`
  line-height: 0;
  padding: 14px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
`;

export const Text = styled.span`
  margin-left: 20px;
`;
