import React from 'react';

export const AwardIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 1.5C6.51472 1.5 4.5 3.51472 4.5 6C4.5 8.48528 6.51472 10.5 9 10.5C11.4853 10.5 13.5 8.48528 13.5 6C13.5 3.51472 11.4853 1.5 9 1.5ZM3 6C3 2.68629 5.68629 0 9 0C12.3137 0 15 2.68629 15 6C15 9.31371 12.3137 12 9 12C5.68629 12 3 9.31371 3 6Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.7439 9.66651C12.1545 9.61203 12.5315 9.90074 12.586 10.3114L13.4935 17.1514C13.5314 17.4368 13.4025 17.7188 13.1618 17.8769C12.9211 18.035 12.6111 18.0413 12.3641 17.8931L9.00002 15.8746L5.63589 17.8931C5.38894 18.0413 5.07895 18.035 4.83824 17.8768C4.59753 17.7187 4.46863 17.4367 4.50655 17.1512L5.41405 10.3187C5.46858 9.90814 5.84566 9.61949 6.25627 9.67403C6.66687 9.72856 6.95553 10.1056 6.90099 10.5162L6.19835 15.8064L8.61415 14.3569C8.85166 14.2144 9.14838 14.2144 9.38589 14.3569L11.8019 15.8065L11.099 10.5086C11.0446 10.098 11.3333 9.72099 11.7439 9.66651Z'
    />
  </svg>
);
