import { LargeCatalogItem } from 'components/Catalog/common/LargeCatalogItem';
import { useMasterClasses } from 'models/inner-insight/model';
import { useGetMasterClassesQuery } from 'queries/inner-insight/useGetMasterClassesQuery';
import React from 'react';
import { Preloader } from 'ui/Preloader';
import { Wrap, MasterClassesList, MasterClassesTitle } from './styles';

export const MasterClasses: React.FC = () => {
  const { isLoading, isFetching, isError } = useGetMasterClassesQuery();
  const masterClasses = useMasterClasses();

  if (isError) return <div>Не удалось загрузить мастер классы</div>;
  if (isLoading || isFetching) return <Preloader />;
  if (!masterClasses.length) return <div>Нет действующих мастер классов</div>;

  return (
    <Wrap>
      <MasterClassesTitle>Maстер-классы</MasterClassesTitle>
      <MasterClassesList>
        {masterClasses.map(item => (
          <LargeCatalogItem
            key={item.id}
            {...item}
            id={String(item.id)}
            itemType='master-class'
          />
        ))}
      </MasterClassesList>
    </Wrap>
  );
};
