import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ModalProps } from './types';
import { XCloseIcon } from 'icons';
import { Button } from 'ui/Button';

const Wrap = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.globalOverlay};
  background-color: ${({ theme }) => theme.colors.mainColor + '50'};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  top: 0;
  left: 0;
`;

const Inner = styled.div`
  padding: 60px;
  border-radius: 28px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  min-width: 620px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
  align-self: flex-start;
`;

const CloseIcon = styled(Button)<{ customStyle?: string }>`
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px !important;
  ${({ customStyle }) => (customStyle ? customStyle : '')}
`;

const Modal: React.FC<ModalProps> = ({
  handleClose,
  children,
  crossIconStyle,
  ...rest
}) => {
  // скрытие скролл бара во время модалки
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <Wrap>
      <Inner {...rest}>
        {children}
        <CloseIcon
          size={'medium'}
          buttonType={'link'}
          onClick={handleClose}
          customStyle={crossIconStyle}
          iconFill={'#b1b1b1'}
          icon={XCloseIcon}
        />
      </Inner>
    </Wrap>
  );
};

export default Modal;
