import { useStore } from 'effector-react';
import { $testimonials } from 'models/courses/model';
import React from 'react';
import styled from 'styled-components';
import { RatingStars } from 'ui/RatingStars/';
import { declension } from 'utilities/declension';

const DECLINATION_VOICES = ['оценка', 'оценки', 'оценок'];

const StyledRatingStars = styled(RatingStars)`
  margin: 0;
  & + & {
    margin-left: 8px;
  }
`;

const ReviewRating = styled.div`
  ${({ theme }) => theme.typography.headline3};
  color: ${({ theme }) => theme.colors.mainBgColor};
  margin-left: 12px;
  align-self: flex-start;
`;

const ReviewCount = styled.div`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

const RatingStarsWrap = styled.div`
  font-size: 0;
  display: flex;
  align-items: center;
`;

const Rating: React.FC = () => {
  const testimonials = useStore($testimonials);

  return (
    <RatingStarsWrap>
      <StyledRatingStars size={'medium'} rating={testimonials?.rating ?? 0} />

      {!!testimonials?.rating && (
        <ReviewRating>{testimonials.rating}</ReviewRating>
      )}
      <ReviewCount>
        (
        {testimonials?.voices
          ? `${testimonials?.voices} ${declension(
              DECLINATION_VOICES,
              testimonials?.voices
            )}`
          : 'нет оценок'}
        )
      </ReviewCount>
    </RatingStarsWrap>
  );
};

export default Rating;
