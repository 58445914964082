import React, { useState } from 'react';
import { StyledPreloader } from '../styles';
import { useFreeCoursesQuery } from 'queries/useFreeCoursesQuery';
import { CatalogFreeCourses } from 'models/catalog/types';
import { useStore } from 'effector-react';
import { $freeCoursesData } from 'models/catalog/model';
import { FreeItem } from '../../common/FreeItem';
import { LogoIcon } from 'icons';
import {
  NoItemsText,
  Title,
  Text,
  Wrap,
  Links,
  GooglePlayLink,
  AppStoreLink,
  ItemWrap,
} from './styles';
import { Modal } from 'ui/Modal';
import GooglePlay from 'images/google-play-badge.png';
import AppStore from 'images/App_store_download.svg';

export const Free: React.FC = () => {
  const { isFetching, isLoading, isError } = useFreeCoursesQuery();
  const data: CatalogFreeCourses[] = useStore($freeCoursesData);
  const [showModal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <ItemWrap>
      {/*TODO: Дополнить по мере расширения эндпоинта*/}
      {isLoading || isFetching ? (
        <StyledPreloader />
      ) : data.length ? (
        data.map(({ id, name, video_pic, video_date }) => {
          return (
            <FreeItem
              id={id.toString()}
              key={id}
              title={name}
              image={video_pic || ''}
              handleClick={openModal}
              date={video_date}
            />
          );
        })
      ) : (
        <NoItemsText>
          {isError ? 'Не удалось загрузить товары' : 'Нет товаров'}
        </NoItemsText>
      )}
      {showModal ? (
        <Modal handleClose={closeModal}>
          <Wrap>
            <div>{LogoIcon}</div>
            <Title>Доступ к вебинару закрыт!</Title>
            <Text>
              Для изучения бесплатных материалов воспользуйтесь нашим мобильным
              приложением
            </Text>
            <Links>
              {/*TODO: Добавить ссылки от мобильщика*/}
              <GooglePlayLink href='#!'>
                <img src={GooglePlay} alt='Google Play Download' />
              </GooglePlayLink>
              {/*TODO: Добавить ссылки от мобильщика*/}
              <AppStoreLink href='#!'>
                <img src={AppStore} alt='App Store Download' />
              </AppStoreLink>
            </Links>
          </Wrap>
        </Modal>
      ) : null}
    </ItemWrap>
  );
};
