import styled from 'styled-components';

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline1};
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: -40px;
  margin-left: -12px;
  margin-right: -12px;
`;

export const Wrap = styled.div`
  margin-top: 24px;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;
