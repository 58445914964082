import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { clearGiftCards, useSortedGiftCards } from 'models/catalog/model';
import { Info } from './Info';
import Ruble from 'components/common/Ruble';
import { baseUrl } from 'api/apiClient';
import {
  StyledPreloader,
  StyledButtonPreloader,
  NoItemText,
  Wrap,
  Preview,
  Photo,
  MainPhoto,
  Image,
  StyledNoImage,
  ThumbPhoto,
  ThumbItem,
  Options,
  Title,
  Author,
  OfferOptions,
  OfferOption,
  Price,
  PriceText,
  AddToCart,
} from './styles';
import { useGetGiftCardsQuery } from 'queries/catalog/useGetGiftCardsQuery';
import { usePurchaseGiftCardQuery } from 'queries/catalog/usePurschaseGiftCardQuery';
import { Path } from 'constants/paths';
import { useHistory } from 'react-router-dom';
import { GiftCard } from 'models/catalog/types';
import { ErrorMessage } from 'ui/ErrorMessage';
import { toast } from 'react-toastify';
import { getImagePath } from 'utilities/getImagePath';
import { showAuthModal } from 'models/auth/model';
import { $user} from 'models/auth';

const DETAIL_GIFT_CARD_TEXT =
  'Электронные сертификаты от «Будь с мужиком» — идеальный выбор на любой праздник, по поводу и без. Радуйте родных, удивляйте друзей, поздравляйте коллег! По электронному сертификату можно выбирать что угодно и оплатить до 50% от покупки.';

export const GiftCardItem: React.FC = () => {
  const { isLoading, isFetching, isError } = useGetGiftCardsQuery();
  const {
    mutate: buyCard,
    isLoading: isButtonLoading,
  } = usePurchaseGiftCardQuery();
  const data = useSortedGiftCards();
  const history = useHistory();
  const [currentGiftCard, setCurrentGiftCard] = useState<GiftCard | null>(null);
  const showPreloader = isLoading || isFetching;
  const [mainPicture, setMainPicture] = useState('');
  const isAuthed = useStore($user);

  useEffect(() => {
    const image = getImagePath(currentGiftCard);
    setMainPicture(image);
  }, [currentGiftCard]);

  useEffect(() => {
    setCurrentGiftCard(data?.[0]);
  }, [data]);

  useEffect(() => {
    return () => clearGiftCards();
  }, []);

  const handleAddToCart = () => {
    if (currentGiftCard) {
      buyCard(currentGiftCard.id.toString(), {
        onSuccess: res => {
          history.push(
            `${Path.directBuy.replace(':key', 'card')}/${res.data.order_id}`
          );
        },
        onError: () => {
          toast.error(<ErrorMessage text={'Не удалось купить'} />);
        },
      });
    }
  };

  if (!showPreloader && !data)
    return <NoItemText>Такого товара нет!</NoItemText>;

  if (isError) return <NoItemText>Не удалось загрузить товар</NoItemText>;

  return showPreloader ? (
    <StyledPreloader />
  ) : (
    <Wrap>
      <Preview>
        <Photo>
          <MainPhoto>
            {mainPicture ? (
              <Image src={mainPicture} alt={currentGiftCard?.name} />
            ) : (
              <StyledNoImage />
            )}
          </MainPhoto>

          {data?.length ? (
            <ThumbPhoto>
              {data.map((item, index) => {
                const { path } = item.pictures[0];
                return (
                  <ThumbItem
                    key={index}
                    selected={baseUrl + path === mainPicture}
                    onClick={() => setCurrentGiftCard(item)}
                  >
                    <Image src={`${baseUrl}${path}`} alt={item.name} />
                  </ThumbItem>
                );
              })}
            </ThumbPhoto>
          ) : null}
        </Photo>
        <Options>
          <Title>Подарочная карта</Title>
          <Author>Выберите номинал</Author>
          {data?.length ? (
            <OfferOptions>
              {data.map(item => {
                const { price } = item;
                return (
                  <OfferOption
                    key={item.id}
                    preview={item.name}
                    price={price}
                    currency={Ruble}
                    pressed={currentGiftCard?.id === item.id}
                    onClick={() => {
                      setCurrentGiftCard(item);
                    }}
                  />
                );
              })}
            </OfferOptions>
          ) : null}
          <Price>
            {currentGiftCard?.price && (
              <PriceText>
                {currentGiftCard.price} {Ruble}
              </PriceText>
            )}
            {isButtonLoading ? (
              <StyledButtonPreloader />
            ) : (
              <AddToCart onClick={() => isAuthed ? handleAddToCart() : showAuthModal()}>Купить</AddToCart>
            )}
          </Price>
        </Options>
      </Preview>
      <Info detailText={DETAIL_GIFT_CARD_TEXT} />
    </Wrap>
  );
};
