import React from 'react';
import Ruble from 'components/common/Ruble';
import {
  Inner,
  Wrap,
  Text,
  Title,
  Action,
  Price,
  BannerButton,
} from './styles';
import { formatPriceToString } from 'utilities/formatPriceToString';
import { SecretChatListItemType } from 'models/catalog/types';
import { Link } from 'react-scroll/modules';

export type SecretChatBannerProps = {
  data: Pick<
    SecretChatListItemType,
    'name' | 'description' | 'price' | 'picture'
  >;
};

export const Banner: React.FC<SecretChatBannerProps> = ({ data, ...rest }) => {
  const { price, name, description, picture } = data;

  return (
    <Wrap bg={picture} {...rest}>
      <Inner>
        <Title>{name}</Title>
        <Text>{description}</Text>
        <Action>
          <Price>
            от {formatPriceToString(price)} {Ruble}
          </Price>
          <Link to={'prices'} spy smooth offset={-100}>
            <BannerButton size='large'>Приобрести</BannerButton>
          </Link>
        </Action>
      </Inner>
    </Wrap>
  );
};
