import { CartNotificationIcon, EmptyCartIcon } from 'icons';
import React from 'react';
import { Path } from 'constants/paths';
import { $cart } from 'models/cart';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useCartQuery } from 'queries/cart/useCartQuery/useCartQuery';

//TODO: Разузнать про ховеры этих иконов в шапке
export const CartLink = styled(Link)`
  padding: 15px;
  font-size: 0;
  margin: 0 112px 0 4px;
  border-radius: 50%;
  &:focus {
    ${({ theme }) => theme.additional.noOutline};
  }
`;

export const Cart: React.FC = props => {
  useCartQuery();
  const cart = useStore($cart);
  return (
    <CartLink to={Path.buy} {...props}>
      {cart?.data.length ? CartNotificationIcon : EmptyCartIcon}
    </CartLink>
  );
};
