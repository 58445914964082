import React, { useRef, useState } from 'react';
import { Button } from 'ui/Button';
import { ChevronDownSmallIcon } from 'icons';
import { DropdownContainer, Text, LogoutButton, Divider } from './styles';
import { Link, useHistory } from 'react-router-dom';
import TokenService from 'services/TokenService';
import { removeUser } from 'models/auth/model';
import { Path } from 'constants/paths';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { scrollToTop } from 'utilities/scrollToTop';

export const Dropdown: React.FC = props => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const onSelect = () => {
    scrollToTop();
    setVisible(!visible);
  };
  const closeDropDownMenu = () => setVisible(false);

  const ref = useRef(null);
  useOnClickOutside(ref, closeDropDownMenu);

  return (
    <div ref={ref} {...props}>
      <Button
        icon={ChevronDownSmallIcon}
        buttonType={'link'}
        onClick={onSelect}
      />
      {visible ? (
        <DropdownContainer>
          <div>
            <Link to={`${Path.account}/my-data`} onClick={onSelect}>
              <Text>Мои данные </Text>
            </Link>
            <Link to={`${Path.account}/orders`} onClick={onSelect}>
              <Text>Заказы</Text>
            </Link>
            <Link to={`${Path.account}/bonus-account`} onClick={onSelect}>
              <Text>Бонусный счет</Text>
            </Link>
            <Link to={`${Path.account}/premium`} onClick={onSelect}>
              <Text>Премия</Text>
            </Link>
            <Divider />
            <LogoutButton
              buttonType={'link'}
              onClick={() => {
                TokenService.removeToken();
                removeUser();
                history.push('/');
                location.reload();
              }}
            >
              Выйти
            </LogoutButton>
          </div>
        </DropdownContainer>
      ) : null}
    </div>
  );
};
