import styled from 'styled-components';

export const Wrap = styled.div`
  & > button {
    height: 54px;
  }
`;

export const TextSC = styled.div`
  width: 500px;
  ${({ theme }) => theme.typography.bodyText1};
  line-height: 28.8px;
  margin: 24px 0 24px 0;
`;
