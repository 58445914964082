import React from 'react';

export const RasstanovkiIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7897 4.05621L7.38071 6.937L4.49992 14.346L11.9089 11.4652L14.7897 4.05621ZM16.5995 3.54084C16.9148 2.73 16.1159 1.93115 15.3051 2.24642L6.63204 5.6187C6.3707 5.72032 6.16403 5.92699 6.06241 6.18833L2.69013 14.8614C2.37486 15.6722 3.17372 16.4711 3.98455 16.1558L12.6576 12.7835C12.9189 12.6819 13.1256 12.4752 13.2272 12.2139L16.5995 3.54084Z'
      />
    </g>
  </svg>
);
