import React from 'react';
import { TextSC, Wrap } from './styled';
import { Button } from 'ui/Button';
import { Link } from 'react-router-dom';
import { Path } from 'constants/paths';
import { Filter } from '../Diplomas';

interface IProps {
  sortType: Filter;
}

export const EmptyItem: React.FC<IProps> = ({ sortType }) => {
  const descriptions = {
    [Filter.AVAILABLE]: 'доступных',
    [Filter.OWNED]: 'полученных',
    [Filter.ALL]: '',
  };

  return (
    <Wrap>
      <TextSC>
        {`У вас пока нет ${descriptions[sortType]} дипломов. Выберите наш продукт по душе и
        начните развиваться прямо сейчас!`}
      </TextSC>
      <Link to={Path.catalogCourses}>
        <Button buttonType='primary'>Выбрать</Button>
      </Link>
    </Wrap>
  );
};
