import styled from 'styled-components';
import { Button } from 'ui/Button';
import { InputText } from 'ui/InputText';
import { LoyaltyPoints } from './LoyaltyPoints';

export const PromoCodeForm = styled.form`
  display: flex;
  align-items: center;
`;

export const SubTitle = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.secondTextColor};
  max-width: 491px;
  & + ${PromoCodeForm} {
    margin-top: 24px;
  }
`;

export const Title = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline2};
  & + ${SubTitle} {
    margin-top: 24px;
  }
`;

export const StyledButton = styled(Button)``;

export const Item = styled(InputText)`
  flex-grow: 1;
  & + & {
    margin: 24px 0 0;
  }
  & + ${StyledButton} {
    margin-left: 24px;
  }
`;

export const StyledLoyaltyPoints = styled(LoyaltyPoints)`
  margin-top: 24px;
`;
