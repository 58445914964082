import React from 'react';

export const TreningsIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.179 1.56359C13.3993 1.64331 13.5696 1.82137 13.6396 2.04494L16.9672 12.6836C17.0384 12.9113 16.997 13.1594 16.8557 13.3516C16.7144 13.5439 16.49 13.6575 16.2514 13.6575H10.6806C10.5025 14.1488 10.1479 14.7997 9.59532 15.3534C8.98676 15.9632 8.1079 16.4812 6.95614 16.4812C5.74338 16.4812 4.91032 15.8361 4.39527 15.1039C4.06596 14.6358 3.85177 14.116 3.72383 13.655C3.01517 13.6211 1.64562 13.2417 1.19 11.7676C0.937274 10.95 0.940358 10.213 1.16607 9.58461C1.39152 8.95695 1.81694 8.50017 2.29889 8.20756C2.85188 7.87181 9.33111 3.69513 12.517 1.6387C12.7138 1.51166 12.9587 1.48388 13.179 1.56359ZM5.30734 13.6575C5.38924 13.857 5.4934 14.0579 5.62212 14.2409C5.93467 14.6852 6.35364 14.9812 6.95614 14.9812C7.61962 14.9812 8.13579 14.6924 8.53358 14.2938C8.73509 14.0919 8.89843 13.8686 9.02409 13.6575H5.30734ZM12.5022 3.43349C11.794 3.89046 10.9879 4.41032 10.1521 4.94913L12.2057 12.1575H15.231L12.5022 3.43349ZM3.07735 9.48974C3.43708 9.27133 6.10752 7.55487 8.8343 5.7983L10.646 12.1575H3.8479V12.1574L3.8354 12.1576C3.69599 12.1599 3.42474 12.1204 3.17013 11.9844C2.93345 11.858 2.72642 11.6589 2.6231 11.3246C2.44726 10.7557 2.48201 10.3582 2.57776 10.0917C2.67377 9.82438 2.85342 9.6257 3.07735 9.48974Z'
    />
  </svg>
);
