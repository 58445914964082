import { regExpOnlyCapitalLetter } from 'constants/regExps';

type FormatString = (string: string, value: string) => string[];

export const formatSuggestionString: FormatString = (string, value) => {
  const regexp = new RegExp(`${value}`, 'ig');
  return string
    .replace(regexp, word =>
      regExpOnlyCapitalLetter.test(word)
        ? `|${value}|`
        : `|${value.replace(/^\W/, c => c.toUpperCase())}|`
    )
    .split('|');
};
