import React from 'react';

export const UsersIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.09835 11.5984C1.80161 10.8951 2.75544 10.5 3.75 10.5H9.75C10.7446 10.5 11.6984 10.8951 12.4017 11.5984C13.1049 12.3016 13.5 13.2554 13.5 14.25V15.75C13.5 16.1642 13.1642 16.5 12.75 16.5C12.3358 16.5 12 16.1642 12 15.75V14.25C12 13.6533 11.7629 13.081 11.341 12.659C10.919 12.2371 10.3467 12 9.75 12H3.75C3.15326 12 2.58097 12.2371 2.15901 12.659C1.73705 13.081 1.5 13.6533 1.5 14.25V15.75C1.5 16.1642 1.16421 16.5 0.75 16.5C0.335786 16.5 0 16.1642 0 15.75V14.25C0 13.2554 0.395088 12.3016 1.09835 11.5984Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.75 3C5.50736 3 4.5 4.00736 4.5 5.25C4.5 6.49264 5.50736 7.5 6.75 7.5C7.99264 7.5 9 6.49264 9 5.25C9 4.00736 7.99264 3 6.75 3ZM3 5.25C3 3.17893 4.67893 1.5 6.75 1.5C8.82107 1.5 10.5 3.17893 10.5 5.25C10.5 7.32107 8.82107 9 6.75 9C4.67893 9 3 7.32107 3 5.25Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.2738 11.16C14.3773 10.7589 14.7864 10.5178 15.1874 10.6213C15.9919 10.829 16.7047 11.2981 17.2137 11.9547C17.7228 12.6114 17.9993 13.4186 17.9999 14.2494L17.9999 15.75C17.9999 16.1642 17.6642 16.5 17.2499 16.5C16.8357 16.5 16.4999 16.1642 16.4999 15.75L16.4999 14.2506C16.4999 14.2505 16.4999 14.2506 16.4999 14.2506C16.4995 13.7521 16.3336 13.2677 16.0282 12.8737C15.7228 12.4797 15.2951 12.1983 14.8124 12.0737C14.4114 11.9701 14.1702 11.5611 14.2738 11.16Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.2734 2.16147C11.3761 1.7602 11.7847 1.5182 12.186 1.62094C12.9926 1.82747 13.7076 2.2966 14.2181 2.95435C14.7287 3.61211 15.0058 4.42109 15.0058 5.25375C15.0058 6.08641 14.7287 6.89539 14.2181 7.55315C13.7076 8.21091 12.9926 8.68003 12.186 8.88656C11.7847 8.98931 11.3761 8.7473 11.2734 8.34603C11.1706 7.94476 11.4126 7.53618 11.8139 7.43344C12.2979 7.30952 12.7269 7.02805 13.0332 6.63339C13.3395 6.23873 13.5058 5.75335 13.5058 5.25375C13.5058 4.75416 13.3395 4.26877 13.0332 3.87411C12.7269 3.47946 12.2979 3.19798 11.8139 3.07406C11.4126 2.97132 11.1706 2.56274 11.2734 2.16147Z'
    />
  </svg>
);

