import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { RadioProps } from './types';

const RadioKnob = styled.span`
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  transition: background-color 0.3s;
  font-size: 0;
`;
const RadioInput = styled.input`
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
`;
const RadioText = styled.span`
  ${props => props.theme.typography.bodyText2};
`;
const RadioInner = styled.span`
  border-radius: 50%;
  transition: background-color 0.3s;
`;

const RadioLabel = styled.label<
  RadioProps & InputHTMLAttributes<HTMLInputElement>
>`
  padding: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  & ${RadioInner} {
    display: block;
    background-color: ${props =>
      props.checked
        ? props.theme.colors.mainColor
        : props.theme.colors.mainBgColor};
    width: ${props => (props.radioSize === 'large' ? '33px' : '20px')};
    height: ${props => (props.radioSize === 'large' ? '33px' : '20px')};
    border: ${props => `2px solid ${props.theme.colors.secondColor}`};
  }

  & ${RadioInput}:checked + ${RadioInner} {
    ${props =>
      props.checked
        ? props.radioSize === 'large'
          ? `border: 12px solid ${props.theme.colors.actionColor};`
          : `border: 7px solid ${props.theme.colors.actionColor};`
        : null}
  }
  & ${RadioKnob} + ${RadioText} {
    margin-left: 8px;
  }
`;

export const Radio: React.FC<RadioProps> = ({
  labelText,
  radioSize = 'small',
  checked,
  className,
  input,
  ...props
}) => {
  return (
    <RadioLabel
      radioSize={radioSize}
      checked={checked}
      className={className}
      {...input}
    >
      <RadioKnob>
        <RadioInput type='radio' checked={checked} {...input} {...props} />
        <RadioInner />
      </RadioKnob>
      {labelText ? <RadioText>{labelText}</RadioText> : null}
    </RadioLabel>
  );
};

export default Radio;
