import styled from 'styled-components';

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0;
  margin: 0 10px 0 0;
  & > svg {
    width: 60px;
    height: 60px;
    fill: ${({ theme }) => theme.colors.thirdColor};
  }
`;

export const Title = styled.div`
  width: 501px;
  text-align: center;
  margin-top: 24px;
  ${({ theme }) => theme.typography.headline2};
`;

export const Text = styled.div`
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Actions = styled.div`
  margin-top: 24px;
  display: flex;
  button:nth-of-type(2) {
    margin-left: 24px;
  }
`;
