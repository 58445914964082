import React from 'react';

export const ConsultationIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.0425 2.70737C5.59072 2.70737 2.7925 5.50559 2.7925 8.95737C2.7925 10.0462 3.07028 11.0679 3.55826 11.9577L3.72572 12.2631L3.60049 12.588C3.42626 13.0401 3.13193 13.8356 2.92617 14.5536C2.85178 14.8131 2.79324 15.0481 2.75536 15.2448C2.95203 15.2069 3.18691 15.1484 3.44645 15.074C4.16444 14.8682 4.95999 14.5738 5.41208 14.3995L5.73703 14.2743L6.0424 14.4417C6.93218 14.9296 7.95378 15.2074 9.0425 15.2074C12.4943 15.2074 15.2925 12.4091 15.2925 8.95737C15.2925 5.50559 12.4943 2.70737 9.0425 2.70737ZM2.3381 15.2914C2.32387 15.29 2.32613 15.2888 2.34003 15.2916C2.33936 15.2915 2.33871 15.2915 2.3381 15.2914ZM2.70854 15.6601C2.71128 15.674 2.71013 15.6763 2.70872 15.662C2.70866 15.6614 2.7086 15.6608 2.70854 15.6601ZM1.2925 8.95737C1.2925 4.67716 4.76229 1.20737 9.0425 1.20737C13.3227 1.20737 16.7925 4.67716 16.7925 8.95737C16.7925 13.2376 13.3227 16.7074 9.0425 16.7074C7.82094 16.7074 6.66368 16.4242 5.63444 15.9196C5.15553 16.0986 4.48774 16.3359 3.85974 16.5159C3.47186 16.6271 3.07069 16.7248 2.72725 16.7681C2.55753 16.7895 2.36997 16.8019 2.19033 16.7841C2.0371 16.7689 1.74393 16.7194 1.51218 16.4875C1.28056 16.2558 1.23113 15.9627 1.21599 15.8095C1.19824 15.6299 1.21069 15.4424 1.23208 15.2727C1.27536 14.9293 1.37306 14.5282 1.48422 14.1403C1.66419 13.5124 1.90143 12.8446 2.08047 12.3657C1.5757 11.3364 1.2925 10.179 1.2925 8.95737Z'
    />
    <circle cx='6.56686' cy='9' r='0.987763' />
    <circle cx='9.04245' cy='9' r='0.987763' />
    <circle cx='11.518' cy='9' r='0.987763' />
  </svg>
);
