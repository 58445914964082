export const beginningOfDay = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

export const endOfDay = {
  hours: 23,
  minutes: 59,
  seconds: 59,
  milliseconds: 999,
};
