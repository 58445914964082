import { useQuery } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { NewsItemDetail } from 'models/news/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetOneNewsItem = (id: number) => {
  const { data, ...rest } = useQuery(
    ['oneNewsItem', id],
    async () => await apiClient.get(Endpoint.GET_NEWS + `/${id}`),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { data: data?.data.data as NewsItemDetail, ...rest };
};
