import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  width: 100%;
  margin: 0 0 40px;
  padding: 0 12px 48px;
  align-self: flex-start;
`;

export const Title = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.headline1}
`;

export const List = styled.div`
  margin: 24px -12px 0;
  display: flex;
  flex-direction: column;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 60px auto 0;
  text-align: center;
`;

export const NoItemText = styled.p`
  ${({ theme }) => theme.typography.headline3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;
