import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';
import { useConnectInstagramQuery } from 'queries/account/instagram/useConnectInstagramQuery';

export const StyledPreloader = styled(Preloader)`
  margin: 30px auto 0;
  padding: 0 0 6px 0;
  text-align: center;
`;

export const InstagramConnectSuccess: React.FC<
  RouteComponentProps<{ code: string }>
> = props => {
  const params = new URLSearchParams(props.location.search);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const authCode = params.get('code')!;
  const { isLoading } = useConnectInstagramQuery(authCode);
  return isLoading ? <StyledPreloader /> : null;
};
