import { ChangePasswordRequest } from 'queries/account/changePassword/types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormSC, Input, ButtonSC, TextSC } from './styled';
import { SuccessChangeModal } from './SuccessChangeModal';
import { AxiosError } from 'axios';
import { ServerValidationError } from 'interfaces/formattedValidationError';
import { formatValidationErrors } from 'utilities/formatValidationErrors';
import { changePasswordFx } from 'models/auth/model';

export const ChangePassword: React.FC = () => {
  const [openSuccessChangeModal, setOpenSuccessChangeModal] = useState(false);
  const handleCloseModal = () => setOpenSuccessChangeModal(false);

  const handleSubmit = (values: ChangePasswordRequest) =>
    changePasswordFx(values)
      .then(() => setOpenSuccessChangeModal(true))
      .catch((err: AxiosError<{ error: ServerValidationError }>) => {
        return formatValidationErrors(err.response?.data.error);
      });

  return (
    <>
      {openSuccessChangeModal && (
        <SuccessChangeModal closeModal={handleCloseModal} />
      )}
      <TextSC>Придумайте новый пароль</TextSC>
      <Form
        onSubmit={handleSubmit}
        render={props => {
          return (
            <FormSC onSubmit={props.handleSubmit}>
              <Field
                labelWidth={144}
                inputWidth={340}
                label='Новый пароль'
                alignLabel='left'
                name='new_password'
                component={Input}
              />
              <Field
                labelWidth={144}
                inputWidth={340}
                label='Повторите пароль'
                alignLabel='left'
                name='password_repeat'
                component={Input}
              />
              <ButtonSC type='submit'>Изменить пароль</ButtonSC>
            </FormSC>
          );
        }}
      />
    </>
  );
};
