import { PaperClipSmallIcon, SendIcon } from 'icons';
import { useChatMessages } from 'models/chat/model';
import { useChatGetMessagesQuery } from 'queries/chat/useChatGetMessagesQuery';
import { useChatSendFileQuery } from 'queries/chat/useChatSendFileQuery';
import { useChatSendMessageQuery } from 'queries/chat/useChatSendMessageQuery';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import {
  Form as StyledForm,
  Input,
  SubmitButton,
  Field,
  UploadFileInput,
  UploadFileLabel,
} from './styles';
import { SubmitHandler } from './types';

export const Form: React.FC = () => {
  const { mutate: sendMessage } = useChatSendMessageQuery();
  const messages = useChatMessages();
  const lastMessageId = messages[0]?.id;
  const { refetch } = useChatGetMessagesQuery(lastMessageId);
  const { mutate: sendFile } = useChatSendFileQuery();

  const onSubmit: SubmitHandler = ({ text }, form) => {
    sendMessage(
      { text },
      {
        onSuccess: () => {
          form.reset();
          refetch();
        },
      }
    );
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const files = target.files as FileList;

    sendFile(files, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <UploadFileLabel htmlFor='file'>{PaperClipSmallIcon}</UploadFileLabel>
          <UploadFileInput
            type='file'
            id='file'
            onChange={handleChangeFile}
            multiple
          />
          <Field
            name='text'
            component={Input}
            placeholder='Напишите что-нибудь...'
          />
          <SubmitButton type='submit'>{SendIcon}</SubmitButton>
        </StyledForm>
      )}
    />
  );
};
