import React, { useState } from 'react';
import {
  Top,
  Badge,
  BadgeText,
  Bottom,
  Title,
  Text,
  Divider,
  Delivery,
  DeliveryTitle,
  DeliveryText,
  StyledLink,
  PaymentWrap,
  Payment,
  PaymentIcon,
  PaymentText,
} from './styles';
import { CreditCardIcon, PaypalIcon } from 'icons';
import { regExpHTML } from 'constants/regExps';

export const Info: React.FC<{ detailText?: string }> = ({ detailText }) => {
  const [visiblePage, setVisiblePage] = useState(1);

  const renderPage = (id: number) => {
    switch (id) {
      case 1: {
        if (!detailText) {
          return <Text>Нет описания</Text>;
        }
        return (
          <div>
            {regExpHTML.test(detailText) ? (
              <Text dangerouslySetInnerHTML={{ __html: detailText }} />
            ) : (
              <Text>{detailText}</Text>
            )}
          </div>
        );
      }
      case 3: {
        return (
          <div>
            <Title>Доставка</Title>
            <Text>
              Доставляем по России, в Беларусь, Казахстан и Узбекистан
            </Text>
            <Divider />
            <Delivery>
              <DeliveryTitle>Самовывоз</DeliveryTitle>
              <DeliveryText>
                г. Москва, ул. Щербаковская, дом 3, оф 413
                <br />
                <br />
                Понедельник – пятница
                <br />с 15:00 до 20:00
              </DeliveryText>
            </Delivery>
            <Divider />
            <Delivery>
              <DeliveryTitle>Доставка курьером СДЭК</DeliveryTitle>
              <DeliveryText>
                Eсли Вы самостоятельно заказываете курьера для доставки книг из
                нашего офиса, курьер должен знать ваши Имя, Фамилию и номер
                заказа
              </DeliveryText>
            </Delivery>
            <Divider />
            <Delivery>
              <DeliveryTitle>
                Самовывоз из пункта <br />
                выдачи СДЭК
              </DeliveryTitle>
              <DeliveryText>
                Адреса пунктов выдачи заказов указаны на{' '}
                <StyledLink
                  rel='noopener'
                  target='_blank'
                  href='https://cdek.ru'
                >
                  www.cdek.ru
                </StyledLink>
              </DeliveryText>
            </Delivery>
            <Divider />
            <Title>Оплата</Title>
            <PaymentWrap>
              <Payment>
                <PaymentIcon>{CreditCardIcon}</PaymentIcon>
                <PaymentText>
                  Картой любого банка с помощью платежного шлюза Сбербанк
                </PaymentText>
              </Payment>
              <Payment>
                <PaymentIcon>{PaypalIcon}</PaymentIcon>
                <PaymentText>
                  Через PayPal, подходит только для клиентов из других стран,
                  кроме России{' '}
                </PaymentText>
              </Payment>
            </PaymentWrap>
          </div>
        );
      }
      default:
        console.error(`Unknown state: '${id}'!`);
        return null;
    }
  };

  return (
    <>
      <Top>
        <Badge selected={visiblePage === 1} onClick={() => setVisiblePage(1)}>
          <BadgeText>Описание</BadgeText>
        </Badge>
        {/*<Badge selected={visiblePage === 2} onClick={() => setVisiblePage(2)}>*/}
        {/*  <BadgeText>F.A.Q</BadgeText>*/}
        {/*</Badge>*/}
        <Badge selected={visiblePage === 3} onClick={() => setVisiblePage(3)}>
          <BadgeText>Доставка и Оплата</BadgeText>
        </Badge>
      </Top>
      <Bottom>{renderPage(visiblePage)}</Bottom>
    </>
  );
};
