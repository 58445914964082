import styled from 'styled-components';
import { Tag } from 'ui/Tag';

export const Wrap = styled.div`
  padding: 16px 0 24px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceDigit = styled.p`
  ${({ theme }) => theme.typography.headline3}
  margin: 0;
`;

export const PriceText = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0 0 0 40px;
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline2};
  margin: 0;
  & + ${Price} {
    margin: 24px 0 0 0;
  }
`;

export const TagItem = styled(Tag)``;

export const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 0;
  & ${TagItem} {
    margin: 0 5px;
  }

  & + ${Title} {
    margin: 12px 0 0 0;
  }
  & + ${Price} {
    margin: 24px 0 0 0;
  }
`;

export const Photo = styled.img`
  border-radius: 14px;
  width: 140px;
  height: 96px;
  object-fit: contain;
`;

export const Inner = styled.div`
  margin: 0 0 0 40px;
`;

export const NoPhoto = styled.div`
  border-radius: 14px;
  width: 140px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
`;
export const NoPhotoText = styled.p`
  ${({ theme }) => theme.typography.headline2};
  font-weight: 800;
`;
