import styled from 'styled-components';
import { Calendar } from 'react-date-range';

export const CalendarSC = styled(Calendar)`
  max-width: 334px;
  .rdrDayToday > span > span {
    &:after {
      background: ${({ theme }) => theme.colors.actionColor};
    }
  }
  .rdrSelected {
    background: ${({ theme }) => theme.colors.actionColor};
  }
  .rdrWeekDays {
    text-transform: capitalize;
  }
`;
