import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Link } from 'react-router-dom';

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 120px;
  &:focus {
    border-radius: 4px;
    outline: none;
    ${({ theme }) => `box-shadow: 0 0 0 2px ${theme.colors.thirdBgColor};`}
  }
  &:active,
  &:visited {
    color: inherit;
  }
`;

export const LogoText = styled.span`
  margin-left: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.mainColor};
  font-weight: 800;
  max-width: 104px;
`;

export const LoginButton = styled(Button)`
  border: 2px solid ${props => props.theme.colors.thirdBgColor};
  &:hover {
    border: 2px solid ${props => props.theme.colors.mainColor};
  }
`;

export const HeaderWrap = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.header};
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
`;

//TODO: Разузнать про ховеры этих иконов в шапке
export const Calendar = styled(Link)`
  padding: 15px;
  font-size: 0;
  margin: 0 0 0 100px;
  flex-shrink: 0;
  &:focus {
    border-radius: 4px;
    outline: none;
    ${({ theme }) => `box-shadow: 0 0 0 2px ${theme.colors.thirdBgColor};`}
  }
`;

export const Container = styled.div`
  max-width: 1464px;
  margin: 0 auto;
  padding: 0 12px;
  display: flex;
  z-index: ${({ theme }) => theme.zIndex.header};
  position: relative;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
`;

export const ChatButton = styled(Button)`
  margin-right: 32px;
`;

export const ChatWrap = styled.div`
  position: relative;
`;
