import { useQuery } from 'react-query';
import { getDeliveryOptionsFx, setDeliveryOptions } from 'models/order/model';
import { DeliveryOptionsQueryProps } from './types';

export const useDeliveryOptionsQuery: DeliveryOptionsQueryProps = () =>
  useQuery('deliveryOptions', () => getDeliveryOptionsFx(), {
    onSuccess: res => {
      setDeliveryOptions(res.data.data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
