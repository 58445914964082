import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { useQuery } from 'react-query';
import { TGiftCardsResponse } from './types';

// TODO разобраться с типом
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetGiftCards = () => {
  const { data, ...rest } = useQuery(
    ['giftCards'],
    () => apiClient.get(Endpoint.GET_GIFTS_CARD),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { data: data?.data.data as TGiftCardsResponse | undefined, ...rest };
};
