import styled from 'styled-components';

export const Wrap = styled.div`
  flex-direction: column;
`;

export const DiplomasList = styled.div`
  width: 568px;
  display: flex;
  flex-wrap: wrap;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const Content = styled.div`
  min-width: 500px;
  margin-top: 24px;
  display: flex;
`;

export const RightBannerColumn = styled.div`
  & > img {
    cursor: pointer;
  }
`;
