import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';
import { Button } from 'ui/Button';

export const Wrap = styled.div`
  max-width: 501px;
  // height: 405px;
  display: flex;
  flex-direction: column;
  align-items: start;
  // heigh: 525px;
`;

export const Description = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
  &:before {
    content: '';
    padding: 0 0 16px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
    bottom: 128px;
    width: calc(100% - 120px);
    position: absolute;
  }
`;

export const Info = styled.div`
  width: 360px;
  ${({ theme }) => theme.typography.bodyText2}
  font-weight: 800;
`;

export const Text = styled.div`
  margin-left: 24px;
  max-width: 319px;
  ${({ theme }) => theme.typography.bodyText2}
`;

export const FotterText = styled.div`
  ${({ theme }) => theme.typography.headline3};
  font-weight: bold;
`;

export const Status = styled.div<{ done: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: ${({ theme, done }) =>
    done ? theme.colors.statusDoneLightColor : theme.colors.secondColor};
  border-radius: 6px;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme, done }) =>
    done ? theme.colors.mainBgColor : theme.colors.mainColor};
  font-weight: 700;
  & > svg {
    fill: ${({ theme }) => theme.colors.mainBgColor};
    margin-right: 5.8px;
  }
`;

export const Preview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PreviewWrapper = styled.div`
  width: 224px;
  height: 156px;
  cursor: zoom-in;
  border-radius: 14px;
  overflow: hidden;
`;

export const DateSC = styled.div`
  margin-left: 20px;
  ${({ theme }) => theme.typography.bodyText3}
  color: ${props => props.theme.colors.thirdTextAndIconColor};
`;

export const StatusWrapper = styled.div`
  margin-bottom: 28px;
  display: flex;
  align-items: center;
`;

export const ShareButton = styled(Button)`
  margin-left: auto;
  & > span > svg {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

export const PreloaderSC = styled(Preloader)`
  display: flex;
  justify-content: center;
`;
