import styled, { css } from 'styled-components';
import { Banner } from '../Banner';
import ActionButton from 'ui/ActionButton/ActionButton';
import { Link } from 'react-router-dom';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  display: grid;
  margin: 0 auto 100px;
  align-items: start;
  grid-template-areas:
    'space1 banner banner banner banner banner banner banner banner banner banner space2'
    'space1 backLink backLink content content content content content content content space3 space2'
    'space1 backLink backLink shareLink shareLink shareLink shareLink shareLink shareLink shareLink space3 space2';
  grid-template-columns: repeat(12, 1fr);
  gap: 40px 25px;
  max-width: 1212px;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const ArticleBanner = styled(Banner)`
  grid-area: banner;
`;
const hiding = css`
  max-height: 258px;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    border-bottom: 128px solid #fff;
    width: 120%;
    bottom: 0;
    left: -5%;
    top: 100%;
    position: absolute;
    z-index: 99;
    box-shadow: 0px -26px 60px #fff;
  }
`;
export const Content = styled.div`
  grid-area: content;
  button {
    padding: 0;
  }
  span:nth-of-type(2) {
    margin-left: 0px !important;
  }
`;

export const HideableText = styled.div<{ shouldHide?: boolean }>`
  ${({ shouldHide }) => (shouldHide ? hiding : '')}
`;

export const Text = styled.p`
  margin: 0;
  white-space: pre-line;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const KeepReadingButton = styled(ActionButton)`
  margin-top: 20px;
  span {
    background-color: transparent;
  }
`;

export const ShareButton = styled(ActionButton)`
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -25px;
  & > span > svg {
    fill: ${({ theme }) => theme.colors.mainColor};
  }
`;

export const BackToFilmsLink = styled(Link)`
  grid-area: backLink;
  & > button > span > svg {
    width: 18px;
  }
`;

export const LinksWrap = styled.div`
  display: flex;
  grid-area: shareLink;
`;

export const PreloaderSC = styled(Preloader)`
  display: flex;
  justify-content: center;
`;
