import { useEffect, useRef } from 'react';
import { PlyrInstance } from 'plyr-react';
import Plyr from 'plyr';
import { baseUrl } from 'api/apiClient';
import {
  AddVideoOverlayHandler,
  CreatePlayerHandler,
  PlayerProps,
} from './types';
import { Endpoint } from 'constants/endpoints';
import { useUserProfile } from 'models/auth/model';
import { Wrap } from './styles';

const addVideoOverlay: AddVideoOverlayHandler = (index, phone, player) => {
  const playerElement = document.querySelectorAll('.plyr')[index];

  const overlay = document.createElement('div');
  overlay.classList.add(`plyr-overlay`, `plyr-overlay-${index}`);

  const overlayContainer = document.createElement('div');
  overlayContainer.classList.add('plyr-overlay__container');

  const text = document.createElement('p');
  text.classList.add('plyr-overlay__text');
  text.innerText = phone;

  overlayContainer.append(text);
  overlay.append(overlayContainer);
  playerElement.append(overlay);

  overlay.addEventListener('click', () => player.pause());
};

const createPlayer: CreatePlayerHandler = (
  source,
  index,
  intervals,
  plyrRef,
  isTrailer,
  phone
) => {
  const player = new Plyr('#player-' + index);
  plyrRef.current.push(player);

  player.source = {
    type: 'video',
    sources: [
      {
        src: `${baseUrl}${Endpoint.GET_VIDEO}/${source.id}`,
        type: source.extension ? 'video/' + source.extension : undefined,
        size: source.size,
      },
    ],
  };

  player.on('play', function () {
    if (phone) {
      const overlay = document.querySelector(`.plyr-overlay-${index}`);
      if (!overlay) addVideoOverlay(index, phone, player);
      intervals[index] = setInterval(() => {
        const overlay = document.querySelector(`.plyr-overlay-${index}`);
        if (!overlay) addVideoOverlay(index, phone, player);
      }, 3000);
    } else if (!isTrailer) {
      player.stop();
    }
  });

  player.on('pause', function () {
    const playerElement = document.querySelectorAll('.plyr')[index];
    const overlay = playerElement.querySelector(`.plyr-overlay-${index}`);
    if (overlay) {
      overlay.removeEventListener('click', () => player.pause());
      overlay.remove();
      clearInterval(intervals[index]);
    }
  });
};

export const VideoPlayer: React.FC<PlayerProps> = ({
  source,
  index = 0,
  isTrailer,
}) => {
  const { phone } = useUserProfile() ?? {};
  const plyrRef = useRef<PlyrInstance[]>([]);

  useEffect(() => {
    let refValue: PlyrInstance[] = [];
    const intervals: ReturnType<typeof setInterval>[] = [];
    createPlayer(source, index, intervals, plyrRef, isTrailer, phone);
    refValue = plyrRef.current;
    return () => {
      if (refValue) {
        const players = Array.from(refValue);
        // Избавляемся от инициализированных плееров
        players.forEach(instance => {
          instance.destroy();
        });
        // Сбрасываем интервалы, которые накопили во время просмотра видео
        intervals.forEach(i => {
          clearInterval(i);
        });
      }
    };
  }, [plyrRef, phone, source, index, isTrailer]);

  return (
    <Wrap>
      <video id={'player-' + index} />
    </Wrap>
  );
};
