import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Content } from './Content';
import { Sidebar } from './Sidebar';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-areas:
    'sidebar sidebar sidebar player player player player player player player player player'
    'link link link content content content content content content content content content';
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto;
  gap: 24px;
  padding: 0 12px 100px;
  max-width: 1236px;
`;

export const StyledSidebar = styled(Sidebar)`
  grid-area: sidebar;
`;

export const StyledContent = styled(Content)`
  grid-area: content;
  grid-row-start: ${({ data }) => (data.video.length ? 'auto' : 1)}; ;
`;

export const BackButton = styled(Button)`
  grid-area: link;
  align-self: flex-start;
`;

export const PlayerWrap = styled.div`
  grid-area: player;
`;

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto;
  text-align: center;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;
