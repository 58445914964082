export const MinusIcon = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.66675 8.00004C2.66675 7.63185 2.96522 7.33337 3.33341 7.33337H12.6667C13.0349 7.33337 13.3334 7.63185 13.3334 8.00004C13.3334 8.36823 13.0349 8.66671 12.6667 8.66671H3.33341C2.96522 8.66671 2.66675 8.36823 2.66675 8.00004Z'
    />
  </svg>
);
