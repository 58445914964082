import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 0 12px 10px;
  width: 100%;
`;

export const Title = styled.p`
  margin-top: 68px;
  ${({ theme }) => theme.typography.headline2};
`;

export const DownloadDoc = styled.a`
  padding: 7px 20px 7px 7px;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  display: flex;
  border-radius: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  & + & {
    margin-top: 10px;
  }
`;

export const DownloadDocIcon = styled.div`
  font-size: 0;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
`;

export const DownloadDocText = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
  padding: 7px 0 4px;
  margin-left: 14px;
`;

export const DownloadDocVolume = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  margin-left: auto;
  padding: 9px 0 6px;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;
