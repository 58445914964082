import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
`;

export const Frame = styled.div`
  background: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 14px;
  padding: 32px 54px 32px 24px;
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  & + & {
    margin: 0 0 0 24px;
  }
`;

export const BoldText = styled.p`
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;

export const Text = styled.p`
  margin: 0 0 0 48px;
  ${({ theme }) => theme.typography.bodyText3};
  & > ${BoldText} {
    margin: 4px 0 0;
  }
`;

export const Header = styled.div`
  width: 135px;
  flex: 0 0 auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
`;

export const HeaderDigit = styled.p`
  margin: 0;
  width: 78px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0px;
  padding: 14px;
  margin: 0 auto;
`;

export const HeaderText = styled.p`
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  & + ${HeaderDigit} {
    margin-bottom: 7px;
  }
`;
