import { TCourseStatuses } from 'models/courses/types';

export const getAvailableStatus = (
  available?: boolean,
  is_bonus?: boolean,
  date_start?: Date,
  courseStatus?: TCourseStatuses
): boolean => {
  if (available && is_bonus) {
    // доступная бонусная
    return courseStatus === TCourseStatuses.COMPLETED;
  }
  if (date_start) {
    // не дополнительный материал
    return !!available;
  }
  // дополнительный материал
  return true;
};
