import { GiftIcon2 } from 'icons/GiftIcon2';
import React from 'react';
import styled from 'styled-components';
import { ProgressProps } from './types';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 25px 0 0;
`;

export const ProgressBarPercent = styled.p`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0 14px 0 0;
`;

export const ProgressBarDot = styled.div<{ started: boolean }>`
  background-color: ${({ theme, started }) =>
    started ? theme.colors.actionColor : theme.colors.secondColor};
  border-radius: 5px;
  height: 6px;
  width: 6px;
  margin: 0 4px 0 0;
`;

export const ProgressBarLine = styled.div`
  background-color: ${({ theme }) => theme.colors.secondColor};
  border-radius: 5px;
  height: 6px;
  width: 220px;
`;

export const ProgressBarDone = styled.div<{
  percent: number;
}>`
  background: ${({ theme }) => theme.colors.actionColor};
  border-radius: 5px;
  height: 100%;
  width: ${({ percent }) => percent}%;
`;

export const ProgressBarFinish = styled.div<{
  complete: number;
  total: number;
}>`
  width: 24px;
  height: 24px;
  display: flex;
  margin: 0 0 0 4px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  background-color: ${({ theme, complete, total }) =>
    complete === total ? theme.colors.actionColor : theme.colors.secondColor};
  border-radius: 50%;
`;

export const ProgressBarCount = styled.p`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  white-space: nowrap;
  margin: 0 0 0 40px;
`;

export const ProgressBar: React.FC<ProgressProps> = ({ progress, active }) => {
  return active && progress ? (
    <Wrap>
      <ProgressBarPercent>{progress.percent}%</ProgressBarPercent>
      <ProgressBarDot started={!!progress.complete && progress.complete > 0} />
      <ProgressBarLine>
        <ProgressBarDone percent={progress.percent} />
      </ProgressBarLine>
      <ProgressBarFinish complete={progress.complete} total={progress.total}>
        {GiftIcon2}
      </ProgressBarFinish>
      <ProgressBarCount>
        {progress.complete}/{progress.total} лекций
      </ProgressBarCount>
    </Wrap>
  ) : null;
};
