import React from 'react';

export const PopupErrorIcon = (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='20' cy='20' r='20' fill='#FFF6F4' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.6919 13.308C26.2812 12.8973 25.6154 12.8973 25.2048 13.308L20 18.5128L14.7952 13.308C14.3846 12.8973 13.7188 12.8973 13.3081 13.308C12.8975 13.7186 12.8975 14.3844 13.3081 14.7951L18.5129 19.9999L13.308 25.2049C12.8973 25.6155 12.8973 26.2813 13.308 26.692C13.7186 27.1027 14.3844 27.1027 14.7951 26.692L20 21.487L25.2049 26.692C25.6156 27.1027 26.2814 27.1027 26.692 26.692C27.1027 26.2814 27.1027 25.6155 26.692 25.2049L21.4871 19.9999L26.6919 14.7951C27.1026 14.3844 27.1026 13.7186 26.6919 13.308Z'
      fill='#FF4921'
    />
  </svg>
);
