import styled from 'styled-components';
import { InputTextarea } from 'ui/InputTextarea';
import { RatingStars } from 'ui/RatingStars';

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline2};
  margin: 0 0 40px;
  max-width: 360px;
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 24px 0 9px;
  color: ${({ theme }) => theme.colors.secondTextColor};
`;

export const StyledRatingStars = styled(RatingStars)`
  margin: 0 0 40px;
  & + & {
    margin-left: 8px;
  }
`;

export const StyledInputTextarea = styled(InputTextarea)`
  margin: 0 0 40px;
  width: 100%;
`;

export const StyledButton = styled.div`
  text-align: center;
`;

export const Wrap = styled.div`
  text-align: center;
`;
export const Logo = styled.div`
  margin: 0 0 24px;
`;

export const TitleSuccess = styled.p`
  ${({ theme }) => theme.typography.headline2};
  margin: 0 0 24px;
`;

export const TextSuccess = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
`;
