import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { LeftSidebardKey } from './LeftSidebar/types';
import {
  LeftSidebarSC,
  Wrap,
  HeaderContentSC,
  ContentSC,
  StyledPreloader,
} from './styled';
import { Path } from 'constants/paths';
import { useUserProfileQuery } from 'queries/auth/useUserProfileQuery';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export const accountTitles = {
  'my-data': 'Мои данные',
  orders: 'Заказы',
  diplomas: 'Дипломы',
  'gift-cards': 'Подарочные карты',
  'bonus-account': 'Бонусный счет',
  premium: 'Премия',
  addresses: 'Адреса',
  password: 'Пароль',
};

export const Account: React.FC<
  RouteComponentProps<{ key: LeftSidebardKey }>
> = ({ match }) => {
  const { key } = match.params;
  const { isLoading, isError, error } = useUserProfileQuery();
  useScrollToTopOnMount();
  if (isError && error?.response?.status === 401) {
    return <Redirect to={`${Path.catalog}/courses`} />;
  }

  return (
    <Wrap>
      {isLoading ? (
        <StyledPreloader />
      ) : (
        <>
          <LeftSidebarSC categoryKey={key} />

          <HeaderContentSC />
          <ContentSC />
        </>
      )}
    </Wrap>
  );
};
