import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { ActionButtonProps } from './types';

//TODO: Пока непонятно какой ховер должен быть

const ButtonIcon = styled.span`
  border-radius: 8px;
  width: 40px;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.secondBgColor};
`;

const ButtonText = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
`;

const ButtonWrap = styled.button<{ iconAlign?: string }>`
  ${({ iconAlign }) =>
    iconAlign === 'right'
      ? css`
          flex-direction: row-reverse;
        `
      : ''}
  cursor: pointer;
  margin: 0;
  padding: 0;
  color: ${props => props.theme.colors.mainColor};
  transition: all 0.3s;
  border: none;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  outline: none;
  &:active,
  &:hover {
    transition: all 0.3s;
  }
  & ${ButtonIcon} + ${ButtonText}, & ${ButtonText} + ${ButtonIcon} {
    margin-left: 16px;
  }
  &:focus {
    outline: none;
    ${({ disabled, theme }) =>
      !disabled ? `box-shadow: 0 0 0 2px ${theme.colors.thirdBgColor};` : null}
  }
`;

export const ActionButton: React.FC<
  ActionButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ label, icon, children, ...props }) => {
  const btnText = label || children;

  return (
    <ButtonWrap type='button' {...props}>
      {icon ? <ButtonIcon>{icon}</ButtonIcon> : null}
      {btnText ? <ButtonText>{btnText}</ButtonText> : null}
    </ButtonWrap>
  );
};

export default ActionButton;
