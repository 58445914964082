import React, { useEffect } from 'react';
import {
  Wrap,
  StyledEventItem,
  StyledPreloader,
  NoData,
  StyledBackButton,
} from './styles';
import { RouteComponentProps } from 'react-router';
import { useGetMasterClassQuery } from 'queries/inner-insight/useGetMasterClassQuery';
import { clearMasterClass, useMasterClass } from 'models/inner-insight/model';
import { ChevronLeftSmallIcon } from 'icons';
import { useHistory } from 'react-router-dom';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';

export const MasterClass: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
  ...props
}) => {
  const { id } = match.params;
  const { isLoading, isError } = useGetMasterClassQuery(Number(id));
  const masterClass = useMasterClass();

  useScrollToTopOnMount();

  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };

  useEffect(() => {
    return () => clearMasterClass();
  }, []);

  if (isError) return <div>Не удалось загрузить школу</div>;
  if (!masterClass) return <NoData>Нет данных</NoData>;

  return isLoading ? (
    <StyledPreloader />
  ) : (
    <Wrap {...props}>
      <StyledBackButton
        buttonType={'link'}
        icon={ChevronLeftSmallIcon}
        onClick={handleClick}
        label='Все мастер-классы'
      />
      <StyledEventItem {...masterClass} id={String(masterClass.id)} />
    </Wrap>
  );
};
