import React from 'react';

const GiftCardsIcon = (
  <svg
    width='18'
    height='14'
    viewBox='0 0 18 14'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.81389 1.68394C1.74885 1.75735 1.69727 1.87299 1.69727 2.015V11.9849C1.69727 12.1269 1.74885 12.2426 1.81389 12.316C1.87798 12.3883 1.94112 12.4079 1.98631 12.4079H16.0135C16.0587 12.4079 16.1218 12.3883 16.1859 12.316C16.2509 12.2426 16.3025 12.1269 16.3025 11.9849V2.015C16.3025 1.87299 16.2509 1.75735 16.1859 1.68394C16.1218 1.6116 16.0587 1.59207 16.0135 1.59207H1.98631C1.94112 1.59207 1.87798 1.6116 1.81389 1.68394ZM0.197266 2.015C0.197266 1.0437 0.917767 0.0920715 1.98631 0.0920715H16.0135C17.082 0.0920715 17.8025 1.0437 17.8025 2.015V11.9849C17.8025 12.9562 17.082 13.9079 16.0135 13.9079H1.98631C0.917767 13.9079 0.197266 12.9562 0.197266 11.9849V2.015Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.8947 4.90781C10.263 4.90781 9.74992 5.42086 9.74992 6.05254V7.19728H10.8947C11.5263 7.19728 12.0394 6.68422 12.0394 6.05254C12.0394 5.42086 11.5263 4.90781 10.8947 4.90781ZM8.24992 6.05254C8.24992 4.59243 9.43455 3.40781 10.8947 3.40781C12.3548 3.40781 13.5394 4.59243 13.5394 6.05254C13.5394 7.51265 12.3548 8.69728 10.8947 8.69728H8.99992C8.58571 8.69728 8.24992 8.36149 8.24992 7.94728V6.05254Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.46045 6.05254C4.46045 4.59243 5.64508 3.40781 7.10519 3.40781C8.56529 3.40781 9.74992 4.59243 9.74992 6.05254V7.94728C9.74992 8.36149 9.41414 8.69728 8.99992 8.69728H7.10519C5.64508 8.69728 4.46045 7.51265 4.46045 6.05254ZM7.10519 4.90781C6.4735 4.90781 5.96045 5.42086 5.96045 6.05254C5.96045 6.68422 6.4735 7.19728 7.10519 7.19728H8.24992V6.05254C8.24992 5.42086 7.73687 4.90781 7.10519 4.90781Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.46959 7.41695C8.76249 7.12406 9.23736 7.12406 9.53025 7.41695L11.425 9.31169C11.7179 9.60458 11.7179 10.0795 11.425 10.3723C11.1321 10.6652 10.6572 10.6652 10.3643 10.3723L8.99992 9.00794L7.63552 10.3723C7.34262 10.6652 6.86775 10.6652 6.57486 10.3723C6.28196 10.0795 6.28196 9.60458 6.57486 9.31169L8.46959 7.41695Z'
    />
  </svg>
);

export default GiftCardsIcon;
