import { createEffect, createEvent, restore } from 'effector';
import { AxiosError, AxiosResponse } from 'axios';
import { CatalogPromoItem, PromosStore, PromoItem } from './types';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';

// События
export const setPromosList = createEvent<CatalogPromoItem[]>();
export const setPromoItem = createEvent<PromoItem>();
export const clearPromoList = createEvent();
export const clearPromoItem = createEvent();

// Сторы
export const $promosData = restore<PromosStore>(setPromosList, []);
export const $promoItem = restore<PromoItem>(setPromoItem, null);

// Эффекты
export const getPromosListFx = createEffect<
  unknown,
  AxiosResponse<{ data: CatalogPromoItem[] }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_PROMOS_LIST));

export const getPromoItemFx = createEffect<
  string,
  AxiosResponse<{ data: PromoItem }>,
  AxiosError<{ error: string }>
>(id => apiClient.get(Endpoint.GET_PROMO_ITEM.replace('{id}', id)));
// Подписки

$promosData.reset(clearPromoList);
$promoItem.reset(clearPromoItem);
