import { PlusButtonIcon } from 'icons/PlusButtonIcon';
import { useAddresses } from 'models/addresses/model';
import { useGetAddressesQuery } from 'queries/addresses/useGetAddressesQuery';
import React, { useState } from 'react';
import { Preloader } from 'ui/Preloader';
import Title from '../../Title';
import { AddAddressModal } from './AddAddressModal';
import AdressItem from './AdressItem';
import { AddAdressButton, AddressesList, Wrapper } from './styled';

export enum OrderStates {
  APPROVED = 'Передан в службу доставки',
  UNAPPROVED = 'Отменен',
}

export const Addresses: React.FC = () => {
  const { isLoading, refetch } = useGetAddressesQuery();
  const addresses = useAddresses();

  const [openAddAddressModal, setOpenAddAddressModal] = useState(false);

  return (
    <Wrapper>
      {openAddAddressModal && (
        <AddAddressModal
          onSuccess={() => refetch()}
          closeModal={() => setOpenAddAddressModal(false)}
        />
      )}
      <Title>{'Адреса'}</Title>
      {isLoading ? (
        <Preloader />
      ) : (
        <AddressesList>
          {addresses.map(address => (
            <AdressItem
              onDelete={() => refetch()}
              data={address}
              key={address.id}
            />
          ))}
        </AddressesList>
      )}
      <AddAdressButton
        icon={PlusButtonIcon}
        buttonType='link'
        onClick={() => setOpenAddAddressModal(true)}
      >
        Добавить адрес
      </AddAdressButton>
    </Wrapper>
  );
};
