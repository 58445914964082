import React from 'react';

const BacsIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.4407 7.85736L17.0855 17.0916C17.0083 17.6165 16.6191 18 16.1639 18L3.29044 18C2.83519 18 2.44655 17.6165 2.36883 17.0916L1.01358 7.85736C0.953965 7.4534 1.09364 7.04398 1.37416 6.79723C1.65584 6.54912 2.0287 6.51016 2.34545 6.68924L5.95361 8.74387L8.91073 2.52186C9.23975 1.82605 10.2163 1.82605 10.5453 2.52186L13.5025 8.74387L17.1106 6.68924C17.4262 6.51016 17.8002 6.54912 18.0819 6.79723C18.3613 7.04398 18.5009 7.4534 18.4407 7.85736Z'
      fill='#D5D5D5'
    />
  </svg>
);

export default BacsIcon;
