import React from 'react';
import { Icon, Text } from './styles';
import { PopupErrorIcon } from 'icons/';

export const ErrorMessage: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <>
      <Icon>{PopupErrorIcon}</Icon>
      <Text>{text || 'Неизвестная ошибка!'}</Text>
    </>
  );
};
