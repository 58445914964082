import React from 'react';
import { DateSC, Operation, Value, Wrap } from './styled';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateFormats } from 'constants/dateFormats';
import { BonusHistoryItem } from 'models/bonus/types';

interface IProps {
  data: BonusHistoryItem[];
}

export const OperationItem: React.FC<IProps> = ({ data }) => {
  return (
    <Wrap>
      <DateSC>
        {format(new Date(data[0].date_create), DateFormats.BonusHistoryDate, {
          locale: ru,
        })}
      </DateSC>
      {data.map(item => (
        <Operation key={item.id}>
          <div>{item.description}</div>
          <div>
            <Value>{`${item.type}${item.amount} бонусов`}</Value>
          </div>
        </Operation>
      ))}
    </Wrap>
  );
};
