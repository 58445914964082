import styled from 'styled-components';
import { Categories } from './Categories';
import { Content } from './Content';
import { Title } from './Title';

export const Wrap = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-areas:
    'empty empty empty header header header header header header header header header'
    'sidebar sidebar sidebar content content content content content content content offset offset';
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  gap: 24px;
  padding: 0 12px 100px;
  max-width: 1236px;
`;

export const StyledTitle = styled(Title)`
  grid-area: header;
`;

export const StyledCategories = styled(Categories)`
  grid-area: sidebar;
`;

export const StyledContent = styled(Content)`
  grid-area: content;
`;

export const AboutWrap = styled.div`
  & > .swiper-container {
    overflow: visible;
    max-width: 1054px;
    padding: 0 24px;
    margin: 0 auto;
  }
`;
