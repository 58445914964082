import { useMutation, UseMutationResult } from 'react-query';
import { markAsReadNotificationsFx } from 'models/notifications/model';
import { AxiosError, AxiosResponse } from 'axios';

export type useMarkAsReadNotificationsQuery = () => UseMutationResult<
  AxiosResponse<{ data: { success: boolean } }>,
  AxiosError<{ error: string }>,
  void
>;

export const useMarkAsReadNotificationsQuery: useMarkAsReadNotificationsQuery = () =>
  useMutation<
    AxiosResponse<{ data: { success: boolean } }>,
    AxiosError<{ error: string }>,
    void
  >('markAsReadNotifications', () => markAsReadNotificationsFx(), {
    retry: false,
  });
