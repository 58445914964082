import styled from 'styled-components';

export const ImageWrap = styled.div<{ border?: boolean }>`
  height: 200px;
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  ${({ border, theme }) =>
    border && `border: 2px solid ${theme.colors.thirdBgColor}`}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CatalogItemWrap = styled.div`
  flex-basis: calc((100% - 72px) / 3);
  margin: 0 12px 40px;
  padding: 0 0 24px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.actionColor};
  }
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline2};
  margin-top: 12px;
  max-width: 100%;
`;

export const EventDateWrap = styled.div`
  margin-top: 24px;
  display: flex;
`;

export const EventBeginning = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const EventDate = styled.span`
  ${({ theme }) => theme.typography.bodyText1};
  color: ${({ theme }) => theme.colors.mainColor};
`;

export const EventDuration = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 0 111px 0 auto;
`;
