import React from 'react';
import {
  List,
  Option,
  Divider,
  Inner,
  OptionRadio,
  OptionTitle,
  OptionText,
  Title,
} from './styles';
import { DeliveryOptions } from 'models/order/types';
import { Field } from 'react-final-form';

type DeliveryOptionProps = {
  deliveryOptions: DeliveryOptions[];
};

export const DeliveryOption: React.FC<DeliveryOptionProps> = ({
  deliveryOptions = [],
  ...rest
}) => (
  <div {...rest}>
    <Title>Способ получения</Title>
    <List>
      {deliveryOptions.map((item, index) =>
        item ? (
          <div key={item.id}>
            <Option>
              <Field
                id={item.id}
                name='delivery_key'
                radioSize='large'
                value={item.code}
                type='radio'
                component={OptionRadio}
              />
              <Inner>
                <OptionTitle>{item.name}</OptionTitle>
                <OptionText>{item.description}</OptionText>
              </Inner>
            </Option>
            {deliveryOptions.length === index + 1 ? null : <Divider />}
          </div>
        ) : null
      )}
    </List>
  </div>
);
