import { useQuery, UseQueryResult } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Path } from 'constants/paths';
import { useHistory } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';

export const useSberbankTransactionCheckQuery: (
  url: string,
  data: { orderId: string; lang: string }
) => UseQueryResult<
  AxiosResponse<{ success: boolean; order_id?: number }>,
  AxiosError<{ error: string }>
> = (url, data) => {
  const history = useHistory();
  return useQuery(
    'checkTransaction',
    () => apiClient.get(`api/v1${url}`, { params: data }),
    {
      onSuccess: res => {
        if (res.data.success && !res.data.order_id) {
          // если приходит success: true, но без orderId
          history.push(Path.myOrders);
        } else if (res.data.success && res.data.order_id) {
          //   если приходит success: true и с orderId
          history.push(Path.myOrders + '/' + res.data.order_id);
        } else if (!res.data.success) {
          //   если приходит success: false
          //   TODO: доработать редирект в случае неуспеха транзакции
          history.push(Path.myOrders);
        }
      },
    }
  );
};
