import styled from 'styled-components';
import { InputSuggestionProps } from './types';
import { Preloader } from 'ui/Preloader';

export const InputLabel = styled.label<InputSuggestionProps>`
  ${props => props.theme.typography.bodyText2};
  letter-spacing: 0;
  text-align: ${props => props.alignLabel};
  margin-bottom: 8px;
  flex-shrink: 0;
  color: ${props => props.theme.colors.secondTextColor};
  ${({ labelWidth }) => (labelWidth ? `width: ${labelWidth}px` : null)};

  ${({ labelWidth }) => (labelWidth ? `width: ${labelWidth}px` : null)};
`;

export const Error = styled.p`
  margin: 8px 0 0;
  max-width: 100%;
  text-align: left;
  color: ${props => props.theme.colors.errorColor};
`;

export const InputInner = styled.div<
  InputSuggestionProps & { isFocused: boolean; disabled?: boolean }
>`
  padding: 12px 14px;
  border-radius: 14px;
  border: 1px solid ${props => props.theme.colors.secondBgColor};
  background-color: ${props => props.theme.colors.secondBgColor};
  transition: all 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};
  ${({ inputWidth }) => (inputWidth ? `width: ${inputWidth}px` : null)};
  ${({ isFocused, theme }) =>
    isFocused &&
    `transition: all 0.3s;border-color: ${theme.colors.secondColor};`}
  ${({ disabled, theme }) =>
    !disabled &&
    `&:hover {
          transition: all 0.3s;
          border-color: ${theme.colors.secondColor};
        }`}
`;

export const Input = styled.input<InputSuggestionProps>`
  outline: none;
  z-index: 3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 100%;
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.disabledColor
        : theme.colors.thirdTextAndIconColor};
  }
`;

export const InputWrap = styled.div<
  InputSuggestionProps & {
    disabled?: boolean;
    error?: string;
  }
>`
  display: inline-flex;
  flex-direction: ${props =>
    props.direction === 'vertical' ? 'column' : 'row'};
  ${({ direction }) =>
    direction !== 'vertical' ? 'align-items: center;' : null}
  ${({ direction }) =>
    direction !== 'vertical' ? `& > ${InputLabel} {margin: 0 16px 0 0;}` : null}

  ${props =>
    props.disabled &&
    `& > ${InputInner} {
        background-color: ${props.theme.colors.thirdBgColor};
        border-color: ${props.theme.colors.thirdBgColor};
        cursor: not-allowed;
      };
      
      & > ${Input} {
        cursor: not-allowed;
      }      
      & > ${InputLabel} {
        color: ${props.theme.colors.disabledColor};
      };`};

  & > ${Error} {
    transition: transform 0.3s, opacity 0.3s;
    ${({ error }) => {
      return !error
        ? 'transform: translate(0, -50%); opacity: 0;'
        : 'transform: translate(0, 0); opacity: 1;';
    }}
  }

  & > ${InputInner} {
    ${({ error, theme }) => error && `border-color: ${theme.colors.errorColor}`}
  }
`;

export const StyledPreloader = styled(Preloader)`
  margin: 0 auto;
  text-align: center;
`;

export const SuggestionContainer = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  max-height: 270px;
  width: 100%;
  overflow-y: scroll;
  scroll-width: 0;
  padding: 18px 0;
  z-index: ${({ theme }) => theme.zIndex.suggestionDropdown};
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.12);
`;

export const SuggestionItem = styled.div`
  padding: 9px 25px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondBgColor};
    transition: background-color 0.3s;
  }

  cursor: pointer;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  }
`;

export const SuggestionText = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const SuggestionSubtext = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const SuggestionAccentText = styled.span`
  color: ${({ theme }) => theme.colors.linksColor};
`;

export const NoDataSuggestion = styled.p`
  padding: 9px 25px;
  margin: 0;
  color: ${({ theme }) => theme.colors.secondColor};
`;
