import styled from 'styled-components';
import { Button } from 'ui/Button';
import { NoImage } from 'ui/NoImage';
import { Link } from 'react-router-dom';
import { Preloader } from 'ui/Preloader';

export const ImageWrap = styled.div<{ border?: boolean }>`
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  padding-top: 100%;
  ${({ border, theme }) =>
    border && `border: 2px solid ${theme.colors.thirdBgColor}`}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledNoImage = styled(NoImage)`
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ItemWrap = styled(Link)`
  flex-basis: calc(100% / 4);
  margin: 0 0 40px;
  padding: 0 12px 48px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  transition: border 0.3s, box-shadow 0.3s;
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.actionColor};
    transition: border 0.3s, box-shadow 0.3s;
    box-shadow: 0 -1px 0 0 ${({ theme }) => theme.colors.actionColor} inset;
  }
`;

export const DisabledItemWrap = styled.div`
  cursor: not-allowed;
  flex-basis: calc(100% / 4);
  margin: 0 0 40px;
  padding: 0 12px 48px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText1};
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin: 6px 0 12px;
`;

export const Price = styled.p`
  ${({ theme }) => theme.typography.headline3};
  margin: auto 0 0;
`;

export const Btn = styled(Button)`
  margin: 12px 0 0;
`;

export const CatalogItemTitle = styled.div`
  ${({ theme }) => theme.typography.headline3};
  margin-top: 12px;
  max-width: 100%;
`;

export const StyledPreloader = styled(Preloader)``;
