import styled from 'styled-components';
import { Radio } from 'ui/Radio';
import { Button } from 'ui/Button';

export const Wrap = styled.div`
  margin-top: 40px;
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline1};
`;

export const Options = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-radius: 14px;
  margin-top: 24px;
  padding: 8px 24px 0px;
`;

export const Option = styled.div`
  display: flex;
  padding: 16px 0 24px;
  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  }
`;

export const OptionRadio = styled(Radio)`
  align-self: flex-start;
`;

export const OptionInner = styled.div`
  max-width: 568px;
  margin-left: 40px;
  margin-right: auto;
`;

export const OptionTitle = styled.div`
  ${({ theme }) => theme.typography.headline3};
  font-size: 24px;
  font-weight: normal;
`;

export const OptionText = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin-top: 12px;
`;

export const OptionPrice = styled.div`
  ${({ theme }) => theme.typography.headline3};
  text-align: right;
  margin-top: 6px;
  margin-left: 64px;
`;

export const OptionPriceLimit = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.thirdColor};
  margin-top: 13px;
  white-space: nowrap;
`;

export const TariffsButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
  display: block;
  text-align: center;
`;
