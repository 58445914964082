import { useQuery, UseQueryResult } from 'react-query';
import { getTrainingItemFx, setTrainingItem } from 'models/catalog/model';
import { AxiosError, AxiosResponse } from 'axios';
import { TrainingItem } from 'models/catalog/types';

export const useGetTrainingItemQuery: (
  id: string
) => UseQueryResult<
  AxiosResponse<{ data: TrainingItem }>,
  AxiosError<{ error: string }>
> = id =>
  useQuery('trainingItem', () => getTrainingItemFx(id), {
    onSuccess: res => {
      setTrainingItem(res.data.data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
