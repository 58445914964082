import { createEffect, createEvent, createStore } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { TLegalDocuments } from './types';
import { AxiosError, AxiosResponse } from 'axios';

export const $legalDocuments = createStore<TLegalDocuments>([]);

export const getLegalDocumentsFx = createEffect<
  void,
  AxiosResponse<{
    data: TLegalDocuments;
  }>,
  AxiosError<{ error: string }>
>(() => apiClient.get(Endpoint.GET_LEGAL_DOCUMENTS));

export const setLegalDocuments = createEvent<TLegalDocuments>();

$legalDocuments.on(setLegalDocuments, (_, payload) => payload);
