import React from 'react';
import {
  Actions,
  ActivateButton,
  DateSC,
  Description,
  ImgSC,
  ImgWrap,
  ShareButton,
  State,
  Title,
  Value,
  Wrap,
} from './styled';
import { ShareIcon } from 'icons/ShareIcon';
import { CheckSmallIcon } from 'icons/CheckSmallIcon';
import { TGiftCard } from 'queries/account/giftCards/types';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { NoImage } from 'ui/NoImage';
import Ruble from 'components/common/Ruble';
import { getImagePath } from 'utilities/getImagePath';

interface IProps {
  data: TGiftCard;
  activated: boolean;
  activate: UseMutationResult<AxiosResponse<unknown>, unknown, string, unknown>;
  setOpenShareModal: (value: boolean) => void;
  setSelectedCard: (value: number) => void;
}

export const GiftItem: React.FC<IProps> = ({
  data,
  activated,
  activate,
  setSelectedCard,
  setOpenShareModal,
}) => {
  const handleActivation = () => {
    setSelectedCard(data.id);
    activate.mutate(data.activation_code);
  };

  const imgSrc = getImagePath(data);

  const handleShareCard = () => {
    setSelectedCard(data.id);
    setOpenShareModal(true);
  };

  return (
    <Wrap>
      <ImgWrap>
        {imgSrc ? (
          <ImgSC src={imgSrc} alt={data.pictures[0].name} />
        ) : (
          <NoImage />
        )}
      </ImgWrap>
      <Description>
        <Title>{data.name}</Title>
        <Value>
          Номинал {data.nominal_price} {Ruble}
        </Value>
        {activated ? (
          <>
            <State>{CheckSmallIcon}Активирована</State>
            <DateSC>
              {format(new Date(data.purchased_at), 'dd MMMM yyyy', {
                locale: ru,
              })}
            </DateSC>
          </>
        ) : (
          <Actions>
            <ActivateButton
              disabled={activate.isLoading}
              onClick={handleActivation}
              type='button'
            >
              Активировать
            </ActivateButton>
            <ShareButton label='' icon={ShareIcon} onClick={handleShareCard} />
          </Actions>
        )}
      </Description>
    </Wrap>
  );
};
