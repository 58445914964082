import React from 'react';
import styled from 'styled-components';
import { RatingStarsProps, StarProps } from './types';

const STARS = new Array(5).fill('');
const COLOR_FILLED = '#FED74D';
const COLOR_OUTLINE = '#D5D5D5';

const Button = styled.button`
  border: 0;
  background: none;
  padding: 0;

  &:focus {
    outline: none;

    svg {
      stroke: ${COLOR_FILLED};
      stroke-width: 2px;
    }
  }
`;

const Star: React.FC<StarProps> = ({
  size,
  index,
  rating,
  setRating,
  hoverRating,
  setHoverRating,
  ...rest
}) => {
  const fill = React.useMemo(() => {
    if (hoverRating && hoverRating >= index) {
      // При наведении
      return COLOR_FILLED;
    } else if (hoverRating === 0 && rating >= index) {
      // При клике
      return COLOR_FILLED;
    } else if (!hoverRating && rating >= index) {
      // Статичные звезды
      return COLOR_FILLED;
    }
    return COLOR_OUTLINE;
  }, [rating, hoverRating, index]);

  const handleMouseEnter = () => {
    if (setHoverRating) {
      setHoverRating(index);
    }
  };

  const handleMouseLeave = () => {
    if (setHoverRating) {
      setHoverRating(0);
    }
  };

  const handleClick = () => {
    if (setRating) {
      setRating(index);
    }
  };

  return size === 'medium' ? (
    <Button
      type='button'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <svg
        width='28'
        height='28'
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...rest}
      >
        <path
          d='M14.0277 0.604047C14.2108 0.23437 14.5877 0 14.9996 0C15.4116 0 15.7891 0.23437 15.9716 0.604047L19.4575 7.66838C19.6152 7.98792 19.9208 8.20961 20.2742 8.26095L28.07 9.39354C28.479 9.45273 28.8172 9.73905 28.9447 10.1311C29.0721 10.5231 28.9658 10.9544 28.6704 11.2419L23.0298 16.7412C22.7743 16.99 22.6578 17.3488 22.7176 17.7004L24.0489 25.4654C24.1177 25.8725 23.9516 26.2833 23.617 26.5261C23.2829 26.7677 22.8414 26.7998 22.4753 26.6089L15.5022 22.9423C15.1869 22.7762 14.8094 22.7762 14.4935 22.9423L7.52033 26.6083C7.15549 26.7998 6.71272 26.7683 6.37869 26.5261C6.04465 26.2839 5.87793 25.8725 5.9474 25.4654L7.27872 17.7004C7.33912 17.3488 7.22194 16.99 6.96642 16.7412L1.32583 11.2419C1.03045 10.9538 0.924142 10.5231 1.0516 10.1311C1.17905 9.73905 1.51792 9.45273 1.92686 9.39354L9.7239 8.26095C10.0767 8.20961 10.3823 7.98792 10.54 7.66777L14.0277 0.604047Z'
          fill={fill}
        />
      </svg>
    </Button>
  ) : (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M6.5146 0.302024C6.60611 0.117185 6.79458 0 7.00056 0C7.20654 0 7.3953 0.117185 7.48651 0.302024L9.22949 3.83419C9.30832 3.99396 9.46114 4.1048 9.63782 4.13047L13.5357 4.69677C13.7402 4.72637 13.9093 4.86953 13.9731 5.06554C14.0368 5.26155 13.9836 5.4772 13.836 5.62096L11.0157 8.37058C10.8879 8.49502 10.8296 8.67442 10.8595 8.8502L11.5252 12.7327C11.5596 12.9363 11.4765 13.1416 11.3092 13.2631C11.1422 13.3839 10.9214 13.3999 10.7384 13.3044L7.25184 11.4712C7.09418 11.3881 6.90542 11.3881 6.74746 11.4712L3.2609 13.3041C3.07848 13.3999 2.85709 13.3842 2.69008 13.2631C2.52306 13.142 2.4397 12.9363 2.47443 12.7327L3.14009 8.8502C3.17029 8.67442 3.1117 8.49502 2.98394 8.37058L0.163649 5.62096C0.0159595 5.4769 -0.0371966 5.26155 0.0265304 5.06554C0.0902573 4.86953 0.259692 4.72637 0.464162 4.69677L4.36268 4.13047C4.53906 4.1048 4.69189 3.99396 4.77072 3.83389L6.5146 0.302024Z'
        fill={fill}
      />
    </svg>
  );
};

const StyledRatingStar = styled(Star)`
  margin: 0;
  cursor: ${({ setHoverRating }) => (setHoverRating ? 'pointer' : 'default')};
  & + & {
    margin-left: ${({ size }) => (size === 'medium' ? '8' : '2')}px;
  }
`;

const RatingStarsWrap = styled.div`
  font-size: 0;
`;

const RatingStars: React.FC<RatingStarsProps> = ({
  rating,
  hoverRating,
  size,
  setRating,
  setHoverRating,
  ...rest
}) => {
  return (
    <RatingStarsWrap {...rest}>
      {STARS.map((_, index: number) => (
        <StyledRatingStar
          key={index}
          size={size}
          index={index + 1}
          rating={rating}
          setRating={setRating}
          hoverRating={hoverRating}
          setHoverRating={setHoverRating}
        />
      ))}
    </RatingStarsWrap>
  );
};

export default RatingStars;
