import { css, DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  zIndex: {
    header: '10000',
    globalOverlay: '12000',
    suggestionDropdown: '20',
  },
  colors: {
    // main colors
    actionColor: '#FED74D',
    mainColor: '#181818',
    secondColor: '#d4d4d4',
    thirdColor: '#FF4921',
    errorColor: '#f63d15',
    secondTextColor: '#6b6b6b',
    thirdTextAndIconColor: '#8d8d8d',
    disabledColor: '#b1b1b1',
    statusDoneLightColor: '#92C91F',
    statusDoneColor: '#8bbc24',
    linksColor: '#1565c0',
    // background colors
    mainBgColor: '#ffffff',
    secondBgColor: '#f2f2f2',
    thirdBgColor: '#eaeaea',
    bgStatusDoneColor: '#f4fae9',
    bgStatusCheckErrorColor: '#fffbed',
    bgStatusNotDoneColor: '#fff6f4',
    bgBlueColor: '#C8E2FF',
    bgStatusColor: '#FFFBED',
    // dividing lines colors
    greenDividingLine: '#e7eddd',
    yellowDividingLine: '#f2eedb',
    redDividingLine: '#f9ece9',
    // social media links
    socialMediaNotActiveColor: '#373737',
    instagramColor: '#5a81b4',
  },
  button: {
    primary: {
      padding: {
        large: '16px 24px',
        medium: '13px 24px',
      },
    },
    secondary: {
      padding: {
        large: '14px 22px',
        medium: '11px 22px',
      },
    },
    link: {
      padding: 0,
    },
  },
  typography: {
    // HEADERS
    headline1: css`
      font-weight: 800;
      font-size: 36px;
      line-height: 42px;
      color: ${props => props.theme.colors.mainColor};
    `,

    headline2: css`
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
      color: ${props => props.theme.colors.mainColor};
    `,

    headline3: css`
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: ${props => props.theme.colors.mainColor};
    `,

    //TITLES

    title1: css`
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: ${props => props.theme.colors.mainColor};
    `,

    title2: css`
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: ${props => props.theme.colors.mainColor};
    `,

    // LINKS

    links: css`
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: ${props => props.theme.colors.mainColor};
    `,

    // BODIES

    bodyText1: css`
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: ${props => props.theme.colors.mainColor};
    `,

    bodyText2: css`
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: ${props => props.theme.colors.mainColor};
    `,

    bodyText3: css`
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: ${props => props.theme.colors.mainColor};
    `,

    bodyText4: css`
      font-weight: normal;
      font-size: 9px;
      line-height: 11px;
      color: ${props => props.theme.colors.mainColor};
    `,

    //OTHERS

    menuText: css`
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: ${props => props.theme.colors.mainColor};
    `,

    statusText: css`
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: ${props => props.theme.colors.mainColor};
    `,

    tabsText: css`
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: ${props => props.theme.colors.mainColor};
    `,

    captionFormText: css`
      font-weight: normal;
      font-size: 9px;
      line-height: 11px;
      color: ${props => props.theme.colors.mainColor};
    `,

    calendarText: css`
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: ${props => props.theme.colors.mainColor};
    `,

    bannerDescription: css`
      font-size: 24px;
      font-weight: 400;
      line-height: 26px;
      color: ${props => props.theme.colors.mainColor};
    `,

    chatTitle: css`
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: ${props => props.theme.colors.mainColor};
    `,
  },
  additional: {
    noOutline: css`
      border-radius: 4px;
      outline: none;
      ${({ theme }) => `box-shadow: 0 0 0 2px ${theme.colors.thirdBgColor};`}
    `,
  },
};
