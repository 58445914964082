import styled from 'styled-components';
import { Categories } from './Categories';
import { Content } from './Content';

export const Wrap = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-areas: 'sidebar sidebar sidebar content content content content content content content content content';
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  gap: 24px;
  margin-top: 40px;
  padding: 0 12px 100px;
  max-width: 1236px;
`;

export const CourseCategory = styled(Categories)`
  grid-area: sidebar;
`;

export const CourseContent = styled(Content)`
  grid-area: content;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;
