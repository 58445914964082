import { getCatalogFx } from 'models/catalog';
import { QueryObserverResult, useQueries, UseQueryOptions } from 'react-query';
import { setCatalog } from 'models/catalog/model';
import { CatalogItem } from 'models/catalog/types';
import { AxiosError, AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGoodsCatalogQuery = () => {
  const queries: UseQueryOptions<
    unknown,
    AxiosError<{ error: string }>,
    AxiosResponse<{ data: Record<string, CatalogItem> }>
  >[] = [
    {
      queryKey: 'merchCatalogData',
      queryFn: () => getCatalogFx('merch'),
      onSuccess: res => {
        setCatalog(res.data.data);
      },
      refetchOnWindowFocus: false,
    },
    {
      queryKey: 'clothersCatalogData',
      queryFn: () => getCatalogFx('clothes'),
      onSuccess: res => {
        setCatalog(res.data.data);
      },
      refetchOnWindowFocus: false,
    },
  ];

  return useQueries(
    queries as UseQueryOptions<unknown, unknown, unknown>[]
  ) as QueryObserverResult<
    AxiosResponse<{ data: Record<string, CatalogItem> }>,
    AxiosError<{ error: string }>
  >[];
};
