import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PreloaderSC = styled(Preloader)`
  margin: auto;
`;

export const MoreButton = styled.div`
  margin-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > button > span > svg {
    width: 18px;
  }
`;
