import { Modal } from 'ui/Modal';
import React from 'react';
import { PopupSuccessIcon } from 'icons/';
import { Icon, Title, Text, Wrapper } from './styled';
import { declension } from 'utilities/declension';

interface IProps {
  closeModal: () => void;
  nominal: number;
}

export const SuccessActivationModal: React.FC<IProps> = ({
  closeModal,
  nominal,
}) => {
  return (
    <Modal handleClose={closeModal}>
      <Wrapper>
        <Icon>{PopupSuccessIcon}</Icon>
        <Title>Ваша карта активирована</Title>
        <Text>
          {`Вам начислено ${nominal} ${declension(
            ['бонус', 'бонуса', 'бонусов'],
            nominal
          )}. Вы можете потратить их на покупки`}
        </Text>
      </Wrapper>
    </Modal>
  );
};
