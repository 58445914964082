import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrap = styled(Link)<{ $active: boolean }>`
  opacity: 0.3;
  ${({ $active, theme }) =>
    $active
      ? `background-color: ${theme.colors.secondBgColor}; opacity: 1;`
      : null};
  border-radius: 14px;
  padding: 16px 16px 18px;
  display: flex;
  transition: opacity 0.3s;
  text-decoration: 0;
  &:hover {
    transition: opacity 0.3s;
    opacity: 1;
  }
`;

export const Inner = styled.div``;

export const Photo = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 10px;
  flex-shrink: 0;
  object-fit: cover;
  & + ${Inner} {
    margin-left: 16px;
  }
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.bodyText2}
  font-weight: bold;
`;

export const StyledDate = styled.div`
  ${({ theme }) => theme.typography.bodyText3}
  & + ${Title} {
    margin-top: 4px;
  }
`;
