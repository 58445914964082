import { PopupSuccessIcon } from 'icons';
import React, { useEffect } from 'react';
import { Wrap, Title, Text } from './styles';
import { SuccessFeedbackProps } from './types';

export const SuccessFeedback: React.FC<SuccessFeedbackProps> = ({
  handleClose,
  timeout = 5000,
  title = 'Успешное действие!',
  text = 'Действие прошло успешно!',
  ...rest
}) => {
  useEffect(() => {
    const timer = setTimeout(handleClose, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [handleClose, timeout]);

  return (
    <Wrap {...rest}>
      <div>{PopupSuccessIcon}</div>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Wrap>
  );
};
