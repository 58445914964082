import React from 'react';
import Ruble from 'components/common/Ruble';
import {
  Item,
  ItemTitle,
  ItemDigit,
  Total,
  TotalTitle,
  TotalDigit,
  Title,
  Divider,
  PaymentInfo,
  StyledPreloader,
} from './styles';
import { formatPriceToString } from 'utilities/formatPriceToString';
import { useStore } from 'effector-react';
import { $calculation } from 'models/order/model';

export const TotalPayment: React.FC<{
  isLoading: boolean;
}> = ({ isLoading, ...rest }) => {
  const calculation = useStore($calculation);

  return (
    <div {...rest}>
      <Title>Сумма заказа</Title>
      <PaymentInfo>
        {isLoading ? (
          <StyledPreloader />
        ) : (
          <>
            <Item>
              <ItemTitle>Стоимость</ItemTitle>
              <ItemDigit>
                {formatPriceToString(calculation?.order_price || 0)} {Ruble}
              </ItemDigit>
            </Item>
            {calculation?.bonus_account ? (
              <Item>
                <ItemTitle>Оплата бонусами</ItemTitle>
                <ItemDigit>
                  -{calculation.bonus_account} {Ruble}
                </ItemDigit>
              </Item>
            ) : null}
            {calculation?.inner_budget ? (
              <Item>
                <ItemTitle>Оплата премией</ItemTitle>
                <ItemDigit>
                  -{calculation.inner_budget} {Ruble}
                </ItemDigit>
              </Item>
            ) : null}
            {calculation?.charge ? (
              <Item>
                <ItemTitle>Будет начислено бонусов</ItemTitle>
                <ItemDigit>
                  {calculation.charge} {Ruble}
                </ItemDigit>
              </Item>
            ) : null}
            <Divider />
            <Total>
              <TotalTitle>Итого</TotalTitle>
              <TotalDigit>
                {formatPriceToString(calculation?.final_price || 0)} {Ruble}
              </TotalDigit>
            </Total>
          </>
        )}
      </PaymentInfo>
    </div>
  );
};
