import { useMutation } from 'react-query';
import { payPaypalFx } from 'models/order/model';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import { PaypalPaymentQuery } from './types';

export const usePaypalPaymentQuery: PaypalPaymentQuery = () =>
  useMutation(data => payPaypalFx(data), {
    onError: res => {
      if (res.response?.data.error) {
        toast.error(<ErrorMessage text={'Произошла ошибка!'} />);
      }
    },
  });
