import { MiddleCatalogItem } from 'components/Catalog/common/MiddleCatalogItem';
import { Path } from 'constants/paths';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useStore } from 'effector-react';
import { $coursesData, clearCatalog } from 'models/catalog/model';
import { CatalogCourses } from 'models/catalog/types';
import { useCoursesQuery } from 'queries/useCoursesQuery';
import React, { useEffect } from 'react';
import { Preloader } from 'ui/Preloader';
import { Title, List, Wrap, NoData } from './styles';

export const OtherCourses: React.FC<{ id: number }> = ({ id, ...props }) => {
  const { isLoading, isFetching, isError } = useCoursesQuery();
  const data: CatalogCourses[] = useStore($coursesData);
  const showPreloader = isLoading || isFetching;

  const otherCourses = data.filter(course => course.id !== id);

  useEffect(() => {
    return () => {
      clearCatalog();
    };
  }, []);

  if (showPreloader) return <Preloader />;
  if (!data.length) return <NoData>Нет других курсов</NoData>;
  if (isError) return <NoData>Не удалось загрузить саморазвития</NoData>;

  return (
    <Wrap id='other-courses' {...props}>
      <Title>Вам также рекомендовано</Title>
      <List>
        {otherCourses.map(item => {
          const start = new Date(item.date_start).getTime();
          const end = new Date(item.date_end).getTime();
          const duration = formatDistance(start, end, { locale: ru });

          return (
            <MiddleCatalogItem
              image={item.picture}
              id={item.id}
              key={item.id}
              duration={duration}
              title={item.name}
              datetime={new Date(item.date_start)}
              is_marathon={item.is_marathon}
              purchased={item.purchased}
              bg_color={item.bg_color}
              url={`${Path.course}/${item.id}`}
            />
          );
        })}
      </List>
    </Wrap>
  );
};
