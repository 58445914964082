import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { getUserFx, setUser } from 'models/auth';

export const useDeleteInstagramConnectQuery: () => UseMutationResult<
  AxiosResponse<{ success: boolean }>,
  AxiosError<{ error: string }>,
  void
> = () =>
  useMutation<
    AxiosResponse<{ success: boolean }>,
    AxiosError<{ error: string }>,
    void
  >('deleteInstagram', () =>
    apiClient.delete(Endpoint.DELETE_INSTAGRAM).then(res => {
      if (res.data.success) {
        getUserFx().then(res => {
          setUser(res.data);
        });
      }
      return res;
    })
  );
