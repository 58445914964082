import React, { useState } from 'react';
import {
  ChangeMyAvatarSC,
  ClickableArea,
  Photo,
  Warning,
  ImageOverlay,
} from './styled';
import avatarBG from 'images/avatar.png';
import CameraIcon from 'icons/Camera';
import { SelectAvatarModal } from '../SelectAvatarModal';

interface IProps {
  avatar: string;
  setAvatar: (value: string) => void;
  setAvatarId: (id: string) => void;
}

export const ChangeMyAvatar: React.FC<IProps> = ({
  avatar,
  setAvatar,
  setAvatarId,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const closeModal = () => setIsOpenModal(false);
  const openModal = () => setIsOpenModal(true);

  return (
    <ChangeMyAvatarSC>
      {isOpenModal && (
        <SelectAvatarModal
          setAvatar={setAvatar}
          closeModal={closeModal}
          setAvatarId={setAvatarId}
        />
      )}
      <ClickableArea onClick={openModal}>
        <ImageOverlay />
        <Photo src={avatar || avatarBG} alt={'Фото пользователя'} />
        {CameraIcon}
      </ClickableArea>
      <Warning>
        {'Рекомендованный размер фото - не менее 200x200 px, jpg, png.'}
      </Warning>
    </ChangeMyAvatarSC>
  );
};
