import React from 'react';
import { Preloader } from 'ui/Preloader';
import { useFetchSchools } from './useFetchSchools';
import { Payment } from './Payment';
import { List } from './List';

export const InnerInsight: React.FC = () => {
  const { school, schools, isError, isLoading } = useFetchSchools();

  if (isError) return <div>Не удалось загрузить школу</div>;
  if (isLoading) return <Preloader />;
  if (!school) return <div>Нет действующей школы</div>;

  return schools[0].purchased ? (
    <List schools={schools} />
  ) : (
    <Payment school={school} />
  );
};
