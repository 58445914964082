import React from 'react';
import { deleteCartItemFx, setCart } from 'models/cart/model';
import { useMutation } from 'react-query';
import CartService from 'services/CartService';
import { toast } from 'react-toastify';
import { SuccessMessage } from 'ui/SuccessMessage';
import { DeleteCartItemQuery } from './types';

export const useDeleteCartItemQuery: DeleteCartItemQuery = () =>
  useMutation('deleteCartItem', id => deleteCartItemFx(id), {
    onSuccess: res => {
      CartService.setCart(res.data.data);
      setCart(res.data.data);
      toast.success(<SuccessMessage text={'Товар удален из корзины'} />);
    },
  });
