import React from 'react';
import photoMember1 from 'images/photo_member_1.png';
import photoMember2 from 'images/photo_member_2.png';
import {
  Wrap,
  List,
  Member,
  Info,
  Photo,
  Name,
  Duty,
  Text,
  TextWrap,
} from './styles';

export const Team: React.FC = props => {
  return (
    <Wrap {...props}>
      <List>
        <Member>
          <Info>
            <Photo src={photoMember1} alt={'Владимир Баранов'} />
            <Name>Владимир Баранов</Name>
            <Duty>Креативный директор проекта @budsmuzhikom</Duty>
          </Info>
          <TextWrap>
            <Text>
              Автор и креативный директор проекта &quot;Будь с мужиком!&ldquo;
              Профессиональный коуч, квалификационная сертификация PCC ICF.
              Профессиональный менеджер проектов и программ проектов. Мастер
              художественного слова. Имеет более 10 лет опыта индивидуальной
              экспертной работы с мужчинами и женщинами.
            </Text>
            <Text>
              Повышение квалификации в области психологии: Diplôme Professionnel
              de l’Université Européenne Jean Monnet A.I.S.B.L. Bruxelles
              (UEJM).
            </Text>
            <Text>
              Владимир придумал лучший блог в Инстаграм для женщин. Дает не
              готовые выводы, а причины и следствия, при этом – заставляет
              думать. Прямо и честно пишет про отношения, типы, ранги, модели
              поведения. Логическим путем предсказывает события, состояния и
              реакции. Объясняет, почему женщина – Богиня нипочему. Вскрывает
              секреты мужского заговора. ДЕЛАЕТ МИР МУЖЧИН ПОЛНОСТЬЮ ПОНЯТНЫМ!!!
              *удаков называет *удаками и учит их идентифицировать. Убирает
              целлюлит головного мозга, ожирение подсознания, лень и апатию
              внутреннего мира. Ржавым гвоздем незашоренного юмора резко и
              дерзко выцарапывает золотые истины на височных долях подписчиц.
            </Text>
            <Text>
              Показывает на пальцах причинно-следственные связи. Убеждает не он
              – убеждает жизнь. Все советы Владимира работают, если им
              следовать!
            </Text>
          </TextWrap>
        </Member>
        <Member>
          <Info>
            <Photo src={photoMember2} alt={'Алина Ермакова'} />
            <Name>Алина Ермакова</Name>
            <Duty>Директор проекта @budsmuzhikom</Duty>
          </Info>
          <TextWrap>
            <Text>
              <Text>
                Соавтор, соучредитель и директор проекта @budsmuzhikom.
                Профессиональный коуч, квалификационная сертификация PCC ICF.
              </Text>
              <Text>
                Эксперт по отношениям, эксперт в сфере личностного роста,
                женского предназначения, взаимоотношений с детьми и родителями и
                пр.
              </Text>
              <Text>
                Бизнес-советник, высококлассный HR-эксперт.Соавтор всех курсов и
                тренингов проекта @budsmuzhikom. Бизнес-леди, достигшая успеха в
                различных сферах предпринимательской деятельности. Руководитель
                «Российского расстановочного центра».
              </Text>
              <Text>
                Имеет огромный опыт индивидуальной экспертной работы с мужчинами
                и женщинами.
              </Text>
              <Text>
                Повышение квалификации в области психологии: Diplôme
                Professionnel der Université Européenne Jean Monnet A.I.S.B.L.
                Bruxelles (UEJM).
              </Text>
              <Text>
                Алина помогает мужчинам и женщинам во всем: наладить отношения в
                семье и в паре, найти путь к успеху, построить бизнес. Умеет
                увидеть и показать самое главное, задать вектор направления в
                нужную сторону.
              </Text>
              <Text>
                Заставляет ценить и уважать себя. Рассказывает, как добиваться
                своей цели. Настраивает женщин на грамотное общение с мужчинами.
                Помогает принять правильное решение.
              </Text>
              <Text>
                ПРОСТЫМИ СЛОВАМИ ОБЪЯСНЯЕТ СЛОЖНЫЕ ЗАКОНЫ РОДОВОЙ СИСТЕМЫ!
              </Text>
              <Text>
                Показывает причины, мешающие достичь успеха. Говорит правду,
                какой бы неприятной она ни была. Выводит на новый уровень
                осознанности. Алина предлагает клиентам ценнейшую информацию для
                проработки и изменения жизни в лучшую сторону!
              </Text>
            </Text>
          </TextWrap>
        </Member>
      </List>
    </Wrap>
  );
};
