import React, { useEffect } from 'react';
import { DeliveryOptionType } from './DeliveryOption/types';
import { CourierFormFields } from './CourierForm';
import { DeliveryOption } from './DeliveryOption';
import { PickUpFormFields } from './PickUpForm';
import { OfficeFormFields } from './OfficeForm';
import { $deliveryOptions, clearDeliveryOptions } from 'models/order/model';
import { useStore } from 'effector-react';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import { useDeliveryOptionsQuery } from 'queries/buy/useDeliveryOptionsQuery';
import { useSetDeliveryQuery } from 'queries/buy/useSetDeliveryQuery/';
import { Form } from 'react-final-form';
import { Button } from 'ui/Button';
import { ButtonWrap, StyledButtonPreloader, StyledPreloader } from './styles';
import {
  CDEKCourierFormValues,
  CDEKOfficeFormValues,
  SelfPickFormValues,
} from './types';
import { RouteComponentProps } from 'react-router';
import { useAddresses } from 'models/addresses/model';
import { formatAddress } from 'utilities/formatAddress';

export const Delivery: React.FC<
  RouteComponentProps<{ id: string }> & { changeToNextStep: () => void }
> = ({ changeToNextStep, match }) => {
  const orderId = match.params.id;
  const deliveryOptions = useStore($deliveryOptions);
  const addresses = useAddresses();
  const next = () => {
    changeToNextStep();
  };
  const renderForm = (key: DeliveryOptionType) => {
    // try {
    switch (key) {
      case 'cdek_courier': {
        return <CourierFormFields />;
      }
      case 'self_pickup': {
        return <PickUpFormFields />;
      }
      case 'cdek_pickup': {
        return <OfficeFormFields />;
      }
      default:
        throw new Error(`Unknown order state: '${key}'!`);
    }
  };
  const { isLoading } = useDeliveryOptionsQuery();
  const { mutate, isLoading: isButtonLoading } = useSetDeliveryQuery(next);

  const handleSubmit = (
    values: SelfPickFormValues & CDEKCourierFormValues & CDEKOfficeFormValues
  ) => {
    const { pvz_code, city_code, delivery_key } = values || {};
    let address = values.address;
    if (!delivery_key) {
      toast.error(<ErrorMessage text={'Выберите способ доставки!'} />);
      return;
    }
    const { id: delivery_type_id } =
      (deliveryOptions &&
        deliveryOptions.find(item => item.code === delivery_key)) ??
      {};
    if (!orderId) {
      toast.error(
        <ErrorMessage
          text={'Такого заказа не существует. Повторите создать заказ вновь'}
        />
      );
      return;
    }

    if (delivery_key === 'cdek_courier') {
      if (addresses.length) {
        const parts = values.address?.split(',');
        address =
          parts &&
          `${parts[0] ?? ''},${parts[1] ?? ''}, ${parts[2] ?? ''}, ${
            parts[3] ?? ''
          }, ${parts[4] ?? ''}, ${parts[5] ?? ''}, ${
            values.flat ? `, кв.${values.flat}` : ''
          }${values.domophone ? `, домофон: ${values.domophone}` : ''}`;
      } else {
        address = formatAddress(values);
      }
    }

    if (!address) {
      toast.error(<ErrorMessage text={'Укажите адрес'} />);
      return;
    }
    if (delivery_type_id) {
      const newValues = {
        pvz_code,
        city_code: city_code,
        address,
        orderId: orderId,
        delivery_type_id: delivery_type_id,
      };
      mutate(newValues);
    }
  };

  useEffect(() => {
    return () => {
      clearDeliveryOptions();
    };
  }, []);

  return isLoading ? (
    <StyledPreloader />
  ) : deliveryOptions?.length ? (
    <Form
      onSubmit={handleSubmit}
      id='DeliveryForm'
      render={props => {
        return (
          <form onSubmit={props.handleSubmit}>
            <DeliveryOption deliveryOptions={deliveryOptions} />
            {props?.values?.['delivery_key'] ? (
              <>
                {renderForm(props.values['delivery_key'] as DeliveryOptionType)}
                <ButtonWrap>
                  {isButtonLoading ? (
                    <StyledButtonPreloader />
                  ) : (
                    <Button type='submit' style={{ width: '150px' }}>
                      Продолжить
                    </Button>
                  )}
                </ButtonWrap>
              </>
            ) : null}
          </form>
        );
      }}
    />
  ) : null;
};
