import { regExpOnlyTwoNumbers } from 'constants/regExps';

/**
 * Добавляет 0 к числу при необходимости
 * @param v
 * @returns
 */

export const zeroPad = (v: number): string =>
  v.toString().match(regExpOnlyTwoNumbers) ? v.toString() : '0' + v;
