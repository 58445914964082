import { createEffect, createEvent, restore } from 'effector';
import { AxiosError, AxiosResponse } from 'axios';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { HomeworkStore, Comment } from './types';

// Эффекты
export const getHomeworkFx = createEffect<
  number,
  AxiosResponse<{ data: HomeworkStore }>,
  AxiosError<{ error: string }>
>(async id => await apiClient.get(Endpoint.GET_HOMEWORK + `/${id}`));

export const uploadFileFx = createEffect<
  { formData: FormData; isComment: boolean },
  AxiosResponse<{ file_id: string[] }>,
  AxiosError<{ error: string }>
>(({ formData, isComment }) => {
  const endpoint = isComment
    ? Endpoint.UPLOAD_COMMENT_FILE
    : Endpoint.UPLOAD_HOMEWORK_FILE;
  return apiClient.post(endpoint, formData);
});

export const uploadHomeworkFx = createEffect<
  {
    course_id: number;
    homework_id: number;
    message: string;
    file_id?: number[];
  },
  AxiosResponse<{ data: HomeworkStore }>,
  AxiosError<{ error: string }>
>(data => apiClient.post(Endpoint.UPLOAD_HOMEWORK, data));

export const uploadCommentFx = createEffect<
  {
    complete_id: number;
    type: 'complete_homework';
    message: string;
    files?: number[];
  },
  AxiosResponse<{ data: Comment }>,
  AxiosError<{ error: string }>
>(data => apiClient.post(Endpoint.UPLOAD_COMMENT, data));

// События
export const setHomework = createEvent<HomeworkStore>();
export const setComments = createEvent<Comment[]>();
export const setComment = createEvent<Comment>();

// Сторы
export const $homework = restore(setHomework, null);
export const $comments = restore(setComments, []);

// Подписки
$comments.on(setComment, (state, payload) => [...state, payload]);
