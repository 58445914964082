import { useMutation, UseMutationResult } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { AxiosError, AxiosResponse } from 'axios';
import { base64toPDF } from 'utilities/base64ToPdf';

type GetDiplomaPdfType = (
  hash: string
) => UseMutationResult<
  AxiosResponse<{ data: { base64: string } }>,
  AxiosError<{ error: string }>,
  string
>;

export const useGetDiplomaPdfQuery: GetDiplomaPdfType = () =>
  useMutation<
    AxiosResponse<{ data: { base64: string } }>,
    AxiosError<{ error: string }>,
    string
  >(
    'getDiplomaPdf',
    hash => apiClient.get(`${Endpoint.GET_DIPLOMA_PDF}/${hash}`),
    {
      onSuccess: res => {
        base64toPDF(res.data.data.base64);
      },
    }
  );
