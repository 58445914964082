export const RocketShuttleLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M19.2 35.5098C9.78 36.3198 11.58 44.9598 9 50.7698C14.81 48.1898 23.45 49.9898 24.26 40.5698'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.9398 25.97C36.7698 27.14 34.8698 27.14 33.6998 25.97C32.5298 24.8 32.5298 22.9 33.6998 21.73C34.8698 20.56 36.7698 20.56 37.9398 21.73C39.1098 22.9 39.1098 24.8 37.9398 25.97Z'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.6306 37.28C30.9106 36.13 37.8806 31.69 42.4606 27.11C50.6306 18.94 50.6706 9 50.6706 9C50.6706 9 40.7306 9.04 32.5606 17.21C27.9806 21.79 23.5406 28.76 22.3906 33.04L26.6306 37.28Z'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.5795 24.6396L14.9295 24.9396L12.4395 31.5796L16.8995 30.3796L22.3895 33.0396'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.0409 33.0898L34.7309 44.7398L28.0909 47.2298L29.2909 42.7698L26.6309 37.2798'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.4607 30.21L20.9707 38.7'
        stroke='#181818'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect
          width='43.67'
          height='43.77'
          fill='white'
          transform='translate(8 8)'
        />
      </clipPath>
    </defs>
  </svg>
);

