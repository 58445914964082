import { useQuery, UseQueryResult } from 'react-query';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { Path } from 'constants/paths';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';

type ConnectInstagramQueryType = (
  code: string
) => UseQueryResult<
  AxiosResponse<{ success: boolean }>,
  AxiosError<{ error: string }>
>;

export const useConnectInstagramQuery: ConnectInstagramQueryType = code => {
  const history = useHistory();
  return useQuery<
    string,
    AxiosError<{ error: string }>,
    AxiosResponse<{ success: boolean }>
  >(
    'instagramCallback',
    () =>
      apiClient.get(Endpoint.INSTAGRAM_CALLBACK, { params: { code: code } }),
    {
      onSuccess: res => {
        if (res.data?.success) {
          history.push(`${Path.account}/my-data`);
        }
      },
      onError: () => {
        toast.error(<ErrorMessage />);
        history.push(`${Path.account}/my-data`);
      },
    }
  );
};
