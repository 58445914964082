import React, { HTMLAttributes, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Wrap, StyledTrainingItem, StyledPreloader, NoData } from './styles';
import { RouteComponentProps } from 'react-router';
import { $trainingItem, clearTrainingItem } from 'models/catalog/model';
import { useGetTrainingItemQuery } from 'queries/catalog/useGetTrainingItemQuery';

export const Training: React.FC<
  RouteComponentProps<{ id: string }> & HTMLAttributes<HTMLDivElement>
> = ({ match, className }) => {
  const { id } = match.params;
  const data = useStore($trainingItem);
  const { isLoading, isFetching } = useGetTrainingItemQuery(id);

  useEffect(() => {
    return () => clearTrainingItem();
  }, []);

  if (isLoading || isFetching) return <StyledPreloader />;

  if (!data) return <NoData>Нет данных</NoData>;

  return (
    <Wrap className={className}>
      <StyledTrainingItem {...data} />
    </Wrap>
  );
};
