import React from 'react';

export const PauseMediumIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 3H4V15H8V3ZM14 3H10V15H14V3Z'
    />
  </svg>
);
