import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  StyledEmailCheck,
  StyledPaymentOption,
  PaymentLotList,
  PaymentButton,
  OfferLicence,
  StyledPreloader,
  StyledTotalPayment,
  OfferLicenceText,
} from './styles';
import { PaymentLot } from './PaymentLot';
import { $accumulatedBonuses, $calculation, $order } from 'models/order/model';
import { useStore } from 'effector-react';
import { useDiscountCalculationQuery } from 'queries/useDiscountCalculateQuery';
import { useSberbankPaymentQuery } from 'queries/userSberbankPaymentQuery';
import { useOrderDetailQuery } from 'queries/useOrderDetailQuery';
import { Preloader } from 'ui/Preloader';
import { LoyaltyProgram } from './LoaltyProgram';
import { Checkbox } from 'ui/Checkbox';
import { OrderType } from '../DirectBuy';
import { usePaypalPaymentQuery } from 'queries/usePaypalPaymentQuery';
import { useHistory } from 'react-router-dom';
import { Path } from 'constants/paths';
import { PaypalButton } from '../../Buy/Content/Payment/PaypalButton';
import {
  SetSubmitCallbackType,
  SubmitCallbackType,
} from 'interfaces/externalSubmitTypes';

export type PaymentOptionKeys = 'bank' | 'paypal';

export const DirectPayment: React.FC<{
  id: string;
  className?: string;
  type: OrderType;
}> = ({ id: orderId, className, type }) => {
  const history = useHistory();
  const [option, setOption] = useState<PaymentOptionKeys>('bank');
  const [isChecked, setIsChecked] = useState(false);

  const orderDetails = useStore($order);
  const accumulatedBonuses = useStore($accumulatedBonuses);

  const { isLoading, mutate: calculate } = useDiscountCalculationQuery();
  const orderQuery = useOrderDetailQuery(orderId);
  const sberbankMutation = useSberbankPaymentQuery();
  const paypalMutation = usePaypalPaymentQuery();
  const calculation = useStore($calculation);
  const final_price = calculation?.final_price || 0;

  const handlePaymentSuccess = useCallback(() => {
    history.push(`${Path.myOrders}`);
  }, [history]);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOption(e.target.value as PaymentOptionKeys);
  };

  const handleCheckboxChange = () => setIsChecked(checked => !checked);

  useEffect(() => {
    if (orderId) {
      calculate({ order_id: orderId });
    }
  }, [orderId, calculate]);

  const handleSberbankPayment = ({ email }: { email: string }) => {
    if (orderId)
      sberbankMutation.mutate({
        order_id: orderId,
        email,
        ...accumulatedBonuses,
      });
  };

  const handlePaypalPayment = useCallback(
    (paypal_order_id: string, paypal_authorize_id: string) => {
      if (orderId)
        paypalMutation.mutate(
          {
            paypal_order_id,
            id: orderId.toString(),
            paypal_authorize_id,
          },
          {
            onSuccess: () => {
              handlePaymentSuccess();
            },
          }
        );
    },
    [handlePaymentSuccess, orderId, paypalMutation]
  );

  // Вспомогательные переменная и функция для внешней отправки форм
  const buttonHandler = useRef<SubmitCallbackType>();
  const setButtonHandler: SetSubmitCallbackType = func => {
    buttonHandler.current = func;
  };

  const showPreloader = sberbankMutation.isLoading;
  return (
    <div className={className}>
      <PaymentLotList>
        {orderQuery.isLoading || orderQuery.isFetching ? (
          <Preloader />
        ) : (
          orderDetails?.basket.map((item, idx) => (
            <PaymentLot key={idx} {...item} />
          ))
        )}
      </PaymentLotList>
      <StyledPaymentOption handleChange={handleChange} option={option} />
      <StyledEmailCheck
        handleSubmit={handleSberbankPayment}
        setButtonHandler={setButtonHandler}
      />
      <LoyaltyProgram orderId={orderId} finalPrice={final_price} type={type} />
      <StyledTotalPayment isLoading={isLoading} />
      {showPreloader ? (
        <StyledPreloader />
      ) : option === 'bank' ? (
        <PaymentButton
          disabled={!isChecked}
          type='button'
          onClick={buttonHandler.current}
        >
          Оплатить
        </PaymentButton>
      ) : orderId ? (
        option === 'paypal' && calculation ? (
          <PaypalButton
            id={orderId}
            disabled={!isChecked}
            value={calculation.final_price.toString()}
            handlePayment={handlePaypalPayment}
          />
        ) : null
      ) : (
        <StyledPreloader />
      )}
      <OfferLicence>
        <Checkbox
          id='licence'
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <OfferLicenceText htmlFor='licence'>
          Я прочитал(а) и соглашаюсь с ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ сайта,
          ПРАВИЛАМИ ОПЛАТЫ, условиями ДОГОВОРА - ОФЕРТЫ, при оплате выездных или
          расстановочных мероприятий - с ДОГОВОРОМ-ОФЕРТОЙ ДЛЯ ВЫЕЗДНЫХ
          МЕРОПРИЯТИЙ и ДОГОВОРОМ-ОФЕРТОЙ ДЛЯ РАССТАНОВОЧНЫХ МЕРОПРИЯТИЙ, а при
          оплате членства в ВИП-Клубе - с условиями ДОГОВОРА-ОФЕРТЫ ДЛЯ
          УЧАСТНИКОВ ВИП-КЛУБА.
        </OfferLicenceText>
      </OfferLicence>
    </div>
  );
};
