import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
`;
