import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.css';
import './fonts/fonts.css';
import 'swiper/swiper.min.css';
import { App } from './components/App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { theme } from 'ui/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import 'models/init';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-toastify/dist/ReactToastify.min.css';

const queryClient = new QueryClient();

// TODO переделать, если появит решение лучше
window.onbeforeunload = function () {
  // фикс проблемы скролла на футер в маленьком окне браузера
  window.scrollTo(0, 0);
};

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
