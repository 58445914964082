import React, { HTMLAttributes, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Wrap, StyledEventItem, StyledPreloader, NoData } from './styles';
import { useGetEventItemQuery } from 'queries/catalog/useGetEventItemQuery';
import { RouteComponentProps } from 'react-router';
import { $eventItem, clearEventItem } from 'models/catalog/model';

export const Event: React.FC<
  RouteComponentProps<{ id: string }> & HTMLAttributes<HTMLDivElement>
> = ({ match, className }) => {
  const { id } = match.params;
  const { isLoading, isFetching } = useGetEventItemQuery(id);
  const data = useStore($eventItem);

  useEffect(() => {
    return () => clearEventItem();
  }, []);

  if (isLoading || isFetching) return <StyledPreloader />;
  if (!data) return <NoData>Нет данных</NoData>;

  return (
    <Wrap className={className}>
      <StyledEventItem {...data} />
    </Wrap>
  );
};
