import React from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/esm/locale';
import { useEventsInMonth } from 'models/calendar/model';
import {
  MonthDaysWrap,
  MonthTitle,
  WeekDayTitle,
  DayDate,
  MonthDayCell,
  MonthDayWeekCell,
  EventLine,
} from './styles';
import { Link } from 'react-router-dom';

export type MonthProps = { date: Date };

const Month: React.FC<MonthProps> = ({ date }) => {
  const monthDaysArray = useEventsInMonth(date);
  //Переводим строку в объект даты для дальнейшей работы
  const currentDate = new Date(date);
  //Получаем название месяца
  const monthTitle = format(currentDate, 'LLLL', { locale: ru });
  const titlePath = 'month/' + format(currentDate, 'LLLL').toLowerCase() + '/';

  return (
    <div>
      <MonthTitle to={titlePath}>{monthTitle}</MonthTitle>
      <MonthDaysWrap>
        <MonthDayWeekCell>
          <WeekDayTitle>Пн</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Вт</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Ср</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Чт</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Пт</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Сб</WeekDayTitle>
        </MonthDayWeekCell>
        <MonthDayWeekCell>
          <WeekDayTitle>Вс</WeekDayTitle>
        </MonthDayWeekCell>
        {monthDaysArray.map((item, idx) => {
          return (
            <MonthDayCell
              as={item.day ? Link : 'div'}
              to={item.day ? titlePath + item.day : ''}
              key={idx}
            >
              <DayDate>{item.day}</DayDate>
              {item.events?.map((item, index) => (
                <EventLine key={index} {...item} />
              ))}
            </MonthDayCell>
          );
        })}
      </MonthDaysWrap>
    </div>
  );
};

export default React.memo(Month);
