import { FormApi } from 'final-form';

type Map = {
  [key: string]: { color: string; text: string };
};

type FormValues = { message: string };

export enum Statuses {
  REVISION = 'Требуется доработка',
  CHECKOUT = 'На проверке',
  COMPLETED = 'Принято',
}

export const statuses: Map = {
  [Statuses.REVISION]: {
    color: 'error',
    text: 'На доработке',
  },
  [Statuses.CHECKOUT]: {
    color: 'progress',
    text: Statuses.CHECKOUT,
  },
  [Statuses.COMPLETED]: {
    color: 'success',
    text: 'Выполнено',
  },
};

export type TForm = FormApi<FormValues>;
export type SubmitHandler = ({ message }: FormValues, form: TForm) => void;
export type HomeworkMutation = (
  isRevision: boolean,
  completedHomeworkId: number,
  homeworkId: number,
  courseId: string,
  files: string[],
  message: string
) => void;
