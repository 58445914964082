import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CalendarEventTypes } from 'models/calendar/types';
import { calendarMonthEventsColors } from 'constants/calendarEventsColors';

export const MonthDaysWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(7, minmax(40px, auto));
  align-items: flex-start;
`;

export const MonthTitle = styled(Link)`
  ${({ theme }) => theme.typography.headline3};
  text-transform: capitalize;
`;

export const WeekDayTitle = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  color: ${({ theme }) => theme.colors.secondColor};
  user-select: none;
  cursor: auto;
`;

export const DayDate = styled.span`
  ${({ theme }) => theme.typography.bodyText2};
  padding: 8px;
  border-radius: 14px;
  display: block;
  width: 100%;
  text-align: center;
`;

export const EventLine = styled.div<{
  type?: CalendarEventTypes;
  start: boolean;
  end: boolean;
  purchased: boolean;
}>`
  width: 100%;
  height: 4px;
  background-color: ${({ type }) =>
    type ? calendarMonthEventsColors[type] : null};
  ${({ start }) =>
    start
      ? `border-bottom-left-radius: 2px; border-top-left-radius: 2px;`
      : null}
  ${({ end }) =>
    end
      ? `border-bottom-right-radius:2px; border-top-right-radius: 2px;`
      : null}
  ${({ purchased }) => (!purchased ? `opacity: 0.5;` : null)}
  & + & {
    margin: 2px 0 0;
  }
`;

export const MonthDayCell = styled.div<{
  to: string;
  isSelected: boolean;
}>`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.typography.bodyText2};
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-height: 40px;
  ${DayDate} {
    ${({ isSelected, theme }) =>
      isSelected && `background-color: ${theme.colors.actionColor};`}
  }
  &:hover ${DayDate} {
    transition: background-color 0.3s;
    background-color: ${({ theme }) => theme.colors.secondBgColor};
`;

export const MonthDayWeekCell = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const MonthHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;
