import { getPlansDataFx, setPlansData } from 'models/education/model';
import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { PlansResponseType } from 'models/education/types';

export const usePlansQuery: (
  category: string
) => UseQueryResult<
  AxiosResponse<{
    data: PlansResponseType;
  }>,
  AxiosError<{ error: string }>
> = category =>
  useQuery('myEducation', () => getPlansDataFx(category), {
    onSuccess: res => {
      setPlansData(res.data.data);
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
