import React from 'react';
import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router-dom';
import { MonthKey } from 'constants/monthKey';
import { ru } from 'date-fns/locale';
import { Path } from 'constants/paths';
import { MonthsType } from 'components/Calendar/common/types';
import { useEventsInMonth } from 'models/calendar/model';
import { Calendar, Miniature, MonthPageWrap } from './styles';

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const MonthPage: React.FC<
  RouteComponentProps<{ year: string; month: MonthsType }>
> = ({ history, match }) => {
  const { year, month } = match.params;
  const currentDate = new Date(`${year} ${MonthKey[month]}`);
  const monthDaysArray = useEventsInMonth(currentDate);

  //Функция перелистывания месячной миниатюры
  const handleMonthChange = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      if (month === 'december') {
        history.push(
          `${Path.calendar}/${parseInt(year) + 1}${Path.month}/january`
        );
      } else {
        const currentMonthIndex = months.findIndex(item => item === month);
        const nextMonth = months[currentMonthIndex + 1];
        history.push(`${Path.calendar}/${year}${Path.month}/${nextMonth}`);
      }
    } else if (direction === 'prev') {
      if (month === 'january') {
        history.push(
          `${Path.calendar}/${parseInt(year) - 1}${Path.month}/december`
        );
      } else {
        const currentMonthIndex = months.findIndex(item => item === month);
        const prevMonth = months[currentMonthIndex - 1];
        history.push(`${Path.calendar}/${year}${Path.month}/${prevMonth}`);
      }
    }
  };

  return (
    <MonthPageWrap>
      <Miniature
        days={monthDaysArray}
        title={format(currentDate, 'LLLL', { locale: ru })}
        handleMonthChange={handleMonthChange}
      />
      <Calendar days={monthDaysArray} />
    </MonthPageWrap>
  );
};
