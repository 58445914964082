import { createEffect, createEvent, restore } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { NewsListItem } from './types';
import { TParams } from 'queries/news/useNewsQuery';
import { AxiosError, AxiosResponse } from 'axios';

export const setNews = createEvent<NewsListItem[]>();

export const $news = restore<NewsListItem[]>(setNews, []);

export const newsFx = createEffect<
  TParams,
  AxiosResponse<{
    data: NewsListItem[];
  }>,
  AxiosError<{
    error: string;
  }>
>((params: TParams) => apiClient.post(Endpoint.GET_NEWS, params));
