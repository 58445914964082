import React, { useMemo } from 'react';
import { usePaypalButtonLinkQuery } from 'queries/usePaypalButtonLinkQuery/usePaypalButtonLinkQuery';
import { usePaypalQuery } from 'hooks/usePaypalQuery';
import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

const PaypalButtonContainer = styled.div<{ disabled: boolean }>`
  width: 100%;
  margin: 24px auto 0;
  ${({ disabled }) =>
    disabled && 'cursor: not-allowed;  opacity: .7;  pointer-events: none;'};
`;

export const StyledPreloader = styled(Preloader)`
  margin: 24px 0 0;
`;

export const PaypalButton: React.FC<{
  id: string;
  value: string;
  disabled: boolean;
  handlePayment: (paypal_order_id: string, paypal_authorize_id: string) => void;
}> = ({ value, handlePayment, id, disabled }) => {
  const paypalButtonLinkQuery = usePaypalButtonLinkQuery(id);
  const buttonUrl = paypalButtonLinkQuery?.data?.data.data.buttonUrl;
  const options = useMemo(
    () => ({
      buttonUrl: buttonUrl,
      value,
    }),
    [buttonUrl, value]
  );

  usePaypalQuery(options, handlePayment);

  return (
    <PaypalButtonContainer disabled={disabled} id='paypal-button-container' />
  );
};

declare global {
  interface Window {
    paypal: unknown;
  }
}
