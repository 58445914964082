import { createEffect, createEvent, createStore } from 'effector';
import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import {
  BonusHistoryItem,
  BonusHistoryRequest,
  BonusHistoryStore,
} from './types';
import { AxiosError, AxiosResponse } from 'axios';
import { useStore } from 'effector-react';

export const $bonusHistory = createStore<BonusHistoryStore>(null);

export const setBonusHistory = createEvent<BonusHistoryItem[]>();
export const clearBonusHistory = createEvent();

export const getBonusHistoryFx = createEffect<
  BonusHistoryRequest,
  AxiosResponse<{ data: BonusHistoryItem[] }>,
  AxiosError<{ error: string }>
>(data => apiClient.post(Endpoint.GET_BONUS_HISTORY, data));

$bonusHistory
  .on(setBonusHistory, (state, payload) => {
    const data = [...(state ?? []), ...payload];
    return data
      .sort(
        (a, b) =>
          new Date(a.date_create).getTime() - new Date(b.date_create).getTime()
      )
      .reverse();
  })
  .reset(clearBonusHistory);

export const useBonusHistory: () => BonusHistoryStore = () =>
  useStore($bonusHistory);
