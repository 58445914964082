import styled from 'styled-components';
import { Button } from 'ui/Button';

export const Item = styled.a`
  display: flex;
  align-items: center;
  padding: 7px;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 14px;
`;

export const ItemTitle = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 0 auto 0 0;
`;

export const Icon = styled.div`
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.mainBgColor};
  & + ${ItemTitle} {
    margin-left: 12px;
  }
`;

export const Info = styled.p`
  margin: 0 5px 0 8px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const PlayButton = styled(Button)`
  padding: 9px;
  border-radius: 50%;
  & + ${ItemTitle} {
    margin-left: 12px;
  }
`;
