import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.p`
  margin: 12px 0 0;
`;

export const ResendCodeButton = styled(Button)`
  margin: 12px 0 0;
`;

export const StyledPreloader = styled(Preloader)`
  text-align: center;
  margin: 8px 0 0;
`;
