import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  text-align: center;
`;

export const ItemWrap = styled.div`
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const NoItemsText = styled.p`
  ${({ theme }) => theme.typography.headline3}
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const Title = styled.p`
  margin: 40px 0 0;
  ${({ theme }) => theme.typography.headline2}
`;

export const Text = styled.p`
  margin: 16px 0 0;
  ${({ theme }) => theme.typography.bodyText2}
`;

export const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GooglePlayLink = styled.a`
  display: block;
  width: 183px;
  line-height: 0;
  & > img {
    width: 100%;
  }
`;

export const AppStoreLink = styled.a`
  display: block;
  width: 148px;
  font-size: 0;
  & > img {
    width: 100%;
  }
`;
