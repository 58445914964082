import styled from 'styled-components';
import bg from 'images/VIP_club_banner.png';
import { Button } from 'ui/Button';

export const Inner = styled.div`
  margin-left: auto;
  width: 280px;
`;

export const Wrap = styled.div`
  padding: 64px 110px 64px 0;
  background-image: url(${bg});
  background-size: cover;
  border-radius: 14px;
`;

export const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 16px 0 0;
  color: ${({ theme }) => theme.colors.mainBgColor};
`;
export const Title = styled.h1`
  ${({ theme }) => theme.typography.headline1};
  color: ${({ theme }) => theme.colors.mainBgColor};
  margin: 0;
`;

export const StyledButton = styled(Button)`
  margin-top: 40px;
`;
