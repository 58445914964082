export const HomeIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.48822 1.00887C9.78915 0.77482 10.2105 0.77482 10.5115 1.00887L18.0115 6.84221C18.2144 7.00009 18.3332 7.24284 18.3332 7.5V16.6667C18.3332 17.3297 18.0698 17.9656 17.6009 18.4344C17.1321 18.9033 16.4962 19.1667 15.8332 19.1667H4.1665C3.50346 19.1667 2.86758 18.9033 2.39874 18.4344C1.9299 17.9656 1.6665 17.3297 1.6665 16.6667V7.5C1.6665 7.24284 1.78523 7.00009 1.98822 6.84221L9.48822 1.00887ZM3.33317 7.90757V16.6667C3.33317 16.8877 3.42097 17.0996 3.57725 17.2559C3.73353 17.4122 3.94549 17.5 4.1665 17.5H15.8332C16.0542 17.5 16.2661 17.4122 16.4224 17.2559C16.5787 17.0996 16.6665 16.8877 16.6665 16.6667V7.90757L9.99984 2.72238L3.33317 7.90757Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.6665 10C6.6665 9.53976 7.0396 9.16667 7.49984 9.16667H12.4998C12.9601 9.16667 13.3332 9.53976 13.3332 10V18.3333C13.3332 18.7936 12.9601 19.1667 12.4998 19.1667C12.0396 19.1667 11.6665 18.7936 11.6665 18.3333V10.8333H8.33317V18.3333C8.33317 18.7936 7.96007 19.1667 7.49984 19.1667C7.0396 19.1667 6.6665 18.7936 6.6665 18.3333V10Z'
      fill='#B1B1B1'
    />
  </svg>
);