import React from 'react';

export const SupportIcon = (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6778 15.446L11.594 14.5432L10.3845 13.3159L9.46601 14.221L9.46211 14.2249C8.6342 15.0512 8.52108 16.4805 9.42548 17.3718L13.5868 21.4726C13.9258 21.8065 14.4712 21.8025 14.8052 21.4636C15.1392 21.1247 15.1352 20.5792 14.7963 20.2453L10.6349 16.1445C10.486 15.9978 10.4538 15.6712 10.6778 15.446Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.91856 10.9539C10.7974 10.0878 12.1738 10.1409 13.0191 10.9738L18.6123 16.4856C18.9512 16.8196 18.9552 17.3651 18.6212 17.704C18.2872 18.0429 17.7417 18.0469 17.4028 17.7129L11.8096 12.2011C11.6013 11.9958 11.3027 12.009 11.128 12.1812L11.1212 12.1878C10.926 12.3803 10.9355 12.6765 11.1106 12.8469L11.1145 12.8507L16.7077 18.3625C17.0466 18.6964 17.0506 19.2419 16.7166 19.5808C16.3826 19.9197 15.8371 19.9237 15.4982 19.5898L9.90676 14.0797C9.01698 13.2116 9.05417 11.8057 9.9118 10.9605L9.91856 10.9539Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6778 19.213L11.594 18.3102L10.3845 17.0829L9.46601 17.988L9.46599 17.988L9.46211 17.9919C8.6342 18.8182 8.52108 20.2476 9.42548 21.1388L15.1528 26.7827C15.8574 27.4771 16.7952 27.79 17.4898 28.0171C17.5354 28.032 17.58 28.0465 17.6236 28.0607C17.94 28.1637 18.205 28.25 18.4436 28.3508C18.712 28.4641 18.8758 28.568 18.9755 28.6663L20.4073 30.0772L21.6168 28.8499L20.1849 27.439C19.8626 27.1213 19.4725 26.9148 19.1138 26.7634C18.8024 26.6319 18.4609 26.521 18.1535 26.4211C18.11 26.4069 18.0672 26.393 18.0252 26.3793C17.2897 26.1389 16.7382 25.926 16.3622 25.5554L10.6349 19.9115C10.486 19.7648 10.4538 19.4382 10.6778 19.213Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.2819 10.4537C14.1777 9.57095 15.5346 9.67812 16.3622 10.4936L21.003 15.067C21.3419 15.4009 21.3459 15.9464 21.012 16.2853C20.678 16.6242 20.1325 16.6282 19.7936 16.2943L15.1536 11.7218L15.7567 11.1081L15.1527 11.721C14.9267 11.4982 14.6491 11.5255 14.4913 11.681L13.4985 12.6594L12.2891 11.4321L13.2819 10.4537Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.3636 11.6979C20.9863 11.6427 21.5835 11.8864 22.0887 12.3842C22.528 12.8171 22.8903 13.175 23.2033 13.4844C23.7164 13.9913 24.0974 14.3678 24.4694 14.7302C25.1371 15.3808 25.9851 16.356 26.3148 18.086C26.5096 19.0841 26.7264 19.7697 27.3433 20.3776L28.7818 21.7952L27.5724 23.0225L26.1338 21.6049C25.1301 20.6158 24.8335 19.4915 24.6232 18.4141L24.6224 18.4102L24.6224 18.4102C24.3845 17.1598 23.8011 16.4849 23.2669 15.9643C22.891 15.5981 22.5032 15.2149 21.9859 14.7038C21.673 14.3947 21.3129 14.0388 20.8793 13.6115C20.6686 13.4039 20.5498 13.4113 20.5158 13.4143C20.4476 13.4203 20.3253 13.4657 20.1774 13.6115L19.2251 14.55L18.0156 13.3227L18.9679 12.3842C19.2995 12.0575 19.775 11.7501 20.3636 11.6979Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.9484 21.4188C28.3377 21.0352 28.9629 21.0352 29.3522 21.4188L31.0323 23.0744C31.3712 23.4084 31.3752 23.9538 31.0412 24.2927C30.7072 24.6317 30.1617 24.6357 29.8228 24.3017L28.6503 23.1462L21.7596 29.9367L22.9141 31.0744C23.253 31.4084 23.257 31.9538 22.923 32.2927C22.589 32.6317 22.0435 32.6357 21.7046 32.3017L20.0275 30.6489C19.63 30.2573 19.63 29.6161 20.0275 29.2244L27.9484 21.4188Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.69159 13.6031L2.25977 12.1921L3.4692 10.9648L4.90103 12.3758C5.22402 12.6941 5.43466 13.0803 5.58924 13.4358C5.72321 13.7439 5.8362 14.0816 5.93751 14.3845C5.95185 14.4273 5.96595 14.4695 5.97985 14.5108C6.22348 15.2344 6.43805 15.774 6.81238 16.1429L8.11009 17.4217C8.449 17.7557 8.453 18.3012 8.11902 18.6401C7.78505 18.979 7.23956 18.983 6.90065 18.649L5.60295 17.3702C4.89666 16.6742 4.57767 15.7462 4.34683 15.0605C4.33168 15.0155 4.31695 14.9716 4.30254 14.9286C4.19796 14.6167 4.11081 14.3568 4.00907 14.1228C3.89498 13.8604 3.79072 13.7008 3.69159 13.6031Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.484 5.125L11.073 3.69318L9.8457 4.90261L11.2567 6.33444C11.575 6.65743 11.9611 6.86807 12.3166 7.02266C12.6247 7.15662 12.9625 7.26961 13.2653 7.37092C13.3082 7.38526 13.3503 7.39936 13.3916 7.41326C14.1153 7.65689 14.6549 7.87146 15.0237 8.24579L16.3026 9.5435C16.6365 9.88241 17.182 9.88641 17.5209 9.55243C17.8598 9.21846 17.8638 8.67297 17.5299 8.33406L16.251 7.03636C15.555 6.33007 14.6271 6.01108 13.9414 5.78025C13.8964 5.76509 13.8524 5.75036 13.8095 5.73595C13.4976 5.63137 13.2377 5.54422 13.0037 5.44249C12.7413 5.32839 12.5817 5.22413 12.484 5.125Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.11744 1.69715C8.4519 1.35871 8.99738 1.35548 9.33582 1.68993L11.0074 3.3418C11.4039 3.73365 11.4034 4.37406 11.0064 4.76535L3.08528 12.5711C2.69597 12.9547 2.07078 12.9547 1.68147 12.5711L0.00818181 10.9222C-0.330728 10.5882 -0.334728 10.0427 -0.000752509 9.7038C0.333223 9.36489 0.878705 9.36089 1.21761 9.69487L2.38338 10.8437L9.27502 4.05235L8.12465 2.91552C7.78622 2.58107 7.78299 2.03558 8.11744 1.69715Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9267 27.7615C12.47 27.9231 12.2114 28.0588 12.067 28.2011L10.6352 29.6121L9.42578 28.3848L10.8576 26.9738C11.2941 26.5437 11.8729 26.3067 12.3517 26.1372C12.56 26.0635 12.7484 26.0022 12.9274 25.9441C12.9406 25.9398 12.9539 25.9355 12.9671 25.9312C13.4196 25.7841 13.9266 25.9969 14.1099 26.436C14.2932 26.8751 14.084 27.3761 13.6326 27.5265C13.539 27.5577 13.4473 27.5877 13.3595 27.6165C13.1985 27.6692 13.0504 27.7177 12.9267 27.7615Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.52552 17.9694C5.99183 18.064 6.30171 18.5215 6.16596 18.9775C5.94377 19.724 5.58936 20.4605 4.90103 21.1388L3.4692 22.5498L2.25977 21.3225L3.69159 19.9115C4.0761 19.5326 4.30459 19.1259 4.46835 18.6324C4.61818 18.1808 5.05921 17.8748 5.52552 17.9694Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.68147 20.9466C2.07062 20.5621 2.69659 20.5616 3.08628 20.9457L11.0072 28.7513C11.4043 29.1425 11.4047 29.783 11.0082 30.1748L9.33669 31.8267C8.99825 32.1611 8.45276 32.1579 8.11831 31.8195C7.78385 31.481 7.78708 30.9355 8.12552 30.6011L9.27588 29.4643L2.3851 22.6738L1.21849 23.8267C0.880055 24.1611 0.334569 24.1579 0.000114739 23.8195C-0.33434 23.481 -0.331111 22.9355 0.00732601 22.6011L1.68147 20.9466Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.1412 11.9118L27.573 10.5009L28.7825 11.7282L27.3507 13.1391C27.2076 13.2801 27.0713 13.5319 26.908 13.9806C26.8637 14.1024 26.8142 14.2496 26.7603 14.41C26.7319 14.4945 26.7022 14.5826 26.6715 14.6726C26.5176 15.1228 26.0151 15.3279 25.5775 15.1411C25.1399 14.9543 24.9309 14.4455 25.0815 13.9942C25.0845 13.9852 25.0874 13.9763 25.0904 13.9673C25.1499 13.7889 25.2126 13.6008 25.2888 13.3915C25.4615 12.9167 25.7034 12.3433 26.1412 11.9118Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.9748 4.85001L20.4067 3.43903L21.6161 4.66633L20.1843 6.07731C19.4958 6.75574 18.7459 7.10358 17.9918 7.32128C17.5346 7.45324 17.0787 7.14168 16.9861 6.67497C16.8934 6.20826 17.201 5.76763 17.6543 5.62299C18.1645 5.46019 18.5861 5.23312 18.9748 4.85001Z'
        fill='#181818'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.7594 3.58106L22.9113 2.44913C23.2507 2.11563 23.2554 1.57016 22.9219 1.23078C22.5884 0.891395 22.043 0.886624 21.7036 1.22012L20.0276 2.86707C19.6291 3.25857 19.6287 3.90054 20.0266 4.29261L27.9475 12.0982C28.3364 12.4815 28.9608 12.4819 29.3503 12.0992L31.0295 10.4491C31.3688 10.1156 31.3736 9.57016 31.0401 9.23078C30.7066 8.8914 30.1611 8.88663 29.8218 9.22012L28.6501 10.3715L21.7594 3.58106Z'
        fill='#181818'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

