import { useActivateGiftCard } from 'queries/account/giftCards/useActivateGiftCard';
import { TGiftCard, TGiftCardsResponse } from 'queries/account/giftCards/types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Preloader } from 'ui/Preloader';
import { Tabs } from 'ui/Tabs';
import { EmptyItem } from './EmptyItem';
import { GiftItem } from './GiftItem';
import { Wrap, ButtonSC, SubTitle, TextSC, InputSC } from './styled';
import Title from './Title';
import { useGetGiftCards } from 'queries/account/giftCards/useGetGiftCards';
import { SuccessActivationModal } from './SuccessActivationModal';
import { ShareGiftCardModal } from './ShareGiftCardModal/';

export enum Filter {
  ALL,
  ACTIVATED,
  UNACTIVATED,
}

function filterData(sortType: Filter, data?: TGiftCardsResponse) {
  if (!data) return;

  if (sortType === Filter.ALL) return [...data.activated, ...data.purchased];
  if (sortType === Filter.ACTIVATED) return data.activated;
  if (sortType === Filter.UNACTIVATED) return data.purchased;
}

function findCard(data?: TGiftCard[], id?: number) {
  if (!data) return;
  if (!id) return;

  return data.find(card => card.id === id);
}

export const GiftCards: React.FC = () => {
  const [sortType, setSortType] = useState(Filter.ALL);
  const { data, isLoading, refetch } = useGetGiftCards();
  const filteredCards = filterData(sortType, data);
  const [openSuccessActivationModal, setOpenSuccessActivationModal] = useState(
    false
  );
  const [openShareModal, setOpenShareModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState<number>();
  const [cardNominal, setCardNominal] = useState<number>();

  const onSuccessMutation = (nominal: number) => {
    refetch();
    setOpenSuccessActivationModal(true);
    setCardNominal(nominal);
  };

  const mutation = useActivateGiftCard(onSuccessMutation);

  const handleSubmit = (values: { activateCode: string }) => {
    mutation.mutate(values.activateCode);
    setOpenSuccessActivationModal(true);
  };

  return (
    <Wrap>
      {openSuccessActivationModal && cardNominal && (
        <SuccessActivationModal
          nominal={cardNominal}
          closeModal={() => setOpenSuccessActivationModal(false)}
        />
      )}

      {openShareModal && (
        <ShareGiftCardModal
          cardInfo={findCard(filteredCards, selectedCard) as TGiftCard}
          closeModal={() => setOpenShareModal(false)}
        />
      )}

      <Title>{'Подарочные карты'}</Title>
      <SubTitle>Активация карты</SubTitle>
      <TextSC>
        Для активации подарочной карты введите код в поле, нажмите кнопку
        «Активировать» и вам будут начислены бонусные баллы
      </TextSC>
      <div>
        <Form
          onSubmit={handleSubmit}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <Field
                placeholder='Введите код, которым с вами поделились'
                name='activateCode'
                inputWidth={500}
                component={InputSC}
              />
              <ButtonSC
                type='submit'
                buttonType='primary'
                disabled={mutation.isLoading}
              >
                Активировать
              </ButtonSC>
            </form>
          )}
        />
        <Tabs
          tabNames={['Все', 'Активированные', 'Нективированные']}
          selected={sortType}
          setSelected={setSortType}
        />
        {isLoading ? (
          <Preloader />
        ) : (
          <>
            {filteredCards?.length && data ? (
              filteredCards.map((item: TGiftCard, index) => {
                const activated = data.activated.includes(item as never);

                return (
                  <GiftItem
                    activate={mutation}
                    setOpenShareModal={setOpenShareModal}
                    setSelectedCard={setSelectedCard}
                    key={item.id + '_' + index}
                    activated={activated}
                    data={item}
                  />
                );
              })
            ) : (
              <EmptyItem sortType={sortType} />
            )}
          </>
        )}
      </div>
    </Wrap>
  );
};
