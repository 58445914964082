import React from 'react';

export const TruckIcon = (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 2.25C0 1.83579 0.335786 1.5 0.75 1.5H12C12.4142 1.5 12.75 1.83579 12.75 2.25V12C12.75 12.4142 12.4142 12.75 12 12.75H0.75C0.335786 12.75 0 12.4142 0 12V2.25ZM1.5 3V11.25H11.25V3H1.5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.25 6C11.25 5.58579 11.5858 5.25 12 5.25H15C15.1989 5.25 15.3897 5.32902 15.5303 5.46967L17.7803 7.71967C17.921 7.86032 18 8.05109 18 8.25V12C18 12.4142 17.6642 12.75 17.25 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12V6ZM12.75 6.75V11.25H16.5V8.56066L14.6893 6.75H12.75Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.125 12.75C3.50368 12.75 3 13.2537 3 13.875C3 14.4963 3.50368 15 4.125 15C4.74632 15 5.25 14.4963 5.25 13.875C5.25 13.2537 4.74632 12.75 4.125 12.75ZM1.5 13.875C1.5 12.4253 2.67525 11.25 4.125 11.25C5.57475 11.25 6.75 12.4253 6.75 13.875C6.75 15.3247 5.57475 16.5 4.125 16.5C2.67525 16.5 1.5 15.3247 1.5 13.875Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.875 12.75C13.2537 12.75 12.75 13.2537 12.75 13.875C12.75 14.4963 13.2537 15 13.875 15C14.4963 15 15 14.4963 15 13.875C15 13.2537 14.4963 12.75 13.875 12.75ZM11.25 13.875C11.25 12.4253 12.4253 11.25 13.875 11.25C15.3247 11.25 16.5 12.4253 16.5 13.875C16.5 15.3247 15.3247 16.5 13.875 16.5C12.4253 16.5 11.25 15.3247 11.25 13.875Z'
    />
  </svg>
);

