import { Modal } from 'ui/Modal';
import React from 'react';
import { Button } from 'ui/Button';
import {
  DateSC,
  PreviewWrapper,
  ShareButton,
  Status,
  StatusWrapper,
  Wrap,
} from './styled';
import { Description, Footer, Info, Preview, Text } from './styled';
import Title from '../Title';
import { CheckSmallIcon } from 'icons/CheckSmallIcon';
import { Path } from 'constants/paths';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateFormats } from 'constants/dateFormats';
import { TDiploma } from 'queries/account/diplomas/types';
import { baseUrl } from 'api/apiClient';
import { useGetDiplomaPdfQuery } from 'queries/account/diplomas/useGetDiplomaPdfQuery';
import { scrollToTop } from 'utilities/scrollToTop';

interface IProps extends TDiploma {
  id: number;
  closeModal: () => void;
}

export const DiplomaModal: React.FC<IProps> = ({
  closeModal,
  name,
  received,
  date_received,
  picture,
  description,
  type_name,
  type_id,
  hash,
}) => {
  const receive_date = format(
    new Date(date_received),
    DateFormats['StartEventDate'],
    {
      locale: ru,
    }
  );

  const { mutate: handleGetPdf } = useGetDiplomaPdfQuery(hash);

  return (
    <Modal handleClose={closeModal}>
      <Wrap>
        <Title>{name}</Title>
        <StatusWrapper>
          <Status done={received}>
            {received ? CheckSmallIcon : null}
            {received ? 'Получен' : 'Не получен'}
          </Status>
          {received ? <DateSC>от {receive_date}</DateSC> : null}
        </StatusWrapper>
        <Description>
          <PreviewWrapper onClick={() => handleGetPdf(hash)}>
            <Preview src={baseUrl + picture} alt={name} />
          </PreviewWrapper>
          <Text>{description ? description : 'Описание отсутствует.'}</Text>
        </Description>
        <Footer>
          {received ? (
            <ShareButton label='Скачать' onClick={() => handleGetPdf(hash)} />
          ) : (
            <>
              <Info>
                {`Пройдите тему саморазвития «${type_name}»`} <br />и получите
                диплом
              </Info>
              <Link to={`${Path.course}/${type_id}`} onClick={scrollToTop}>
                <Button buttonType='primary'>Получить</Button>
              </Link>
            </>
          )}
        </Footer>
      </Wrap>
    </Modal>
  );
};
