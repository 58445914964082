import React from 'react';

export const BookOpenIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.833496 2.50008C0.833496 2.03984 1.20659 1.66675 1.66683 1.66675H6.66683C7.7719 1.66675 8.83171 2.10573 9.61311 2.88714C10.3945 3.66854 10.8335 4.72835 10.8335 5.83342V17.5001C10.8335 17.9603 10.4604 18.3334 10.0002 18.3334C9.53993 18.3334 9.16683 17.9603 9.16683 17.5001C9.16683 17.0581 8.99124 16.6341 8.67868 16.3216C8.36612 16.009 7.94219 15.8334 7.50016 15.8334H1.66683C1.20659 15.8334 0.833496 15.4603 0.833496 15.0001V2.50008ZM9.16683 14.6133V5.83342C9.16683 5.17037 8.90344 4.53449 8.4346 4.06565C7.96576 3.59681 7.32987 3.33341 6.66683 3.33341H2.50016V14.1667H7.50016C8.09035 14.1667 8.66441 14.3233 9.16683 14.6133Z'
      fill='#B1B1B1'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.3869 2.88714C11.1683 2.10573 12.2281 1.66675 13.3332 1.66675H18.3332C18.7934 1.66675 19.1665 2.03984 19.1665 2.50008V15.0001C19.1665 15.4603 18.7934 15.8334 18.3332 15.8334H12.4998C12.0578 15.8334 11.6339 16.009 11.3213 16.3216C11.0088 16.6341 10.8332 17.0581 10.8332 17.5001C10.8332 17.9603 10.4601 18.3334 9.99984 18.3334C9.5396 18.3334 9.1665 17.9603 9.1665 17.5001V5.83342C9.1665 4.72835 9.60549 3.66854 10.3869 2.88714ZM10.8332 14.6133C11.3356 14.3233 11.9096 14.1667 12.4998 14.1667H17.4998V3.33341H13.3332C12.6701 3.33341 12.0342 3.59681 11.5654 4.06565C11.0966 4.53449 10.8332 5.17037 10.8332 5.83342V14.6133Z'
      fill='#B1B1B1'
    />
  </svg>
);