import { Modal } from 'ui/Modal';
import React from 'react';
import { Title, Text, Wrapper, CodeInputSC, FormSC } from './styled';
import { Field, Form } from 'react-final-form';
import { useConfirmEmailChange } from 'queries/account/myData/useConfirmEmailChange';
import { toast } from 'react-toastify';
import { SuccessMessage } from 'ui/SuccessMessage';

interface IProps {
  verificationCode: string;
  closeModal: () => void;
}

export const ConfirmEmailChange: React.FC<IProps> = ({
  closeModal,
  verificationCode,
}) => {
  const mutation = useConfirmEmailChange();

  const submit = (value: string) => {
    mutation.mutate(
      { verificationCode, otp: value },
      {
        onSuccess: () => {
          closeModal();
          toast.success(<SuccessMessage text='Адрес почты обновлен' />);
        },
      }
    );
  };

  const handleInputChange = (value: string) => {
    if (value.length === 4) {
      submit(value);
    }
  };

  return (
    <Modal handleClose={closeModal}>
      <Wrapper>
        <Title>Подтвердите адрес электронной почты</Title>
        <Text>
          Подтверждающий код для смены почты был отправлен на указанный email
        </Text>
        <Form
          onSubmit={submit}
          render={props => {
            return (
              <FormSC onSubmit={props.handleSubmit}>
                <Field
                  name={'code'}
                  inputMode={'numeric'}
                  type={'text'}
                  component={CodeInputSC}
                  onChange={handleInputChange}
                />
              </FormSC>
            );
          }}
        />
      </Wrapper>
    </Modal>
  );
};
