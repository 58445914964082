import { apiClient } from 'api/apiClient';
import { Endpoint } from 'constants/endpoints';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { ErrorMessage } from 'ui/ErrorMessage';
import { UseChangeEmail } from './types';

export const useChangeEmail: UseChangeEmail = () => {
  return useMutation(
    'changeEmail',
    email => apiClient.post(Endpoint.CHANGE_EMAIL, { email }),
    {
      onError: err => {
        toast.error(<ErrorMessage text={err.response?.data.error} />);
      },
    }
  );
};
