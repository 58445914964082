import React, { useEffect } from 'react';
import { TrainingsStore } from 'models/catalog/types';
import { useStore } from 'effector-react';
import { clearCatalog } from 'models/catalog';
import { useGetTrainingsListQuery } from 'queries/catalog/useGetTrainingsListQuery';
import { $trainingsData } from 'models/catalog/model';
import { StyledPreloader, NoData } from './styles';
import { LargeCatalogItem } from 'components/Catalog/common/LargeCatalogItem';

export const Trainings: React.FC = () => {
  const { isLoading, isFetching, isError } = useGetTrainingsListQuery();
  const data: TrainingsStore = useStore($trainingsData);
  const showPreloader = isLoading || isFetching;
  useEffect(() => {
    return () => {
      clearCatalog();
    };
  }, []);

  if (isError) return <NoData>Не удалось загрузить встречи</NoData>;

  return showPreloader ? (
    <StyledPreloader />
  ) : (
    <>
      {data.map(item => (
        <LargeCatalogItem itemType={'training'} key={item.id} {...item} />
      ))}
    </>
  );
};
