import React from 'react';

export const SmileHandLargeIcon = (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.0128 18.0118C17.7024 17.7015 17.0116 17.0107 16.0103 17.0107C15.0091 17.0107 14.3182 17.7015 14.0078 18.0118'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
    <path
      d='M26.0245 18.0118C25.7142 17.7015 25.0233 17.0107 24.022 17.0107C23.0208 17.0107 22.3299 17.7015 22.0195 18.0118'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
    <path
      d='M26.0228 23.0166C24.4208 24.8886 22.3182 26.0198 20.0153 26.0198C17.7124 26.0198 15.6098 24.8886 14.0078 23.0166'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
    <path
      d='M25.0426 32.0266C23.5006 32.6773 21.7985 33.0277 20.0163 33.0277C12.8273 33.0277 7 27.2015 7 20.0138C7 12.8262 12.8273 7 20.0163 7C27.2053 7 33.0325 12.8262 33.0325 20.0138C33.0325 21.7857 32.6821 23.4775 32.0313 25.0192'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinejoin='round'
    />
    <path
      d='M32.8027 32.2571L34.2846 31.4062C35.1256 30.9057 36.1369 30.7956 36.7677 31.1259L38.4999 30.1249C39.3109 29.6544 40.6726 29.8846 41.2333 30.8556L42.9654 29.8546C43.7765 29.3841 45.1382 29.6143 45.6989 30.5854L50.7051 39.2546C51.7164 41.0064 50.7952 43.419 51.4761 44.5903C52.1469 45.7615 53.1382 47.3532 53.1382 47.3532'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M36.7773 31.1152L39.2805 35.4498'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M41.2422 30.8555L43.2447 34.3192'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M35.8059 37.4524L29.7984 27.0514C29.2577 26.0903 28.0262 25.77 27.065 26.3206C26.1038 26.8612 25.7734 28.0624 26.3141 29.0235C26.3141 29.0235 32.3316 39.4445 33.9636 42.2676C34.1038 42.5078 33.8635 42.7881 33.6032 42.698C32.5719 42.3476 30.289 41.5768 28.8673 41.4567C27.9661 41.3766 27.055 41.8571 26.5343 42.6079C25.8034 43.659 26.3641 45.1206 27.5857 45.511C31.2603 46.7023 33.9736 49.5753 37.468 50.3562C38.9098 50.7466 39.05 51.0969 39.3404 51.5875C39.6207 52.078 39.861 52.4884 39.861 52.4884'
      stroke='#181818'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
  </svg>
);

