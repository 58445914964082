import React, { useMemo, useState } from 'react';
import { Category, CategoryWrap, BackButton } from './styles';
import { StickyContainer, Sticky } from 'react-sticky';
import { Link as ScrollLink } from 'react-scroll';
import { ChevronLeftIcon } from 'icons';
import { useHistory } from 'react-router-dom';
import { Path } from 'constants/paths';
import { CategoriesProps } from './types';

const categoriesList = [
  { key: 'description', label: 'Описание' },
  { key: 'rules', label: 'Правила' },
  { key: 'trailer', label: 'Трейлер' },
  { key: 'tariffs', label: 'Тарифы' },
  { key: 'lessons', label: 'Темы' },
  { key: 'reviews', label: 'Отзывы' },
];

const OFFSET_FROM_TOP = -100;
const OFFSET_FROM_BOTTOM = 50;
const HEADER_HEIGHT = -95;

export const Categories: React.FC<CategoriesProps> = ({
  course_fee,
  trailer,
  textBackButton,
  isCourse,
}) => {
  const [categoryKey, setCategoryKey] = useState('');
  const history = useHistory();
  const handleClick = () => {
    history.push(Path.catalogCourses);
  };

  const categories = useMemo(
    () =>
      categoriesList.filter(({ key }) => {
        if (trailer && course_fee) return true;
        if (!trailer && key === 'trailer') return false;
        return !(!course_fee && key === 'tariffs');
      }),
    [course_fee, trailer]
  );

  return (
    <StickyContainer>
      <Sticky topOffset={OFFSET_FROM_TOP} bottomOffset={OFFSET_FROM_BOTTOM}>
        {stickyProps => (
          <CategoryWrap sticky={stickyProps} offsetFromTop={OFFSET_FROM_TOP}>
            <BackButton
              buttonType={'link'}
              icon={ChevronLeftIcon}
              onClick={handleClick}
              marginIcon={0}
            >
              {textBackButton}
            </BackButton>

            {categories.map(({ label, key }) => (
              <ScrollLink
                to={key}
                spy
                smooth
                onSetActive={to => setCategoryKey(to)}
                offset={HEADER_HEIGHT}
                key={key}
              >
                <Category isActive={categoryKey === key}>{label}</Category>
              </ScrollLink>
            ))}
            {isCourse && <ScrollLink
                to='other-courses'
                spy
                smooth
                onSetActive={to => setCategoryKey(to)}
                offset={HEADER_HEIGHT}
                key='other-courses'
              >
                <Category isActive={categoryKey === 'other-courses'}>Рекомендуем</Category>
              </ScrollLink>}
          </CategoryWrap>
        )}
      </Sticky>
    </StickyContainer>
  );
};
