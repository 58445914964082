import styled from 'styled-components';
import { Button } from 'ui/Button';
import { RatingStars } from 'ui/RatingStars';

export const Title = styled.div`
  ${({ theme }) => theme.typography.headline1};
`;
export const FeedbackButton = styled(Button)`
  flex-shrink: 0;
`;

export const Text = styled.div`
  ${({ theme }) => theme.typography.bodyText2};
  margin-left: 60px;
  max-width: 469px;

  & + ${FeedbackButton} {
    margin-left: 60px;
  }
`;

export const Count = styled.span`
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  margin-left: 16px;
`;

export const Rating = styled.div`
  flex-shrink: 0;
`;

export const ReviewRating = styled.div`
  ${({ theme }) => theme.typography.headline3};
  align-self: flex-start;
`;

export const ReviewCount = styled.div`
  margin-top: 5px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
`;

export const ReviewWrap = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const Wrap = styled.div`
  margin-top: 24px;
`;

export const Inner = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.thirdBgColor};
  border-radius: 14px;
  padding: 24px;
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const ReviewList = styled.div`
  margin: 40px 0 0;
`;

export const RatingComponent = styled(RatingStars)`
  margin: 0;
  & + & {
    margin-left: 8px;
  }
`;

export const MoreButton = styled.div`
  padding: 24px 0 40px;
  text-align: center;
`;
