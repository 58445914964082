import styled from 'styled-components';
import { InputText } from 'ui/InputText';
import { Field as FinalField } from 'react-final-form';
import { InputInner } from 'ui/InputText/InputText';

export const Form = styled.form`
  display: flex;
  align-items: center;
  padding: 0 15px;
  background: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 14px;
`;

export const SubmitButton = styled.button`
  display: flex;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  stroke: ${({ theme }) => theme.colors.disabledColor};
`;

export const Input = styled(InputText)``;

export const Field = styled(FinalField)`
  flex-grow: 1;

  ${InputInner} {
    border-color: transparent;
  }
`;

export const UploadFileInput = styled.input`
  display: none;
`;

export const UploadFileLabel = styled.label`
  cursor: pointer;
  display: flex;
`;
