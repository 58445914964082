import styled from 'styled-components';

export const Wrap = styled.div`
  margin-top: 60px;
`;

export const MasterClassesTitle = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.headline1}
`;

export const MasterClassesList = styled.div`
  margin-top: 24px;
`;
