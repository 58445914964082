import React from 'react';
import { Inner, Wrap, Text, Title, BannerButton } from './styles';
import { Link } from 'react-scroll/modules';

const OFFSET = -120;
const DURATION = 500;

export const Banner: React.FC = props => {
  return (
    <Wrap {...props}>
      <Inner>
        <Title>VIP-клуб</Title>
        <Text>
          Уникальная по эффективности программа в рамках проекта
          «БУДЬ&nbsp;С&nbsp;МУЖИКОМ»
        </Text>
        <Link to={'subscription'} smooth duration={DURATION} offset={OFFSET}>
          <BannerButton size='large'>Вступить в клуб</BannerButton>
        </Link>
      </Inner>
    </Wrap>
  );
};
