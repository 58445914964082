import { Path } from 'constants/paths';
import { ActiveBellNotificationIcon, BellNotificationIcon } from 'icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGetNewNotificationsQuery } from 'queries/notifications/useGetNewNotificationsQuery';
import styled from 'styled-components';
import { useHasNewNotifications } from 'models/notifications/model';

export const Wrap = styled(Link)`
  padding: 15px;
  font-size: 0;
  margin: 0 0 0 4px;
  flex-shrink: 0;
  &:focus {
    ${({ theme }) => theme.additional.noOutline};
  }
`;

export const Notification: React.FC = () => {
  useGetNewNotificationsQuery();
  const hasNewMessages = useHasNewNotifications();
  return (
    <Wrap to={`${Path.notifications}`}>
      {hasNewMessages ? ActiveBellNotificationIcon : BellNotificationIcon}
    </Wrap>
  );
};
