import { useQuery, UseQueryResult } from 'react-query';
import { getIntensiveItemFx, setIntensiveItem } from 'models/catalog/model';
import { AxiosError, AxiosResponse } from 'axios';
import { IntensiveItem } from 'models/catalog/types';

export const useGetIntensiveItemQuery: (
  id: string
) => UseQueryResult<
  AxiosResponse<{ data: IntensiveItem }>,
  AxiosError<{ error: string }>
> = id =>
  useQuery('eventItem', () => getIntensiveItemFx(id), {
    onSuccess: res => {
      setIntensiveItem(res.data.data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
