import { CalendarIcon } from 'icons';
import React, { useState } from 'react';
import { DateInputType } from 'react-date-range';
import ReactTooltip from 'react-tooltip';
import { DataPicker } from './DataPicker';
import {
  Input,
  Error,
  InputInner,
  InputLabel,
  InputWrap,
  CalendarIconSC,
} from './styled';
import { InputTextProps } from './types';

const InputDatePicker: React.FC<InputTextProps> = ({
  alignLabel = 'left',
  label,
  disabled,
  direction,
  labelWidth,
  inputWidth,
  className,
  fullWidth,
  input,
  meta,
  changeFieldValue,
  tooltipMessage,
  editable,
  ...rest
}) => {
  const [date, setDate] = useState<DateInputType>('');
  const [showPicker, setShowPicker] = useState(false);

  return (
    <>
      <InputWrap
        error={meta.error}
        disabled={disabled}
        direction={direction}
        className={className}
      >
        {label ? (
          <InputLabel
            direction={direction}
            alignLabel={alignLabel}
            labelWidth={labelWidth}
          >
            {label}
          </InputLabel>
        ) : null}
        <div>
          <InputInner
            isFocused={meta.active}
            fullWidth={fullWidth}
            inputWidth={inputWidth}
          >
            <Input type='text' disabled={disabled} {...input} {...rest} />

            <CalendarIconSC
              data-tip={editable ? undefined : tooltipMessage}
              onClick={() => editable && setShowPicker(!showPicker)}
            >
              {CalendarIcon}
            </CalendarIconSC>
            {!editable && <ReactTooltip effect='solid' />}
          </InputInner>

          {meta.error ? <Error>{meta.error}</Error> : null}
        </div>
      </InputWrap>
      {showPicker && (
        <DataPicker
          date={date}
          setDate={setDate}
          changeFieldValue={changeFieldValue}
          setShowPicker={setShowPicker}
        />
      )}
    </>
  );
};

export default InputDatePicker;
