import { useQuery } from 'react-query';
import { getPaypalButtonLinkFx } from 'models/order/model';
import { PaypalButtonLinkQuery } from './types';

export const usePaypalButtonLinkQuery: PaypalButtonLinkQuery = id =>
  useQuery('paypalButtonLink', () => getPaypalButtonLinkFx(id), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
