import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';
import { Banner } from './Banner';

export const Wrap = styled.div`
  width: 100%;
  margin: 0 0 40px;
  padding: 0 12px 48px;
  align-self: flex-start;
`;
export const StyledPreloader = styled(Preloader)`
  margin: 120px auto 0;
`;

export const NoData = styled.div`
  text-align: center;
  ${({ theme }) => theme.typography.bodyText2};
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.headline1}
`;

export const StyledBanner = styled(Banner)`
  & + ${Title} {
    margin: 40px 0 0;
  }
`;

export const Text = styled.p`
  max-width: 697px;
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.bodyText2}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.thirdBgColor};
  margin: 40px 0;
`;

export const Subtitle = styled.p`
  margin: 60px 0 0;
  ${({ theme }) => theme.typography.headline2}
`;

export const List = styled.ul`
  padding: 0 0 0 24px;
  max-width: 697px;
`;

export const Item = styled.li`
  & + & {
    margin: 18px 0 0;
  }
`;
