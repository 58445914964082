import styled from 'styled-components';
import { Preloader } from 'ui/Preloader';

export const Wrap = styled.div`
  padding: 17px 20px;
  height: 467px;
  overflow-y: auto;
`;

export const Date = styled.div`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.thirdTextAndIconColor};
  text-align: center;
  margin-bottom: 10px;
`;

export const TextWrap = styled.div`
  max-width: 291px;
  display: flex;
  padding: 12px 10px 12px;
  background-color: ${({ theme }) => theme.colors.secondBgColor};
  border-radius: 14px 14px 14px 0;
  min-width: 0;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
`;

export const Message = styled.div<{ isUserMessage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isUserMessage }) =>
    isUserMessage ? 'flex-end' : 'flex-start'};

  & + & {
    margin-top: 16px;
  }

  & > ${TextWrap} {
    border-radius: ${({ isUserMessage }) =>
      isUserMessage ? '14px 14px 0 14px' : '14px 14px 14px 0'};
  }

  & > ${Avatar} {
    order: ${({ isUserMessage }) => (isUserMessage ? 1 : 'initial')};
    margin: ${({ isUserMessage }) =>
      isUserMessage ? '0 0 0 10px' : '0 10px 0'};
  }
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.bodyText2};
  margin: 0 12px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Time = styled.div`
  ${({ theme }) => theme.typography.bodyText3};
  color: ${({ theme }) => theme.colors.disabledColor};
`;

export const CheckIcon = styled.div`
  display: flex;
  margin-left: 4px;
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const Spinner = styled(Preloader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
