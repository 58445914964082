import styled from 'styled-components';

export const Wrap = styled.div`
  text-align: center;
`;

export const Title = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.headline2};
`;

export const Text = styled.p`
  margin: 24px 0 0;
  ${({ theme }) => theme.typography.bodyText2};
`;
